import { getStatusesAppointment } from '@esg/business-report';
import { TableCustom, globalDrawerState, useHttpQuery } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs , AppointmentStatus } from '@esg/shared';
import React from 'react';

import { useRecoilState } from 'recoil';
import AppointmentDetailTable from './AppointmentDetailTable';

interface AppointmentStatusesTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

interface AppointmentStatusesColumns {
    readonly id?: string;
    readonly title: string;
    readonly value?: number;
    readonly status?: AppointmentStatus;
}

export default function AppointmentStatusesTable( props: AppointmentStatusesTableProps) {
    const { dateRange } = props;
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const {data: statusesAppointment, isFetching} = useHttpQuery(getStatusesAppointment, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        },
    });

    const viewDetail = (status?: AppointmentStatus) => {
        setDrawerGlobalState({
            titleTransCode: 'View Detail',
            isOpen: true,
            hideHeader: true,
            content: (
                <AppointmentDetailTable
                    dateRange={dateRange}
                    appointmentStatus={status}
                />
            ),
            footer: null,
        });
    };

    const statusesDataTable: AppointmentStatusesColumns[] = [
        {
            title: 'Books',
            value: statusesAppointment?.totalBook,
            status: AppointmentStatus.Booked,
        },
        {
            title: 'Confirmations',
            value: statusesAppointment?.totalConfirm,
            status: AppointmentStatus.Confirmed,
        },
        {
            title: 'Check-In',
            value: statusesAppointment?.totalCheckIn,
            status: AppointmentStatus.CheckedIn,
        },
        {
            title: 'Completed',
            value: statusesAppointment?.totalComplete,
            status: AppointmentStatus.Completed,
        },
        {
            title: 'No-Show',
            value: statusesAppointment?.totalNoShow,
            status: AppointmentStatus.NoShow,
        },
        {
            title: 'Cancel',
            value: statusesAppointment?.totalCancel,
            status: AppointmentStatus.Cancelled,
        },
    ];

    const columns: TableColumnsType<AppointmentStatusesColumns> = [
        {
            key: 'title',
            title: 'Status',
            dataIndex: 'title',
        },
        {
            key: 'value',
            title: 'Count',
            dataIndex: 'value',
        },
        {
            key: 'links',
            title: 'Links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={()=>viewDetail(record.status)}>View Detail</Tag>
                );
            }
        },
    ];

    return (
        <TableCustom
            showHeader={false}
            showFooter={false}
            columns={columns}
            dataSource={statusesDataTable}
            loading={isFetching}
        />
    );
}
