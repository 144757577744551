import { AppButton, globalModalState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { PlusCircleFilled } from '@ant-design/icons';
import { translateCodes } from '@/locales';
import JobTypeForm from '@/components/form/job-type/JobTypeForm';

interface JobTypeBtnProps{
    resetGlobalModal: () => void;
    refetch: () => void;
}
export const AddJobTypeBtn = (props: JobTypeBtnProps) => {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    return (
        <AppButton
            type='primary'
            translateCode={translateCodes.ADD_JOB_TYPE}
            onClick={() => {
                setGlobalModal({
                    ...globalModal,
                    isOpen: true,
                    titleTransCode: translateCodes.ADD_JOB_TYPE,
                    content: <JobTypeForm
                        onCancel={() => props.resetGlobalModal()}
                        onSuccess={() => {
                            props.resetGlobalModal();
                            props.refetch();
                        }} />,
                });
            }}
            icon={<PlusCircleFilled />}
        />
    );
};
