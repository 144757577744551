import { FlexBox, Spacer, SwitchField, Text } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';

export function EmployeeSelectionRequired() {
    return (
        <Card>
            <FlexBox preset='column-start'>
                <Text fontSize={16} fontWeight={700}>Employee Selection Requirement</Text>
                <Spacer type='margin' bottom={10} />
                <FlexBox preset='column-start'>
                    <SwitchField
                        directionLabel='row-reverse'
                        description="Require 'Select Employee' for Each Appointment"
                        name='employeeSelectionRequired'
                    />
                </FlexBox>

                <Spacer type='margin' bottom={8} />
            </FlexBox>
        </Card>
    );
}
