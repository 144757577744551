/* eslint-disable @typescript-eslint/no-explicit-any */

type Item = { name: string, [key: string]: unknown };
type Category = { name: string, [key: string]: any };

export const searchHelper = {
    searchItemInCategories: <T extends Category>(list: T[], searchValue: string, entitySearch:string) => {
        const keySearch = searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        
        return list?.filter((item) => {
            const items = item?.[entitySearch];
            const isArray = Array.isArray(items);

            if(isArray) return items?.some((itemSearch: Item) => {
                const nameService = itemSearch.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                return nameService.toLowerCase().includes(keySearch.toLowerCase());
            });

            return [];
        });
    } 
};