import React from 'react';
import { TableCustom, useHttpQuery, Text, useLocalesFormat, FlexBox, Block, ColumnAvatarGroup } from '@esg/ui';
import { Card, Divider, TableColumnsType, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProductDetailClientDetailReport, GetProductDetailClientDetailResponse } from '@esg/business-report/api/client-detail-report/getProductDetailClientDetail';
import { getProductDetailClientDetailTotalReport } from '@esg/business-report/api/client-detail-report/getProductDetailClientDetailTotal';
import { translateCodes } from '@/locales';
import'./CustomerProductDetailTable.scss';
interface CustomerProductDetailTableProps {
    customerId?: string;
    productId?: string;
}

export const CustomerProductDetailTable = ({ customerId, productId }: CustomerProductDetailTableProps) => {
    const { t } = useTranslation();
    const { formatDate } = useLocalesFormat();

    const { data: productDetails, isFetching: isProductReportFetching } = useHttpQuery(getProductDetailClientDetailReport, {
        query: {
            CustomerId: customerId,
            ProductId: productId,
        }
    });

    const { data: productDetailTotals } = useHttpQuery(getProductDetailClientDetailTotalReport, {
        query: {
            CustomerId: customerId,
            ProductId: productId,
        }
    });

    const employeeColumn = (productReport: GetProductDetailClientDetailResponse) => {
        const employees = productReport.saleDetailEmployees?.map((saleDetailEmployee) => saleDetailEmployee.employee) ?? [];
        
        return (
            <ColumnAvatarGroup
                items={employees}
                maxCount={2}
                prefixEnd={
                    employees?.length === 1 ?
                        employees[0].fullName ?? '' :
                        `${employees?.length} employee`}
            />
        );
    };

    const columnDetails: TableColumnsType<GetProductDetailClientDetailResponse> = [
        {
            title: 'Order Id',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
            render: (_, productReport) => (
                <a
                    onClick={() => {
                       
                    }}
                >
                    {productReport.code}
                </a>
            )
        },
        {
            title: 'Sale Date',
            key: 'saleDate',
            dataIndex: 'saleDate',
            render: (_, productReport) => {
                return formatDate(productReport.saleDate!);
            },
        },

        {
            title: 'Employees',
            dataIndex: 'employee',
            key: 'employee',
            render: (_, productReport) => employeeColumn(productReport)
        },
        {
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        
        {
            title: t(translateCodes.TOTAL_AMOUNT),
            key: 'Amount',
            dataIndex: 'Amount',
            render: (_,productReport) => <Text fontWeight={600}>{productReport.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>,

        }
    ];

    return (
        <div style={{width:700, padding: 20, display: 'flex', flexDirection: 'column', gap: 20}}>
            <FlexBox direction='column' gap={20}>
                <FlexBox justifyContent='space-between'>
                    <Block>
                        <Text fontSize={16} fontWeight={700}>Order History</Text>
                    </Block>
                    <Block >
                        <Text>Total: </Text>
                        <Text fontWeight='bold'>{productDetailTotals?.total}</Text>
                        <Divider type='vertical' />
                        <Text>Total amount: </Text>
                        <Text fontWeight='bold'>${productDetailTotals?.totalAmount}</Text>
                    </Block>
                </FlexBox>
            </FlexBox>
            <Card>
                <TableCustom
                    className='custom-table-detail'
                    showHeader={false}
                    placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                    loading={isProductReportFetching}
                    titleTableTransCode={translateCodes.PRODUCT_LIST}
                    subTitleTable={<Tag color='processing'>{`${productDetails?.items.length} ${t(translateCodes.PRODUCTS)}`}</Tag>}
                    columns={columnDetails}
                    dataSource={productDetails?.items ?? []}
                />
            </Card>
        </div>
    );
};

