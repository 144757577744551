import { ExternalLink, urlUtil } from '@esg/ui';

import { env } from '@/configs';

export const ToLoginPageLink = () => {
    const redirectParams = {
        client_id: env.MANAGEMENT_CLIENT_ID,
        redirect_uri: env.MANAGEMENT_LOGIN_CALLBACK_PAGE
    };

    return (
        <ExternalLink
            href={urlUtil.toRelativeUrl(env.IDENTITY_LOGIN_PAGE, redirectParams)}
            translateCode='TO_LOGIN_PAGE'
        />
    );
};