import { FacilityTimeOffGetAllResponseItem } from '@esg/business-resource-management/api/facility/facilityTimeOffGetAll';
import { GetDetailFacilitiesResponse } from '@esg/business-resource-management';
import { checkOverlap } from '@esg/shared';
import { ServiceDetailsInfo } from './getServiceDetailsInfo';

interface CheckFacilityValidParams {
	facilityTimeOffs: FacilityTimeOffGetAllResponseItem[];
	facility: GetDetailFacilitiesResponse;
	guestIndex: number;
	serviceDetailsInfo: ServiceDetailsInfo[];
	timeEndWork: string;
	timeStartWork: string;
}

export const checkFacilityValid = ({
    facility,
    guestIndex,
    timeEndWork,
    timeStartWork,
    facilityTimeOffs,
    serviceDetailsInfo,
}: CheckFacilityValidParams) => {
    const result = serviceDetailsInfo.filter(
        (s) => s?.facilityId === facility.id && s.guestIndex !== guestIndex
    );

    const isOverlapWithClient = checkOverlap(result, timeStartWork, timeEndWork);

    if (isOverlapWithClient) {
        return false;
    }

    return !facilityTimeOffs.some((st) => {
        return (
            st.facilityId === facility.id && checkOverlap(
                [{ startTime: st.startTime, endTime: st.endTime }],
                timeStartWork,
                timeEndWork
            )
        );
    });
};
