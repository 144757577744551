import { TimeBlockRepeatBy } from '@esg/business-resource-management';
import { translateCodes } from '@/locales';

export const timeBlockRepeatBy: Record<TimeBlockRepeatBy, string> = {
    '0': translateCodes.NOT_REPEAT,
    '1': translateCodes.EVERY_DAY,
    '2': translateCodes.EVERY_WEEK,
    '3': translateCodes.EVERY_MONTH

};
