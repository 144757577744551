import React from 'react';

export const IconSaleEmpty = () => {
    return (
        <svg width='186' height='182' viewBox='0 0 186 182' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M151.67 104.301C151.67 117.528 147.241 129.958 139.859 139.765C135.771 145.124 130.888 149.799 125.209 153.449C116.124 159.492 105.108 162.913 93.4107 162.913C61.2716 163.027 35.1514 136.8 35.1514 104.301C35.1514 71.9156 61.158 45.5742 93.4107 45.5742C105.108 45.5742 116.01 48.9952 125.209 55.0389C130.888 58.6879 135.771 63.3632 139.859 68.7227C147.241 78.6435 151.67 90.959 151.67 104.301Z' fill='#EAEEF9' />
            <path d='M49.0425 96.4495H48.3391C47.8116 96.4495 47.46 96.2854 47.46 96.0392C47.46 95.793 47.8116 95.6289 48.3391 95.6289H49.0425C49.57 95.6289 49.9216 95.793 49.9216 96.0392C49.9216 96.2854 49.57 96.4495 49.0425 96.4495Z' fill='#2D5887' />
            <path d='M49.0425 99.7307H48.3391C47.8116 99.7307 47.46 99.5666 47.46 99.3204C47.46 99.0743 47.8116 98.9102 48.3391 98.9102H49.0425C49.57 98.9102 49.9216 99.0743 49.9216 99.3204C49.9216 99.5666 49.57 99.7307 49.0425 99.7307Z' fill='#2D5887' />
            <path d='M49.0425 103.014H48.3391C47.8116 103.014 47.46 102.85 47.46 102.604C47.46 102.357 47.8116 102.193 48.3391 102.193H49.0425C49.57 102.193 49.9216 102.357 49.9216 102.604C49.9216 102.85 49.57 103.014 49.0425 103.014Z' fill='#2D5887' />
            <path d='M49.0425 106.295H48.3391C47.8116 106.295 47.46 106.131 47.46 105.885C47.46 105.639 47.8116 105.475 48.3391 105.475H49.0425C49.57 105.475 49.9216 105.639 49.9216 105.885C49.9216 106.131 49.57 106.295 49.0425 106.295Z' fill='#2D5887' />
            <path d='M81.1413 96.4495H69.5754C69.1065 96.4495 68.7939 96.2854 68.7939 96.0392C68.7939 95.793 69.1065 95.6289 69.5754 95.6289H81.1413C81.6102 95.6289 81.9228 95.793 81.9228 96.0392C81.9228 96.2854 81.6102 96.4495 81.1413 96.4495Z' fill='white' />
            <path d='M105.785 106.295H69.5489C69.0959 106.295 68.7939 106.131 68.7939 105.885C68.7939 105.639 69.0959 105.475 69.5489 105.475H105.785C106.237 105.475 106.539 105.639 106.539 105.885C106.539 106.131 106.237 106.295 105.785 106.295Z' fill='url(#paint0_linear_1575_203339)' />
            <path d='M81.1867 99.7307H65.4278C64.986 99.7307 64.6914 99.5666 64.6914 99.3204C64.6914 99.0743 64.986 98.9102 65.4278 98.9102H81.1867C81.6285 98.9102 81.9231 99.0743 81.9231 99.3204C81.9231 99.5666 81.6285 99.7307 81.1867 99.7307Z' fill='#2D5887' />
            <path d='M93.4965 103.014H65.4262C64.9853 103.014 64.6914 102.85 64.6914 102.604C64.6914 102.357 64.9853 102.193 65.4262 102.193H93.4965C93.9374 102.193 94.2314 102.357 94.2314 102.604C94.0844 102.85 93.7905 103.014 93.4965 103.014Z' fill='#2D5887' />
            <path d='M105.741 103.014H95.0307C94.5511 103.014 94.2314 102.85 94.2314 102.604C94.2314 102.357 94.5511 102.193 95.0307 102.193H105.741C106.22 102.193 106.54 102.357 106.54 102.604C106.54 102.85 106.22 103.014 105.741 103.014Z' fill='white' />
            <path d='M112.328 103.014H108.137C107.671 103.014 107.36 102.85 107.36 102.604C107.36 102.357 107.671 102.193 108.137 102.193H112.328C112.794 102.193 113.104 102.357 113.104 102.604C113.104 102.85 112.794 103.014 112.328 103.014Z' fill='url(#paint1_linear_1575_203339)' />
            <path d='M22.0225 148.145H159.876' stroke='#D6DEE8' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
            <g filter='url(#filter0_d_1575_203339)'>
                <path d='M118.848 95.6289V148.144H44.3937C41.4567 148.144 39.2539 145.784 39.2539 142.981V95.6289H118.848Z' fill='white' />
            </g>
            <path d='M148.388 95.6289V142.981C148.388 145.932 145.939 148.144 143.031 148.144H118.848V95.6289H148.388Z' fill='#DAE1ED' />
            <g filter='url(#filter1_d_1575_203339)'>
                <path d='M148.388 95.6289V142.981C148.388 145.932 145.939 148.144 143.031 148.144H118.848V95.6289H148.388Z' fill='#E7EAF4' />
            </g>
            <path d='M67.9736 95.6284L83.6298 71.0117H163.979L147.879 95.6284H67.9736Z' fill='#C5CDDB' />
            <g filter='url(#filter2_d_1575_203339)'>
                <path d='M67.9736 95.6284L83.6298 71.0117H163.979L147.879 95.6284H67.9736Z' fill='url(#paint2_linear_1575_203339)' />
            </g>
            <path opacity='0.3' d='M148.388 95.7727V123.528H126.848C124.848 123.528 123.463 122.233 123.156 120.364L118.848 95.6289L148.388 95.7727Z' fill='url(#paint3_linear_1575_203339)' />
            <g filter='url(#filter3_d_1575_203339)'>
                <path d='M147.769 95.6289H118.848L133.533 117.887C134.582 119.361 136.23 120.246 137.879 120.246H160.356C161.855 120.246 162.904 118.477 162.004 117.297L147.769 95.6289Z' fill='url(#paint4_linear_1575_203339)' />
            </g>
            <g filter='url(#filter4_d_1575_203339)'>
                <path d='M118.848 95.6284L103.082 71.0117H22.0225L38.3831 95.6284H118.848Z' fill='#E7EAF4' />
            </g>
            <path d='M78.9763 125.989H47.9438C47.2187 125.989 46.6387 125.442 46.6387 124.758C46.6387 124.074 47.2187 123.527 47.9438 123.527H78.9763C79.7014 123.527 80.2814 124.074 80.2814 124.758C80.1364 125.442 79.7014 125.989 78.9763 125.989Z' fill='#DAE1ED' />
            <path d='M78.9763 131.733H47.9438C47.2187 131.733 46.6387 131.368 46.6387 130.912C46.6387 130.456 47.2187 130.092 47.9438 130.092H78.9763C79.7014 130.092 80.2814 130.456 80.2814 130.912C80.1364 131.368 79.7014 131.733 78.9763 131.733Z' fill='#DAE1ED' />
            <path d='M62.5448 138.297H47.9642C47.2278 138.297 46.6387 137.933 46.6387 137.477C46.6387 137.021 47.2278 136.656 47.9642 136.656H62.5448C63.2812 136.656 63.8703 137.021 63.8703 137.477C63.723 137.933 63.1339 138.297 62.5448 138.297Z' fill='#DAE1ED' />
            <path d='M79 96C82.0201 95.5605 123.144 89.1947 131.693 67.3209C132.761 64.5876 135.837 56.9822 132.463 49.5064C130.22 44.5352 124.904 38.812 120.829 39.4445C119.83 39.5995 118.027 40.2727 117.745 41.4843C117.511 42.4852 118.39 43.5317 119.237 44.055C120.989 45.1397 123.362 44.4411 124.201 44.1947C128.989 42.7849 132.939 35.8781 133.168 30.3887C133.52 21.9501 125.162 13.1329 112.261 9' stroke='#D6DEE8' strokeWidth='0.90449' strokeMiterlimit='10' strokeLinecap='round' />
            <path d='M105.152 6.32426C103.86 4.87385 103.773 2.82828 104.806 1.50647C105.759 0.289316 107.639 -0.287823 108.447 0.346172C109.399 1.09381 108.982 3.61782 107.336 6.62032C109.676 3.37259 110.652 2.95687 111.115 3.14528C111.758 3.40844 112.016 5.08919 111.238 6.27644C110.661 7.15565 109.604 7.63109 108.522 7.48755' fill='#D0D5DD' />
            <path d='M104.933 6.58984C103.016 6.93675 101.676 8.4859 101.667 10.1636C101.658 11.7067 102.779 13.3186 103.806 13.3186C105.02 13.3186 106.257 11.0786 106.831 7.70531C106.651 11.6977 107.158 12.6278 107.639 12.7624C108.306 12.9508 109.556 11.7904 109.682 10.3789C109.775 9.33222 109.24 8.30945 108.303 7.7562' fill='#D0D5DD' />
            <path d='M103.893 6.44045C103.085 6.22513 101.865 6.01584 100.411 6.18929C99.6958 6.27602 99.077 6.43748 98.5693 6.60794' stroke='#98A2B3' strokeWidth='0.452262' strokeMiterlimit='10' strokeLinecap='round' />
            <path d='M103.956 5.54941C103.133 5.30718 101.685 4.73603 100.654 3.3544C100.462 3.09721 100.306 2.84001 100.177 2.5918' stroke='#98A2B3' strokeWidth='0.452262' strokeMiterlimit='10' strokeLinecap='round' />
            <path d='M109.669 9.12245L104.013 7.17268C103.439 6.97531 103.133 6.34724 103.331 5.77605C103.529 5.20486 104.16 4.89982 104.734 5.09719L110.39 7.04703C110.964 7.2444 111.271 7.87247 111.072 8.44366C110.874 9.01485 110.243 9.31982 109.669 9.12245Z' fill='#98A2B3' />
            <defs>
                <filter id='filter0_d_1575_203339' x='17.2539' y='84.6289' width='123.594' height='96.5156' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='11' />
                    <feGaussianBlur stdDeviation='11' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1575_203339' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1575_203339' result='shape' />
                </filter>
                <filter id='filter1_d_1575_203339' x='96.8477' y='84.6289' width='73.54' height='96.5156' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='11' />
                    <feGaussianBlur stdDeviation='11' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1575_203339' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1575_203339' result='shape' />
                </filter>
                <filter id='filter2_d_1575_203339' x='45.9736' y='60.0117' width='140.005' height='68.6172' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='11' />
                    <feGaussianBlur stdDeviation='11' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1575_203339' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1575_203339' result='shape' />
                </filter>
                <filter id='filter3_d_1575_203339' x='96.8477' y='84.6289' width='87.4893' height='68.6172' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='11' />
                    <feGaussianBlur stdDeviation='11' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1575_203339' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1575_203339' result='shape' />
                </filter>
                <filter id='filter4_d_1575_203339' x='0.0224609' y='60.0117' width='140.825' height='68.6172' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='11' />
                    <feGaussianBlur stdDeviation='11' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1575_203339' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1575_203339' result='shape' />
                </filter>
                <linearGradient id='paint0_linear_1575_203339' x1='68.9635' y1='105.902' x2='106.583' y2='105.902' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FF66A9' />
                    <stop offset='1' stopColor='#F53689' />
                </linearGradient>
                <linearGradient id='paint1_linear_1575_203339' x1='107.399' y1='102.579' x2='113.103' y2='102.579' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#83A6FF' />
                    <stop offset='1' stopColor='#5A78FF' />
                </linearGradient>
                <linearGradient id='paint2_linear_1575_203339' x1='115.945' y1='70.4423' x2='115.945' y2='95.8937' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FDFEFF' />
                    <stop offset='0.9964' stopColor='#ECF0F5' />
                </linearGradient>
                <linearGradient id='paint3_linear_1575_203339' x1='133.624' y1='124.1' x2='133.624' y2='98.6879' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.00289017' stopColor='#606673' stopOpacity='0' />
                    <stop offset='1' stopColor='#AAB2C5' />
                </linearGradient>
                <linearGradient id='paint4_linear_1575_203339' x1='140.578' y1='95.0595' x2='140.578' y2='120.511' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FDFEFF' />
                    <stop offset='0.9964' stopColor='#ECF0F5' />
                </linearGradient>
            </defs>
        </svg>
    );
};

