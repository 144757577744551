import { FormBase, FormLayout, Spacer, globalDrawerState, useHttpCommand } from '@esg/ui';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import { schedulerApis } from '@esg/business-schedule';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { AutoNoShowEnabled, EmployeeSelectionRequired, DoubleAppointmentEnabled, TimeSlotDuration, AppointmentMinuteStep, AppointmentTimeSlot } from './children';
import { translateCodes } from '@/locales';
interface AppointmentSettingValues {
    id?: string;
    timeSlotDuration: number;
    doubleAppointmentEnabled: boolean;
    doubleAppointmentMaxSlot: number;
    employeeSelectionRequired: boolean;
    autoNoShowEnabled: boolean;
    autoNoShowAfterTime: number;
    autoNoShowPromptEnabled: boolean;
}

interface AppointmentSettingFormProps {
    defaultValues?: AppointmentSettingValues;
    refetchAppointmentSetting: () => void;
}

export function AppointmentSettingForm(props: AppointmentSettingFormProps) {
    const { t } = useTranslation();
    const { refetchAppointmentSetting,  defaultValues } = props;
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    
    const { mutateAsync: updateAppointmentSetting, isPending: isUpdating } = useHttpCommand(
        schedulerApis.updateAppointmentSetting
    );

    const onSubmit = React.useCallback(
        async (values: AppointmentSettingValues) => {

            const resultTimeSlotDuration = TimeSlotDuration.timeSlotToMinutes(values.timeSlotDuration as AppointmentTimeSlot);
            const onSuccess = () => {
                refetchAppointmentSetting();
                resetDrawerState();
            };

            await updateAppointmentSetting(
                {
                    pathData: {
                        id: defaultValues?.id,
                    },
                    body: {
                        ...values,
                        timeSlotDuration: resultTimeSlotDuration,
                    }
                },
                { onSuccess(_){
                    onSuccess();
                    viewRequests.showNotification.send({
                        type: 'success',
                        message: t(translateCodes.NM_GM_01),
                    });
                } }
            );
        },
        [defaultValues?.id, refetchAppointmentSetting, resetDrawerState, t, updateAppointmentSetting]
    );

    return (
        <FormBase
            onSubmit={onSubmit}
            defaultValues={{
                ...defaultValues,
                timeSlotDuration: TimeSlotDuration.minutesToTimeSlot((defaultValues?.timeSlotDuration ?? 15) as AppointmentMinuteStep),
            }}
        >
            <FormLayout
                isLoadingButton={isUpdating}
                labelSubmitCode='Save'
                width={613}
            >
                <TimeSlotDuration
                    label='Time slot duration'
                    description='Adjust how detailed you want your calendar view. Shorter durations allow for more precise scheduling.'
                />
                <Spacer type='margin' bottom={15} />
                <DoubleAppointmentEnabled />
                <Spacer type='margin' bottom={15} />
                <EmployeeSelectionRequired />
                <Spacer type='margin' bottom={15} />
                <AutoNoShowEnabled />
            </FormLayout>
        </FormBase>
    );
}
