import { HttpRequestData, httpUtil } from '@esg/framework';
import { PackageDurationType } from '@esg/shared/enum/PackageEnum';
import { env } from '../../configs';
import { GetAllPackageResponseItem } from './getAllPackage';

interface CreatePackageRequest extends HttpRequestData {
    readonly body: {
        name: string;
        packageCategoryId: string;
        serviceIds?: string[];
        packageDurationType?: PackageDurationType;
        packageDurationValue?: number;
        usageLimit?: number;
        price: number;
        taxSettingId?: string;
        description?: string;
    };
}

export const createPackage = httpUtil.createHttpRequestMeta<
    CreatePackageRequest,
    GetAllPackageResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/packages',
    method: 'POST',
    authentication: 'bearer',
});
