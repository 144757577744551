import { FlexBox, TimePickerField } from '@esg/ui';
import { IntRange } from 'rc-picker/lib/interface';
import React, { useCallback, useMemo } from 'react';
import { convertSameDate, Dayjs } from '@esg/shared';
import { useFormContext } from 'react-hook-form';
import { BreakTimeGetDetailResponse } from '@esg/business-resource-management';
import { useBreakTimeContext } from '../BreakTimeContext';
import { useBusinessHour } from '@/hooks/useBusinessHour';

export const BreakTimeTimeRange = () => {
    const { timeStep, currentDate } = useBreakTimeContext();
    const {
        getValues,
        setValue,
        formState: { defaultValues },
    } = useFormContext<BreakTimeGetDetailResponse>();
    const {
        branchWorkingTime: [start, end],
    } = useBusinessHour(currentDate);

    const originDate = useMemo(() => defaultValues!.startTime!, [defaultValues]);

    const { fromMax, fromMin, toMax, toMin } = useMemo(() => {

        const fromMin = convertSameDate(originDate, start);
        const toMax = convertSameDate(originDate, end);

        return {
            toMax,
            fromMin,
            toMin: fromMin.add(timeStep, 'm'),
            fromMax: toMax.add(-timeStep, 'm'),
        };
    }, [end, originDate, start, timeStep]);

    const handleChangeForm = useCallback(
        (date: Dayjs) => {
            let value = date.startOf('m');
            const endTime = getValues('endTime');
            const to = convertSameDate(date, endTime);

            if (date >= to) {
                value = to.add(-timeStep, 'm');
            }

            setValue('startTime', value.toISOString(), {
                shouldDirty: true,
            });
        },
        [getValues, setValue, timeStep]
    );

    const handleChangeTo = useCallback(
        (date: Dayjs) => {
            let value = date.startOf('m');
            const startTime = getValues('startTime');
            const from = convertSameDate(date, startTime);

            if (date <= from) {
                value = from.add(timeStep, 'm');
            }

            setValue('endTime', value.toISOString(), {
                shouldDirty: true,
            });
        },
        [getValues, setValue, timeStep]
    );

    return (
        <FlexBox gap={16}>
            <TimePickerField
                label='From'
                format='h:mm A'
                name='startTime'
                minDate={fromMin}
                maxDate={fromMax}
                style={{ width: '100%' }}
                onChange={handleChangeForm}
                minuteStep={timeStep as IntRange<1, 59>}
            />
            <TimePickerField
                label='To'
                maxDate={toMax}
                name='endTime'
                format='h:mm A'
                minDate={toMin}
                style={{ width: '100%' }}
                onChange={handleChangeTo}
                minuteStep={timeStep as IntRange<1, 59>}
            />
        </FlexBox>
    );
};
