import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { env } from '../../configs';

interface getSalesByClientRequest extends HttpRequestData {
	readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface getSalesByClientItem {
    id: string;
    customer: GetCustomerDetailResponse;
    totalSale: number;
    totalService: number;
    totalProduct: number;
    totalDiscount: number;
    totalAmount: number;
    customerLabel: number;
}

export const getSalesByClientReport = httpUtil.createHttpRequestMeta<
    getSalesByClientRequest,
	PaginationResponse<getSalesByClientItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-report/sales-by-client',
    authentication: 'bearer',
});