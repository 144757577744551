import { Route, Routes } from 'react-router-dom';
import { CustomerDetail } from './customer/detail/CustomerDetail';
import { CustomerList } from './customer/list/CustomerList';
import { CustomerGroupList } from './customer-group/list/CustomerGroupList';
import { CustomerSourceList } from './customer-source/CustomerSourceList';

export const Crm = () => {

    return (
        <Routes>
            <Route path='/' Component={CustomerList} />
            <Route path='customer/:id' Component={CustomerDetail} />  
            <Route path='customer/groups' Component={CustomerGroupList} />  
            <Route path='customer/sources' Component={CustomerSourceList} />  
        </Routes>
    );
};