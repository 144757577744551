import { managementApis } from '@esg/business-management';
import {
    FormLayout,
    FlexBox,
    FormBase,
    InputField,
    useHttpCommand,
} from '@esg/ui';
import { Card } from 'antd';
import React from 'react';

import { GetAllProductUnitResponseItem } from '@esg/business-management/api/unit';
import { ProductUnitType } from '@esg/shared/enum';

import { translateCodes } from '@/locales';

interface ProductUnitValues {
    id?: string;
    name: string;
    type: ProductUnitType;
}

interface ProductUnitFormProps {
    readonly onSuccess?: (response?: ProductUnitValues) => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: ProductUnitValues;
}

export const ProductUnitForm = (props: ProductUnitFormProps) => {
    const isUpdate = !!props.defaultValues?.id;

    const { mutateAsync: createUnit, isPending: isLoadingCreate } = useHttpCommand(
        managementApis.createProductUnit
    );

    const { mutateAsync: updateUnit, isPending: isLoadingUpdate } = useHttpCommand(
        managementApis.updateProductUnit
    );

    const onSubmit = React.useCallback(
        async (values: ProductUnitValues) => {
            const onSuccess = (response?: GetAllProductUnitResponseItem) => {
                props.onSuccess?.(response);
            };

            if (isUpdate) {
                await updateUnit({
                    pathData: { id: props.defaultValues?.id },
                    body: {...values, type: 0},
                }, { onSuccess });
                return;
            }

            await createUnit({ body: {...values, type: 0} }, { onSuccess });
        },
        [createUnit, isUpdate, props, updateUnit]
    );

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                onDeleted={props.onDelete}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    props.defaultValues?.id
                        ? translateCodes.SAVE
                        : translateCodes.CREATE
                }
                width={400}
            >
                <Card bordered={false}>
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            rules={{
                                required: translateCodes.NAME_UNIT_REQUIRED,
                            }}
                            name='name'
                            label={translateCodes.NAME}
                            placeholder={translateCodes.NAME_UNIT_PLACEHOLDER}
                        />
                    </FlexBox>
                </Card>
            </FormLayout>
        </FormBase>
    );
};
