import { GetClientType, getSummaryAppointment, getTotalNewClientsAppointment } from '@esg/business-report';
import { TableCustom, globalDrawerState, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React from 'react';
import { useRecoilState } from 'recoil';
import AppointmentDetailTable from './AppointmentDetailTable';
import AppointmentDetailClientsTable from './AppointmentDetailClientsTable';

interface AppointmentSummaryTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

interface AppointmentSummaryColumns {
    readonly id?: string;
    readonly title: string;
    readonly value?: number | string;
    readonly viewType?: GetClientType | 'APPOINTMENT';
}

export default function AppointmentSummaryTable( props: AppointmentSummaryTableProps) {
    const { formatCurrency } = useLocalesFormat();

    const { dateRange } = props;
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const {data: summaryAppointment, isFetching} = useHttpQuery(getSummaryAppointment, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        },
    });

    const {data: totalNewClients} = useHttpQuery(getTotalNewClientsAppointment, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        },
    });

    const viewDetail = (viewType?: GetClientType | 'APPOINTMENT') => {
        if(viewType === 'APPOINTMENT') {
            return setDrawerGlobalState({
                titleTransCode: 'View Detail',
                isOpen: true,
                hideHeader: true,
                content: (
                    <AppointmentDetailTable
                        dateRange={dateRange}
                    />
                ),
                footer: null,
            });
        }

        return setDrawerGlobalState({
            titleTransCode: 'View Detail',
            isOpen: true,
            hideHeader: true,
            content: (
                <AppointmentDetailClientsTable
                    dateRange={dateRange}
                    getClientType={viewType}
                />
            ),
            footer: null,
        });

    };

    const summaryDataTable: AppointmentSummaryColumns[] = [
        {
            title: 'Total Appointment',
            value: summaryAppointment?.totalAppointment ?? 0 as number,
            viewType: 'APPOINTMENT',
        },
        {
            title: 'Total Services',
            value: summaryAppointment?.totalServices ?? 0 as number,
            viewType: 'APPOINTMENT',
        },
        {
            title: 'Clients',
            value: summaryAppointment?.totalClients ?? 0 as number,
            viewType: GetClientType.All,
        },
        {
            title: 'Clients (No-Show)',
            value: summaryAppointment?.totalClientsNoShow ?? 0 as number,
            viewType: GetClientType.NoShow,
        },
        {
            title: 'New Clients',
            value: totalNewClients?.totalClientsNew ?? 0 as number,
            viewType: GetClientType.New,
        },
        {
            title: 'Total Revenue',
            value: formatCurrency(summaryAppointment?.totalAppointmentValue ?? 0),
            viewType: 'APPOINTMENT',
        },
    ];

    const columns: TableColumnsType<AppointmentSummaryColumns> = [
        {
            key: 'title',
            title: 'Title',
            dataIndex: 'title',
        },
        {
            key: 'value',
            title: 'Value',
            dataIndex: 'value',
        },
        {
            key: 'links',
            title: 'Links',
            dataIndex: 'links',
            width: 150,
            render: (_,record) => {
                return (
                    <Tag 
                        className='cursor-pointer' 
                        onClick={()=> viewDetail(record.viewType)}
                    >
                        View Detail
                    </Tag>
                );
            }
        },
    ];

    return (
        <TableCustom
            showHeader={false}
            showFooter={false}
            columns={columns}
            dataSource={summaryDataTable}
            loading={isFetching}
        />
    );
}
