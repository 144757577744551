import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHttpQuery } from '@esg/ui';
import { schedulerApis } from '@esg/business-schedule';
import { AppointmentFormValues } from '../../AppointmentFormControl';

export const useEmployeeWorkTimes = (appointmentDate: string) => {
    const { formState: { defaultValues } } = useFormContext<AppointmentFormValues>();

    const query = useMemo(() => {
        const date = dayjs(appointmentDate).startOf('day').add(8, 'hour').toISOString();
        if (defaultValues?.id) {
            return {
                date,
                currentAppointmentId: defaultValues.id,
            };
        }
        return {
            date,
        };
    }, [appointmentDate, defaultValues?.id]);

    const { data } = useHttpQuery(
        schedulerApis.getAppointmentQuantityByEmployee,
        {
            query,
        },
        { enabled: !!appointmentDate }
    );

    return data || [];
};