import { Route, Routes } from 'react-router-dom';
import DailySaleManagement from './sale/DailySaleManagement';
import FastPosManagement from './sale/FastPosManagement';

export const Sales = () => {
    return (
        <Routes>
            <Route path='' Component={FastPosManagement} />
            <Route path='/daily' Component={DailySaleManagement} />
        </Routes>
    );
};