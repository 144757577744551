import { createBreakTime, getAllBreakTime } from './break-time';
import { getAllBreakTimeDisable } from './break-time-disable';
import { createJobType, deleteJobType, getAllJobTypes, updateJobType } from './job-type';
import { updateShifts } from './shift';
import { createEmployee, deleteEmployee, getAllEmployees, getEmployeeDetail, getEmployeesByAppointment, updateEmployee, updateEmployeeJobStatus, updateEmployeeService, updateEmployeeWorkingTime } from './employee';
import { createTimeOff, deleteTimeOff, getAllTimeOffs, updateTimeOff } from './time-off';
import { createFacility, deleteFacility, getAllFacilities, updateFacility,getDetailFacilities,} from './facility';
export const resourceApis = {
    createJobType,
    getAllJobTypes,
    updateJobType,
    getAllEmployees,
    createEmployee,
    deleteEmployee,
    updateShifts,
    deleteJobType,
    getEmployeeDetail,
    updateEmployee,
    updateEmployeeService,
    updateEmployeeWorkingTime,
    updateTimeOff,
    createTimeOff,
    getAllTimeOffs,
    deleteTimeOff,
    getEmployeesByAppointment,
    updateEmployeeJobStatus,
    createFacility,
    getAllFacilities,
    getDetailFacilities,
    updateFacility,
    deleteFacility,
    createBreakTime,
    getAllBreakTime,
    getAllBreakTimeDisable,
};
