import {
    HOME_URL,
    BRANCH_MANAGEMENT,
    SALON_INFORMATION,
    SUBSCRIPTION
} from '../url';

import { IMenuItem } from '@/Types';

export const salonMenu: IMenuItem[] = [
    {
        label: 'Salon Home',
        icon: '/assets/layout/icons/Home_Icon.svg',
        iconActive: '/assets/layout/icons/Home_Active_Icon.svg',
        url: HOME_URL,
    },
    {
        label: 'Salon Management',
        children: [
            {
                url: `${SALON_INFORMATION}`,
                icon: '/assets/layout/icons/Pos_Icon.svg',
                iconActive: '/assets/layout/icons/Pos_Active_Icon.svg',
                label: 'Salon management',
                disable: true
            },
            {
                url: `${SUBSCRIPTION}`,
                icon: '/assets/layout/icons/Subscription_Icon.svg',
                iconActive: '/assets/layout/icons/Subscription_Active_Icon.svg',
                label: 'Subscription',
                disable: true
            },
            {
                url: `${BRANCH_MANAGEMENT}`,
                icon: '/assets/layout/icons/Pos_Icon.svg',
                iconActive: '/assets/layout/icons/Pos_Active_Icon.svg',
                label: 'Branch management',
            },
        ]
    }
];