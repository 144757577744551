import { AppointmentGetDetailResponse, changeAppointmentStatus } from '@esg/business-schedule';
import { useHttpCommand } from '@esg/ui';
import { useCallback, useState } from 'react';
import { AppointmentStatus } from '@esg/shared';
import { useFormContext } from 'react-hook-form';
import { appointmentEvents } from '@/event';

export const useAptStatusFormUpdate = () => {
    const { getValues } = useFormContext<AppointmentGetDetailResponse>();

    const defaultSaleId = getValues('saleId');

    const [saleId, setSaleId] = useState<string | undefined>(defaultSaleId);

    const { mutateAsync: updateAptStatus, isPending } = useHttpCommand(
        changeAppointmentStatus,
        {
            onSuccess(data) {
                appointmentEvents.refetchAppointments.emit();
                
                if (data.saleId) {
                    setSaleId(data.saleId);
                }
            },
        }
    );

    const handleUpdate = useCallback(
        async (id: string, status: AppointmentStatus) => {
            await updateAptStatus({
                pathData: {
                    id,
                },
                body: {
                    id,
                    status,
                },
            });
        },
        [updateAptStatus]
    );

    return { handleUpdate, saleId, loading: isPending };
};
