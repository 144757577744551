import { managementProfileState } from '@esg/ui';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { RealtimeMethods } from '@esg/shared';
import { realtimeSubscribesArg } from '../realtimeResource';
import { env } from '@/configs';

const connection = new HubConnectionBuilder()
    .withUrl(env.REALTIME_API_SERVER)
    .configureLogging(LogLevel.Information)
    .build();

export const useRealtimeInit = () =>{
    const [profile] = useRecoilState(managementProfileState);
    const [realtimeConn, setRealtimeConn] = useState<HubConnection>();

    useEffect(() => {
        if (profile?.branch.id) {
                
            const start = async () => {
                try {
                    connection.on('Connected', () => {});
                    await connection.start();
                    realtimeSubscribesArg?.forEach((realtimeArg) => {
                        const arg = {
                            ...realtimeArg,
                            key: `${realtimeArg.key}-${profile.branch.id}`,
                        };
                        connection.invoke(RealtimeMethods.Subscribe, arg);
                    });
                    // eslint-disable-next-line no-console
                    console.log('SignalR Connected.');

                    setRealtimeConn(connection);
                } catch (err) {
                    // console.log(err);
                    setTimeout(start, 5000);
                }
            };

            connection.onclose(async () => {
                await start();
            });

            start();
        }
    }, [profile?.branch.id]);

    return realtimeConn;
};