import { Block, FlexBox, FormBase, SelectField, Spacer, SwitchField, Text, useHttpCommand, useHttpQuery } from '@esg/ui';
import React, { useCallback } from 'react';
import { Card, Divider, Typography } from 'antd';
import { automationReminderApi, GetAllReminderResponseItem } from '@esg/business-success-platform/api/automation-reminder';
import { useTranslation } from 'react-i18next';
import FormLayoutAutomation from '../components/FormLayoutAutomation';
import { translateCodes } from '@/locales';

interface reminderTime {
    label: string,
    value: number
}

const reminderTimeOption: reminderTime[] = [
    { value: 1800, label: '30 minutes' },
    { value: 3600, label: '1 hour' },
    { value: 7200, label: '2 hours' },
    { value: 14400, label: '4 hours' },
    { value: 28800, label: '8 hours' },
    { value: 86400, label: '24 hours' },
    { value: 172800, label: '48 hours' },
    { value: 259200, label: '3 days' },
    { value: 432000, label: '5 days' },
    { value: 604800, label: '1 week' },
];

export function AutomationReminder() {
    const { t } = useTranslation();
    const { data: automationReminders, refetch, isFetching } = useHttpQuery(
        automationReminderApi.getAllAutomationReminder
    );
    const automationReminder = automationReminders?.items[0];
    const { mutateAsync } = useHttpCommand(
        automationReminderApi.updateAutomationReminder,
        {
            onSuccess: () => {
                refetch();
            },
        }
    );

    const handleUpdateAutomationReminder = useCallback(async (values: GetAllReminderResponseItem) => {
        await mutateAsync({
            pathData: { id: values.id },
            body: values,
        });
    }, [mutateAsync]);

    return (
        !isFetching && (
            <FormBase onSubmit={handleUpdateAutomationReminder} defaultValues={automationReminder}>
                <FormLayoutAutomation>
                    <Card>
                        <FlexBox>
                            <div style={{ padding: -24 }}>
                                <Typography.Text
                                    style={{ fontSize: 16, fontWeight: 700 }}
                                >
                                    {t(translateCodes.REMIND_EMAIL_APPOINTMENT)}
                                </Typography.Text>
                                <br />
                                <Typography.Text>
                                    {t(translateCodes.REMIND_EMAIL_APPOINTMENT_DESCRIPTION)}
                                </Typography.Text>
                            </div>
                        </FlexBox>
                        <Divider />
                        <FlexBox preset='column-start' gap={30}>
                            <SelectField
                                name='reminderTimeInSeconds'
                                label={t(translateCodes.REMIND_EMAIL_TIME)}
                                description={t(translateCodes.REMIND_EMAIL_TIME_DESCRIPTION)}
                                style={{ width: '50%' }}
                                options={reminderTimeOption}
                            />
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='emailReminder'
                                label={t(translateCodes.REMIND_EMAIL)}
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text translateCode={translateCodes.REMIND_EMAIL_ACTIVE_DESCRIPTION} />
                                        </Spacer>
                                    </Block>
                                }
                            />
                            {/* <SwitchField
                        alignItems='flex-start'
                        directionLabel='column'
                        justifyContent='flex-start'
                        name='smsReminder'
                        gap={8}
                        onChange={(value) => handleUpdateAutomationReminder('smsReminder', value)}
                        label='SMS Reminder'
                        prefix={
                            <Block display='inline-block'>
                                <Spacer left={16}>
                                    <Text>Send customers a SMS reminder of their upcoming appointment</Text>
                                </Spacer>
                            </Block>
                        }
                    /> */}
                        </FlexBox>
                    </Card>
                </FormLayoutAutomation>
            </FormBase>
        ) 
    );
}
