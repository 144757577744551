import { Block, Header, Text } from '@esg/ui';
import React from 'react';

export const DrawerTitle = (dataCode: string, created: string) => {
    return (
        <Block>
            <Header level={2}>{dataCode}</Header>
            <Text>{created}</Text>
        </Block>
    );
};
