import { ClockCircleOutlined } from '@ant-design/icons';
import {
    AppAvatar,
    AppButton,
    Block,
    FlexBox,
    IconDoc,
    IconFacility,
    IconPen,
    IconTrash,
    SchedulerItem,
    Text,
    useLocalesFormat,
} from '@esg/ui';
import { Tooltip } from 'antd';
import React from 'react';

import { AppointmentFastUpdate } from './AppointmentFastUpdate';
import { AppointmentFormBtn } from '@/components';
import './PopoverContent.scss';
import { ScheduleAppointment } from '@/pages/scheduler/Types';
import { useAppointmentDelete } from '@/hooks/useAppointmentDelete';
interface PopoverContentProp {
	readonly item: SchedulerItem<ScheduleAppointment>;
	readonly refetch: () => void;
}

export function PopoverContent(props: PopoverContentProp) {
    const { formatCurrency } = useLocalesFormat();

    const {
        item: {
            data: { values },
            endTime,
            startTime,
        },
    } = props;

    const { appointment, service, facility, appointmentServiceEmployee } = values;

    const employee = appointmentServiceEmployee?.employee;

    const { customer, notes } = appointment;

    const handleDelete = useAppointmentDelete();

    return (
        <div className='scheduler-popover-content'>
            <FlexBox gap={12} alignItems='center'>
                <Block>
                    <AppAvatar data={customer} size='lg' />
                </Block>
                <FlexBox direction='column'>
                    <Text
                        maxLines={1}
                        fontSize={16}
                        color='#141533'
                        fontWeight={700}
                        translateCode={customer?.fullName}
                    />
                    <Text color='#475467' fontSize={14}>
                        {startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}
                    </Text>
                </FlexBox>
            </FlexBox>
            <FlexBox direction='column' gap={12}>
                <FlexBox justifyContent='space-between' alignItems='center'>
                    <Text fontSize={16} color='#141533' fontWeight={700} className='service-name'>
                        {service?.name}
                    </Text>
                    <Text color='#475467' fontSize={16} fontWeight='bold' className='service-price'>
                        {formatCurrency(service!.serviceBranches[0].price ?? 0)}
                    </Text>
                </FlexBox>
                {employee && (
                    <FlexBox alignItems='center' gap={8}>
                        <Block>
                            <AppAvatar data={employee} size='xs' />
                        </Block>
                        <Text color='#475467' fontSize={16} fontWeight='bold' maxLines={1}>
                            {employee?.fullName}
                        </Text>
                    </FlexBox>
                )}
                <FlexBox alignItems='center'>
                    <FlexBox gap={8} flex='unset' className='duration'>
                        <ClockCircleOutlined />
                        <Text color='#475467' fontSize={14}>
                            {service?.duration} mins
                        </Text>
                    </FlexBox>
                    {facility && 
                        <>
                        &nbsp;∙&nbsp;
                            <FlexBox gap={8} alignItems='center' flex='unset'>
                                <IconFacility />
                                <Text color='#475467' fontSize={14} maxLines={1}>
                                    {facility?.name}
                                </Text>
                            </FlexBox>
                        </>
                    }
                </FlexBox>
                {notes && (
                    <FlexBox gap={12} alignItems='center'>
                        <IconDoc />
                        <Tooltip title={notes}>
                            <p
                                style={{
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {notes}
                            </p>
                        </Tooltip>
                    </FlexBox>
                )}
            </FlexBox>
            <FlexBox>
                <FlexBox gap={16}>
                    <AppointmentFormBtn 
                        shape='circle' 
                        initialValues={appointment}
                    >
                        <FlexBox preset='row-center'>
                            <IconPen />
                        </FlexBox>
                    </AppointmentFormBtn>
                    <AppButton
                        shape='circle'
                        onClick={() => {
                            handleDelete({
                                service,
                                appointment,
                            });
                        }}
                    >
                        <FlexBox preset='row-center'>
                            <IconTrash />
                        </FlexBox>
                    </AppButton>
                </FlexBox>
                <AppointmentFastUpdate appointment={appointment} employee={employee} />
            </FlexBox>
        </div>
    );
}
