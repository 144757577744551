import { TimeBlockRepeatType as RepeatType, TimeBlockRepeatBy } from '@esg/business-resource-management';
import { Block, DatePickerField, InputNumberField, SelectField, Spacer } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { dayjs } from '@esg/shared';
import { TimeBlockFormValues } from '../Types';

const repeatToOptions = [
    { label: 'Never', value: RepeatType.NEVER },
    { label: 'On specific date', value: RepeatType.SPECIFIC_DATE },
    { label: 'After', value: RepeatType.AFTER },
];

export const TimeBlockRepeat = () => {
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { watch } = useFormContext<TimeBlockFormValues>();
    const repeatBy = watch('repeatBy');
    const repeatType = watch('repeatType');

    return repeatBy === TimeBlockRepeatBy.NONE ? null : (
        <Block>
            <SelectField name='repeatType' options={repeatToOptions} label='Ends' />
            <Spacer type='margin' bottom={12} />
            {repeatType === RepeatType.SPECIFIC_DATE && (
                <DatePickerField
                    disabledDate={(value) => {
                        const date = watch('date');
                        return value <= dayjs(date); 
                    }}
                    allowClear={false}
                    name='specificDate'
                    format={'ddd ' + currentLocale?.format}
                    rules={{ required: 'The date is required' }}
                />
            )}
            {repeatType === RepeatType.AFTER && (
                <InputNumberField
                    min={1}
                    max={100}
                    size='large'
                    name='occurrences'
                    className='input-after'
                    description='Occurrences'
                    rules={{ required: 'The occurrences is required' }}
                />
            )}
        </Block>
    );
};
