import { Navigate, Route, Routes } from 'react-router-dom';
import { JobType } from './job-type';
import { EmployeeDetail, EmployeeMembers } from './employee-members';
import { Facilities } from './facilities';
import { EmployeeShifts } from './employee-shifts/EmployeeShifts';
import { SubMenuLayout } from '@/layouts';

import {
    RESOURCE_MANAGEMENT_SHIFT_URL,
    resourceManagementMenu,
} from '@/configs';

export const ResourceManagement = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={<SubMenuLayout subMenu={resourceManagementMenu} />}
            >
                <Route
                    path='/'
                    element={<Navigate to={RESOURCE_MANAGEMENT_SHIFT_URL} />}
                />
                <Route path='/shifts' Component={EmployeeShifts} />
                <Route path='/employee-members' Component={EmployeeMembers} />
                <Route path='/employee-members/:id' Component={EmployeeDetail} />
                <Route path='/job-type' Component={JobType} />
                <Route path='/facility-list/*' Component={Facilities}/>
            </Route>
        </Routes>
    );
};
