import React from 'react';
import { Dayjs } from '@esg/shared';
import { SchedulerRowData, SchedulerRowInfo } from '../../@shared';

interface HorizontalSchedulerLayoutProps {
    timeCells: Dayjs[];
    headerWidth?: number;
    baseColumnWidth: number;
    baseRowHeight: number;
    rowData: SchedulerRowData[];
    renderTimeLabel?: (date: Dayjs) => React.ReactElement;
    renderRowInfo?: (rowInfo: SchedulerRowInfo) => React.ReactElement;
    isDisabled?: boolean;
}

export const HorizontalSchedulerLayout = (props: React.PropsWithChildren<HorizontalSchedulerLayoutProps>) => {

    return (
        <div className='horizontal-scheduler-layout'>
            <div className='head-row-anchor' style={{ width: props.headerWidth }} />
            <div className='time-row' style={{ marginLeft: props.headerWidth }}>
                {props.timeCells.map((timeCells) => (
                    <div
                        key={timeCells.toISOString()}
                        className='time-row-cell'
                        style={{
                            width: props.baseColumnWidth
                        }}
                    >
                        {
                            props.renderTimeLabel?.(timeCells) ?? <span className='time-row-cell-label'>{timeCells.format('HH:mm')}</span>
                        }
                    </div>
                ))}
            </div>
            <div className='head-column' style={{ width: props.headerWidth }}>
                {props.rowData.map((row) => (
                    <div
                        key={row.rowInfo.key}
                        className='head-column-cell'
                        style={{ minHeight: props.baseRowHeight * (row.subRows.length ?? 1) }}
                    >
                        {props.renderRowInfo?.(row.rowInfo)}
                    </div>
                ))}
            </div>
            <div className={`horizontal-scheduler-body ${props.isDisabled && 'scheduler-disabled'}`}>
                {props.children}
            </div>
        </div>
    );
};