import React from 'react';

export const IconTotalNewClient = () => {
    return (
        <svg width='49' height='48' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0.200195 24C0.200195 10.7452 10.9454 0 24.2002 0C37.455 0 48.2002 10.7452 48.2002 24C48.2002 37.2548 37.455 48 24.2002 48C10.9454 48 0.200195 37.2548 0.200195 24Z' fill='#ECFDF3'/>
            <path opacity='0.3' d='M28.2002 18C28.2002 20.2091 26.4093 22 24.2002 22C21.9911 22 20.2002 20.2091 20.2002 18C20.2002 15.7909 21.9911 14 24.2002 14C26.4093 14 28.2002 15.7909 28.2002 18Z' fill='#17B26A'/>
            <path opacity='0.4' d='M26.6776 33.9208C25.9514 33.9728 25.1298 34 24.2002 34C16.2002 34 16.2002 31.9853 16.2002 29.5C16.2002 27.0147 19.7819 25 24.2002 25C27.0808 25 29.6058 25.8564 31.0144 27.1412C30.4982 27 29.7739 27 28.7002 27C27.0503 27 26.2253 27 25.7128 27.5126C25.2002 28.0251 25.2002 28.8501 25.2002 30.5C25.2002 32.1499 25.2002 32.9749 25.7128 33.4874C25.9503 33.725 26.2549 33.8524 26.6776 33.9208Z' fill='#17B26A'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M28.2002 34C26.3146 34 25.3718 34 24.786 33.4874C24.2002 32.9749 24.2002 32.1499 24.2002 30.5C24.2002 28.8501 24.2002 28.0251 24.786 27.5126C25.3718 27 26.3146 27 28.2002 27C30.0858 27 31.0286 27 31.6144 27.5126C32.2002 28.0251 32.2002 28.8501 32.2002 30.5C32.2002 32.1499 32.2002 32.9749 31.6144 33.4874C31.0286 34 30.0858 34 28.2002 34ZM28.8669 28.9444C28.8669 28.6223 28.5684 28.3611 28.2002 28.3611C27.832 28.3611 27.5335 28.6223 27.5335 28.9444V29.9167H26.4224C26.0542 29.9167 25.7558 30.1778 25.7558 30.5C25.7558 30.8222 26.0542 31.0833 26.4224 31.0833H27.5335V32.0556C27.5335 32.3777 27.832 32.6389 28.2002 32.6389C28.5684 32.6389 28.8669 32.3777 28.8669 32.0556V31.0833H29.978C30.3462 31.0833 30.6446 30.8222 30.6446 30.5C30.6446 30.1778 30.3462 29.9167 29.978 29.9167H28.8669V28.9444Z' fill='#17B26A'/>
        </svg>

    );
};

