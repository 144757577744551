import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { env } from '../../configs';

interface GetRevenueProductDetailRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
        readonly productId?: string;
        readonly employeeId?: string;
        readonly productCategoryId?: string;
    }
}

export const getRevenueProductDetailReport = httpUtil.createHttpRequestMeta<
    GetRevenueProductDetailRequest,
    PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/detail-by-product',
    authentication: 'bearer',
});