export const mainLayoutTheme = {
    notificationIcon: '/assets/layout/icons/Notify.svg',
    buttonBgColor: '#F9FAFB',
    buttonHeight: 40,
    siderWidth: 196,
    subSiderWidth: 204,
    siderCollapsedWidth: 80,
    headerHeight: 72,
    headerContentHeight: 48,
    searchIcon: '/assets/layout/icons/Search_Icon.svg',
    siderActionRightIcon: '/assets/layout/icons/Sider_Arrow_Right_Icon.svg',
    siderActionLeftIcon: '/assets/layout/icons/Sider_Arrow_Left_Icon.svg',
    viewProfileIcon: '/assets/layout/icons/View_Profile_Icon.svg',
    subscriptionIcon: '/assets/layout/icons/Subscription_Icon.svg',
    logOutIcon: '/assets/layout/icons/Logout_Icon.svg',
    createBillIcon: '/assets/layout/icons/Bill_Create.svg',
    addAppointmentIcon: '/assets/layout/icons/Add_Apt.svg',
    salonManagement: '/assets/layout/icons/Shop.svg',
};