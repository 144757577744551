import { getAppointmentStatusLabel } from '@esg/shared';
import { AppButton, FlexBox, Text } from '@esg/ui';
import { Checkbox, Divider, SelectProps, Select } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { range } from 'lodash';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';

import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

interface AppointmentStatusFilterProps extends SelectProps {
    readonly width?: CSSProperties['width'];
    readonly statuses: number[];
    readonly handleFilter?: (statuses: number[]) => void;
    readonly setFilterOpen: (filter: string) => void;
    readonly filerOpen: string;
    readonly allStatus?: boolean;
}

export const AppointmentStatusFilter = (props: AppointmentStatusFilterProps) => {
    const { statuses, width = 120, handleFilter, setFilterOpen, filerOpen, allStatus, ...rest } = props;
    const [values, setValues] = useState(statuses?.map((i) => Number(i)) || []);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (statuses?.length === 0) {
            setValues([]);
        }
        if (filerOpen != 'status') {
            setOpen(false);
        }
    }, [statuses, filerOpen]);

    const options = useMemo(
        () =>
            range(0, 6).map((i) => {
                const label = getAppointmentStatusLabel(i);
                return { label, value: i };
            }),
        []
    );

    const optionRender = useCallback(
        ({ data }: { data: BaseOptionType }) => (
            <FlexBox gap={12}>
                <Checkbox checked={values.includes(data.value)} />
                <Text ellipsis>{data.label}</Text>
            </FlexBox>
        ),
        [values]
    );

    const dropdownRender = useCallback(
        (options: React.ReactElement) => (
            <div onMouseLeave={() => setOpen(false)}>
                {options}
                <Divider />
                <div
                    className='select-filter-actions'
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <AppButton
                        className='action-btn'
                        translateCode='CANCEL'
                        onClick={() => {
                            handleFilter?.([]);
                            setValues([]);
                        }}
                    />
                    <AppButton
                        className='action-btn'
                        type='primary'
                        translateCode='Apply'
                        onClick={() => handleFilter?.(values)}
                    />
                </div>
            </div>
        ),
        [handleFilter, values]
    );

    const maxTagPlaceholder = useCallback(
        (omittedValues: DisplayValueType[]) => {
            const isAll = omittedValues.length === options.length;
            const isOne = omittedValues.length === 1;
            return (
                <span>
                    {isAll
                        ? 'All status'
                        : isOne
                            ? omittedValues[0].label
                            : `${omittedValues.length} status`}
                </span>
            );
        },
        [options.length]
    );

    const style: CSSProperties = useMemo(() => ({ width, height: 40 }), [width]);

    return (
        <Select
            open={open}
            onClick={() => {
                setOpen(true);
                setFilterOpen('status');
            }}
            value={values}
            onChange={(value) => {
                setValues(value);
            }}
            mode='multiple'
            options={options}
            style={style}
            showSearch={false}
            popupMatchSelectWidth={308}
            listHeight={400}
            popupClassName='select-filter'
            optionRender={optionRender}
            maxTagCount={0}
            maxTagPlaceholder={maxTagPlaceholder}
            className={allStatus ? 'placeholderSelectAll' : '' }
            placeholder={allStatus ? t(translateCodes.ALL_STATUS) : t(translateCodes.FILTER_STATUS)}
            dropdownRender={dropdownRender}
            menuItemSelectedIcon={null}
            {...rest}
        />
    );
};
