import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';

import { CreateJobTypeResponse } from '.';

interface GetAllJobTypeRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}

export const getAllJobTypes = httpUtil.createHttpRequestMeta<
	GetAllJobTypeRequest,
	PaginationResponse<CreateJobTypeResponse>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/job-types',
    method: 'GET',
    authentication: 'bearer',
});
