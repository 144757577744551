import { AppAvatar,  FlexBox, Text } from '@esg/ui';
import React from 'react';
import { GetAllCustomerResponseItem } from '@esg/business-crm';

interface CustomerSelectValueProps {
    customer?: GetAllCustomerResponseItem;
}

export default function CustomerSelectOption(props: CustomerSelectValueProps) {
    const {customer} = props;
    
    return (
        <FlexBox alignItems='center' gap={16} height={72}>
            <AppAvatar data={props.customer} size='lg' />
            <FlexBox direction='column'>
                <Text ellipsis maxWidth={300} maxLines={1} fontWeight='bold'>{customer?.fullName}</Text>
                <FlexBox gap={18} justifyContent='space-between'>
                    <Text ellipsis maxWidth='70%' maxLines={1}>{customer?.email}</Text>
                    <Text maxWidth='30%' maxLines={1}>{customer?.phone}</Text>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
}
