import { Block, FlexBox, IconCalendar, IconList, IconTimeLine, useLocalesFormat } from '@esg/ui';
import { DatePicker, Radio, RadioChangeEvent, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { dayjs, Dayjs } from '@esg/shared';
import { AppointmentQueryParams, ViewType } from '..';
import { SchedulerAppointmentBtn } from './scheduler-header/SchedulerAppointmentBtn';
import { AppointmentSetting } from '.';
import { EmployeeFilter } from '@/components/employee/EmployeeFilter';
import { ServiceFilter } from '@/components/service/ServiceFilter';
import { AppointmentStatusFilter } from '@/components/appointment/AppointmentStatusFilter';
import './scheduler-header/SchedulerHeader.scss';

interface SchedulerFilterProps {
	readonly viewType: ViewType;
	readonly setViewType: React.Dispatch<React.SetStateAction<ViewType>>;
	readonly query: AppointmentQueryParams;
	readonly setQuery: (value: AppointmentQueryParams) => void;
}

export const SchedulerHeader = (props: SchedulerFilterProps) => {
    const { formatDate } = useLocalesFormat();
    const [ filerOpen, setFilterOpen ] = useState('');
    const {
        setViewType,
        viewType,
        query,
        setQuery,
    } = props;

    const handleFilterByEmployee = useCallback(
        (employeeIds: string[]) => {
            setQuery({ ...query, employeeIds });
        },
        [query, setQuery]
    );

    const handleFilterByService = useCallback(
        (serviceIds: string[]) => {
            setQuery({ ...query, serviceIds });
        },
        [query, setQuery]
    );

    const handleFilterByStatus = useCallback(
        (statuses: number[]) => {
            setQuery({ ...query, statuses });
        },
        [query, setQuery]
    );

    const handleFilterByDate = useCallback(
        (value: Dayjs) => {
            setQuery({
                ...query,
                currentDate: value.toISOString(),
                employeeIds: [],
                statuses: [],
                serviceIds: [],
            });
        },
        [query, setQuery]
    );

    const handleChangeViewMode = useCallback(
        (e: RadioChangeEvent) => {
            setViewType((prev) => ({ ...prev, viewMode: e.target.value }));
        },
        [setViewType]
    );

    const handleChangeViewBy = useCallback(
        (e: RadioChangeEvent) => {
            setViewType((prev) => ({ ...prev, viewBy: e.target.value }));
        },
        [setViewType]
    );

    const handlerSerFilterOpen = useCallback(
        (e: string) => {
            setFilterOpen(e);
        },
        []
    );

    return (
        <Block className='scheduler-header'>
            <FlexBox className='scheduler-header-inner'>
                <FlexBox gap={16} alignItems='center' className='scheduler-header_view'>
                    <Radio.Group value={viewType.viewBy} onChange={handleChangeViewBy} size='large'>
                        <Radio.Button value='EMPLOYEE' className='primary-button'>Employee</Radio.Button>
                        <Radio.Button value='FACILITY' className='primary-button'>Facility</Radio.Button>
                    </Radio.Group>

                    <Radio.Group
                        value={viewType.viewMode}
                        onChange={handleChangeViewMode}
                        size='large'
                    >
                        <Tooltip title='Timeline'>
                            <Radio.Button value='TIMELINE' className='primary-button'>
                                <IconTimeLine />
                            </Radio.Button>
                        </Tooltip>
                        <Tooltip title='Calendar'>
                            <Radio.Button value='SCHEDULER' disabled className='primary-button'>
                                <IconCalendar />
                            </Radio.Button>
                        </Tooltip>
                        <Tooltip title='View list'>
                            <Radio.Button value='LIST' className='primary-button'>
                                <IconList />
                            </Radio.Button>
                        </Tooltip>
                    </Radio.Group>

                    <ServiceFilter
                        serviceIds={query.serviceIds}
                        handleFilter={handleFilterByService}
                        setFilterOpen={handlerSerFilterOpen}
                        filerOpen={filerOpen}
                        allServices
                        width={200}
                    />
                    <EmployeeFilter
                        employeeIds={query.employeeIds}
                        handleFilter={handleFilterByEmployee}
                        setFilterOpen={handlerSerFilterOpen}
                        filerOpen={filerOpen}
                        allEmployees
                        width={200}
                    />
                    <AppointmentStatusFilter
                        statuses={query.statuses}
                        handleFilter={handleFilterByStatus}
                        setFilterOpen={handlerSerFilterOpen}
                        filerOpen={filerOpen}
                        allStatus
                        width={200}
                    />

                    <Radio.Group size='large' className='schedulerGroupDatePicker'>
                        <Radio.Button onClick={() => handleFilterByDate(dayjs(query.currentDate).subtract(1, 'day'))}>
                            <LeftOutlined />
                        </Radio.Button>
                        <Radio.Button style={{ padding: 0 }}>
                            <DatePicker
                                value={dayjs(query.currentDate)}
                                onChange={handleFilterByDate}
                                format={(value) => {
                                    const isToday = value.isSame(dayjs(), 'day');

                                    if (isToday) {
                                        return 'Today';
                                    }
                                    return formatDate(value);
                                }}
                                allowClear={false}
                                suffixIcon={null}
                                style={{
                                    borderRadius: 0,
                                    border: 'none',
                                    background: 'transparent',
                                    cursor: 'pointer',
                                    height: '100%'
                                }}
                            />
                        </Radio.Button>
                        <Radio.Button onClick={() => handleFilterByDate(dayjs(query.currentDate).add(1, 'day'))}>
                            <RightOutlined />
                        </Radio.Button>
                    </Radio.Group>
                </FlexBox>
                <FlexBox flex='unset' gap={16}>
                    <AppointmentSetting />
                    <SchedulerAppointmentBtn />
                </FlexBox>
            </FlexBox>
        </Block>
    );
};
