import { emailTemplateCreate } from './emailTemplateCreate';
import { emailTemplateDelete } from './emailTemplateDelete';
import { emailTemplateGetAll } from './emailTemplateGetAll';
import { emailTemplateUpdate } from './emailTemplateUpdate';

export * from './emailTemplateGetAll';

export const emailTemplateApi = {
    emailTemplateGetAll,
    emailTemplateCreate,
    emailTemplateDelete,
    emailTemplateUpdate
};
