import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { GetDetailSaleResponse, updateSaleActive } from '@esg/business-pos';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Block, IconSaleEmpty, useHttpCommand, Text } from '@esg/ui';
import { CloseFastSaleTab } from '../CloseFastSaleTab';
import { saleEvents } from '@/event/saleEvent';
import { currentSaleState } from '@/states';
import './FastSaleMainContent.scss';
import { translateCodes } from '@/locales';

interface FastSaleTabProps {
    sales: GetDetailSaleResponse[];
}

interface SaleTab {
    id: string;
    label: string;
    key: number
}

const initialTabs = (sales: GetDetailSaleResponse[]): SaleTab[] => {
    return sales.map((item, index) => {
        const tab = initialTab(item, index);
        return tab;
    });
};

const initialTab: (sale: GetDetailSaleResponse, key: number) => SaleTab = (sale, key) => {
    const id = sale.id;
    const customerName = sale.customer?.firstName ?? 'Walk-in';
    const customerMobile = sale.customer?.phone?.slice(-3) ?? sale.code?.slice(-3);
    return {
        id: id,
        label: `${customerName}-${customerMobile}`,
        key: key
    } as SaleTab;
};

export const FastSaleMainTab: React.FC<FastSaleTabProps> = ({
    sales
}) => {
    const { t } = useTranslation();
    const [currentSale, setCurrentSale] = useRecoilState(currentSaleState);
    const [activeKey, setActiveKey] = useState(sales?.[0]?.id);
    const [saleTabs, setSaleTabs] = useState(initialTabs(sales));
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const { mutateAsync: onUpdateSaleActive, isPending } = useHttpCommand(updateSaleActive, {
        onSuccess: (response) => {
            const inActiveId = response?.id;
            if (inActiveId !== activeKey) {
                const newTabs = saleTabs.filter(tab => tab.id !== inActiveId);
                return setSaleTabs(newTabs);
            }

            if (inActiveId == activeKey) {
                if (saleTabs.length === 1) {
                    setCurrentSale(null);
                    return saleEvents.onRefetchSaleList.emit();
                }

                const newTabs = saleTabs.filter(tab => tab.id !== inActiveId);

                const currentActiveKey = saleTabs.find(tab => tab.id === inActiveId)?.key || 0;

                const nextTab = saleTabs.filter(tab => tab.key > currentActiveKey)?.[0];
                const nextSale = sales.find(c => c.id === nextTab?.id);

                if (nextTab && nextSale) {
                    setActiveKey(nextTab.id);
                    setSaleTabs(newTabs);
                    return setCurrentSale(nextSale);
                }

                const preTab = saleTabs.filter(tab => tab.key < currentActiveKey).reverse()?.[0];
                const preSale = sales.find(c => c.id === preTab?.id);

                if (preSale && preSale) {
                    setActiveKey(preSale.id);
                    setSaleTabs(newTabs);
                    return setCurrentSale(preSale);
                }

                setSaleTabs(newTabs);
                return saleEvents.onRefetchSaleList.emit();
            }
        }
    });

    const handleWheel = (e: WheelEvent) => {
        if (!containerRef.current) return;
        const scrollAmount = 50; // Adjust the sensitivity of the scroll
        containerRef.current.scrollBy({
            left: e.deltaY < 0 ? -scrollAmount : scrollAmount,
        });
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (!containerRef.current) return;

        const startX = e.pageX;
        const scrollLeft = containerRef.current.scrollLeft;

        const handleMouseMove = (e: MouseEvent) => {
            const dx = e.pageX - startX;
            containerRef.current!.scrollLeft = scrollLeft - dx;
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const scrollContainer = (direction: 'left' | 'right') => {
        if (!containerRef.current) return;
        const scrollAmount = 150;
        containerRef.current.scrollBy({
            left: direction === 'left' ? -scrollAmount : scrollAmount,
            behavior: 'smooth',
        });
    };

    const renderEmptySaleTab = () => {
        return (
            <div className='fast-sale-tab-empty'>
                <IconSaleEmpty />
                <Block>
                    <Text display='block' textAlign='center' fontWeight={600}>
                        {t(translateCodes.CART_NO_ITEM)}
                    </Text>
                    <Text color='#7F8FA4'>
                        {t(translateCodes.CART_NO_ITEM_DESC_FAST_SALE)}
                    </Text>
                </Block>
            </div>
        );
    };

    const updateScrollState = () => {
        if (!containerRef.current) return;
    
        const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
        setIsPrevDisabled(scrollLeft === 0);
        setIsNextDisabled(scrollLeft + offsetWidth >= scrollWidth);
    };

    useEffect(() => {
        setSaleTabs(initialTabs(sales));
        setActiveKey(sales?.[0]?.id);
    }, [sales]);

    useEffect(() => {
        const isEmptySale = saleTabs.length === 0;
        if (isEmptySale && currentSale != null) {
            setCurrentSale(null);
        }
    }, [currentSale, saleTabs, setCurrentSale]);

    useEffect(() => {
        if (activeKey && sales) {
            const sale = sales.find(c => c.id === activeKey);
            if (sale) {
                setCurrentSale({
                    ...sale,
                    tipUnit: sale?.tipUnit
                });
            }
        }
    }, [activeKey, sales, setCurrentSale]);

    useEffect(() => {
        if (!activeKey || !containerRef.current) return;

        const activeTab = containerRef.current.querySelector<HTMLDivElement>(`.item[data-id="${activeKey}"]`);
        if (!activeTab) return;

        const container = containerRef.current;
        const containerWidth = container.offsetWidth;
        const tabOffsetLeft = activeTab.offsetLeft - 32;
        const tabWidth = activeTab.offsetWidth;

        const tabRight = tabOffsetLeft + tabWidth;
        const containerRight = container.scrollLeft + containerWidth;

        if (tabOffsetLeft >= container.scrollLeft && tabRight <= containerRight) {
            return;
        }

        if (tabOffsetLeft < container.scrollLeft) {

            return container.scrollTo({
                left: tabOffsetLeft,
                behavior: 'smooth',
            });
        }

        if (tabRight > containerRight) {
            return container.scrollTo({
                left: container.scrollLeft + tabRight - containerRight,
                behavior: 'smooth',
            });
        }
    }, [activeKey]);

    useEffect(() => {
        const onRefetchTabSaleList = saleEvents.onRefetchTabSaleList.listen(() => {
            const updatedTabs = saleTabs.map(tab =>
                tab?.id === currentSale?.id ? initialTab(currentSale, tab.key) : tab
            );

            setSaleTabs(updatedTabs);
        });

        return () => { onRefetchTabSaleList?.(); };
    }, [currentSale, saleTabs]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel);
            container.addEventListener('scroll', updateScrollState);
            updateScrollState();
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
                container.removeEventListener('scroll', updateScrollState);
            }
        };
    }, []);

    if (saleTabs.length === 0) {
        return renderEmptySaleTab();
    }

    return (
        <Block className='fast-sale-tab-menu-wrapper'>
            <Block 
                className={`scroll-btn ${isPrevDisabled ? 'disabled' : ''}`} 
                onClick={() => !isPrevDisabled && scrollContainer('left')}
            >
                <LeftOutlined style={{ color: '#667085' }} />
            </Block>
            <div
                className='fast-sale-tab-menu-container'
                ref={containerRef}
                onMouseDown={handleMouseDown}
            >
                {saleTabs.map((item) => (
                    <div
                        key={item.id}
                        data-id={item.id}
                        className={`item ${item.id === activeKey ? 'active' : ''}`}

                    >
                        <span
                            onClick={() => setActiveKey(item.id)}
                        >{item.label}
                        </span>
                        <CloseFastSaleTab
                            isPending={isPending}
                            onInActive={async () => {

                                if (isPending) return;
                                await onUpdateSaleActive({
                                    pathData: { id: item.id },
                                    body: {
                                        id: item.id,
                                        isActive: false,
                                        isDisplay: false
                                    }
                                });
                            }}
                        />
                    </div>
                ))}
            </div>
            <Block 
                className={`scroll-btn ${isNextDisabled ? 'disabled' : ''}`} 
                onClick={() => !isNextDisabled && scrollContainer('right')}
            >
                <RightOutlined style={{ color: '#667085' }} />
            </Block>
        </Block>
    );
};
