import { EmployeeGetDetailResponse, resourceApis } from '@esg/business-resource-management';
import { AppAvatar, FlexBox, TableCustom, Text, useHttpCommand, useHttpQuery, usePagination } from '@esg/ui';
import { Switch, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

export default function EmployeeSettingService() {
    const [searchParams, setSearchParams] = usePagination();
    const { t } = useTranslation();

    const { data: employees, isFetching, refetch } = useHttpQuery(resourceApis.getAllEmployees, {
        query: {
            ...searchParams,
        },
    });

    const { mutateAsync } = useHttpCommand(resourceApis.updateEmployee, {
        onSuccess: () => {
            refetch();
        },
    });

    const employeesColumn: ColumnsType<EmployeeGetDetailResponse> = [
        {
            key: 'employeeCode',
            title: t(translateCodes.EMPLOYEE_ID),
            dataIndex: 'employeeCode',
        },
        {
            key: 'name',
            dataIndex: 'fullName',
            title: t(translateCodes.NAME),
            render: (_, value) => {
                return (
                    <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                        <AppAvatar
                            size='md'
                            data={value}
                        />
                        <Text fontWeight='bold'>{value.fullName}</Text>
                    </FlexBox>
                );
            },
        },
        {
            key: 'jobType',
            dataIndex: 'jobType',
            title: t(translateCodes.JOB_TYPE),
            render: (_, item) => {
                return item?.jobType?.name || '-';
            },
        },
        {
            key: 'action',
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <Switch
                        checked={record.isAllowBooking}
                        onChange={async () => {
                            await mutateAsync({
                                pathData: { id: record.id },
                                body: {
                                    ...record,
                                    isAllowBooking: !record.isAllowBooking,
                                },
                            });
                        }}
                    />
                );
            },
        },
    ];

    return (
        <TableCustom
            disableFilter={true}
            loading={isFetching}
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, page: page.toString() })
            }
            onSearch={(value) => setSearchParams({ ...searchParams, search: value })}
            titleTableTransCode={translateCodes.EMPLOYEE_LIST}
            subTitleTable={<Tag>{`${employees?.meta.totalItems} employees`}</Tag>}
            placeholderSearchTransCode={translateCodes.SEARCH_PLACEHOLDER}
            dataSource={employees?.items || []}
            columns={employeesColumn}
        />
    );
}
