import './AppointmentForm.scss';

import { Block } from '@esg/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
    AppointmentCreateRequest,
    AppointmentGetDetailResponse,
} from '@esg/business-schedule';
import { AppointmentFormBase } from './AppointmentFormBase';
import { AppointmentForm } from './AppointmentForm';
import { AppointmentContextProvider } from './@share/AppointmentFormContext';

export type AppointmentFormValues = AppointmentCreateRequest['body'] | AppointmentGetDetailResponse;

interface AppointmentFormControlProps {
	refetch?: () => void;
	defaultValues: Partial<AppointmentFormValues>;
}

export const AppointmentFormControl = ({ defaultValues, refetch }: AppointmentFormControlProps) => {

    const methods = useForm<AppointmentFormValues>({
        defaultValues,
    });

    return (
        <Block className='appointment-form'>
            <FormProvider {...methods}>
                <AppointmentContextProvider refetch={refetch} defaultValues={defaultValues}>
                    <AppointmentFormBase>
                        <AppointmentForm />
                    </AppointmentFormBase>
                </AppointmentContextProvider>
            </FormProvider>
        </Block>
    );
};
