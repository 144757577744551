import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { GetCurrentBookingOnlineSettingResponse } from './getCurrentBookingOnlineSetting';

export interface UpdateBookingOnlineSettingRequest extends HttpRequestData {
    readonly body: {
        readonly id?: string;
        readonly timeSlotDuration?: number;
        readonly doubleBookingEnabled?: boolean;
        readonly bookingFeatureLimit?: number;
        readonly allowCustomerSelfCancel?: boolean;
        readonly allowBookingSlotLimit?: boolean;
        readonly bookingSlotLimit?: number;
        readonly numberHoursBeforeCancel?: number;
        readonly allowGroupAppointment?: boolean;
        readonly requiredSelectEmployee?: boolean;
        readonly showServicePrice?: boolean;
        readonly confirmOnlineAppointment?: boolean;
        readonly bookingOnlineDateOffs?: string[]
    };
}

export const updateBookingOnlineSetting = httpUtil.createHttpRequestMeta<
    UpdateBookingOnlineSettingRequest,
    GetCurrentBookingOnlineSettingResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/booking-online-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
