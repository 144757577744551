import { ClockCircleOutlined, SolutionOutlined } from '@ant-design/icons';
import { AppAvatar, FlexBox, Text, Translate } from '@esg/ui';
import React from 'react';
import { dayjs } from '@esg/shared';
import { BreakTimePopoverAction } from './children/BreakTimePopoverAction';
import { ScheduleTimeDisableValue } from '@/pages/scheduler/Types';
import { translateCodes } from '@/locales';
import { timeBlockRepeatBy } from '@/constants/timeBlockConstants';

interface BreakTimePopoverProps {
	readonly data: ScheduleTimeDisableValue;
}

export function BreakTimePopover({ data }: BreakTimePopoverProps) {

    const { startTime, endTime, employee, type } = data;

    return (
        <FlexBox direction='column' gap={16}>
            <FlexBox gap={12} alignItems='center'>
                <AppAvatar data={employee} size='lg' />
                <FlexBox direction='column'>
                    <Text fontSize={16} color='#141533' fontWeight={700}>
                        {employee?.fullName}
                    </Text>
                    <Text color='#475467' fontSize={14}>
                        {dayjs(startTime).format('hh:mm A')} - {dayjs(endTime).format('hh:mm A')}
                    </Text>
                </FlexBox>
            </FlexBox>
            <FlexBox direction='column' gap={12}>
                <Text fontSize={16} color='#141533' fontWeight={700}>
                    {type === 'BREAK_TIME' ? (
                        <Translate translateCode={translateCodes.BREAK_TIME} />
                    ) : (
                        data.title
                    )}
                </Text>
                <FlexBox gap={12}>
                    <ClockCircleOutlined />
                    <Text color='#475467' fontSize={12}>
                        {dayjs(endTime).diff(startTime, 'minutes')} minutes
                    </Text>
                </FlexBox>
                {type === 'BREAK_TIME' ? null : (
                    <FlexBox gap={12}>
                        <SolutionOutlined />
                        <Text
                            color='#475467'
                            fontSize={12}
                            translateCode={timeBlockRepeatBy[data.repeatBy]}
                        />
                    </FlexBox>
                )}
            </FlexBox>
            <BreakTimePopoverAction data={data} />
        </FlexBox>
    );
}
