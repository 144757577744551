import {
    createCustomer,
    deleteCustomer,
    getAllCustomers,
    getCustomerDetail,
    updateCustomer,
    GetAllCustomerResponseItem,
    GetCustomerDetailResponse,
} from './customer';
import { getAllCustomersGroup, createCustomerGroup, updateCustomerGroup, CreateCustomerGroupResponse, GetAllCustomerGroupResponseItem ,deleteCustomerGroup} from './customer-group';
import { CreateCustomerSourceResponse, GetAllCustomerSourceResponseItem, createCustomerSource, deleteCustomerSource, getAllCustomersSource, updateCustomerSource } from './customer-source';

export type {
    GetAllCustomerResponseItem,
    GetCustomerDetailResponse,
    GetAllCustomerGroupResponseItem,
    CreateCustomerGroupResponse,
    CreateCustomerSourceResponse,
    GetAllCustomerSourceResponseItem,
};

export const customerApi = {
    createCustomer,
    updateCustomer,
    getAllCustomers,
    getCustomerDetail,
    deleteCustomer,
    getAllCustomersGroup,
    createCustomerGroup,
    updateCustomerGroup,
    deleteCustomerGroup,
    getAllCustomersSource,
    createCustomerSource,
    updateCustomerSource,
    deleteCustomerSource,
};