import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { IconIntegrationMail, globalDrawerState, useHttpQuery } from '@esg/ui';
import {
    IntegrationEmailGetAllResponseItem,
    integrationEmailGetAll,
} from '@esg/business-setting/api/integration/integrationEmailGetAll';
import {
    EmailEncryptionType,
    IntegrationEmailService,
    IntegrationStatus,
    IntegrationType,
} from '@esg/business-setting/api/integration/Types';
import { Integration } from './@share/components/integration/Integration';
import { IntegrationDrawerTitle } from './children/IntegrationDrawerTitle';
import { PageLayout } from '@/layouts';
import { IntegrationForm } from '@/components/form/integration/IntegrationForm';
import { translateCodes } from '@/locales';

export const Integrations = () => {
    const [, showIntegration] = useRecoilState(globalDrawerState);

    const { data: integrations, refetch } = useHttpQuery(integrationEmailGetAll);

    const { emailIntegration } = useMemo(() => {
        const emailIntegration = 
			integrations?.find((it) => it.type === IntegrationType.Email) ?? (
                {
                    type: IntegrationType.Email,
                    configuration: {
                        service: IntegrationEmailService.Gmail,
                        smtpServer: 'smtp.gmail.com',
                        port: 465,
                        encryption: EmailEncryptionType.SSL,
                    },
                    status: IntegrationStatus.Disabled,
                } as Partial<IntegrationEmailGetAllResponseItem>
			);

        return {
            emailIntegration,
        };
    }, [integrations]);

    return (
        <PageLayout pageTitleTransCode='Integrations'>
            <Integration
                integration={emailIntegration as IntegrationEmailGetAllResponseItem}
                icon={<IconIntegrationMail />}
                subTitle={translateCodes.MARKETING}
                desc={translateCodes.INTEGRATION_EMAIL_DESC}
                onClick={() => {
                    showIntegration({
                        isOpen: true,
                        titleTransCode: (
                            <IntegrationDrawerTitle
                                icon={<IconIntegrationMail />}
                                integration={emailIntegration as IntegrationEmailGetAllResponseItem}
                            />
                        ),
                        content: (
                            <IntegrationForm
                                defaultValues={emailIntegration}
                                refetch={refetch}
                                titleIcon={<IconIntegrationMail />}
                            />
                        ),
                    });
                }}
            />
        </PageLayout>
    );
};
