import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import {  GetAllCustomerResponseItem } from '@esg/business-crm';
import { env } from '../../configs';

export enum GetClientType {
    All = 0,
    New = 1,
    NoShow = 2,
}

interface GetAppointmentsDetailClientsReportRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
        readonly GetClientType?: GetClientType;
    }
}

export const getAppointmentsDetailClientsReport = httpUtil.createHttpRequestMeta<
    GetAppointmentsDetailClientsReportRequest,
	PaginationResponse<GetAllCustomerResponseItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments-report/detail-clients',
    authentication: 'bearer'
});
