import React, { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUpdateGuestQuantity } from './@share/hooks/useUpdateGuestQuantity';
import { useUpdateGuestServices } from './@share/hooks/useUpdateGuestServices';
import { AppointmentFormValues } from './AppointmentFormControl';

export const AppointmentFormBase = (props: PropsWithChildren) => {
    const methods = useFormContext<AppointmentFormValues>();
    
    useUpdateGuestQuantity(methods);
    useUpdateGuestServices(methods);
    return <>{props.children}</>;
};
