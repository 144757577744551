import { FlexBox, Spacer } from '@esg/ui';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import React, { useMemo } from 'react';
import { Dayjs } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RevenueSummaryTable from './table/RevenueSummaryTable';
import RevenueProductSummaryTable from './table/RevenueProductSummaryTable';
import RevenueProductByEmployeeTable from './table/RevenueProductByEmployeeTable';
import RevenueProductsByCategoryTable from './table/RevenueProductsByCategoryTable';
import RevenueServiceSummaryTable from './table/RevenueServiceSummaryTable';
import RevenueServicesByCategoryTable from './table/RevenueServicesByCategoryTable';
import RevenueServiceByEmployeeTable from './table/RevenueServiceByEmployeeTable';
import RevenuePaymentMethodTable from './table/RevenuePaymentMethodTable';
import { translateCodes } from '@/locales';

interface AnalyserRevenueTabsProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AnalyserRevenueTabs(props: AnalyserRevenueTabsProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const navigate = useNavigate();
    
    const handlerChangeTabReport = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.toString()) {
            navigate(window.location.pathname, { replace: true });
        }
    };
    
    const revenueProductTabsContent = useMemo(()=> (
        <FlexBox gap={24} direction='column'>
            <RevenueProductSummaryTable dateRange={dateRange} />
            <RevenueProductByEmployeeTable dateRange={dateRange} />
            <RevenueProductsByCategoryTable dateRange={dateRange} />
        </FlexBox>
    ),[dateRange]);
    const revenueServiceTabsContent = useMemo(()=>(
        <FlexBox gap={24} direction='column'>
            <RevenueServiceSummaryTable dateRange={dateRange} />
            <RevenueServiceByEmployeeTable dateRange={dateRange} />
            <RevenueServicesByCategoryTable dateRange={dateRange} />
        </FlexBox>
    ),[dateRange]);
    
    const items: TabsProps['items'] = useMemo(()=> ([
        {
            key: '1',
            label: t(translateCodes.SUMMARY),
            children: <RevenueSummaryTable dateRange={dateRange}/>,
        },
        {
            key: '2',
            label: t(translateCodes.PRODUCTS),
            children: revenueProductTabsContent,
        },
        {
            key: '3',
            label: t(translateCodes.SERVICES),
            children: revenueServiceTabsContent,
        },
        {
            key: '4',
            label: t(translateCodes.PAYMENT_METHOD),
            children: <RevenuePaymentMethodTable dateRange={dateRange}/>,
        },
    ]),[dateRange, revenueProductTabsContent, revenueServiceTabsContent, t]);
      
    return (
        <Spacer top={24} >
            <Tabs defaultActiveKey='1' items={items} onChange={handlerChangeTabReport}/>
        </Spacer>
    );
}
