export const handleCreateAppointment = (rowKey: string, date: string) => {
    const appointmentBtn = document.getElementById('appointment-form-btn')!;

    if (rowKey !== '0') {
        appointmentBtn.setAttribute('data-employee-id', rowKey);
    }
    appointmentBtn.setAttribute('data-date', date);

    appointmentBtn.click();
};
