import { managementApis } from '@esg/business-management';
import { resourceApis } from '@esg/business-resource-management';
import { AppointmentGetDetailResponse, schedulerApis } from '@esg/business-schedule';
import {  useHttpQuery } from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { facilityTimeOffGetAll } from '@esg/business-resource-management/api/facility/facilityTimeOffGetAll';
import { useMemo } from 'react';
import { customerApi } from '@esg/business-crm';
import { useEmployeeWorkTimes } from './useEmployeeWorkTimes';
import { useEmployeeDisableTime } from './useEmployeeDisableTime';

export const useAppointmentResources = () => {
    const { watch, formState: {defaultValues} } = useFormContext<AppointmentGetDetailResponse>();
    const date = watch('appointmentDate');

    const aptId = useMemo(() => defaultValues?.id ? ({appointmentId: defaultValues.id}) : ({}),[defaultValues?.id]);

    const { data: appointmentSettings } = useHttpQuery(schedulerApis.getAllAppointmentSetting);

    const { data: services } = useHttpQuery(managementApis.getAllServices, {
        query: {
            pageSize: 100
        }
    });

    const { data: facilities } = useHttpQuery(resourceApis.getAllFacilities, {
        query: {
            pageSize: 100
        }
    });

    const { data: categories } = useHttpQuery(managementApis.getAllServiceCategory, {
        query: {
            pageSize: 100
        }
    });

    const { data: employees } = useHttpQuery(resourceApis.getAllEmployees, {
        query: {
            isAllowBooking: true,
            pageSize: 100
        },
    });

    const { data: customers } = useHttpQuery(customerApi.getAllCustomers, {
        query: {
            pageSize: 100
        },
    });
    
    const { data: facilityTimeOffs } = useHttpQuery(
        facilityTimeOffGetAll,
        {
            query: {
                ...aptId,
                date,
            },
        },
        {
            enabled: !!date,
        }
    );

    const employeeWorkTimes = useEmployeeWorkTimes(date);
    const employeeDisableTimes = useEmployeeDisableTime(date, employees?.items ?? []);

    return {
        employeeWorkTimes,
        employeeDisableTimes,
        employees: employees?.items ?? [],
        services: services?.items ?? [],
        facilities: facilities?.items ?? [],
        categories: categories?.items ?? [],
        facilityTimeOffs: facilityTimeOffs ?? [],
        appointmentSetting: appointmentSettings?.items[0],
        customers: customers?.items ?? [],
    };
};