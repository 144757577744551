import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { AppAvatar, Block, FlexBox, SchedulerRowInfo, Spacer, Text } from '@esg/ui';
import { Dropdown, Image, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export interface SchedulerEmployeeRowInfo {
	name: string;
	employee: EmployeeGetDetailResponse;
	isUnassigned?: boolean;
}

interface SchedulerEmployeeRowInfoProps {
	readonly rowInfo: SchedulerRowInfo<SchedulerEmployeeRowInfo>;
	readonly items: MenuProps['items'];
}

export const SchedulerEmployeeRowInfo = ({ rowInfo, items }: SchedulerEmployeeRowInfoProps) => {

    return (
        <Block position='absolute' height='calc(100% - 2px)' width='calc(100% - 2px)'>
            {rowInfo.data.isUnassigned ? (
                <Spacer type='padding' left={16} right={16} width='100%' height='100%'>
                    <FlexBox height='100%' gap={12} alignItems='center' width='100%'>
                        <Image
                            src='/assets/icons/Default-Employee.svg'
                            width={40}
                            height={40}
                            preview={false}
                        />
                        <Text textAlign='center' ellipsis>
                            {rowInfo.data.name}
                        </Text>
                    </FlexBox>
                </Spacer>
            ) : (
                <Spacer type='padding' left={16} right={30} width='100%' height='100%'>
                    <FlexBox height='100%' gap={12} alignItems='center' width='100%'>
                        <Dropdown menu={{ items }} arrow={{ pointAtCenter: true }}>
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Space size='middle'>
                                    <AppAvatar data={rowInfo.data.employee} />
                                    <Text maxWidth={85} textAlign='center' ellipsis>
                                        {rowInfo.data.name}
                                    </Text>
                                    <DownOutlined
                                        color='#344054'
                                        style={{ position: 'absolute', right: -12, top: 13 }}
                                    />
                                </Space>
                            </div>
                        </Dropdown>
                    </FlexBox>
                </Spacer>
            )}
        </Block>
    );
};
