import { dayjs, Dayjs } from '@esg/shared';

export const dateHelper = {
    getStartAndEndOfWeek : () => {
        const currentDate = dayjs();
        
        const startOfWeek = currentDate.clone().startOf('week').add(1, 'day').endOf('day');
        const endOfWeek = currentDate.clone().endOf('week').add(1, 'day').endOf('day');

        return {
            startOfWeek: startOfWeek,
            endOfWeek: endOfWeek,
        };
    },
    getStartAndEndOfMonth : (date?: string) => {
        const currentDate =date ? dayjs(date): dayjs();
        const startOfMonth = currentDate.clone().startOf('month');
        const endOfMonth = currentDate.clone().endOf('month');

        return {
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth,
        };
    },
    checkIsRangeDateOfMonth : (startDate: Dayjs, endDate: Dayjs) => {
        const startOfMonth = startDate.startOf('month');
        const endOfMonth = startDate.endOf('month');

        return startOfMonth.isSame(startDate) && endOfMonth.isSame(endDate);
    },
    getDateRangePrevious: (startDate: Dayjs, endDate: Dayjs) => {
        const numberDaysRange = endDate.diff(startDate, 'd');

        const isViewByOneDay = numberDaysRange === 0;
        const isViewByMonth = dateHelper.checkIsRangeDateOfMonth(startDate, endDate);

        const dateBefore = startDate.add(-1, 'd');

        if(isViewByOneDay) {
            return [dateBefore, endDate.add(-1, 'd')];
        }

        if(isViewByMonth) {
            return [startDate.add(-1, 'month'), dateBefore];
        }

        return [startDate.add(-numberDaysRange, 'd'), dateBefore];
    },
    //[2024-08-17T13:45:00.000Z]  to 2024-08-17T13:45:00.000Z 
    transformDateLogToISO : (date: string) => {
        const dateLog = date.split('[')[1].split(']')[0];
        return dateLog;
    },
};