import { useMemo } from 'react';
import { CloseOutlined, UserAddOutlined } from '@ant-design/icons';
import { AppAvatar, AppButton, Block, FlexBox, IconEye, Spacer, Text } from '@esg/ui';
import { Card } from 'antd';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { AppointmentFormValues } from '../../..';
import { CUSTOMER_DETAIL_URL } from '@/configs';
import { CustomerLabelTag } from '@/components/customer/CustomerLabelTag';

type CustomerInfoProps = {
    customer: AppointmentFormValues['customer'];
} & (
        | {
            type: 'display';
            handleClear: React.MouseEventHandler<HTMLElement> | undefined;
        }
        | {
            type: 'create';
            handleCreate: () => void;
        }
        | {
            type: 'select';
        }
    );

export const CustomerInfo = (props: CustomerInfoProps) => {
    const { customer, type } = props;
    const isDisplay = type === 'display';

    const avatarData = useMemo(
        () => (type === 'create' ? { icon: <UserAddOutlined /> } : { data: customer }),
        [customer, type]
    );

    return (
        <Card size='small' bordered={isDisplay} className={`customer-card ${type}`}>
            <FlexBox alignItems='center' gap={16}>
                <AppAvatar {...avatarData} size='lg' />
                <Block maxWidth={230}>
                    <FlexBox gap={8}>
                        <Text fontWeight='bold' maxWidth={100} ellipsis>{customer?.fullName}</Text>
                        {isDisplay &&  <CustomerLabelTag customer={customer as GetCustomerDetailResponse} />}
                    </FlexBox>
                    {type === 'create' && (
                        <Text fontWeight='bold' textAlign='start'>
                            New client - {customer?.firstName}z
                        </Text>
                    )}
                    <Spacer bottom={6} />
                    <FlexBox flexWrap='wrap'>
                        <Spacer right={16}>
                            <Text maxWidth={200} ellipsis>
                                {customer?.email}
                            </Text>
                        </Spacer>
                        <Text>{customer?.phone}</Text>
                    </FlexBox>
                </Block>
                {isDisplay && (
                    <FlexBox flex='unset' className='customer-action' gap={8}>
                        <AppButton
                            type='text'
                            shape='circle'
                            className='icon-btn'
                            href={`${CUSTOMER_DETAIL_URL}/${customer?.id}`}
                        >
                            <IconEye />
                        </AppButton>
                        <AppButton shape='circle' type='text' onClick={props.handleClear}>
                            <CloseOutlined />
                        </AppButton>
                    </FlexBox>
                )}
            </FlexBox>
        </Card>
    );
};
