import { convertSameDate, Dayjs, dayjs } from '@esg/shared';
import { managementProfileState } from '@esg/ui';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

export const useBusinessHour = (date?: string | Dayjs) => {
    const [branchProfile] = useRecoilState(managementProfileState);

    const getBusinessHour = useCallback(
        (date: string | Dayjs) => {
            const result = branchProfile?.branch.businessHour.find(
                (o) => o.dayOfWeek === dayjs(date).day()
            );

            const startTime = convertSameDate(date, dayjs(result?.startTime));
            const endTime = convertSameDate(date, dayjs(result?.endTime));

            return {
                branchBusinessHour: result!,
                branchWorkingTime: [startTime, endTime] as [Dayjs, Dayjs],
            };
        },
        [branchProfile?.branch.businessHour]
    );

    const { branchBusinessHour, branchWorkingTime } = useMemo(
        () => getBusinessHour(date ?? dayjs()),
        [date, getBusinessHour]
    );

    return {
        branchBusinessHour,
        branchWorkingTime,
        getBusinessHour
    };
};
