import { FlexBox, Spacer } from '@esg/ui';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import React from 'react';
import { Dayjs } from '@esg/shared';

import AppointmentSummaryTable from './table/AppointmentSummaryTable';
import AppointmentStatusesTable from './table/AppointmentStatusesTable';
import AppointmentServicesTable from './table/AppointmentServicesTable';
import AppointmentEmployeeTable from './table/AppointmentEmployeeTable';
import AppointmentCategoriesTable from './table/AppointmentCategoriesTable';

interface AnalyserAppointmentTabsProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AnalyserAppointmentTabs(props: AnalyserAppointmentTabsProps) {
    const { dateRange } = props;

    const serviceTabsContent = (
        <FlexBox gap={24} direction='column'>
            <AppointmentServicesTable dateRange={dateRange} />
            <AppointmentEmployeeTable dateRange={dateRange} />
            <AppointmentCategoriesTable dateRange={dateRange} />
        </FlexBox>
    );
    
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Summary',
            children: <AppointmentSummaryTable dateRange={dateRange}/>,
        },
        {
            key: '2',
            label: 'Statuses',
            children: <AppointmentStatusesTable dateRange={dateRange}/>,
        },
        {
            key: '3',
            label: 'Services',
            children: serviceTabsContent,
        },
    ];
      
    return (
        <Spacer top={24} >
            <Tabs defaultActiveKey='1' items={items} />
        </Spacer>
    );
}
