import { GetDetailSaleResponse, TipUnit } from '@esg/business-pos';
import { DrawerState, globalDrawerState, subDrawerState } from '@esg/ui';
import { useRecoilState, useResetRecoilState } from 'recoil';
import  SaleForm  from '@/components/form/sale/SaleForm';
import { currentSaleState } from '@/states';
import useFetchDataSaleForm from '@/hooks/useFetchDataSaleForm';
import { saleEvents } from '@/event/saleEvent';

interface ShowSaleFormParams {
	subDrawer?: boolean;
	paidFromScheduler?: boolean;
}

export default function useOpenSaleFrom () {
    const initDataSaleForm = useFetchDataSaleForm();

    const [, setCurrentSale] = useRecoilState(currentSaleState);
    const resetCurrentSale = useResetRecoilState(currentSaleState);
    const [,setDrawerState] = useRecoilState(globalDrawerState);
    const [,setSubDrawerState] = useRecoilState(subDrawerState);

    const onShowFormSale = async (
        sale: GetDetailSaleResponse,
        params?: ShowSaleFormParams
    ) => {
        setCurrentSale({
            ...sale,
            tipUnit: sale?.tipUnit ?? TipUnit.Percent,
            paidFromScheduler: params?.paidFromScheduler,
        });
        const drawerState = {
            isOpen: true,
            onClose: () => {
                saleEvents.onRefetchSaleList.emit();
                saleEvents.onRefetchSaleActive.emit();
                resetCurrentSale();
            },
            content: <SaleForm initDataSaleForm={initDataSaleForm} />,
            footer: null,
        } as DrawerState;

        if (params?.subDrawer) {
            setSubDrawerState(drawerState);
        } else {
            setDrawerState(drawerState);
        }
    };

    return {
        onShowFormSale,
        isLoadingInitData: initDataSaleForm.isLoadingInitData
    };
}
