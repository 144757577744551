import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetAllPaymentMethodRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllPaymentMethodResponseItem {
    id: string;
    name: string;
    isActive: boolean;
}

export const getAllPaymentMethod = httpUtil.createHttpRequestMeta<
GetAllPaymentMethodRequest,
PaginationResponse<GetAllPaymentMethodResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/payment-method-settings',
    method: 'GET',
    authentication: 'bearer',
});
