import React from 'react';
import { IconProps } from '../../Types';

export const IconRank = (props: IconProps) => {
    return (
  
        <svg
            {...props}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.99998 15.1668H5.99998C2.37998 15.1668 0.833313 13.6202 0.833313 10.0002V6.00016C0.833313 2.38016 2.37998 0.833496 5.99998 0.833496H9.33331C9.60665 0.833496 9.83331 1.06016 9.83331 1.3335C9.83331 1.60683 9.60665 1.8335 9.33331 1.8335H5.99998C2.92665 1.8335 1.83331 2.92683 1.83331 6.00016V10.0002C1.83331 13.0735 2.92665 14.1668 5.99998 14.1668H9.99998C13.0733 14.1668 14.1666 13.0735 14.1666 10.0002V6.66683C14.1666 6.3935 14.3933 6.16683 14.6666 6.16683C14.94 6.16683 15.1666 6.3935 15.1666 6.66683V10.0002C15.1666 13.6202 13.62 15.1668 9.99998 15.1668Z'
                fill={props.color} />
            <path d='M14.6666 7.16682H12C9.71998 7.16682 8.83331 6.28016 8.83331 4.00016V1.33349C8.83331 1.13349 8.95331 0.946822 9.13998 0.873489C9.32665 0.793489 9.53998 0.840155 9.68665 0.980155L15.02 6.31349C15.16 6.45349 15.2066 6.67349 15.1266 6.86016C15.0466 7.04682 14.8666 7.16682 14.6666 7.16682ZM9.83331 2.54016V4.00016C9.83331 5.72016 10.28 6.16682 12 6.16682H13.46L9.83331 2.54016Z'
                fill={props.color} />
            <path d='M8.66669 9.1665H4.66669C4.39335 9.1665 4.16669 8.93984 4.16669 8.6665C4.16669 8.39317 4.39335 8.1665 4.66669 8.1665H8.66669C8.94002 8.1665 9.16669 8.39317 9.16669 8.6665C9.16669 8.93984 8.94002 9.1665 8.66669 9.1665Z'
                fill={props.color} />
            <path d='M7.33335 11.8335H4.66669C4.39335 11.8335 4.16669 11.6068 4.16669 11.3335C4.16669 11.0602 4.39335 10.8335 4.66669 10.8335H7.33335C7.60669 10.8335 7.83335 11.0602 7.83335 11.3335C7.83335 11.6068 7.60669 11.8335 7.33335 11.8335Z'
                fill={props.color} />
        </svg>

    );
};

IconRank.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
