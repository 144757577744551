import './SubmenuLayout.scss';
import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { mainLayoutTheme } from '../main-layout/@shared';
import { MainLayoutSubSider } from './children';

import { IMenuItem } from '@/Types';

interface SubMenuLayoutProps {
    readonly subMenu: IMenuItem[];
}

export function SubMenuLayout(props: SubMenuLayoutProps) {
    const { subMenu } = props;

    return (
        <Layout className='sub-menu-layout'>
            <MainLayoutSubSider subMenu={subMenu}/>
            <Layout.Content style={{padding: 0, marginLeft: mainLayoutTheme.subSiderWidth}}>
                <Outlet />
            </Layout.Content>
        </Layout>
    );
}
