import React from 'react';
import { AppButton } from '@esg/ui';
import { useNavigate } from 'react-router-dom';
import { translateCodes } from '@/locales';

export const DailySalesBtn = () => {
    const navigate = useNavigate(); 

    const handleNavigateDailySales = async () => {
        navigate('/fast-pos/daily');
    };

    return (
        <AppButton
            translateCode={translateCodes.DAILY_SALE}
            onClick={()=>handleNavigateDailySales()}
            height={36}
        />
    );
};
