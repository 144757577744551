import { GetDetailSaleResponse, SaleStatus } from '@esg/business-pos';
import { AppButton, Block, FlexBox, Spacer, Text, globalDrawerState, globalModalState, useLocalesFormat } from '@esg/ui';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import ConfirmPaySale from '../../../ConfirmPaySale';
import CancelSaleForm from '../../../CancelSaleForm';
import PrintButton from '@/pages/fast-pos/sale/children/prints/PrintButton';
import { saleEvents } from '@/event/saleEvent';
import { translateCodes } from '@/locales';

interface SaleOrderFooterProps {
    height?: number | string;
    sale?: GetDetailSaleResponse;
    handleUpdateSale: (sale: GetDetailSaleResponse) => Promise<void>;
}

export default function SaleOrderFooter(props: SaleOrderFooterProps) {
    const { formatCurrency } = useLocalesFormat();
    const { t } = useTranslation();

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const resetDrawer = useResetRecoilState(globalDrawerState);

    const totalAmount = props.sale?.totalAmount ?? 0;
    const isHasSaleDetail = props.sale?.saleDetails?.length ?? 0 > 0;

    const onUnpaidSale = useCallback(async () => {
        await props.handleUpdateSale({
            ...props.sale!,
            status: SaleStatus.Unpaid
        });
    }, [props]);

    const showModalConfirmPay = useCallback((sale: GetDetailSaleResponse) => {
        setGlobalModal({
            ...globalModal,
            titleTransCode: (
                <FlexBox direction='column'>
                    <Text fontWeight={700} fontSize={18}>{t(translateCodes.CONFIRM_PAYMENT)}</Text>
                    <Text fontWeight={400} fontSize={14} color='#475467'>{t(translateCodes.DESCRIPTION_CONFIRM_PAYMENT)}</Text>
                </FlexBox>
            ),
            isOpen: true,
            content: (
                <ConfirmPaySale
                    sale={sale}
                    onCancel={resetGlobalModal}
                    onSuccess={resetGlobalModal}
                />
            )
        });
    }, [globalModal, resetGlobalModal, setGlobalModal, t]);

    const showModalConfirmCancel = useCallback((sale: GetDetailSaleResponse) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.CANCEL_SALE,
            content: <CancelSaleForm
                sale={sale}
                onSuccess={() => {
                    resetGlobalModal();
                    resetDrawer();
                    saleEvents.onRefetchSaleList.emit();
                }}
            />
        });
    }, [globalModal, resetDrawer, resetGlobalModal, setGlobalModal]);

    const renderActionSale = useCallback((sale: GetDetailSaleResponse) => {
        if(sale?.status === SaleStatus.Cancelled) {
            return <PrintButton sale={sale!} />;
        }

        return (
            <>
                <PrintButton sale={sale!} />
                <FlexBox gap={16} justifyContent='right'>
                    <AppButton
                        onClick={() => showModalConfirmCancel(props.sale!)}
                        translateCode={translateCodes.CANCEL_SALE}
                        width={174}
                    />
                    {sale?.status === SaleStatus.Paid && (
                        <AppButton
                            onClick={onUnpaidSale}
                            translateCode={translateCodes.EDIT_SALE}
                            type='primary'
                            ghost
                            width={174}
                        />
                    )}
                    {sale?.status === SaleStatus.Unpaid && (
                        <AppButton
                            disabled={!isHasSaleDetail}
                            onClick={() => showModalConfirmPay(props.sale!)}
                            translateCode={translateCodes.CHECK_OUT}
                            type='primary'
                            width={174}
                        />
                    )}
                </FlexBox>
            </>
        );
    }, [isHasSaleDetail, onUnpaidSale, props.sale, showModalConfirmCancel, showModalConfirmPay]);

    return (
        <Block className='sale-order-footer' height={props.height}>
            <Spacer type='padding' top={16} bottom={16} left={20} right={20}>
                <FlexBox justifyContent='space-between'>
                    <Text fontSize={18} fontWeight={700}>{t(translateCodes.TOTAL_AMOUNT)}</Text>
                    <Text fontSize={18} fontWeight={700}>{formatCurrency(totalAmount ?? 0)}</Text>
                </FlexBox>
                <Spacer type='padding' bottom={14} />
                <FlexBox justifyContent='space-between' alignItems='center'>
                    {renderActionSale(props.sale!)}
                </FlexBox>
            </Spacer>
        </Block>
    );
}

SaleOrderFooter.renderAction = (sale: GetDetailSaleResponse) => {
    if(sale.status === SaleStatus.Cancelled) {
        return <PrintButton sale={sale!} />;
    }

    if(sale.status === SaleStatus.Paid) {
        return <PrintButton sale={sale!} />;
    }
};
