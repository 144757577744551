import { dayjs } from '@esg/shared';
import { TimeBlockRepeatBy, TimeBlockRepeatType } from '@esg/business-resource-management';
import { TimeBlockFormValues } from '../Types';

export const getRepeatToDate = (values: TimeBlockFormValues) => {
    const { date, specificDate, occurrences, repeatBy, repeatType } = values;

    if (repeatBy === TimeBlockRepeatBy.NONE) {
        return date;
    }

    if (repeatType === TimeBlockRepeatType.NEVER) {
        const maxDate = new Date('12/31/9999');
        return maxDate.toISOString();
    }

    if (repeatType === TimeBlockRepeatType.AFTER) {
        if (repeatBy === TimeBlockRepeatBy.DAILY) {
            return dayjs(date).add(occurrences!, 'day').toISOString();
        }

        if (repeatBy === TimeBlockRepeatBy.WEEKLY) {
            return dayjs(date).add(occurrences!, 'week').toISOString();
        }

        if (repeatBy === TimeBlockRepeatBy.MONTHLY) {
            return dayjs(date).add(occurrences!, 'month').toISOString();
        }
    }

    return dayjs(specificDate).startOf('day').toISOString();
};
