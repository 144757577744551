import { PropsWithChildren } from 'react';
import { useDataModified } from './@share/hooks/useDataModified';
import { useDataFetched } from './@share/hooks/useDataFetched';

export const RealtimeHandler = ({ children }: PropsWithChildren) => {
    useDataFetched();
    useDataModified();
    
    return children;
};
