import { RealtimeKeys } from '@esg/shared/enum';

type RealtimeSubscribeArg = { key: string };

export const realtimeSubscribesArg: RealtimeSubscribeArg[] = [
    {
        key: RealtimeKeys.AppointmentNoShow,
    },
    {
        key: RealtimeKeys.AppointmentBooked,
    },
    {
        key: RealtimeKeys.AppointmentRefetch,
    },
];
