import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../config';
import { GetRoleDetailResponse } from './getRoleDetail';

interface DeleteRoleRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const deleteRole = httpUtil.createHttpRequestMeta<
    DeleteRoleRequest,
    GetRoleDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/roles/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
