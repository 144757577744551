import {
    AvatarName,
    Block,
    FlexBox,
    Spacer,
    Text
    , managementProfileState, 
    useLogout} from '@esg/ui';
import {
    Avatar,
    Dropdown,
    Image,
    MenuProps
} from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { mainLayoutTheme } from '../../@shared';

import { PROFILE } from '@/configs';

export function UserDropdown() {
    const [currentProfile] = useRecoilState(managementProfileState);
    const logout = useLogout();

    const items: MenuProps['items'] = useMemo(()=>
        [
            {
                key: '1',
                label: (
                    <Link className='user-dropdown-link' to={PROFILE}>
                        <Block width={228}>
                            <Spacer type='padding' top={5} bottom={5}>
                                <FlexBox>
                                    <Spacer right={14}>
                                        <Image
                                            src={mainLayoutTheme.viewProfileIcon}
                                            preview={false}
                                        />
                                    </Spacer>
                                    <Text fontSize={14} fontWeight={500}>
										View profile
                                    </Text>
                                </FlexBox>
                            </Spacer>
                        </Block>
                    </Link>
                ),
            },
            // {
            //     key: '2',
            //     label: (
            //         <Link className='user-dropdown-link' to={ORGANIZATION_MANAGEMENT_URL}>
            //             <Block width={228}>
            //                 <Spacer type='padding' top={5} bottom={5}>
            //                     <FlexBox>
            //                         <Spacer right={14}>
            //                             <Image
            //                                 src={mainLayoutTheme.salonManagement}
            //                                 preview={false}
            //                             />
            //                         </Spacer>
            //                         <Text fontSize={14} fontWeight={500}>
            // 							Salon Management
            //                         </Text>
            //                     </FlexBox>
            //                 </Spacer>
            //             </Block>
            //         </Link>
            //     ),
            // },
            {
                key: '3',
                label: (
                    <Block className='user-dropdown-link border-top' onClick={() => logout()}>
                        <Block width={228}>
                            <Spacer type='padding' top={5} bottom={5}>
                                <FlexBox>
                                    <Spacer right={14}>
                                        <Image src={mainLayoutTheme.logOutIcon} preview={false} />
                                    </Spacer>
                                    <Text fontSize={14} fontWeight={500}>
										Logout
                                    </Text>
                                </FlexBox>
                            </Spacer>
                        </Block>
                    </Block>
                ),
            },
        ]
    ,[logout]);

    return (
        <Dropdown menu={{ items }} placement='bottomRight'>
            <div className='user-button'>
                <FlexBox alignItems='center'>
                    <Spacer right={12}>
                        <FlexBox direction='column' alignItems='flex-end'>
                            <Text fontWeight={700} fontSize={14}>
                                {`${currentProfile!.firstName} ${currentProfile!.lastName}`}
                            </Text>
                            <Text color='#475467'>
                                {currentProfile!.role?.name}
                            </Text>
                        </FlexBox>
                    </Spacer>
                    {
                        currentProfile?.avatar ?
                            <Avatar src={currentProfile.avatar} shape='square' size={40}/>
                            :
                            <AvatarName fullName={currentProfile!.firstName} size={40}/>
                    }
                </FlexBox>
            </div>
        </Dropdown>
    );
}