import {useEffect} from 'react';
import { timeBlockEvents } from '@/event';
import { useSchedulerContext } from '@/pages/scheduler/@share/SchedulerContext';

export const useTimeBlockRefetchListener = () => {
    const { refetchTimeBlocks } = useSchedulerContext();
    useEffect(() => {
        const unListen = timeBlockEvents.refetch.listen(() => {
            refetchTimeBlocks();
        });
        return () => {
            unListen();
        };
    }, [refetchTimeBlocks]);
    
};