import {
    AppButton,
    Block,
    FlexBox,
    Text,
    Spacer,
    Translate,
    useHttpCommand,
    FormBase
} from '@esg/ui';
import { IconPen } from '@esg/ui/components/icons';
import React, { useMemo, useState } from 'react';
import { EmployeeGetDetailResponse, updateEmployeeShifts } from '@esg/business-resource-management';
import { EmployeeWorkingTimeForm } from '../../@share';
import { EmployeeWorkingTimeTable } from './employee-working-time';
import { translateCodes } from '@/locales';

interface EmployeeWorkingTimeProps {
    employee?: EmployeeGetDetailResponse;
    refetch: () => void
}
export const EmployeeWorkingTime = (props: EmployeeWorkingTimeProps) => {
    const { employee, refetch } = props;
    const [isEdit, setIsEdit] = useState(false);
    const { mutateAsync: updateShifts, isPending: isUpdating } = useHttpCommand(
        updateEmployeeShifts,
        {
            onSuccess: () => {
                refetch();
                setIsEdit(false);
            }
        }
    );

    const onSubmit = React.useCallback(
        async (values: EmployeeGetDetailResponse) => {
            await updateShifts({
                pathData: { id: employee?.id },
                body: {
                    id: values.id,
                    shifts: values.shifts
                },
            });
        },
        [employee?.id, updateShifts]
    );

    const defaultValues = useMemo(
        () =>
            ({
                ...employee,
                // s.date == null <==> fixed shift
                shifts: employee?.shifts.filter((s) => s.date === null),
            } as EmployeeGetDetailResponse),
        [employee]
    );

    return (
        <Block>
            <FormBase onSubmit={onSubmit} defaultValues={defaultValues}>
                <FlexBox gap={12} justifyContent='space-between'>
                    <Text
                        translateCode={translateCodes.WORKING_TIME}
                        fontWeight={700}
                        fontSize={20}
                    />
                    {isEdit ? (
                        <FlexBox gap={12} flex='none'>
                            <AppButton
                                translateCode={translateCodes.CANCEL}
                                onClick={() => setIsEdit(false)}
                            />
                            <AppButton
                                translateCode={translateCodes.SAVE}
                                type='primary'
                                htmlType='submit'
                                loading={isUpdating}
                            />
                        </FlexBox>
                    ) : (
                        <AppButton onClick={() => setIsEdit(true)}>
                            <FlexBox gap={6}>
                                <IconPen />
                                <Translate translateCode={translateCodes.EDIT} />
                            </FlexBox>
                        </AppButton>
                    )}
                </FlexBox>
                <Spacer size={16} />
                {isEdit ? <EmployeeWorkingTimeForm /> : <EmployeeWorkingTimeTable employee={defaultValues} />}
            </FormBase>
        </Block>
    );
};
