import React from 'react';
import { IconProps } from '../../Types';

export const IconRowRight = (props: IconProps) => {
    return (
        <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.5 15L12.5 10L7.5 5' stroke={props.color} strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};

IconRowRight.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#0C6FF3',
};
