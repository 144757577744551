import { FlexBox, FormLayoutModal, globalModalState, Spacer } from '@esg/ui';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useResetRecoilState } from 'recoil';
import { ShiftFormValues } from './Types';
import { ShiftDetail } from './children/ShiftDetail';
import { useShiftFormBusiness } from './@share/hooks';
import { ShiftFormItem } from './children/ShiftFormItem';
import { ShiftTimeBlocks } from './children/shift-time-blocks';
import { translateCodes } from '@/locales';

export const ShiftFormControl = () => {
    const hideShiftForm = useResetRecoilState(globalModalState);
    const { control, handleSubmit } = useFormContext<ShiftFormValues>();

    const { fields, remove, append } = useFieldArray({
        control: control,
        name: 'breakTimes',
    });

    const { onSubmit, loading } = useShiftFormBusiness();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='staff-shifts'>
            <FormLayoutModal
                useSubmitButton={true}
                isLoadingButton={loading}
                onCancel={hideShiftForm}
                okTextCode={translateCodes.SAVE}
            >
                <ShiftDetail append={append} />
                <Spacer bottom={16} />
                <FlexBox direction='column' gap={12}>
                    {fields.map((field, index) => (
                        <ShiftFormItem
                            key={field.id}
                            remove={remove}
                            fieldIndex={index}
                            fieldName={`breakTimes.${index}`}
                        />
                    ))}
                </FlexBox>
                <Spacer bottom={16} />
                <ShiftTimeBlocks />
            </FormLayoutModal>
        </form>
    );
};
