import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { GetDetailSaleResponse } from './getDetailSale';

interface CancelSaleRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        cancelReasonId?: string;
        cancelNote?: string;
    };
}

export const cancelSale = httpUtil.createHttpRequestMeta<
    CancelSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales/:id/canceled',
    method: 'PATCH',
    authentication: 'bearer',
});
