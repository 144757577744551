import { GetAllServiceResponseItem } from '@esg/business-management';
import {
    GetDetailFacilitiesResponse,
    ShiftGetDetailResponse,
    EmployeeGetDetailResponse,
    TimeOffGetDetailResponse,
} from '@esg/business-resource-management';
import { GetAllAppointmentSettingResponseItem } from '@esg/business-schedule';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { IWorkingTimeItem } from '@esg/ui';
import { Dayjs } from '@esg/shared';
import { ViewType } from '../Scheduler';
import { ScheduleTimeDisableValue } from '../Types';
import { useSchedulerResource } from './hooks/useSchedulerResource';

export interface ISchedulerContextProps {
	readonly refetchAppointment: () => void;
	readonly viewType: ViewType;
	readonly currentDate: string;
	readonly isAppointmentFetching: boolean;
}

interface ISchedulerContext extends ISchedulerContextProps {
	readonly loading: boolean;
	readonly refetchShifts: () => void;
	readonly refetchTimeBlocks: () => void;
	readonly shifts: ShiftGetDetailResponse[];
	readonly branchWorkingTime: [Dayjs, Dayjs];
	readonly branchBusinessHour: IWorkingTimeItem;
	readonly services: GetAllServiceResponseItem[];
	readonly refetchAppointmentSetting: () => void;
	readonly employees: EmployeeGetDetailResponse[];
	readonly facilities: GetDetailFacilitiesResponse[];
	readonly timeDisables: ScheduleTimeDisableValue[];
	readonly employeeTimeOffs: TimeOffGetDetailResponse[];
	readonly appointmentSetting?: GetAllAppointmentSettingResponseItem;
}

const SchedulerContext = createContext<ISchedulerContext>(null!);

export const SchedulerProvider = (
    props: PropsWithChildren<{ resources: ISchedulerContextProps }>
) => {
    const { children, resources } = props;

    const {
        shifts,
        services,
        employees,
        facilities,
        timeDisables,
        refetchShifts,
        isShiftFetching,
        employeeTimeOffs,
        refetchTimeBlocks,
        branchWorkingTime,
        isEmployeeFetching,
        branchBusinessHour,
        appointmentSetting,
        refetchAppointmentSetting,
    } = useSchedulerResource({ defaultDate: resources.currentDate });

    const values = useMemo<ISchedulerContext>(() => {
        return {
            ...resources,
            shifts,
            services,
            employees,
            facilities,
            refetchShifts,
            timeDisables,
            employeeTimeOffs,
            refetchTimeBlocks,
            branchWorkingTime,
            branchBusinessHour,
            appointmentSetting,
            refetchAppointmentSetting,
            loading: isEmployeeFetching || resources.isAppointmentFetching || isShiftFetching,
        };
    }, [
        shifts,
        services,
        employees,
        resources,
        facilities,
        timeDisables,
        refetchShifts,
        isShiftFetching,
        employeeTimeOffs,
        refetchTimeBlocks,
        branchWorkingTime,
        isEmployeeFetching,
        appointmentSetting,
        branchBusinessHour,
        refetchAppointmentSetting,
    ]);

    return <SchedulerContext.Provider value={values}>{children}</SchedulerContext.Provider>;
};

export const useSchedulerContext = () => {
    return useContext(SchedulerContext);
};
