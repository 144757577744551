import { GetDetailSaleResponse, getAllSale, updateSaleActive } from '@esg/business-pos';
import { FlexBox, Text, useHttpCommand, useHttpQuery } from '@esg/ui';
import { Avatar, notification } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { saleEvents } from '@/event/saleEvent';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';

interface SaleMinimizeProps {
    readonly onShowFormSale: (sale: GetDetailSaleResponse) => void;
    readonly isLoadingInitData: boolean;
}

export default function InitSaleMinimize(props: SaleMinimizeProps) {
    const {onShowFormSale, isLoadingInitData} = props;

    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();

    const { data: saleResponse, refetch } = useHttpQuery(getAllSale, {
        query: { isActive: 'true' }
    });

    const { mutateAsync: onUpdateSaleActive, isPending } = useHttpCommand(updateSaleActive);

    useEffect(() => {
        saleEvents.onRefetchSaleActive.listen(() => {
            refetch();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(isLoadingInitData) return;

        if(saleResponse) {
            const saleMinimize = saleResponse.items;
            saleMinimize.forEach(o=> {
                const nameSplit = o.customer?.fullName?.split(' ') ?? [];
                const nameAvatar = nameSplit?.length > 1 ? nameSplit[0]?.charAt(0) + nameSplit[1]?.charAt(0) : nameSplit[0]?.charAt(0);
                api.open({
                    key: o.id,
                    message: 
                        <FlexBox gap={16} alignItems='center'>
                            <Avatar
                                src={o.customer?.avatar}
                                icon={o.customer ? undefined : <UserOutlined /> }
                                style={{ backgroundColor: '#F2F4F7' }}
                            >
                                {o.customer ? nameAvatar : undefined}
                            </Avatar>
                            <Text maxWidth={130} maxLines={1} fontWeight={600}>
                                {o.customer?.fullName ?? t(translateCodes.WALK_IN)}
                            </Text>
                            <Text>{t(saleHelper.getTextStatus(o?.status))}</Text>
                        </FlexBox> ,
                    placement: 'bottomRight',
                    duration: null, 
                    onClick: () => onShowFormSale(o),
                    onClose: async () => {
                        if(isPending) return;
                        await onUpdateSaleActive({
                            pathData: {id: o.id},
                            body: {id: o.id, isActive: false}
                        });
                    }
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleResponse?.items, isLoadingInitData]);

    return (
        <>
            {contextHolder}
        </>
    );
}
