import React from 'react';
import { IconProps } from '../../Types';

export const IconRevert = (props: IconProps) => {
    return (
        <svg
            {...props}
            onClick={props.onClick}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M10.0657 1.875C6.07112 1.875 2.78727 4.9275 2.46709 8.81944H1.66664C1.41341 8.81944 1.1852 8.97225 1.08875 9.2064C0.992302 9.44055 1.04668 9.70976 1.22644 9.88812L2.62629 11.277C2.86996 11.5188 3.26302 11.5188 3.50669 11.277L4.90653 9.88812C5.0863 9.70976 5.14067 9.44055 5.04422 9.2064C4.94777 8.97225 4.71957 8.81944 4.46633 8.81944H3.72229C4.03872 5.62639 6.75395 3.125 10.0657 3.125C12.3736 3.125 14.3939 4.34037 15.5129 6.16066C15.6937 6.45471 16.0786 6.54654 16.3727 6.36576C16.6668 6.18499 16.7586 5.80006 16.5778 5.50601C15.2392 3.32856 12.8227 1.875 10.0657 1.875Z'
                fill={props.color} />
            <path d='M17.3676 8.72215C17.1241 8.4815 16.7323 8.4815 16.4889 8.72215L15.0837 10.111C14.9034 10.2892 14.8486 10.5586 14.9449 10.793C15.0412 11.0275 15.2696 11.1806 15.523 11.1806H16.2723C15.9546 14.3718 13.2299 16.875 9.90225 16.875C7.58351 16.875 5.55485 15.6586 4.43159 13.8384C4.25031 13.5447 3.86523 13.4535 3.57149 13.6348C3.27774 13.8161 3.18657 14.2012 3.36784 14.4949C4.71169 16.6725 7.13667 18.125 9.90225 18.125C13.9088 18.125 17.2062 15.0744 17.5275 11.1806H18.3334C18.5869 11.1806 18.8152 11.0275 18.9115 10.793C19.0078 10.5586 18.953 10.2892 18.7728 10.111L17.3676 8.72215Z'
                fill={props.color}/>
        </svg>
    );
};

IconRevert.defaultProps = {
    className: '',
    onClick: () => {},
    color: 'red',
};
