import { HttpRequestData, httpUtil } from '@esg/framework';
import { AppointmentStatus } from '@esg/shared';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { env } from '../../configs';
import { AppointmentService } from '.';
export interface AppointmentUpdateRequest extends HttpRequestData {
	body: {
		readonly id: string;
		readonly customerId: string;
		readonly numberOfGuest: number;
		readonly appointmentDate: string;
		readonly notes?: string;
		readonly status: AppointmentStatus;
		readonly customer: GetCustomerDetailResponse;
		readonly appointmentServices: AppointmentService [];
	};
}

export const updateAppointment = httpUtil.createHttpRequestMeta<AppointmentUpdateRequest, void>({
    baseUrl: env.apiUrlBase,
    method: 'PATCH',
    path: '/api/v1/appointments/:id',
    authentication: 'bearer',
});
