import {
    getCoordinates,
    GetCoordinatesResponse,
    getTimezoneByCoordinate,
    GetTimezoneByCoordinateResponse,
} from '@esg/business-setting';
import { env } from '../configs';

export const getLocationByAddress = async (address: string) => {
    const response = await fetch(
        `${getCoordinates.baseUrl}${getCoordinates.path}?address=${address}&key=${env.GOOGLE_MAPS_API_KEY}`
    );

    if (!response.ok) {
        throw new Error('Failed to get location');
    }

    return response.json() as Promise<GetCoordinatesResponse>;
};

export const getTimeZoneByAddress = async (address: string) => {
    const coordinates = await getLocationByAddress(address);

    const lat = coordinates.results[0].geometry.location.lat;
    const lng = coordinates.results[0].geometry.location.lng;

    const response = await fetch(
        `${getTimezoneByCoordinate.baseUrl}${getTimezoneByCoordinate.path}?location=${lat},${lng}&key=${env.GOOGLE_MAPS_API_KEY}&timestamp=1331161200`
    );

    if (!response.ok) {
        throw new Error('Failed to get timezone');
    }

    return response.json() as Promise<GetTimezoneByCoordinateResponse>;
};
