import { Block, ListNameColumn, TableCustom, Text, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import React, { useMemo } from 'react';
import { AppointmentStatus, Dayjs } from '@esg/shared';
import { TableColumnsType, Tag } from 'antd';
import { flatten } from 'lodash';
import { GetDetailSaleResponse, SaleDetailGetAllResponseItem } from '@esg/business-pos';
import { getClientDetailReport } from '@esg/business-report';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { saleHelper } from '@/helpers';
import { SaleCustomerColumn } from '@/pages/fast-pos/sale/children/SaleCustomerColumn';
import SaleEmployeeColumn from '@/pages/fast-pos/sale/children/SaleEmployeeColumn';

interface ClientDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly appointmentStatus?: AppointmentStatus;
    readonly customerId?: string;
}

export function ClientDetailTable(props: ClientDetailTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency, formatDate } = useLocalesFormat();
    const [searchParams, setSearchParams] = usePagination({
        pageSize: '10',
        orderBy: 'code'
    });

    const { data: clientDetailReport, isFetching } = useHttpQuery(getClientDetailReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            status: props.appointmentStatus,
            customerId: props.customerId,
            ...searchParams,
        },
    });
    
    const columns: TableColumnsType<GetDetailSaleResponse> = useMemo(()=> ([
        {
            title: 'ID',
            dataIndex: 'SaleCode',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.orderDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, orderDirection: order });
                }
            }),
            render: (_, record) => record.code
        },
        {
            title: t(translateCodes.CLIENT),
            dataIndex: 'customer',
            render: (_, record) => {
                return <SaleCustomerColumn record={record.customer} />;
            },
        },
        {
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'saleDetails',
            render: (saleDetails: SaleDetailGetAllResponseItem[]) => <SaleEmployeeColumn saleDetails={saleDetails} />,
        },
        {
            title: t(translateCodes.TIME),
            dataIndex: 'date',
            render: (_, record) => {
                const checkoutAtTime = formatDate(record?.checkoutAt, 'hh:mm a');
                const createdTime = formatDate(record?.date, 'hh:mm a');
                const createdDate = formatDate(record?.date);                
                return (
                    <Block>
                        <p>{createdTime} - {record?.checkoutAt ? checkoutAtTime : ''}</p>
                        <p>{createdDate}</p>
                    </Block>
                );
            },
        },
        {

            title: t(translateCodes.SERVICES_TIME),
            dataIndex: 'saleDetails',
            render: (saleDetails?: SaleDetailGetAllResponseItem[]) => {
                const listService = flatten(saleDetails?.map(a => a.service)).filter(x => !!x);
                const names = listService.map(o => o?.name);
                const namesUnique = [...new Set(names)];
                return <ListNameColumn names={namesUnique} limit={2} />;
            },
        },
        {
            title: t(translateCodes.TIP),
            dataIndex: 'tip',
            render: (_, record) => {
                return record.tip;
            },
        },
        {
            title: t(translateCodes.DISCOUNT),
            dataIndex: 'Discount',
            render: (_, record) => formatCurrency(saleHelper.calcDiscountAmount(record.saleDetails ?? [])),
        },
        {
            title: t(translateCodes.TOTAL),
            dataIndex: 'totalAmount',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.totalAmount)}
                    </Text>
                );
            },
        },
    ]), [ formatCurrency, formatDate, searchParams, setSearchParams, t]);

    return (
        <Block width={1300}>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                pageInfo={clientDetailReport?.meta}
                titleTableTransCode={translateCodes.BILL_LIST}
                placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                showHeader={true}
                loading={isFetching}
                columns={columns}
                dataSource={clientDetailReport?.items}
                subTitleTable={<Tag color='processing'>{`${clientDetailReport?.meta.totalItems} bills`}</Tag>}
            />
        </Block>
    );
}
