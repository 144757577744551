import { managementApis } from '@esg/business-management';
import { FormLayout ,
    FormBase,
    useHttpCommand,
} from '@esg/ui';
import React from 'react';
import { viewRequests } from '@esg/framework';

import { useTranslation } from 'react-i18next';
import { CreateServiceFormStep } from '@esg/shared/enum/serviceEnum';
import { FacilityService, InfoServiceForm, EmployeeAllocate } from './steps';

import { translateCodes } from '@/locales';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stepsFormService =(t: any) =>[
    {
        title: 'General Information',
        label: t(translateCodes.GENERAL_INFORMATION),
        value: CreateServiceFormStep.Info,
    },
    {
        title: 'Employee Allocate',
        label: t(translateCodes.EMPLOYEE_ALLOCATE),
        value: CreateServiceFormStep.EmployeeAllocate,
    },
    {
        title: 'Facility',
        label: t(translateCodes.FACILITY),
        value: CreateServiceFormStep.Facility,
    },
];

export interface EmployeeServiceValue {
    readonly id?: string;
    readonly employeeId?: string;
}
export interface ServiceFacilityValue {
    readonly id?: string;
    readonly facilityId?: string;
}
interface ServiceValues {
    readonly id?: string;
    readonly name: string;
    readonly serviceCategoryId: string;
    readonly duration?: number;
    readonly durationUnit?: number;
    readonly description?: string;
    readonly price: number;
    readonly taxSettingId?: string;
    readonly isAllowOnlineBooking: boolean;
    readonly employeeServices?: EmployeeServiceValue[];
    readonly serviceFacilities?: ServiceFacilityValue[];
}

interface ServiceFormProps {
    readonly onDelete?: () => void;
    readonly refetch: () => void;
    readonly resetDrawerState: () => void;
    readonly defaultValues?: ServiceValues;
}

export const ServiceForm = (props: ServiceFormProps) => {
    const {t} = useTranslation();

    const isUpdate = !!props.defaultValues?.id;

    const { mutateAsync: createService, isPending: isCreating } = useHttpCommand(managementApis.createService);

    const { mutateAsync: updateService, isPending: isUpdating } = useHttpCommand(managementApis.updateService);

    const onSubmit = React.useCallback(
        async (values: ServiceValues) => {
            if (isUpdate) {
                return await updateService({
                    pathData: { id: props.defaultValues?.id },
                    body: values,
                },{
                    onSuccess(_) {
                        props.refetch();
                        props.resetDrawerState();
                    },
                });
            }

            await createService({ body: values },{
                onSuccess(_) {
                    viewRequests.showNotification.send({
                        type: 'success',
                        message: t(translateCodes.NM_PT_01),
                    });
                    props.refetch(); 
                    props.resetDrawerState();
                },
            });
        },
        [createService, isUpdate, props, t, updateService]
    );

    return (
        <FormBase onSubmit={onSubmit} defaultValues={{
            ...props.defaultValues,
            employeeServices: props.defaultValues?.employeeServices ?? [],
            serviceFacilities: props.defaultValues?.serviceFacilities ?? [],
            taxSettingId: props.defaultValues?.taxSettingId,
        }}>
            <FormLayout
                onDeleted={props.onDelete}
                uxMode={isUpdate ? 'tabs' : 'steps'}
                itemsStep={stepsFormService(t)}
                labelSubmitCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                isLoadingButton={isCreating || isUpdating}
            >
                {(step) => (
                    <>
                        {step == CreateServiceFormStep.Info && <InfoServiceForm />}
                        {step == CreateServiceFormStep.EmployeeAllocate && <EmployeeAllocate />}
                        {step == CreateServiceFormStep.Facility && <FacilityService />}
                    </>
                )}
            </FormLayout>
        </FormBase>
    );
};

ServiceForm.defaultProps = {
    defaultValues: {
        isAllowOnlineBooking: true,
    },
};

