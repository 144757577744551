import {Dayjs} from 'dayjs';  
import { SchedulerItem } from './SchedulerTypes';

export const createSubRows = (rowItems: SchedulerItem[]) => {
    function checkOverlap(a: SchedulerItem, b: SchedulerItem) {
        return a.startTime.isBefore(b.endTime) && a.endTime.isAfter(b.startTime);
    }

    function removeOverlappingItems(items: SchedulerItem[]) {
        const result: SchedulerItem[] = [];
        for (const item of items) {
            if (!result.some((i) => checkOverlap(i, item))) {
                result.push(item);
            }
        }
        return result;
    }

    const itemWithOverlapLevels: { item: SchedulerItem, level: number }[] = [];

    let _rowItems = [...rowItems];
    let level = 0;
    while (_rowItems.length) {
        const nonOverlappingItems = removeOverlappingItems(_rowItems);
        itemWithOverlapLevels.push(...nonOverlappingItems.map((i) => ({ item: i, level: level })));

        const overlappingItems = _rowItems.filter((i) => !nonOverlappingItems.includes(i));
        _rowItems = overlappingItems;
        level++;
    }

    const subRows: SchedulerItem[][] = [];
    for (const { item, level } of itemWithOverlapLevels) {
        if (!subRows[level]) {
            subRows[level] = [];
        }
        subRows[level].push(item);
    }
    return subRows;
};

export const getTimeClick = ({ 
    timeCells, 
    currentPosition, 
    baseColumnWidth 
} : { 
    timeCells: Dayjs[], 
    currentPosition: number, 
    baseColumnWidth: number
}) => {
    const timeCellsWithPosition = timeCells.map((timeCell, index) => ({
        timeCell,
        position: (index + 1) * baseColumnWidth,
    }));

    const result = timeCellsWithPosition.find(
        (timeCellWithPosition) => timeCellWithPosition.position > currentPosition
    );

    return result!.timeCell.toISOString();
};