import { DatePickerField, FlexBox, FormBase, FormModalFilter, SelectFieldCheckBox, useHttpQuery } from '@esg/ui';
import React from 'react';
import { resourceApis } from '@esg/business-resource-management';
import { managementApis } from '@esg/business-management';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useTranslation } from 'react-i18next';
import { FilterSaleParams } from '../DailySaleManagement';
import { EmployeeSelectField } from '@/components/form/employee/children/employee-select/EmployeeSelectField';
import { translateCodes } from '@/locales';

interface FilterSaleFormProps {
    readonly defaultValues?: FilterSaleParams;
    readonly handleSubmit?: (value: FilterSaleParams) => void;
    readonly onCancel?: () => void;
}

export default function FilterSaleForm(props: FilterSaleFormProps) {
    const { t } = useTranslation();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const {data: serviceCategories} = useHttpQuery(managementApis.getAllServiceCategory);
    const {data: employees} = useHttpQuery(resourceApis.getAllEmployees);

    const serviceOptions = serviceCategories?.items.filter(o=> o.services.length > 0).map((c) => ({
        label: c.name,
        options: c.services.map((s) => ({
            label: s.name,
            value: s.id,
        }))
    }));

    return (
        <FormBase onSubmit={props.handleSubmit} defaultValues={props.defaultValues}>
            {(form) => (
                <FormModalFilter 
                    onCancel={props.onCancel}
                    onReset={() => {
                        form.reset({
                            date: null,
                            employeeIds: [],
                            serviceIds: []
                        });
                    }}
                >
                    <FlexBox direction='column' gap={16}>
                        <EmployeeSelectField
                            allEmployee
                            mode='multiple'
                            type='default'
                            name='employeeIds'
                            maxCountAvatar={4}
                            label={translateCodes.EMPLOYEES}
                            value={form.watch('employeeIds')}
                            handleChangedEmployee={(value) => form.setValue('employeeIds', value)} 
                            employeeOptions={employees?.items?.map(o=> ({
                                ...o,
                                label: o.fullName,
                                value: o.id,
                            }))}
                        />
                        <DatePickerField
                            name='date'
                            format={ currentLocale?.format }
                            label={translateCodes.DATE}
                        />
                        <SelectFieldCheckBox
                            filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                            name='serviceIds'
                            label={translateCodes.SERVICES}
                            mode='multiple'
                            placeholder={translateCodes.SELECT_SERVICE}
                            options={serviceOptions}
                            placeholderCode={translateCodes.SELECT_SERVICE}
                            allData={`${t(translateCodes.ALL)} ${t(translateCodes.SERVICES)}`}
                        />
                    </FlexBox>
                </FormModalFilter>
            )}
        </FormBase>
    );
}
