import { PlusCircleFilled } from '@ant-design/icons';
import { AppButton, ColumnActions, Header, Paragraph, Spacer, TableCustom, Text, globalDrawerState, globalModalState, useHttpCommand, useHttpQuery , managementProfileState, defaultShifts } from '@esg/ui';
import React, { useCallback } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { BranchGetAllResponseItem, getAllBranches } from '@esg/business-organization/api/branch/getAllBranches';
import { deleteBranch } from '@esg/business-organization/api/branch/deleteBranch';
import { translateCodes } from '@/locales';
import { PageLayout } from '@/layouts';
import { BranchForm } from '@/components/form/branch-form/BranchForm';

export const BranchManagement = () => {
    const { t } = useTranslation();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const [currentProfile] = useRecoilState(managementProfileState);
    const { data: branches, refetch } = useHttpQuery(getAllBranches);

    const { mutateAsync } = useHttpCommand(deleteBranch, {
        onSuccess() {
            resetModalState();
            refetch();
        },
    });

    const handleEdit = useCallback(
        async (branch: BranchGetAllResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: '',
                content: <BranchForm
                    onSuccess={() => {
                        refetch();
                        resetDrawerState();
                    }}
                    defaultValues={branch}
                />,
            });
        },
        [refetch, resetDrawerState, setDrawerState]
    );

    const handleDelete = useCallback(
        async (branch: BranchGetAllResponseItem) => {
            setModalState({
                isOpen: true,
                showModalDelete: true,
                content: (
                    <>
                        <Header level={3} translateCode={translateCodes.DELETE_BRANCH} />
                        <Spacer bottom='xs' />
                        <Paragraph>
                            Are you sure you want to delete branch:&nbsp;
                            <Text fontWeight='bold'>{branch.name}</Text>
                        </Paragraph>
                    </>
                ),
                onOk: () => {
                    mutateAsync({
                        pathData: {
                            id: branch.id,
                        },
                    });
                },
            });
        },
        [mutateAsync, setModalState]
    );

    const branchColumn: ColumnsType<BranchGetAllResponseItem> = [
        {
            width: '10%',
            title: t(translateCodes.NAME),
            dataIndex: 'name',
            key: '',
        },
        {
            width: '10%',
            title: t(translateCodes.BRANCH_COUNTRY),
            dataIndex: 'country',
            key: '',
        },
        {
            width: '20%',
            title: t(translateCodes.BRANCH_TIMEZONE),
            dataIndex: 'timezone',
            key: '',
        },
        {
            width: '10%',
            title: t(translateCodes.BRANCH_CITY),
            dataIndex: 'city',
            key: '',
        },
        {
            width: '20%',
            title: t(translateCodes.BRANCH_ADDRESS),
            dataIndex: 'address',
            key: '',
        },
        {
            width: '10%',
            title: t(translateCodes.BRANCH_TAX),
            dataIndex: 'tax',
            key: '',
        },
        {
            width: '10%',
            title: t(translateCodes.BRANCH_CURRENCY),
            dataIndex: 'currency',
            key: '',
        },
        {
            title: t(translateCodes.ACTION),
            key: 'name',
            render: (_, record) => {
                if (currentProfile?.branch.id == record.id) {
                    return;
                } else {
                    return (
                        <ColumnActions
                            onClickEdit={() => handleEdit(record)}
                            onClickDelete={() => handleDelete(record)}
                        />
                    );
                }
            },
        },
    ];
    
    return (
        <PageLayout
            pageTitleTransCode={translateCodes.BRANCH_MANAGEMENT}
            rightHeaderPage={
                <AppButton
                    type='primary'
                    translateCode={translateCodes.BRANCH_CREATE}
                    onClick={() => {
                        setDrawerState({
                            isOpen: true,
                            titleTransCode: t(translateCodes.BRANCH_CREATE),
                            content: <BranchForm 
                                onSuccess={() => {
                                    refetch();
                                    resetDrawerState();
                                }}
                                defaultValues={{
                                    businessHour: defaultShifts
                                }}
                            />,
                        });
                    }}
                    icon={<PlusCircleFilled />}
                />
            }
        >
            <TableCustom
                titleTableTransCode={translateCodes.BRANCH_LIST}
                placeholderSearchTransCode={translateCodes.SEARCH_BRANCH}
                columns={branchColumn}
                dataSource={branches?.items}
            />
        </PageLayout>
    );
};
