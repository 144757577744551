import { FormLayout, WorkingTime, Text, Block, FormBase, managementProfileState, useHttpCommand } from '@esg/ui';
import { notification } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { CreateBranchFormStep } from '@esg/shared';
import { branchApis, BranchGetAllResponseItem } from '@esg/business-organization';
import { BranchGeneralFormForm } from './children/BranchGeneralForm';
import { translateCodes } from '@/locales';
import './BranchForm.scss';

interface BranchFormProps {
    readonly defaultValues?: Partial<BranchGetAllResponseItem>;
    readonly onSuccess?: () => void;
}

export const BranchForm = (props: BranchFormProps) => {
    const { t } = useTranslation();
    const [currentProfile] = useRecoilState(managementProfileState);
    const isUpdate = !!props.defaultValues?.id;

    const { mutateAsync: createBranchMutate, isPending: isLoadingCreate } = useHttpCommand(
        branchApis.createBranch,
        {
            onSuccess() {
                props.onSuccess?.();
                notification.success({
                    message: t(translateCodes.BRANCH_CREATE_SUCCESS),
                    description: t(translateCodes.BRANCH_CREATE_DESCRIPTION),
                    placement: 'bottomLeft'
                });
            },
            onError() {
                notification.error({
                    message: t(translateCodes.BRANCH_CREATE_FAILURE),
                    placement: 'bottomLeft'
                });
            }
        }
    );

    const { mutateAsync: updateBranchMutate, isPending: isLoadingUpdate } = useHttpCommand(
        branchApis.updateBranch,
        {
            onSuccess() {
                props.onSuccess?.();
            },
            onError() {
                notification.error({
                    message: t(translateCodes.BRANCH_UPDATE_FAILURE),
                    placement: 'bottomLeft'
                });
            }
        }
    );

    const handleSubmit = useCallback((data: BranchGetAllResponseItem) => {
        if (isUpdate) {
            return updateBranchMutate({
                pathData: { id: props.defaultValues?.id },
                body: data,
            });
        }

        return createBranchMutate({ body: data });
    }, [createBranchMutate, updateBranchMutate, isUpdate, props.defaultValues?.id]);

    const stepsFormBranch = [
        {
            title: t(translateCodes.BRANCH_INFORMATION),
            label: t(translateCodes.BRANCH_INFORMATION),
            value: CreateBranchFormStep.Info,
        },
        {
            title: t(translateCodes.BRANCH_WORKING_TIME),
            label: t(translateCodes.BRANCH_WORKING_TIME),
            value: CreateBranchFormStep.WorkingTime,
        }
    ];
    
    return (
        <FormBase 
            onSubmit={handleSubmit} 
            defaultValues={{
                ...props.defaultValues,
                languages: currentProfile?.branch.languages ?? 'en',
            }}
        >
            {(form) => (
                <FormLayout
                    itemsStep={stepsFormBranch}
                    width={900}
                    labelSubmitCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                    uxMode={isUpdate ? 'tabs' : 'steps'}
                    isLoadingButton={isLoadingCreate || isLoadingUpdate}
                    className='branchSettingForm'
                > 
                    {(step) => (
                        <>
                            {step == CreateBranchFormStep.Info && <BranchGeneralFormForm />}
                            {step == CreateBranchFormStep.WorkingTime && (
                                <>
                                    <Text className='workingTimeTitle'>Business Hour<span style={{ color: '#ff0000'}}> *</span></Text>
                                    <Block className='workingTimeTable'>
                                        <WorkingTime
                                            name='businessHour'
                                            control={form.control}
                                            watch={form.watch}
                                            useAddBreakTime={false}
                                        />
                                    </Block>
                                </>
                            )}
                        </>
                    )}
                </FormLayout>
            )}
        </FormBase>
    );
};
