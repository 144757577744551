import { Spacer } from '@esg/ui';
import { Segmented } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CreateUserButton from './users/CreateUserButton';
import CreateRoleButton from './roles/CreateRoleButton';
import { RoleTable } from './roles/RoleTable';
import { UserTable } from './users/UserTable';
import { translateCodes } from '@/locales';
import { PageLayout } from '@/layouts';

enum UserRoleTabs {
    User,
    Role,
}

export default function UserRole() {
    const {t} = useTranslation();
    const [activeTabs, setActiveTabs] = React.useState<UserRoleTabs>(UserRoleTabs.User);

    const optionsSegmented = useMemo(() => [
        {
            value: UserRoleTabs.User,
            label: <Spacer width={130}>{t(translateCodes.USER)}</Spacer>
        },
        {
            value: UserRoleTabs.Role,
            label: <Spacer width={130}>{t(translateCodes.ROLE)}</Spacer>
        }
    ], [t]);

    return (
        <PageLayout 
            pageTitleTransCode={translateCodes.USER_AND_ROLE}
            middleHeaderPage = {
                <Segmented
                    size='large'
                    value={activeTabs}
                    options={optionsSegmented}
                    onChange={setActiveTabs}
                />
            }
            rightHeaderPage = {
                activeTabs === UserRoleTabs.User ? <CreateUserButton /> : <CreateRoleButton />
            }
        >
            <>
                {activeTabs === UserRoleTabs.User && <UserTable />}
                {activeTabs === UserRoleTabs.Role && <RoleTable />}
            </>
        </PageLayout>
    );
}
