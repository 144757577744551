import { GetEmployeeAppointmentReportItem, getEmployeesAppointmentReport } from '@esg/business-report';
import { AppAvatar, FlexBox, TableCustom, Text, globalDrawerState, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import AppointmentDetailTable from './AppointmentDetailTable';
import { translateCodes } from '@/locales';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface AppointmentEmployeeTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AppointmentEmployeeTable( props: AppointmentEmployeeTableProps) {
    const { dateRange } = props;
    const { t } = useTranslation();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);
    const { formatCurrency } = useLocalesFormat();

    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeEmployee: '10',
        orderByEmployee: 'id',
    },);
    
    const {data: servicesAppointment, isFetching} = useHttpQuery(getEmployeesAppointmentReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            search: searchParams.searchByEmployee,
            orderBy: searchParams.orderByEmployee,
            orderDirection: searchParams.employeeDirection,
            page: searchParams.pageEmployee,
            pageSize: searchParams.pageSizeEmployee,
            ...searchParams
        },
    });

    const viewDetail = (employeeId: string) => {
        setDrawerGlobalState({
            titleTransCode: 'View Detail',
            isOpen: true,
            hideHeader: true,
            content: (
                <AppointmentDetailTable
                    dateRange={dateRange}
                    employeeIds={[employeeId]}
                />
            ),
            footer: null,
        });
    };

    const columns: TableColumnsType<GetEmployeeAppointmentReportItem> = [
        {
            key: 'employee',
            title: 'Employees',
            dataIndex: 'employee',
            render: (_, value) => {
                return (
                    <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                        <AppAvatar
                            size='md'
                            data={value.employee}
                        />
                        <Text fontWeight='bold'>{value.employee.fullName}</Text>
                    </FlexBox>
                );
            },
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.employeeDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, employeeDirection: order });
                }
            }),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            render: (_, record) =>{
                return formatCurrency(record.revenue);
            }
        },
        {
            title: 'Links',
            key: 'links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={()=>viewDetail(record.id)}>View Detail</Tag>
                );
            }
        },
    ];

    return (
        <TableCustom
            titleTableTransCode='Services employee'
            loading={isFetching}
            columns={columns}
            dataSource={servicesAppointment?.items}
            pageInfo={servicesAppointment?.meta}
            placeholderSearchTransCode={`${t(translateCodes.SEARCH)} ${t(translateCodes.EMPLOYEE)}`}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByEmployee: value })
            }
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, pageEmployee: page.toString() })
            }
        />
    );
}
