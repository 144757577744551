import { useCallback, useEffect } from 'react';
import dayjsLib, { Dayjs } from 'dayjs';
import { useRecoilState } from 'recoil';
import { setTimezone, dayjs } from '@esg/shared';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localesArgument from '@esg/shared/json/localesArgument.json';
import { currentLocaleState } from '../state/currentLocale';

dayjsLib.extend(utc);
dayjsLib.extend(timezone);

const currencyDefault = 'USD';
const localesDefault: string[] = ['en-US'];
const timezoneDefault = dayjsLib.tz.guess();

export const useLocalesFormat = () => {
    const [currentLocale] = useRecoilState(currentLocaleState);

    const locale = localesArgument.find((c) => c.country == currentLocale?.country)?.locales?.replace(/\s/g, '').split(',') || localesDefault;
    const currency = currentLocale?.currency;
    const timezone = currentLocale?.timezone || timezoneDefault;
    const defaultFormat= currentLocale?.format;

    useEffect(()=>{
        setTimezone(timezone);
    }, [timezone]);

    const formatDate = useCallback((date: string | Dayjs, customFormat = defaultFormat) => {
        return dayjs(date).tz(timezone).format(customFormat);
    }, [defaultFormat, timezone]);

    const formatCurrency = useCallback((value: number) => {
        return value.toLocaleString(locale, {
            style: 'currency',
            currency: currency || currencyDefault,
        });
    }, [currency, locale]);

    const formatCurrencySymbol = useCallback((CustomCountry?: string, customCurrency?: string) => {
        
        return (0).toLocaleString(CustomCountry && locale, { 
            style: 'currency', 
            currency: customCurrency ? customCurrency : (currency || currencyDefault), 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0 
        }).replace(/\d/g, '').trim();
    }, [currency, locale]);
    
    return {
        formatDate,
        formatCurrency,
        formatCurrencySymbol
    };
};