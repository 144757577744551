import { Block, FlexBox, InputNumberFieldCustom, Spacer, SwitchField, Text } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export function DoubleAppointmentEnabled() {
    const { watch } = useFormContext();
    const doubleAppointmentEnabledValue = watch('doubleAppointmentEnabled');
    return (
        <Card>
            <FlexBox preset='column-start'>
                <Text fontSize={16} fontWeight={700}>Double booking</Text>
                <Spacer type='margin' bottom={10} />
                <FlexBox preset='column-start'>
                    <SwitchField
                        directionLabel='row-reverse'
                        description='Enable Double Booking'
                        name='doubleAppointmentEnabled'
                    />
                    <Spacer type='margin' right={8} />
                    {doubleAppointmentEnabledValue == true && <FlexBox>
                        <Block width={60} />
                        <FlexBox preset='column-start'>
                            <Spacer type='margin' bottom={15} />
                            <Text fontSize={14}> Maximum double bookings per time slot</Text>
                            <Spacer type='margin' bottom={5} />
                            <InputNumberFieldCustom name='doubleAppointmentMaxSlot' />
                        </FlexBox>
                    </FlexBox>}
                </FlexBox>
                <Spacer type='margin' bottom={8} />
            </FlexBox>
        </Card>
    );
}
