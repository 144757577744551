import { TimeOffType } from './api';

export const timeOffTypeOptions = [
    {
        label: 'Annual leave',
        value: TimeOffType.AnnualLeave,
    },
    {
        label: 'Sick leave',
        value: TimeOffType.SickLeave,
    },
    {
        label: 'Training',
        value: TimeOffType.Training,
    },
    {
        label: 'No show',
        value: TimeOffType.NoShow,
    },
    {
        label: 'Late for work',
        value: TimeOffType.LateForWork,
    },
    {
        label: 'Early leave',
        value: TimeOffType.EarlyLeave,
    },
    {
        label: 'Other absence reason',
        value: TimeOffType.Other,
    }
];

export const defaultFormatTime = 'YYYY-MM-DDTHH:mm:ss';