import { Text, FlexBox, useLocalesFormat, useHttpQuery, IconPerson, IconEditPen, IconTrash, IconArrowUpClient, IconArrowDownClient, globalModalState, Spacer, Paragraph, useHttpCommand } from '@esg/ui';
import { Card } from 'antd';
import React, { useCallback } from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { GetOverviewClientDetailResponse } from '@esg/business-report/api/client-detail-report';
import './CustomerDetailTable.scss';
import { deleteCustomerNote, GetAllCustomerNoteResponseItem, GetCustomerNoteDetailResponse } from '@esg/business-crm/api/customer-note';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { getAllUser } from '@esg/business-account';
import { translateCodes } from '@/locales/translateCodes';
import CustomerAddNoteForm from '@/components/form/customer/CustomerAddNoteForm';

interface CustomerNoteDetailProps {
    customer?: GetCustomerDetailResponse;
    loading?: boolean;
    reportOverview?: GetOverviewClientDetailResponse
    customerNotes: GetAllCustomerNoteResponseItem[];
    refetchCustomerNotes: () => void;
}

export function CustomerNoteDetail({ customer, loading, customerNotes, refetchCustomerNotes }: CustomerNoteDetailProps) {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [isShowAllNotes, setIsShowAllNotes] = React.useState(true);

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const { data: usersResponse } = useHttpQuery(getAllUser, {});

    const CustomerNoteDeleteConfirm = () => {
        return (
            <Spacer top='xs'>
                <Text fontWeight='bold' translateCode={translateCodes.DELETE_NOTE} />
                <Spacer bottom='xs' />
                <Paragraph>
                    <Text translateCode={translateCodes.DELETE_CUSTOMER_NOTE_MSG} />
                </Paragraph>
            </Spacer>
        );
    };

    const { mutateAsync } = useHttpCommand(deleteCustomerNote);

    const onDelete = React.useCallback(
        async (id: string) => {
            await mutateAsync(
                { pathData: { id: id } },
                {
                    onSuccess(_) {
                        resetGlobalModal();
                        refetchCustomerNotes();
                    },
                }
            );
        },
        [mutateAsync, resetGlobalModal, refetchCustomerNotes]
    );

    const handleDelete = React.useCallback(
        (customerNoteId: string) => {
            setGlobalModal({
                content: (
                    <CustomerNoteDeleteConfirm />
                ),
                isOpen: true,
                onOk: () => {
                    onDelete(customerNoteId);
                },
                showModalDelete: true,
            });
        },
        [onDelete, setGlobalModal]
    );

    const showModalAddNote = useCallback((note?: GetCustomerNoteDetailResponse) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.ADD_NOTE,
            content: <CustomerAddNoteForm
                note={note}
                customerId={customer!.id}
                onSuccess={() => {
                    resetGlobalModal();
                    refetchCustomerNotes();
                }}
                onCancel={() => {
                    resetGlobalModal();
                }}
            />
        });
    }, [customer, globalModal, refetchCustomerNotes, resetGlobalModal, setGlobalModal]);

    return (
        <Card className='customer-detail-info' loading={loading} style={{ borderRadius: 16 }} >
            <a onClick={() => {
                setIsShowAllNotes(!isShowAllNotes);
            }}>
                <FlexBox alignItems='center' gap={8} flex='none' width='100%'>
                    {isShowAllNotes ? <IconArrowUpClient color='#98A2B3' /> : <IconArrowDownClient color='#98A2B3' />}
                    <Text fontSize={14} fontWeight='bold'>Notes</Text>
                </FlexBox>
            </a>
            {isShowAllNotes && <div className='custom-scroll' style={{ display: 'flex', gap: 12, flexWrap: 'wrap', maxHeight: '430px', overflowY: 'auto' }}   >
                {
                    customerNotes?.map((o, i) => {
                        const user = usersResponse?.items?.slice(0, 3).find(x => x.id === o.creatorId);
                        return (
                            <Card hoverable key={i} className='hoverable-card' loading={loading} style={{ borderRadius: 16, width: 244, position: 'relative', }} >
                                <FlexBox direction='column' gap={8} width='100%' flex='none'>
                                    <Text fontWeight='bold' >{formatDate(o.creationTime!, `${currentLocale?.format}, HH:mm`)}</Text>
                                    <span style={{ fontSize: 12 }} color='#667085' >{o.value}</span>
                                    <FlexBox gap={4} justifyContent='space-between'>
                                        <FlexBox alignItems='center'>
                                            <IconPerson />
                                            <Text >{user?.fullName}</Text>
                                        </FlexBox>
                                        <div className='hover-actions'>
                                            <IconEditPen onClick={() => showModalAddNote(o)} />
                                            <IconTrash onClick={() => handleDelete(o.id)} />
                                        </div>
                                    </FlexBox>
                                </FlexBox>
                            </Card>
                        );
                    })
                }
            </div>
            }
        </Card>
    );
}
