import { ConnectTokenResponse } from '@esg/business-account';
import { LoginState } from '@esg/framework';

export const convertOpenIdResponse = (response: ConnectTokenResponse): LoginState => {
    return {
        token: response.access_token,
        expiresAt: Date.now() + response.expires_in * 1000,
        tokenType: response.token_type,
        idToken: response.id_token,
        refreshToken: response.refresh_token,
    };
};
