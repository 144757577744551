import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { ProductCapacityType, ProductUnitType } from '@esg/shared/enum';
import { env } from '../../configs';

interface GetAllProductRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

interface ProductCategory {
    readonly id: string;
    name: string;
    description: string;
}
interface ProductUnit {
    readonly id: string;
    name: string;
    type: ProductUnitType;
}
interface ProductCapacity {
    value?: number;
    type: ProductCapacityType;
}
interface ProductBranch {
    readonly productId: string;
    readonly branchId: string;
    originalPrice: number;
    sellingPrice: number;
    taxSettingId: string;
    taxSetting: taxSetting;
}
interface taxSetting {
    readonly id: string;
    readonly name: string;
    readonly value: number;
    readonly descriptions: string;
}
export interface GetAllProductResponseItem {
    readonly id: string;
    name: string;
    productCategoryId: string;
    productUnitId: string;
    productCapacityId: string;
    description?: string;
    productCategory?: ProductCategory;
    productUnit?: ProductUnit;
    productCapacity: ProductCapacity;
    productBranches: ProductBranch[];
}

export const getAllProduct = httpUtil.createHttpRequestMeta<
    GetAllProductRequest,
    PaginationResponse<GetAllProductResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/products',
    method: 'GET',
    authentication: 'bearer',
});
