import {
    Block,
    FlexBox,
    Header,
    Spacer
} from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PageLayoutSizeType {
	pageTitleSize: 1 | 2 | 3 | 4 | 5;
    padding?: number;
    marginWithChild?: number
}

const pageLayoutSizes: Record<string, PageLayoutSizeType> = {
    default: {
        pageTitleSize: 2,
        marginWithChild: 24,
        padding: 21,
    },
    small: {
        pageTitleSize: 4,
        marginWithChild: 12,
        padding: 0,
    },
    medium: {
        pageTitleSize: 3,
        marginWithChild: 16,
        padding: 21,
    },
};

interface PageLayoutProps {
    className?: string;
	pageTitleTransCode?: string;
	middleHeaderPage?: React.ReactNode;
	rightHeaderPage?: React.ReactNode;
    leftHeaderPage?: React.ReactNode;
	children: React.ReactNode;
	size?: 'small' | 'medium';
}

export const PageLayout = (props: PageLayoutProps) => {
    const {
        pageTitleTransCode = '',
        middleHeaderPage,
        rightHeaderPage,
        leftHeaderPage,
        size,
        className
    } = props;
    const { t } = useTranslation();
    const theme = pageLayoutSizes[size || 'default'];

    const renderLeftHeaderView = () => {
        if (leftHeaderPage) {
            return leftHeaderPage;
        }

        return (<Header level={theme.pageTitleSize}>
            {t(pageTitleTransCode)}
        </Header>);
    };
    
    return (
        <Spacer type='padding' size={theme.padding} className={className}>
            <FlexBox position='relative' justifyContent='space-between' alignItems='center' >
                {renderLeftHeaderView()}
                <Block
                    position='absolute'
                    left='50%'
                    top='50%'
                    transform='translate(-50%, -50%)'
                >
                    {middleHeaderPage}
                </Block>
                {rightHeaderPage}
            </FlexBox>
            <Spacer type='margin' bottom={theme.marginWithChild} />
            {props.children}
        </Spacer>
    );
};
