import { FlexBox, IconPen, IconTrash, Text, useLocalesFormat } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { SaleStatus } from '@esg/business-pos';
import { useTranslation } from 'react-i18next';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';

interface OrderServiceItemProps {
    saleDetail?: SaleDetailGetAllResponseItem
    onEditOrderItem: () => void;
    onDeleteOrderItem: () => void;
}

export default function OrderServiceItem(props: OrderServiceItemProps) {
    const { formatCurrency } = useLocalesFormat();
    const { t } = useTranslation();

    const { onDeleteOrderItem, onEditOrderItem, saleDetail} = props;
    const [currentSale]  = useRecoilState(currentSaleState);
    const isCanUpdate = currentSale?.status === SaleStatus.Unpaid;

    const employeesName = saleDetail?.saleDetailEmployees?.map(o => o.employee?.fullName).join(', ');
    const subTotal = (saleDetail?.objectPrice??0) * (saleDetail?.quantity ??0);
    const showSubTotal = subTotal > (saleDetail?.totalAmount??0);

    return (
        <Card
            size='small'
            bordered={false}
            key={saleDetail?.id} 
        >
            <FlexBox justifyContent='space-between' alignItems='center' flex={1}>
                <FlexBox direction='column' flex={1}>
                    <FlexBox gap={8}>
                        <Text fontSize={14} fontWeight={600} textTransform='capitalize'>
                            {saleDetail?.objectName} 
                        </Text>
                        {saleDetail && saleDetail.quantity > 1 && 
                            <Text fontSize={14} fontWeight={400}> x{saleDetail.quantity}</Text>
                        }
                    </FlexBox>
                    <FlexBox>
                        <Text>{saleDetail?.service?.duration} {t(translateCodes.MINS)} </Text>
                        {employeesName && (
                            <Text maxLines={1} maxWidth={185} fontWeight={600} ellipsis> 
                                &nbsp;{t(translateCodes.WITH)} {employeesName ?? '_'}
                            </Text>
                        )}
                    </FlexBox>
                </FlexBox>
                <FlexBox direction='column' alignItems='end' gap={8} flex='none'>
                    <FlexBox gap={8} alignItems='center'>
                        {showSubTotal && <Text textDecoration='line-through' fontSize={12}>{formatCurrency(subTotal)}</Text>}
                        <Text fontWeight={600}>{formatCurrency(saleDetail?.totalAmount ?? 0)}</Text>
                    </FlexBox>
                    {isCanUpdate && (
                        <FlexBox gap={12}>
                            <IconPen onClick={onEditOrderItem} width={16} height={16}/>
                            <IconTrash onClick={onDeleteOrderItem} />
                        </FlexBox>
                    )}
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
