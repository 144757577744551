import { getAllEmployees } from '@esg/business-resource-management';
import { useHttpQuery } from '@esg/ui';

export const useTimeOffResource = () => {
    const { data: employeeResponse, isPending } = useHttpQuery(getAllEmployees, {
        query: {
            pageSize: 50,
        },
    });
    return {
        employees: employeeResponse?.items ?? [],
        loading: isPending
    };
};
