import { getOverviewRevenueReport } from '@esg/business-report';
import { Block, FlexBox, IconGrowDown, IconGrowUp, IconRevenue, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Avatar, Card } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { dateHelper } from '@/helpers';

interface AnalyserRevenueOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface appointmentOverviews {
    label: string;
    value: number;
    preValue: number;
    color: string;
    formatCurrency?: boolean;
    icon: React.JSX.Element;
}

export default function AnalyserRevenueOverview(props: AnalyserRevenueOverviewProps) {
    const { formatCurrency } = useLocalesFormat();
    const { t } = useTranslation();
    const { dateRange } = props;
    const {data: overviewRevenue, isLoading} = useHttpQuery(getOverviewRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        }
    });

    const dateRangePrevious = useMemo(()=>(dateHelper.getDateRangePrevious(dateRange[0], dateRange[1])),[dateRange]);

    const {data: overviewRevenuePrevious} = useHttpQuery(getOverviewRevenueReport, {
        query: {
            startDate: dateRangePrevious[0].toISOString(),
            endDate: dateRangePrevious[1].toISOString(),
        }
    });
    const formatCurrencyByType = useCallback((o: appointmentOverviews)=>{
        return o.formatCurrency === true ? formatCurrency(o.value) : o.value;
    },[formatCurrency]);

    const renderGrow = useCallback((current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';

        if(previous === 0) {
            return ;
        }

        const percent = (grow / previous) * 100;

        return (
            <FlexBox alignItems='center' >
                <Text className='grow-level' color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );
    },[]);

    const appointmentOverviews = useMemo<appointmentOverviews[]>(() => [
        {
            label: t(translateCodes.TOTAL_REVENUES),
            value: overviewRevenue?.totalRevenue ?? 0,
            preValue: overviewRevenuePrevious?.totalRevenue ?? 0,
            color: '#EFF4FF',
            formatCurrency: true,
            icon:<IconRevenue color='#3D8CF5'/>
        },
        {
            label: t(translateCodes.HIGHEST_SALE),
            value: overviewRevenue?.highestSale ?? 0,
            preValue: overviewRevenuePrevious?.highestSale ?? 0,
            color: '#ECFDF3',
            formatCurrency: true,
            icon:<IconRevenue color='#17B26A'/>
        },
        {
            label: t(translateCodes.TOTAL_SALE),
            value: overviewRevenue?.totalSale ?? 0,
            preValue: overviewRevenuePrevious?.totalSale ?? 2,
            color: '#FEF3F2',
            icon:<IconRevenue color='#F04438'/>
        },
    ],[overviewRevenue, overviewRevenuePrevious, t]);
    return (
        <FlexBox className='overview-revenue' direction='row' gap={16} flexWrap='wrap'>
            {appointmentOverviews.map((o, i)=> {
                const resultFormatCurrencyByLabel = formatCurrencyByType(o);
                return (
                    <Card key={i} loading={isLoading} className='card-overview' style={{minWidth: 267, width: 'calc((100% / 3) - calc(32px/3))', display: 'flex', alignItems: 'center'}}>
                        <FlexBox gap={8} alignItems='center' flex='none'>
                            <Avatar
                                style={{ backgroundColor: o.color }}
                                size={42}
                                icon={o.icon}
                            />
                            <FlexBox direction='column'>
                                <Text maxLines={1} maxWidth={121} fontWeight={600} translateCode={o.label} />
                                <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                    <Block>
                                        <Text color='#101828' fontWeight='700' fontSize={18}>
                                            {resultFormatCurrencyByLabel}
                                        </Text>
                                    </Block>
                                    <Block>
                                        {renderGrow(o.value, o.preValue)}
                                    </Block>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </Card>
                );
            })}
        </FlexBox>
    );
}
