import { AppButton, AppButtonProps } from '@esg/ui';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import React from 'react';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';

interface AppointmentFormBtnProps extends AppButtonProps {
	readonly initialValues?: Partial<AppointmentGetDetailResponse>;
    readonly refetch?: () => void;
}

export const AppointmentFormBtn = (props: AppointmentFormBtnProps) => {
    const showAppointmentForm = useAppointmentForm();
    const { initialValues, refetch, ...rest } = props;

    return (
        <AppButton
            {...rest}
            id='appointment-form-btn'
            onClick={(e) => {
                const btnElement = e.target as HTMLElement;

                const employeeId = btnElement.getAttribute('data-employee-id') || undefined;
                const date = btnElement.getAttribute('data-date') || undefined;
                const facilityId = btnElement.getAttribute('data-facility-id') || undefined;

                btnElement.removeAttribute('data-employee-id');
                btnElement.removeAttribute('data-facility-id');
                btnElement.removeAttribute('data-date');

                showAppointmentForm({
                    date,
                    refetch,
                    facilityId,
                    initialValues,
                    
                    defaultEmployeeId: employeeId,
                });
            }}
        />
    );
};
