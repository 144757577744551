import { GetDetailSaleResponse, cancelSale, createCancelReason, getAllCancelReason } from '@esg/business-pos';
import { FlexBox, FormBase, FormLayoutModal, SelectField, TextAreaField, useHttpCommand, useHttpQuery } from '@esg/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { viewRequests } from '@esg/framework';
import { translateCodes } from '@/locales';

interface CancelSaleFormProps {
    readonly onCancel?: () => void;
    readonly onSuccess?: () => void;
    readonly sale: GetDetailSaleResponse;
}

export default function CancelSaleForm(props: CancelSaleFormProps) {
    const {t} = useTranslation();
    const {data: cancelReasons, refetch: refetchCancelReasons, isFetching} = useHttpQuery(getAllCancelReason);

    const cancelReasonsOptions = cancelReasons?.items?.map(o => ({
        value: o.id,
        label: o.name
    }));

    const {mutateAsync} = useHttpCommand(createCancelReason);
    const {mutateAsync: onCancelSale} = useHttpCommand(cancelSale, {
        onSuccess: () => {
            props.onSuccess?.();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.CANCEL_SALE_SUCCESS)
            });
        }
    });

    const handleCreateCancelReason = async (form: ReturnType<typeof useForm>, value?: string) => {
        await mutateAsync({
            body: { name: value}
        }, 
        {
            onSuccess: (response) => {
                refetchCancelReasons?.();
                form.setValue('cancelReasonId', response.id);
            }
        });
    };

    const submit = async (values: GetDetailSaleResponse) => {
        await onCancelSale({
            pathData: { id: values.id },
            body: {
                cancelNote: values.cancelNote,
                cancelReasonId: values.cancelReasonId,
                id: values.id
            }
        });
    };

    return (
        <FormBase onSubmit={submit} defaultValues={props.sale}>
            {(form) =>(
                <FormLayoutModal 
                    useSubmitButton={true}
                    onCancel={props.onCancel}
                    cancelTextCode={translateCodes.CANCEL}
                    okTextCode={translateCodes.CANCEL_SALE}
                >
                    <FlexBox direction='column' gap={16}>
                        <SelectField
                            rules={{ required: translateCodes.CANCEL_REASON_REQUIRED }}
                            name='cancelReasonId'
                            placeholder={t(translateCodes.SELECT_CANCEL_REASON)}
                            label= {t(translateCodes.CANCELATION_REASON)}
                            options={cancelReasonsOptions}
                            onCreate={async (value) => handleCreateCancelReason(form, value)}
                            createTextCode={translateCodes.CREATE_CANCEL_REASON}
                            typeCreate='input'
                            loading={isFetching}
                        />
                        <TextAreaField
                            name='cancelNote'
                            placeholder={t(translateCodes.CANCEL_NOTE_PLACEHOLDER)}
                            label={t(translateCodes.CANCELATION_NOTE)}
                        />
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
