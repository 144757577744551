import React from 'react';
import { ConnectTokenResponse } from '@esg/business-account';
import { env } from '../config/env';

interface ExchangeTokenPeriodicallyPayload {
    readonly getRefreshToken: () => string | null | undefined;
    readonly setTokens: (tokens: ConnectTokenResponse) => void;
    readonly interval: number;
}

export function useExchangeToken() {
    const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

    const exchangeToken = React.useCallback(async (refreshToken: string): Promise<ConnectTokenResponse> => {
        const response = await fetch(`${env.identityServer}/connect/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to exchange token');
        }

        const data = await response.json();
        return data;
    }, []);

    const exchangeTokenPeriodically = React.useCallback((options: ExchangeTokenPeriodicallyPayload) => {
        const interval = options.interval;
        intervalRef.current = setInterval(async () => {
            const refreshToken = options.getRefreshToken();
            if (!refreshToken) {
                return null;
            }
            await exchangeToken(refreshToken);
        }, interval);

        return intervalRef.current;
    }, [exchangeToken]);

    React.useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return {
        exchangeToken,
        exchangeTokenPeriodically,
    };
}