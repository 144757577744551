import React, {  } from 'react';
import FastPostForm from '@/components/form/sale/FastPostForm';

interface FastPostContentProps {
    loadingUpdate?: boolean;
    loadingCreate?: boolean;
}

export const FastSaleDetail: React.FC<FastPostContentProps> = ({ loadingUpdate, loadingCreate }) => {

    return (
        <FastPostForm  
            loadingUpdate={loadingUpdate} 
            loadingCreate={loadingCreate}
        />
    );
};
