import { AppointmentStatus as Status, getAppointmentStatusAction } from '@esg/shared';
import { AppButton, FlexBox, Text, useHttpCommand } from '@esg/ui';
import React, { useMemo } from 'react';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { getDetailSale } from '@esg/business-pos';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { useApptStatusFastUpdate } from '../@share/hooks';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';

interface AppointmentFastUpdateProps {
	readonly appointment: AppointmentGetDetailResponse;
	readonly employee?: EmployeeGetDetailResponse;
}

export const AppointmentFastUpdate = ({ appointment, employee }: AppointmentFastUpdateProps) => {
    const { status, saleId, id } = appointment;
    const { mutateAsync: getSaleDetail } = useHttpCommand(getDetailSale);
    const { onShowFormSale } = useOpenSaleFrom();

    const { handleUpdate, loading } = useApptStatusFastUpdate();

    const { isCheckIn, allowAction, label } = useMemo(() => {
        const { label, allowAction } = getAppointmentStatusAction(status);

        return {
            label,
            allowAction,
            isCheckIn: status === Status.CheckedIn,
        };
    }, [status]);

    return (
        <FlexBox justifyContent='flex-end'>
            {allowAction && (
                <AppButton
                    ghost
                    type='primary'
                    disabled={loading}
                    onClick={async () => {
                        if (isCheckIn) {
                            const sale = await getSaleDetail({
                                pathData: {
                                    id: saleId!,
                                },
                            });

                            return void onShowFormSale(sale, {
                                paidFromScheduler: true,
                            });
                        }

                        handleUpdate({ id, status, employee });
                    }}
                >
                    <Text translateCode={label} color='#0B5DCC' />
                </AppButton>
            )}
        </FlexBox>
    );
};
