import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { currentSaleState } from '@/states';

interface InitSaleFormProps {
    children?: React.ReactNode;
}

export default function InitSaleForm(props: InitSaleFormProps) {
    const [currentSale] = useRecoilState(currentSaleState);
    const {reset} = useFormContext();

    React.useEffect(() => {
        if(currentSale) {
            reset(currentSale);
        }
    }, [reset, currentSale]);

    return props.children;
}
