import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';
import { BreakTimeDisable } from './@type';

interface GetAllBreakTimeDisableRequest extends HttpRequestData {
    readonly query: PaginationRequest & {
        startTime: boolean;
        endTime: boolean;
    };
}

export const getAllBreakTimeDisable = httpUtil.createHttpRequestMeta<
    GetAllBreakTimeDisableRequest,
    PaginationResponse<BreakTimeDisable>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/break-time-disables',
    method: 'GET',
    authentication: 'bearer',
});
