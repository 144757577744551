import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { env } from '../../configs';

export enum TypeRevenueDetail
{
    All = 0,
    Appointment = 1,
    Product = 2,
    Service = 3,
    Discount = 4,
    Tip = 5,
    Tax = 6,
    Payment = 7,
}

interface GetSummaryDetailRevenueRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
        readonly typeRevenueDetail: TypeRevenueDetail;
    }
}

export const getSummaryDetailRevenueReport = httpUtil.createHttpRequestMeta<
    GetSummaryDetailRevenueRequest,
    PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/summary-detail',
    authentication: 'bearer',
});