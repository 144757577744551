import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface getOverviewClientGenderRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface getOverviewClientGenderResponse {
    totalMale: number;
    totalFemale: number;
    totalUnknown: number;
    totalOther: number;
    totalClient: number;
}

export const getOverviewClientGenderReport = httpUtil.createHttpRequestMeta<
    getOverviewClientGenderRequest,
	getOverviewClientGenderResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-report/overview-gender',
    authentication: 'bearer',
});