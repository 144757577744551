import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';

export const EmployeeDeleteConfirm = ({employee}: {employee?: EmployeeGetDetailResponse}) => {
    return (
        <Spacer top='xs'>
            <Paragraph>
				Are you sure you want to delete employee:&nbsp;
                <Text fontWeight='bold'>{employee?.fullName}</Text> &nbsp;(
                {employee?.employeeCode})
            </Paragraph>
            <Spacer bottom='sm' />
            <Paragraph translateCode='The employee will be removed permanently from your account. Please re-schedule any future appointments prior to deleting the employee.' />
        </Spacer>
    );
};
