import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface CreatePackageCategoryRequest extends HttpRequestData {
    readonly body: {
        name: string;
        description?: string;
    };
}

export interface CreatePackageCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const createPackageCategory = httpUtil.createHttpRequestMeta<
    CreatePackageCategoryRequest,
    CreatePackageCategoryResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/package-categories',
    method: 'POST',
    authentication: 'bearer',
});
