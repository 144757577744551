import {
    ShiftByEmployeeDetail,
    ShiftsGetByEmployeeResponse,
} from '@esg/business-resource-management';
import { SchedulerItem } from '@esg/ui';
import { uniqueId } from 'lodash';
import { dayjs } from '@esg/shared';
import { ShiftQueryParams } from '../Types';

export const getShiftItems = (
    shiftData: ShiftsGetByEmployeeResponse[],
    query: ShiftQueryParams
) => {
    const result: SchedulerItem<ShiftByEmployeeDetail>[] = [];

    const startOfMonth = dayjs(query.startDate);
    const daysInMonth = startOfMonth.daysInMonth();

    shiftData.forEach((data) => {
        let day = 1;
        let date = startOfMonth;
        while (day <= daysInMonth) {
            
            const shift = data.shifts.find((s) => {
                
                const shiftDisable = data.shiftDisables.find(
                    (o) => date.isSame(o.date, 'day') && s.id === o.shiftId
                );

                if (shiftDisable) {
                    return false;
                }
                
                if (s.date && date.isSame(s.date, 'day')) {
                    return true;

                }

                if (!s.date && s.dayOfWeek === date.day()) {
                    return true;
                }

                return false;
            });

            if (shift) {
                const timeOff = data.timeOffs.find((to) => date.isSame(to.date, 'm'));
                
                const timeBlocks = data.timeBlocks.filter((tb) => {
                    const from = dayjs(tb.date).startOf('d');
                    const to = dayjs(tb.repeatTo).startOf('d');

                    const valid = from <= date && to >= date;

                    const disable = data.timeBlockDisables.some(
                        (tbd) => date.isSame(tbd.date, 'd') && tbd.timeBlockId === tb.id
                    );

                    return valid && !disable;
                });

                result.push({
                    data: {
                        ...shift,
                        timeOff,
                        timeBlocks,
                    },
                    key: uniqueId(),
                    startTime: date,
                    rowKey: data.employeeId,
                    endTime: date.add(1, 'day'),
                    canDrag: false
                });
            }
            day++;
            date = date.add(1, 'day');
        }
    });

    return result;
};
