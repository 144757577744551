import { HttpRequestData, httpUtil } from '@esg/framework';
import { ObjectInfo } from '@esg/shared';

import { env } from '../../config';

interface GetUserProfileRequest extends HttpRequestData {
}

export interface UserOrganizations {
    readonly organizationId: string;
    readonly organization: ObjectInfo;
}
interface GetUserProfileResponse {
    readonly id: string;
    readonly username: string;
    readonly email: string;
    readonly role: string;
    readonly userOrganizations: UserOrganizations[];
}

export const getUserProfile = httpUtil.createHttpRequestMeta<
    GetUserProfileRequest,
    GetUserProfileResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/users/profile',
    method: 'GET',
    authentication: 'bearer'
});
