import { managementApis } from '@esg/business-management';
import {
    FormLayout,
    FlexBox,
    FormBase,
    InputField,
    TextAreaField,
    useHttpCommand,
} from '@esg/ui';
import { Card } from 'antd';
import React from 'react';

import { CreateProductCategoryResponse } from '@esg/business-management/api/product-category';
import { useTranslation } from 'react-i18next';
import { productCategoryRules } from '../rules';

import { translateCodes } from '@/locales';

interface ProductCategoryValues {
    id?: string;
    name: string;
    description: string;
}

interface ProductCategoryFormProps {
    readonly onSuccess?: (response?: ProductCategoryValues) => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: ProductCategoryValues;
}

export const ProductCategoryForm = (props: ProductCategoryFormProps) => {
    const isUpdate = !!props.defaultValues?.id;

    const { mutateAsync: createProductCategory, isPending: isLoadingCreate } = useHttpCommand(
        managementApis.createProductCategory
    );

    const { mutateAsync: updateProductCategory, isPending: isLoadingUpdate } = useHttpCommand(
        managementApis.updateProductCategory
    );
    
    const {t} = useTranslation();
    const onSubmit = React.useCallback(
        async (values: ProductCategoryValues) => {
            const onSuccess = (response?: CreateProductCategoryResponse) => {
                props.onSuccess?.(response);
            };

            if (isUpdate) {
                await updateProductCategory({
                    pathData: { id: props.defaultValues?.id },
                    body: values,
                }, { onSuccess });
                return;
            }

            await createProductCategory({ body: values }, { onSuccess });
        },
        [createProductCategory, isUpdate, props, updateProductCategory]
    );

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                onDeleted={props.onDelete}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    props.defaultValues?.id
                        ? translateCodes.SAVE
                        : translateCodes.CREATE
                }
            >
                <Card bordered={false}>
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            rules={productCategoryRules.name(t)}
                            name='name'
                            label={translateCodes.NAME}
                            placeholder={
                                translateCodes.ENTER_PRODUCT_CATEGORY_NAME
                            }
                        />
                        <TextAreaField
                            rules={productCategoryRules.descriptions(t)}
                            name='description'
                            label={translateCodes.DESCRIPTION}
                            placeholder={
                                translateCodes.ENTER_PRODUCT_CATEGORY_DESCRIPTION
                            }
                        />
                    </FlexBox>
                </Card>
            </FormLayout>
        </FormBase>
    );
};
