import { AppButton, Translate, useHttpCommand , managementProfileState } from '@esg/ui';
import { Image } from 'antd';
import React from 'react';

import { useRecoilState } from 'recoil';
import { createSale } from '@esg/business-pos';
import { useLocation } from 'react-router-dom';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { mainLayoutTheme } from '../../@shared';

import { translateCodes } from '@/locales';

import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import { saleEvents } from '@/event/saleEvent';

export function CreateBillButton() {
    const { t } = useTranslation();
    const location = useLocation();
    const [currentProfile] = useRecoilState(managementProfileState);
    const { onShowFormSale } = useOpenSaleFrom();

    const { mutateAsync: onCreateSale, isPending } = useHttpCommand(createSale, {
        onSuccess: (response) => {
            const isFastPosRoute = location.pathname === '/fast-pos';
            if(isFastPosRoute)
            {
                viewRequests.showNotification.send({
                    type: 'success',
                    message: t(translateCodes.MM_FP_04),
                });
                return saleEvents.onRefetchSaleList.emit();
            }

            onShowFormSale(response);
        }
    });

    const handleCreateSale = async () => {
        await onCreateSale({
            body: {
                branchId: currentProfile?.branch.id,
            }
        });
    };

    return (
        <AppButton 
            onClick={handleCreateSale}
            loading={isPending}
            icon={(
                <Image
                    src={mainLayoutTheme.createBillIcon}
                    preview={false}
                    width={24}
                    height={24}
                />
            )}>
            <span>
                <Translate translateCode={translateCodes.CREATE_SALE} />
            </span>
        </AppButton>
    );
}
