import { Block, CheckboxField, FlexBox, SelectField, Spacer, SwitchField, Text } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export function AutoNoShowEnabled() {
    const { watch } = useFormContext();
    const renderMinutes = [
        { value: 5, label: '5 min' },
        { value: 10, label: '10 min' },
        { value: 15, label: '15 min' },
        { value: 20, label: '20 min' },
        { value: 25, label: '25 min' },
        { value: 30, label: '30 min' },
        { value: 35, label: '35 min' },
        { value: 40, label: '40 min' },
        { value: 45, label: '45 min' },
        { value: 50, label: '50 min' },
        { value: 55, label: '55 min' },
        { value: 60, label: '60 min', disabled: true },
    ];
    const autoNoShowEnabledValue = watch('autoNoShowEnabled');
    return (
        <Card>
            <FlexBox preset='column-start'>
                <Text fontSize={16} fontWeight={700}>No show appointment</Text>
                <Spacer type='margin' bottom={10} />
                <FlexBox direction='column'>
                    <SwitchField
                        directionLabel='row-reverse'
                        description="Automatically mark appointments as 'No show' if they haven't been checked in within the set time after the scheduled start"
                        name='autoNoShowEnabled'
                    />
                    <Spacer type='margin' right={8} />
                    {autoNoShowEnabledValue == true && <FlexBox>
                        <Block width={60} />
                        <FlexBox preset='column-start'>
                            <Spacer type='margin' bottom={15} />
                            <Text fontSize={14}>The set time</Text>
                            <Spacer type='margin' bottom={5} />
                            <SelectField name='autoNoShowAfterTime' options={renderMinutes} />
                            <Spacer type='margin' bottom={8} />
                            <FlexBox alignItems='center'>
                                <CheckboxField name='autoNoShowPromptEnabled' />
                                <Text fontSize={14}>Show mark appointments as &quot;No show&quot; prompt</Text>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>}
                </FlexBox>
                <Spacer type='margin' bottom={8} />
            </FlexBox>
        </Card>
    );
}
