import React , {useState} from 'react';
import qs from 'qs';

export const useSearchParams = <T>(initialValues: Partial<T>, keys: (keyof T)[]): [Partial<T>, (value: Partial<T>) => void] => {
    const searchParamsFromUrl = React.useMemo(
        () => {
            const params = new URLSearchParams(window.location.search);
            const searchParams = { ...initialValues } as T;

            const queryObject = qs.parse(params.toString());

            for (const key in queryObject) {
                if (keys.length && !keys.includes(key as keyof T)) {
                    continue;
                }

                searchParams[key as keyof T] = queryObject[key] as T[keyof T];
            }

            return searchParams;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [keys]
    );

    const [searchParams, setSearchParams] = useState<Partial<T>>(searchParamsFromUrl);

    const handleSetSearchParams = React.useCallback((newSearchParams: Partial<T>) => {
        const params = new URLSearchParams(window.location.search);

        for (const key in newSearchParams) {
            if (!newSearchParams[key]) {
                params.delete(key);
                continue;
            }

            if (keys.length && !keys.includes(key as keyof T)) {
                continue;
            }

            if (Array.isArray(newSearchParams[key])) {
                params.delete(key);
                for (const value of newSearchParams[key] as string[]) {
                    params.append(key, value);
                }
                continue;
            }

            params.set(key, newSearchParams[key] as string);
        }

        setSearchParams({ ...searchParams, ...newSearchParams });

        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys]);

    return [searchParamsFromUrl, handleSetSearchParams];
};