import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';
import { GetAllServiceResponseItem } from '../service';

interface GetAllServiceCategoryRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllServiceCategoryResponseItem {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly services: GetAllServiceResponseItem[]
}

export const getAllServiceCategory = httpUtil.createHttpRequestMeta<
    GetAllServiceCategoryRequest,
    PaginationResponse<GetAllServiceCategoryResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/service-categories',
    method: 'GET',
    authentication: 'bearer',
});
