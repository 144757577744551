import { Dayjs } from '@esg/shared';
import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { SaleDetailGetAllResponseItem } from '../sale-detail';
import { GetDetailSaleResponse, TipUnit } from './getDetailSale';

interface UpdateSaleRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        customerId?: string;
        branchId?: string;
        note?: string;
        code: string;
        date: Dayjs;
        totalAmount?: number;
        subTotal?: number;
        tip?: number;
        tipUnit?: TipUnit;
        status: number;
        saleDetails?: SaleDetailGetAllResponseItem[]
    };
}

export const updateSale = httpUtil.createHttpRequestMeta<
    UpdateSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
