import { Navigate, Route, Routes } from 'react-router-dom';
import { SaleAnalyzerAppointment } from './sale-analyzer-appointment/SaleAnalyzerAppointment';
import { SaleAnalyzerRevenue } from './sale-analyzer-revenue/SaleAnalyzerRevenue';
import { SaleAnalyzerClient } from './sale-analyzer-client/SaleAnalyzerClient';
import { SubMenuLayout } from '@/layouts';

import {
    SALE_ANALYZER_REVENUE_URL,
    saleAnalyzerMenu,
} from '@/configs';

export const SaleAnalyzer = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={<SubMenuLayout subMenu={saleAnalyzerMenu} />}
            >
                <Route
                    path='/'
                    element={<Navigate to={SALE_ANALYZER_REVENUE_URL} />}
                />
                <Route path='/appointment' Component={SaleAnalyzerAppointment} />
                <Route path='/revenue' Component={SaleAnalyzerRevenue} />
                <Route path='/client' Component={SaleAnalyzerClient} />
            </Route>
        </Routes>
    );
};
