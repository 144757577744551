import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteCustomerNoteRequest extends HttpRequestData {
}

export const deleteCustomerNote = httpUtil.createHttpRequestMeta<
    DeleteCustomerNoteRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-notes/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
