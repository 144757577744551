import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface GetAllAppointmentActivitiesRequest extends HttpRequestData {
    readonly query: {
        id : string;
    };
}

export interface AppointmentActivityData {
    readonly field: string;
    readonly originValue: string;
    readonly changedValue: string;
}

interface AppointmentResourceLog {
    readonly serviceName?: string;
    readonly customerName?: string;
    readonly employeeName?: string;
    readonly facilityName?: string;
    readonly guestCode?: string;
}

export interface AppointmentActivity {
    readonly createById: string;
    readonly createByName: string;
    readonly recordId: string;
    readonly parentRecordId?: string;
    readonly entityName: string;
    readonly action: string;
    readonly created: string;
    readonly createdTime: string;
    readonly data: Array<AppointmentActivityData>;
    readonly currentDataJson: string;
    readonly resource?: AppointmentResourceLog;
}

export interface GetAllAppointmentActivitiesResponse {
    readonly id: string;
    readonly activities: AppointmentActivity[][];
}

export const getAllAppointmentActivities = httpUtil.createHttpRequestMeta<
    GetAllAppointmentActivitiesRequest,
    GetAllAppointmentActivitiesResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/activities/appointment/:id',
    method: 'GET',
    authentication: 'bearer',
});
