import { managementApis } from '@esg/business-management';
import { FormLayout ,
    FlexBox,
    FormBase,
    InputField,
    TextAreaField,
    useHttpCommand,
} from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { CreateServiceCategoryResponse } from '@esg/business-management/api/service-category';
import { serviceCategoryRules } from '../rules';

import { translateCodes } from '@/locales';

interface ServiceCategoryValues {
    id?: string;
    name: string;
    description: string;
}

interface ServiceCategoryFormProps {
    readonly onSuccess?: (response?: ServiceCategoryValues) => void;
    readonly refetch: () => void;
    readonly resetDrawerState: () => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: ServiceCategoryValues;
}

export const ServiceCategoryForm = (props: ServiceCategoryFormProps) => {
    const isUpdate = !!props.defaultValues?.id;
    const { t } = useTranslation();

    const { mutateAsync: createServiceCategory, isPending: isLoadingCreate } = useHttpCommand(
        managementApis.createServiceCategory
    );

    const { mutateAsync: updateServiceCategory, isPending: isLoadingUpdate } = useHttpCommand(
        managementApis.updateServiceCategory
    );

    const onSubmit = React.useCallback(
        async (values: ServiceCategoryValues) => {
            const onSuccess = (response? :CreateServiceCategoryResponse) => {
                props.onSuccess?.(response);
            };
            
            if (isUpdate) {
                await updateServiceCategory({
                    pathData: { id: props.defaultValues?.id },
                    body: values,
                }, {  
                    onSuccess(_) {
                        props.refetch();
                        props.resetDrawerState();
                    },
                });
                return;
            }else{
                await createServiceCategory({ 
                    body: values 
                }, {
                    onSuccess(_) {
                        props.refetch();
                        props.resetDrawerState();
                        viewRequests.showNotification.send({
                            type: 'success',
                            message: t(translateCodes.NM_PT_02),
                        });
                        onSuccess;
                    },
                });
                return;
            }
        },
        [createServiceCategory, isUpdate, props, t, updateServiceCategory]
    );

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                onDeleted={props.onDelete}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    props.defaultValues?.id
                        ? translateCodes.SAVE
                        : translateCodes.CREATE
                }
            >
                <Card bordered={false}>
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            rules={serviceCategoryRules.name(t)}
                            name='name'
                            label={translateCodes.NAME}
                            placeholder={
                                translateCodes.ENTER_SERVICE_CATEGORY_NAME
                            }
                        />
                        <TextAreaField
                            name='description'
                            rules={serviceCategoryRules.descriptions(t)}
                            label={translateCodes.DESCRIPTION}
                            placeholder={
                                translateCodes.ENTER_SERVICE_CATEGORY_DESCRIPTION
                            }
                        />
                    </FlexBox>
                </Card>
            </FormLayout>
        </FormBase>
    );
};
