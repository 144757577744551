import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { IntegrationEmailConfig, IntegrationType } from './Types';

export interface IntegrationEmailCreateRequest extends HttpRequestData {
	body: {
		readonly type: IntegrationType;
		readonly configuration: IntegrationEmailConfig
	};
}

export const integrationEmailCreate = httpUtil.createHttpRequestMeta<IntegrationEmailCreateRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/integration-settings',
    method: 'POST',
    authentication: 'bearer',
});