import { Block, FlexBox, Spacer , managementProfileState, useLocalesFormat } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { dayjs } from '@esg/shared';
import { Divider, Image } from 'antd';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';
import { saleHelper } from '@/helpers';

const stylePrint = {
    default : {
        fontSize: 14,
        fontSizeTitle: 18,
        fontSizeSubTitle: 16,
    }
};

export default function PrintSale() {
    const { formatDate, formatCurrency } = useLocalesFormat();
    const {t} = useTranslation();

    const [currentProfile] = useRecoilState(managementProfileState);
    const [currentSale] = useRecoilState(currentSaleState);
    const [currentLocale] = useRecoilState(currentLocaleState);

    const saleDetailGroupsByType = groupBy(currentSale?.saleDetails, 'objectType');

    const fontSizeDefault = stylePrint.default.fontSize;

    const tipAmount = saleHelper.calcTipAmount(currentSale!) ?? 0;
    const taxAmount = saleHelper.calcTaxAmount(currentSale?.saleDetails ?? []) ?? 0;

    return (
        <Block id='print-bill' className='print'>       
            <Spacer type='padding' top_bottom={16} left_right={8}>
                <FlexBox direction='column' alignItems='center'>
                    <Image src={currentProfile?.branch.logo} width={74} height={74} />
                    <span className='title'>
                        {currentProfile?.branch.name}
                    </span>
                    <span className='subtitle' >
                        {formatDate(dayjs(), 'h:mm a ddd, ' + currentLocale?.format)}
                    </span>
                    <Spacer type='margin' top_bottom={32} width='100%'>
                        <FlexBox 
                            border='1px dashed #101828'  
                            position='relative' 
                            borderRadius={fontSizeDefault} 
                            height={57}
                            justifyContent='center'
                            alignItems='center'
                        >
                            <Block 
                                position='absolute' 
                                top='-10px'
                                left='50%'
                                transform='translateX(-50%)'
                                backgroundColor='#FFF'
                                zIndex={1}
                            >
                                {t(translateCodes.RECEIPT).toLocaleUpperCase()}
                            </Block>
                            <span>{currentProfile?.branch.phoneNumber ?? '___'}</span>
                        </FlexBox>
                    </Spacer>
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.CLIENT_NAME)}:</span>
                        <span className='text-default'>{currentSale?.customer?.fullName ?? 'Visiting Client'}</span>
                    </FlexBox>
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.PHONE_NUMBER)}:</span>
                        <span className='text-default'>{currentSale?.customer?.phone ?? '_'}</span>
                    </FlexBox>
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.PAYMENT_TYPE)}:</span>
                        <span className='text-default'>{currentSale?.paymentMethodSetting?.name ?? '_'}</span>
                    </FlexBox>
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.SALE_ID)}:</span>
                        <span className='text-default'>{currentSale?.code ?? '_'}</span>
                    </FlexBox>
                    <Divider style={{borderColor: '#000'}} dashed />
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default font-bold'>{t(translateCodes.DESCRIPTION)}:</span>
                        <span className='text-default font-bold'>{t(translateCodes.PRICE)}</span>
                    </FlexBox>
                    {Object.keys(saleDetailGroupsByType).map((type) => (
                        <Spacer key={type} width='100%' type='padding' top_bottom={4}>
                            <span className='text-default font-600 italic capitalize'>{type.toLowerCase()}</span>
                            <FlexBox gap={4}  direction='column' >                        
                                {saleDetailGroupsByType[type].map((saleDetail) => {
                                    const showQuantity = saleDetail.quantity > 1;
                                    const subTotal = (saleDetail?.objectPrice ?? 0) * (saleDetail?.quantity ??0);
                                    const showSubTotal = subTotal > (saleDetail?.totalAmount??0);

                                    return (
                                        <Block key={saleDetail.id}>
                                            <FlexBox width='100%' gap={6} justifyContent='space-between'>
                                                <FlexBox direction='column'>
                                                    <span className='text-default'>{saleDetail.objectName}</span>
                                                </FlexBox>
                                                {showSubTotal && (
                                                    <span className='text-default line-through'>
                                                        {formatCurrency(subTotal)} &nbsp;
                                                    </span>
                                                )}
                                                <span className='text-default'>{formatCurrency(saleDetail.totalAmount ?? 0)}</span>
                                            </FlexBox>
                                            {showQuantity && (
                                                <span className='text-default'>
                                                    {formatCurrency(saleDetail.objectPriceOrigin)} x {saleDetail.quantity}
                                                </span>
                                            )} 
                                        </Block>
                                    );
                                })}
                            </FlexBox>
                        </Spacer>
                    ))}
                    <Divider style={{borderColor: '#000'}} dashed />
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.SUB_TOTAL)}:</span>
                        <span className='text-default font-700'>{formatCurrency(currentSale?.subTotal ?? 0)}</span>
                    </FlexBox>
                    {/* <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.PROMOTIONS)}:</span>
                        <span className='text-default' className='font-bold'>_</span>
                    </FlexBox> */}
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.TAX)}:</span>
                        <span className='text-default font-bold'>{formatCurrency(taxAmount)}</span>
                    </FlexBox>
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='text-default'>{t(translateCodes.TIP)}:</span>
                        <span className='text-default font-bold'>{formatCurrency(tipAmount)}</span>
                    </FlexBox>
                    <Divider style={{borderColor: '#000'}} dashed />
                    <FlexBox width='100%' justifyContent='space-between'>
                        <span className='subtitle font-bold'>{t(translateCodes.TOTAL_AMOUNT).toLocaleUpperCase()}:</span>
                        <span className='subtitle font-bold'>{formatCurrency(currentSale?.totalAmount ?? 0)}</span>
                    </FlexBox>

                    <Spacer top={24} >
                        <Block textAlign='center'>
                            <span className='text-default'>
                                Thanks for using service at <b>{currentProfile?.branch.name}</b>, we hope you had a great time here!
                            </span>
                        </Block>
                    </Spacer>
                </FlexBox>
            </Spacer>
        </Block>
    );
}
