import { FlexBox, FormBase, Text, Translate } from '@esg/ui';
import React from 'react';
import { useShiftContext } from '../../ShiftContext';
import { TimelineSelector } from './children/TimelineSelector';
import { translateCodes } from '@/locales';
import { EmployeeSelectField } from '@/components';

export function ShiftsHeader() {
    const { employees, setQuery } = useShiftContext();
    const employeeOptions = employees.map((employee) => ({
        label: employee.fullName,
        value: employee.id,
        avatar: employee.avatar,
    }));
    return (
        <FlexBox justifyContent='space-between' alignItems='center'>
            <Text fontSize={20} fontWeight={700} lineHeight='30px'>
                <Translate translateCode={translateCodes.SHIFTS} />
            </Text>
            <FlexBox gap={16} flex={0}>
                <TimelineSelector />
                <FormBase onSubmit={() => {}}>
                    {(form) => (
                        <EmployeeSelectField
                            width={220}
                            handleChangedEmployee={(value) => {
                                form.setValue('employeeIds', value);
                                setQuery({
                                    employeeIds: value
                                });
                            }}
                            value={form.watch('employeeIds')}
                            name='employeeIds'
                            type='default'
                            mode='multiple'
                            maxCountAvatar={2}
                            employeeOptions={employeeOptions}
                            allEmployee
                            allowClear
                        />
                    )}
                </FormBase>
            </FlexBox>
        </FlexBox>
    );
}
