import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';

interface GetAllCustomerNoteRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllCustomerNoteResponseItem {
    readonly id: string;
    readonly customerId: string;
    readonly value?: string;
    readonly creatorId?: string;
    readonly creationTime?: string;
}

export const getAllCustomerNotes = httpUtil.createHttpRequestMeta<GetAllCustomerNoteRequest, PaginationResponse<GetAllCustomerNoteResponseItem>>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-notes',
    method: 'GET',
    authentication: 'bearer'
});