import {
    schedulerApis,
} from '@esg/business-schedule';
import { SchedulerItem, useHttpQuery, usePagination } from '@esg/ui';
import React, { useEffect } from 'react';
import { dayjs } from '@esg/shared';
import { flatten } from 'lodash';

import { AppointmentQueryParams, ViewType } from '../../Scheduler';
import { SchedulerItemInfo } from '../../Types';
import { appointmentEvents } from '@/event';
import { useBusinessHour } from '@/hooks/useBusinessHour';

interface Params {
	readonly query: AppointmentQueryParams;
	readonly viewType: ViewType;
}

export const useAppointmentFetcher = (params: Params) => {
    const [searchParams] = usePagination({orderBy: 'appointmentCode',});
    const {
        query,
        viewType: { viewBy, viewMode },
    } = params;

    const { branchWorkingTime: [startDate, endDate] } = useBusinessHour(query.currentDate);

    const {
        data,
        isFetching: isAppointmentFetching,
        refetch: refetchAppointment,
    } = useHttpQuery(schedulerApis.getAllAppointments, {
        query: {
            ...query,
            ...searchParams,
            endDate: endDate.toISOString(),
            startDate: startDate.toISOString(),
            pageSize: viewMode === 'TIMELINE' ? 1000 : 20,
        },
    });

    useEffect(() => {
        const listen = appointmentEvents.refetchAppointments.listen(() => {
            refetchAppointment();
        });

        return () => {
            listen();
        };
    }, [refetchAppointment]);

    const schedulerItems = React.useMemo(() => {
        const result: SchedulerItem<SchedulerItemInfo>[] = [];

        let appointmentServiceDetails = flatten(
            data?.items.map((o) =>
                flatten(
                    o.appointmentServices.map((a) => {
                        let time = dayjs(o.appointmentDate);
                        return a.appointmentServiceDetails.map((b) => {
                            time = time.add(b.service.duration, 'minute');
                            return {
                                ...b,
                                appointment: o,
                                startTime: time.subtract(b.service.duration, 'minute'),
                                endTime: time,
                            };
                        });
                    })
                )
            )
        );

        appointmentServiceDetails = appointmentServiceDetails.filter((u) => {
            if (!query.serviceIds || query.serviceIds?.length === 0) {
                return true;
            }

            if (query.serviceIds.includes(u.serviceId)) {
                return true;
            }

            return false;
        });
        
        //Facility scheduleItems

        if (viewBy === 'FACILITY') {
            appointmentServiceDetails = appointmentServiceDetails.filter((o) => {
                if (!query.employeeIds) {
                    return true;
                }

                const employeeIds = o.appointmentServiceEmployees.map((o) => o.employeeId);

                if (employeeIds.some((o) => query.employeeIds.includes(o))) {
                    return true;
                }

                return o.facilityId !== undefined;
            });

            appointmentServiceDetails.forEach((o) => {
                result.push({
                    key: o.id!,
                    rowKey: o.facilityId!,
                    startTime: o.startTime,
                    endTime: o.endTime,
                    data: {
                        type: 'APPOINTMENT',
                        values: {
                            ...o,
                            appointmentServiceEmployee: o.appointmentServiceEmployees[0],
                        },
                    },
                });
            });

            return result;
        }

        //Employee scheduleItems

        appointmentServiceDetails.forEach((o, index) => {
            if (!o.appointmentServiceEmployees || o.appointmentServiceEmployees.length === 0) {
                result.push({
                    key: `unassigned.${index}`,
                    rowKey: '0',
                    startTime: o.startTime,
                    endTime: o.endTime,
                    data: {
                        type: 'APPOINTMENT',
                        values: o,
                    },
                });
                return;
            }

            const appointmentServiceEmployees = o.appointmentServiceEmployees.filter((o) => {
                
                if (!query.employeeIds || query.employeeIds?.length === 0) {
                    return true;
                }

                if (query.employeeIds.includes(o.employeeId)) {
                    return true;
                }

                return false;
            });

            appointmentServiceEmployees.forEach((a) => {
                result.push({
                    key: a.id!,
                    rowKey: a.employeeId!,
                    startTime: dayjs(a.timeStartWork),
                    endTime: dayjs(a.timeEndWork),
                    data: {
                        type: 'APPOINTMENT',
                        values: {
                            ...o,
                            appointmentServiceEmployee: a,
                        },
                    },
                });
            });
        });

        return result;
    }, [data?.items, query.serviceIds, query.employeeIds, viewBy]);

    return {
        appointments: data?.items ?? [],
        refetchAppointment,
        isAppointmentFetching,
        schedulerItems: schedulerItems ?? [],
    };
};
