import { getAppointmentDetail } from '@esg/business-schedule';
import { useHttpCommand } from '@esg/ui';
import { useCallback } from 'react';

export const useFetchAppointment = () => {
    const { mutateAsync: getAppointment } = useHttpCommand(
        getAppointmentDetail
    );

    const fetchAppointment = useCallback(
        async (appointmentId: string) => {
            const appointment = await getAppointment({
                pathData: {
                    id: appointmentId,
                },
            });

            return appointment;
        },
        [getAppointment]
    );

    return fetchAppointment;
};