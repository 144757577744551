import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { EmployeeGetDetailResponse } from '../employee';
import { ShiftGetDetailResponse } from '../shift';

export interface BreakTimeGetDetailResponse {
	readonly id: string;
	readonly date?: string;
	readonly title?: string;
	readonly employeeId: string;
	readonly endTime: string;
	readonly isFixed: boolean;
	readonly repeatTo?: string;
	readonly startTime: string;
	readonly shiftId: string;
	readonly dayOfWeek: number;
	readonly dayInMonth?: number;
	readonly occurrences?: number;
	readonly specificDate?: string;
	readonly DayOfWeekTitle?: string;
	readonly organizationId?: string;

	readonly employee?: EmployeeGetDetailResponse;
	readonly shift?: ShiftGetDetailResponse;
}

interface GetAllBreakTimeRequest extends HttpRequestData {
    readonly query: {
        startDate: string;
        endDate: string;
    };
}

export const getAllBreakTime = httpUtil.createHttpRequestMeta<
	GetAllBreakTimeRequest,
	BreakTimeGetDetailResponse[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/break-times',
    method: 'GET',
    authentication: 'bearer',
});
