import {
    Spacer,
    Text,
    Translate,
    globalDrawerState,
    globalModalState,
    useHttpCommand,
} from '@esg/ui';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback } from 'react';
import {
    BreakTimeGetDetailResponse,
    updateBreakTime,
    updateShiftByBreakTime,
} from '@esg/business-resource-management';
import { viewRequests } from '@esg/framework';

import { translateCodes } from '@/locales';
import { breakTimeEvents } from '@/event/breakTimeEvents';

export const useBreakTimeSubmit = () => {
    const hideModal = useResetRecoilState(globalModalState);
    const hideDrawer = useResetRecoilState(globalDrawerState);
    const [, showModal] = useRecoilState(globalModalState);

    const handleSuccess = useCallback(() => {
        hideModal();
        breakTimeEvents.refetchBreakTime.emit();
        viewRequests.showNotification.send({
            type: 'success',
            message: <Translate translateCode={translateCodes.NM_SC_05} />,
        });
    }, [hideModal]);

    const { mutateAsync: editBreakTime, isPending: breakTimeLoading } = useHttpCommand(
        updateBreakTime,
        {
            onSuccess() {
                handleSuccess();
            },
        }
    );

    const { mutateAsync: editShift, isPending: shiftLoading } = useHttpCommand(
        updateShiftByBreakTime,
        {
            onSuccess() {
                handleSuccess();
            },
        }
    );

    const edit = useCallback(
        async (values: BreakTimeGetDetailResponse) => {
            if (values.isFixed) {
                return void editShift({
                    pathData: {
                        id: values.shiftId,
                    },
                    body: values,
                });
            }

            await editBreakTime({
                pathData: {
                    id: values.id,
                },
                body: values,
            });
        },
        [editBreakTime, editShift]
    );

    const handleEdit = useCallback(
        (breakTime: BreakTimeGetDetailResponse) => {
            hideDrawer();
            showModal({
                titleTransCode: translateCodes.UPDATE_BREAK_TIME,
                content: (
                    <Spacer top='xs'>
                        <Text>
                            <Text translateCode={translateCodes.UPDATE_BREAK_TIME_CONFIRM} />
                        </Text>
                    </Spacer>
                ),
                isOpen: true,
                onOk: () => {
                    edit(breakTime);
                },
            });
        },
        [edit, hideDrawer, showModal]
    );

    return { handleEdit, loading: breakTimeLoading || shiftLoading };
};
