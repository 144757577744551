import { HttpRequestData, httpUtil } from '@esg/framework';

import { PackageDurationType } from '@esg/shared/enum/PackageEnum';
import { env } from '../../configs';
import { GetAllPackageResponseItem } from './getAllPackage';

interface UpdatePackageRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        packageCategoryId: string;
        serviceIds?: string[];
        packageDurationType?: PackageDurationType;
        packageDurationValue?: number;
        usageLimit?: number;
        price: number;
        taxSettingId?: string;
        description?: string;
    }
}

export const updatePackage = httpUtil.createHttpRequestMeta<
    UpdatePackageRequest,
    GetAllPackageResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/packages/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
