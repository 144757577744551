import React from 'react';
import { Block } from '@esg/ui';
import { Tabs, TabsProps } from 'antd';
import { AutomationEmailSetting } from './automation-email-setting/AutomationEmailSetting';
import { EmailTemplate } from './email-template/EmailTemplate';
import { AutomationReminder } from './automation-reminder/AutomationReminder';
import { PageLayout } from '@/layouts';

const automationTabKeys = {
    REMINDER: 'REMINDER',
    SMS: 'SMS',
    TEMPLATE: 'TEMPLATE',
    EMAIL: 'EMAIL',
};
export function Automation() {
    const [activeTab, setActiveTab] = React.useState(automationTabKeys.REMINDER);
    const onChange = (key: string) => { setActiveTab(key); };
    const tabItems: TabsProps['items'] = [
        {
            key: automationTabKeys.REMINDER,
            label: 'Reminder',
            children: <AutomationReminder/>
        },
        // {
        //     key: automationTabKeys.SMS,
        //     label: 'SMS',
        //     children: (
        //         <>SMS</>
        //     )
        // },
        {
            key: automationTabKeys.TEMPLATE,
            label: 'Template',
            children: <EmailTemplate />
        },
        {
            key: automationTabKeys.EMAIL,
            label: 'Email',
            children: <AutomationEmailSetting />
        },
    ];
    return (
        <>
            <PageLayout
                pageTitleTransCode='Automation'
            >
                <Block >
                    <Tabs defaultActiveKey={activeTab} items={tabItems} onChange={onChange} />
                </Block>
            </PageLayout>   
        </>
    );
}
