import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { managementProfileState } from '@esg/ui';
import { WithUserProfile } from './children';

export const Home = () => {
    const [currentUser] = useRecoilState(managementProfileState);

    if (!currentUser) {
        return (
            <Navigate to='/errors/unauthorized' />
        );
    }

    return (
        <WithUserProfile currentUserId={currentUser.id}>
            {(userProfile) => {
                if (userProfile.userOrganizations.length === 0) {
                    return <Navigate to='/setup' />;
                }

                return <Outlet />;
            }}
        </WithUserProfile>
    );
};