import { BreakTimeGetDetailResponse, getAllEmployees, EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { getAllAppointmentSetting } from '@esg/business-schedule';
import { dayjs } from '@esg/shared';
import { useHttpQuery } from '@esg/ui';
import React, { createContext, useMemo, useContext, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

export interface BreakTimeContextProps {}

interface IBreakTimeContext extends BreakTimeContextProps {
	readonly timeStep: number;
	readonly currentDate: string;
	readonly employees: EmployeeGetDetailResponse[];
}

export const BreakTimeContext = createContext<IBreakTimeContext>(null!);

export const BreakTimeProvider = ({
    children,
    ...props
}: PropsWithChildren<BreakTimeContextProps>) => {
    const { formState: { defaultValues } } = useFormContext<BreakTimeGetDetailResponse>();
    const { data: employeeResponse } = useHttpQuery(getAllEmployees, {
        query: {
            isAllowBooking: true,
            pageSize: 100,
        },
    });

    const { data: appointmentSettings } = useHttpQuery(getAllAppointmentSetting);

    const context = useMemo<IBreakTimeContext>(
        () => ({
            ...props,
            employees: employeeResponse?.items ?? [],
            currentDate: defaultValues!.date ?? dayjs().toISOString(),
            timeStep: appointmentSettings?.items[0].timeSlotDuration ?? 30,
        }),
        [appointmentSettings?.items, defaultValues, employeeResponse?.items, props]
    );

    return <BreakTimeContext.Provider value={context}>{children}</BreakTimeContext.Provider>;
};

export const useBreakTimeContext = () => {
    return useContext(BreakTimeContext);
};
