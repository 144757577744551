import './CustomerDetailTable.scss';
import { FlexBox, IconRowLeft, IconRowRight, Spacer } from '@esg/ui';
import { TabsProps } from 'antd';
import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { Tabs } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { GetAllCustomerActivityResponseItem } from '@esg/business-crm/api/customer-activity';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { CustomerAppointmentTable } from './customer-table/CustomerAppointmentTable';
import { CustomerSaleTable } from './customer-table/CustomerSaleTable';
import { CustomerProductTable } from './customer-table/CustomerProductTable';
import { CustomerActivity } from './CustomerActivity';
import { translateCodes } from '@/locales';

interface CustomerDetailTableProps {
    customer?: GetCustomerDetailResponse;
    refetchOverview: () => void;
    customerActivities: GetAllCustomerActivityResponseItem[];
    appointmentUpcoming: AppointmentGetDetailResponse[];
    refetchAppointmentUpcoming: () => void;
    isLoadingAppointmentUpcoming: boolean;
}

export const CustomerDetailTable = ({ 
    customer, 
    refetchOverview, 
    customerActivities, 
    appointmentUpcoming,
    refetchAppointmentUpcoming,
    isLoadingAppointmentUpcoming
}: CustomerDetailTableProps) => {

    const { t } = useTranslation();

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <p style={{ fontSize: 16 }}>{t(translateCodes.ACTIVITY)}</p>,
            children: customer && <CustomerActivity 
                appointmentUpcoming={appointmentUpcoming} 
                customer={customer} 
                customerActivities={customerActivities}
                refetchAppointmentUpcoming={refetchAppointmentUpcoming}
                isLoadingAppointmentUpcoming={isLoadingAppointmentUpcoming}
            />,
        },
        {
            key: '2',
            label: <p style={{ fontSize: 16 }}>{t(translateCodes.APPOINTMENTS)}</p>,
            children: customer && <CustomerAppointmentTable customer={customer} />,
        },
        {
            key: '3',
            label: <p style={{ fontSize: 16 }}>{t(translateCodes.SALES)}</p>,
            children: customer && <CustomerSaleTable customer={customer!} refetchOverview={refetchOverview} />,
        },
        {
            key: '4',
            label: <p style={{ fontSize: 16 }}>{t(translateCodes.PRODUCTS)}</p>,
            children: customer && <CustomerProductTable customer={customer!} refetchOverview={refetchOverview} />,
        },
    ];

    return (
        <FlexBox direction='column'>
            <Tabs
                style={{ padding: 5 }}
                tabBarExtraContent={{
                    right: <FlexBox >
                        <IconRowLeft color='#98A2B3' />
                        <Spacer width={16} />
                        <IconRowRight color='#475467' />
                    </FlexBox>
                }}
                defaultActiveKey='1' items={items} />
            <Spacer height={16} />
        </FlexBox>

    );
};
