type MediatorHandler<TRequest, TResponse> = (request?: TRequest) => Promise<(TResponse | void)> | (TResponse | void);

export class Mediator<TRequest, TResponse> {
    private _handler: MediatorHandler<TRequest, TResponse> | undefined;

    constructor(handler?: MediatorHandler<TRequest, TResponse>) {
        this._handler = handler;
    }

    public setHandler = (handler: MediatorHandler<TRequest, TResponse>) => {
        if (!this._handler) {
            this._handler = handler;
            return;
        }

        throw new Error('Handler already added');
    };

    public send = async (request?: TRequest) => {
        if (!this._handler) {
            throw new Error('Handler not found');
        }

        return await this._handler(request);
    };
}