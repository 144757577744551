export enum ReferredTypeEnum {
    Employee = 0,
    Client = 1,
}

export const referredTypeOptions = [
    {
        label: 'Employee',
        value: ReferredTypeEnum.Employee,
    },
    {
        label: 'Client',
        value: ReferredTypeEnum.Client,
    },
];
