import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface EmployeeUpdateJobStatusRequest extends HttpRequestData {
	readonly body: {
		readonly id: string;
		readonly jobStatus: number;
	};
}

export const updateEmployeeJobStatus = httpUtil.createHttpRequestMeta<EmployeeUpdateJobStatusRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/employees/:id/update-job-status',
    method: 'PATCH',
    authentication: 'bearer',
});
