import { IconGoogle, Spacer, Text, useHttpCommand , managementProfileState } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { disconnectProvider } from '@esg/business-account';
import { ConnectSocialItem } from '.';
import { translateCodes } from '@/locales';

import { env } from '@/configs';

export const ConnectSocials = () => {
    const [currentProfile, setCurrentProfile] = useRecoilState(managementProfileState);
    const isConnectGoogle = currentProfile?.loginMethods?.includes('google');
    const isOnlyConnectGoogle = isConnectGoogle && currentProfile?.loginMethods.length === 1;

    const { mutateAsync, isPending } = useHttpCommand(disconnectProvider, {
        onSuccess: (response) => {
            setCurrentProfile({
                ...currentProfile!,
                loginMethods: response.loginMethods
            });
        },
    });

    const handleGoogleClick = async () => {
        if (isConnectGoogle) {
            await mutateAsync({ body: { userId: currentProfile!.id, provider: 'google' } });
        }
        else {
            window.location.href = `${env.GOOGLE_CHALLENGE_URL}?action=connect&userId=${currentProfile!.id}`;
        }
    };

    return (
        <>
            <Spacer type='margin' bottom={12} top={32} >
                <Text
                    fontSize={16}
                    fontWeight={700}
                    lineHeight='24px'
                    translateCode={translateCodes.CONNECT_A_SOCIAL_ACCOUNT}
                />
            </Spacer>
            <Card bordered={false}  >
                <ConnectSocialItem
                    disabled={isOnlyConnectGoogle}
                    icon={<IconGoogle />}
                    labelCode={translateCodes.GOOGLE}
                    connected={isConnectGoogle}
                    onClick={handleGoogleClick}
                    isLoading={isPending}
                />
                {/* <Divider /> */}
                {/* <ConnectSocialItem
                    icon={<IconFacebook />}
                    labelCode={translateCodes.FACEBOOK}
                    connected={true}
                /> */}
            </Card>
        </>
    );
};
