import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { TimeBlockRepeatBy, TimeBlockRepeatType } from './Types';

interface TimeBlockUpdateResponse extends HttpRequestData {
	body: {
		readonly id: string;
		readonly date: string;
		readonly title: string;
		readonly endTime: string;
		readonly repeatTo: string;
		readonly startTime: string;
		readonly employeeId: string;
		readonly occurrences?: number;
		readonly specificDate?: string;
		readonly repeatBy: TimeBlockRepeatBy;
		readonly repeatType?: TimeBlockRepeatType;
	};
}

export const updateTimeBlock = httpUtil.createHttpRequestMeta<TimeBlockUpdateResponse, void>({
    method: 'PATCH',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/time-blocks/:id',
});