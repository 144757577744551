import { globalDrawerState } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { TimeBlockRepeatBy, TimeBlockRepeatType } from '@esg/business-resource-management';
import { useCallback } from 'react';
import { dayjs } from '@esg/shared';
import { TimeBlockCreateValues, TimeBlockForm, TimeBlockUpdateValues } from '@/components';
import { translateCodes } from '@/locales';

interface ITimeBlockCreatePrs {
	readonly mode: 'create';
	readonly currentDate: string;
	readonly defaultEmployee?: string;
}

interface ITimeBlockUpdatePrs {
	readonly mode: 'update';
	readonly defaultValues: TimeBlockUpdateValues;
}

type TimeBlockFormPrs = ITimeBlockCreatePrs | ITimeBlockUpdatePrs;

const getCreateValues = ({
    currentDate,
    defaultEmployee,
}: ITimeBlockCreatePrs): TimeBlockCreateValues => {
    const date = dayjs(currentDate).startOf('d').toISOString();

    return {
        date,
        title: '',
        repeatTo: date,
        startTime: currentDate,
        repeatBy: TimeBlockRepeatBy.NONE,
        repeatType: TimeBlockRepeatType.SPECIFIC_DATE,
        endTime: dayjs(currentDate).add(1, 'h').toISOString(),
        employeeIds: defaultEmployee ? [defaultEmployee] : [],
    };
};

export const useTimeBlockForm = () => {
    const [, openDrawer] = useRecoilState(globalDrawerState);

    const showForm = useCallback(
        (params: TimeBlockFormPrs) => {
            const defaultValues =
				params.mode === 'create' ? getCreateValues(params) : params.defaultValues;

            openDrawer({
                isOpen: true,
                titleTransCode: translateCodes.ADD_TIME_BLOCK,
                content: <TimeBlockForm defaultValues={defaultValues} />,
            });
        },
        [openDrawer]
    );

    return showForm;
};
