import { httpUtil } from '@esg/framework';

import { env } from '../../config';

export interface UpsertPasswordRequest {
    readonly body: {
        readonly userId: string;
        readonly currentPassword?: string;
        readonly newPassword: string;
    };
}

export interface UpsertPasswordResponse {
    readonly id: string;
    readonly loginMethods: string[];
}

export const upsertPassword = httpUtil.createHttpRequestMeta<
    UpsertPasswordRequest,
    UpsertPasswordResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/account/upsert-password',
    method: 'PATCH',
});
