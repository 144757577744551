import React from 'react';
import { IconProps } from '../../Types';

export const IconIntegrationMail = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='4' y='4' width='32' height='32' rx='10' fill='white' />
            <path
                opacity='0.5'
                d='M23.6667 5H16.3334C9.41948 5 5.96252 5 3.81463 7.1967C1.66675 9.3934 1.66675 12.9289 1.66675 20C1.66675 27.0711 1.66675 30.6066 3.81463 32.8033C5.96252 35 9.41948 35 16.3334 35H23.6667C30.5807 35 34.0376 35 36.1855 32.8033C38.3334 30.6066 38.3334 27.0711 38.3334 20C38.3334 12.9289 38.3334 9.3934 36.1855 7.1967C34.0376 5 30.5807 5 23.6667 5Z'
                fill='#FEC84B'
            />
            <path
                d='M31.8805 13.3903C32.4639 12.9042 32.5427 12.0371 32.0566 11.4538C31.5704 10.8704 30.7034 10.7916 30.12 11.2777L26.1621 14.576C24.4516 16.0014 23.2641 16.9878 22.2615 17.6326C21.2911 18.2568 20.6329 18.4663 20.0003 18.4663C19.3676 18.4663 18.7095 18.2568 17.739 17.6326C16.7365 16.9878 15.5489 16.0014 13.8385 14.576L9.88053 11.2777C9.29715 10.7916 8.43013 10.8704 7.94397 11.4538C7.45782 12.0371 7.53664 12.9042 8.12003 13.3903L12.1469 16.7461C13.7719 18.1003 15.089 19.1979 16.2514 19.9455C17.4623 20.7243 18.6416 21.2163 20.0003 21.2163C21.3589 21.2163 22.5382 20.7243 23.7491 19.9455C24.9116 19.1979 26.2287 18.1003 27.8537 16.7461L31.8805 13.3903Z'
                fill='#FFFCF5'
            />
        </svg>
    );
};

IconIntegrationMail.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
