import { AppButton, Block } from '@esg/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { translateCodes } from '@/locales';

interface FormLayoutBookingSettingProps {
    readonly isPending?: boolean;
    readonly children?: React.ReactNode;
}

export default function FormLayoutBookingSetting(props: FormLayoutBookingSettingProps) {
    const { isPending } = props;
    const {formState} = useFormContext();

    const isFormDirty = formState.isDirty;
    
    return (
        <Block position='relative'>
            <AppButton
                disabled={!isFormDirty}
                className='btn-save-change'
                loading={isPending}
                htmlType='submit'
                type='primary'
                translateCode={translateCodes.SAVE_CHANGE}
            />
            {props.children}
        </Block>
    );
}
