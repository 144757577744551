import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface CreateCustomerGroupRequest extends HttpRequestData {
    readonly body: {
        readonly name: string;
        readonly active?: boolean;

    };
}

export interface CreateCustomerGroupResponse {
    readonly id: string;
    readonly name: string;
    readonly active?: boolean;

}

export const createCustomerGroup = httpUtil.createHttpRequestMeta<
    CreateCustomerGroupRequest,
    CreateCustomerGroupResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-groups',
    method: 'POST',
    authentication: 'bearer',
});
