import { managementApis } from '@esg/business-management';
import {
    FormLayout,
    FlexBox,
    FormBase,
    InputField,
    TextAreaField,
    useHttpCommand,
    SelectField,
    useHttpQuery,
    subDrawerState,
    useLocalesFormat,
} from '@esg/ui';
import { Card, Select } from 'antd';
import React, { useMemo } from 'react';

import { GetAllProductResponseItem } from '@esg/business-management/api/product';
import { taxApi } from '@esg/business-setting';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { ProductCapacityType } from '@esg/shared/enum';
import { ProductCategoryForm } from '../product-category';
import { productRulers } from '../rules/productRulers';
import { ProductUnit } from './children';
import { translateCodes } from '@/locales';

interface ProductValues {
    id?: string;
    name: string;
    productCategoryId: string;
    productUnitId: string;
    productCapacityValue?: number;
    productCapacityType?: ProductCapacityType;
    originalPrice: number;
    sellingPrice: number;
    taxSettingId?: string;
    description?: string;
}

interface ProductFormProps {
    readonly onSuccess?: (response?: GetAllProductResponseItem) => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: ProductValues;
}

export const ProductForm = (props: ProductFormProps) => {
    const {onSuccess} = props;
    const { t } = useTranslation();
    const isUpdate = !!props.defaultValues?.id;
    const { formatCurrencySymbol } = useLocalesFormat();

    const [, setSubDrawerState] = useRecoilState(subDrawerState);
    const resetSubDrawerState = useResetRecoilState(subDrawerState);

    const { data: tax } = useHttpQuery(taxApi.taxGetAll);
    const { mutateAsync: createProduct, isPending: isLoadingCreate } = useHttpCommand(
        managementApis.createProduct, { onSuccess }
    );
    const { mutateAsync: updateProduct, isPending: isLoadingUpdate } = useHttpCommand(
        managementApis.updateProduct, { onSuccess }
    );

    const { data: productCategories , refetch: refetchProductCategory } = useHttpQuery(managementApis.getAllProductCategory);

    const optionsProductCategory = useMemo(() => productCategories?.items?.map((item) => ({
        label: item.name,
        value: item.id,
    }))
    , [productCategories]);
    
    const optionsTax = useMemo(() => tax?.items.map((i) => ({
        label: i.name,
        value: i.id
    })) ?? []
    , [tax]);

    const capacityOptions = [
        {
            label: ProductCapacityType[ProductCapacityType.g].toLowerCase(),
            value: 0,
        },
        {
            label: ProductCapacityType[ProductCapacityType.ml].toLowerCase(),
            value: 1,
        },
    ];

    const onSubmit = React.useCallback(
        async (values: ProductValues) => {
            if (isUpdate) {
                await updateProduct({
                    pathData: { id: props.defaultValues?.id },
                    body:values
                });
                return;
            }

            await createProduct({
                body: {
                    ...values,
                    productCapacityType: values.productCapacityType ? values.productCapacityType : 0
                }}
            );
        },
        [createProduct, isUpdate, props, updateProduct]
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const showCreateProductCategory = (form: any)=>{
        setSubDrawerState({
            isOpen: true,
            titleTransCode: t(translateCodes.TITLE_FORM_CREATE_PRODUCT_CATEGORY),
            content: (
                <ProductCategoryForm
                    onSuccess={(res) => {
                        refetchProductCategory();
                        form.setValue('productCategoryId', res?.id);
                        resetSubDrawerState();
                    }}
                />
            ),
        });
    };

    return (
        <FormBase onSubmit={onSubmit} defaultValues={{
            ...props.defaultValues,
        }}> 
            {(form) => (
                <FormLayout
                    onDeleted={props.onDelete}
                    isLoadingButton={isLoadingCreate || isLoadingUpdate}
                    labelSubmitCode={
                        props.defaultValues?.id
                            ? translateCodes.SAVE
                            : translateCodes.CREATE
                    }
                >
                    <Card bordered={false}>
                        <FlexBox preset='column-start' gap={8}>
                            <FlexBox preset='row-start' gap={16}>
                                <InputField
                                    rules={productRulers.name(t)}
                                    name='name'
                                    label={translateCodes.NAME}
                                    placeholder={
                                        translateCodes.ENTER_PRODUCT_CATEGORY_NAME
                                    }
                                />
                                <SelectField
                                    filterOption={false}
                                    name='productCategoryId'
                                    label= {t(translateCodes.PRODUCT_CATEGORY)}
                                    placeholder= {t(translateCodes.SELECT_PRODUCT_CATEGORY)}
                                    rules={productRulers.productCategory}
                                    options={optionsProductCategory}
                                    onCreate={()=>showCreateProductCategory(form)}
                                />
                            </FlexBox>
                            <FlexBox preset='row-start' gap={16}>
                                <ProductUnit />
                                <InputField
                                    addonAfter={
                                        <Select
                                            value={form.watch('productCapacityType')}
                                            options={capacityOptions}
                                            onSelect={(value) => { 
                                                form.setValue('productCapacityType', value, {
                                                    shouldDirty: true,
                                                });
                                            }}
                                        />
                                    }
                                    name='productCapacityValue'
                                    label={t(translateCodes.CAPACITY)}
                                    placeholder={t(translateCodes.CAPACITY_PLACEHOLDER)}
                                    rules={productRulers.capacity}
                                />
                            </FlexBox>
                            <FlexBox preset='row-start' gap={16}>
                                <InputField
                                    addonBefore={formatCurrencySymbol()}
                                    rules={productRulers.originalPrice}
                                    name='originalPrice'
                                    label={t(translateCodes.ORIGINAL_PRICE)}
                                    placeholder={t(translateCodes.ORIGINAL_PRICE_PLACEHOLDER)}
                                />
                                <InputField
                                    addonBefore={formatCurrencySymbol()}
                                    rules={productRulers.sellingPrice}
                                    name='sellingPrice'
                                    label={t(translateCodes.SELLING_PRICE)}
                                    placeholder={t(translateCodes.SELLING_PRICE_PLACEHOLDER)}
                                />
                            </FlexBox>
                            <SelectField
                                addonBefore='%'
                                addonBeforeWidth='9%'
                                name='taxSettingId'
                                label= {t(translateCodes.TAX)}
                                placeholder={t(translateCodes.TAX_PLACEHOLDER)}
                                options={optionsTax}
                            />
                            <TextAreaField
                                rules={productRulers.descriptions(t)}
                                name='description'
                                label={translateCodes.DESCRIPTION}
                                placeholder={translateCodes.DESCRIPTION_PRODUCT_PLACEHOLDER}
                            />
                        </FlexBox>
                    </Card>
                </FormLayout>
            )}
        </FormBase>
    );
};
