import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface DeleteBankAccountRequest extends HttpRequestData {
}

export const deleteBankAccount = httpUtil.createHttpRequestMeta<    
    DeleteBankAccountRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/bank-account-settings/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
