import { CloseOutlined } from '@ant-design/icons';
import { FieldArray, FlexBox, Spacer, Text, useLocalesFormat } from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppointmentService as AppointmentServiceType } from '@esg/business-schedule';
import { useAppointmentContext } from '../@share/AppointmentFormContext';
import { getServicesSummary } from '../@share/appointmentUtils';
import { AppointmentServiceDetails } from './appointment-service';
import { translateCodes } from '@/locales';

interface AppointmentServiceProps extends UseFieldArrayReturn {}

export const AppointmentService = (props: AppointmentServiceProps) => {
    const { t } = useTranslation();
    const { services } = useAppointmentContext();
    const { getValues } = useFormContext();
    const { fields, remove ,} = props;
    const { formatCurrency } = useLocalesFormat();

    return (
        <FlexBox direction='column' gap={16} className='appointment-services'>
            {fields?.map((item, i) => {
                const fieldName = `appointmentServices.${i}.appointmentServiceDetails`;
                const appointmentService = getValues(`appointmentServices.${i}`) as AppointmentServiceType;
                const { minutes, price } = getServicesSummary(appointmentService.appointmentServiceDetails, services);
                const showDeleteAppointmentService = i > 0;

                return (
                    <Card key={item.id} size='small' className='appointment-service'>
                        {showDeleteAppointmentService && (
                            <Card
                                className='extra-btn'
                                size='small'
                                hoverable
                                onClick={() => remove(i)}
                            >
                                <CloseOutlined />
                            </Card>
                        )}
                        {/* Service detail list */}
                        <FieldArray name={fieldName}>
                            {(fieldArrayProps) => (
                                <AppointmentServiceDetails
                                    {...fieldArrayProps}
                                    parentFieldName={fieldName}
                                    guestIndex={i}
                                    guestCode={appointmentService.guestCode}
                                />
                            )}
                        </FieldArray>
                        {/* Summary services*/}
                        <Spacer bottom={12} />
                        <FlexBox flex='unset' justifyContent='right' gap={12}>
                            <Text>
                                {minutes}
                                {t(translateCodes.MINUTE_DURATION)}
                            </Text>
							-<Text fontWeight='bold'>{formatCurrency(price)}</Text>
                        </FlexBox>
                    </Card>
                );
            })}
        </FlexBox>
    );
};
