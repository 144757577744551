import { createBankAccount } from './createBankAccount';
import { deleteBankAccount } from './deleteBankAccount';
import { getAllBankAccount, GetAllBankAccountResponseItem } from './getAllBankAccount';
import { updateBankAccount } from './updateBankAccount';

export type { GetAllBankAccountResponseItem };

export const bankAccountApi = {
    getAllBankAccount,
    createBankAccount,
    updateBankAccount,
    deleteBankAccount
};