import { dayjs } from '@esg/shared';
import { SchedulerItem, Scheduler } from '@esg/ui/components/schedulers';
import { Spacer, SchedulerRowInfo, Block, FlexBox, AvatarName } from '@esg/ui';
import { useCallback, useLayoutEffect, useState } from 'react';
import { Image } from 'antd';

interface RowInfoData {
    title: string;
    isUnassigned?: boolean;
}

interface ItemData {
    title: string;
}

const renderRowInfo = (rowInfo: SchedulerRowInfo<RowInfoData>) => (
    <Block
        position='absolute'
        height='calc(100% - 2px)'
        width='calc(100% - 2px)'
    >
        <FlexBox height='100%' gap={12} alignItems='center'>
            <Spacer type='padding' left={16}/>
            {rowInfo.data.isUnassigned ? (
                <Image
                    src='/assets//icons/Default-Employee.svg'
                    width={40}
                    height={40}
                    preview={false}
                />
            ) : (
                <AvatarName fullName={rowInfo.data.title}/>
            )}
            {rowInfo.data.title}
            <Spacer type='padding' right={16}/>
        </FlexBox>
    </Block>
);

const defaultTestRows: SchedulerRowInfo<RowInfoData>[] = [{
    key: '1',
    allowOverlap: true,
    data: {
        title: 'Unassigned',
        isUnassigned: true,
    }
},
{
    key: '2',
    data: {
        title: 'Employee 1'
    }
},
{
    key: '3',
    data: {
        title: 'Employee 2'
    }
}];

const renderItem = (item: SchedulerItem<ItemData>) => (
    <Block
        border='1px solid gray'
        backgroundColor='#e5e5e5'
        height='calc(100% - 2px)'
        width='calc(100% - 2px)'
    >
        <FlexBox>
            {item.data.title}
        </FlexBox>
    </Block>
);

const defaultTestItems: SchedulerItem<ItemData>[] = [
    {
        key: '1',
        rowKey: '1',
        startTime: dayjs().startOf('day').add(8, 'hour'),
        endTime: dayjs().startOf('day').add(9, 'hour'),
        data: {
            title: 'item-1'
        }
    },
    {
        key: '1-1',
        rowKey: '1',
        startTime: dayjs().startOf('day').add(8, 'hour').add(30, 'minute'),
        endTime: dayjs().startOf('day').add(9, 'hour').add(30, 'minute'),
        data: {
            title: 'item-1-1'
        }
    },
    {
        key: '2',
        rowKey: '2',
        startTime: dayjs().startOf('day').add(9, 'hour'),
        endTime: dayjs().startOf('day').add(11, 'hour'),
        data: {
            title: 'item-2'
        }
    },
    {
        key: '2-2',
        rowKey: '2',
        startTime: dayjs().startOf('day').add(8, 'hour').add(15, 'minute'),
        endTime: dayjs().startOf('day').add(9, 'hour').add(10, 'minute'),
        data: {
            title: 'item-2-2'
        }
    },
    {
        key: '3',
        rowKey: '3',
        startTime: dayjs().startOf('day').add(12, 'hour'),
        endTime: dayjs().startOf('day').add(13, 'hour'),
        data: {
            title: 'item-3'
        }
    },
    {
        key: '3-2',
        rowKey: '3',
        startTime: dayjs().startOf('day').add(9, 'hour').add(30, 'minute'),
        endTime: dayjs().startOf('day').add(10, 'hour').add(30, 'minute'),
        data: {
            title: 'item-3-2'
        }
    }
];

const fixedStartTime = dayjs().startOf('day').add(8, 'hour');
const fixedEndTime = dayjs().startOf('day').add(18, 'hour');

export const TestPage = () => {
    const [schedulerHeight, setSchedulerHeight] = useState(0);

    const [rows] = useState<SchedulerRowInfo<RowInfoData>[]>(defaultTestRows);
    const [items, setItems] = useState<SchedulerItem<ItemData>[]>(defaultTestItems);

    const onDrop = useCallback(
        (item: SchedulerItem, rowInfo: SchedulerRowInfo) => {
            setItems((prevItems) => {
                const newItems = prevItems.map((prevItem) => {
                    if (prevItem.key === item.key) {
                        return {
                            ...prevItem,
                            rowKey: rowInfo.key
                        };
                    }
                    return prevItem;
                });
                return newItems;
            });
        },
        []
    );

    useLayoutEffect(
        () => {
            const marginValue = 64;
            const mainLayoutContent = document.querySelector('.main-layout-content');
            if (mainLayoutContent) {
                const mainLayoutContentHeight = mainLayoutContent.clientHeight;
                setSchedulerHeight(mainLayoutContentHeight - marginValue);
            }
        },
        []
    );

    return (
        <Spacer size='lg' height={schedulerHeight}>
            <Scheduler
                layout='horizontal'
                minutesPerCell={15}
                columns={[]}
                rowInfos={rows}
                items={items}
                startTime={fixedStartTime}
                endTime={fixedEndTime}
                baseColumnWidth={100}
                baseRowHeight={100}
                onDrop={onDrop}
                renderRowInfo={renderRowInfo}
                renderItem={renderItem}
                renderTimeMaker={() => (
                    <div
                        style={{
                            borderLeft: '1px dashed red',
                            height: 'calc(100% + 25px)',
                            position: 'absolute',
                            top: -25,
                            zIndex: 1
                        }}
                    />
                )}
            />
        </Spacer>
    );
};