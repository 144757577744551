import { FlexBox, FormBase, FormLayoutModal, InputField, globalModalState, managementProfileState, useHttpCommand, Text } from '@esg/ui';
import React, { useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { upsertPassword } from '@esg/business-account';
import { HttpRequestError } from '@esg/framework';
import { passwordRules } from '../rules';
import { translateCodes } from '@/locales';

interface ChangePasswordValues {
    userId: string;
    currentPassword?: string;
    newPassword: string;
}

interface UpdatePasswordFormProps {
    isUpdatePassword: boolean
}

export const UpdatePasswordForm = (props: UpdatePasswordFormProps) => {
    const resetModalState = useResetRecoilState(globalModalState);
    const { t } = useTranslation();
    const [currentProfile, setCurrentProfile] = useRecoilState(managementProfileState);
    const [isError, setIsError] = useState(false);
    const {mutateAsync: upsertPasswordRequest, isPending} = useHttpCommand(upsertPassword, {
        showNotificationError: false,
        onSuccess: (response) => {
            setCurrentProfile({
                ...currentProfile!,
                loginMethods: response.loginMethods
            });
            resetModalState();
        },
        onError(error) {
            const errorResponse = error as HttpRequestError;

            if (errorResponse.response.status == 400) {
                setIsError(true);
            }

        },
    });
    const onSubmit= async(value: ChangePasswordValues) => {
        await  upsertPasswordRequest({
            body: {
                ...value,
                userId: currentProfile!.id,

            }
        });
    };

    return (
        <FormBase onSubmit={onSubmit}>
            {(form) =>
            {
                form.register('confirmNewPassword', {
                    validate: (val: string) => {
                        if (form.watch('newPassword') !== val) {
                            return t(translateCodes.PASSWORD_NOT_MATCH);
                        }
                    },
                });
                return (
                    <FormLayoutModal
                        useSubmitButton={true}
                        isLoadingButton={isPending}
                        onCancel={resetModalState}
                        okTextCode={props.isUpdatePassword ? translateCodes.SAVE : translateCodes.CREATE}
                    >
                        <FlexBox direction='column' gap={16}>
                            {props.isUpdatePassword && (
                                <FlexBox direction='column'>
                                    <InputField
                                        name='currentPassword'
                                        label={translateCodes.CURRENT_PASSWORD}
                                        type='password'
                                        placeholder={translateCodes.CURRENT_PASSWORD}
                                        tooltipContent={translateCodes.VALIDATE_PASSWORD}
                                        rules={passwordRules.currentPassword}
                                    />
                                    {isError&&<Text color='red' translateCode={translateCodes.CURRENT_PASSWORD_NOT_MATCH} />}
                                </FlexBox>
                            )}
                            <InputField
                                name='newPassword'
                                label={translateCodes.NEW_PASSWORD}
                                type='password'
                                rules={passwordRules.password}
                                placeholder={translateCodes.NEW_PASSWORD}
                                tooltipContent={translateCodes.VALIDATE_PASSWORD}
                            />
                            <InputField
                                name='confirmNewPassword'
                                label={translateCodes.CONFIRM_NEW_PASSWORD}
                                type='password'
                                placeholder={translateCodes.CONFIRM_NEW_PASSWORD}
                                tooltipContent={translateCodes.VALIDATE_CONFIRM_PASSWORD}
                                rules={passwordRules.confirmPassword}
                            />
                            
                        </FlexBox>
                    </FormLayoutModal>
                );
            } }
        </FormBase>
    );
};

