import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetStateByCountryRequest extends HttpRequestData {
    readonly pathData: {
        country: string;
    }
}

export interface GetStateByCountryResponse {
    readonly id: string;
    name: string;
    iso2: string;
}
export const getStateByCountry = httpUtil.createHttpRequestMeta<GetStateByCountryRequest, GetStateByCountryResponse[]>({
    baseUrl: env.apiUrlCountry,
    path: '/v1/countries/:country/states',
    method: 'GET',
});
