import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateAppointmentSettingRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        timeSlotDuration: number;
        doubleAppointmentEnabled: boolean;
        doubleAppointmentMaxSlot: number;
        employeeSelectionRequired: boolean;
        autoNoShowEnabled: boolean;
        autoNoShowAfterTime: number;
        autoNoShowPromptEnabled: boolean;
    };
}

export interface UpdateAppointmentSettingResponse {
    id: string;
    timeSlotDuration: number;
    doubleAppointmentEnabled: boolean;
    doubleAppointmentMaxSlot: number;
    employeeSelectionRequired: boolean;
    autoNoShowEnabled: boolean;
    autoNoShowAfterTime: number;
    autoNoShowPromptEnabled: boolean;
}

export const updateAppointmentSetting = httpUtil.createHttpRequestMeta<
    UpdateAppointmentSettingRequest,
    UpdateAppointmentSettingResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/appointment-Settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
