
import React from 'react';
import { Block, FlexBox, IconSaleEmpty, Spacer, Text } from '@esg/ui';
import { groupBy } from 'lodash';
import { SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { useTranslation } from 'react-i18next';
import OrderServiceItem from './OrderServiceItem';
import OrderProductItem from './OrderProductItem';
import { translateCodes } from '@/locales';

interface SaleOrderListProps {
    saleDetails: SaleDetailGetAllResponseItem[];
    onDeleteSaleDetail: (id: string) => void;
    onEditSaleDetail: (saleDetail: SaleDetailGetAllResponseItem) => void;
}

export default function SaleOrderList(props: SaleOrderListProps) {
    const {saleDetails, onDeleteSaleDetail, onEditSaleDetail} = props;
    const saleDetailGroupsByType = groupBy(saleDetails, 'objectType');
    const {t} = useTranslation();

    if(saleDetails.length === 0) {
        return (
            <FlexBox direction='column' justifyContent='center' alignItems='center' height='calc(100% - 200px)'>
                <Spacer top={32}/>
                <IconSaleEmpty/>
                <Block>
                    <Text display='block' textAlign='center' fontWeight={600}> 
                        {t(translateCodes.CART_NO_ITEM)}
                    </Text>
                    <Text color='#7F8FA4'>
                        {t(translateCodes.CART_NO_ITEM_DESC)}
                    </Text>
                </Block>
            </FlexBox>
        );
    }

    return (
        <Block>
            <Spacer type='padding' top={4} bottom={16}>
                {Object.keys(saleDetailGroupsByType).map((type) => {
                    return (
                        <Block key={type} >
                            <Spacer type='margin' top={12} bottom={6} >
                                <Text fontWeight={600} fontSize={16}>{t(type)}</Text>
                                {saleDetailGroupsByType[type].length > 1 && (
                                    <Spacer left={8} display='inline' >
                                        <Text>{`(${saleDetailGroupsByType[type].length})`}</Text>
                                    </Spacer>
                                )}
                            </Spacer>
                            <FlexBox gap={16}  direction='column' >                        
                                {saleDetailGroupsByType[type].map((saleDetail) => 
                                {
                                    if(saleDetail.objectType === 'SERVICE') {
                                        return (
                                            <OrderServiceItem 
                                                key={saleDetail.id} 
                                                saleDetail={saleDetail} 
                                                onDeleteOrderItem={() => onDeleteSaleDetail(saleDetail.id)} 
                                                onEditOrderItem={() => onEditSaleDetail(saleDetail)}
                                            />
                                        );
                                    }
                                    if(saleDetail.objectType === 'PRODUCT') {
                                        return (
                                            <OrderProductItem 
                                                key={saleDetail.id} 
                                                saleDetail={saleDetail} 
                                                onDeleteOrderItem={() => onDeleteSaleDetail(saleDetail.id)} 
                                                onEditOrderItem={() => onEditSaleDetail(saleDetail)}
                                            />
                                        );
                                    }
                                }
                                )}
                            </FlexBox>
                        </Block>
                    );
                })}
            </Spacer>
        </Block>
    );
}
