import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { GetCustomerNoteDetailResponse } from './getCustomerDetail';

interface UpdateCustomerNoteRequest extends HttpRequestData {
    readonly body: {
        readonly id: string;
        readonly customerId: string;
        readonly value?: string;
    };
}

export const updateCustomerNote = httpUtil.createHttpRequestMeta<
    UpdateCustomerNoteRequest,
    GetCustomerNoteDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-notes/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
