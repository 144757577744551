import { HttpRequestData, httpUtil } from '@esg/framework';

import { ProductCapacityType } from '@esg/shared/enum';
import { env } from '../../configs';
import { GetAllProductResponseItem } from './getAllProduct';

interface CreateProductRequest extends HttpRequestData {
    readonly body: {
        name: string;
        productCategoryId: string;
        productUnitId?: string;
        productCapacityValue?: number;
        productCapacityType?: ProductCapacityType;
        originalPrice: number;
        sellingPrice: number;
        taxSettingId?: string;
        description?: string;
    };
}

export const createProduct = httpUtil.createHttpRequestMeta<
    CreateProductRequest,
    GetAllProductResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/products',
    method: 'POST',
    authentication: 'bearer',
});
