import React from 'react';
import { IconProps } from '../../Types';

export const IconCheckCircle = (props: IconProps) => {
    return (
        <svg
            {...props}
            width={16}
            height={16}
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.6869 6.68656C10.8822 6.4913 10.8822 6.17472 10.6869 5.97945C10.4917 5.78419 10.1751 5.78419 9.97982 5.97945L7.00004 8.95923L6.02026 7.97945C5.825 7.78419 5.50842 7.78419 5.31315 7.97945C5.11789 8.17472 5.11789 8.4913 5.31315 8.68656L6.64649 10.0199C6.84175 10.2152 7.15833 10.2152 7.35359 10.0199L10.6869 6.68656Z'
                fill={props.color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.00004 0.833008C4.042 0.833008 0.833374 4.04163 0.833374 7.99967C0.833374 11.9577 4.042 15.1663 8.00004 15.1663C11.9581 15.1663 15.1667 11.9577 15.1667 7.99967C15.1667 4.04163 11.9581 0.833008 8.00004 0.833008ZM1.83337 7.99967C1.83337 4.59392 4.59428 1.83301 8.00004 1.83301C11.4058 1.83301 14.1667 4.59392 14.1667 7.99967C14.1667 11.4054 11.4058 14.1663 8.00004 14.1663C4.59428 14.1663 1.83337 11.4054 1.83337 7.99967Z'
                fill={props.color}
            />
        </svg>
    );
};

IconCheckCircle.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
