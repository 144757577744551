import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { env } from '../../configs';

interface GetRevenuePaymentDetailReportRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
        readonly paymentMethodSettingId: string;
    }
}

export const getRevenuePaymentDetailReport = httpUtil.createHttpRequestMeta<
    GetRevenuePaymentDetailReportRequest,
	PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/detail-by-payment',
    authentication: 'bearer'
});
