import React from 'react';
import { IconProps } from '../../Types';

export const IconSharp = (props: IconProps) => {
    return (
        <svg
            {...props}
            width={16}
            height={14}
            viewBox='0 0 16 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.14847 1.13395C7.22237 0.867881 7.0666 0.592276 6.80053 0.518368C6.53446 0.44446 6.25886 0.600237 6.18495 0.866305L5.17555 4.50013H2.66671C2.39056 4.50013 2.16671 4.72398 2.16671 5.00013C2.16671 5.27627 2.39056 5.50013 2.66671 5.50013H4.89778L3.87926 9.16679H1.33337C1.05723 9.16679 0.833374 9.39065 0.833374 9.66679C0.833374 9.94294 1.05723 10.1668 1.33337 10.1668H3.60148L2.85162 12.8663C2.77771 13.1324 2.93348 13.408 3.19955 13.4819C3.46562 13.5558 3.74123 13.4 3.81513 13.1339L4.63934 10.1668H9.60148L8.85162 12.8663C8.77771 13.1324 8.93348 13.408 9.19955 13.4819C9.46562 13.5558 9.74123 13.4 9.81513 13.1339L10.6393 10.1668H13.3334C13.6095 10.1668 13.8334 9.94294 13.8334 9.66679C13.8334 9.39065 13.6095 9.16679 13.3334 9.16679H10.9171L11.9356 5.50013H14.6667C14.9429 5.50013 15.1667 5.27627 15.1667 5.00013C15.1667 4.72398 14.9429 4.50013 14.6667 4.50013H12.2134L13.1485 1.13395C13.2224 0.867881 13.0666 0.592276 12.8005 0.518368C12.5345 0.44446 12.2589 0.600237 12.1849 0.866305L11.1756 4.50013H6.21342L7.14847 1.13395ZM9.87926 9.16679L10.8978 5.50013H5.93564L4.91712 9.16679H9.87926Z'
                fill={props.color}
            />
        </svg>
    );
};

IconSharp.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
