import { Block, FlexBox, Spacer, Text } from '@esg/ui';
import { Segmented } from 'antd';
import React from 'react';
import { CreateSaleDetailRequest, SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { useRecoilState } from 'recoil';
import { SegmentedValue } from 'antd/es/segmented';
import { useTranslation } from 'react-i18next';
import { InitDataSaleForm } from '../../SaleForm';
import ServicesTab from './ServicesTab';
import ProductTab from './ProductTab';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';
import { mainLayoutTheme } from '@/layouts/main-layout/@shared';

enum MenuOrderTabs {
    Services,
    Products,
    Packages,
    Card,
}

interface MenuOrderProps {
    readonly initDataSaleForm?: InitDataSaleForm
    readonly updateSaleDetail: (saleDetail: SaleDetailGetAllResponseItem) => Promise<void>;
    readonly createSaleDetail: (saleDetailValue: CreateSaleDetailRequest['body']) => Promise<void>;
    readonly showMenu?: boolean;
    readonly width?: string;
    readonly actions?: React.ReactNode;
    readonly isFastSale?: boolean;
}

export default function MenuOrder(props: MenuOrderProps) {
    const {isFastSale} = props;
    const {t} = useTranslation();
    const { initDataSaleForm, updateSaleDetail, createSaleDetail } = props;
    const [currentSale] = useRecoilState(currentSaleState);
    const [activeTab, setActiveTab] = React.useState<SegmentedValue>(MenuOrderTabs.Services);

    const handleCreateSaleDetail = async (saleDetailValue: CreateSaleDetailRequest['body']) => {
        const increaseQuantityService = currentSale?.saleDetails?.find(o => o.objectId === saleDetailValue.objectId);
        if (increaseQuantityService) {
            return await updateSaleDetail({
                ...increaseQuantityService,
                quantity: increaseQuantityService.quantity + 1,
                taxAmount: increaseQuantityService.taxAmount * (increaseQuantityService.quantity + 1),
            });
        }

        await createSaleDetail({
            ...saleDetailValue,
        });
    };

    const menuOrderOptions = [
        {
            label: t(translateCodes.SERVICES),
            value: MenuOrderTabs.Services,
            content:
                <ServicesTab
                    sale={currentSale!}
                    serviceCategories={initDataSaleForm?.serviceCategories}
                    handleCreateSaleDetail={handleCreateSaleDetail}
                />,
        },
        {
            label: t(translateCodes.PRODUCTS),
            value: MenuOrderTabs.Products,
            content:
                <ProductTab
                    sale={currentSale!}
                    handleCreateSaleDetail={handleCreateSaleDetail}
                    productCategories={initDataSaleForm?.productCategories}
                />
        }
    ];

    return (
        <Block 
            width={props?.width ||'33vw'} 
            className={`order-menu ${props.showMenu ? 'show' : 'hide'}`}
        >
            <FlexBox 
                backgroundColor='#fff' 
                alignItems='center' 
                height={mainLayoutTheme.headerHeight} 
                position='relative' 
                justifyContent='space-between'
                borderBottom='1px solid #EAECF0'
            >
                <Spacer type='padding' left={16}>
                    <Text fontSize={20} fontWeight={700} >Item Menu</Text>
                </Spacer>
                <Spacer type='padding' right={16}>
                    {props.actions ? props.actions : null}
                </Spacer>
            </FlexBox>
            <Block 
                height={`calc(100vh - ${isFastSale
                    ? (2*mainLayoutTheme.headerHeight + 1) 
                    : mainLayoutTheme.headerHeight}px)`}
                overflow='auto'
            >
                <Spacer type='padding' top={16} left={20} right={20}>
                    <Segmented
                        style={{ width: '100%'}}
                        size='large'
                        value={activeTab}
                        options={menuOrderOptions}
                        onChange={(value) => {
                            setActiveTab(value);
                        }}
                        block
                    />
                    {menuOrderOptions.find((o) => o.value === activeTab)?.content}
                </Spacer>
            </Block>
        </Block>
    );
}
