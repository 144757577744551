import React from 'react';
import { IconProps } from '../../Types';

export const IconArrowUpClient = (props: IconProps) => {
    return (
        <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 10L8 6L4 10' stroke='#98A2B3' strokeWidth='1.33333' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};

IconArrowUpClient.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
