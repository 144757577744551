import { ShiftByEmployeeDetail } from '@esg/business-resource-management';
import { FlexBox, SchedulerItem } from '@esg/ui';
import React from 'react';
import { Dropdown } from 'antd';
import { useShiftContext } from '../../../../ShiftContext';
import { useShiftMenu } from './@share/hooks';
import { ShiftTimeOff } from './children/ShiftTimeOff';
import { ShiftAvailable } from './children/ShiftAvailable';

interface ShiftItemProps {
	readonly shiftItem: SchedulerItem<ShiftByEmployeeDetail>;
}

export function ShiftItem(props: ShiftItemProps) {
    const {
        shiftItem: { data, startTime },
    } = props;

    const { timeOff } = data;

    const { loading } = useShiftContext();

    const shiftMenu = useShiftMenu(data, startTime.toISOString());

    if (loading) {
        return null;
    }

    return (
        <Dropdown menu={{ items: shiftMenu }} key={data.id} trigger={['click']}>
            <div className='shift-item'>
                <FlexBox className={`shift-item-inner ${timeOff ? 'time-off' : 'shift'}`}>
                    {timeOff ? <ShiftTimeOff timeOff={timeOff} /> : <ShiftAvailable shift={data} />}
                </FlexBox>
            </div>
        </Dropdown>
    );
}
