import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../config';

interface UserUpdateBranchRequest extends HttpRequestData {
    body: {
        id: string;
        branchActive: string;
    }
}
interface UserUpdateBranchResponse {
    id: string;
    branchActive: string;
}

export const userUpdateBranch = httpUtil.createHttpRequestMeta<
    UserUpdateBranchRequest,
    UserUpdateBranchResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/users/updateBranch',
    method: 'PATCH',
    authentication: 'bearer'
});
