import { GetAllServiceCategoryResponseItem } from '@esg/business-management';
import { FlexBox, InputSearch, Spacer, Text } from '@esg/ui';
import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib';
import React, { useMemo } from 'react';
import { CreateSaleDetailRequest } from '@esg/business-pos/api/sale-detail';
import { GetDetailSaleResponse } from '@esg/business-pos';
import MenuServiceItem from './menu-item/MenuServiceItem';
import { searchHelper } from '@/helpers';

interface ServicesTabProps {
    readonly serviceCategories?: GetAllServiceCategoryResponseItem[];
    handleCreateSaleDetail: (saleDetailValue: CreateSaleDetailRequest['body']) => void;
    sale: GetDetailSaleResponse
}

export default function ServicesTab(props: ServicesTabProps) {
    const {handleCreateSaleDetail, sale} = props;
    const [searchValue, setSearchValue] = React.useState<string>('');   

    const serviceCategoriesSearch = useMemo(() => {
        if(!searchValue) return props?.serviceCategories;
        return searchHelper.searchItemInCategories(props?.serviceCategories ?? [], searchValue, 'services');
    },[props?.serviceCategories, searchValue]);

    const menuOptions : CollapseProps['items'] = serviceCategoriesSearch?.map((category) => {
        return {
            key: category.id,
            label: <><Text textTransform='capitalize' fontSize={16} fontWeight={700}>{`${category.name}`}</Text> ({category?.services?.length??0}) </>,
            children: 
            <FlexBox direction='column' gap={16}>
                {category.services?.map((service) => 
                    <MenuServiceItem 
                        sale={sale}
                        key={service.id}
                        service={service} 
                        onOrderItem={handleCreateSaleDetail}
                    />
                )}
            </FlexBox>
        };
    });

    const activeKeys = menuOptions?.map((item) => item.key as string);

    return (
        <Spacer type='margin' top={16}>
            <InputSearch 
                height={40} 
                placeholder='Search an item'
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <Spacer type='margin' top={16}>
                <Collapse
                    defaultActiveKey={activeKeys}
                    bordered={false}
                    expandIconPosition='end'
                    items={menuOptions}
                />
            </Spacer>
        </Spacer>
    );
}

