import { managementApis } from '@esg/business-management';
import { AppButton, FlexBox, InputSearch, Text, useHttpQuery } from '@esg/ui';
import { Checkbox, Divider, Select } from 'antd';
import { SelectProps } from 'antd/lib';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import './ServiceFilter.scss';
import { BaseOptionType } from 'antd/es/select';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

interface ServiceFilterProps extends SelectProps {
    readonly width?: CSSProperties['width'];
    readonly serviceIds?: string[];
    readonly handleFilter: (serviceIds: string[]) => void;
    readonly setFilterOpen: (filter: string) => void;
    readonly filerOpen: string;
    readonly allServices?: boolean;
}

export const ServiceFilter = (props: ServiceFilterProps) => {
    const { t } = useTranslation();
    const { serviceIds, width = 130, handleFilter, setFilterOpen, filerOpen, allServices, ...rest } = props;
    const [searchValue, setSearchValue] = useState('');
    const [values, setValues] = useState(serviceIds || []);
    const { data: servicesResponse } = useHttpQuery(managementApis.getAllServices);
    const { data: categoriesResponse } = useHttpQuery(managementApis.getAllServiceCategory);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (serviceIds?.length === 0) {
            setValues([]);
        }
        if (filerOpen != 'service') {
            setOpen(false);
        }
    }, [serviceIds, filerOpen]);

    const services = useMemo(() => servicesResponse?.items || [], [servicesResponse?.items]);

    const options = useMemo(() => {
        const categoryIds = services.filter((o) => (
            o.name.toLowerCase().includes(searchValue.toLowerCase())
        )).map((s) => s.serviceCategoryId);
        const categories =
            categoriesResponse?.items.filter((c) => categoryIds.includes(c.id)) || [];

        return categories.map((c) => {
            const servicesByCategory = services.filter((s) => 
                s.serviceCategoryId === c.id && s.name.toLowerCase().includes(searchValue.toLowerCase())
            );

            return {
                label: c.name,
                options: servicesByCategory.map((o) => {
                    return {
                        label: o.name,
                        value: o.id,
                    };
                }),
            };
        });
    }, [categoriesResponse?.items, searchValue, services]);

    const optionRender = useCallback(
        ({ data }: { data: BaseOptionType }) => (
            <FlexBox gap={12}>
                <Checkbox checked={values.includes(data.value)} />
                <Text>{data.label}</Text>
            </FlexBox>
        ),
        [values]
    );

    const dropdownRender = useCallback(
        (options: React.ReactElement) => (
            <div onMouseLeave={() => setOpen(false)}>
                <InputSearch
                    variant='borderless'
                    name='search-service'
                    placeholder='Search service'
                    onChange={(value) => setSearchValue(value.target.value)}
                />
                {options}
                <Divider />
                <div
                    className='select-filter-actions'
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <AppButton
                        className='action-btn'
                        translateCode='CANCEL'
                        onClick={() => {
                            handleFilter?.([]);
                            setValues([]);
                        }}
                    />
                    <AppButton
                        className='action-btn'
                        type='primary'
                        translateCode='Apply'
                        onClick={() => handleFilter(values)}
                    />
                </div>
            </div>
        ),
        [handleFilter, values]
    );

    const maxTagPlaceholder = useCallback(
        (omittedValues: DisplayValueType[]) => {
            const isAll = omittedValues.length === services.length;
            const isOne = omittedValues.length === 1;
            return (
                <span>
                    {isAll
                        ? t(translateCodes.ALL_SERVICE)
                        : isOne
                            ? omittedValues[0].label
                            : `${omittedValues.length} services`}
                </span>
            );
        },
        [services.length, t]
    );

    const style: CSSProperties = useMemo(() => ({ width, height: 40 }), [width]);

    return (
        <Select
            open={open}
            onClick={() => {
                setOpen(true);
                setFilterOpen('service');
            }} value={values}
            onChange={(value) => {
                setValues(value);
            }}
            mode='multiple'
            options={options}
            style={style}
            showSearch={false}
            popupMatchSelectWidth={308}
            popupClassName='select-filter'
            optionRender={optionRender}
            menuItemSelectedIcon={null}
            maxTagCount={0}
            maxTagPlaceholder={maxTagPlaceholder}
            className={allServices ? 'placeholderSelectAll' : '' }
            placeholder={allServices ? t(translateCodes.ALL_SERVICE) : t(translateCodes.FILTER_SERVICE)}
            dropdownRender={dropdownRender}
            {...rest}
        />
    );
};
