import { JOB_STATUS } from '@esg/shared';
import {
    AppButton,
    FlexBox,
    globalModalState,
    Spacer,
    Text,
    useHttpCommand,
    useHttpQuery,
} from '@esg/ui';
import { Image , Dropdown, MenuProps } from 'antd';

import { IconMore } from '@esg/ui/components/icons';
import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { resourceApis, EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { EmployeeDeleteConfirm, useEmployeeDelete } from '../@share';
import { resourceManagementTheme } from '../../@shared';
import { EmployeeBusiness, EmployeeInformation } from './children';
import { translateCodes } from '@/locales';
import { RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL } from '@/configs';

export const EmployeeDetail = () => {
    const { id: employeeId } = useParams();
    const [, setModalState] = useRecoilState(globalModalState);
    const {
        data: employee,
        isLoading,
        isFetching,
        refetch,
    } = useHttpQuery(resourceApis.getEmployeeDetail, {
        pathData: {
            id: employeeId,
        },
    });

    const onDeleted = useEmployeeDelete(undefined, RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL);

    const handleDelete = React.useCallback(
        (employee: EmployeeGetDetailResponse) => {
            setModalState({
                content: (
                    <EmployeeDeleteConfirm {...{ employee }} />
                ),
                isOpen: true,
                onOk: () => {
                    onDeleted(employee.id);
                },
                showModalDelete: true,
            });
        },
        [onDeleted, setModalState]
    );

    const { mutateAsync } = useHttpCommand(resourceApis.updateEmployeeJobStatus, {
        onSuccess() {
            refetch();
        },
    });

    const changeJobStatus = useCallback(
        async () => {
            await mutateAsync({
                pathData: {
                    id: employee!.id
                },
                body: {
                    id: employee!.id,
                    jobStatus: employee?.jobStatus == 0 ? 1 : 0,
                },
            });
        },
        [mutateAsync, employee]
    );
    const items: MenuProps['items'] = [
        {
            key: 1,
            label: (
                <Text
                    onClick={changeJobStatus}
                    translateCode={
                        employee?.jobStatus === JOB_STATUS.ACTIVE
                            ? translateCodes.SET_AS_SEPARATED
                            : translateCodes.SET_AS_ACTIVE
                    }
                />
            ),
        },
        {
            key: 2,
            label: (
                <Text
                    onClick={() => {
                        setModalState({
                            content: <EmployeeDeleteConfirm {...{ employee }} />,
                            isOpen: true,
                            onOk: () => {
                                handleDelete(employee!);
                            },
                            showModalDelete: true,
                        });
                    }}
                    translateCode={translateCodes.DELETE_THIS_EMPLOYEE}
                    color='#D92D20'
                />
            ),
        },
    ];

    const loading = isLoading || isFetching;

    return (
        <Spacer size={24} >
            <FlexBox preset='column-start' gap={12}>
                <FlexBox gap={20}>
                    <Link to={RESOURCE_MANAGEMENT_EMPLOYEE_MEMBER_URL}>
                        <Text translateCode={translateCodes.EMPLOYEE_MEMBERS} fontWeight={500} fontSize={14} color='#475467' />
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode={employee?.fullName} fontWeight={600} fontSize={14} color='#0C6FF3' />
                </FlexBox>
                <FlexBox>
                    <FlexBox>
                        <Text translateCode={translateCodes.EMPLOYEE_DETAIL} fontWeight={700} fontSize={20} />
                    </FlexBox>
                    <Dropdown trigger={['click']} menu={{ items }}>
                        <AppButton>
                            <IconMore />
                        </AppButton>
                    </Dropdown>
                </FlexBox>
                <FlexBox className='employee-detail' gap={20}>
                    <EmployeeInformation 
                        employee={employee}
                        loading={loading}
                        refetch={refetch}
                        onDeleted={() => handleDelete(employee!)}
                    />
                    <FlexBox>
                        <EmployeeBusiness {...{ employee, loading, refetch }} />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Spacer>
    );
};
