import {IntegrationEmailService, IntegrationStatus} from '../../business/setting/api/integration/Types';

export const getIntegrationStatus = (status: IntegrationStatus) => {
    switch (status) {
    case IntegrationStatus.Disabled:
        return 'Disabled';
    case IntegrationStatus.Connected:
        return 'Connected';
    default:
        return null;
    }
};

export const getIntegrationService = (service: IntegrationEmailService) => {
    switch (service) {
    case IntegrationEmailService.Gmail:
        return 'Gmail';
    case IntegrationEmailService.Other:
        return 'Other';
    default:
        return null;
    }
};