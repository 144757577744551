import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateAutomationReminderRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        reminderTimeInSeconds?: number;
        emailReminder?: boolean;
        SMSReminder?: boolean;
    };
}

export interface UpdateAutomationReminderResponse {
    id?: string;
    reminderTimeInSeconds?: number;
    emailReminder?: boolean;
    SMSReminder?: boolean;
}

export const updateAutomationReminder = httpUtil.createHttpRequestMeta<
    UpdateAutomationReminderRequest,
    UpdateAutomationReminderResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/automation-reminders/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
