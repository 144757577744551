import { HttpRequestData, httpUtil } from '@esg/framework';
import { Dayjs } from '@esg/shared';
import { env } from '../../configs';
export interface IWorkingTimeItem {
    isDayOff: boolean;
    startTime: string | Dayjs;
    endTime: string | Dayjs;
    dayOfWeek: number;
    dayOfWeekTitle: string;
    breakTimes?: IBreakTimes[]
}

export interface IBreakTimes {
    startTime: Dayjs;
    endTime: Dayjs;
    dayOfWeek: number;
    dayOfWeekTitle: string;
    isFixed?: boolean;
}

export type IWorkingTime = IWorkingTimeItem[];
export interface UpdateBranchRequest extends HttpRequestData {
    readonly body: {
        id: string;
        name: string;
        logo?: string;
        countryCode?: string;
        phoneNumber?: string;
        currency?: string;
        tax?: string;
        dateTimeFormat?: string;
        timezone?: string;
        languages?: string;
        address: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
        organizationId?: string;
        businessHour?: IWorkingTime;
    }
}

export interface UpdateBranchResponseItem {
    id: string;
    name: string;
    logo?: string;
    countryCode?: string;
    phoneNumber?: string;
    currency?: string;
    tax?: string;
    dateTimeFormat?: string;
    timezone?: string;
    languages?: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    businessHour: IWorkingTime;
    organizationId: string;
}

export const updateBranch = httpUtil.createHttpRequestMeta<
    UpdateBranchRequest,
    UpdateBranchResponseItem
>({
    baseUrl: env.apiUrlBase,
    method: 'PATCH',
    path: '/api/v1/branches/:id',
    authentication: 'bearer',
});