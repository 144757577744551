import { GetCurrentBookingOnlineSettingResponse, UpdateBookingOnlineSettingRequest } from '@esg/business-setting/api/booking-online';
import { Block, DatePickerField, FormBase, InputNumberFieldCustom, SelectField, Spacer, SwitchField, useHttpQuery } from '@esg/ui';
import { Card } from 'antd';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { schedulerApis } from '@esg/business-schedule';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import FormLayoutBookingSetting from './layout-tab/FormLayoutTabSetting';
import {
    AppointmentMinuteStep,
    AppointmentTimeSlot,
    TimeSlotDuration,
} from '@/components/form/appointment-setting/children';
import { translateCodes } from '@/locales';
interface SettingsTabProps {
  readonly bookingOnlineSetting?: GetCurrentBookingOnlineSettingResponse;
  readonly updateBookingOnlineSetting: (
    values: Partial<UpdateBookingOnlineSettingRequest['body']>
  ) => Promise<void>;
  readonly isLoading?: boolean;
}

const bookingFeatureLimitOptions = [
    { label: '1 day', value: 1 },
    { label: '2 day', value: 2 },
    { label: '3 day', value: 3 },
    { label: '5 day', value: 5 },
    { label: '1 week', value: 7 },
    { label: '2 weeks', value: 14 },
    { label: '3 weeks', value: 21 },
    { label: '1 month', value: 30 },
    { label: '2 months', value: 60 },
    { label: '3 months', value: 90 },
    { label: '4 months', value: 120 },
    { label: '6 months', value: 180 },
    { label: '9 months', value: 270 },
    { label: '1 year', value: 365 },
    { label: 'No limit', value: 0 },
];

export default function SettingsTab(props: SettingsTabProps) {
    const { updateBookingOnlineSetting, bookingOnlineSetting } = props;
    const { t } = useTranslation();

    const { data: appointmentSetting } = useHttpQuery(schedulerApis.getDetailAppointmentSetting);

    const [currentLocale] = useRecoilState(currentLocaleState);
    const onSubmit = useCallback(
        async (values: Partial<UpdateBookingOnlineSettingRequest['body']>) => {
            await updateBookingOnlineSetting({
                ...values,
                timeSlotDuration: TimeSlotDuration.timeSlotToMinutes(
          values.timeSlotDuration as AppointmentTimeSlot
                ),
            });
        },
        [updateBookingOnlineSetting]
    );

    return (
        <Block>
            <FormBase
                onSubmit={onSubmit}
                defaultValues={{
                    ...bookingOnlineSetting,
                    doubleAppointmentEnabled: appointmentSetting?.doubleAppointmentEnabled
                        ? bookingOnlineSetting?.doubleAppointmentEnabled
                        : false,
                    bookingFeatureLimit: bookingOnlineSetting?.bookingFeatureLimit || 0,
                    timeSlotDuration: TimeSlotDuration.minutesToTimeSlot(
						(bookingOnlineSetting?.timeSlotDuration || 15) as AppointmentMinuteStep
                    ),
                    bookingOnlineDateOffs:
						bookingOnlineSetting?.bookingOnlineDateOffs?.map((date) => date.dateOff) ||
						[],
                }}
            >
                {(form) => (
                    <FormLayoutBookingSetting>
                        <TimeSlotDuration
                            label={t(translateCodes.TIME_SLOT_DURATION)}
                            description={t(translateCodes.TIME_SLOT_DURATION_DESCRIPTION)}
                            onChange={(value) => form.setValue('timeSlotDuration', value)}
                        />
                        <Spacer top={24} />
                        <Card>
                            <Block width='50%'>
                                <DatePickerField
                                    multiple={true}
                                    label={translateCodes.BOOKING_DATE_LIMIT}
                                    description={translateCodes.BOOKING_DATE_LIMIT_DESCRIPTION}
                                    name='bookingOnlineDateOffs'
                                    placeholder={translateCodes.DATE_OF_BIRTH_PLACEHOLDER}
                                    format={currentLocale?.format}
                                    maxTagCount='responsive'
                                />
                            </Block>
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                justifyContent='space-between'
                                name='doubleAppointmentEnabled'
                                label={t(translateCodes.DOUBLE_APPOINTMENT_ENABLED)}
                                description={t(
                                    translateCodes.DOUBLE_APPOINTMENT_ENABLED_DESCRIPTION
                                )}
                                width='100%'
                            />
                            <Spacer top={16} />
                            <div style={{ width: '50%' }}>
                                <InputNumberFieldCustom
                                    name='doubleAppointmentMaxSlot'
                                    rules={{
                                        max: {
                                            value: appointmentSetting?.doubleAppointmentMaxSlot || 1000,
                                            message: t(translateCodes.DOUBLE_APPOINTMENT_MAX_SLOT_MAX_MESSAGE),
                                        },
                                    }}
                                />
                            </div>
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <Spacer top={16} />
                            <div style={{ width: '50%' }}>
                                <SelectField
                                    options={bookingFeatureLimitOptions}
                                    name='bookingFeatureLimit'
                                    label={t(translateCodes.BOOKING_FEATURE_LIMIT)}
                                    description={t(
                                        translateCodes.BOOKING_FEATURE_LIMIT_DESCRIPTION
                                    )}
                                />
                            </div>
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                justifyContent='space-between'
                                name='allowCustomerSelfCancel'
                                label={t(translateCodes.ALLOW_CUSTOMER_SELF_CANCEL)}
                                description={t(
                                    translateCodes.ALLOW_CUSTOMER_SELF_CANCEL_DESCRIPTION
                                )}
                                width='100%'
                            />
                            <Spacer top={16} />
                            <div style={{ width: '50%' }}>
                                <InputNumberFieldCustom name='numberHoursBeforeCancel' />
                            </div>
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                alignItems='flex-start'
                                justifyContent='space-between'
                                name='allowBookingSlotLimit'
                                label={t(translateCodes.ALLOW_BOOKING_SLOT_LIMIT)}
                                description={t(translateCodes.ALLOW_BOOKING_SLOT_LIMIT_DESCRIPTION)}
                                width='100%'
                            />
                            <Spacer top={16} />
                            <div style={{ width: '50%' }}>
                                <InputNumberFieldCustom name='bookingSlotLimit' />
                            </div>
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='allowGroupAppointment'
                                label={t(translateCodes.ALLOW_GROUP_APPOINTMENT)}
                                description={t(translateCodes.ALLOW_GROUP_APPOINTMENT_DESCRIPTION)}
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            {t(translateCodes.GROUP_APPOINTMENT_PREFIX)}
                                        </Spacer>
                                    </Block>
                                }
                            />
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='requiredSelectEmployee'
                                label={t(translateCodes.REQUIRED_SELECT_EMPLOYEE)}
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            {t(translateCodes.REQUIRED_SELECT_EMPLOYEE_PREFIX)}
                                        </Spacer>
                                    </Block>
                                }
                            />
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                directionLabel='column'
                                alignItems='flex-start'
                                justifyContent='flex-start'
                                name='showServicePrice'
                                label={t(translateCodes.SHOW_SERVICE_PRICE)}
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            {t(translateCodes.SHOW_SERVICE_PRICE_PREFIX)}
                                        </Spacer>
                                    </Block>
                                }
                            />
                        </Card>
                        <Spacer top={24} />
                        <Card>
                            <SwitchField
                                directionLabel='column'
                                justifyContent='flex-start'
                                alignItems='flex-start'
                                name='confirmOnlineAppointment'
                                label={t(translateCodes.CONFIRM_ONLINE_APPOINTMENT)}
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            {t(translateCodes.CONFIRM_ONLINE_APPOINTMENT_PREFIX)}
                                        </Spacer>
                                    </Block>
                                }
                            />
                        </Card>
                    </FormLayoutBookingSetting>
                )}
            </FormBase>
        </Block>
    );
}
