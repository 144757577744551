import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface CreateBankAccountRequest extends HttpRequestData {
    readonly body: {
        accountName: string;
        accountNumber: string;
        bankName?: string;
    };
}

export interface CreateBankAccountResponse {
    readonly id: string;
    accountName: string;
    accountNumber: number;
    bankName?: string;
    branchId: string;
}

export const createBankAccount = httpUtil.createHttpRequestMeta<
CreateBankAccountRequest,
CreateBankAccountResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/bank-account-settings',
    method: 'POST',
    authentication: 'bearer',
});
