import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { FileUpload } from '../../../upload/api/file/createFile';

interface GetCurrentPageBookingSettingRequest extends HttpRequestData {
}

export interface GetCurrentPageBookingSettingResponse {
    readonly id: string;
    readonly bookingSlug: string;
    readonly allowBookingOnline: boolean;
    readonly bookTabName: string;
    readonly bookTabInformation: string;
    readonly showTopBanner: boolean;
    readonly topBannerUrl: string;
    readonly showGallery: boolean;
    readonly galleryTabName: string;
    readonly galleryImages: FileUpload[];
}

export const getCurrentPageBookingSetting = httpUtil.createHttpRequestMeta<
    GetCurrentPageBookingSettingRequest,
    GetCurrentPageBookingSettingResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/page-booking-settings/current',
    method: 'GET',
    authentication: 'bearer',
});
