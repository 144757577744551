import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AppointmentStatus } from '@esg/shared';
import { appointmentEvents } from '@/event';
import { saleEvents } from '@/event/saleEvent';

export const useListenPaidSale = () => {
    const { setValue } = useFormContext();
    useEffect(() => {
        const listen = saleEvents.onPaid.listen(() => {
            setValue('status', AppointmentStatus.Completed);
            appointmentEvents.refetchAppointments.emit();
        });

        return () => {
            listen();
        };
    }, [setValue]);
};