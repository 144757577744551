import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';
import { GetAllProductResponseItem } from '../product';

interface GetAllProductCategoryRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllProductCategoryResponseItem {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly products?: GetAllProductResponseItem[];
}

export const getAllProductCategory = httpUtil.createHttpRequestMeta<
    GetAllProductCategoryRequest,
    PaginationResponse<GetAllProductCategoryResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/product-categories',
    method: 'GET',
    authentication: 'bearer',
});
