import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface UpdateServiceRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        serviceCategoryId: string;
        duration?: number;
        description?: string;
        price: number;
        taxSettingId?: string;
        isAllowOnlineBooking: boolean;
    };
}

export interface UpdateServiceResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const updateService = httpUtil.createHttpRequestMeta<
    UpdateServiceRequest,
    UpdateServiceResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/services/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
