import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { ProductUnitType } from '@esg/shared/enum';
import { env } from '../../configs';

interface GetAllProductUnitRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllProductUnitResponseItem {
    readonly id: string;
    name: string;
    type: ProductUnitType;
}

export const getAllProductUnits = httpUtil.createHttpRequestMeta<
    GetAllProductUnitRequest,
    PaginationResponse<GetAllProductUnitResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/product-units',
    method: 'GET',
    authentication: 'bearer',
});
