import React from 'react';
import { IconProps } from '../../Types';

export const IconSalon = (props: IconProps) => {
    return (
        <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                opacity='0.4'
                d='M18.8686 13.4997C19.6857 13.4997 20.4449 13.2874 21.1001 12.9187V13.9997C21.1001 17.771 21.1001 19.6566 19.9285 20.8282C18.9854 21.7712 17.5797 21.9552 15.1001 21.991V18.4998C15.1001 17.5651 15.1001 17.0978 14.8991 16.7498C14.7675 16.5217 14.5781 16.3324 14.3501 16.2007C14.002 15.9998 13.5347 15.9998 12.6001 15.9998C11.6655 15.9998 11.1982 15.9998 10.8501 16.2007C10.6221 16.3324 10.4327 16.5217 10.3011 16.7498C10.1001 17.0978 10.1001 17.5651 10.1001 18.4998V21.991C7.62053 21.9552 6.21476 21.7712 5.27167 20.8282C4.1001 19.6566 4.1001 17.771 4.1001 13.9997V12.9186C4.75533 13.2874 5.51449 13.4997 6.33167 13.4997C7.52874 13.4997 8.62627 13.0361 9.44444 12.2717C10.2718 13.0318 11.3766 13.4997 12.5999 13.4997C13.8233 13.4997 14.9282 13.0317 15.7556 12.2715C16.5738 13.036 17.6714 13.4997 18.8686 13.4997Z'
                fill={props.color}
            />
            <path
                opacity='0.7'
                d='M15.1001 21.9913V18.5C15.1001 17.5654 15.1001 17.0981 14.8991 16.75C14.7675 16.522 14.5781 16.3326 14.3501 16.201C14.002 16 13.5347 16 12.6001 16C11.6655 16 11.1982 16 10.8501 16.201C10.6221 16.3326 10.4327 16.522 10.3011 16.75C10.1001 17.0981 10.1001 17.5654 10.1001 18.5V21.9913H15.1001Z'
                fill={props.color}
            />
            <path
                d='M10.0999 2H15.0999L15.7516 8.51737C15.9381 10.382 14.4738 12 12.5999 12C10.726 12 9.26169 10.382 9.44816 8.51737L10.0999 2Z'
                fill={props.color}
            />
            <path
                opacity='0.7'
                d='M3.92985 5.35133C4.10793 4.46093 4.19697 4.01573 4.37801 3.65484C4.75948 2.89439 5.44589 2.33168 6.26638 2.10675C6.65577 2 7.10978 2 8.01781 2H10.1001L9.37559 9.24527C9.21921 10.8091 7.90328 12 6.33165 12C4.4012 12 2.95334 10.2339 3.33193 8.34093L3.92985 5.35133Z'
                fill={props.color}
            />
            <path
                opacity='0.7'
                d='M21.2704 5.35133C21.0923 4.46093 21.0032 4.01573 20.8222 3.65484C20.4407 2.89439 19.7543 2.33168 18.9338 2.10675C18.5444 2 18.0904 2 17.1824 2H15.1001L15.8246 9.24527C15.981 10.8091 17.2969 12 18.8686 12C20.799 12 22.2469 10.2339 21.8683 8.34093L21.2704 5.35133Z'
                fill={props.color}
            />
        </svg>
    );
};

IconSalon.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#F79009',
};
