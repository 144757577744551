import { Translate } from '@esg/ui';
import { Image } from 'antd';
import React from 'react';

import { mainLayoutTheme } from '../../@shared';

import { translateCodes } from '@/locales';
import { AppointmentFormBtn } from '@/components';

export function AddAptButton() {
    return (
        <AppointmentFormBtn 
            icon={
                <Image
                    src={mainLayoutTheme.addAppointmentIcon}
                    preview={false}
                    width={24}
                    height={24}
                />
            }>
            <span>
                <Translate translateCode={translateCodes.ADD_APPOINTMENT} />
            </span>
        </AppointmentFormBtn>
    );
}
