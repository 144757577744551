import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../config';

interface ConnectTokenRequest extends HttpRequestData {
}

export interface ConnectTokenResponse {
    readonly access_token: string;
    readonly expires_in: number;
    readonly token_type: string;
    readonly id_token?: string;
    readonly refresh_token?: string;
}

export const connectToken = httpUtil.createHttpRequestMeta<
    ConnectTokenRequest,
    ConnectTokenResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/connect/token',
    method: 'POST',
});
