import { httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { EmployeeGetDetailResponse } from '../employee';
import { TimeBlockRepeatBy, TimeBlockRepeatType } from './Types';

interface TimeBlockGetAllRequest {}

export interface TimeBlockDetailResponse {
	readonly id: string;
	readonly date: string;
	readonly title: string;
	readonly endTime: string;
	readonly repeatTo: string;
	readonly startTime: string;
	readonly employeeId: string;
	readonly occurrences?: number;
	readonly specificDate?: string;
	readonly repeatBy: TimeBlockRepeatBy;
	readonly repeatType?: TimeBlockRepeatType;
	readonly employee?: EmployeeGetDetailResponse;
}

export const getAllTimeBlock = httpUtil.createHttpRequestMeta<
	TimeBlockGetAllRequest,
	TimeBlockDetailResponse[]
>({
    method: 'GET',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/time-blocks',
});
