import React from 'react';
import { IconProps } from '../../Types';

export const IconFilePlus = (props: IconProps) => {
    return (
        <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16.6663 8.75V5.66666C16.6663 4.26653 16.6663 3.56647 16.3939 3.03169C16.1542 2.56128 15.7717 2.17883 15.3013 1.93915C14.7665 1.66666 14.0665 1.66666 12.6663 1.66666H7.33301C5.93288 1.66666 5.23281 1.66666 4.69803 1.93915C4.22763 2.17883 3.84517 2.56128 3.60549 3.03169C3.33301 3.56647 3.33301 4.26653 3.33301 5.66666V14.3333C3.33301 15.7335 3.33301 16.4335 3.60549 16.9683C3.84517 17.4387 4.22763 17.8212 4.69803 18.0608C5.23281 18.3333 5.93288 18.3333 7.33301 18.3333H9.99967M11.6663 9.16666H6.66634M8.33301 12.5H6.66634M13.333 5.83333H6.66634M14.9997 17.5V12.5M12.4997 15H17.4997' stroke='#667085' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

IconFilePlus.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
