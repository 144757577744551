export enum IntegrationType {
    Email
}

export enum IntegrationEmailService {
    Gmail,
    Other
}

export enum IntegrationStatus {
    Disabled,
    Connected
}

export enum EmailEncryptionType {
    None,
    SSL,
    TSL
}

export interface IntegrationEmailConfig {
	readonly service: IntegrationEmailService;
	readonly smtpServer: string;
	readonly port: number;
	readonly encryption: EmailEncryptionType;
	readonly userName: string;
	readonly password: string;
	readonly sendingEmail: string;
    readonly sendingFrom: string;
}