import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { GetDetailSaleResponse } from './getDetailSale';

interface CheckoutSaleRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        paymentMethodSettingId?: string;
    };
}

export const checkoutSale = httpUtil.createHttpRequestMeta<
CheckoutSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales/:id/checkout',
    method: 'PATCH',
    authentication: 'bearer',
});
