import { DatePickerField, FlexBox, FormBase, FormModalFilter, SelectFieldCheckBox, useHttpQuery } from '@esg/ui';
import React from 'react';
import { customerApi } from '@esg/business-crm';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useTranslation } from 'react-i18next';
import { FilterCustomerParams } from '../CustomerList';
import { translateCodes } from '@/locales';

interface FilterCustomerFormProps {
    readonly defaultValues?: FilterCustomerParams;
    readonly handleSubmit?: (value: FilterCustomerParams) => void;
    readonly onCancel?: () => void;
}

export default function FilterCustomerForm(props: FilterCustomerFormProps) {
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { t } = useTranslation();
    const { data: customerGroups} = useHttpQuery(customerApi.getAllCustomersGroup);
    const { data: customerSources} = useHttpQuery(customerApi.getAllCustomersSource);

    const sourceOptions = customerSources?.items.map((c) => ({
        label: c.name,
        value: c.id,
    }));

    const groupOptions = customerGroups?.items.map((c) => ({
        label: c.name,
        value: c.id,
    }));

    return (
        <FormBase onSubmit={props.handleSubmit} defaultValues={{
            ...props.defaultValues,
        }}>
            {(form) => (
                <FormModalFilter 
                    onCancel={props.onCancel}
                    onReset={() => {
                        form.reset({
                            date: null,
                            sourceIds: [],
                            groupIds: []
                        });
                    }}
                >
                    <FlexBox direction='column' gap={16}>
                        <SelectFieldCheckBox
                            name='sourceIds'
                            label={translateCodes.SOURCE}
                            mode='multiple'
                            options={sourceOptions}
                            placeholderCode={translateCodes.SELECT_SOURCE}
                            allData={`${t(translateCodes.ALL)} ${t(translateCodes.SOURCE)}`}
                        />
                        <SelectFieldCheckBox
                            name='groupIds'
                            label={translateCodes.GROUP}
                            mode='multiple'
                            options={groupOptions}
                            placeholderCode={translateCodes.SELECT_GROUP}
                            allData={`${t(translateCodes.ALL)} ${t(translateCodes.GROUP)}`}
                        />
                        <DatePickerField
                            name='date'
                            format={ currentLocale?.format }
                            label={translateCodes.CREATED_ON}
                            placeholder={translateCodes.SELECT_DATE}
                        />
                    </FlexBox>
                </FormModalFilter>
            )}
        </FormBase>
    );
}
