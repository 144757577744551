import { EmployeeCreateRequest, resourceApis } from '@esg/business-resource-management';
import { Card, Switch } from 'antd';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
    DatePickerField,
    useHttpQuery,
    Block,
    FlexBox,
    Header,
    InputField,
    SelectField,
    Spacer,
    Text,
    AvatarUpload,
    globalModalState,
} from '@esg/ui';
import { branchApis } from '@esg/business-organization';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { currentLocaleState } from '@esg/ui/state/currentLocale';

import { EmployeeRules } from '../EmployeeRules';
import { translateCodes } from '@/locales';
import { genderOptions, jobStatusOptions } from '@/constants';
import JobTypeForm from '@/components/form/job-type/JobTypeForm';

export const EmployeeInfoForm = () => {
    const {t}=useTranslation();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { data: jobTypes } = useHttpQuery(resourceApis.getAllJobTypes);
    const { data: branches } = useHttpQuery(branchApis.getAllBranches);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);

    const jobTypeOptions = useMemo(
        () => jobTypes?.items.map((o) => ({ label: o.name, value: o.id })) || [],
        [jobTypes?.items]
    );
    const { control } = useFormContext<EmployeeCreateRequest['body']>();
    const { setValue } = useFormContext();
    const [filteredOptionsJobType, setFilteredOptionsJobType] = useState(jobTypeOptions);

    const handleSearchJobType = (searchText: string) => {
        const filteredJobType = jobTypeOptions.filter(o => o.label.toString().toLowerCase().includes(searchText.toLowerCase()));
        setFilteredOptionsJobType(filteredJobType);
    };
    const branchOptions = useMemo(
        () => branches?.items.map((o) => ({ label: o.name, value: o.id })) || [],
        [branches?.items]
    );
    const createJobType = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.ADD_JOB_TYPE,
            content: <JobTypeForm
                onCancel={() => resetGlobalModal()}
                onSuccess={(res) => {
                    resetGlobalModal();
                    setFilteredOptionsJobType([...filteredOptionsJobType, {
                        label: res?.name ?? '',
                        value: res?.id ?? ''
                    }]);
                    setValue('jobTypeId', res?.id);
                }}
            />,
        });
    };
    return (
        <Block>
            <Block>
                <Header level={4} translateCode={translateCodes.GENERAL_INFO} />
                <Spacer bottom={12} />
                <Card>
                    <AvatarUpload translateCode={translateCodes.UPLOAD_PHOTO} name='avatar' />
                    <Spacer bottom={32} />
                    <FlexBox direction='column' gap={16}>
                        <FlexBox gap={16}>
                            <InputField
                                label={translateCodes.FIRST_NAME}
                                name='firstName'
                                placeholder={translateCodes.FIRST_NAME_PLACEHOLDER}
                                rules={EmployeeRules.firstName(t)}
                            />
                            <InputField
                                label={translateCodes.LAST_NAME}
                                name='lastName'
                                placeholder={translateCodes.FIRST_NAME_PLACEHOLDER}
                                rules={EmployeeRules.lastName(t)}
                            />
                        </FlexBox>
                        <FlexBox gap={16}>
                            <SelectField
                                allowClear
                                options={genderOptions}
                                name='gender'
                                label={t(translateCodes.GENDER)}
                                placeholder={t(translateCodes.GENDER_PLACEHOLDER)}
                            />
                            <DatePickerField
                                label={translateCodes.DATE_OF_BIRTH}
                                name='dateOfBirth'
                                placeholder={translateCodes.DATE_OF_BIRTH_PLACEHOLDER}
                                format={ currentLocale?.format }
                            />
                        </FlexBox>
                        <FlexBox gap={16}>
                            <InputField
                                label={translateCodes.PHONE}
                                name='phone'
                                placeholder={translateCodes.PHONE_PLACEHOLDER}
                                rules={EmployeeRules.phone}
                            />
                            <InputField
                                name='email'
                                placeholder={translateCodes.EMAIL_PLACEHOLDER}
                                label={translateCodes.EMAIL}
                                rules={EmployeeRules.email(t)}
                            />
                        </FlexBox>
                        <InputField
                            name='address'
                            placeholder={translateCodes.ADDRESS_PLACEHOLDER}
                            label={translateCodes.ADDRESS}
                            rules={EmployeeRules.address(t)}
                        />
                    </FlexBox>
                </Card>
            </Block>
            <Spacer bottom={24} />
            <Block>
                <Header level={4} translateCode={translateCodes.JOB_INFO} />
                <Spacer bottom={12} />
                <Card>
                    <FlexBox direction='column' gap={16}>
                        <FlexBox gap={16}>
                            <FlexBox>
                                <SelectField
                                    filterOption={false}
                                    showSearch
                                    onSearch={handleSearchJobType}
                                    allowClear
                                    label={translateCodes.JOB_TYPE}
                                    name='jobTypeId'
                                    placeholder={translateCodes.SELECT_JOB_TYPE}
                                    options={jobTypeOptions}
                                    onCreate={createJobType}
                                />
                            </FlexBox>
                            <FlexBox>
                                <DatePickerField
                                    label={translateCodes.START_DATE}
                                    name='jobStartDate'
                                    placeholder={translateCodes.START_DATE_PLACEHOLDER}
                                    format={ currentLocale?.format }
                                />
                            </FlexBox>
                        </FlexBox>
                        <FlexBox gap={16}>
                            <SelectField
                                label={translateCodes.STATUS}
                                name='jobStatus'
                                placeholder={translateCodes.STATUS_PLACEHOLDER}
                                options={jobStatusOptions}
                            />
                            <SelectField
                                name='locationId'
                                placeholder={translateCodes.LOCATION_PLACEHOLDER}
                                label={translateCodes.LOCATION}
                                options={branchOptions}
                            />
                        </FlexBox>
                        <FlexBox gap={8} alignItems='center'>
                            <Controller
                                name='isAllowBooking'
                                control={control}
                                render={({ field }) => <Switch {...field} defaultValue={true} />}
                            />
                            <Text translateCode={translateCodes.ALLOW_BOOKING_EMPLOYEE} />
                        </FlexBox>
                    </FlexBox>
                </Card>
            </Block>
        </Block>
    );
};
