import React, { useCallback } from 'react';
import { Scheduler, SchedulerItem } from '@esg/ui';
import { dayjs } from '@esg/shared';
import { ShiftByEmployeeDetail } from '@esg/business-resource-management';
import { useRefetchShifts, useShiftTimeLabel } from '../../@share/hooks';
import { useShiftRowInfo } from '../../@share/hooks/useShiftRowInfo';
import { useShiftContext } from '../../ShiftContext';
import { ShiftItem } from './children/shift-item';

export const ShiftsManagement = () => {
    const renderTimeLabel = useShiftTimeLabel();
    const {
        loading,
        employees,
        shiftItems,
        query: { endDate, startDate },
    } = useShiftContext();

    useRefetchShifts();

    const { renderRowInfo, rowInfos } = useShiftRowInfo(employees);

    const renderItem = useCallback((item: SchedulerItem<ShiftByEmployeeDetail>) => {
        return <ShiftItem shiftItem={item} />;
    }, []);

    return (
        <Scheduler
            columns={[]}
            loading={loading}
            items={shiftItems}
            layout='horizontal'
            baseRowHeight={100}
            rowInfos={rowInfos}
            baseColumnWidth={130}
            renderItem={renderItem}
            endTime={dayjs(endDate)}
            minutesPerCell={60 * 24}
            startTime={dayjs(startDate)}
            renderRowInfo={renderRowInfo}
            renderTimeMaker={() => <> </>}
            renderTimeLabel={renderTimeLabel}
        />
    );
};
