import { useEffect } from 'react';
import { RealtimeEvents } from '@esg/shared';
import { getRealtimeJob, realtimeDataParse } from '../realtimeUtils';
import { useAppointmentRealtime } from './useAppointmentRealtime';
import { useRealtimeContext, RealtimeData } from '@/contexts/realtime-context';

export const useDataModified = () => {
    const { realtimeConn: conn } = useRealtimeContext();

    const handleAppointmentRealtime = useAppointmentRealtime();

    useEffect(() => {
        if (!conn) {
            return;
        }

        conn.on(RealtimeEvents.DataModified, async (data: RealtimeData) => {
            data = realtimeDataParse(data);
            const job = getRealtimeJob(data);

            switch (job) {
            case 'APPOINTMENT': {
                handleAppointmentRealtime(data);
                break;
            }
            default:
                break;
            }
        });
        
        return () => {
            conn.off(RealtimeEvents.DataModified);
        };
    }, [conn, handleAppointmentRealtime]);
};
