import { getAllSale } from '@esg/business-pos';
import { createService, deleteService, getAllServices } from './service';
import { updateService } from './service/updateService';
import {
    GetAllServiceCategoryResponseItem,
    createServiceCategory,
    deleteServiceCategory,
    getAllServiceCategory,
    updateServiceCategory
} from './service-category';
import {createProductCategory, getAllProductCategory, deleteProductCategory, updateProductCategory} from './product-category';
import {createProduct, getAllProduct, deleteProduct, updateProduct} from './product';
import{createProductUnit, getAllProductUnits, deleteProductUnit,updateProductUnit }from'./unit';
import{createPackageCategory, updatePackageCategory, deletePackageCategory, getAllPackageCategory}from'./package-category';
import{createPackage, deletePackage, getAllPackages, updatePackage}from'./package';
import { getAllAppointmentActivities, getAllSaleActivities } from './activities';
export type {
    GetAllServiceCategoryResponseItem
};

export const managementApis = {
    getAllServices,
    createService,
    updateService,
    deleteService,
    createServiceCategory,
    getAllServiceCategory,
    updateServiceCategory,
    deleteServiceCategory,
    createProductCategory,
    getAllProductCategory,
    deleteProductCategory,
    updateProductCategory,
    createProduct,
    getAllProduct,
    deleteProduct,
    updateProduct,
    createProductUnit,
    getAllProductUnits,
    deleteProductUnit,
    updateProductUnit,
    createPackageCategory,
    updatePackageCategory,
    deletePackageCategory,
    getAllPackageCategory,
    createPackage,
    deletePackage,
    getAllPackages,
    updatePackage,
    getAllAppointmentActivities,
    getAllSaleActivities,
    getAllSale
};
