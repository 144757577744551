import { httpUtil } from '@esg/framework';
import { env } from '../../configs';

export enum StatusEmailTemPlate {
    SystemTemPlate = 0
}

interface EmailTemplateCreateRequest {
    readonly body: {
        type?: string;
        subject?: string;
        status?: StatusEmailTemPlate;
        body?: string;
    };
}
export interface EmailTemplateCreateResponseItem {
    id?: string;
    type?: string;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
}

export const emailTemplateCreate = httpUtil.createHttpRequestMeta<
    EmailTemplateCreateRequest,
    EmailTemplateCreateResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/email-templates',
    method: 'POST',
    authentication: 'bearer',
});
