import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TimeBlockFormControl } from './TimeBlockFormControl';
import { TimeBlockFormValues } from './Types';
import './TimeBlockForm.scss';

interface TimeBlockFormProps {
	readonly defaultValues: TimeBlockFormValues;
}

export const TimeBlockForm = (props: TimeBlockFormProps) => {
    const { defaultValues } = props;
    const methods = useForm<TimeBlockFormValues>({
        defaultValues,
    });

    return (
        <FormProvider {...methods}>
            <TimeBlockFormControl />
        </FormProvider>
    );
};
