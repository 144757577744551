import { Block, FlexBox, IconTrash } from '@esg/ui';
import { Alert, Card } from 'antd';
import React, { useCallback } from 'react';

import { FieldArrayWithId, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppointmentContext } from '../../@share/AppointmentFormContext';

import { useEmployeeOptions } from '../../@share/hooks/useEmployeeOptions';
import { useCurrentServiceWorkTime } from '../../@share/hooks/useCurrentServiceTimeFrame';
import { useServicesWorkTime } from '../../@share/hooks/useServicesWorkTime';
import { useUnAvailableEmployee } from '../../@share/hooks/useUnAvailableEmployee';
import {
    FacilitySelectField,
    ServiceSelectField,
} from './appointment-service-detail';
import { IAppointmentServiceEmployee } from './AppointmentServiceDetails';
import { EmployeeSelectField } from '@/components/form/employee/children/employee-select/EmployeeSelectField';
import { translateCodes } from '@/locales';

interface AppointmentServiceDetailProps {
	readonly parentFieldName: string;
	readonly serviceIndex: number;
	readonly remove: UseFieldArrayRemove;
	readonly fields: FieldArrayWithId[];
	readonly guestIndex: number;
}

export const AppointmentServiceDetail = (props: AppointmentServiceDetailProps) => {
    const { setValue, watch, getValues } = useFormContext();
    const { t } = useTranslation();
    const { services, employees, categories, facilities, appointmentSetting } = useAppointmentContext();
    const { remove, parentFieldName, serviceIndex, fields, guestIndex } = props;

    const fieldName = `${parentFieldName}.${serviceIndex}`;

    const date = watch('appointmentDate');
    const endTime = watch(`${fieldName}.endTime`);
    const employeeIds = watch(`${fieldName}.employeeIds`);
    const startTime = watch(`${fieldName}.startTime`);
    const currentServiceId = watch(`${fieldName}.serviceId`);
    const currentFacilityId = watch(`${fieldName}.facilityId`);

    const employeeOptions = useEmployeeOptions({
        date,
        endTime,
        startTime,
        guestIndex,
        currentServiceId,
    });

    useCurrentServiceWorkTime({ fieldName, guestIndex, serviceIndex, date });
    useServicesWorkTime({ fields, guestIndex, parentFieldName, serviceIndex, currentServiceId });

    const unAvailableEmployee = useUnAvailableEmployee({
        employeeIds,
        fieldName,
        employeeOptions,
    });
    
    const handleChangeEmployee = useCallback(
        (value: string[]) => {

            setValue(`${fieldName}.employeeIds`, value);

            const appointmentServiceEmployees: IAppointmentServiceEmployee[] = getValues(
                `${fieldName}.appointmentServiceEmployees`
            );

            const currentAppointmentServiceEmployees = value.map((employeeId: string) => {
                const current = appointmentServiceEmployees?.find((a) => a.employeeId === employeeId);
                if (current) {
                    return current;
                }
                return {
                    employeeId,
                };
            });

            setValue(`${fieldName}.appointmentServiceEmployees`, currentAppointmentServiceEmployees);
        },
        [fieldName, getValues, setValue]
    );

    const showRemoveButton = serviceIndex > 0;

    return (
        <Block>
            <FlexBox gap={12} alignItems='flex-start'>
                <div style={{ width: facilities.length ? '29%' : '45%' }}>
                    <ServiceSelectField
                        employees={employees}
                        fieldName={fieldName}
                        currentEmployeeIds={employeeIds}
                        dataSource={services}
                        categories={categories}
                        currentFacilityId={currentFacilityId}
                        rules={{ required: {
                            value: true,
                            message: t(translateCodes.SERVICE_REQUIRED),
                        } }}
                    />
                </div>
                <div style={{ width: facilities.length ? '33%' : '45%' }}>
                    <EmployeeSelectField
                        name={`${fieldName}.employeeIds`}
                        mode='multiple'
                        employeeOptions={employeeOptions}
                        handleChangedEmployee={(value: string[])=>handleChangeEmployee(value)}
                        value={getValues(`${fieldName}.employeeIds`)}
                        rules={{ required: {
                            value: appointmentSetting?.employeeSelectionRequired ?? false,
                            message: t(translateCodes.THE_EMPLOYEE_IS_REQUIRED),
                        } }}
                    />
                </div>
                {facilities.length ? (
                    <div style={{ width: '29%' }}>
                        <FacilitySelectField
                            fieldName={fieldName}
                            timeEndWork={endTime}
                            guestIndex={guestIndex}
                            dataSource={facilities}
                            timeStartWork={startTime}
                            currentServiceId={currentServiceId}
                        />
                    </div>
                ) : null}
                <div className='trash-btn'>
                    {showRemoveButton &&(
                        <Card
                            size='small'
                            className='extra-btn'
                            hoverable
                            onClick={() => {
                                if (serviceIndex > 0) {
                                    remove(serviceIndex);
                                }
                            }}
                            style={{ margin: 0 }}
                        >
                            <IconTrash />
                        </Card>
                    )}
                </div>
            </FlexBox>
            {unAvailableEmployee && (
                <Alert
                    banner
                    showIcon
                    type='warning'
                    message={unAvailableEmployee.warningMsg}
                    style={{ marginTop: 8, color: 'red', borderRadius: 8 }}
                />
            )}
        </Block>
    );
};
