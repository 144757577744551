import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';
import { EmployeeGetDetailResponse } from '.';

interface GetEmployeesByAppointmentRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}

export const getEmployeesByAppointment = httpUtil.createHttpRequestMeta<
    GetEmployeesByAppointmentRequest,
	PaginationResponse<EmployeeGetDetailResponse>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/employees/requested-by-appointment',
    method: 'GET',
    authentication: 'bearer',
});
