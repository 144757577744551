import { globalModalState } from '@esg/ui';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ShiftGetDetailResponse, TimeOffType } from '@esg/business-resource-management';
import { convertSameDate } from '@esg/shared';
import { TimeOffForm , TimeOffFormValues } from '@/components';
import { translateCodes } from '@/locales';

export const useTimeOffForm = () => {
    const [, showModal] = useRecoilState(globalModalState);

    const showForm = useCallback(
        (shift: ShiftGetDetailResponse) => {
            const { endTime, startTime, employeeId, date } = shift; 
            
            const defaultValues: TimeOffFormValues = {
                employeeId,
                date: date!,
                type: TimeOffType.AnnualLeave,
                endTime: convertSameDate(date!, endTime).toISOString(),
                startTime: convertSameDate(date!, startTime).toISOString(),
            };

            showModal({
                isOpen: true,
                titleTransCode: translateCodes.ADD_TIME_OFF,
                content: (
                    <TimeOffForm
                        defaultValues={defaultValues}
                    />
                ),
                width: 730,
                footer: null,
            });
        },
        [showModal]
    );

    return showForm;
};