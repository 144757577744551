import { FlexBox, SliderField, Spacer, Text } from '@esg/ui';
import { Card, SliderSingleProps } from 'antd';
import React from 'react';

interface TimeSlotDurationProps {
    readonly label?: string;
    readonly description?: string;
    readonly onChange?: (value: AppointmentTimeSlot) => void;
}

export function TimeSlotDuration(props: TimeSlotDurationProps) {
    const { label, description, onChange } = props;
    const marks: SliderSingleProps['marks'] = [...minutesToTimeSlotMap.entries()].reduce((acc, [timeSlot, minutes]) => {
        acc[timeSlot] = `${minutes}'`;
        return acc;
    }, {} as Record<number, string>);

    const handleChanged = React.useCallback((value: number) => {
        onChange?.(value as AppointmentTimeSlot);
    }, [onChange]);

    return (
        <Card>
            <FlexBox preset='column-start' className='setting-booking-slider'>
                <Text fontSize={16} fontWeight={700}>{label}</Text>
                <Text fontSize={14}>{description}</Text>
                <Spacer type='margin' bottom={8} />
                <SliderField
                    name='timeSlotDuration'
                    min={1 as AppointmentTimeSlot}
                    max={5 as AppointmentTimeSlot}
                    marks={marks}
                    onChange={handleChanged}
                />
            </FlexBox>
        </Card>
    );
}

export type AppointmentTimeSlot = 1 | 2 | 3 | 4 | 5;
export type AppointmentMinuteStep = 5 | 10 | 15 | 30 | 60;

const minutesToTimeSlotMap = new Map<AppointmentTimeSlot, AppointmentMinuteStep>([
    [1, 5],
    [2, 10],
    [3, 15],
    [4, 30],
    [5, 60]
]);

TimeSlotDuration.minutesToTimeSlot = (targetMinuteStep: AppointmentMinuteStep): AppointmentTimeSlot => {
    for (const pair of minutesToTimeSlotMap.entries()) {
        const [timeSlot, minutes] = pair;
        if (minutes === targetMinuteStep) {
            return timeSlot;
        }
    }

    return 1;
};

TimeSlotDuration.timeSlotToMinutes = (timeSlot: AppointmentTimeSlot): AppointmentMinuteStep => {
    return minutesToTimeSlotMap.get(timeSlot) ?? 15;
};