import { resourceApis, getShiftsByEmployee } from '@esg/business-resource-management';
import { dayjs } from '@esg/shared';
import { useHttpQuery, useSearchParams } from '@esg/ui';
import { useMemo } from 'react';
import { getShiftItems } from '../shiftUtils';
import { ShiftQueryParams } from '../../Types';

export const useShiftResources = () => {
    const initialDate = useMemo(() => dayjs().endOf('month'), []);

    const [query, setQuery] = useSearchParams<ShiftQueryParams>(
        {
            endDate: initialDate.toISOString(),
            startDate: initialDate.startOf('month').toISOString(),
        },
        ['endDate', 'startDate', 'employeeIds']
    );

    const { data: employeesResponse } = useHttpQuery(resourceApis.getAllEmployees, {
        query: {
            pageSize: 50,
        },
    });

    const {
        isFetching,
        data: shifts,
        refetch: refetchShifts,
    } = useHttpQuery(getShiftsByEmployee, {
        query: {
            endDate: query.endDate,
            startDate: query.startDate,
            employeeIds: query.employeeIds,
        },
    });

    const shiftItems = useMemo(
        () => getShiftItems(shifts ?? [], query as ShiftQueryParams),
        [query, shifts]
    );

    return {
        setQuery,
        shiftItems,
        refetchShifts,
        loading: isFetching,
        query: query as ShiftQueryParams,
        employees: employeesResponse?.items ?? [],
    };
};
