import { Spacer, globalModalState } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { TimeBlockDetailResponse } from '@esg/business-resource-management';
import { useCallback } from 'react';
import { TimeBlockDeleteConfirm } from '@/components';
import { translateCodes } from '@/locales';

export const useTimeBlockDeleteConfirm = () => {
    const [, showModal] = useRecoilState(globalModalState);

    const showConfirm = useCallback(
        (values: TimeBlockDetailResponse, currentDate: string) => {
            showModal({
                titleTransCode: translateCodes.DELETE_TIME_BLOCK,
                content: (
                    <Spacer top='xs'>
                        <TimeBlockDeleteConfirm values={values} currentDate={currentDate} />
                    </Spacer>
                ),
                isOpen: true,
                showModalDelete: true,
                footer: null,
            });
        },
        [showModal]
    );

    return showConfirm;
};
