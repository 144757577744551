import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import { AppButton, useHttpCommand , managementProfileState } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { createSale } from '@esg/business-pos';
import useOpenSaleFrom from '../hook/useOpenSaleFrom';
import { translateCodes } from '@/locales';

export default function CreateSaleBtn() {
    const [currentProfile] = useRecoilState(managementProfileState);
    const { onShowFormSale } = useOpenSaleFrom();

    const { mutateAsync: onCreateSale, isPending } = useHttpCommand(createSale, {
        onSuccess: (response) => {
            onShowFormSale(response);
        }
    });

    const handleCreateSale = async () => {
        await onCreateSale({
            body: {
                branchId: currentProfile?.branch.id,
            }
        });
    };

    return (
        <AppButton
            loading={isPending}
            translateCode={translateCodes.CREATE_SALE}
            icon={<PlusCircleFilled />}
            type='primary'
            onClick={handleCreateSale}
        />
    );
}
