import { TimeBlockRepeatBy } from '@esg/business-resource-management';
import { AppButton, FlexBox, globalModalState, Spacer, Text, Translate } from '@esg/ui';
import { Radio, Space } from 'antd';
import React, { useState, useMemo } from 'react';
import { useResetRecoilState } from 'recoil';
import { TimeBlockCircle, TimeBlockUpdateValues } from './Types';
import { useTimeBlockUpdate } from './@share/hooks';
import { translateCodes } from '@/locales';

interface TimeBlockConfirmProps {
	readonly values: TimeBlockUpdateValues;
	readonly defaultValues: TimeBlockUpdateValues;
}

export const TimeBlockUpdateConfirm = ({ values, defaultValues }: TimeBlockConfirmProps) => {
    const hideModal = useResetRecoilState(globalModalState);

    const isNone = useMemo(
        () => defaultValues.repeatBy === TimeBlockRepeatBy.NONE,
        [defaultValues.repeatBy]
    );

    const [type, setType] = useState<TimeBlockCircle | undefined>(isNone ? undefined : 'now');

    const handleUpdate = useTimeBlockUpdate();

    const noChangeDate = useMemo(
        () => values.date === defaultValues.date,
        [defaultValues.date, values.date]
    );

    return (
        <>
            {isNone ? (
                <Text>
                    <Translate translateCode={translateCodes.UPDATE_TIME_BLOCK_CONFIRM} />
                </Text>
            ) : (
                <Radio.Group
                    value={type}
                    onChange={(value) => {
                        setType(value.target.value);
                    }}
                >
                    <Space direction='vertical'>
                        <Radio value='now'>
                            <Translate translateCode={translateCodes.UPDATE_TIME_BLOCK_ONLY} />
                        </Radio>
                        <Radio value='future'>
                            <Translate translateCode={translateCodes.UPDATE_TIME_BLOCK_FUTURE} />
                        </Radio>
                        {noChangeDate && (
                            <Radio value='all'>
                                <Translate translateCode={translateCodes.UPDATE_TIME_BLOCK_ALL} />
                            </Radio>
                        )}
                    </Space>
                </Radio.Group>
            )}
            <Spacer top_bottom={20} />
            <FlexBox justifyContent='space-between' gap={20}>
                <AppButton translateCode={translateCodes.CANCEL} width='100%' onClick={hideModal} />
                <AppButton
                    width='100%'
                    type='primary'
                    translateCode='OK'
                    onClick={() => {
                        handleUpdate({ values, editType: type, defaultValues });
                    }}
                />
            </FlexBox>
        </>
    );
};
