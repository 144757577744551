import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface AppointmentSettingGetDetailRequest extends HttpRequestData {
}

export interface AppointmentSettingDetailResponse {
    id: string;
    timeSlotDuration: number;
    doubleAppointmentEnabled: boolean;
    doubleAppointmentMaxSlot: number;
    employeeSelectionRequired: boolean;
    autoNoShowEnabled: boolean;
    autoNoShowAfterTime: number;
    autoNoShowPromptEnabled: boolean;
}

export const getDetailAppointmentSetting = httpUtil.createHttpRequestMeta<
	AppointmentSettingGetDetailRequest,
	AppointmentSettingDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/appointment-Settings/current',
    method: 'GET',
    authentication: 'bearer',
});
