import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface AppointmentServiceEmployeeCreateRequest extends HttpRequestData {
    body: {
        readonly employeeId: string;
        readonly appointmentServiceDetailId: string;
        readonly timeStartWork: string;
        readonly timeEndWork: string;
    }
}

export const createAppointmentServiceEmployee = httpUtil.createHttpRequestMeta<AppointmentServiceEmployeeCreateRequest, void>({
    baseUrl: env.apiUrlBase,
    method: 'POST',
    path: '/api/v1/appointment-service-employees',
    authentication: 'bearer',
});
