import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteTaxRequest extends HttpRequestData {
}

export const taxDelete = httpUtil.createHttpRequestMeta<
    DeleteTaxRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/TaxSettings/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
