import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { AppointmentQueryParams } from '../Scheduler';

export const getResourcesFromAppointment = (appointments: AppointmentGetDetailResponse[]) => { 
    const serviceIds: string[] = [];
    const employeeIds: string[] = [];
    const statuses: number[] = [];

    appointments.forEach((item) => {
        if (!statuses.includes(item.status)) {
            statuses.push(item.status);
        }
        item.appointmentServices.forEach((a) => {
            a.appointmentServiceDetails.forEach((detail) => {
                const serviceId = detail.service.id;
                if (!serviceIds.includes(serviceId)) {
                    serviceIds.push(serviceId);
                }
                detail.appointmentServiceEmployees?.forEach((s) => {
                    if (s.employeeId && !employeeIds.includes(s.employeeId)) {
                        employeeIds.push(s.employeeId);
                    }
                });
            });
        });
    });

    return {serviceIds, employeeIds, statuses};
};

export const getAppointmentQueryParams = (query: Partial<AppointmentQueryParams>) => {
    const result = { ...query };
    if (query.employeeIds && typeof query.employeeIds === 'string') {
        result.employeeIds = [query.employeeIds];
    }

    if (query.serviceIds && typeof query.serviceIds === 'string') {
        result.serviceIds = [query.serviceIds];
    }

    if (query.statuses && typeof query.statuses === 'string') {
        result.statuses = [query.statuses];
    }
    return result;
};