import React from 'react';
import { Text } from '@esg/ui';
import { ShiftByEmployeeDetail } from '@esg/business-resource-management';
import { useShiftDelete } from './useShiftDelete';
import { useTimeOffDelete } from './useTimeOffDelete';
import { translateCodes } from '@/locales';
import { useEmployeeShiftForm } from '@/hooks/useStaffShiftForm';
import { useTimeOffForm } from '@/hooks/useTimeOffForm';

export const useShiftMenu = ({timeOff, ...shift}: ShiftByEmployeeDetail, date: string) => {
    const handleDelete = useShiftDelete();
    const showTimeOffForm = useTimeOffForm();
    const showShiftForm = useEmployeeShiftForm();
    const handleDeleteTimeOff = useTimeOffDelete();

    const shiftMenu = React.useMemo(() => {
        if (timeOff) {
            
            return [
                {
                    key: '1',
                    label: (
                        <Text
                            translateCode={translateCodes.DELETE_TIME_OFF}
                            color='red'
                            onClick={() => {
                                handleDeleteTimeOff(timeOff.id);
                            }}
                        />
                    ),
                },
            ];
        }
        
        return [
            {
                key: '1',
                label: (
                    <Text
                        onClick={() => {
                            showShiftForm({...shift, currentDate: date});
                        }}
                        translateCode={translateCodes.EDIT_THIS_DAY}
                    />
                ),
            },
            {
                key: '2',
                label: (
                    <Text
                        translateCode={translateCodes.ADD_TIME_OFF}
                        onClick={() => {
                            showTimeOffForm({...shift, date });
                        }}
                    />
                ),
            },
            {
                key: '3',
                label: (
                    <Text
                        color='red'
                        translateCode={translateCodes.DELETE_THIS_SHIFT}
                        onClick={() => {
                            handleDelete(shift, date);
                        }}
                    />
                ),
            },
        ];

    }, [timeOff, handleDeleteTimeOff, showShiftForm, shift, date, showTimeOffForm, handleDelete]);

    return shiftMenu;
};
