import { dayjs, Dayjs } from '@esg/shared';
import { Spacer, useLocalesFormat } from '@esg/ui';
import { useCallback } from 'react';
import { useShiftContext } from '../../ShiftContext';

export const useShiftTimeLabel = () => {
    const { employees } = useShiftContext();
    const { formatDate } = useLocalesFormat();

    const renderTimeLabel = useCallback((time: Dayjs) => {
        const isSameDay = time.isSame(dayjs(), 'day');
        const duration = employees.reduce((result, employee) => {
            const shift = employee.shifts.find((s) => s.dayOfWeek === time.day() && !s.isDayOff);
            return (result += dayjs(shift?.endTime).diff(shift?.startTime, 'h')); 
        },0);
        
        return (
            <div
                className='time-label'
                style={{ backgroundColor: isSameDay ? '#EFF6FF' : undefined }}
            >
                <p style={{ fontWeight: 'bold' }}>{formatDate(time)}</p>
                <Spacer bottom={4} />
                <p>{duration}h</p>
            </div>
        );
    }, [employees, formatDate]);
    
    return renderTimeLabel; 
};