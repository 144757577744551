import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface UpdateSaleActiveResponse extends HttpRequestData {
    id?: string;
    isActive: boolean;
}

interface UpdateSaleActiveRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        isActive: boolean;
        isDisplay?: boolean;
    };
}

export const updateSaleActive = httpUtil.createHttpRequestMeta<
UpdateSaleActiveRequest,
UpdateSaleActiveResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales/:id/active',
    method: 'PATCH',
    authentication: 'bearer',
});
