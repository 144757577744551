import { useCallback } from 'react';
import { useHttpCommand } from '@esg/ui';
import { getDetailSale } from '@esg/business-pos';
import { AppointmentStatus as Status } from '@esg/shared';
import { useFormContext } from 'react-hook-form';
import { NextStep } from '../aptStatusInfos';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';

export const useAptStatusNextStep = (
    handleUpdate: (id: string, status: Status) => Promise<void>
) => {
    const {
        setValue,
        formState: { defaultValues },
    } = useFormContext();
    
    const { mutateAsync: getSaleDetail } = useHttpCommand(getDetailSale);

    const { onShowFormSale } = useOpenSaleFrom();

    const handleClick = useCallback(
        async (nextStep: NextStep, saleId?: string) => {
            if (nextStep.value === 'view_sale' || nextStep.value === Status.Completed) {
                const sale = await getSaleDetail({
                    pathData: {
                        id: saleId!,
                    },
                });

                if (sale) {
                    onShowFormSale(sale, { subDrawer: true });
                }
                return;
            }
            setValue('status', nextStep.value);

            handleUpdate(defaultValues?.id, nextStep.value);
        },
        [defaultValues?.id, getSaleDetail, handleUpdate, onShowFormSale, setValue]
    );

    return handleClick;
};
