import { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

interface TranslateProps {
	readonly type?: 'html' | 'string';
	readonly translateCode: string;
	readonly option?: TOptions;
}
export function Translate({ translateCode, type = 'string', option }: TranslateProps) {
    const { t } = useTranslation();

    if (type === 'string') {
        return t(translateCode, option);
    }

    return <span dangerouslySetInnerHTML={{ __html: t(translateCode, option) }} />;
}
