import { useResetLoginState } from '@esg/auth';
import React from 'react';

export const useLogout = () => {
    const resetLoginState = useResetLoginState();

    return React.useCallback(() => {
        resetLoginState();
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    }, [resetLoginState]);
};