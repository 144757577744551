import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface CreateProductCategoryRequest extends HttpRequestData {
    readonly body: {
        name: string;
        description?: string;
    };
}

export interface CreateProductCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const createProductCategory = httpUtil.createHttpRequestMeta<
    CreateProductCategoryRequest,
    CreateProductCategoryResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/product-categories',
    method: 'POST',
    authentication: 'bearer',
});
