import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';

interface GetAllPackageCategoryRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllPackageCategoryResponseItem {
    readonly id: string;
    readonly name: string;
    readonly description: string;
}

export const getAllPackageCategory = httpUtil.createHttpRequestMeta<
    GetAllPackageCategoryRequest,
    PaginationResponse<GetAllPackageCategoryResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/package-categories',
    method: 'GET',
    authentication: 'bearer',
});
