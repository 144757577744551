import { checkOverlap, dayjs } from '@esg/shared';
import { AppointmentQuantityByEmployee } from '@esg/business-schedule';
import { useMemo } from 'react';
import { useAppointmentContext } from '../AppointmentFormContext';
import { ServiceDetailsInfo, useServiceDetailsInfo } from './useServiceDetailsInfo';

interface CheckFullSlotParams {
	endTime: string;
	startTime: string;
	currentEmployeeId: string;
	guestIndex: number;
	serviceDetailsInfo: ServiceDetailsInfo[];
	employeeWorkTimes: AppointmentQuantityByEmployee[];
}

interface Params {
    date: string;
    endTime: string;
    startTime: string;
    guestIndex: number;
    currentServiceId: string;
}

export const useEmployeeOptions = ({ guestIndex, startTime, endTime, currentServiceId, date }: Params) => {
    const { employees, employeeDisableTimes, employeeWorkTimes } = useAppointmentContext();

    const serviceDetailsInfo = useServiceDetailsInfo();

    const dayOfWeek = dayjs(date).day();

    const result = useMemo(() => employees.map((st) => {
        const isDayOff = st.shifts.find((s) => s.dayOfWeek === dayOfWeek)?.isDayOff ?? false;

        const serviceIds = st.employeeServices.map((ss) => ss.serviceId);
        const isNoProvider = currentServiceId ? !serviceIds.includes(currentServiceId) : false;

        const employeeBreakTimes = employeeDisableTimes.filter((b) => b.employeeId === st.id);
        const isOverlap = checkOverlap(employeeBreakTimes, startTime, endTime);

        const isFull = checkFullSlot({
            endTime,
            startTime,
            guestIndex,
            employeeWorkTimes,
            serviceDetailsInfo,
            currentEmployeeId: st.id,
        });

        const employeeNoValid = isOverlap || isDayOff || isFull;

        return {
            value: st.id,
            isNoProvider,
            avatar: st.avatar,
            label: st.fullName,
            fullName: st.fullName,
            isOverlap: employeeNoValid,
        };
    }), [currentServiceId, dayOfWeek, endTime, guestIndex, serviceDetailsInfo, employeeDisableTimes, employeeWorkTimes, employees, startTime]);
    
    return result;
};

const checkFullSlot = ({
    endTime,
    startTime,
    guestIndex,
    employeeWorkTimes,
    currentEmployeeId,
    serviceDetailsInfo,
}: CheckFullSlotParams) => {
    serviceDetailsInfo = serviceDetailsInfo.filter(
        (s) => s.employeeIds.includes(currentEmployeeId) && s.guestIndex !== guestIndex
    );

    const isOverlapWithClient = checkOverlap(serviceDetailsInfo, startTime, endTime);

    if (isOverlapWithClient && employeeWorkTimes[0]?.limit === 1) {
        return true;
    }

    employeeWorkTimes = employeeWorkTimes
        .filter((s) => s.employeeId === currentEmployeeId)
        .map((u) => {
            const currentQuantity = serviceDetailsInfo.filter((sd) => 
                checkOverlap(
                    [
                        {
                            startTime: sd.startTime,
                            endTime: sd.endTime,
                        },
                    ],
                    u.startTime,
                    u.endTime
                )
            ).length + u.quantity;

            return {
                ...u,
                quantity: currentQuantity,
                isFull: currentQuantity >= u.limit,
            };
        })
        .filter((o) => o.isFull);

    return checkOverlap(employeeWorkTimes, startTime, endTime);
};

