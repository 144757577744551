import { FilterOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';

import { usePagination } from '../../hooks';
import { AppButton } from '../buttons';
import { InputSearch } from '../inputs/input-search';
import { Block, FlexBox } from '../layouts';
import { Text } from '../typo';
import { IconPrint } from '../icons';
import './TableCustom.scss';

interface HeaderTableProps {
    titleTable?: string;
    subTitleTable?:React.ReactNode; 
    onSearch?: (value: string) => void;
    playHolderInputSearch?: string;
    onFilter?: () => void;
    disableFilter?: boolean;
    onExportExcel?: ()=> void;
}

export const HeaderTable = (props: HeaderTableProps) => {
    const { onSearch } = props;
    const [searchParams] = usePagination();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleSearch = useCallback(
        debounce((search: string) => {
            onSearch?.(search);
        }, 1000),
        []
    );

    return (
        <FlexBox justifyContent='space-between' alignItems='center'>
            <FlexBox alignItems='center' gap={12}>
                <Text fontSize={18} fontWeight={600}>
                    {props.titleTable}
                </Text>
                {props.subTitleTable}
            </FlexBox>
            <Block width='50%'>
                <FlexBox justifyContent='flex-end' alignItems='center' gap={16}>
                    {props.onSearch && <InputSearch
                        height={40}
                        defaultValue={searchParams.search}
                        width={288}
                        onChange={(e) => debouncedHandleSearch(e.target.value)}
                        placeholder={props.playHolderInputSearch}
                    />}
                    {props.onExportExcel &&
                        <AppButton 
                            borderColor='#079455'
                            onClick={props.onExportExcel}>
                            <FlexBox gap={6}>
                                <IconPrint color='#079455' />
                                <Text translateCode='Export Data' />
                            </FlexBox>
                        </AppButton>
                    }
                    {props.onFilter && <AppButton
                        onClick={props.onFilter}
                        translateCode='Filter'
                        icon={<FilterOutlined />}
                    />}
                </FlexBox>
            </Block>
        </FlexBox>
    );
};
