import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface DeleteJobTypeRequest extends HttpRequestData {}

export const deleteJobType = httpUtil.createHttpRequestMeta<DeleteJobTypeRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/job-types/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
