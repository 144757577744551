import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface DeleteShiftRequest extends HttpRequestData {

}

export const deleteShift = httpUtil.createHttpRequestMeta<
    DeleteShiftRequest,
    void
>({
    method: 'DELETE',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id',
});
