import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface IntegrationEmailDisconnectRequest extends HttpRequestData {
    body: {
        id: string
    }
}

export const integrationDisconnect = httpUtil.createHttpRequestMeta<IntegrationEmailDisconnectRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/integration-settings/:id/disconnect',
    method: 'PATCH',
    authentication: 'bearer',
});