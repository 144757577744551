import { FlexBox, Spacer, Text, Translate } from '@esg/ui';
import React from 'react';
import { Badge, Popover } from 'antd';
import { dayjs } from '@esg/shared';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BreakTimePopover } from './children/break-time-popover';
import './ScheduleBreakTimeItem.scss';
import { ScheduleTimeDisableValue } from '@/pages/scheduler/Types';
import { translateCodes } from '@/locales';

export const ScheduleBreakTimeItem = ({ data }: { data: ScheduleTimeDisableValue }) => {
    const { endTime, startTime, type, title } = data;

    return (
        <Spacer type='padding' size={2} height='calc(100% - 2px)' width='calc(100% - 2px)'>
            <Popover
                content={<BreakTimePopover data={data} />}
                title={renderTitle(data)}
                zIndex={10}
            >
                <div className='break-time-item'>
                    <div className='break-time-item-content'>
                        <FlexBox
                            direction='column'
                            justifyContent='center'
                            alignItems='baseline'
                            gap={4}
                            width='100%'
                            height='100%'
                        >
                            <Text color='#475467' fontSize={14}>
                                {dayjs(startTime).format('hh:mm A')}
								&nbsp;&nbsp;
                                <ArrowRightOutlined />
								&nbsp;&nbsp;
                                {dayjs(endTime).format('hh:mm A')}
                            </Text>
                            <Text color='#101828' fontSize={14} fontWeight={700}>
                                {type === 'BREAK_TIME' ? (
                                    <Translate translateCode={translateCodes.BREAK_TIME} />
                                ) : (
                                    title
                                )}
                            </Text>
                        </FlexBox>
                    </div>
                </div>
            </Popover>
        </Spacer>
    );
};

const renderTitle = ({ type }: ScheduleTimeDisableValue) => {
    return (
        <div className='break-title'>
            <Badge color='#98A2B3' />
            <Text color='#98A2B3'>
                {type === 'BREAK_TIME' ? (
                    <Translate translateCode={translateCodes.BREAK_TIME} />
                ) : (
                    <Translate translateCode={translateCodes.TIME_BLOCK} />
                )}
            </Text>
        </div>
    );
};
