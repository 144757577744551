import { PaginationRequest } from '../Types';
import { useSearchParams } from './useSearchParams';

export const usePagination = (value?: PaginationRequest) => {
    const initialValues: PaginationRequest = {
        page: '1',
        pageSize: '20',
        useCountTotal: 'true',
        orderBy: 'id',
        orderDirection: 'desc',
        search: '',
        ...value
    };

    return useSearchParams<PaginationRequest>(initialValues, [
        'page',
        'pageSize',
        'orderBy',
        'orderDirection',
        'search',
        'useCountTotal'
    ]);
};
