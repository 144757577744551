import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface DeleteCustomerGroupRequest extends HttpRequestData {
}

export const deleteCustomerGroup = httpUtil.createHttpRequestMeta<
    DeleteCustomerGroupRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-groups/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
