export const DateTimeFormatOptions = [
    {
        label: 'MMM DD, YYYY',
        value: 'MMM DD, YYYY'
    },
    {
        label: 'DD MMM, YYYY',
        value: 'DD MMM, YYYY'
    },
    {
        label: 'DD-MM-YYYY',
        value: 'DD-MM-YYYY'
    },
    {
        label: 'MM-DD-YYYY',
        value: 'MM-DD-YYYY'
    },
    {
        label: 'YYYY-MM-DD',
        value: 'YYYY-MM-DD'
    },
];