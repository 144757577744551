import React, { useMemo } from 'react';
import { Line } from '@ant-design/charts';
import {useHttpQuery } from '@esg/ui';
import {getOverviewChartClientReport } from '@esg/business-report';
import { Dayjs } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { chartHelper } from '@/helpers';
import { translateCodes } from '@/locales';

interface AnalyserClientLineChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface LineDataChart {
    date?: string;
    type?: string;
    value?: number;
}
export default function AnalyserClientLineChart(props: AnalyserClientLineChartProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const numberOfDays = useMemo(()=>dateRange[1].diff(dateRange[0], 'd'),[dateRange]);
    const viewType = useMemo(()=>chartHelper.getViewTypeChart(numberOfDays),[numberOfDays]);

    const {data: overviewChartClient, isLoading} = useHttpQuery(getOverviewChartClientReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            viewType: viewType,
        }
    });

    const dataChart : LineDataChart[] = useMemo(()=>(overviewChartClient?.overviewChartInfos?.map(o=> ([
        {
            date: chartHelper.getLabelDateByViewChart(viewType, o.date),
            type: t(translateCodes.RETENTION_CLIENT),
            value: o.retentionClient
        },
        {
            date: chartHelper.getLabelDateByViewChart(viewType, o.date),
            type: t(translateCodes.NEW_CLIENT),
            value: o.newClient
        },
        {
            date: chartHelper.getLabelDateByViewChart(viewType, o.date),
            type: t(translateCodes.WALK_IN_CLIENT),
            value: o.walkInClient
        },
    ])).flat() ?? []),[overviewChartClient, t, viewType]);

    const lineChartConfig = useMemo(()=>( {
        data: dataChart,
        xField: 'date',
        yField: 'value',
        colorField: 'type',
        axis: {
            y: {
                labelFormatter: (v: unknown) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        scale: { color: { range: ['#F04438', '#17B26A', '#2970FF'] } },
        style: {
            lineWidth: 2,
        },
    }),[dataChart]);

    return (
        !isLoading && (
            <Line {...lineChartConfig} loading={isLoading}/>
        )
    );
}
