import { useEffect } from 'react';
import { useShiftContext } from '../../ShiftContext';
import { shiftEvents } from '@/event';

export const useRefetchShifts = () => {
    const { refetchShifts } = useShiftContext();
    useEffect(() => {
        const listen = shiftEvents.refetch.listen(() => {
            refetchShifts();
        });
        
        return () => {
            listen();
        };
    }, [refetchShifts]);
};
