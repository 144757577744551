import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface EmployeeServiceUpdateRequest extends HttpRequestData {
	readonly body: {
		readonly id?: string;
		readonly serviceIds?: string[];
	};
}

export const updateEmployeeService = httpUtil.createHttpRequestMeta<EmployeeServiceUpdateRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/employees/:id/update-employee-services',
    method: 'PATCH',
    authentication: 'bearer',
});
