import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface AppointmentServiceEmployeeUpdateRequest extends HttpRequestData {
    body: {
        readonly id: string;
        readonly employeeId: string;
    }
}

export const updateAppointmentServiceEmployee = httpUtil.createHttpRequestMeta<AppointmentServiceEmployeeUpdateRequest, void>({
    baseUrl: env.apiUrlBase,
    method: 'PATCH',
    path: '/api/v1/appointment-service-employees/:id',
    authentication: 'bearer',
});
