import React from 'react';
import { IconProps } from '../../Types';

export const IconUserCheck = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.5 1.04167C8.31388 1.04167 6.54167 2.81387 6.54167 5C6.54167 7.18613 8.31388 8.95833 10.5 8.95833C12.6861 8.95833 14.4583 7.18613 14.4583 5C14.4583 2.81387 12.6861 1.04167 10.5 1.04167ZM7.79167 5C7.79167 3.50423 9.00423 2.29167 10.5 2.29167C11.9958 2.29167 13.2083 3.50423 13.2083 5C13.2083 6.49577 11.9958 7.70833 10.5 7.70833C9.00423 7.70833 7.79167 6.49577 7.79167 5Z'
                fill={props.color}
            />
            <path
                d='M16.2129 13.8108C16.4606 14.0512 16.4666 14.4468 16.2262 14.6945L14.6985 16.2686C14.5751 16.3958 14.4035 16.4646 14.2264 16.4579C14.0493 16.4512 13.8834 16.3696 13.7699 16.2335L13.0753 15.4002C12.8543 15.135 12.8901 14.7409 13.1553 14.5199C13.4204 14.2989 13.8145 14.3347 14.0355 14.5998L14.2853 14.8995L15.3291 13.824C15.5695 13.5763 15.9652 13.5704 16.2129 13.8108Z'
                fill={props.color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.0923 18.0069C11.5826 18.0841 11.049 18.125 10.5 18.125C8.80056 18.125 7.23127 17.7327 6.06513 17.0663C4.91667 16.4101 4.04167 15.4082 4.04167 14.1667C4.04167 12.9251 4.91667 11.9233 6.06513 11.267C7.23127 10.6006 8.80056 10.2083 10.5 10.2083C12.0037 10.2083 13.4021 10.5153 14.5098 11.0447C14.5618 11.0427 14.6141 11.0417 14.6667 11.0417C16.8528 11.0417 18.625 12.8139 18.625 15C18.625 17.1861 16.8528 18.9583 14.6667 18.9583C13.6837 18.9583 12.7844 18.6 12.0923 18.0069ZM11.9583 15C11.9583 13.5042 13.1709 12.2917 14.6667 12.2917C16.1624 12.2917 17.375 13.5042 17.375 15C17.375 16.4958 16.1624 17.7083 14.6667 17.7083C13.1709 17.7083 11.9583 16.4958 11.9583 15ZM12.5146 11.6772C11.4275 12.3828 10.7083 13.6074 10.7083 15C10.7083 15.6691 10.8743 16.2994 11.1674 16.852C10.949 16.8672 10.7262 16.875 10.5 16.875C8.97779 16.875 7.63041 16.5211 6.6853 15.981C5.72251 15.4309 5.29167 14.7661 5.29167 14.1667C5.29167 13.5673 5.72251 12.9025 6.6853 12.3523C7.63041 11.8122 8.97779 11.4583 10.5 11.4583C11.2176 11.4583 11.8973 11.537 12.5146 11.6772Z'
                fill={props.color}
            />
        </svg>
    );
};

IconUserCheck.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
