import React, { useMemo } from 'react';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { Block, FlexBox } from '../layouts';
import { Text } from '../typo';
import { translateCodes } from '../../locales';
export interface EmptyTableProps {
    readonly isSearch?: boolean;
    readonly title?: string;
    readonly description?: string;
    readonly actionButton?: React.JSX.Element;
}

export default function EmptyTable(props: EmptyTableProps) {
    const { t } = useTranslation();
    const {
        isSearch,
        title = t(translateCodes.TABLE_EMPTY_TITLE),
        description = t(translateCodes.TABLE_EMPTY_DESCRIPTION),
        actionButton = null
    } = props;

    const emptyContent = useMemo(() => {
        if (isSearch) {
            return {
                imageEmptyUrl: '/assets/images/empty-search.png',
                title: t(translateCodes.TABLE_SEARCH_EMPTY_TITLE),
                description: t(translateCodes.TABLE_SEARCH_EMPTY_DESCRIPTION),
            };
        }

        return {
            imageEmptyUrl: '/assets/images/empty-data.png',
            title: title,
            description: description,
            actionButton: actionButton
        };
    }, [actionButton, description, isSearch, t, title]);

    return (
        <FlexBox
            height='100%'
            justifyContent='center'
            alignItems='center'
            direction='column'
        >
            <Image
                preview={false}
                width={130}
                height={107}
                alt={emptyContent.title}
                src={emptyContent.imageEmptyUrl}
            />
            <FlexBox width={350} gap={16} direction='column'>
                <Text fontSize={16} fontWeight={600}>
                    {emptyContent.title}
                </Text>
                <Text fontSize={14} color='gray'>
                    {emptyContent.description}
                </Text>
                {emptyContent.actionButton && 
                <FlexBox justifyContent='center'>
                    <Block width='fit-content' >
                        {emptyContent.actionButton}
                    </Block>
                </FlexBox>
                }
            </FlexBox>
        </FlexBox>
    );
}
