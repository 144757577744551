import { Paragraph, Spacer, Text, useLocalesFormat } from '@esg/ui';
import React from 'react';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { translateCodes } from '@/locales';

export const AppointmentDeleteConfirm = ({ item }: { item: AppointmentGetDetailResponse}) => {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    
    return (
        <Spacer top='xs'>
            <Paragraph fontSize={18} fontWeight={700}>
                <Text translateCode={translateCodes.DELETE_APPOINTMENT} />
            </Paragraph>
            <Spacer type='margin' top={8} />
            <Paragraph>
                <Text translateCode={translateCodes.DELETE_APPOINTMENT_CONFIRM_MESSAGE} />:
                <Text fontWeight={700}> 
                    {' '+ item?.customer.fullName} at {formatDate(item?.appointmentDate,  'h:mm a ddd, ' + currentLocale?.format )}?
                </Text>
            </Paragraph>
        </Spacer>
    );
};
