import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateServiceAllowBookingRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        serviceIds: string[];
    };
}

export const updateServiceAllowBookingSetting = httpUtil.createHttpRequestMeta<
    UpdateServiceAllowBookingRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/booking-online-settings/:id/services-allow',
    method: 'PATCH',
    authentication: 'bearer',
});
