import { globalDrawerState } from '@esg/ui';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { BreakTimeGetDetailResponse } from '@esg/business-resource-management';
import { BreakTimeForm } from '@/components/form';

export const useBreakTimeForm = () => {
    const [,showForm] = useRecoilState(globalDrawerState);
    const showBreakTimeForm = useCallback((defaultValues: BreakTimeGetDetailResponse ) => {
        showForm({
            isOpen: true,
            content: <BreakTimeForm defaultValues={defaultValues}/>,
            titleTransCode: 'Edit break time'
        });
    },[showForm]);

    return showBreakTimeForm;
};