import { GetServicesAppointmentItem, getServicesAppointment } from '@esg/business-report';
import { TableCustom, globalDrawerState, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import AnalyserAppointmentDetailTable from './AppointmentDetailTable';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';
import { translateCodes } from '@/locales';

interface AppointmentServicesTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AppointmentServicesTable( props: AppointmentServicesTableProps) {
    const { dateRange } = props;
    const { t } = useTranslation();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);
    const { formatCurrency } = useLocalesFormat();

    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeMain: '10',
        orderByMain: 'name',
    },);
    
    const {data: servicesAppointment, isFetching} = useHttpQuery(getServicesAppointment, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            search: searchParams.searchByMain,
            orderBy: searchParams.orderByMain,
            orderDirection: searchParams.mainDirection,
            page: searchParams.pageMain,
            pageSize: searchParams.pageSizeMain,
            ...searchParams
        },
    });

    const viewDetail = (serviceId: string) => {
        setDrawerGlobalState({
            titleTransCode: null,
            isOpen: true,
            hideHeader: true,
            content: (
                <AnalyserAppointmentDetailTable
                    dateRange={dateRange}
                    serviceIds={[serviceId]}
                />
            ),
            footer: null,
        });
    };

    const columns: TableColumnsType<GetServicesAppointmentItem> = [
        {
            title: 'Service Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.mainDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, mainDirection: order });
                }
            }),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            render: (_, record) =>{
                return formatCurrency(record.revenue);
            }
        },
        {
            title: 'Links',
            key: 'links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={()=>viewDetail(record.id)}>View Detail</Tag>
                );
            }
        },
    ];

    return (
        <TableCustom
            titleTableTransCode='Service Summary'
            loading={isFetching}
            columns={columns}
            dataSource={servicesAppointment?.items}
            pageInfo={servicesAppointment?.meta}
            placeholderSearchTransCode={`${t(translateCodes.SEARCH)} ${t(translateCodes.SERVICE)}`}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByMain: value })
            }
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, pageMain: page.toString() })
            }
        />
    );
}
