import { DownOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { Dropdown } from 'antd';
import { getTimeByMinuteStep } from '@esg/shared';
import { useSchedulerContext } from '../../@share/SchedulerContext';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import { useTimeBlockForm } from '@/hooks/useTimeBlockForm';
import { useBusinessHour } from '@/hooks/useBusinessHour';

export const SchedulerAppointmentBtn = () => {
    const { currentDate, appointmentSetting } = useSchedulerContext();
    const showAppointmentForm = useAppointmentForm();
    const showTimeBlockForm = useTimeBlockForm();
    const {
        branchWorkingTime: [start],
    } = useBusinessHour(currentDate);

    const date = useMemo(
        () => getTimeByMinuteStep(appointmentSetting?.timeSlotDuration ?? 30, currentDate),
        [appointmentSetting?.timeSlotDuration, currentDate]
    );

    const menu = useMemo(
        () => ({
            items: [
                {
                    label: 'Add Appointment',
                    key: '1',
                    onClick: () => {
                        showAppointmentForm({
                            date,
                        });
                    },
                },
                {
                    label: 'Add Time Block',
                    key: '2',
                    onClick: () => {
                        showTimeBlockForm({
                            mode: 'create',
                            currentDate: start.toISOString(),
                        });
                    },
                },
            ],
        }),
        [date, showAppointmentForm, showTimeBlockForm, start]
    );

    return (
        <Dropdown.Button
            type='primary'
            trigger={['click']}
            icon={<DownOutlined />}
            menu={menu}
            onClick={() =>
                showAppointmentForm({
                    date,
                })
            }
        >
			Add Appointment
        </Dropdown.Button>
    );
};
