import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { AppointmentGetDetailResponse } from './getAppointmentDetail';

interface AppointmentUpcomingGetRequest extends HttpRequestData {
    query?: {
        customerId?: string;
    }
}

export const getUpcomingAppointments = httpUtil.createHttpRequestMeta<
    AppointmentUpcomingGetRequest,
	PaginationResponse<AppointmentGetDetailResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments/Upcoming',
    authentication: 'bearer'
});
