import React from 'react';

export const IconEdited = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='#0C6FF3'/>
            <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='white'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M11.2203 7.02893C11.3885 7.2024 11.3843 7.47937 11.2108 7.64758L9.57963 9.22933H15.5C15.7416 9.22933 15.9375 9.42521 15.9375 9.66683C15.9375 9.90845 15.7416 10.1043 15.5 10.1043H9.57963L11.2108 11.6861C11.3843 11.8543 11.3885 12.1313 11.2203 12.3047C11.0521 12.4782 10.7751 12.4825 10.6017 12.3142L8.19544 9.98091C8.11046 9.89851 8.0625 9.7852 8.0625 9.66683C8.0625 9.54846 8.11046 9.43515 8.19544 9.35275L10.6017 7.01941C10.7751 6.85121 11.0521 6.85547 11.2203 7.02893ZM12.7797 11.6956C12.9479 11.5221 13.2249 11.5179 13.3983 11.6861L15.8046 14.0194C15.8895 14.1018 15.9375 14.2151 15.9375 14.3335C15.9375 14.4519 15.8895 14.5652 15.8046 14.6476L13.3983 16.9809C13.2249 17.1491 12.9479 17.1449 12.7797 16.9714C12.6115 16.7979 12.6157 16.521 12.7892 16.3527L14.4204 14.771L8.5 14.771C8.25838 14.771 8.0625 14.5751 8.0625 14.3335C8.0625 14.0919 8.25838 13.896 8.5 13.896L14.4204 13.896L12.7892 12.3142C12.6157 12.146 12.6115 11.8691 12.7797 11.6956Z' fill='white'/>
        </svg>
    );
};
