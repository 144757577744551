import { createPaymentMethod } from './createPaymentMethod';
import { deletePaymentMethod } from './deletePaymentMethod';
import { getAllPaymentMethod, GetAllPaymentMethodResponseItem } from './getAllPaymentMethod';
import { updatePaymentMethod } from './updatePaymentMethod';

export type { GetAllPaymentMethodResponseItem };

export const paymentMethodApi = {
    getAllPaymentMethod,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod
};
