import { AppointmentStatus, RealtimeKeys } from '@esg/shared';
import { AppointmentGetDetailResponse, AppointmentService } from '@esg/business-schedule';
import { AptNotifyStatus, IJobType, RealtimeData } from '../Types';
import { translateCodes } from '@/locales';

export const realtimeDataParse = (data: RealtimeData): RealtimeData => {
    return {
        key: getRealtimeKey(data),
        data: data.data,
    };
};

export const getRealtimeKey = (data: RealtimeData): RealtimeKeys => {
    return data.key.split('-')[0] as RealtimeKeys;
};

export const getRealtimeJob = (data: RealtimeData): IJobType => {
    return data.key.split('_')[0] as IJobType;
};

//-------------//

export const getAptNotifyResource = (
    data: RealtimeData,
    appointment: AppointmentGetDetailResponse
) => {
    let status: AptNotifyStatus;
    switch (appointment.status) {
    case AppointmentStatus.Booked:
        status = 'book';
        break;
    case AppointmentStatus.Confirmed:
        status = 'confirm';
        break;
    default:
        if (data.key === RealtimeKeys.AppointmentNoShow) {
            status = 'no_show';
            break;
        }

        status = 'cancel';
        break;
    }
    return {
        status,
        customerName: appointment.customer.fullName,
        appointmentDuration: getAppointmentDuration(appointment.appointmentServices),
        serviceName: appointment.appointmentServices[0].appointmentServiceDetails[0].service.name,
    };
};

export const getNotifyDesc = (status: AptNotifyStatus) => {
    switch (status) {
    case 'book':
        return translateCodes.APPOINTMENT_BOOKED_DESC;
    case 'confirm':
        return translateCodes.APPOINTMENT_CONFIRM_DESC;
    case 'cancel':
        return translateCodes.APPOINTMENT_CANCEL_DESC;
    default:
        return translateCodes.APPOINTMENT_NO_SHOW_DESC;
    }
};

export const getAppointmentDuration = (appointmentServices: AppointmentService[]) => {
    const durations: number[] = [];

    appointmentServices.forEach((as) => {
        let sum = 0;
        as.appointmentServiceDetails.forEach((asd) => {
            sum += asd.service.duration;
        });
        durations.push(sum);
    });
    return Math.max(...durations);
};
