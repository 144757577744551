import { Dayjs } from '@esg/shared';
import React from 'react';
import { SchedulerDraggableObject, SchedulerDroppableField, SchedulerItem, SchedulerRowData, SchedulerRowInfo, getTimeClick } from '../../@shared';

interface HorizontalSchedulerRowProps {
    droppableBgColor?: string;
    baseColumnWidth: number;
    baseRowHeight: number;
    minutesPerCell: number;
    startTime: Dayjs;
    rowData: SchedulerRowData;
    timeCells: Dayjs[];
    renderItem?: (item: SchedulerItem) => React.ReactElement;
    onDragStart?: (item: SchedulerItem) => void;
    onDragEnd?: (item: SchedulerItem) => void;
    onDrop?: (item: SchedulerItem, rowInfo: SchedulerRowInfo) => void;
    onCreate?: (rowKey: string, date: string) => void;
}

export const HorizontalSchedulerRow = (props: HorizontalSchedulerRowProps) => {
    const checkItemCanDrop = React.useCallback(
        (checkingItem: SchedulerItem) => {
            // If the item is being dragged to the same row, it can't be dropped
            if (checkingItem.rowKey === props.rowData.rowInfo.key) {
                return false;
            }

            if (props.rowData.rowInfo.allowOverlap) {
                return true;
            }

            for (const item of props.rowData.items) {
                const isOverlapping = checkingItem.startTime.isBefore(item.endTime) && checkingItem.endTime.isAfter(item.startTime);
                if (isOverlapping) {
                    return false;
                }
            }

            return true;
        },
        [
            props.rowData,
        ]
    );

    const onDragOver = React.useCallback(
        (_item: SchedulerItem) => {
            // TODO: implement the logic
        },
        []
    );

    const onDragLeave = React.useCallback(
        () => {
            // TODO: implement the logic
        },
        []
    );

    const onDrop = React.useCallback(
        (item: SchedulerItem) => {
            props.onDrop?.(item, props.rowData.rowInfo);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onDrop, props.rowData.rowInfo]
    );

    const rowStyle = React.useMemo((): React.CSSProperties => ({
        width: props.baseColumnWidth * props.timeCells.length,
    }), [props.baseColumnWidth, props.timeCells.length]);

    const handleClick = React.useCallback(
        (e: React.MouseEvent) => {
            if ((e.target as HTMLElement).className !== 'sub-row') {
                return;
            }

            props.onCreate?.(
                props.rowData.rowInfo.key,
				getTimeClick({timeCells: props.timeCells, currentPosition:e.nativeEvent.offsetX, baseColumnWidth: props.baseColumnWidth})!
            );
        },
        [props]
    );

    return (
        <SchedulerDroppableField
            acceptType='SCHEDULER_ITEM'
            key={props.rowData.rowInfo.key}
            className='body-row'
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            validateDroppable={checkItemCanDrop}
            style={rowStyle}
            droppableBgColor={props.droppableBgColor}
        >
            <div className='body-row-grid'>
                {props.timeCells.map((timeCell) => (
                    <div
                        key={timeCell.toISOString()}
                        className='body-row-cell'
                        style={{ width: props.baseColumnWidth, minHeight: props.baseRowHeight, pointerEvents: 'none' }}
                    />
                ))}
            </div>
            {props.rowData.subRows.map((subRow, index) => {
                const listItem = [...props.rowData.timesDisabled, ...subRow];

                return (
                    <div
                        key={index}
                        className='sub-row'
                        style={{ height: props.baseRowHeight }}
                        onClick={handleClick}
                    >
                        {listItem.map((item) => (
                            <SchedulerDraggableObject
                                key={item.key}
                                item={item}
                                type='SCHEDULER_ITEM'
                                className='sub-row-item'
                                onDragStart={props.onDragStart}
                                onDragEnd={props.onDragEnd}
                                style={{
                                    left:
										props.baseColumnWidth *
										(item.startTime.diff(props.startTime, 'minute') /
											props.minutesPerCell),
                                    width:
										props.baseColumnWidth *
										(item.endTime.diff(item.startTime, 'minute') /
											props.minutesPerCell),
                                    minHeight: props.baseRowHeight,
                                }}
                            >
                                {props.renderItem?.(item)}
                            </SchedulerDraggableObject>
                        ))}
                    </div>
                );
            })}
        </SchedulerDroppableField>
    );
};