import { PlusCircleFilled } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ServiceForm } from '@/components/form';
import { translateCodes } from '@/locales';

interface CreateServiceBtnProps {
    readonly refetchServices?: () => void;
}

export const CreateServiceBtn = (props: CreateServiceBtnProps) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerGlobal = useResetRecoilState(globalDrawerState);

    return (
        <AppButton
            translateCode={translateCodes.CREATE_SERVICE}
            icon={<PlusCircleFilled />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: translateCodes.CREATE_SERVICE,
                    content: (
                        <ServiceForm
                            refetch={() => props.refetchServices?.()}
                            resetDrawerState={resetDrawerGlobal}
                        />
                    )
                });
            }}
        />
    );
};
