import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import { FlexBox, Text } from '@esg/ui';
import { Avatar } from 'antd';
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CustomerLabelTag } from '@/components/customer/CustomerLabelTag';
import { translateCodes } from '@/locales';

interface SaleCustomerColumnProps {
    record?: GetAllCustomerResponseItem;
}

export const SaleCustomerColumn = (props: SaleCustomerColumnProps) => {
    const { record } = props;
    const { t } = useTranslation();
    const nameAvatar = record?.firstName?.[0] + (record?.lastName ? record?.lastName?.[0] : record?.firstName?.[1]??'');

    return (
        <FlexBox gap={12} alignItems='center'>
            <Avatar
                key={record?.id}
                src={record?.avatar}
                icon={record ? undefined : <UserOutlined /> }
                style={{ backgroundColor: '#F2F4F7' }}
            >
                {record ? nameAvatar : undefined}
            </Avatar>

            <FlexBox direction='column'>
                <Text maxLines={1} maxWidth={220} fontSize={14} fontWeight={600} lineHeight='20px'>
                    {record ? record.fullName : t(translateCodes.WALK_IN)}
                </Text>
                {record && <CustomerLabelTag customer={record as GetCustomerDetailResponse} isTag={false} />}
            </FlexBox>
        </FlexBox>
    );
};
