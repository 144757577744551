import { useCallback } from 'react';
import { ItemType } from 'antd/es/menu/interface';
import { Badge } from 'antd';
import { useFormContext } from 'react-hook-form';
import { AppointmentStatus as Status } from '@esg/shared';
import { aptStatusInfos, IStatusType } from '../aptStatusInfos';

export const useAptStatusMenu = (
    handleUpdate: (id: string, status: Status) => Promise<void>,
    isValid: boolean
) => {
    const {
        setValue,
        formState: { defaultValues },
    } = useFormContext();

    const getMenu = useCallback(
        (list: IStatusType['more']): ItemType[] => {
            return list!.map((status, i) => {
                const { currentStep, color } = aptStatusInfos[status];
                return {
                    key: i,
                    label: <Badge text={currentStep} color={color} />,
                    onClick: () => {
                        handleUpdate(defaultValues!.id!, status);
                        setValue('status', status);
                    },
                    disabled: !isValid && status === Status.NoShow,
                };
            });
        },
        [defaultValues, handleUpdate, isValid, setValue]
    );

    return getMenu;
};
