import React from 'react';
import { IconProps } from '../../Types';

export const IconPhone = (props: IconProps) => {
    return (
        <svg
            {...props}
            width={14}
            height={14}
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.82175 1.362C2.6333 0.555055 3.96955 0.698692 4.64909 1.60642L5.48975 2.72935C6.04268 3.46794 5.99389 4.50021 5.33755 5.15282L5.17837 5.3111C5.17142 5.33129 5.15456 5.39477 5.17327 5.5154C5.21542 5.78707 5.44227 6.36334 6.39462 7.31028C7.34663 8.25688 7.92699 8.48351 8.2025 8.52577C8.32736 8.54492 8.39267 8.52707 8.41288 8.52002L8.68489 8.24956C9.26836 7.6694 10.1649 7.56095 10.8873 7.95366L12.161 8.64604C13.2516 9.23893 13.5272 10.7214 12.6337 11.6099L11.6866 12.5515C11.3882 12.8482 10.987 13.0956 10.4973 13.1413C9.29124 13.2537 6.47909 13.1102 3.52435 10.1723C0.765745 7.42933 0.23637 5.03734 0.169391 3.85878L0.668585 3.83041L0.169391 3.85878C0.135523 3.26284 0.417135 2.75864 0.775348 2.40246L1.82175 1.362ZM3.84857 2.20571C3.51082 1.75455 2.8813 1.71868 2.52684 2.07112L1.48044 3.11158C1.26049 3.33028 1.15467 3.5713 1.16778 3.80204C1.221 4.73844 1.64853 6.8969 4.22945 9.46316C6.93708 12.1554 9.43786 12.2357 10.4044 12.1456C10.6019 12.1272 10.7983 12.0246 10.9815 11.8424L11.9286 10.9008C12.3135 10.518 12.2286 9.821 11.6834 9.52462L10.4097 8.83224C10.058 8.64105 9.64599 8.70412 9.38998 8.95868L9.08635 9.26058L8.7338 8.90603C9.08635 9.26058 9.08587 9.26106 9.08539 9.26153L9.08441 9.2625L9.08239 9.26448L9.07806 9.26865L9.06829 9.27774C9.06128 9.28413 9.05326 9.29114 9.0442 9.29864C9.0261 9.31363 9.00383 9.33062 8.97721 9.34845C8.92383 9.3842 8.85335 9.42311 8.76479 9.4561C8.58421 9.52336 8.3461 9.55949 8.05089 9.51421C7.47303 9.42557 6.70753 9.03161 5.68953 8.01939C4.67187 7.00752 4.27459 6.24556 4.18509 5.66871C4.13933 5.37374 4.17583 5.13554 4.24392 4.95483C4.27729 4.86627 4.31661 4.79586 4.35269 4.7426C4.37069 4.71603 4.38782 4.69383 4.40293 4.67579C4.41049 4.66677 4.41756 4.65878 4.42398 4.6518L4.43314 4.64208L4.43733 4.63777L4.43932 4.63576L4.4403 4.63478C4.44077 4.6343 4.44125 4.63383 4.7938 4.98839L4.44125 4.63383L4.63246 4.44371C4.91819 4.15959 4.95822 3.68796 4.68922 3.32865L3.84857 2.20571Z'
                fill={props.color}
            />
        </svg>
    );
};

IconPhone.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
