import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../config';
import { GetUserDetailResponse, UserBranch } from './getUserDetail';

interface CreateUserRequest extends HttpRequestData {
    readonly body: {
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly phoneNumber: string;
        readonly countryCode: string;
        readonly roleId: string;
        readonly userBranches: UserBranch[];
    };
}

export const createUser = httpUtil.createHttpRequestMeta<
    CreateUserRequest,
    GetUserDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/users',
    method: 'POST',
    authentication: 'bearer',
});
