import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteEmailTemplateRequest extends HttpRequestData {
}

export const emailTemplateDelete = httpUtil.createHttpRequestMeta<
    DeleteEmailTemplateRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/email-templates/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
