import { getOverviewAppointment } from '@esg/business-report';
import { Block, FlexBox, IconAppointmentCancel, IconAppointmentOnline, IconGrowDown, IconGrowUp, IconRevenue, IconSalon, IconTotalAppointment, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Avatar, Card } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useMemo } from 'react';
import { dateHelper } from '@/helpers';

interface AnalyserAppointmentOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AnalyserAppointmentOverview(props: AnalyserAppointmentOverviewProps) {
    const { dateRange } = props;
    const { formatCurrency } = useLocalesFormat();

    const {data: overviewAppointment, isLoading} = useHttpQuery(getOverviewAppointment, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        }
    });

    const dateRangePrevious = useMemo(()=>dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]),[dateRange]);

    const {data: overviewAppointmentPrevious} = useHttpQuery(getOverviewAppointment, {
        query: {
            startDate: dateRangePrevious[0].toISOString(),
            endDate: dateRangePrevious[1].toISOString(),
        }
    });

    const renderGrow = (current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';

        if(previous === 0) {
            return ;
        }

        const percent = (grow / previous) * 100;

        return (
            <FlexBox alignItems='center' >
                <Text className='grow-level' color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );

    };

    const appointmentOverviews = useMemo(() => [
        {
            label: 'Total appointment',
            value: overviewAppointment?.totalAppointment ?? 0,
            preValue: overviewAppointmentPrevious?.totalAppointment ?? 0,
            color: '#EFF4FF',
            icon: <IconTotalAppointment/>
        },
        {
            label: 'Cancel',
            value: overviewAppointment?.totalAppointmentCancel ?? 0,
            preValue: overviewAppointmentPrevious?.totalAppointmentCancel ?? 0,
            color: '#FEF3F2',
            icon: <IconAppointmentCancel/>
        },
        {
            label: 'Online',
            value: overviewAppointment?.totalAppointmentOnline ?? 0,
            preValue: overviewAppointmentPrevious?.totalAppointmentOnline ?? 2,
            color: '#ECFDF3',
            icon: <IconAppointmentOnline/>
        },
        {
            label: 'Salon',
            value: overviewAppointment?.totalAppointmentSalon ?? 0,
            preValue: overviewAppointmentPrevious?.totalAppointmentSalon ?? -1,
            color: '#FFFAEB',
            icon: <IconSalon/>
        },
        {
            label: 'Appointment value',
            value: overviewAppointment?.totalAppointmentValue ?? 0,
            preValue: overviewAppointmentPrevious?.totalAppointmentValue ?? 0,
            color: '#EFF4FF',
            icon: <IconRevenue/>
        }
    ],[overviewAppointment, overviewAppointmentPrevious]);

    return (
        <FlexBox className='overview-appointment' direction='row' gap={16} flexWrap='wrap'>
            {appointmentOverviews.map((o, i)=> (
                <Card key={i} loading={isLoading} className='card-overview' style={{minWidth: 267, width: 'calc((20% - calc(64px/5))',  display: 'flex', alignItems: 'center'}}>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Block>
                            <Avatar
                                style={{ backgroundColor: o.color }}
                                size={42}
                                icon={o.icon}
                            />
                        </Block>
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={121} fontWeight={600} translateCode={o.label} />
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#101828' fontWeight='700' fontSize={18}>
                                        {o.label === 'Appointment value' ? formatCurrency(o.value) : o.value}
                                    </Text>
                                </Block>
                                {renderGrow(o.value, o.preValue)}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
