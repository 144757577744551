import { FlexBox, FormBase, FormLayoutModal, InputField, useHttpCommand } from '@esg/ui';
import React from 'react';
import { GetAllCustomerSourceResponseItem } from '@esg/business-crm';
import { CreateJobTypeResponse, resourceApis } from '@esg/business-resource-management';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

interface JobTypeFormProps {
    readonly onCancel?: () => void;
    readonly onSuccess?: (response?: CreateJobTypeResponse) => void;
    readonly jobType?: CreateJobTypeResponse;
    readonly activeDefault?: boolean;
}

export default function JobTypeForm(props: JobTypeFormProps) {
    const isUpdate = !!props.jobType?.id;
    const { onSuccess } = props;
    const {t} = useTranslation();
    const { mutateAsync: createJobType ,isPending: isLoadingCreate } = useHttpCommand(resourceApis.createJobType, {
        onSuccess
    });
    const { mutateAsync: editJobType, isPending: isLoadingUpdate } = useHttpCommand(resourceApis.updateJobType, {
        onSuccess
    });
    const submit = async (values: GetAllCustomerSourceResponseItem) => {
        isUpdate ? await editJobType({
            pathData: { id: props.jobType?.id },
            body: values,
        }) : await createJobType({ body: values });
    };

    return (
        <FormBase onSubmit={submit} defaultValues={props.jobType}>
            {() => (
                <FormLayoutModal
                    isLoadingButton={isLoadingCreate || isLoadingUpdate}
                    useSubmitButton={true}
                    okTextCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                    onCancel={props.onCancel}
                >
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            name='name'
                            label={translateCodes.JOB_TYPE_NAME}
                            placeholder={translateCodes.JOB_TYPE_PLACEHOLDER}
                            rules={{
                                required: translateCodes.VAL_SM_05,
                                maxLength: { value: 50, message: t(translateCodes.MAX_LENGTH_NAME, { number: 50 })}
                            }}
                        />
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
