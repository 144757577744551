import { GetAllServiceCategoryResponseItem } from '@esg/business-management';
import {
    managementProfileState,
    TreeCheckboxField,
    useLocalesFormat,
} from '@esg/ui';
import { Col, Row, Space, Tag } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { translateCodes } from '@/locales';

interface TreeServiceFieldProps {
    readonly mode?: 'edit' | 'view';
    readonly useSearch?: boolean;
    readonly label?: string;
    readonly serviceCategories?: GetAllServiceCategoryResponseItem[];
    readonly isLoading?: boolean;
}

export const TreeServiceField = (props: TreeServiceFieldProps) => {
    const {mode = 'edit', useSearch, label, serviceCategories, isLoading } = props;
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const { formatCurrency } = useLocalesFormat();
    const [currentProfile] = useRecoilState(managementProfileState);
    
    const valuesSelected = watch('serviceIds');

    const [searchValue, setSearchValue] = useState('');

    const serviceCategoriesTree = useMemo(() => {
        let categories = serviceCategories;

        if(useSearch) {
            categories = categories?.map((category) => ({
                ...category,
                services: category.services.filter((service) =>
                    service.name.toLowerCase().includes(searchValue.toLowerCase())
                ),
            }));
        }

        // if mode view, show only selected services
        if (mode === 'view') {
            categories = categories?.map((category) => ({
                ...category,
                services: category.services.filter((service) => valuesSelected?.includes(service.id)),
            }));
        }

        return categories?.filter((category) => category.services.length > 0);
    }, [serviceCategories, useSearch, mode, searchValue, valuesSelected]);

    const treeData = useMemo(
        () =>
            serviceCategoriesTree?.map((category) => ({
                key: category?.id,
                isLeaf: false,
                title: TreeServiceField.categoryTitle(category.name,category.services.length),
                children: category.services
                    ?.map((service) => {
                        const price = service.serviceBranches
                            .find(o=> o.branchId === currentProfile?.branchActive)?.price ?? 0;

                        return {
                            key: service?.id,
                            isLeaf: true,
                            title: TreeServiceField.serviceTitle(
                                service.name,
                                `${service.duration} ${t(translateCodes.MINS)}`,
                                formatCurrency(price)
                            ),
                        };
                    }),
            })),
        [currentProfile?.branchActive, formatCurrency, serviceCategoriesTree, t]
    );

    return (
        <TreeCheckboxField
            key={mode}
            isLoading={isLoading}
            treeData={treeData}
            checkable={mode === 'edit'}
            name='serviceIds'
            label={label}
            searchLabel={t(translateCodes.PLACE_HOLDER_SEARCH_SERVICE)}
            onSearch={useSearch ? (value) => setSearchValue(value) : undefined}
        />
    );
};

TreeServiceField.categoryTitle = (name: string, numberService: number) => {
    return (
        <Space size='small'>
            <h3>{name.toUpperCase()}</h3>
            <Tag color='processing'>{numberService}</Tag>
        </Space>
    );
};

TreeServiceField.serviceTitle = (
    name: string,
    duration: string,
    price: string
) => { 
    return (
        <Row className='service-item'>
            <Col span={10}><b>{name}</b></Col>
            <Col span={8}>{duration}</Col>
            <Col span={5} style={{ textAlign: 'right' }}>
                {price}
            </Col>
        </Row>
    );
};
