import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface Params {
	fieldName: string;
	employeeIds: string[];
	employeeOptions: DefaultOptionType[];
}

export const useUnAvailableEmployee = ({ employeeIds, employeeOptions, fieldName }: Params) => {
    const { setValue } = useFormContext();
    const result = useMemo<DefaultOptionType | undefined>(() => {
        const option = employeeOptions.find((st) => {
            if (employeeIds?.includes(st.value as string) && (st.isNoProvider || st.isOverlap)) {
                return true;
            }
            return false;
        });

        return option
            ? {
                ...option,
                warningMsg: `${option.fullName} ${
                    option.isOverlap
                        ? 'isn\'t available, please select other employee'
                        : 'doesn’t provide this service. Please select another employee.'
                }`,
            }
            : option;
    }, [employeeIds, employeeOptions]);

    useEffect(() => {
        result?.value
            ? setValue(`${fieldName}.isValid`, false)
            : setValue(`${fieldName}.isValid`, true);
    }, [fieldName, result?.value, setValue]);

    return result;
};