import React, { useMemo } from 'react';
import { Bar } from '@ant-design/plots';
import { Card } from 'antd';
import { Block, useHttpQuery } from '@esg/ui';
import { Dayjs } from '@esg/shared';
import { getOverviewClientSourceReport } from '@esg/business-report';
import { useTranslation } from 'react-i18next';
import { chartHelper } from '@/helpers';
import { translateCodes } from '@/locales';

interface AnalyserClientSourceBarChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface BarDataSourceChart {
    labelName?: number;
    value?: number;
}
function AnalyserClientSourceBarChart(props: AnalyserClientSourceBarChartProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const numberOfDays = useMemo(()=>dateRange[1].diff(dateRange[0], 'd'),[dateRange]);
    const viewType = useMemo(()=>chartHelper.getViewTypeChart(numberOfDays),[numberOfDays]);

    const {data: overviewChartClientGroup, isLoading} = useHttpQuery(getOverviewClientSourceReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            viewType: viewType,
        }
    });
    const dataChart : BarDataSourceChart[] = useMemo(()=>(overviewChartClientGroup?.map(item => ({
        labelName: item.name,
        value: item.newClient
    })) ?? []),[overviewChartClientGroup]);

    const config = useMemo(()=>( {
        data: dataChart,
        xField: 'labelName',
        yField: 'value',
        height: dataChart.length * 90,
        style: {
            maxWidth: 10,
            radius: 10,
        },
        className: 'bar-chart',
        markBackground: {
            label: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                text: ({originData}: any) => {
                    return `${originData.value}`;
                },
                position: 'right',
                dy: -20,
                style: {
                    fill: '#aaa',
                    fillOpacity: 1,
                    fontSize: 14,
                },
            },
            style: {
                fill: '#eee',
            },
        },
        label: {
            position: 'left',
            dy:-20,
            text: ({labelName}: BarDataSourceChart) => {
                return `${labelName}`;
            },
            style: {
                fill: '#1E1E1E',
            }
        },
        scale: {
            y: {
                domain: [0, 200],
            },
        },
        axis: {
            x: {
                tick: false,
                title: false,
                label: false
            },
        },
        interaction: {
            elementHighlightByColor: false,
        },
    }),[dataChart]);
    return (
        <Block width='100%' minWidth='300px'>
            <Card title={t(translateCodes.CLIENT_SOURCE)}>
                <Bar {...config} loading={isLoading} />
            </Card>
        </Block>
    );
}

export default AnalyserClientSourceBarChart;