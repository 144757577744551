/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateCodes } from '@/locales';

export const roleRules = {
    name: (t: any) => ({
        required: translateCodes.NAME_ROLE_REQUIRED,
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_NAME, { number: 50 }),
        },
    }),
    descriptions: (t: any) => ({
        maxLength: {
            value: 255,
            message: t(translateCodes.MAX_LENGTH_DESCRIPTION, { number: 255 }),
        },
    }),
 
};
