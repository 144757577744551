import React from 'react';
import { IconProps } from '../../Types';

export const IconLocation = (props: IconProps) => {
    return (
        <svg
            {...props}
            width={12}
            height={16}
            viewBox='0 0 12 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.166626 6.76187C0.166626 3.49586 2.76997 0.833008 5.99996 0.833008C9.22995 0.833008 11.8333 3.49586 11.8333 6.76187C11.8333 8.3386 11.3839 10.0316 10.5896 11.4942C9.79621 12.9551 8.63704 14.2245 7.18697 14.9023C6.43367 15.2544 5.56625 15.2544 4.81295 14.9023C3.36287 14.2245 2.2037 12.9551 1.41033 11.4942C0.615978 10.0316 0.166626 8.3386 0.166626 6.76187ZM5.99996 1.83301C3.33891 1.83301 1.16663 4.03133 1.16663 6.76187C1.16663 8.15992 1.56838 9.68995 2.2891 11.017C3.01079 12.3458 4.03064 13.4328 5.23639 13.9963C5.72133 14.223 6.27859 14.223 6.76353 13.9963C7.96928 13.4328 8.98913 12.3458 9.71082 11.017C10.4315 9.68995 10.8333 8.15992 10.8333 6.76187C10.8333 4.03133 8.661 1.83301 5.99996 1.83301ZM5.99996 5.16634C5.17153 5.16634 4.49996 5.83791 4.49996 6.66634C4.49996 7.49477 5.17153 8.16634 5.99996 8.16634C6.82839 8.16634 7.49996 7.49477 7.49996 6.66634C7.49996 5.83791 6.82839 5.16634 5.99996 5.16634ZM3.49996 6.66634C3.49996 5.28563 4.61925 4.16634 5.99996 4.16634C7.38067 4.16634 8.49996 5.28563 8.49996 6.66634C8.49996 8.04705 7.38067 9.16634 5.99996 9.16634C4.61925 9.16634 3.49996 8.04705 3.49996 6.66634Z'
                fill={props.color}
            />
        </svg>
    );
};

IconLocation.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#475467',
};
