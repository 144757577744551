import { FlexBox, IconAt, IconGMail, RadioGroupField, Spacer, Text } from '@esg/ui';
import { Radio } from 'antd';
import React from 'react';
import { translateCodes } from '@/locales';

interface IntegrationServiceFieldProps {
    readonly disabled?: boolean;
}

export const IntegrationServiceField = (props: IntegrationServiceFieldProps) => {
    return (
        <RadioGroupField name='configuration.service' label={translateCodes.CONNECTION_INFORMATION} disabled={props.disabled}>
            <FlexBox justifyContent='space-between' gap={20}>
                <label className='service' htmlFor='service-1'>
                    <IconGMail />
                    <Text translateCode={translateCodes.GMAIL} />
                    <Radio value={0} className='radio' id='service-1' />
                </label>
                <label className='service' htmlFor='service-2'>
                    <IconAt />
                    <Text translateCode={translateCodes.OTHER} />
                    <Radio value={1} className='radio' id='service-2' />
                </label>
            </FlexBox>

            <Spacer bottom={16} top={8}>
                <Text>
                    <Text fontWeight='bold' translateCode={translateCodes.NOTE}/>: <Text translateCode={translateCodes.EMAIL_LIMIT_MESSAGE}/>
                </Text>
            </Spacer>
        </RadioGroupField>
    );
};
