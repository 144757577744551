import { getUserProfile } from '@esg/business-account';
import { IdentityUserProfile, useHttpQuery, identityProfileState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';

interface WithUserProfileProps {
    readonly currentUserId: string;
    readonly children: (userProfile: IdentityUserProfile) => JSX.Element;
}

export const WithUserProfile = (props: WithUserProfileProps) => {
    const [userProfile, setUserProfile] = useRecoilState(identityProfileState);
    const { data: userProfileData } = useHttpQuery(getUserProfile);

    React.useEffect(() => {
        if (!userProfileData) {
            return;
        }
        setUserProfile({
            id: userProfileData.id,
            username: userProfileData.username,
            email: userProfileData.email,
            role: userProfileData.role,
            userOrganizations: userProfileData.userOrganizations,
        });
    }, [setUserProfile, userProfileData]);

    if (!userProfileData) {
        return null;
    }

    if (!userProfile) {
        return null;
    }

    return props.children(userProfile);
};