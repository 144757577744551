import { GetAllTaxResponseItem, taxApi } from '@esg/business-setting';
import {
    ColumnActions,
    Paragraph,
    Spacer,
    TableCustom,
    globalDrawerState,
    globalModalState,
    useHttpCommand,
    useHttpQuery,
    usePagination,
} from '@esg/ui';
import { TableColumnsType } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useTranslation } from 'react-i18next';
import { CreateTaxBtn } from './button-handler';

import { TaxForm } from '@/components';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { translateCodes } from '@/locales';

export const Tax = () => {
    const [searchParams, setSearchParams] = usePagination();
    const { t } = useTranslation();
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const [, setDrawerState] = useRecoilState(globalDrawerState);

    const resetModal = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { data: tax, isFetching, refetch } = useHttpQuery(taxApi.taxGetAll, {
        query: searchParams,
    });

    const { mutateAsync } = useHttpCommand(taxApi.taxDelete, {
        onSuccess() {
            refetch();
            resetModal();
            resetDrawerState();
        }
    });

    const showDeleteConfirm = useCallback((tax: GetAllTaxResponseItem) => {
        setModalGlobalState({
            isOpen: true,
            showModalDelete: true,
            content: (
                <Spacer top='xs'>
                    <Paragraph fontSize={18} fontWeight={700}>Delete Tax</Paragraph>
                    <Spacer type='margin' top={8} />
                    <Paragraph>Are you sure you want to delete Tax: <b>{tax.name}</b></Paragraph>
                </Spacer>
            ),
            onOk: async () => {
                await mutateAsync(
                    { pathData: { id: tax.id } },
                );
            },
        });
    },[mutateAsync, setModalGlobalState]);

    const onEdit = useCallback(
        (values: GetAllTaxResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: translateCodes.EDIT_TAX,
                content: (
                    <TaxForm
                        onDeleted={() => showDeleteConfirm(values)}
                        onSuccess={() => {
                            resetDrawerState();
                            refetch();
                        }}
                        defaultValues={values}
                    />
                ),
            });
        },
        [setDrawerState, showDeleteConfirm, resetDrawerState, refetch]
    );
    
    const columns: TableColumnsType<GetAllTaxResponseItem> = [
        {
            width: 200,
            title: 'Name',
            dataIndex: 'name',
            render: (_, record) => {
                return record.name;
            },
        },
        {
            width: 200,
            title: 'Tax rate',
            dataIndex: 'value',
            render: (_, record) => {
                return record.value + '%';
            },
        },
        {
            width: 116,
            title: 'Action',
            align: 'left',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showDeleteConfirm(record);
                        }}
                        onClickEdit={() => onEdit(record)}
                    />
                );
            },
        },
    ];
    
    return (
        <PageLayout
            pageTitleTransCode='Tax'
            rightHeaderPage={
                <CreateTaxBtn
                    refetchCreateTax={() => {
                        resetDrawerState();
                        refetch();
                    }}
                />
            }
        >
            <TableCustom
                showHeader={true}
                loading={isFetching}
                columns={columns}
                dataSource={tax?.items}
                pageInfo={tax?.meta}
                titleTableTransCode='Tax List'
                placeholderSearchTransCode='Search tax name'
                onSearch={(value) => { 
                    setSearchParams({ ...searchParams, search: value });
                }}
                emptyOption={{
                    title: t(translateCodes.ADD_TAX),
                    description: t(translateCodes.EMPTY_TAX_DESCRIPTION),
                    actionButton: (
                        <CreateTaxBtn
                            refetchCreateTax={() => {
                                resetDrawerState();
                                refetch();
                            }}
                        />
                    )
                }}
            />
        </PageLayout>
    );
};
