import { GetAllCustomerGroupResponseItem, customerApi } from '@esg/business-crm';
import {
    AppButton,
    ColumnActions,
    FlexBox,
    TableCustom,
    globalModalState,
    useHttpQuery,
    usePagination,
    Text,
    useHttpCommand,

} from '@esg/ui';
import { TableColumnsType, Tag ,Image} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState ,useResetRecoilState } from 'recoil';
import { PlusCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { translateCodes } from '@/locales';
import { CustomerGroupDeleteConfirm } from '@/components/customer/CustomerDeleteGroupConfirm';
import CreateGroupForm from '@/components/form/customer/children/customer-group/CreateGroupForm';
import { CRM_URL } from '@/configs';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';

export const CustomerGroupList = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();
    const [, setModalState] = useRecoilState(globalModalState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const { mutateAsync: deleteCustomerGroup, isPending: isLoadingDelete } = useHttpCommand(
        customerApi.deleteCustomerGroup,  
    );

    const {
        data: groups,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(customerApi.getAllCustomersGroup, {
        query: {
            ...searchParams,
        }
    });

    const showModalCreateGroup = (group?: GetAllCustomerGroupResponseItem) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: group==null?translateCodes.NEW_CLIENT_GROUP: translateCodes.EDIT_CLIENT_GROUP,
            content: <CreateGroupForm
                group={group}
                onCancel={() => resetGlobalModal()}
                onSuccess={() => {
                    resetGlobalModal();
                    refetch();
                }}
            />
        });
    };

    const handleDelete = React.useCallback(
        (group: GetAllCustomerGroupResponseItem) => {
            const onSuccess = () => {
                resetGlobalModal();
                refetch();
            };
            setModalState({
                content: (
                    <CustomerGroupDeleteConfirm {...{ group }} />
                ),
                isOpen: true,
                onOk: async () => {
                    await deleteCustomerGroup({ pathData: { id: group.id }}, {onSuccess});
                },
                showModalDelete: true,
            });
        },
        [deleteCustomerGroup, refetch, resetGlobalModal, setModalState]
    );

    const columns: TableColumnsType<GetAllCustomerGroupResponseItem> = [
        {
            title: t(translateCodes.GROUP_NAME),
            dataIndex: 'name',
            key: 'name',
            width: '80%',
        },
        {
            title: t(translateCodes.STATUS),
            dataIndex: 'active',
            key: 'active',
            render: (active) => {
                return <Tag color={active ? 'green' : 'default'}>{active ? t(translateCodes.ACTIVE) : t(translateCodes.INACTIVE)}</Tag>;
            },
        },
       
        {
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() =>  handleDelete(record)}
                        onClickEdit={() => showModalCreateGroup(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            rightHeaderPage={
                <FlexBox alignItems='center'>
                    <FlexBox gap={20} >
                        <Link to={CRM_URL}>
                            <Text translateCode={translateCodes.SIMPLE_CRM} fontWeight={500} fontSize={14} color='#475467' /> 
                        </Link>
                        <Image
                            preview={false}
                            src={resourceManagementTheme.arrowRightIcon}
                            width={14}
                            height={14}
                        />
                        <Text translateCode={translateCodes.CLIENT_GROUP} fontWeight={700} fontSize={14} color='#0C6FF3' />
                    </FlexBox>
                    <AppButton
                        translateCode={translateCodes.CREATE_NEW_GROUP}
                        icon={<PlusCircleFilled />}
                        type='primary'
                        onClick={()=>showModalCreateGroup()}
                    />
                </FlexBox>
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({
                        ...searchParams,
                        page: page.toString(),
                    })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading || isLoadingDelete}
                titleTableTransCode={translateCodes.GROUP_LIST}
                placeholderSearchTransCode={translateCodes.SEARCH_GROUP_PLACEHOLDER}
                columns={columns}
                dataSource={ groups?.items??[]}
                emptyOption={{
                    title: t(translateCodes.ADD_GROUP),
                    description: t(translateCodes.EMPTY_GROUP_DESCRIPTION),
                    actionButton: (
                        <AppButton
                            translateCode={translateCodes.CREATE_NEW_GROUP}
                            icon={<PlusCircleFilled />}
                            type='primary'
                            onClick={()=>showModalCreateGroup()}
                        />
                    )
                }}
            />
        </PageLayout>
    );
};
