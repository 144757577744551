import React from 'react';
import { dayjs, Dayjs } from '@esg/shared';

interface HorizontalSchedulerTimeMarkerProps {
    startTime: Dayjs;
    endTime: Dayjs;
    timeCells: Dayjs[];
    baseColumnWidth: number;
    renderTimeMaker: (() => React.JSX.Element) | boolean;
}

export const HorizontalSchedulerTimeMarker = ({renderTimeMaker = true, ...props}: React.PropsWithChildren<HorizontalSchedulerTimeMarkerProps>) => {
    const [timeMarkerPosition, setTimeMakerPosition] = React.useState(-1);
    const elementRef = React.useRef<HTMLDivElement>(null);

    const timeCellsLength = React.useRef<number>(props.timeCells.length);

    React.useLayoutEffect(
        () => {
            const now = dayjs();
            const startTime = props.startTime;
            const endTime = props.endTime;

            if (now.isBefore(startTime)) {
                return setTimeMakerPosition(-1);
            }

            const totalMinutes = endTime.diff(startTime, 'minute');
            const totalWidth = props.timeCells.length * props.baseColumnWidth;

            if (now.isAfter(endTime)) {
                return void setTimeMakerPosition(totalWidth);
            }

            const pointsPerMinute = totalWidth / totalMinutes;

            const diffNow = now.diff(startTime, 'minute');

            const currentPosition = diffNow * pointsPerMinute;

            if (timeMarkerPosition === -1 || props.timeCells.length !== timeCellsLength.current) {
                setTimeMakerPosition(currentPosition);
                setTimeout(() => {
                    elementRef.current!.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center'
                    });
                });
            }

            const moveMarkerPosition = setInterval(() => {
                setTimeMakerPosition(currentPosition);
            }, 1000 * 60);

            return () => clearInterval(moveMarkerPosition);
        },
        [
            props.baseColumnWidth,
            props.endTime,
            props.startTime,
            props.timeCells,
            timeMarkerPosition
        ]
    );

    return (
        <div
            ref={elementRef}
            className='time-marker'
            style={{
                left: timeMarkerPosition,
                minHeight: '100%'
            }}
        >
            {typeof renderTimeMaker === 'function' ? (
                renderTimeMaker()
            ) : (
                <>
                    <div
                        style={{
                            borderLeft: '1px dashed red',
                            height: 'calc(100% + 44px)',
                            position: 'absolute',
                            top: -44,
                            zIndex: 1,
                        }}
                    />
                    <div style={{
                        backgroundColor: 'red',
                        borderRadius: '8px 0 0 8px ',
                        height: '24px',
                        width: '70px',
                        position: 'absolute',
                        top: -36,
                        right: 0,
                        zIndex: 1,
                        color: 'white',
                        textAlign: 'center',
                        lineHeight: '24px',
                    }}>{dayjs().format('h:mm A')}</div>
                </>
            )}
        </div>
    );
};