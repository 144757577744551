import {
    GetAllServiceCategoryResponseItem,
    GetAllServiceResponseItem,
} from '@esg/business-management';
import {
    GetDetailFacilitiesResponse,
    EmployeeGetDetailResponse,
} from '@esg/business-resource-management';
import React, {
    PropsWithChildren,
    createContext,
    useContext,
    useMemo,
} from 'react';
import {
    AppointmentQuantityByEmployee,
    GetAllAppointmentSettingResponseItem,
} from '@esg/business-schedule';

import { FacilityTimeOffGetAllResponseItem } from '@esg/business-resource-management/api/facility/facilityTimeOffGetAll';
import { GetAllCustomerResponseItem } from '@esg/business-crm';
import { AppointmentFormValues } from '../AppointmentFormControl';
import { useAppointmentResources } from './hooks/useAppointmentResources';
import { EmployeeTimeFrame } from '@/helpers/schedulerHelper';

interface AptContextProviderProps {
    readonly refetch?: () => void;
    readonly defaultValues: Partial<AppointmentFormValues>;
}

interface IAppointmentContext extends AptContextProviderProps {
    readonly employees: EmployeeGetDetailResponse[];
    readonly employeeDisableTimes: EmployeeTimeFrame[];
    readonly facilities: GetDetailFacilitiesResponse[];
    readonly employeeWorkTimes: AppointmentQuantityByEmployee[];
    readonly categories: GetAllServiceCategoryResponseItem[];
    readonly facilityTimeOffs: FacilityTimeOffGetAllResponseItem[];
    readonly appointmentSetting?: GetAllAppointmentSettingResponseItem;
    readonly services: (GetAllServiceResponseItem & {
        employeeIds: string[];
    })[];
    readonly customers: GetAllCustomerResponseItem[];
}

const AppointmentContext = createContext<IAppointmentContext>(null!);

export const AppointmentContextProvider = ({
    children,
    ...props
}: PropsWithChildren<AptContextProviderProps>) => {
    const {
        appointmentSetting,
        services,
        facilities,
        categories,
        employees,
        employeeDisableTimes,
        employeeWorkTimes,
        facilityTimeOffs,
        customers
    } = useAppointmentResources();

    const serviceData = useMemo(
        () =>
            services.map((s) => {
                const employeeIds =
                    employees
                        .filter((st) => {
                            const serviceIds = st.employeeServices.map(
                                (ss) => ss.serviceId
                            );
                            return serviceIds.includes(s.id);
                        })
                        .map((o) => o.id) || [];

                return {
                    ...s,
                    employeeIds,
                };
            }),
        [services, employees]
    );

    const formContextValues: IAppointmentContext = useMemo(() => {
        return {
            ...props,
            employees,
            categories,
            facilities,
            employeeWorkTimes,
            facilityTimeOffs,
            employeeDisableTimes,
            appointmentSetting,
            services: serviceData ?? [],
            customers
        };
    }, [
        props,
        employees,
        categories,
        facilities,
        employeeWorkTimes,
        facilityTimeOffs,
        employeeDisableTimes,
        appointmentSetting,
        serviceData,
        customers
    ]);

    return (
        <AppointmentContext.Provider value={formContextValues}>
            {children}
        </AppointmentContext.Provider>
    );
};

export const useAppointmentContext = () => {
    const appointmentContext = useContext(AppointmentContext);
    return appointmentContext;
};
