import { globalModalState, useHttpCommand } from '@esg/ui';
import { useResetRecoilState } from 'recoil';
import { updateShifts } from '@esg/business-resource-management';
import {useCallback} from 'react';   
import { ShiftFormValues } from '../../Types';
import { shiftEvents } from '@/event';

export const useShiftFormBusiness = () => {
    const hideShiftForm = useResetRecoilState(globalModalState);
    const { mutateAsync: updateShiftAsync, isPending: loading } = useHttpCommand(updateShifts, {
        onSuccess() {
            hideShiftForm();
            shiftEvents.refetch.emit();
        },
    });

    const onSubmit = useCallback(
        async (body: ShiftFormValues) => {
            await updateShiftAsync({
                pathData: {
                    id: body.id,
                },
                body,
            });
        },
        [updateShiftAsync]
    );

    return { onSubmit, loading };
};
