import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetAppointmentQuantityByEmployeeRequest extends HttpRequestData{
    query: {
        date: string;
        currentAppointmentId: string;
    }
}

export interface AppointmentQuantityByEmployee{
    readonly employeeId: string;
    readonly quantity: number;
    readonly limit: number;
    readonly isFull: boolean;
    readonly startTime: string;
    readonly endTime: string
}

export const getAppointmentQuantityByEmployee = httpUtil.createHttpRequestMeta<
	GetAppointmentQuantityByEmployeeRequest,
	AppointmentQuantityByEmployee[]
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments/quantity-by-employee',
    authentication: 'bearer',
});