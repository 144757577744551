import { bankAccountApi } from '@esg/business-setting';
import { Card } from 'antd';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import {
    FlexBox,
    FormBase,
    FormLayout,
    globalDrawerState,
    InputField,
    Spacer,
    useHttpCommand,
} from '@esg/ui';
import { bankAccountRules } from '../rules';
interface BankAccountValues {
    id?: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
}

interface BankAccountProps {
    readonly onSuccess?: () => void;
    readonly defaultValues?: BankAccountValues;
}
export const BankAccountForm = (props: BankAccountProps) => {
    const { mutateAsync: createBankAccount, isPending: isCreating } = useHttpCommand(
        bankAccountApi.createBankAccount
    );

    const { mutateAsync: updateBankAccount, isPending: isUpdating } = useHttpCommand(
        bankAccountApi.updateBankAccount
    );

    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const isUpdate = !!props.defaultValues?.id;

    const onSubmit = React.useCallback(
        async (values: BankAccountValues) => {
            const onSuccess = () => {
                props.onSuccess?.();
                resetDrawerState();
            };

            if (isUpdate) {
                await updateBankAccount(
                    {
                        pathData: {
                            id: props.defaultValues?.id,
                        },
                        body: values,
                    },
                    { onSuccess }
                );
                return;
            }

            await createBankAccount(
                {
                    body: {
                        ...values
                    },
                },
                { onSuccess }
            );
        },
        [createBankAccount, isUpdate, props, resetDrawerState, updateBankAccount]
    );
    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                isLoadingButton={isCreating || isUpdating}
                labelSubmitCode={isUpdate ? 'Save' : 'Create'}
                width={500}
            >
                <Card>
                    <FlexBox>
                        <InputField
                            rules={bankAccountRules.accountName}
                            name='accountName'
                            label='Account Name'
                            placeholder='Enter Account Name'
                        />
                        <Spacer right='sm' />
                        <InputField
                            rules={bankAccountRules.accountNumber}
                            name='accountNumber'
                            label='Account Number'
                            placeholder='Enter Account Number'
                        />
                    </FlexBox>
                    <Spacer bottom='sm' />
                    <InputField
                        rules={bankAccountRules.bankName}
                        name='bankName'
                        label='Bank Account'
                        placeholder='Enter Bank Account'
                    />
                </Card>
            </FormLayout>
        </FormBase>
    );
};
