import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { StatusEmailTemPlate } from './emailTemplateCreate';

interface EmailTemplateUpdateRequest extends HttpRequestData {
    readonly body: {
        id: string;
        type?: string;
        subject?: string;
        status?: StatusEmailTemPlate;
        body?: string;
        bodyCustom?: string;
        SubjectCustom?: string;
        IsRevert?: boolean;
    };
}
export interface EmailTemplateUpdateResponseItem {
    id: string;
    type?: string;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    SubjectCustom?: string;
}

export const emailTemplateUpdate = httpUtil.createHttpRequestMeta<
    EmailTemplateUpdateRequest,
    EmailTemplateUpdateResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/email-templates/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
