import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';
import { GetClientType } from '../appointment-report';

interface GetPaymentMethodRevenueRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface PaymentMethodInfo {
   readonly id: string
   readonly name:string;
}
export interface GetPaymentMethodRevenueItem {
    readonly id: string;
    readonly paymentMethod: PaymentMethodInfo;
    readonly quantity: number;
    readonly total: number;
    readonly viewType?: GetClientType | 'REVENUE';
}

export const getPaymentMethodRevenueReport = httpUtil.createHttpRequestMeta<
    GetPaymentMethodRevenueRequest,
	PaginationResponse<GetPaymentMethodRevenueItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/payment-method',
    authentication: 'bearer',
});