import { PlusCircleOutlined } from '@ant-design/icons';
import { AppButton, FlexBox, Text } from '@esg/ui';
import React from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppointmentServiceDetail } from './AppointmentServiceDetail';
import { translateCodes } from '@/locales';

export interface IAppointmentServiceEmployee {
	id?: string;
	employeeId: string;
}

interface AppointmentServiceDetailsProps extends UseFieldArrayReturn {
	readonly parentFieldName: string;
	readonly guestIndex: number;
    readonly guestCode: string;
}

export const AppointmentServiceDetails = (props: AppointmentServiceDetailsProps) => {
    const { t } = useTranslation();
    const { fields, append, parentFieldName, guestIndex, remove } = props;

    return (
        <FlexBox direction='column'>
            {/* Add service btn */}
            <FlexBox alignItems='center' justifyContent='space-between'>
                <Text fontWeight='bold'>
                    {t(translateCodes.APT)} #{guestIndex + 1}
                    {props.guestCode && ` - ${props.guestCode}`}
                </Text>
                <AppButton
                    translateCode={translateCodes.ADD_SERVICE}
                    type='link'
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                        append({});
                    }}
                />
            </FlexBox>
            <FlexBox direction='column' gap={8}>
                {fields.map((item, index) => {
                    return (
                        <AppointmentServiceDetail
                            key={item.id}
                            parentFieldName={parentFieldName}
                            serviceIndex={index}
                            remove={remove}
                            fields={fields}
                            guestIndex={guestIndex}
                        />
                    );
                })}
            </FlexBox>
        </FlexBox>
    );
};
