import { DiscountUnit, GetDetailSaleResponse, SaleDetailGetAllResponseItem, SaleStatus, TipUnit } from '@esg/business-pos';
import { translateCodes } from '@/locales';

export const saleHelper = {
    calcSubTotal: (saleDetails: SaleDetailGetAllResponseItem[]) => {
        return saleDetails.reduce((acc, saleDetail) => {
            return acc + saleDetail.totalAmount;
        }, 0);
    },
    calcTipAmount: (sale?: GetDetailSaleResponse) => {
        if(sale?.tipUnit === TipUnit.Percent) {
            return (sale?.subTotal ??0) * (sale.tip??0) / 100;
        } 
        else if(sale?.tipUnit === TipUnit.Amount) {
            return sale?.tip;
        }

        return 0;
    },
    calcDiscountAmount: (saleDetails: SaleDetailGetAllResponseItem[]) => {
        return saleDetails?.reduce((acc, saleDetail) => {
            let discountAmount = 0;

            if(saleDetail.discountUnit === DiscountUnit.Percent) 
            {
                discountAmount == ((saleDetail.discount ?? 0) * saleDetail.objectPrice);
            } 
            else 
            {
                discountAmount += saleDetail.discount ?? 0;
            }

            return acc + discountAmount;
        }, 0) ?? 0;
    },
    calcTaxAmount: (saleDetails: SaleDetailGetAllResponseItem[]) => {
        return saleDetails?.reduce((acc, saleDetail) => {
            return acc + saleDetail.taxAmount;
        }, 0) ?? 0;
    },
    updateCurrentSale: (currentSale: GetDetailSaleResponse, saleDetails: SaleDetailGetAllResponseItem[]) => {
        const taxAmount = saleHelper.calcTaxAmount(saleDetails);
        const subTotal = saleHelper.calcSubTotal(saleDetails);
        const tipAmount = saleHelper.calcTipAmount(currentSale);

        return {
            ...currentSale,
            saleDetails: saleDetails,
            subTotal: saleHelper.calcSubTotal(saleDetails),
            taxAmount: saleHelper.calcTaxAmount(saleDetails),
            totalAmount: subTotal + taxAmount + tipAmount,
        };
    },
    getTextStatus: (status?: SaleStatus) => {
        switch(status) {
        case SaleStatus.Paid:
            return translateCodes.PAID;
        case SaleStatus.Unpaid:
            return translateCodes.UNPAID;    
        case SaleStatus.Cancelled:
            return translateCodes.CANCELLED;
        default:
            return translateCodes.UNPAID;
        }
    }
};