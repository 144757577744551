import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { IntegrationEmailConfig, IntegrationStatus, IntegrationType, } from './Types';

interface IntegrationEmailGetAllRequest extends HttpRequestData {}

export type IntegrationEmailGetAllResponse = IntegrationEmailGetAllResponseItem[];

export interface IntegrationEmailGetAllResponseItem {
	readonly id: string;
	readonly type: IntegrationType;
    readonly status: IntegrationStatus;
	readonly configuration: IntegrationEmailConfig
}

export const integrationEmailGetAll = httpUtil.createHttpRequestMeta<IntegrationEmailGetAllRequest, IntegrationEmailGetAllResponse>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/integration-settings/email',
    authentication: 'bearer',
});