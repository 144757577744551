import {
    Block,
    FlexBox,
    Spacer,
    Text,
    useHttpCommand,
    useHttpQuery,
    managementProfileState } from '@esg/ui';
import { Avatar, Button, Dropdown, MenuProps, Radio } from 'antd';
import React, { useCallback } from 'react';

import { useRecoilState } from 'recoil';
import { DownOutlined } from '@ant-design/icons';
import { userUpdateBranch } from '@esg/business-account/api/users/userUpdateBranch';

import { currentLocaleState } from '@esg/ui/state/currentLocale';

import { branchApis } from '@esg/business-organization';

export function SelectSalonBranch() {
    const [currentProfile, setCurrentProfile] = useRecoilState(managementProfileState);
    const [, setCurrentLocale] = useRecoilState(currentLocaleState);

    const { data: branchesResponse } = useHttpQuery(branchApis.getAllBranches);
    const { mutateAsync: editBranchActive } = useHttpCommand(
        userUpdateBranch,
        {
            onSuccess(response) {
                const newBrachCurrent = branchesResponse?.items.find(ub => ub.id == response.branchActive);
                if (currentProfile) {
                    setCurrentProfile({
                        ...currentProfile,
                        branch: newBrachCurrent!,
                    });

                    setCurrentLocale({
                        country: newBrachCurrent?.country,
                        currency: newBrachCurrent?.currency,
                        timezone: newBrachCurrent?.timezone,
                        language: newBrachCurrent?.languages,
                        format: newBrachCurrent?.dateTimeFormat,
                    });
                }
            },
        }
    );
    const handleChangeBranch = useCallback(
        async (id: string) => {
            currentProfile && (
                await editBranchActive({
                    body: {
                        id: currentProfile.id,
                        branchActive: id
                    },
                })
            );
            return;
        },
        [currentProfile, editBranchActive]
    );

    const items: MenuProps['items'] = branchesResponse?.items
        .filter(b => b.organizationId === currentProfile?.organization.id)
        .map(branch => ({
            key: branch.id,
            label: (
                <div className='12' onClick={() => handleChangeBranch(branch.id)}>
                    <FlexBox alignItems='center' gap={16}>
                        <Avatar shape='square' size={36} src={branch.logo} />
                        <FlexBox direction='column' width={150}>
                            <Text fontSize={16} fontWeight={700} lineHeight='24px' textOverflow='ellipsis' overflow='hidden'>{branch.name}</Text>
                            <Spacer type='margin' bottom={4} />
                            <Text lineHeight='20px' color='#576676' textOverflow='ellipsis' overflow='hidden'>{branch.address}</Text>
                        </FlexBox>
                        <Radio checked={currentProfile?.branch.id === branch.id} className='not-show-when-selected' />
                    </FlexBox>
                </div>
            ),
        }));

    return (
        <Block>
            <FlexBox alignItems='center'>
                <Dropdown menu={{ items }} placement='bottomLeft' arrow>
                    <Button>
                        <FlexBox alignItems='center'>
                            <Avatar shape='square' size={36} src={currentProfile?.branch.logo} />
                            <Spacer type='margin' right={12} />
                            <Text fontSize={16} fontWeight={700} lineHeight='24px' textOverflow='ellipsis' overflow='hidden'>{currentProfile?.branch.name}</Text>
                            <DownOutlined style={{marginLeft: 10}}/>
                        </FlexBox>
                    </Button>
                </Dropdown>
            </FlexBox>
        </Block>
    );
}
