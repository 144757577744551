import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface FacilityTimeOffGetAllRequest extends HttpRequestData {
    query: {
        date: string
    }
}

export interface FacilityTimeOffGetAllResponseItem {
    facilityId: string
    startTime: string
    endTime: string
}

export const facilityTimeOffGetAll = httpUtil.createHttpRequestMeta<
	FacilityTimeOffGetAllRequest,
	FacilityTimeOffGetAllResponseItem[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/facilities/time-offs',
    method: 'GET',
    authentication: 'bearer',
});