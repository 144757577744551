import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { AppointmentGetDetailResponse } from './getAppointmentDetail';

interface AppointmentGetAllRequest extends HttpRequestData {}

export const getAllAppointments = httpUtil.createHttpRequestMeta<
	AppointmentGetAllRequest,
	PaginationResponse<AppointmentGetDetailResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments',
    authentication: 'bearer'
});
