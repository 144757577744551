/* eslint-disable react/prop-types */
import './TableCustom.scss';
import { PageInfo } from '@esg/framework';
import {
    Pagination,
    Table,
    TableColumnsType,
    TableProps
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from '../layouts';
import { usePagination } from '../../hooks';
import { HeaderTable } from './HeaderTable';
import EmptyTable, { EmptyTableProps } from './EmptyTable';

interface TableCustomProps<TItem> extends TableProps<TItem> {
    readonly placeholderSearchTransCode?: string;
    readonly titleTableTransCode?: string;
	readonly subTitleTable?: React.ReactNode;
    readonly onPageChange?: (nextPage: number, pageSize?: number) => void;
    readonly pageInfo?: PageInfo;
    readonly showHeader?: boolean;
    readonly showFooter?: boolean;
    readonly onSearch?: (value: string) => void;
    readonly onFilter?: () => void;
    readonly dataSource?: TItem[];
    readonly columns?: TableColumnsType<TItem>;
    readonly disableFilter?: boolean;
    readonly onExportExcel?: () => void;
    readonly emptyOption?: EmptyTableProps;
}

const defaultPageInfo: PageInfo = {
    page: 1,
    pageSize: 20,
    totalItems: 0,
    pageCount: 0,
};

interface TableCustomItem {
    readonly id?: string;
}

type TableCustomTypeType = <TItem extends TableCustomItem>(
    props: TableCustomProps<TItem>
) => React.ReactElement<TableCustomProps<TItem>>;

export const TableCustom: TableCustomTypeType = (props) => {

    const { t } = useTranslation();
    const [searchParams] = usePagination();

    const renderHeader = React.useCallback(() => {
        return (
            <HeaderTable
                playHolderInputSearch={t(props?.placeholderSearchTransCode || '')}
                titleTable={t(props?.titleTableTransCode || '')}
                subTitleTable={props.subTitleTable}
                onSearch={props.onSearch}
                onFilter={props.onFilter}
                onExportExcel = {props.onExportExcel}
            />
        );
    }, [props, t]);

    const renderFooter = React.useCallback(() => {
        return (
            <Pagination
                className='custom-pagination'
                current={props?.pageInfo?.page}
                total={props?.pageInfo?.totalItems}
                pageSize={props.pageInfo?.pageSize}
                onChange={(nextPage, pageSize) => {
                    if (props.onPageChange) {
                        props.onPageChange(nextPage, pageSize ?? 20);
                    }
                }}
            />
        );
    }, [props]);

    return (
        <Block borderRadius={16} backgroundColor='white' overflow='hidden' border='1px solid #eaecf0'>
            <Table
                scroll={props.scroll}
                loading={props.loading}
                rowKey={(e) => e.id ?? Math.random()}
                className='custom-table'
                title={props.showHeader ? renderHeader : undefined}
                columns={props.columns}
                locale={{ emptyText: (
                    <EmptyTable
                        isSearch={searchParams.search !== ''}
                        title={props.emptyOption?.title}
                        description={props.emptyOption?.description}
                        actionButton={props.emptyOption?.actionButton}
                    />
                )}}
                dataSource={props.dataSource}
                pagination={false}
                footer={props.showFooter ? renderFooter : undefined}
            >
                {props.children}
            </Table>
        </Block>
    );
};

(TableCustom as React.ComponentType<TableCustomProps<TableCustomItem>>).defaultProps = {
    pageInfo: defaultPageInfo,
    showHeader: true,
    showFooter: true,
    placeholderSearchTransCode: 'MISSING_PLACEHOLDER_SEARCH',
    titleTableTransCode: 'MISSING_TITLE_TABLE',
    scroll: { x: 'max-content' },
};
