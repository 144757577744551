import { TimeOffGetDetailResponse } from '@esg/business-resource-management';
import { dayjs } from '@esg/shared';
import { Text } from '@esg/ui';
import React from 'react';
import { timeOffType } from '@/constants';

interface ShiftTimeOffProps {
    readonly timeOff: TimeOffGetDetailResponse
}

export const ShiftTimeOff = ({timeOff}: ShiftTimeOffProps) => {
    const { startTime, endTime, type } = timeOff;
    
    return ( 
        <>
            <Text fontSize={11} fontWeight='bold'>
                {timeOffType[type]}
            </Text>
            <Text fontSize={11} lineHeight='18px'>
                {dayjs(startTime).format('h:mm A')} - {' '}
                {dayjs(endTime).format('h:mm A')}
            </Text>
        </>
    );
};
