import { useFormContext } from 'react-hook-form';
import {useEffect} from 'react';
import { getEmployeeTimeWork } from '../appointmentUtils';
import { useAppointmentContext } from '../AppointmentFormContext';
import { AppointmentFormValues } from '../../AppointmentFormControl';

interface Params {
    fieldName: string;
    guestIndex: number;
    serviceIndex: number;
    date: string;
}

export const useCurrentServiceWorkTime = ({fieldName, guestIndex, serviceIndex, date}: Params) => {
    const { getValues, setValue } = useFormContext();
    const { services } = useAppointmentContext();

    useEffect(() => {
        return () => {
            const values = getValues();
            const currentDate = getValues('appointmentDate');

            if (currentDate !== date) {
                const { startTime, endTime } = getEmployeeTimeWork({
                    services,
                    guestIndex,
                    guestServiceIndex: serviceIndex,
                    appointment: values as AppointmentFormValues,
                });
                setValue(`${fieldName}.startTime`, startTime);
                setValue(`${fieldName}.endTime`, endTime);
            }
        };
    }, [date, fieldName, getValues, guestIndex, serviceIndex, services, setValue]);
};