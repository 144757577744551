import { TableCustom, globalDrawerState, useHttpQuery, Text, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { GetProductSummaryItem, getProductsSummaryRevenueReport } from '@esg/business-report/api/revenue-report';
import { useTranslation } from 'react-i18next';
import { RevenueProductDetailTable } from './RevenueProductDetailTable';
import { translateCodes } from '@/locales';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface RevenueProductSummaryTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function RevenueProductSummaryTable( props: RevenueProductSummaryTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency } = useLocalesFormat();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeMain: '10',
        orderByMain: 'name',
    },);
    
    const {data: productSummary, isFetching} = useHttpQuery(getProductsSummaryRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            search: searchParams.searchByMain,
            orderBy: searchParams.orderByMain,
            orderDirection: searchParams.mainDirection,
            page: searchParams.pageMain,
            pageSize: searchParams.pageSizeMain,
            ...searchParams
        },
    });

    const viewDetail = useCallback((productId: string) => {
        setDrawerGlobalState({
            titleTransCode: null,
            isOpen: true,
            hideHeader: true,
            content: (
                <RevenueProductDetailTable
                    dateRange={dateRange}
                    productId={productId}
                />
            ),
            footer: null,
        });
    },[dateRange, setDrawerGlobalState]);

    const columns: TableColumnsType<GetProductSummaryItem> = useMemo(()=>( [
        {
            title: t(translateCodes.PRODUCTS),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.mainDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, mainDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t(translateCodes.DISCOUNT),
            dataIndex: 'discount',
            key: 'discount',
            render: (_, record) => {
                return formatCurrency(record.discount);
            },
        },
        {
            title: t(translateCodes.REVENUE),
            dataIndex: 'revenue',
            key: 'revenue',
            render: (_, value) => {
                return (
                    <Text>
                        {formatCurrency(value.revenue)}
                    </Text>
                );
            },
        },
        {
            title: t(translateCodes.LINKS),
            key: 'links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={()=>viewDetail(record.id)}>View Detail</Tag>
                );
            }
        },
    ]),[formatCurrency, searchParams, setSearchParams, t, viewDetail]);

    return (
        <TableCustom
            titleTableTransCode= {translateCodes.PRODUCTS_SUMMARY}
            loading={isFetching}
            columns={columns}
            dataSource={productSummary?.items}
            pageInfo={productSummary?.meta}
            placeholderSearchTransCode={`${t(translateCodes.SEARCH)} ${t(translateCodes.PRODUCT)}`}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByMain: value })
            }
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, pageMain: page.toString() })
            }
        />
    );
}
