import { customerApi, GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import {
    Block,
    DatePickerField,
    FlexBox,
    FormBase,
    InputField,
    SelectField,
    Spacer,
    Text,
    useHttpCommand,
    FormLayout,
    RefMethodFormType,
    CheckboxField,
    Translate,
} from '@esg/ui';
import {
    Card,
} from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarUpload } from '@esg/ui/components/avatar';
import { HttpRequestError, viewRequests } from '@esg/framework';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { AdditionalInfo } from './children';
import { translateCodes } from '@/locales';
import { genderOptions } from '@/constants';
import { customerRules } from '@/pages/crm/customer/@share/customerRules';
interface CustomerFormProps {
    readonly onSuccess?: (response?: GetCustomerDetailResponse) => void;
    readonly defaultValues?: Partial<GetAllCustomerResponseItem>;
    readonly onDeleted?: () => void;
    readonly isShowMoreDetail?: boolean;
}

export const CustomerForm = (props: CustomerFormProps) => {
    const { t } = useTranslation();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const formRef: RefMethodFormType = useRef(null);
    const [IsShowMoreDetail, setIsShowMoreDetail] = useState<boolean>(props.isShowMoreDetail??true);

    const { mutateAsync: createCustomer, isPending: isLoadingCreate } = useHttpCommand(customerApi.createCustomer, {
        showNotificationError: false,

        onError(error) {
            const errorResponse = error as HttpRequestError;
            if (errorResponse.message === 'PHONE_OR_EMAIL_IS_REQUIRED') {
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_OR_EMAIL_REQUIRED),
                });
                formRef.current?.setError('phone', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_OR_EMAIL_REQUIRED),
                });

            }
            if (errorResponse.message === 'CUSTOMER_EMAIL_EXISTING') {
                formRef.current?.setError('email', {
                    type: 'validate',
                    message: t(translateCodes.EMAIL_ALREADY_EXIST),
                });
            }
            if (errorResponse.message === 'CUSTOMER_PHONE_EXISTING') {
                formRef.current?.setError('phone', {
                    type: 'validate',
                    message: t(translateCodes.PHONE_ALREADY_EXIST),
                });
            }
        },
        onSuccess: (response) => {
            props.onSuccess?.(response);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_CM_01),
                duration: 1,
            });
        }
    });

    const { mutateAsync: updateCustomer, isPending: isLoadingUpdate } = useHttpCommand(customerApi.updateCustomer, {
        onError() {
            formRef.current?.setError('email', {
                type: 'validate',
                message: t(translateCodes.EMAIL_ALREADY_EXIST),
            });
        },
        onSuccess: () => {
            props.onSuccess?.();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_CM_05),
                duration: 1,
            });
        }
    });

    const onSubmit = React.useCallback(async (values: GetAllCustomerResponseItem) => {
        const isUpdate = !!props.defaultValues?.id;

        if (isUpdate) {
            return await updateCustomer({
                pathData: { id: props.defaultValues?.id },
                body: values,
            });
        }

        await createCustomer({ body: values });

    }, [createCustomer, props, updateCustomer]);

    return (
        <FormBase formRef={formRef} onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                onDeleted={props.onDeleted}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    props.defaultValues?.id
                        ? translateCodes.SAVE
                        : translateCodes.CREATE
                }
            >
                <Block>
                    <Text fontSize={16} fontWeight={700}
                        lineHeight='24px'
                        translateCode={translateCodes.GENERAL_INFORMATION} />
                    <Spacer type='margin' bottom={8} />
                    <Card bordered={false}>
                        <FlexBox direction='column' gap={16}>
                            <AvatarUpload
                                translateCode={translateCodes.UPLOAD_PHOTO}
                                name='avatar'
                            />
                            <FlexBox gap={16}>
                                <InputField
                                    rules={customerRules.firstName(t)}
                                    name='firstName'
                                    label={t(translateCodes.FIRST_NAME)}
                                    placeholder={t(translateCodes.FIRST_NAME_PLACEHOLDER)}
                                />
                                <InputField
                                    name='lastName'
                                    rules={customerRules.lastName(t)}
                                    label={t(translateCodes.LAST_NAME)}
                                    placeholder={t(translateCodes.LAST_NAME_PLACEHOLDER)}
                                />
                            </FlexBox>
                            {
                                IsShowMoreDetail &&
                                <FlexBox gap={16}>
                                    <SelectField
                                        allowClear
                                        options={genderOptions}
                                        name='gender'
                                        label={t(translateCodes.GENDER)}
                                        placeholder={t(translateCodes.GENDER_PLACEHOLDER)}
                                    />
                                    <DatePickerField
                                        label={translateCodes.DATE_OF_BIRTH}
                                        name='dateOfBirth'
                                        placeholder={translateCodes.DATE_OF_BIRTH_PLACEHOLDER}
                                        format={currentLocale?.format}
                                    />
                                </FlexBox>
                            }
                            <FlexBox gap={16}>
                                <InputField
                                    name='phone'
                                    label={t(translateCodes.CONTACT_INFORMATION)}
                                    placeholder={t(translateCodes.PHONE_PLACEHOLDER)}
                                    rules={customerRules.phone}
                                    requiredCustom
                                />
                                <InputField
                                    name='email'
                                    placeholder={t(translateCodes.EMAIL_PLACEHOLDER)}
                                    rules={customerRules.email(t)}
                                />
                            </FlexBox>
                            {
                                IsShowMoreDetail && <InputField
                                    name='address'
                                    label={t(translateCodes.ADDRESS)}
                                    placeholder={t(translateCodes.ADDRESS_PLACEHOLDER)}
                                    rules={customerRules.address(t)}
                                />
                            }

                        </FlexBox>
                    </Card>

                    {
                        IsShowMoreDetail && <>
                            <Spacer type='margin' bottom={12} top={24}>
                                <Text fontSize={16} fontWeight={700} lineHeight='24px'
                                    translateCode={translateCodes.ADDITIONAL_INFO} />
                            </Spacer>
                            <AdditionalInfo customer={props.defaultValues} /></>
                    }
                    <Spacer top={24} />
                    <CheckboxField
                        name='rememberMe'
                        onChange={(e) => {
                            setIsShowMoreDetail(e);
                        }}
                    >
                        <Translate
                            translateCode={
                                translateCodes.SHOW_MORE_DETAIL
                            }
                        />
                    </CheckboxField>

                </Block>
            </FormLayout>
        </FormBase>
    );
};
