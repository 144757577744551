import { globalModalState, Paragraph, Spacer, Text, Translate, useHttpCommand } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { AppointmentGetDetailResponse, deleteAppointment } from '@esg/business-schedule';
import { dayjs } from '@esg/shared';
import { viewRequests } from '@esg/framework';
import { GetAllServiceResponseItem } from '@esg/business-management';
import { translateCodes } from '@/locales';
import { appointmentEvents } from '@/event';

interface Params {
	readonly appointment: AppointmentGetDetailResponse;
	readonly service: GetAllServiceResponseItem;
}

export const useAppointmentDelete = () => {
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [, showModal] = useRecoilState(globalModalState);
    const hideModal = useResetRecoilState(globalModalState);

    const { mutateAsync: deleteAppointmentAsync } = useHttpCommand(deleteAppointment, {
        onSuccess() {
            hideModal();
            appointmentEvents.refetchAppointments.emit();
            viewRequests.showNotification.send({
                type: 'success',
                message: <Translate translateCode={translateCodes.NM_SC_03} />,
            });
        },
    });

    const handleDelete = useCallback(
        ({ appointment, service }: Params) => {
            const { customer, appointmentDate, id } = appointment;

            showModal({
                content: (
                    <Spacer top='xs'>
                        <Text
                            fontSize={18}
                            fontWeight={700}
                            translateCode={translateCodes.DELETE_APPOINTMENT}
                        />
                        <Spacer type='margin' top={8} />
                        <Paragraph>
                            <Text
                                translateCode={translateCodes.DELETE_APPOINTMENT_CONFIRM_MESSAGE}
                            />
							:&nbsp;
                            <Text fontWeight={700}>
                                {service.name} for {customer.fullName} at&nbsp;
                                {dayjs(appointmentDate).format(
                                    'h:mm a ddd, ' + currentLocale?.format
                                )}&nbsp;
								?
                            </Text>
                        </Paragraph>
                    </Spacer>
                ),
                isOpen: true,
                onOk: () => {
                    deleteAppointmentAsync({
                        pathData: { id },
                    });
                },
                showModalDelete: true,
            });
        },
        [currentLocale?.format, deleteAppointmentAsync, showModal]
    );

    return handleDelete;
};
