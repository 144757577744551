import { EmployeeGetDetailResponse, resourceApis } from '@esg/business-resource-management';
import { AppAvatar, FlexBox, TableCustom, useHttpQuery,Text } from '@esg/ui';
import { Card, Switch, TableColumnsType } from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmployeeServiceValue } from '../ServiceForm';
import { translateCodes } from '@/locales';

export const EmployeeAllocate = () => {
    const {watch, setValue} = useFormContext();
    const { data: employees, isLoading } = useHttpQuery(resourceApis.getAllEmployees);

    const employeeServices: EmployeeServiceValue[] = watch('employeeServices');
    const {t} = useTranslation();

    const columns: TableColumnsType<EmployeeGetDetailResponse> = [
        {
            width: '90%',
            title: t(translateCodes.EMPLOYEE),
            dataIndex: 'employee',
            render: (_, record) => {
                return <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                    <AppAvatar
                        size='sm'
                        data={record}
                    />
                    <Text maxWidth={350} fontWeight='bold'>{record.fullName}</Text>
                </FlexBox>;
            },
        },
        {
            title: t(translateCodes.ALLOCATE),
            dataIndex: 'employee',
            render: (_, record) => {
                return (
                    <Switch
                        checked={employeeServices?.some(o=> o.employeeId === record.id)}
                        onChange={(value) => {
                            let newEmployeeServices = [];
                            if (value) {
                                newEmployeeServices = [...employeeServices, {employeeId: record.id}];
                            } else {
                                newEmployeeServices = employeeServices.filter(o => o.employeeId !== record.id);
                            }
                            setValue('employeeServices', newEmployeeServices, {
                                shouldDirty: true,
                            });
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Card bordered={false}>
            <TableCustom
                columns={columns}
                loading={isLoading}
                dataSource={employees?.items}
                showHeader={false}
                showFooter={false}
            />
        </Card>
    );
};
