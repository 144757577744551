import React from 'react';
import { Column } from '@ant-design/plots';
import { Line } from '@ant-design/charts';
import { Block, Spacer, Text, useHttpQuery } from '@esg/ui';
import { getOverviewChartAppointment } from '@esg/business-report';
import { Dayjs , dayjs } from '@esg/shared';
import { Card } from 'antd';
import { ViewType } from '@esg/shared/enum';
import { chartHelper } from '@/helpers';

interface AnalyserAppointmentChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface ColumnData {
    readonly xField: string;
    readonly yField: number;
    readonly name?: string;
    readonly color?: string;
    readonly value?: number;
    readonly category?: string;
}

export default function AnalyserAppointmentChart(props: AnalyserAppointmentChartProps) {
    const { dateRange } = props;
    const numberOfDays = dateRange[1].diff(dateRange[0], 'd');
    const viewType = chartHelper.getViewTypeChart(numberOfDays);

    const {data: overviewChartAppointment, isLoading} = useHttpQuery(getOverviewChartAppointment, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            viewType: viewType,
        }
    });

    const dataChart = overviewChartAppointment?.overviewChartInfos?.map((item) => {
        let columns:ColumnData[] = [];
        if (viewType === ViewType.Hour) {
            columns = [
                { 
                    xField: dayjs(item.date).format('HH:mm'), 
                    yField: item.totalAppointment,
                    category: 'Total appointment',
                    value: item.totalAppointment,
                },
                { 
                    xField: dayjs(item.date).format('HH:mm'), 
                    yField: item.totalAppointmentCancel ,
                    category: 'Cancel appointment',
                    value: item.totalAppointmentCancel,
                },
            ];
            return columns;
        }

        columns = [
            { 
                xField: chartHelper.getDayOrdinalFromDate(item.date), 
                yField: item.totalAppointment,
                name: 'Total appointment' ,
                color: '#2970FF',
            },
            { 
                xField: chartHelper.getDayOrdinalFromDate(item.date), 
                yField: item.totalAppointmentCancel,
                name: 'Cancel appointment' ,
                color: '#F04438',
            },
        ];  
        return columns;
    }).flat() ?? [];

    const configChartColumn = {
        data: dataChart,
        xField: 'xField',
        yField: 'yField',
        colorField: 'name',
        group: true,
        sizeField: 35,
        scrollbar: {  x: {
            ratio: numberOfDays> 7 ? 0.5 : 1 ,
            style : { trackSize: 15 }
        }},
        style: {
            radiusTopLeft:10,
            radiusTopRight:10,
            insetLeft:10,
            insetRight:10,
            fill: (type : ColumnData) => {
                return type.color;
            },
        },
        scale: {
            x: { 
                type: 'band',
            },
            color : {
                range: ['#2970FF', '#F04438'],
            },
        },
        legend: {
            color: {
                layout: {
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end'
                },
                itemWidth: '100%',
            },
        },
    };

    const configChartLine = {
        data: dataChart,
        xField: 'xField',
        yField: 'yField',
        sizeField: 'value',
        shapeField: 'trail',
        legend: {
            size: false,
            color: {
                layout: {
                    justifyContent: 'flex-end',
                },
                position: 'top',
                itemWidth: 100,
                itemName: {
                    style: {
                        fontSize: 12,
                    },
                },
                title: {
                    text: 'Legend Title', 
                    style: {
                        fontSize: 14,
                        fontWeight: 'bold',
                    },
                },
            },
        },
        colorField: 'category',
    };

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={30}>
                <Card>
                    <Text fontSize={18} fontWeight={600}>Total Appointment</Text>
                    {!isLoading && (
                        <>
                            {viewType === ViewType.Hour && (
                                <Line {...configChartLine} loading={isLoading}/>
                            )}
                            {viewType !== ViewType.Hour && (
                                <Column {...configChartColumn} loading={isLoading}/>
                            )}
                        </>
                    )}
                </Card>
            </Spacer>
        </Block>
    );
}
