import { GetAllServiceResponseItem } from '@esg/business-management';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { AppointmentGetDetailResponse, AppointmentService , AppointmentServiceDetailCreate } from '@esg/business-schedule';
import { dayjs } from '@esg/shared';
import { AppointmentFormValues } from '..';

interface GetEmployeeTimeWorkParams {
	guestIndex: number;
	guestServiceIndex: number;
	appointment: AppointmentFormValues;
	services: GetAllServiceResponseItem[];
}

export const getServicesSummary = (
    value: AppointmentService['appointmentServiceDetails'],
    services: GetAllServiceResponseItem[]
) => {
    let minutes = 0;
    let price = 0;
    for (const item of value) {
        const service = services.find((s) => s.id === item.serviceId);
        minutes += service?.duration || 0;
        price += service?.serviceBranches[0].price || 0;
    }
    return { minutes, price };
};

export const getAppointmentSummary = (
    value: AppointmentService[],
    services: GetAllServiceResponseItem[]
) => {
    let minutes = 0;
    let price = 0;

    for (const item of value) {
        const { minutes: m, price: p } = getServicesSummary(
            item.appointmentServiceDetails,
            services
        );
        minutes += m || 0;
        price += p || 0;
    }
    return { minutes, price };
};

export const getEmployeesAppointment = (appointment: AppointmentGetDetailResponse) => {
    let employees: EmployeeGetDetailResponse[] = [];
    appointment.appointmentServices.forEach((e) => {
        e.appointmentServiceDetails.forEach((t) => {
            t.appointmentServiceEmployees?.forEach((s) => {
                if (s.employee != null && !employees.map((o) => o.id).includes(s.employee.id)) {
                    employees = [...employees, s.employee];
                }
            });
        });
    });

    return employees;
};

export const getEmployeeTimeWork = ({
    services,
    guestIndex,
    appointment,
    guestServiceIndex,
}: GetEmployeeTimeWorkParams) => {
    const { appointmentDate, appointmentServices } = appointment;
    let startMinute = 0;
    let startTime = dayjs(appointmentDate).set('seconds', 0).set('milliseconds', 0).toISOString();
    const appointmentServiceDetails = appointmentServices[guestIndex]?.appointmentServiceDetails;
    for (let index = 0; index < guestServiceIndex; index++) {
        const serviceDuration =
			services.find((s) => s.id === appointmentServiceDetails[index].serviceId)?.duration ||
			0;
        startMinute += serviceDuration;
        startTime = dayjs(startTime).add(serviceDuration, 'minutes').toISOString();
    }
    const currentServiceDuration = services.find((s) => s.id === appointmentServiceDetails?.[guestServiceIndex]?.serviceId)
        ?.duration || 0;
    const endMinute = startTime + currentServiceDuration;
    const endTime = dayjs(startTime).add(currentServiceDuration, 'minutes').toISOString();
    return { startMinute, endMinute, startTime, endTime };
};

export const checkValidAptServiceDetail = (
    appointmentServiceDetails: AppointmentFormValues['appointmentServices']
) => {

    const result: AppointmentServiceDetailCreate[] = [];
    appointmentServiceDetails.forEach((a) => {
        a.appointmentServiceDetails.forEach((ap) => {
            result.push(ap);
        });
    });

    return result.every((a) => a.isValid == true);
};