import { HttpRequestData, httpUtil } from '@esg/framework';
import {Dayjs} from 'dayjs';
import { env } from '../../configs';

interface GetDetailBranchRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}
export interface IWorkingTimeItem {
    readonly isDayOff: boolean;
    readonly startTime: string | Dayjs;
    readonly endTime: string | Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly breakTimes?: IBreakTimes[]
}

export interface IBreakTimes {
    readonly startTime: Dayjs;
    readonly endTime: Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly isFixed?: boolean;
}

export type IWorkingTime = IWorkingTimeItem[];
export interface GetDetailBranchResponseItem{
    readonly id: string;
    readonly name: string;
    readonly logo?: string;
    readonly countryCode?: string;
    readonly phoneNumber?: string;
    readonly currency?: string;
    readonly tax?: string;
    readonly dateTimeFormat?: string;
    readonly timezone?: string;
    readonly languages?: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly postalCode: string;
    readonly country: string;
    readonly businessHour: IWorkingTime;
}

export const getDetailBranch = httpUtil.createHttpRequestMeta<
	GetDetailBranchRequest,
	GetDetailBranchResponseItem
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/branches/:id',
    authentication: 'bearer',
});