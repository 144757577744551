import { useEffect } from 'react';
import { RealtimeEvents } from '@esg/shared';
import { useRealtimeContext } from '../../RealtimeContext';

export const useDataFetched = () => {
    const { realtimeConn: conn } = useRealtimeContext();
    useEffect(() => {
        if (conn) {
            conn.on(RealtimeEvents.DataFetched, () => {
                // Your business
            });
        }
    }, [conn]);
};
