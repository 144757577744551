/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    HttpRequestData,
    HttpRequestMeta,
} from '../types';

export const httpUtil = {
    createHttpRequestMeta: <TRequest extends HttpRequestData, TResponse>(options: HttpRequestMeta<TRequest, TResponse>): HttpRequestMeta<TRequest, TResponse> => {
        return options;
    },
    parsePath: (path: string, pathData?: Record<string, any>) => {
        if (!pathData) {
            return path;
        }

        let parsedPath = path;
        Object.keys(pathData).forEach(key => {
            parsedPath = parsedPath.replace(`:${key}`, pathData[key]);
        });

        return parsedPath;
    },
    parseQuery: (queryObject?: Record<string, any>) => {
        if (!queryObject) {
            return '';
        }

        const queryArray = Object.keys(queryObject).filter(key => {
            if (queryObject[key] === undefined || queryObject[key] === null) {
                return false;
            }

            if(Array.isArray(queryObject[key]) && queryObject[key].length === 0) {
                return false;
            }

            return true;
        }).map(key => {
            if (Array.isArray(queryObject[key])) {
                return queryObject[key].map((value: any) => `${key}=${value}`).join('&');
            }

            return `${key}=${queryObject[key]}`;
        });
        
        const query = queryArray.join('&');

        return `?${query}`;
    },
    jsonToUrlEncoded: (json: Record<string, any>) => {
        const formBody = Object.keys(json)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
            .join('&');

        return formBody;
    },
    jsonToFormData: (json: Record<string, any>) => {
        const formData = new FormData();
        Object.keys(json).forEach(key => {
            formData.append(key, json[key]);
        });

        return formData;
    }
};