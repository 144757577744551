import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { GetDetailFacilitiesResponse } from '@esg/business-resource-management';
import { Input, Space } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import type { GetProp, MenuProps } from 'antd';
import { useMemo, useState } from 'react';
import { Spacer } from '@esg/ui';
import { AddFacilityBtn } from './AddFacilityBtn';
import { translateCodes } from '@/locales';

interface FacilitiesListProps {
  handleActiveFacility: (facility: GetDetailFacilitiesResponse) => void;
  facilityData?: GetDetailFacilitiesResponse[];
  refetch: () => void;
}

export function FacilitiesMenu(props: FacilitiesListProps) {
    const { t } = useTranslation();
    const params = new URLSearchParams(location.search);
    const [listMenu, setListMenu] = useState<MenuItem[]>([]);    
    type MenuItem = GetProp<MenuProps, 'items'>[number];

    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode
    ): MenuItem {
        return {
            key,
            label,
            icon
        } as MenuItem;
    }
    
    const onClick: MenuProps['onClick'] = (e) => {
        const filteredFacility = props.facilityData?.filter((f) => f.id === e.key);
        if (filteredFacility && filteredFacility.length > 0) {
            props.handleActiveFacility(filteredFacility[0]); 
        }
    };         
    useMemo(()=>{
        const items: MenuItem[] = [
            ...(props.facilityData||[]).map((e) => getItem(e.name, e.id, <MenuOutlined />)),
        ];
        setListMenu(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.facilityData]);   

    const handleSearch = (value: string, items: MenuItem[] ) => {
        items = [
            ...(props.facilityData||[]).filter(t => t.name?.includes(value)).map((e) => getItem(e.name, e.id, <MenuOutlined />)),
        ];
        setListMenu(items as MenuItem[]);
    };           

    return (
        <>
            <Spacer size='sm'>
                <Space size='middle' direction='vertical'>
                    <AddFacilityBtn refetch={props.refetch}/>
                    <Input
                        size='large'
                        placeholder={t(translateCodes.SEARCH_FACILITY)}
                        prefix={<SearchOutlined />}
                        onChange={(value) => handleSearch(value.target.value, listMenu)}
                    />
                </Space>
            </Spacer>
            <Menu
                style={{
                    padding: 0,
                    border: 'none'
                }}
                items={listMenu}
                onClick={onClick}
                defaultSelectedKeys={[String(params.get('id'))]}
                mode='vertical'
            />
        </>
    );
}