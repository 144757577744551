import { useFormContext } from 'react-hook-form';
import { AppointmentServiceCreate } from '@esg/business-schedule';
import {useMemo} from 'react';

export interface ServiceDetailsInfo {
	endTime: string;
	startTime: string;
	employeeIds: string[];
	guestIndex: number;
	facilityId?: string;
	serviceIndex: number;
}

export const useServiceDetailsInfo = () => {
    const { watch } = useFormContext();
    const appointmentServices = watch('appointmentServices') as AppointmentServiceCreate[];

    const serviceDetailsInfo = useMemo(()=>{
        const serviceDetailsInfo: ServiceDetailsInfo[] = [];

        appointmentServices.forEach((as, guestIndex) => {
            as.appointmentServiceDetails.forEach((sd, serviceIndex) => {
                serviceDetailsInfo.push({
                    guestIndex,
                    serviceIndex,
                    endTime: sd.endTime!,
                    startTime: sd.startTime!,
                    facilityId: sd.facilityId,
                    employeeIds: sd.employeeIds || [],
                });
            });
        });

        return serviceDetailsInfo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(appointmentServices)]);

    return serviceDetailsInfo;
};
