import './HorizontalScheduler.scss';
import React from 'react';
import { Dayjs } from '@esg/shared';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SchedulerItem, SchedulerProps, SchedulerRowData, createSubRows } from '../@shared';
import { HorizontalSchedulerLayout, HorizontalSchedulerRow, HorizontalSchedulerTimeMarker } from './horizontal-scheduler';

export const HorizontalScheduler = ({renderTimeMaker = false,...props}: SchedulerProps) => {
    const onDragStart = React.useCallback(
        (_item: SchedulerItem) => {
        },
        []
    );

    const onDragEnd = React.useCallback(
        (_item: SchedulerItem) => {
        },
        []
    );

    const { items, endTime, startTime } = React.useMemo(() => {
        const items = props.items.sort((a, b) => a.startTime.diff(b.startTime));

        const start = items[0]?.startTime;
        const end = items.slice(-1)[0]?.endTime;

        const startTime = start < props.startTime ? start : props.startTime;
        const endTime = end > props.endTime ? end : props.endTime;

        return { items, startTime, endTime };
    }, [props.endTime, props.items, props.startTime]);

    const timeCells = React.useMemo(
        () => {
            const timeCells: Dayjs[] = [];
            let time = startTime.clone();
            while (time.isBefore(endTime)) {
                timeCells.push(time);
                time = time.add(props.minutesPerCell, 'minute');
            }
            return timeCells;
        }, [endTime, props.minutesPerCell, startTime]);

    const rowData = React.useMemo<SchedulerRowData[]>(() => {
        const rowData: SchedulerRowData[] = [];

        for (const rowInfo of props.rowInfos) {
            const timesDisabled =
				props.timesDisabled?.filter((i) => i.rowKey === rowInfo.key) ?? [];

            const rowItems = items.filter((i) => i.rowKey === rowInfo.key);
            const subRows = createSubRows(rowItems);

            if (!subRows.length) {
                subRows.push([]);
            }

            rowData.push({ rowInfo, subRows, items: rowItems, timesDisabled });
        }

        return rowData;
    }, [items, props.rowInfos, props.timesDisabled]);

    const children = React.useMemo(
        () => (
            <HorizontalSchedulerLayout
                rowData={rowData}
                timeCells={timeCells}
                headerWidth={props.headerWidth}
                baseColumnWidth={props.baseColumnWidth}
                baseRowHeight={props.baseRowHeight}
                renderRowInfo={props.renderRowInfo}
                renderTimeLabel={props.renderTimeLabel}
                isDisabled={props.isDisabled}
            >
                {rowData.map((data) => {
                    return (
                        <HorizontalSchedulerRow
                            key={data.rowInfo.key}
                            baseColumnWidth={props.baseColumnWidth}
                            baseRowHeight={props.baseRowHeight}
                            minutesPerCell={props.minutesPerCell}
                            startTime={startTime}
                            rowData={data}
                            timeCells={timeCells}
                            droppableBgColor={props.droppableBgColor}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                            onDrop={props.onDrop}
                            renderItem={props.renderItem}
                            onCreate={props.onCreate}
                        />
                    );
                })}
                {
                    renderTimeMaker && (
                        <HorizontalSchedulerTimeMarker
                            startTime={startTime}
                            endTime={endTime}
                            timeCells={timeCells}
                            baseColumnWidth={props.baseColumnWidth}
                            renderTimeMaker={renderTimeMaker}
                        />
                    )
                }
            </HorizontalSchedulerLayout>
        ),
        [
            onDragEnd,
            onDragStart,
            props.baseColumnWidth,
            props.baseRowHeight,
            props.droppableBgColor,
            props.headerWidth,
            props.minutesPerCell,
            props.onDrop,
            props.renderItem,
            props.renderRowInfo,
            props.renderTimeLabel,
            renderTimeMaker,
            startTime,
            endTime,
            rowData,
            timeCells,
            props.onCreate,
            props.isDisabled
        ]
    );

    const container = React.useMemo(
        () => {
            const containerClassName = 'horizontal-scheduler';
            if (props.renderContainer) {
                return props.renderContainer({
                    className: containerClassName,
                    children: children
                });
            }

            return (
                <div className={containerClassName}>
                    {children}
                </div>
            );
        },
        [children, props]
    );

    return (
        <DndProvider backend={HTML5Backend}>
            {container}
        </DndProvider>
    );
};