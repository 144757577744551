import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetOverviewClientRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetOverviewClientResponse {
    totalClient: number;
    newClient: number;
    retentionClient: number;
    walkInClient: number;
    totalRevenue: number;
}

export const getOverviewClientReport = httpUtil.createHttpRequestMeta<
    GetOverviewClientRequest,
	GetOverviewClientResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-report/overview',
    authentication: 'bearer',
});