import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateFacilityRequest extends HttpRequestData {
	readonly body: {
		id?: string;
		name: string;
		description?: string;
		serviceIds?: string[]
	};
}

export interface UpdateFacilityResponse {
	readonly id: string;
	name: string;
	description: string;
}

export const updateFacility = httpUtil.createHttpRequestMeta<
	UpdateFacilityRequest,
	UpdateFacilityResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/facilities/:id',
    method: 'PATCH',
    authentication: 'bearer',
});