export enum RealtimeEvents {
	Valued = 'Valued',
	DataFetched = 'DataFetched',
	DataModified = 'DataModified',
	DataAdded = 'DataAdded',
	DataRemoved = 'DataRemoved',
}

export enum RealtimeMethods {
	Subscribe = 'Subscribe',
	Update = 'Update',
	Remove = 'Remove',
}

export enum RealtimeKeys {
	AppointmentBooked = 'APPOINTMENT_BOOKED',
	AppointmentNoShow = 'APPOINTMENT_NO_SHOW',
	AppointmentRefetch = 'APPOINTMENT_REFETCH',
}
