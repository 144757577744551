import { Route, Routes } from 'react-router-dom';
import { LoginCallback } from './login';

export const Auth = () => {
    return (
        <Routes>
            <Route
                path='/login-callback'
                Component={LoginCallback}
            />
        </Routes>
    );
};