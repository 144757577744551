import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface CreatePaymentMethodRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        isActive: boolean;
    };
}

export interface CreatePaymentMethodResponse {
    id: string;
    name: string;
    isActive: boolean;
}

export const createPaymentMethod = httpUtil.createHttpRequestMeta<
    CreatePaymentMethodRequest,
    CreatePaymentMethodResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/payment-method-settings',
    method: 'POST',
    authentication: 'bearer',
});
