import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { BreakTimeGetDetailResponse } from '../break-time';

export interface UpdateShiftsRequest extends HttpRequestData {
	readonly body: {
		readonly id: string;
		readonly date?: string;
		readonly endTime: string;
		readonly employeeId: string;
		readonly startTime: string;
		readonly isDayOff: boolean;
		readonly breakTimes: BreakTimeGetDetailResponse[];
	};
}

export const updateShifts = httpUtil.createHttpRequestMeta<
    UpdateShiftsRequest,
    void
>({
    method: 'PATCH',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id',
});
