import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetOverviewAppointmentRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetOverviewAppointmentResponse {
    totalAppointment: number;
    totalAppointmentCancel: number;
    totalAppointmentOnline: number;
    totalAppointmentSalon: number;
    totalAppointmentValue: number;
}

export const getOverviewAppointment = httpUtil.createHttpRequestMeta<
    GetOverviewAppointmentRequest,
	GetOverviewAppointmentResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments-report/overview',
    authentication: 'bearer',
});