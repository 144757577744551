import { GetDetailFacilitiesResponse } from '@esg/business-resource-management';
import { InputSearch, SelectField, Spacer, Translate } from '@esg/ui';
import { Divider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { checkFacilityValid } from '../@share/checkFacilityValid';
import { useServiceDetailsInfo } from '../../../@share/hooks/useServiceDetailsInfo';
import { useAppointmentContext } from '../../../@share/AppointmentFormContext';
import { translateCodes } from '@/locales';

interface FacilitySelectFieldProps {
  fieldName: string;
  guestIndex: number;
  timeEndWork: string;
  timeStartWork: string;
  currentServiceId: string;
  dataSource: GetDetailFacilitiesResponse[];
}

export const FacilitySelectField = (props: FacilitySelectFieldProps) => {
    const { watch, setValue } = useFormContext();
    const { facilityTimeOffs } = useAppointmentContext();
    const [searchValue, setSearchValue] = useState('');
    
    const {
        fieldName,
        dataSource,
        guestIndex,
        currentServiceId,
        timeEndWork,
        timeStartWork,
    } = props;

    const field = `${fieldName}.facilityId`;

    const facilityId = watch(field);

    const serviceDetailsInfo = useServiceDetailsInfo();

    const facilityOptions = useMemo(
        () =>
            dataSource.filter((o) => o.name?.toLowerCase().includes(searchValue.toLowerCase())).map((f) => {
                const serviceIds = f.serviceFacilities?.map((fs) => fs.serviceId) || [];
                const disabled = !serviceIds.includes(currentServiceId);

                const isValidFacility = checkFacilityValid({
                    guestIndex,
                    facility: f,
                    timeEndWork,
                    timeStartWork,
                    facilityTimeOffs,
                    serviceDetailsInfo,
                });

                return {
                    label: f.name,
                    value: f.id,
                    disabled: disabled || !isValidFacility,
                };
            }),
        [currentServiceId, dataSource, facilityTimeOffs, guestIndex, searchValue, serviceDetailsInfo, timeEndWork, timeStartWork]
    );

    useEffect(() => {
        const currentFacility = dataSource.find(o => o.id === facilityId);
        if (currentFacility) {
            const isValidFacility = checkFacilityValid({
                guestIndex,
                facility: currentFacility,
                timeEndWork,
                timeStartWork,
                facilityTimeOffs,
                serviceDetailsInfo,
            });
            if (isValidFacility == false) {
                setValue(field, null);
            }
        }
    }, [dataSource, facilityId, facilityTimeOffs, field, guestIndex, serviceDetailsInfo, setValue, timeEndWork, timeStartWork]);
    
    const dropdownRenderFacility = useCallback(
        (options: React.ReactElement) => (
            <>
                {dataSource.length ? (
                    <>
                        <InputSearch variant='borderless' placeholder='Search' onChange={(value) => setSearchValue(value.target.value)}/>
                        <Divider style={{ margin: 0 }} />
                        <Spacer top_bottom={9} left_right={12}>
                            <Translate translateCode={translateCodes.ANY_RESOURCE} />
                        </Spacer>{' '}
                        <Divider style={{ margin: 0 }} />
                    </>
                ) : null}
                {options}
            </>
        ),
        [dataSource.length]
    );

    return (
        <SelectField
            allowClear
            name={field}
            defaultValue={facilityId}
            onChange={(value) => {
                setValue(field, value ?? null);
            }}
            options={facilityOptions}
            placeholder={translateCodes.SEARCH_FACILITY}
            disabled={!currentServiceId}
            dropdownRender={dropdownRenderFacility}
        />
    );
};
