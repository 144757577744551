import React from 'react';

export const IconTotalRetentionClient = () => {
    return (
        <svg width='49' height='48' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0.399902 24C0.399902 10.7452 11.1451 0 24.3999 0C37.6547 0 48.3999 10.7452 48.3999 24C48.3999 37.2548 37.6547 48 24.3999 48C11.1451 48 0.399902 37.2548 0.399902 24Z' fill='#FEF3F2'/>
            <path opacity='0.4' d='M14.3999 20.8522C14.3999 25.7151 18.4193 28.3065 21.3616 30.626C22.3999 31.4445 23.3999 32.2151 24.3999 32.2151C25.3999 32.2151 26.3999 31.4445 27.4382 30.626C30.3805 28.3065 34.3999 25.7151 34.3999 20.8522C34.3999 15.9893 28.8997 12.5406 24.3999 17.2157C19.9001 12.5406 14.3999 15.9893 14.3999 20.8522Z' fill='#F04438'/>
            <path d='M28.8999 25.2871C26.425 22.5713 23.3999 24.5746 23.3999 27.3995C23.3999 29.9583 25.2139 31.4344 26.7584 32.6912L26.8017 32.7265C26.9473 32.8449 27.0902 32.9615 27.2289 33.0769C27.7999 33.5523 28.3499 34 28.8999 34C29.4499 34 29.9999 33.5523 30.5709 33.0769C32.1892 31.7296 34.3999 30.2243 34.3999 27.3995C34.3999 26.4715 34.0734 25.6321 33.5473 25.0197C32.4717 23.7677 30.5618 23.4635 28.8999 25.2871Z' fill='#F04438'/>
        </svg>
    );
};

