import { LoginState } from '@esg/framework';
import React from 'react';

export function useLoginStore() {
    const getData = React.useCallback((): LoginState | null => {
        const token = sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? '';
        if (!token) return null;

        return {
            token: sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? '',
            expiresAt: Number(sessionStorage.getItem('expiresIn') ?? localStorage.getItem('expiresIn') ?? 0),
            tokenType: sessionStorage.getItem('tokenType') ?? localStorage.getItem('tokenType') ?? '',
            idToken: sessionStorage.getItem('idToken') ?? localStorage.getItem('idToken') ?? '',
            refreshToken: sessionStorage.getItem('refreshToken') ?? localStorage.getItem('refreshToken') ?? '',
        };
    }, []);

    const setData = React.useCallback((loginInfo: LoginState) => {
        const refreshToken = loginInfo.refreshToken;
        const storage = refreshToken ? localStorage : sessionStorage;

        storage.setItem('token', loginInfo.token);
        storage.setItem('expiresIn', loginInfo.expiresAt.toString());
        storage.setItem('tokenType', loginInfo.tokenType);
        storage.setItem('idToken', loginInfo.idToken ?? '');
        storage.setItem('refreshToken', loginInfo.refreshToken ?? '');

    }, []);

    return {
        getData,
        setData
    };
}