import { HttpRequestData, httpUtil } from '@esg/framework';
import { Dayjs } from '@esg/shared';
import { env } from '../../configs';

export interface EmployeeUpdateRequest extends HttpRequestData {
	body: {
		readonly id: string;
		readonly dateOfBirth?: string | Dayjs;
		readonly phone?: string;
		readonly firstName: string;
		readonly lastName: string;
		readonly email: string;
		readonly avatar?: string;
		readonly address?: string;
		readonly gender?: number;
		readonly jobStatus?: number;
		readonly isAllowBooking: boolean;
		readonly jobStartDate?: string | Dayjs;
		readonly branchId?: string;
		readonly jobTypeId?: string;
	};
}

export const updateEmployee = httpUtil.createHttpRequestMeta<EmployeeUpdateRequest, void>({
    baseUrl: env.apiUrlBase,
    method: 'PATCH',
    path: '/api/v1/employees/:id',
    authentication: 'bearer',
});
