import React, { useEffect } from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { TableCustom, useHttpQuery, Text, ActionsColumn, globalModalState, useHttpCommand, IconRefresh, IconEye, managementProfileState, useLocalesFormat, globalDrawerState, FlexBox} from '@esg/ui';
import {  TableColumnsType, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {  resellSale } from '@esg/business-pos';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetProductClientDetailResponse, getProductClientDetailReport } from '@esg/business-report/api/client-detail-report/getProductClientDetail';
import { CustomerProductDetailTable } from './CustomerProductDetailTable';
import { translateCodes } from '@/locales';

import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import { saleEvents } from '@/event/saleEvent';
import { CustomerResellConfirm } from '@/components/customer/CustomerResellConfirm';
import { POS_URL } from '@/configs';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface CustomerProductTableProps {
    customer: GetCustomerDetailResponse;
    refetchOverview: () => void;
}

export const CustomerProductTable = ({ customer, refetchOverview }: CustomerProductTableProps) => {
    const { t } = useTranslation();
    const { onShowFormSale } = useOpenSaleFrom();
    const { formatDate } = useLocalesFormat();

    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeDetail: '10',
        orderByDetail: 'productName',
    });
    const [, setModalState] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [currentProfile] = useRecoilState(managementProfileState);
    const [,setDrawerState] = useRecoilState(globalDrawerState);

    const branchId = currentProfile?.branch.id;

    const { data: productReports, isFetching: isProductReportFetching, refetch } = useHttpQuery(getProductClientDetailReport, {
        pathData: {
            clientId: customer?.id,
        },
        query: {
            clientId: customer?.id,
            search: searchParams.searchByDetail,
            orderBy: searchParams.orderByDetail,
            orderDirection: searchParams.detailDirection,
            page: searchParams.pageDetail,
            pageSize: searchParams.pageSizeDetail,
        }
    });

    const onShowDetail = React.useCallback(
        (productId: string) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: <FlexBox direction='column'> 
                    <Text fontSize={20}>Rejoice Shampoo</Text>
                    <Text fontSize={12} color='#344054'>Latest sale date: 11:13 AM 11/07/2024</Text>
                </FlexBox>,
                content: (
                    <CustomerProductDetailTable productId={productId} customerId={customer.id} />
                ),
            });
		
        },[customer, setDrawerState]);

    useEffect(() => {
        const clearRefetchSaleList = saleEvents.onRefetchSaleList.listen(() => {
            refetch();
            refetchOverview();
        });
        return () => { clearRefetchSaleList?.(); };
    }, [refetch, refetchOverview]);

    const { mutateAsync: onResell } = useHttpCommand(resellSale, {
        onSuccess(sale) {
            resetGlobalModal();
            onShowFormSale(sale);
        },
    });

    const handleResell = React.useCallback(
        (productReport: GetProductClientDetailResponse) => {
            const saleCode = productReport?.saleCode ?? '';
            setModalState({
                content: (
                    <CustomerResellConfirm {...{ saleCode }} />
                ),
                isOpen: true,
                onOk: () => {
                    onResell({
                        body: {
                            branchId: branchId,
                            id: productReport.saleId,
                            productId: productReport.productId,
                        }
                    });
                },
                showModalConfirm: true,
            });
        },
        [branchId, onResell, setModalState]
    );

    const productActions = (productReport: GetProductClientDetailResponse) => {
        if (!productReport.saleId)
            return [{
                viewDetailUrl: `${POS_URL}`,
                icon: <IconEye />,
                tooltipTitle: t(translateCodes.VIEW),
            },];

        return [
            {
                icon: <IconRefresh />,
                onClick: () => handleResell(productReport),
                tooltipTitle: t(translateCodes.RESELL),
            }
        ];

    };

    const columns: TableColumnsType<GetProductClientDetailResponse> = [
        {
            width: '40%',
            title: t(translateCodes.PRODUCT_NAME),
            dataIndex: 'productName',
            key: 'name',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.detailDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, detailDirection: order });
                }
            }),
            render: (productName, productReport) => (
                <a
                    onClick={() => {
                        onShowDetail(productReport.productId!);
                    }}
                >
                    {productName}
                </a>
            )
        },

        {
            width: '10%',
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            width: '20%',
            title: t(translateCodes.ORDER_DATE),
            key: 'orderDate',
            dataIndex: 'orderDate',
            render: (date) => {
                return formatDate(date);
            },
        },
        {
            width: '20%',
            title: t(translateCodes.TOTAL_AMOUNT),
            key: 'totalAmount',
            dataIndex: 'totalAmount',
            render: (totalAmount) => <Text fontWeight={600}>{totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>,

        },
        {
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ActionsColumn
                        actions={productActions(record)}
                    />
                );
            },
        },
    ];

    return (
        <TableCustom
            onPageChange={(page) =>
                setSearchParams({
                    ...searchParams,
                    pageDetail: page.toString(),
                })
            }
            // onFilter={()=>{}}
            // onExportExcel ={()=>{}}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByDetail: value })
            }
            placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
            loading={isProductReportFetching}
            titleTableTransCode={translateCodes.PRODUCT_LIST}
            subTitleTable={<Tag color='processing'>{`${productReports?.items.length} ${t(translateCodes.PRODUCTS)}`}</Tag>}
            columns={columns}
            dataSource={productReports?.items ?? []}
        />
    );
};

