import { ClockCircleOutlined } from '@ant-design/icons';
import { FlexBox, Text, Translate } from '@esg/ui';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { dayjs } from '@esg/shared';
import { TimeOffFormValues } from '../Types';

export const TimeOffTotal = () => {
    const { watch } = useFormContext<TimeOffFormValues>();

    const date = watch('date');
    const repeatToDate = watch('repeatToDate');

    const total = useMemo(() => {
        if (!repeatToDate) {
            return 1;
        }

        return dayjs(repeatToDate).diff(date, 'd') + 1;
    },[date, repeatToDate]); 

    return (
        <FlexBox gap={6} alignItems='center'>
            <ClockCircleOutlined />
            <Translate translateCode='Time off total:' />
            <Text fontWeight='bold'>{total}d</Text>
        </FlexBox>
    );
};
