import { Block, Spacer,Text } from '@esg/ui';
import { Card } from 'antd';
import { Dayjs } from '@esg/shared';
import React from 'react';
import AnalyserClientLineChart from './AnalyserClientLineChart';

interface AnalyserClientMainChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
function AnalyserClientMainChart(props: AnalyserClientMainChartProps) {
    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={30}>
                <Card>
                    <Text fontSize={18} fontWeight={600}>Total Client</Text>
                    <AnalyserClientLineChart dateRange={props.dateRange} />
                </Card>
            </Spacer>
        </Block>
    );
}

export default AnalyserClientMainChart;