import { PlusCircleFilled } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';

import { ServiceCategoryForm } from '@/components';
import { translateCodes } from '@/locales';

interface CreateServiceCategoryBtnProps {
    readonly refetch: () => void;
    readonly resetDrawerState: () => void;
}

export const CreateServiceCategoryBtn = (
    props: CreateServiceCategoryBtnProps
) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    return (
        <AppButton
            translateCode={translateCodes.CREATE_SERVICE_CATEGORY_BUTTON}
            icon={<PlusCircleFilled />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: translateCodes.TITLE_FORM_CREATE_SERVICE_CATEGORY,
                    content: (
                        <ServiceCategoryForm
                            refetch={props.refetch}
                            resetDrawerState={props.resetDrawerState}
                        />
                    ),
                });
            }}
        />
    );
};