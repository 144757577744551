import { AppointmentCreateRequest } from '@esg/business-schedule';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppointmentFormValues } from '../../AppointmentFormControl';

export const useUpdateGuestServices = ({
    watch,
    getValues,
    setValue,
}: UseFormReturn<AppointmentFormValues>) => {
    const prevNumberOfGuest = watch('numberOfGuest') || 1;

    useEffect(() => {
        return () => {
            const currentNumberOfGuest = getValues('numberOfGuest') || 1;
            const appointmentServices = getValues('appointmentServices');
            const appointmentServicesUpdate: AppointmentCreateRequest['body']['appointmentServices'] =
				[];

            for (let i = 0; i < currentNumberOfGuest; i++) {
                if (appointmentServices[i]) {
                    appointmentServicesUpdate.push(appointmentServices[i]);
                    continue;
                }
                appointmentServicesUpdate.push({
                    appointmentServiceDetails: [{}],
                });
            }
            setValue('appointmentServices', appointmentServicesUpdate);
        };
    }, [getValues, prevNumberOfGuest, setValue]);
};
