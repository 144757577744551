import { FlexBox, Spacer, useHttpQuery, Text } from '@esg/ui';
import { Link, useParams } from 'react-router-dom';
import { customerApi } from '@esg/business-crm';
import { Image } from 'antd';
import { getOverviewClientDetailReport } from '@esg/business-report/api/client-detail-report';
import { CustomerInfo } from './children/CustomerInfo';
import { translateCodes } from '@/locales';
import { CRM_URL } from '@/configs';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';

export const CustomerDetail = () => {
    const { id: customerId } = useParams();

    const {
        data: customer,
        isLoading,
        isFetching,
        refetch,
    } = useHttpQuery(customerApi.getCustomerDetail, {
        pathData: {
            id: customerId,
        },
    });

    const { data: reportOverview, isLoading: isReportLoading, refetch: refetchOverview } = useHttpQuery(getOverviewClientDetailReport, {
        pathData: {
            clientId: customerId,
        },
    });

    const loading = isLoading || isFetching || isReportLoading;

    return (
        <Spacer size={24} >
            <FlexBox className='customer-detail' direction='column' >
                <Spacer height={8} />
                <FlexBox gap={20} >
                    <Link to={CRM_URL}>
                        <Text translateCode={translateCodes.SIMPLE_CRM} fontWeight={500} fontSize={14} color='#475467' /> 
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Link to={CRM_URL}>
                        <Text translateCode={translateCodes.CLIENT_LIST} fontWeight={500} fontSize={14} color='#475467' />
                    </Link>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={14}
                        height={14}
                    />
                    <Text translateCode = {customer?.customerCode} fontWeight={600} fontSize={14} color='#0C6FF3'/>
                </FlexBox>
                <Spacer height={16} />
                <Spacer height={6} />
                <CustomerInfo refetchOverview={refetchOverview} customer={customer} loading={loading} refetchCustomerDetail={refetch} reportOverview={reportOverview} />
            </FlexBox>
        </Spacer>
    );
};

