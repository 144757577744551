import { taxCreate } from './taxCreate';
import { taxDelete } from './taxDelete';
import { GetAllTaxResponseItem, taxGetAll } from './taxGetAll';
import { taxUpdate } from './taxUpdate';

export type {
    GetAllTaxResponseItem
};

export const taxApi = {
    taxGetAll,
    taxCreate,
    taxDelete,
    taxUpdate
};
