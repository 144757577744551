import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface CancelReasonGetAllRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}

export interface CancelReasonGetAllResponseItem {
    readonly id: string;
    readonly name: string;
}

export const getAllCancelReason = httpUtil.createHttpRequestMeta<
    CancelReasonGetAllRequest,
	PaginationResponse<CancelReasonGetAllResponseItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/cancel-reasons',
    authentication: 'bearer',
});