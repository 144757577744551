export enum ActivityAction
{
    Sale = 0,
    Appointment = 1,
    Customer = 2,
}
export enum ActivityType
{
    Create = 0,
    Update = 1,
    Delete = 2,
    UnPaid = 3,
    Paid = 4,
    Confirmed = 5,
    Booked = 6,
    CheckedIn = 7,
    Completed = 8,
    NoShow = 9,
    CheckOut = 10,
    Cancelled = 11,
}