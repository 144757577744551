import { env } from '@esg/shared';
import React from 'react';

interface UseLoginPageOptions {
    readonly redirectUri: string;
    readonly clientId: string;
}

export const useLoginPage = () => {
    return React.useCallback((options: UseLoginPageOptions) => {
        const { redirectUri, clientId } = options;
        const url = new URL(env.VITE_IDENTITY_CLIENT + '/auth/login');
        url.searchParams.append('redirect_uri', redirectUri);
        url.searchParams.append('client_id', clientId);

        location.href = url.toString();
    }, []);
};