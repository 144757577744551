import { GetAllServiceResponseItem } from '@esg/business-management/api/service';
import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { env } from '../../configs';

interface SaleDetailGetAllRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}
export enum DiscountUnit {
    Percent = 0,
    Amount = 1
}

export interface SaleDetailEmployee {
    readonly employeeId: string;
    readonly employee?: EmployeeGetDetailResponse

}
export interface SaleDetailGetAllResponseItem{
    readonly id: string;
    readonly saleId: string;
    readonly objectId: string;
    readonly objectType: string;
    readonly objectPrice: number;
    readonly objectPriceOrigin: number;
    readonly objectName: string;
    readonly quantity: number;
    readonly totalAmount: number;
    readonly taxAmount: number;
    readonly discount?: number;
    readonly discountUnit?: DiscountUnit;
    readonly serviceId?: string;
    readonly saleDetailEmployees?: SaleDetailEmployee[];
    readonly service?: GetAllServiceResponseItem;
}

export const getAllSaleDetail = httpUtil.createHttpRequestMeta<
    SaleDetailGetAllRequest,
	PaginationResponse<SaleDetailGetAllResponseItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/sale-details',
    authentication: 'bearer',
});