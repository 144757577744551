import { FlexBox } from '@esg/ui';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ShiftFormValues } from '../../Types';
import { ShiftFormItem } from '../ShiftFormItem';

export const ShiftTimeBlocks = () => {
    const { control } = useFormContext<ShiftFormValues>();
    const { fields, remove } = useFieldArray({
        control: control,
        name: 'timeBlocks',
    });

    return (
        <FlexBox direction='column' gap={12}>
            {fields.map((field, index) => (
                <ShiftFormItem
                    key={field.id}
                    remove={remove}
                    fieldIndex={index}
                    title={field.title}
                    fieldName={`timeBlocks.${index}`}
                />
            ))}
        </FlexBox>
    );
};
