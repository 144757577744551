import { customerApi } from '@esg/business-crm';
import { globalDrawerState, globalModalState, useHttpCommand } from '@esg/ui';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';

export const useCustomerDelete = (refetch?: () => void, urlRedirect?: string) => {

    const navigate = useNavigate();
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    
    const { mutateAsync } = useHttpCommand(customerApi.deleteCustomer, {
        onSuccess() {
            resetModalState();
            resetDrawerState();
            if (refetch) {
                refetch();
            }
            if (urlRedirect) {
                navigate(urlRedirect);
            }
        },
    });

    return async (customerId: string) => {
        await mutateAsync({ pathData: { id: customerId } });
    };
};

