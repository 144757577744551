import { useCallback } from 'react';
import { globalModalState } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { dayjs } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { ShiftFormValues, EmployeeShiftForm } from '@/components';
import { translateCodes } from '@/locales';

export const useEmployeeShiftForm = () => {
    const { t } = useTranslation();
    const [, showModal] = useRecoilState(globalModalState);

    const showForm = useCallback(
        async (defaultValues: ShiftFormValues) => {

            showModal({
                isOpen: true,
                titleTransCode: `${t(translateCodes.EDIT_SHIFTS_FOR)} ${
                    defaultValues.employee.fullName
                }, ${dayjs(defaultValues.currentDate).format('DD MMM')}`,
                content: <EmployeeShiftForm defaultValues={defaultValues} />,
                width: 738,
                footer: null,
            });
        },
        [showModal, t]
    );

    return showForm;
};
