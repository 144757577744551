import React from 'react';
import { IconProps } from '../../Types';

export const IconMinimize = (props: IconProps) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='ic/Bold / Arrows Action / Minimize '>
                <g id='Vector'>
                    <path d='M17.3809 8.12502C17.7261 8.12502 18.0059 7.8452 18.0059 7.50002C18.0059 7.15484 17.7261 6.87502 17.3809 6.87502H14.0088L18.7752 2.10863C19.0193 1.86455 19.0193 1.46882 18.7752 1.22475C18.5312 0.980668 18.1354 0.980668 17.8914 1.22475L13.125 5.99114V2.61907C13.125 2.27389 12.8451 1.99407 12.5 1.99407C12.1548 1.99407 11.875 2.27389 11.875 2.61907V7.50002C11.875 7.8452 12.1548 8.12502 12.5 8.12502H17.3809Z' fill='#101828'/>
                    <path d='M2.61901 11.875C2.27383 11.875 1.99401 12.1548 1.99401 12.5C1.99401 12.8452 2.27383 13.125 2.61901 13.125H5.99108L1.22468 17.8914C0.980607 18.1355 0.980607 18.5312 1.22468 18.7753C1.46876 19.0194 1.86449 19.0194 2.10857 18.7753L6.87496 14.0089V17.381C6.87496 17.7262 7.15478 18.006 7.49996 18.006C7.84514 18.006 8.12496 17.7262 8.12496 17.381V12.5C8.12496 12.1548 7.84514 11.875 7.49996 11.875H2.61901Z' fill='#101828'/>
                </g>
            </g>
        </svg>
    );
};

IconMinimize.defaultProps = {
    color: '#101828',
    className: '',
    onClick: () => {},
};
