import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteServiceRequest extends HttpRequestData {
}

export const deleteService = httpUtil.createHttpRequestMeta<
    DeleteServiceRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/services/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
