import { DownOutlined, } from '@ant-design/icons';
import {  FlexBox, Text, IconGroup, IconSource } from '@esg/ui';
import React from 'react';
import { Button, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { CUSTOMER_GROUP_URL, CUSTOMER_SOURCE_URL } from '@/configs';
import { translateCodes } from '@/locales';

export const ActionCustomer = () => {
   
    const items = [
        {
            key: 'group',
            label: (
                <Link to={CUSTOMER_GROUP_URL}>
                    <FlexBox gap={12} alignItems='center'>
                        <IconGroup />
                        <Text translateCode={translateCodes.CLIENT_GROUP} />
                    </FlexBox>
                </Link>
            ),
        },
        {
            key: 'source',
            label: (
                <Link to={CUSTOMER_SOURCE_URL}>
                    <FlexBox gap={12} alignItems='center'>
                        <IconSource />
                        <Text translateCode={translateCodes.CLIENT_SOURCE} />
                    </FlexBox>
                </Link>
            ),
        },
    ];

    return (
        <Dropdown menu={{ items }} placement='bottomRight' arrow>
            <Button>
                <FlexBox alignItems='center'>
                    <Text fontSize={14} fontWeight={400} translateCode='Action' />
                    <DownOutlined style={{ marginLeft: 10 }} />
                </FlexBox>
            </Button>
        </Dropdown>
    );
};
