import { getAllBranches } from './getAllBranches';
import { updateBranch } from './updateBranch';
import { getDetailBranch } from './getDetailBranch';
import { deleteBranch } from './deleteBranch';
import { createBranch } from './createBranch';

export type { BranchGetAllResponseItem } from './getAllBranches';

export const branchApis = {
    getAllBranches,
    updateBranch,
    getDetailBranch,
    deleteBranch,
    createBranch
};