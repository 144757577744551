import {
    EmployeeGetDetailResponse,
    ShiftGetDetailResponse,
} from '@esg/business-resource-management';
import { dayjs } from '@esg/shared';
import { AppAvatar, FlexBox, SchedulerRowInfo, Spacer, Text, Translate } from '@esg/ui';
import { Dropdown } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { flatten } from 'lodash';
import { useShiftContext } from '../../ShiftContext';
import { translateCodes } from '@/locales';

interface ShiftRowInfo {
	readonly employee: EmployeeGetDetailResponse;
	readonly shifts: ShiftGetDetailResponse[];
}

export const useShiftRowInfo = (employees: EmployeeGetDetailResponse[]) => {
    const { shiftItems } = useShiftContext();

    const rowInfos = React.useMemo<SchedulerRowInfo[]>(
        () =>
            employees.map((employee) => {
                const shifts = shiftItems
                    .filter((i) => i.rowKey === employee.id)
                    .map((i) => i.data);
                return {
                    key: employee.id,
                    data: { employee, shifts },
                };
            }),
        [employees, shiftItems]
    );

    const renderRowInfo = React.useCallback(
        ({ data: { employee, shifts } }: SchedulerRowInfo<ShiftRowInfo>) => {
            const dropdownItems = [
                {
                    key: '1',
                    label: (
                        <Link to={`/resource-management/employee-members/${employee.id}`}>
                            <Spacer type='padding' left={4}>
                                <Translate translateCode={translateCodes.EDIT_WORKING_HOUR} />
                            </Spacer>
                        </Link>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Link to={`/resource-management/employee-members/${employee.id}`}>
                            <Spacer type='padding' left={4}>
                                <Translate translateCode={translateCodes.VIEW_EMPLOYEE_DETAIL} />
                            </Spacer>
                        </Link>
                    ),
                },
            ];

            const totalMinuteEstimate = shifts.reduce(
                (result, shift) => result + dayjs(shift.endTime).diff(shift.startTime, 'm'),
                0
            );

            const hourEstimate = Math.floor(totalMinuteEstimate / 60);

            const minuteEstimate = totalMinuteEstimate % 60;

            const totalBreakTime = flatten(shifts.map((s) => s.breakTimes)).reduce(
                (result, bt) => result + dayjs(bt.endTime).diff(bt.startTime, 'm'),
                0
            );

            const totalMinuteActual = totalMinuteEstimate - totalBreakTime;

            const hourActual = Math.floor(totalMinuteActual / 60);

            const minuteActual = totalMinuteActual % 60;

            return (
                <div className='row-info' key={employee.id}>
                    <FlexBox width='100%' gap={12} alignItems='center'>
                        <AppAvatar data={employee} />
                        <FlexBox direction='column' gap={4}>
                            <Text fontWeight={700} lineHeight='20px' ellipsis maxWidth={100}>
                                {employee.fullName}
                            </Text>
                            <Text fontSize={12} lineHeight='18px'>
                                {hourActual}h {minuteActual ? minuteActual + 'min' : undefined} /{' '}
                                {hourEstimate}h {minuteEstimate ? minuteEstimate + 'min' : undefined}
                            </Text>
                        </FlexBox>
                        <Dropdown menu={{ items: dropdownItems }}>
                            <img src='/assets/icons/Edit.svg' className='cursor-pointer' />
                        </Dropdown>
                    </FlexBox>
                </div>
            );
        },
        []
    );

    return { renderRowInfo, rowInfos };
};
