import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface CreateBranchRequest extends HttpRequestData {
    readonly pathData: {
		id: string;
	};
}

export const deleteBranch = httpUtil.createHttpRequestMeta<
    CreateBranchRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/branches/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
