import { AppButton, FlexBox, IconAddAppointmentEmpty, IconBorn, Text } from '@esg/ui';
import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import { translateCodes } from '@/locales/translateCodes';

interface AppointmentUpcomingEmptyProps {
    customer?: GetCustomerDetailResponse;
    refetchAppointmentUpcoming: () => void;
}

export function AppointmentUpcomingEmpty({customer, refetchAppointmentUpcoming}:AppointmentUpcomingEmptyProps) {
    const showAppointmentForm = useAppointmentForm();
    return (
        <FlexBox direction='row' justifyContent='space-between'>
            <FlexBox direction='column'gap={12}>
                <Text>
                This customer currently does not have any appointments scheduled.
                </Text>
                <FlexBox>
                    <AppButton
                        type='default'
                        onClick={()=>showAppointmentForm({
                            customerId: customer?.id,
                            refetch: refetchAppointmentUpcoming,
                        })}>
                        <FlexBox gap={6}>
                            <IconBorn width={20} height={20} color='#344054' />
                            <Text color='#344054' translateCode={translateCodes.BOOKING_NOW} />
                        </FlexBox>
                    </AppButton>
                </FlexBox>
            </FlexBox>
            <IconAddAppointmentEmpty />
        </FlexBox>
    );
}
