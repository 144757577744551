import { CreateJobTypeResponse, resourceApis, } from '@esg/business-resource-management';
import { useHttpCommand, useHttpQuery, usePagination } from '@esg/ui/hooks';
import { globalModalState } from '@esg/ui/state';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
    ColumnActions,
    Header,
    Paragraph,
    Spacer,
    TableCustom,
    Text,
} from '@esg/ui/components';
import { AddJobTypeBtn } from './AddJobTypeBtn';
import { translateCodes } from '@/locales';
import { PageLayout } from '@/layouts/page-layout';
import JobTypeForm from '@/components/form/job-type/JobTypeForm';

export const JobType = () => {
    const { t } = useTranslation();
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const [searchParams, setSearchParams] = usePagination();
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);

    const resetGlobalModal = useResetRecoilState(globalModalState);

    const { data: jobTypes, refetch } = useHttpQuery(resourceApis.getAllJobTypes, 
        {query: {...searchParams}});

    const { mutateAsync } = useHttpCommand(resourceApis.deleteJobType, {
        onSuccess() {
            resetModalState();
            refetch();
        },
    });

    const handleEdit = useCallback(
        async (jobType: CreateJobTypeResponse) => {
            setGlobalModal({
                ...globalModal,
                isOpen: true,
                titleTransCode: translateCodes.EDIT_JOB_TYPE,
                content: <JobTypeForm
                    onCancel={() => resetGlobalModal()}
                    jobType={jobType}
                    onSuccess={() => {
                        resetGlobalModal();
                        refetch();
                    }}
                />,
            });
        },
        [globalModal, refetch, resetGlobalModal, setGlobalModal]
    );

    const handleDelete = useCallback(
        async (jobType: CreateJobTypeResponse) => {
            setModalState({
                isOpen: true,
                showModalDelete: true,
                content: (
                    <>
                        <Header level={3} translateCode={translateCodes.DELETE_JOB_TYPE} />
                        <Spacer bottom='xs' />
                        <Paragraph>
                            <Text translateCode={translateCodes.DELETE_JOB_TYPE_MSG} />:{' '}  
                            <Text fontWeight='bold'>{jobType.name}</Text>
                        </Paragraph>
                    </>
                ),
                onOk: () => {
                    mutateAsync({
                        pathData: {
                            id: jobType.id,
                        },
                    });
                },
            });
        },
        [mutateAsync, setModalState]
    );

    const jobTypesColumn: ColumnsType<{ id: string; name: string }> = [
        {
            width: '90%',
            title: t(translateCodes.JOB_TYPE),
            dataIndex: 'name',
            key: 'JobType',
        },
        {
            width: 116,
            title: t(translateCodes.ACTION),
            key: 'name',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickEdit={() => handleEdit(record)}
                        onClickDelete={() => handleDelete(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.JOB_TYPE}
            rightHeaderPage={
                <AddJobTypeBtn
                    resetGlobalModal={resetGlobalModal}
                    refetch={refetch}
                />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({
                        ...searchParams,
                        page: page.toString(),
                    })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                titleTableTransCode={translateCodes.JOB_TYPE_LIST}
                placeholderSearchTransCode={translateCodes.SEARCH_JOB_TYPE}
                columns={jobTypesColumn}
                dataSource={jobTypes?.items}
                emptyOption={{
                    title: t(translateCodes.ADD_JOB_TYPE),
                    description: t(translateCodes.EMPTY_JOB_TYPE_DESCRIPTION),
                    actionButton: (
                        <AddJobTypeBtn
                            resetGlobalModal={resetGlobalModal}
                            refetch={refetch}
                        />
                    )
                }}
            />
        </PageLayout>
    );
};
