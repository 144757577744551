import {
    createAppointment,
    getAllAppointments,
    getAppointmentDetail,
    updateAppointment,
    deleteAppointment,
    getAppointmentQuantityByEmployee,
} from './appointment';
import { getAllAppointmentSetting, getDetailAppointmentSetting, updateAppointmentSetting } from './appointment-setting';
import { deleteAppointmentServiceDetail } from './appointment-service-detail/deleteAppointmentServiceDetail';
import { updateAppointmentServiceDetail } from './appointment-service-detail/updateAppointmentServiceDetail';
import { deleteAppointmentServiceEmployee, createAppointmentServiceEmployee, updateAppointmentServiceEmployee } from './appointment-service-employee';
import { changeAppointmentStatus } from './appointment/noShowAppointment';

export const schedulerApis = {
    createAppointment,
    getAllAppointments,
    getAppointmentDetail,
    deleteAppointment,
    updateAppointment,
    changeAppointmentStatus,
    getAllAppointmentSetting,
    updateAppointmentSetting,
    deleteAppointmentServiceEmployee,
    deleteAppointmentServiceDetail,
    createAppointmentServiceEmployee,
    updateAppointmentServiceEmployee,
    getAppointmentQuantityByEmployee,
    updateAppointmentServiceDetail,
    getDetailAppointmentSetting
};
