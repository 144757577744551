import { translateCodes } from '@/locales';

export const bankAccountRules = {
    accountName: {
        required: translateCodes.THIS_FIELD_IS_REQUIRED,
    },
    accountNumber: {
        required: translateCodes.THIS_FIELD_IS_REQUIRED,
    },
    bankName: {
        required: translateCodes.THIS_FIELD_IS_REQUIRED,
    },
};