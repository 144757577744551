import { schedulerApis } from '@esg/business-schedule';
import { AppointmentStatus } from '@esg/shared';
import { useHttpCommand } from '@esg/ui';
import { useCallback } from 'react';
import { appointmentEvents } from '@/event';

export const useUpdateAptStatus = () => {

    const { mutateAsync: updateAptStatus } = useHttpCommand(schedulerApis.changeAppointmentStatus, {
        onSuccess() {
            appointmentEvents.refetchAppointments.emit();
        },
    });

    const handleUpdate = useCallback(
        async (appointmentId: string, status: AppointmentStatus) => {
            await updateAptStatus({
                pathData: {
                    id: appointmentId,
                },
                body: {
                    id: appointmentId,
                    status,
                },
            });
        },
        [updateAptStatus]
    );

    return handleUpdate;
};
