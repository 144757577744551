import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppointmentFormValues } from '../../AppointmentFormControl';
export const useUpdateGuestQuantity = ({ getValues, setValue }: UseFormReturn<AppointmentFormValues>) => {
    
    const numberServices = getValues('appointmentServices')?.length;

    useEffect(() => {
        setValue('numberOfGuest', numberServices);
    }, [getValues, numberServices, setValue]);
};
