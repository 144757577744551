import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Automation } from './automation';
import { SubMenuLayout } from '@/layouts';
import { AUTOMATION_URL, salonMarketingMenu } from '@/configs';

export function SuccessPlatform() {
    return (
        <Routes>
            <Route
                element={<SubMenuLayout subMenu={salonMarketingMenu} />}
            >
                <Route path='/' element={<Navigate to={AUTOMATION_URL} />} />
                <Route path='/Automation' Component={Automation} />
            </Route>
        </Routes>
    );
}
