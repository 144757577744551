import { EmployeeCreateRequest } from '@esg/business-resource-management';
import { WorkingTime } from '@esg/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const EmployeeWorkingTimeForm = () => {
    const { control, watch } = useFormContext<EmployeeCreateRequest['body']>();
    
    return (
        <WorkingTime
            name='shifts'
            control={control}
            watch={watch}
        />
    );
};
