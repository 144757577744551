import { managementApis } from '@esg/business-management';
import { SelectField, subDrawerState, useHttpQuery } from '@esg/ui';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ProductUnitForm } from '../../unit';
import { productRulers } from '../../rules/productRulers';
import { translateCodes } from '@/locales';

export function ProductUnit() {
    const { t } = useTranslation();
    const [, setSubDrawerState] = useRecoilState(subDrawerState);
    const resetSubDrawerState = useResetRecoilState(subDrawerState);
    const { setValue } = useFormContext();
    const { data: unit, isFetching, refetch } = useHttpQuery(managementApis.getAllProductUnits);

    const optionsUnit = useMemo(() => unit?.items.map((i) => ({
        label: i.name,
        value: i.id
    })) ?? []
    , [unit]);
    
    const createUnit = () => {
        setSubDrawerState({
            isOpen: true,
            titleTransCode: translateCodes.CREATE_UNIT,
            content: (
                <ProductUnitForm
                    onSuccess={(res) => {
                        refetch();
                        setValue('productUnitId', res?.id);
                        resetSubDrawerState();
                    }}
                />
            ),
        });
    };
    return (
        <SelectField
            loading={isFetching}
            showSearch
            filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
            rules={productRulers.unit}
            name='productUnitId'
            label= {t(translateCodes.UNIT)}
            placeholder={t(translateCodes.SELECT_UNIT)}
            options={optionsUnit}
            onCreate={createUnit}
        />
    );
}
