export enum TimeBlockRepeatType {
	NEVER = 0,
	SPECIFIC_DATE = 1,
	AFTER = 2,
}

export enum TimeBlockRepeatBy {
	NONE = 0,
	DAILY = 1,
	WEEKLY = 2,
	MONTHLY = 3,
}
