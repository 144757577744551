import { atom } from 'recoil';

export interface LoginState {
    readonly token: string;
    readonly expiresAt: number;
    readonly tokenType: string;
    readonly idToken?: string;
    readonly refreshToken?: string;
}

export const loginState = atom<LoginState | null>({
    key: 'loginState',
    default: null
});