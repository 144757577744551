export enum CUSTOMER_LABEL {
	NEW,
	RETURN,
}

export const customerLabelUtil = (value?: number) => {
    switch (value) {
    case CUSTOMER_LABEL.NEW:
        return 'New Client';
    case CUSTOMER_LABEL.RETURN:
        return 'Return Client';
    default:
        return '';
    }
};

export const customerLabelSimpleUtil = (value?: number) => {
    switch (value) {
    case CUSTOMER_LABEL.NEW:
        return 'New';
    case CUSTOMER_LABEL.RETURN:
        return 'Return';
    default:
        return '';
    }
};

export const customerLabelColorUtil = (value?: number) => {
    switch (value) {
    case CUSTOMER_LABEL.NEW:
        return '#079455';
    case CUSTOMER_LABEL.RETURN:
        return '#0C6FF3';
    default:
        return '#079455';
    }
};

export const customerLabelTagColorUtil = (value?: number) => {
    switch (value) {
    case CUSTOMER_LABEL.NEW:
        return 'green';
    case CUSTOMER_LABEL.RETURN:
        return 'blue';
    default:
        return 'green';
    }
};