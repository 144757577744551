import React from 'react';
import { Block, useHttpCommand, useHttpQuery } from '@esg/ui';
import { Spin, Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import {
    UpdateBookingOnlineSettingRequest,
    getCurrentBookingOnlineSetting,
    updateBookingOnlineSetting,
} from '@esg/business-setting/api/booking-online';
import { useTranslation } from 'react-i18next';
import { getCurrentPageBookingSetting } from '@esg/business-setting/api/page-booking';
import AlertBookingUrl from './children/AlertBookingUrl';
import PagesTab from './children/PagesTab';
import SettingsTab from './children/SettingsTab';
import ServicesSettingTab from './children/ServicesSettingTab';
import EmployeeSettingService from './children/EmployeeSettingService';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import './BookingOnlineSetting.scss';

const settingTabKeys = {
    SETTING: 'SETTING',
    PAGES: 'PAGES',
    SERVICE: 'SERVICE',
    EMPLOYEE: 'EMPLOYEE',
};

export default function BookingOnlineSetting() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = React.useState(settingTabKeys.SETTING);

    const {
        data: bookingOnlineSetting,
        refetch: refetchBookingOnline,
        isFetching: isFetchingBookingOnline,
    } = useHttpQuery(getCurrentBookingOnlineSetting);

    const {
        data: pageBookingSetting,
        refetch: refetchPageBooking,
        isFetching: isFetchingPageBooking,
    } = useHttpQuery(getCurrentPageBookingSetting);

    const { mutateAsync, isPending } = useHttpCommand(
        updateBookingOnlineSetting,
        {
            onSuccess: () => {
                refetchBookingOnline();
            },
            onError: () => {
                refetchBookingOnline();
            },
        }
    );

    const onUpdateBookingOnlineSetting = async (
        values: Partial<UpdateBookingOnlineSettingRequest['body']>
    ) => {
        await mutateAsync({
            pathData: { id: values.id },
            body: values,
        });
    };

    const tabItems: TabsProps['items'] = [
        {
            key: settingTabKeys.SETTING,
            label: t(translateCodes.PREFERENCES),
            children: (
                <SettingsTab
                    isLoading={isPending}
                    updateBookingOnlineSetting={onUpdateBookingOnlineSetting}
                    bookingOnlineSetting={bookingOnlineSetting}
                />
            ),
        },
        {
            key: settingTabKeys.PAGES,
            label: t(translateCodes.PAGES),
            children: (
                <PagesTab
                    pageBookingSetting={pageBookingSetting}
                    isFetching={isFetchingPageBooking}
                    refetch={refetchPageBooking}
                />
            ),
        },
        {
            key: settingTabKeys.SERVICE,
            label: t(translateCodes.SERVICES),
            children: (
                <ServicesSettingTab
                    bookingOnlineSetting={bookingOnlineSetting}
                />
            ),
        },
        {
            key: settingTabKeys.EMPLOYEE,
            label: t(translateCodes.EMPLOYEES),
            children: <EmployeeSettingService />,
        },
    ];

    if (isFetchingBookingOnline || isFetchingPageBooking) return <Spin fullscreen={true} />;

    return (
        <>
            <AlertBookingUrl pageBookingSetting={pageBookingSetting} />
            <PageLayout pageTitleTransCode={translateCodes.BOOKING_ONLINE}>
                <Block>
                    <Tabs
                        defaultActiveKey={activeTab}
                        items={tabItems}
                        onChange={setActiveTab}
                    />
                </Block>
            </PageLayout>
        </>
    );
}
