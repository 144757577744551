import {
    FlexBox,
    FormLayout,
    InputField,
    RadioGroupField,
    globalDrawerState,
    useHttpCommand,
} from '@esg/ui';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Card, Radio, Spin } from 'antd';
import { useResetRecoilState, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IntegrationEmailGetAllResponseItem } from '@esg/business-setting/api/integration/integrationEmailGetAll';
import { IntegrationStatus } from '@esg/business-setting/api/integration/Types';
import { integrationDisconnect } from '@esg/business-setting/api/integration/integrationEmailDisconnect';
import { integrationEmailUpdateAsConnect } from '@esg/business-setting/api/integration/integrationEmailUpdateAsConnect';
import { integrationEmailCreate } from '@esg/business-setting/api/integration/integrationEmailCreate';
import { HttpRequestError, viewRequests } from '@esg/framework';
import { IntegrationServiceField } from './children/IntegrationServiceField';
import { integrationRules } from './@share/rules';
import { IntegrationDrawerTitle } from '@/pages/setting/integrations/children/IntegrationDrawerTitle';
import { translateCodes } from '@/locales';

interface IntegrationFormProps {
    defaultValues: Partial<IntegrationEmailGetAllResponseItem>;
    refetch: () => void;
    titleIcon: React.ReactNode;
}

export const IntegrationForm = (props: IntegrationFormProps) => {
    const { defaultValues, refetch, titleIcon } = props;
    const hideIntegrationDrawer = useResetRecoilState(globalDrawerState);
    const [integrationState, showIntegration] = useRecoilState(globalDrawerState);
    const { t } = useTranslation();

    const methods = useForm<IntegrationEmailGetAllResponseItem>({
        defaultValues,
    });

    const status = methods.watch('status');

    const { mutateAsync: disconnect } = useHttpCommand(integrationDisconnect, {
        onSuccess() {
            methods.setValue('status', IntegrationStatus.Disabled);
            showIntegration({
                ...integrationState,
                titleTransCode: (
                    <IntegrationDrawerTitle
                        icon={titleIcon}
                        integration={methods.getValues()}
                    />
                ),
            });
            refetch();
        },
    });

    const { mutateAsync: createAsConnect } = useHttpCommand(integrationEmailCreate, {
        onSuccess() {
            hideIntegrationDrawer();
            refetch();
            viewRequests.showNotification.send({
                type: 'success',
                title: 'Connect email success!!! ',
                message: 'Connect email success!!!',
            });
        },
        onError(error) {
            const errorResponse = error as HttpRequestError;

            if (errorResponse.response.status == 400) {
                viewRequests.showNotification.send({
                    type: 'error',
                    title: 'Connect email fail!!! ',
                    message: 'Connect email fail!!!',
                });
            }

        },
    });

    const { mutateAsync: updateAsConnect, isPending } = useHttpCommand(integrationEmailUpdateAsConnect, {
        onSuccess() {
            hideIntegrationDrawer();
            refetch();
            viewRequests.showNotification.send({
                type: 'success',
                title: 'Connect email success!!! ',
                message: 'Connect email success!!!',
            });
        },
        onError() {
            viewRequests.showNotification.send({
                type: 'error',
                title: 'Connect email fail!!! ',
                message: 'Connect email fail!!!',
            });
        },
    });

    const submitText = useMemo(() => {
        return status === IntegrationStatus.Disabled ? t(translateCodes.CONNECT) : t(translateCodes.DISCONNECT);
    }, [status, t]);

    const handleSubmit = useCallback(
        (values: IntegrationEmailGetAllResponseItem) => {
            if (values.status === IntegrationStatus.Connected) {
                return void disconnect({ pathData: { id: values.id }, body: { id: values.id } });
            }

            if (values.id) {
                return void updateAsConnect({
                    pathData: { id: values.id },
                    body: values,
                });
            }

            createAsConnect({ body: values });
        },
        [createAsConnect, disconnect, updateAsConnect]
    );

    const isConnecting = useMemo(() => status === IntegrationStatus.Connected, [status]);

    return (
        <>
            {isPending && <Spin fullscreen />}
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <FormLayout
                        labelSubmitCode={submitText}
                        className='integration-form'
                        alwaysEnableSubmit
                        isDangerSubmit={status === IntegrationStatus.Connected}
                    >
                        <IntegrationServiceField disabled={isConnecting} />

                        <Card size='small' className='body'>
                            <InputField
                                name='configuration.smtpServer'
                                placeholder={translateCodes.PLACEHOLDER_SMTP_SERVER}
                                label={translateCodes.SMTP_SERVER}
                                rules={integrationRules.smtpServer}
                                disabled={isConnecting}
                            />

                            <InputField
                                name='configuration.port'
                                placeholder={translateCodes.PLACEHOLDER_PORT}
                                label={translateCodes.PORT}
                                rules={integrationRules.port}
                                disabled={isConnecting}
                            />

                            <RadioGroupField
                                name='configuration.encryption'
                                label={translateCodes.ENCRYPTION}
                                required
                                disabled={isConnecting}
                            >
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>SSL</Radio>
                                <Radio value={2}>TLS</Radio>
                            </RadioGroupField>
                            <FlexBox gap={12}>
                                <InputField
                                    name='configuration.userName'
                                    placeholder={translateCodes.PLACEHOLDER_USER_NAME}
                                    label={translateCodes.USER_NAME}
                                    rules={integrationRules.userName}
                                    disabled={isConnecting}
                                />
                                <InputField
                                    name='configuration.password'
                                    placeholder={translateCodes.PLACEHOLDER_PASSWORD}
                                    label={translateCodes.PASSWORD}
                                    type='password'
                                    rules={integrationRules.password}
                                    disabled={isConnecting}
                                />
                            </FlexBox>
                            <InputField
                                name='configuration.sendingEmail'
                                placeholder={translateCodes.PLACEHOLDER_EMAIL}
                                label={translateCodes.SENDING_EMAIL}
                                rules={integrationRules.sendingEmail}
                                disabled={isConnecting}
                            />

                            <InputField
                                name='configuration.sendingFrom'
                                placeholder={translateCodes.PLACEHOLDER_SALON_NAME}
                                label={translateCodes.SENDING_FORM}
                                disabled={isConnecting}
                            />
                        </Card>
                    </FormLayout>
                </form>
            </FormProvider>
        </>
    );
};
