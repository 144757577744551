import {
    AppButton,
    Block,
    FlexBox,
    SwitchField,
    Text,
    TimePickerField,
    translateCodes,
} from '@esg/ui';
import React, { useMemo } from 'react';
import { UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { Image } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { BreakTimeGetDetailResponse } from '@esg/business-resource-management';
import { dayjs } from '@esg/shared';
import { ShiftFormValues } from '../Types';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';

interface ShiftDetailProps {
	readonly append: UseFieldArrayAppend<ShiftFormValues, 'breakTimes'>;
}

export const ShiftDetail = ({ append }: ShiftDetailProps) => {
    const {
        watch,
        setValue,
        formState: { defaultValues },
    } = useFormContext<ShiftFormValues>();

    const endTime = watch('endTime');
    const isDayOff = watch('isDayOff');
    const startTime = watch('startTime');

    const { currentDate, id, employeeId } = useMemo(() => defaultValues!, [defaultValues]);

    const { hour, minute } = useMemo(() => {
        const totalMinute = dayjs(endTime).diff(startTime, 'm');

        return {
            hour: Math.floor(totalMinute / 60),
            minute: totalMinute % 60,
        };
    }, [endTime, startTime]);

    return (
        <FlexBox alignItems='center' flex='unset' gap={24}>
            <Block width={136}>
                <SwitchField
                    name='isActive'
                    directionLabel='row-reverse'
                    label={defaultValues?.dayOfWeekTitle}
                    onChange={(value) => {
                        setValue('isDayOff', !value, {
                            shouldDirty: true,
                        });
                    }}
                    defaultChecked={!isDayOff}
                />
            </Block>
            <Block>
                <TimePickerField name='startTime' format='h:mm A' />
            </Block>
            <Block>
                <TimePickerField name='endTime' format='h:mm A' />
            </Block>
            <FlexBox gap={8} flex='unset' width={77}>
                <ClockCircleOutlined />
                <Text>
                    {hour}h {minute ? minute + 'min' : null}
                </Text>
            </FlexBox>
            <AppButton
                type='link'
                translateCode={translateCodes.ADD_BREAK}
                icon={
                    <Image
                        width={20}
                        height={20}
                        preview={false}
                        src={resourceManagementTheme.addIcon}
                    />
                }
                onClick={() => {
                    append({
                        employeeId,
                        shiftId: id,
                        isFixed: false,
                        date: currentDate,
                        endTime: currentDate,
                        startTime: currentDate,
                        dayOfWeek: dayjs(currentDate).day()
                    } as BreakTimeGetDetailResponse);
                }}
            />
        </FlexBox>
    );
};
