import './AbbreviationsNamePaymentMethod.scss';

import React from 'react';

interface abbreviationsNameProps {
    readonly namePayment: string;
}

const renderAbbreviations = (namePayment: string) => {
    const elementNamePayment = namePayment.split(' ');
    let firstNamePayment = '';
    let secondNamePayment = '';
    if(elementNamePayment.length>=2){
        firstNamePayment = elementNamePayment[0].slice(0, 1);
        secondNamePayment = elementNamePayment[1].slice(0, 1);
    }else{
        return elementNamePayment[0].slice(0, 1);
    }
    return firstNamePayment + secondNamePayment;
};

export function AbbreviationsNamePaymentMethod(props: abbreviationsNameProps) {
    return (
        <div className='abbreviations'>
            <a className='abbreviations-text'>{renderAbbreviations(props.namePayment)}</a>
        </div>
    );
}
