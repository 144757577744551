import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface UpdatePackageCategoryRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        description?: string;
    };
}

export interface UpdatePackageCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const updatePackageCategory = httpUtil.createHttpRequestMeta<
    UpdatePackageCategoryRequest,
    UpdatePackageCategoryResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/package-categories/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
