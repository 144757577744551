import { TimeOffType } from '@esg/business-resource-management';

export const timeOffType: Record<TimeOffType, string> = {
    '0': 'Annual leave',
    '1': 'Sick leave',
    '2': 'Training',
    '3': 'No show',
    '4': 'Late for work',
    '5': 'Early leave',
    '6': 'Other',
};
