import { GetDetailSaleResponse } from '@esg/business-pos';
import { FlexBox, FormBase, FormLayoutModal, Spacer, SwitchField, Text, globalModalState, useLocalesFormat } from '@esg/ui';
import { Card, Divider } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import SelectPaymentForm from './SelectPaymentForm';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';

interface ConfirmPaySaleProps {
    readonly onCancel?: () => void;
    readonly onSuccess?: () => void;
    readonly sale: GetDetailSaleResponse;
}

interface ConfirmPaySaleFormValues {
    readonly isPrintBillAfterPayment: boolean;
}

export default function ConfirmPaySale(props: ConfirmPaySaleProps) {
    const { sale } = props;
    const {t}= useTranslation();
    const { formatCurrency } = useLocalesFormat();

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);

    const tipAmount = saleHelper.calcTipAmount(sale!) ?? 0;
    const taxAmount = saleHelper.calcTaxAmount(sale.saleDetails ?? []) ?? 0;

    const submit = async (values: ConfirmPaySaleFormValues) => {
        setGlobalModal({
            ...globalModal,
            titleTransCode: <FlexBox direction='column'>
                <Text fontWeight={700} fontSize={18}>{t(translateCodes.SELECT_PAYMENT_METHOD)}</Text>
                <Text fontWeight={400} fontSize={14} color='#475467'>{t(translateCodes.SELECT_PAYMENT_METHOD_CONTINUE) }</Text>
            </FlexBox>,
            isOpen: true,
            content: <SelectPaymentForm sale={sale} isPrintBillAfterPayment={values.isPrintBillAfterPayment} />,
        });
    };

    return (
        <FormBase onSubmit={submit}>
            <FormLayoutModal
                useSubmitButton={true}
                onCancel={props.onCancel}
                okTextCode={translateCodes.PROCEED}
                alwaysEnableSubmit
            >
                <Card>
                    <FlexBox direction='column' gap={16}>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.SUB_TOTAL)}</Text>
                            <Text fontWeight={600}>{formatCurrency(sale.subTotal)}</Text>
                        </FlexBox>
                        {/* <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.PROMOTIONS)}</Text>
                            <Text fontWeight={600}>$0</Text>
                        </FlexBox> */}
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.TAX)}</Text>
                            <Text fontWeight={600}>{formatCurrency(taxAmount)}</Text>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.TIP)}</Text>
                            <Text fontWeight={600}>{formatCurrency(tipAmount)}</Text>
                        </FlexBox>
                    </FlexBox>
                    <Divider />
                    <FlexBox justifyContent='space-between'>
                        <Text fontWeight={700} fontSize={16}>{t(translateCodes.TOTAL_AMOUNT)}</Text>
                        <Text fontWeight={700} fontSize={16}>{formatCurrency(sale.totalAmount)}</Text>
                    </FlexBox>
                </Card>
                <Spacer type='margin' top={8} />
                <SwitchField
                    justifyContent='center'
                    name='isPrintBillAfterPayment'
                    description={t(translateCodes.PRINT_BILL_AFTER_PAYMENT)}
                    directionLabel='row-reverse'
                />
            </FormLayoutModal>
        </FormBase>
    );
}
