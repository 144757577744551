import { connectToken } from '@esg/business-account';
import { Block, ExternalLink, useHttpCommand } from '@esg/ui';
import { Alert } from 'antd';
import React, { useEffect } from 'react';

import { useLoginStore } from '@esg/ui/hooks/useExistingLogin';
import { convertOpenIdResponse } from '@esg/auth';
import { HOME_URL, env } from '@/configs';

export const LoginCallback = () => {
    const {
        setData: saveLogin
    } = useLoginStore();
    
    const { mutateAsync: login, error } = useHttpCommand(connectToken);

    const authorizationCode = React.useMemo(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get('code');
    }, []);

    const doLogin = React.useCallback(async () => {
        const response = await login({
            contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
            body: {
                grant_type: 'authorization_code',
                code: authorizationCode,
                redirect_uri: env.MANAGEMENT_LOGIN_CALLBACK_PAGE,
                client_id: env.MANAGEMENT_CLIENT_ID
            }
        });
        const loginStateValue = convertOpenIdResponse(response);
        saveLogin(loginStateValue);
        window.location.href = HOME_URL;
    }, [authorizationCode, login, saveLogin]);

    useEffect(() => {
        if (!authorizationCode) {
            return;
        }

        doLogin();
    }, [authorizationCode, doLogin]);

    if (!authorizationCode) {
        return (
            <Block>
                <Alert
                    message='Error'
                    description='Authorization code not found'
                    type='error'
                    showIcon
                />
            </Block>
        );
    }

    if (error) {
        return (
            <Block>
                <Alert
                    message='Error'
                    description={(
                        <Block>
                            {error.message}
                            &nbsp;
                            <ExternalLink href={`${env.IDENTITY_LOGIN_PAGE}`}>Back to login</ExternalLink>
                        </Block>
                    )}
                    type='error'
                    showIcon
                />
            </Block>
        );
    }

    return (
        <Block />
    );
};