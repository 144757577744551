import { IconWarning } from '@esg/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRealtimeContext } from '../../RealtimeContext';
import { RealtimeAppointmentData, RealtimeData } from '../../Types';
import { getAptNotifyResource, getNotifyDesc } from '../realtimeUtils';
import { AppointmentNotify } from '../../children/AppointmentNotify';
import { useFetchAppointment } from './useFetchAppointment';
import { appointmentEvents } from '@/event';

export const useAppointmentNotify = () => {
    const { t } = useTranslation();
    const fetchAppointment = useFetchAppointment();
    const { notify, aptSetting } = useRealtimeContext();

    const handleAppointmentNotify = useCallback(
        async (data: RealtimeData) => {
            const { appointmentId } = data.data as RealtimeAppointmentData;

            const appointment = await fetchAppointment(appointmentId);

            const { status, serviceName, customerName, appointmentDuration } = getAptNotifyResource(
                data,
                appointment
            );

            const desc = t(getNotifyDesc(status), {
                serviceName,
                customerName,
                appointmentDuration,
                noShowAfter: aptSetting?.autoNoShowAfterTime ?? 5,
            });

            notify.open({
                message: (
                    <span>
                        <IconWarning />
                    </span>
                ),
                description: (
                    <AppointmentNotify
                        desc={desc}
                        status={status}
                        notify={notify}
                        appointment={appointment}
                    />
                ),
                duration: 10,
                placement: 'bottomRight',
                style: {
                    width: 367,
                },
            });

            if (status !== 'cancel') {
                appointmentEvents.refetchAppointments.emit();
            }
        },
        [aptSetting?.autoNoShowAfterTime, fetchAppointment, notify, t]
    );

    return handleAppointmentNotify;
};
