import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdatePaymentMethodRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        isActive: boolean;
    };
}

export interface UpdatePaymentMethodResponse {
    id: string;
    name: string;
    isActive: boolean;
}

export const updatePaymentMethod = httpUtil.createHttpRequestMeta<
UpdatePaymentMethodRequest,
UpdatePaymentMethodResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/payment-method-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
