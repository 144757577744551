import {
    DatePickerField,
    FlexBox,
    FormLayoutModal,
    globalModalState,
    SelectField,
    TimePickerField,
} from '@esg/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { timeOffTypeOptions } from '@esg/business-resource-management';
import { useResetRecoilState } from 'recoil';
import { TimeOffFormValues } from './Types';
import { TimeOffRepeat } from './children/TimeOffRepeat';
import { useTimeOffCreate , useTimeOffResource } from './@share/hooks';

import { TimeOffTotal } from './children/TimeOffTotal';
import { translateCodes } from '@/locales';
import './TimeOffForm.scss';

export const TimeOffFormControl = () => {
    const { employees } = useTimeOffResource();
    const hideModal = useResetRecoilState(globalModalState);
    const { handleCreate, isCreating } = useTimeOffCreate();
    const { handleSubmit } = useFormContext<TimeOffFormValues>();

    return (
        <form onSubmit={handleSubmit(handleCreate)}>
            <FormLayoutModal
                useSubmitButton
                alwaysEnableSubmit
                onCancel={hideModal}
                className='time-off-control'
                isLoadingButton={isCreating}
                okTextCode={translateCodes.CREATE}
            >
                <FlexBox gap={20}>
                    <SelectField
                        disabled
                        name='employeeId'
                        dataSource={employees}
                        dataLabelKey='fullName'
                        label={translateCodes.TEAM_MEMBER}
                    />
                    <SelectField
                        name='type'
                        label={translateCodes.TYPE}
                        options={timeOffTypeOptions}
                    />
                </FlexBox>
                <FlexBox gap={20}>
                    <DatePickerField
                        disabled
                        name='date'
                        allowClear={false}
                        label={translateCodes.DATE}
                    />
                    <FlexBox width='100%' flex='unset' gap={12}>
                        <TimePickerField
                            disabled
                            format='h:mm A'
                            className='time'
                            name='startTime'
                            label='Start time'
                        />
                        <TimePickerField
                            disabled
                            name='endTime'
                            format='h:mm A'
                            label='End time'
                            className='time'
                        />
                    </FlexBox>
                </FlexBox>
                <TimeOffRepeat />
                <TimeOffTotal/>
            </FormLayoutModal>
        </form>
    );
};
