import { PaginationResponse, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface TaxRequest {
    readonly body: {
        readonly branchId: string;
    };
}
export interface GetAllTaxResponseItem {
    name: string;
    branchId: string;
    value: number;
    descriptions: string;
    id: string;
}

export const taxGetAll = httpUtil.createHttpRequestMeta<
    TaxRequest,
    PaginationResponse<GetAllTaxResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/TaxSettings',
    method: 'GET',
    authentication: 'bearer',
});
