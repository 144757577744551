import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { GetDetailSaleResponse } from './getDetailSale';

interface CreateSaleRequest extends HttpRequestData {
    body: {
        readonly branchId?: string;
        readonly id?: string;
        readonly productId?: string;
    }
}

export const resellSale = httpUtil.createHttpRequestMeta<
    CreateSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales/resell',
    method: 'POST',
    authentication: 'bearer',
});
