import { AppointmentStatus as Status } from '@esg/shared';

export interface NextStep {
	readonly value: Status | 'view_sale';
	readonly label: string;
}

export interface IStatusType {
	color: string;
	more?: Status[];
	nextStep?: NextStep;
	currentStep: string;
}

export const aptStatusInfos: Record<Status, IStatusType> = {
    '0': {
        color: '#079455',
        currentStep: 'Confirm',
        more: [Status.Booked, Status.NoShow, Status.Cancelled],
        nextStep: { label: 'Check in', value: Status.CheckedIn },
    },
    '1': {
        color: '#E59C30',
        currentStep: 'Booked',
        more: [Status.NoShow, Status.Cancelled],
        nextStep: { label: 'Confirm', value: Status.Confirmed },
    },
    '2': {
        color: '#0C6FF3',
        currentStep: 'Checked In',
        nextStep: { label: 'Check out', value: Status.Completed },
        more: [Status.Booked, Status.Confirmed, Status.NoShow, Status.Cancelled],
    },
    '3': {
        color: '#475467',
        currentStep: 'Completed',
        nextStep: { label: 'View sale', value: 'view_sale' },
    },
    '4': {
        color: '#D92D20',
        currentStep: 'No show',
        more: [Status.Cancelled],
        nextStep: { label: 'Undo no show', value: Status.Booked },
    },
    '5': {
        color: '#DC6803',
        currentStep: 'Cancel',
        nextStep: { label: 'Undo canceled', value: Status.Booked },
    },
};
