import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { GetDetailSaleResponse } from './getDetailSale';

interface CreateSaleRequest extends HttpRequestData {
    body: {
        readonly branchId?: string;
        readonly customerId?: string;
    }
}

export const createSale = httpUtil.createHttpRequestMeta<
    CreateSaleRequest,
    GetDetailSaleResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales',
    method: 'POST',
    authentication: 'bearer',
});
