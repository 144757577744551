export enum JOB_STATUS {
	ACTIVE = 0,
	SEPARATED = 1,
}

export const jobStatusUtil = (value?: number) => {
    switch (value) {
    case JOB_STATUS.ACTIVE:
        return 'Active';
    case JOB_STATUS.SEPARATED:
        return 'Separated';
    default:
        return null;
    }
};
