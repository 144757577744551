import './SaleAnalyzerAppointment.scss';

import React, { useState } from 'react';
import { Dayjs } from '@esg/shared';
import SaleAnalyserAppointmentHeader from './children/SaleAnalyserAppointmentHeader';
import AnalyserAppointmentOverview from './children/AnalyserAppointmentOverview';
import AnalyserAppointmentChart from './children/AnalyserAppointmentChart';
import AnalyserAppointmentTabs from './children/AnalyserAppointmentTabs';
import { PageLayout } from '@/layouts';
import { dateHelper } from '@/helpers';

export function SaleAnalyzerAppointment() {
    const {startOfMonth, endOfMonth} = dateHelper.getStartAndEndOfMonth();
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([startOfMonth, endOfMonth]);

    return (
        <PageLayout
            pageTitleTransCode='Appointment report'
            className='sale-analyzer-appointment'
            rightHeaderPage={<SaleAnalyserAppointmentHeader 
                dateRange={dateRange} 
                setDateRange={(dates)=>{
                    setDateRange([dates[0].startOf('day'), dates[1].endOf('day')]);
                }}/>}
        >
            <AnalyserAppointmentOverview dateRange={dateRange}/>
            <AnalyserAppointmentChart dateRange={dateRange}/>
            <AnalyserAppointmentTabs dateRange={dateRange}/>
        </PageLayout>
    );
}
