import { RealtimeKeys } from '@esg/shared';
import {useCallback} from 'react';
import { RealtimeData } from '../../Types';
import { useAppointmentNotify } from './useAppointmentNotify';
import { appointmentEvents } from '@/event';

export const useAppointmentRealtime = () => {
    const handleAppointmentNotify = useAppointmentNotify();

    const handleAppointmentRealtime = useCallback(
        (data: RealtimeData) => {
            switch (data.key) {
            case RealtimeKeys.AppointmentRefetch:
                appointmentEvents.refetchAppointments.emit();
                break;

            default:
                handleAppointmentNotify(data);
                break;
            }
        },
        [handleAppointmentNotify]
    );

    return handleAppointmentRealtime;
};