import { Block, FlexBox, Spacer, Text, useLocalesFormat } from '@esg/ui';
import { Image } from 'antd';
import React, {useMemo} from 'react';

import { dayjs } from '@esg/shared';
import { useShiftContext } from '../../../ShiftContext';
import { resourceManagementTheme } from '@/pages/resource-management/@shared';

export function TimelineSelector() {
    const { setQuery, query: {endDate, startDate} } = useShiftContext();
    const { formatDate } = useLocalesFormat();

    const isThisMonth = useMemo(() => dayjs().isSame(endDate, 'month'), [endDate]);

    return (
        <FlexBox className='shift-change-month'>
            <Block className='arrow-left' width={44}>
                <Spacer type='padding' top={12} bottom={12} left={10} right={10}>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowLeftIcon}
                        width={20}
                        height={20}
                        onClick={() => {
                            const date = dayjs(startDate).add(-1, 'd');
                            setQuery({
                                endDate: date.endOf('month').toISOString(),
                                startDate: date.startOf('month').toISOString(),
                            });
                        }}
                    />
                </Spacer>
            </Block>
            <Block>
                <Spacer type='padding' left={16} right={16} top={10}>
                    <Text whiteSpace='nowrap'>{isThisMonth ? 'This Month' : formatDate(startDate, 'MMM YYYY')}</Text>
                </Spacer>
            </Block>
            <Block className='arrow-right' width={44}>
                <Spacer type='padding' top={12} bottom={12} left={10} right={10}>
                    <Image
                        preview={false}
                        src={resourceManagementTheme.arrowRightIcon}
                        width={20}
                        height={20}
                        onClick={() => {
                            const date = dayjs(endDate).add(1, 'd');
                            setQuery({
                                endDate: date.endOf('month').toISOString(),
                                startDate: date.startOf('month').toISOString(),
                            });
                        }}
                    />
                </Spacer>
            </Block>
        </FlexBox>
    );
}
