import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateJobTypeRequest extends HttpRequestData {
	readonly body: {
		id?: string;
		name: string;
	};
}

export interface UpdateJobTypeResponse {
	readonly id: string;
	name: string;
}

export const updateJobType = httpUtil.createHttpRequestMeta<
	UpdateJobTypeRequest,
	UpdateJobTypeResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/job-types/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
