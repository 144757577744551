import React from 'react';
import { AppButton, Block, FlexBox, Header, IconClock, IconMail, IconPhone, IconUserCheck, Spacer, Text, globalModalState, useHttpCommand , managementProfileState, useLocalesFormat } from '@esg/ui';
import { Avatar, Card } from 'antd';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { updateCurrentProfile } from '@esg/business-account';
import { UserOutlined } from '@ant-design/icons';
import {  ConnectSocials } from './children';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { ProfileForm, UpdatePasswordForm } from '@/components';
import './Profile.scss';

export const Profile = () => {
    const { formatDate } = useLocalesFormat();

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const [currentProfile, setCurrentProfile] = useRecoilState(managementProfileState);

    const {mutateAsync, isPending} = useHttpCommand(updateCurrentProfile, {
        onSuccess: (response) => {
            setCurrentProfile({
                ...currentProfile!,
                ...response
            });
            resetModalState();
        }
    });

    const infos = React.useMemo(() => [
        {
            icon: <IconUserCheck />,
            label: currentProfile?.role.name || '_',
        },
        {
            icon: <IconMail />,
            label: currentProfile?.email || '_',
        },
        {
            icon: <IconPhone />,
            label: currentProfile?.phoneNumber || '_',
        },
        {
            icon: <IconClock />,
            label: currentProfile?.createdAt ? `Created at ${formatDate(currentProfile?.createdAt)}` : '_',
        },
    ], [currentProfile?.createdAt, currentProfile?.email, currentProfile?.phoneNumber, currentProfile?.role.name, formatDate]);

    const openEditProfileModal = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.EDIT_PROFILE,
            content: (
                <ProfileForm 
                    onSubmit={(value) =>{
                        mutateAsync({
                            body: {
                                ...value,
                                branchActive: value.branchActive
                            }
                        });
                    }}
                    isLoading={isPending}
                    defaultValues={currentProfile!}
                />
            ),
        });
    };

    const isUpdatePassword = currentProfile?.loginMethods.some(o => o ==='local');

    const openChangePasswordModal = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: isUpdatePassword ? translateCodes.UPDATE_PASSWORD : translateCodes.CREATE_PASSWORD,
            content: (
                <UpdatePasswordForm 
                    isUpdatePassword={isUpdatePassword!}
                />
            ),
        });
    };

    return (
        <PageLayout>
            <FlexBox justifyContent='center'>
                <Block width='35%' className='profile-button'>
                    <Header level={2} fontWeight={700} translateCode={translateCodes.PROFILE_DETAILS}  />
                    <Spacer type='margin' bottom={12} top={24} >
                        <Text 
                            fontSize={16} 
                            fontWeight={700} 
                            lineHeight='24px' 
                            translateCode={translateCodes.GENERAL_INFORMATION} 
                        />
                    </Spacer>
                    <Card bordered={false} >
                        <FlexBox direction='column' gap={16}>
                            <FlexBox justifyContent='center' alignItems='center' >
                                <Avatar
                                    style={{ backgroundColor: '#EAECF0' , color: '#0B5DCC'}} 
                                    size={100} 
                                    src={currentProfile?.avatar}
                                    icon={!currentProfile?.avatar ? <UserOutlined />: undefined}
                                />
                            </FlexBox>
                            <FlexBox direction='column' gap={16}>
                                {infos.map((info, index) => (
                                    <FlexBox key={index} alignItems='center' gap={8}>
                                        {info.icon}
                                        <Text translateCode={info.label} />
                                    </FlexBox>
                                ))}
                            </FlexBox>
                            <Spacer type='margin' top={8}>
                                <FlexBox justifyContent='center' gap={16} flexWrap='wrap'>
                                    <AppButton 
                                        width='calc(50% - 8px)' 
                                        onClick={openChangePasswordModal} 
                                        translateCode={isUpdatePassword ? translateCodes.UPDATE_PASSWORD : translateCodes.CREATE_PASSWORD}
                                    />
                                    <AppButton 
                                        width='calc(50% - 8px)' 
                                        onClick={openEditProfileModal} 
                                        type='primary' ghost  
                                        translateCode={translateCodes.EDIT_PROFILE} 
                                    />
                                </FlexBox>
                            </Spacer>
                        </FlexBox>
                    </Card>
                    <ConnectSocials/>
                </Block>
            </FlexBox>
        </PageLayout>
    );
};

