import { SchedulerItem, Scheduler } from '@esg/ui/components/schedulers';
import {
    SchedulerRowInfo,
    FlexBox,
    globalModalState,
    Spacer,
    Paragraph,
    useHttpCommand,
    Text,
} from '@esg/ui';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { schedulerApis } from '@esg/business-schedule';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ScheduleAppointment, SchedulerItemInfo } from '../Types';
import { useSchedulerContext } from '../@share/SchedulerContext';
import { ScheduleAppointmentItem } from './components';
import { translateCodes } from '@/locales';

interface RowInfoData {
	name: string;
}

const renderRowInfo = (rowInfo: SchedulerRowInfo<RowInfoData>) => (
    <FlexBox position='absolute' height='100%' width='100%' preset='row-center'>
        <Text maxWidth={100} fontWeight='bold' textAlign='center'>
            {rowInfo.data.name}
        </Text>
    </FlexBox>
);

interface SchedulerTableProps {
	readonly schedulerItems: SchedulerItem<SchedulerItemInfo>[];
	readonly loading?: boolean;
	readonly refetch: () => void;
}

export const SchedulerTimelineByFacility = (props: SchedulerTableProps) => {
    const { branchWorkingTime, facilities, loading, appointmentSetting } = useSchedulerContext();
    const { t } = useTranslation();
    const [, showDropConfirm] = useRecoilState(globalModalState);
    const hideDropConfirm = useResetRecoilState(globalModalState);
    const { schedulerItems, refetch } = props;

    const { mutateAsync: updateAppointmentServiceDetail } = useHttpCommand(
        schedulerApis.updateAppointmentServiceDetail,
        {
            onSuccess() {
                refetch();
                hideDropConfirm();
            },
        }
    );

    const renderItem = React.useCallback(
        (item: SchedulerItem<ScheduleAppointment>) => (
            <ScheduleAppointmentItem scheduleAppointment={item} refetch={refetch} />
        ),
        [refetch]
    );

    const rowInfos = React.useMemo(() => {
        return facilities.map((facility) => {
            return {
                key: facility.id,
                allowOverlap: true,
                data: {
                    name: facility.name,
                },
            } as SchedulerRowInfo<RowInfoData>;
        });
    }, [facilities]);

    const dropConfirm = React.useCallback(
        (
            item: SchedulerItem<ScheduleAppointment>,
            rowInfo: SchedulerRowInfo<RowInfoData>,
            onOk: () => void
        ) => {
            showDropConfirm({
                content: (
                    <Spacer type='padding' size='lg'>
                        <Paragraph>
							Are you sure you want to move{' '}
                            {item.data.values.service.name} for{' '}
                            {rowInfo.data.name}
                        </Paragraph>
                    </Spacer>
                ),
                titleTransCode: 'Update appointment',
                isOpen: true,
                onOk: onOk,
            });
        },
        [showDropConfirm]
    );

    const onDrop = useCallback(
        (item: SchedulerItem<ScheduleAppointment>, rowInfo: SchedulerRowInfo) => {
            const serviceIds = facilities.find((f) => f.id === rowInfo.key)!.serviceFacilities?.map(s=>s.serviceId) || [];

            const serviceId = item.data.values.service.id;

            if (!serviceIds.includes(serviceId)) {
                message.warning({
                    content: `${rowInfo.data.name} ${t(
                        translateCodes.DOES_NOT_PROVIDER_THIS_SERVICE
                    )}`,
                    style: {
                        marginTop: '15vh',
                    },
                });
                return;
            }

            dropConfirm(item, rowInfo, () => {
                const data = item.data.values;
                updateAppointmentServiceDetail({
                    pathData: {
                        id: data.id,
                    },
                    body: {
                        id: data.id,
                        serviceId: data.service.id,
                        facilityId: rowInfo.key,
                    },
                });
            });
        },
        [dropConfirm, facilities, t, updateAppointmentServiceDetail]
    );

    const handleCreateAppointment = React.useCallback((rowKey: string, date: string) => {
        const appointmentBtn = document.getElementById('appointment-form-btn')!;

        appointmentBtn.setAttribute('data-facility-id', rowKey);
        appointmentBtn.setAttribute('data-date', date);

        appointmentBtn.click();
    }, []);

    return (
        <Scheduler
            layout='horizontal'
            minutesPerCell={appointmentSetting?.timeSlotDuration ?? 15}
            columns={[]}
            rowInfos={rowInfos}
            items={schedulerItems}
            startTime={branchWorkingTime[0]}
            endTime={branchWorkingTime[1]}
            baseColumnWidth={100}
            baseRowHeight={100}
            renderRowInfo={renderRowInfo}
            renderItem={renderItem}
            renderTimeMaker={true}
            loading={loading}
            onDrop={onDrop}
            onCreate={handleCreateAppointment}
        />
    );
};
