import { useEffect } from 'react';
import { useSchedulerContext } from '../SchedulerContext';
import { breakTimeEvents } from '@/event/breakTimeEvents';

export const useBreakTimeEvent = () => {
    const { refetchShifts } = useSchedulerContext();
    useEffect(() => {
        const listen = breakTimeEvents.refetchBreakTime.listen(() => {
            refetchShifts();
        });

        return () => {
            listen();
        };
    }, [refetchShifts]);
};