import React, { useState } from 'react';
import { Avatar, Form} from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Block, FlexBox} from '@esg/ui';
import { Controller } from 'react-hook-form';
import { t } from 'i18next';
import { FileUpload } from '@esg/business-upload';
import { FormConnector } from '../inputs/FormConnector';
import { IconCamera } from '../icons/IconCamera';
import './AvatarUpload.scss';
import { UploadImage } from '../upload';

interface AvatarUploadProps {
    readonly translateCode?: string;
    readonly name: string;
    readonly disabled?: boolean;
    readonly shape?: 'circle' | 'square';
    readonly icon?: React.ReactNode;
}

export const AvatarUpload = (props: AvatarUploadProps) => {
    const { name } = props;
    const [loading, setLoading] = useState(false);

    const onRender = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (renderProps: any) => {
            const { onChange, value } = renderProps.field;
            const { error } = renderProps.fieldState;
            const handleChange = (v :FileUpload) =>{
                onChange(v.uri?? '');
            };

            return (
                <Form.Item
                    validateStatus={error ? 'error' : undefined}
                    help={error?.message && t(error?.message)}
                >
                    <FlexBox direction='column' alignItems='center' gap={8}>
                        <UploadImage 
                            name={name}
                            onChange={handleChange}
                            setLoading={setLoading}
                            disabled={props.disabled}
                            className='avatar-uploader'
                        >
                            <FlexBox className='avatar-wrapper'>
                                <Avatar
                                    shape={props.shape} 
                                    size={100} 
                                    style={{ backgroundColor: '#EAECF0' , color: '#0B5DCC'}} 
                                    src={value}
                                    icon={!loading ? props.icon: undefined}
                                >
                                    <LoadingOutlined />
                                </Avatar>
                                <Block className={`camera ${props.disabled ? 'hidden' :''}`}>
                                    <IconCamera />
                                </Block>
                            </FlexBox>
                        </UploadImage>
                    </FlexBox>
                </Form.Item>
            );
        },
        [loading, name, props.disabled, props.icon, props.shape]
    );

    return (
        <FormConnector>
            {(form) => {
                const { control } = form;
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={onRender}
                    />
                );
            }}
        </FormConnector>
    );
};

AvatarUpload.defaultProps = {
    shape: 'circle',
    icon: <UserOutlined />,
};

