import { FlexBox, FormBase, FormLayoutModal, useHttpCommand, Text, InputField } from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllCustomerSourceResponseItem, customerApi } from '@esg/business-crm';
import { Switch } from 'antd';
import { translateCodes } from '@/locales';

interface CreateSourceFormProps {
    readonly onCancel?: () => void;
    readonly onSuccess?: (response?: GetAllCustomerSourceResponseItem) => void;
    readonly source?: GetAllCustomerSourceResponseItem;
    readonly activeDefault?: boolean;
}

export default function CreateSourceForm(props: CreateSourceFormProps) {
    const { t } = useTranslation();
    const isUpdate = !!props.source?.id;
    const { onSuccess } = props;

    const { mutateAsync: createCustomerSource, isPending: isLoadingCreate } = useHttpCommand(
        customerApi.createCustomerSource, { onSuccess }
    );

    const { mutateAsync: updateCustomerSource, isPending: isLoadingUpdate } = useHttpCommand(
        customerApi.updateCustomerSource, { onSuccess }
    );

    const submit = async (values: GetAllCustomerSourceResponseItem) => {
        if(isUpdate) {
            return  await updateCustomerSource({
                pathData: { id: props.source?.id },
                body: values,
            });
        }
        
        await createCustomerSource({ body: props.activeDefault ? { ...values, active: true } : values });
    };

    return (
        <FormBase onSubmit={submit} defaultValues={props.source}>
            {(form) => (
                <FormLayoutModal
                    isLoadingButton={isLoadingCreate || isLoadingUpdate}
                    useSubmitButton={true}
                    onCancel={props.onCancel}
                    okTextCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                >
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            name='name'
                            label={t(translateCodes.SOURCE_NAME)}
                            placeholder='eg: Facebook, Instagram...'
                            rules={{required: translateCodes.SOURCE_NAME_REQUIRED}}
                        />
                        {!props.activeDefault && <FlexBox gap={8} alignItems='center'>
                            <Switch
                                defaultChecked={props.source?.active}
                                onChange={(value) => form.setValue('active', value)}
                            />
                            <Text translateCode={translateCodes.ACTIVE} />
                        </FlexBox>}
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
