import { deleteUser, getAllUser, GetUserDetailResponse, UserBranch } from '@esg/business-account';
import { ActionsColumn, FlexBox, globalDrawerState, globalModalState, IconClock, IconPen, IconTrash, TableCustom, Text, useHttpCommand, useHttpQuery, usePagination } from '@esg/ui';
import { Avatar, TableColumnsType } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { UserDeleteConfirm } from './UserDeleteConfirm';
import { translateCodes } from '@/locales';
import { UserForm } from '@/components';

export const UserTable = () => {
    const [queryParams, setQueryParams] = usePagination({
        orderBy: undefined,
    });

    const {t} = useTranslation();

    const [, setGlobalModal] = useRecoilState(globalModalState);
    const [, setGlobalDrawer] = useRecoilState(globalDrawerState);

    const resetGlobalModal = useResetRecoilState(globalModalState);
    const resetGlobalDrawer = useResetRecoilState(globalDrawerState);

    const {data: usersResponse, isFetching, refetch} = useHttpQuery(getAllUser, {
        query: queryParams,
    });

    const {mutateAsync: deleteUserMutate} = useHttpCommand(deleteUser, {
        onSuccess() {
            refetch();
            resetGlobalModal();
            resetGlobalDrawer();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_ACC_03),
            });
        }
    });

    const handleDeleteUser = React.useCallback((user: GetUserDetailResponse) => {
        setGlobalModal({
            isOpen: true,
            showModalDelete: true,
            content: <UserDeleteConfirm user={user} />,
            onOk: async () => await deleteUserMutate({pathData: {id: user.id}}),
        });
    }, [deleteUserMutate, setGlobalModal]);
    
    const handleUpdateUser = React.useCallback((user: GetUserDetailResponse) => {
        setGlobalDrawer({
            isOpen: true,
            titleTransCode: translateCodes.EDIT_USER,
            content: <UserForm 
                defaultValues={user}
                onDelete={() => handleDeleteUser(user)}
                onSuccess={() => {
                    resetGlobalDrawer();
                    refetch();
                    viewRequests.showNotification.send({
                        type: 'success',
                        message: t(translateCodes.NM_ACC_02),
                    });
                }}
            />,
        });
    }, [handleDeleteUser, refetch, resetGlobalDrawer, setGlobalDrawer, t]);

    const columns = useMemo<TableColumnsType<GetUserDetailResponse>>(() => [
        {
            key: 'code',
            title: t(translateCodes.ID),
            dataIndex: 'code',
        },
        {
            key: 'fullName',
            title: t(translateCodes.NAME),
            dataIndex: 'fullName',
            render: (fullName, record) => UserTable.renderColumnAvatar(fullName, record),
        },
        {
            key: 'phoneNumber',
            title: t(translateCodes.PHONE_NUMBER),
            dataIndex: 'phoneNumber',
        },
        {
            key: 'email',
            title: t(translateCodes.EMAIL),
            dataIndex: 'email',
        },
        {
            key: 'role',
            title: t(translateCodes.ROLE),
            dataIndex: 'role',
            render: (role) => role?.name,
        },
        {
            key: 'location',
            title: t(translateCodes.LOCATION),
            dataIndex: 'userBranches',
            render: (userBranches) => userBranches?.map((userBranch: UserBranch) => userBranch.branch?.name).join(', '),
        },
        {
            key: 'action',
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                if(record.role?.isSystem) {
                    return;
                }

                return (
                    <ActionsColumn
                        actions={[
                            {
                                icon: <IconPen />,
                                onClick: () => handleUpdateUser(record),
                                tooltipTitle: t(translateCodes.EDIT),
                            },
                            {
                                icon: <IconTrash />,
                                onClick: () => handleDeleteUser(record),
                                tooltipTitle: t(translateCodes.DELETE),
                            }
                        ]}
                    />
                );
            },
        }
    ],[handleDeleteUser, handleUpdateUser, t]);

    return (
        <TableCustom
            loading={isFetching}
            columns={columns}
            dataSource={usersResponse?.items}
            titleTableTransCode={translateCodes.USER_LIST}
            placeholderSearchTransCode={translateCodes.SEARCH_USER_PLACEHOLDER}
            pageInfo={usersResponse?.meta}
            onPageChange={(page, pageSize) =>
                setQueryParams({ 
                    ...queryParams, 
                    page: page.toString(),
                    pageSize: pageSize?.toString()
                })
            }
            onSearch={(value) =>
                setQueryParams({ ...queryParams, search: value })
            }
        />
    );
};

UserTable.renderColumnAvatar = (fullName: string, record: GetUserDetailResponse) => {

    return <FlexBox gap={12} alignItems='center'>
        <Avatar
            src={record?.avatar}
            style={{ backgroundColor: '#F2F4F7' }}
        >
            {fullName[0]}
        </Avatar>
        <FlexBox direction='column'>
            <Text maxLines={1} maxWidth={220} fontSize={14} lineHeight='20px'>
                {fullName}
            </Text>
            {!record.emailConfirmed && (
                <FlexBox gap={4} alignItems='center'>
                    <IconClock color='#FDB022' width={16} height={16}/>
                    <Text fontSize={12} color='#FDB022' translateCode='Pending Invitation'/>
                </FlexBox>
            )}
        </FlexBox>
    </FlexBox>;
};