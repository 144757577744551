import { SchedulerRowInfo } from '@esg/ui';
import React, {useCallback} from 'react';
import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SchedulerEmployeeRowInfo } from '../../children/SchedulerEmployeeRowInfo';
import { translateCodes } from '@/locales';

export const useSchedulerRenderRowInfo = () => {
    const { t } = useTranslation();

    const renderRowInfo = useCallback(
        (rowInfo: SchedulerRowInfo<SchedulerEmployeeRowInfo>) => {
            const items: MenuProps['items'] = [
                // {
                //     key: '1',
                //     label: <TimeBlock defaultEmployee={rowInfo.key} />,
                // },
                {
                    key: '2',
                    label: (
                        <Link to={`/resource-management/shifts?id=${rowInfo.key}`}>
                            {t(translateCodes.EDIT_SHIFTS)}
                        </Link>
                    ),
                },
                // {
                //     key: '3',
                //     label: (
                //         <span
                //             onClick={() => {
                //                 setModalGlobalState({
                //                     isOpen: true,
                //                     titleTransCode: translateCodes.ADD_TIME_OFF,
                //                     content: (
                //                         <div>
                //                             <TimeOffForm
                //                                 defaultValues={{
                //                                     employeeId: rowInfo.key,
                //                                     type: TimeOffType.AnnualLeave,
                //                                     isRepeat: false,
                //                                     date: currentDate,
                //                                     startTime: dayjs(currentDate)
                //                                         .clone()
                //                                         .hour(9)
                //                                         .minute(0)
                //                                         .toISOString(),
                //                                     endTime: dayjs(currentDate)
                //                                         .clone()
                //                                         .hour(17)
                //                                         .minute(0)
                //                                         .toISOString(),
                //                                 }}
                //                                 onSuccess={() => {
                //                                     refetchAppointment();
                //                                     setModalGlobalState({
                //                                         isOpen: false,
                //                                     });
                //                                 }}
                //                             />
                //                         </div>
                //                     ),
                //                     width: 730,
                //                     footer: null,
                //                 });
                //             }}
                //         >
                //             <Translate translateCode={translateCodes.ADD_TIME_OFF} />
                //         </span>
                //     ),
                // },
                {
                    key: '4',
                    label: (
                        <Link to={`/resource-management/employee-members/${rowInfo.key}`}>
                            {t(translateCodes.VIEW_EMPLOYEE_DETAIL)}
                        </Link>
                    ),
                },
            ];

            return <SchedulerEmployeeRowInfo rowInfo={rowInfo} items={items} />;
        },
        [t]
    );

    return renderRowInfo;
};