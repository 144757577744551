import { Block, FlexBox, Spacer, useLocalesFormat } from '@esg/ui';
import { Card, Divider } from 'antd';
import React from 'react';
import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import { GetOverviewClientDetailResponse } from '@esg/business-report/api/client-detail-report';
import { CustomerInfoFullName } from './CustomerInfoFullName';
import { CustomerOverviewItem } from './CustomerOverviewItem';
import { translateCodes } from '@/locales/translateCodes';

interface CustomerOverviewProps {
    customer?: GetCustomerDetailResponse;
    loading?: boolean;
    reportOverview?: GetOverviewClientDetailResponse
}

export function CustomerOverview({ customer, loading, reportOverview }: CustomerOverviewProps) {
    const { formatCurrency } = useLocalesFormat();
    return (
        <Card  loading={loading} style={{ height:'auto', paddingRight:24, borderRadius: 16 }} >
            <FlexBox alignItems='center' justifyContent='space-between'>
                <Spacer right={24}>
                    <CustomerInfoFullName record={customer as GetAllCustomerResponseItem} />
                </Spacer>
                    
                <Block>
                    <FlexBox direction='row'>
                        <CustomerOverviewItem 
                            title={translateCodes.APPOINTMENTS}
                            value={reportOverview?.totalSale.toString()}
                        />
                        <Spacer width={16} />
                        <Divider style={{height: 48}} type='vertical' />
                        <Spacer width={16} />
                        <CustomerOverviewItem 
                            title={translateCodes.TOTAL_SALE}
                            value={reportOverview?.totalSale.toString()}
                        />
                        <Spacer width={16} />
                        <Divider style={{height: 48}} type='vertical' />
                        <Spacer width={16} />
                        <CustomerOverviewItem 
                            title={translateCodes.REVENUE}
                            value={formatCurrency(reportOverview?.totalRevenue ?? 0)}
                        />
                    </FlexBox>
                </Block>
            </FlexBox>
        </Card>
    );
}
