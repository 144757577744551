import { createTimeOff } from '@esg/business-resource-management';
import { viewRequests } from '@esg/framework';
import { globalModalState, useHttpCommand } from '@esg/ui';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import { TimeOffFormValues } from '../../Types';
import { translateCodes } from '@/locales';
import { shiftEvents } from '@/event';

export const useTimeOffCreate = () => {
    const { t } = useTranslation();
    const hideModal = useResetRecoilState(globalModalState);

    const { mutateAsync: createTimeOffAsync, isPending: isCreating } = useHttpCommand(
        createTimeOff,
        {
            onSuccess() {
                hideModal();
                shiftEvents.refetch.emit();
            },
        }
    );

    const handleCreate = React.useCallback(
        async (values: TimeOffFormValues) => {
            await createTimeOffAsync(
                {
                    body: values,
                },
                {
                    onSuccess(_) {
                        viewRequests.showNotification.send({
                            type: 'success',
                            message: t(translateCodes.NM_SM_02),
                        });
                    },
                }
            );
        },
        [createTimeOffAsync, t]
    );

    return { handleCreate, isCreating };
};
