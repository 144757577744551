import './MainLayout.scss';

import { Block, Spacer } from '@esg/ui';
import { Layout } from 'antd';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { mainLayoutTheme } from './@shared';
import { MainLayoutHeader, MainLayoutSider } from './children';

export function MainLayout() {
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout className='main-layout'>
            <MainLayoutHeader />
            <Layout className='main-layout-content'>
                <MainLayoutSider
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
                <Layout.Content style={{marginTop: -mainLayoutTheme.headerHeight}}>
                    <Block width='100%'>
                        <Spacer type='padding' top={mainLayoutTheme.headerHeight} />
                        <Spacer
                            type='margin'
                            left={
                                collapsed
                                    ? mainLayoutTheme.siderCollapsedWidth
                                    : mainLayoutTheme.siderWidth
                            }
                        >
                            <Outlet />
                        </Spacer>
                    </Block>
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
