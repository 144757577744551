import { PaginationResponse, HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetAllAutomationEmailSettingRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllAutomationEmailSettingResponseItem {
    id?: string;
    sendNewAppointment?: boolean;
    sendConfirmAppointment?: boolean;
    sendCancelAppointment?: boolean;
    sendCheckInAppointment?: boolean;
    sendNoShowAppointment?: boolean;
    sendWelcomeAppointment?: boolean;
    sendCloseSaleAppointment?: boolean;
    
}

export const getAllAutomationEmailSetting = httpUtil.createHttpRequestMeta<
    GetAllAutomationEmailSettingRequest,
    PaginationResponse<GetAllAutomationEmailSettingResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/automation-email-settings',
    method: 'GET',
    authentication: 'bearer',
});
