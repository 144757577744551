import { AppButton, DatePickerRange, FlexBox, Spacer } from '@esg/ui';
import React from 'react';
import {Dayjs} from 'dayjs';

interface SaleAnalyserAppointmentHeaderProps {
    dateRange: [Dayjs, Dayjs];
    setDateRange: (dates: [Dayjs, Dayjs]) => void;
}

export default function SaleAnalyserAppointmentHeader(props: SaleAnalyserAppointmentHeaderProps) {
    const {dateRange, setDateRange} = props;

    return (
        <Spacer right={16}>
            <FlexBox justifyContent='flex-end' gap={16}>
                <DatePickerRange
                    onChange={(dates) => setDateRange(dates)}
                    defaultValue={dateRange}
                />
                <AppButton> Print </AppButton>
            </FlexBox>
        </Spacer>
    );
}
