import { AppointmentCreateRequest, AppointmentGetDetailResponse, AppointmentType, schedulerApis } from '@esg/business-schedule';
import { globalDrawerState, useHttpCommand } from '@esg/ui';
import { useResetRecoilState } from 'recoil';
import {useCallback} from 'react';
import { AppointmentStatus } from '@esg/shared';
import { useFormContext } from 'react-hook-form';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { useAppointmentContext } from '../AppointmentFormContext';
import { AppointmentFormValues } from '../../AppointmentFormControl';
import { appointmentEvents } from '@/event';
import { translateCodes } from '@/locales';

export const useAppointmentBusiness = () => {
    const { refetch } = useAppointmentContext();
    const { t } = useTranslation();
    const hideAppointmentForm = useResetRecoilState(globalDrawerState);
    const { handleSubmit, formState: {defaultValues} } = useFormContext<AppointmentFormValues>();

    const { mutateAsync: createAppointment } = useHttpCommand(
        schedulerApis.createAppointment,
        {
            onSuccess() {
                viewRequests.showNotification.send({
                    type: 'success',
                    message: t(translateCodes.NM_SC_01),
                });
                hideAppointmentForm();
                if (refetch) {
                    refetch();
                    return;
                }
                appointmentEvents.refetchAppointments.emit();
            },
        }
    );
    const { mutateAsync: updateAppointment } = useHttpCommand(
        schedulerApis.updateAppointment,
        {
            onSuccess() {
                viewRequests.showNotification.send({
                    type: 'success',
                    message: t(translateCodes.NM_SC_02),
                });
                hideAppointmentForm();
                if (refetch) {
                    refetch();
                    return;
                }
                appointmentEvents.refetchAppointments.emit();
            },
        }
    );

    const beforeSubmit = useCallback(async (values: AppointmentCreateRequest['body']) => {
        return {
            ...values,
            appointmentServices: values.appointmentServices.map((a) => ({
                ...a,
                appointmentServiceDetails: a.appointmentServiceDetails.map((s) => ({
                    ...s,
                    appointmentServiceEmployees: s.appointmentServiceEmployees?.map((st) => ({
                        ...st,
                        timeStartWork: s.startTime,
                        timeEndWork: s.endTime,
                    })),
                })),
            })),
        };
    }, []);

    const handleSubmitApt = useCallback(
        (status?: AppointmentStatus) =>
            handleSubmit(async (values) => {
                const body = await beforeSubmit(values);
                if (defaultValues?.id) {
                    return void (await updateAppointment({
                        pathData: { id: defaultValues.id },
                        body: body as AppointmentGetDetailResponse,
                    },{
                        onSuccess() {
                            body.status == 5 ? 
                                (viewRequests.showNotification.send({
                                    type: 'success',
                                    message: t(translateCodes.NM_SC_07),
                                }))
                                :
                                (viewRequests.showNotification.send({
                                    type: 'success',
                                    message: t(translateCodes.NM_SC_02),
                                }));
                            
                            hideAppointmentForm();
                            if (refetch) {
                                refetch();
                                return;
                            }
                            appointmentEvents.refetchAppointments.emit();
                        },
                    }));
                }

                await createAppointment({
                    body: { ...body, status, appointmentType: AppointmentType.Salon },
                });
            }),
        [beforeSubmit, createAppointment, defaultValues?.id, handleSubmit, hideAppointmentForm, refetch, t, updateAppointment]
    );

    return {
        handleSubmitApt,
    };
};