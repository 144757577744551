import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { GetDetailSaleResponse } from '@esg/business-pos/api/sale';
import { env } from '../../configs';

interface GetRevenueDetailReportRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
        readonly customerId?: string;
    }
}

export const getClientDetailReport = httpUtil.createHttpRequestMeta<
    GetRevenueDetailReportRequest,
	PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-report/detail-sales-by-client',
    authentication: 'bearer'
});
