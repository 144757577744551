import React from 'react';

interface UseTokenValidateOptions {
    readonly token?: string;
    readonly refreshToken?: string;
    readonly expiresAt?: number;
}

type UseTokenValidateResult = true | 'expired' | 'invalid' | 'need-refresh';

export function useTokenValidate() {
    return React.useCallback((options: UseTokenValidateOptions): UseTokenValidateResult => {
        const { token, refreshToken, expiresAt } = options;

        if (!token || !expiresAt) {
            return 'invalid';
        }

        if (Date.now() > expiresAt) {
            if (refreshToken) {
                return 'need-refresh';
            }

            return 'expired';
        }

        return true;
    }, []);
}