export const translateCodes = {
    //#region [common]
    CANCEL: 'CANCEL',
    SAVE: 'SAVE',
    TYPE: 'TYPE',
    DATE: 'DATE',
    EMPLOYEE_TIME: 'EMPLOYEE_TIME',
    END_TIME: 'END_TIME',
    REPEAT: 'REPEAT',
    MONDAY: 'MONDAY',
    MINS: 'MINS',
    WITH: 'WITH',
    SAVE_CHANGE: 'SAVE_CHANGE',
    FROM: 'FROM',
    TO: 'TO',
    ALL: 'ALL',
    SEARCH: 'SEARCH',
    REBOOK: 'REBOOK',
    APT: 'APT',
    ID: 'ID',
    //#endregion [common]

    //#region user and role
    USER_AND_ROLE: 'USER_AND_ROLE',
    ROLE: 'ROLE',
    CREATE_USER: 'CREATE_USER',
    CREATE_ROLE: 'CREATE_ROLE',
    ROLE_LIST: 'ROLE_LIST',
    SEARCH_ROLE_PLACEHOLDER: 'SEARCH_ROLE_PLACEHOLDER',
    NAME_ROLE_REQUIRED: 'NAME_ROLE_REQUIRED',
    ENTER_ROLE_NAME: 'ENTER_ROLE_NAME',
    ENTER_ROLE_DESCRIPTION: 'ENTER_ROLE_DESCRIPTION',
    DELETE_ROLE_MSG: 'DELETE_ROLE_MSG',
    DELETE_ROLE: 'DELETE_ROLE',
    EDIT_ROLE: 'EDIT_ROLE',
    EMAIL_PLACEHOLDER_SHORT: 'EMAIL_PLACEHOLDER_SHORT',
    DELETE_USER: 'DELETE_USER',
    DELETE_USER_MSG: 'DELETE_USER_MSG',
    EDIT_USER: 'EDIT_USER',
    USER_LIST: 'USER_LIST',
    SEARCH_USER_PLACEHOLDER: 'SEARCH_USER_PLACEHOLDER',
    LAST_NAME_MAX_LENGTH: 'LAST_NAME_MAX_LENGTH',
    FIRST_NAME_MAX_LENGTH: 'FIRST_NAME_MAX_LENGTH',
    ROLE_REQUIRED: 'ROLE_REQUIRED',
    LOCATION_REQUIRED: 'LOCATION_REQUIRED',
    CANNOT_DELETE_ROLE_WITH_USERS: 'CANNOT_DELETE_ROLE_WITH_USERS',
    SELECT_LOCATION: 'SELECT_LOCATION',
    SELECT_ROLE: 'SELECT_ROLE',
    ASSIGNED: 'ASSIGNED',
    NM_ACC_01: 'NM_ACC_01',
    NM_ACC_02: 'NM_ACC_02',
    NM_ACC_03: 'NM_ACC_03',
    NM_ACC_04: 'NM_ACC_04',
    NM_ACC_05: 'NM_ACC_05',
    NM_ACC_06: 'NM_ACC_06',
    EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
    //#end region user and role

    THIS_FIELD_IS_REQUIRED: 'THIS_FIELD_IS_REQUIRED',
    ACTIVITY_Log: 'ACTIVITY_Log',
    ACTIVITY_CHECKOUT_SALE: 'ACTIVITY_CHECKOUT_SALE',
    ACTIVITY_ADD_CLIENT: 'ACTIVITY_ADD_CLIENT',
    ACTIVITY_REMOVE_CLIENT: 'ACTIVITY_REMOVE_CLIENT',
    ACTIVITY_REMOVE_TIP: 'ACTIVITY_REMOVE_TIP',
    ACTIVITY_ADD_TIP: 'ACTIVITY_ADD_TIP',
    ACTIVITY_CHANGE_TIP: 'ACTIVITY_CHANGE_TIP',
    ACTIVITY_CHANGE_TIP_UNIT: 'ACTIVITY_CHANGE_TIP_UNIT',
    ACTIVITY_ADD_SALE_DETAIL: 'ACTIVITY_ADD_SALE_DETAIL',
    ACTIVITY_QUANTITY: 'ACTIVITY_QUANTITY',
    ACTIVITY_DELETE_SALE_DETAIL: 'ACTIVITY_DELETE_SALE_DETAIL',
    ACTIVITY_CHANGE_QUANTITY: 'ACTIVITY_CHANGE_QUANTITY',
    ACTIVITY_CHANGE_PRICE: 'ACTIVITY_CHANGE_PRICE',
    ACTIVITY_CHANGE_DISCOUNT: 'ACTIVITY_CHANGE_DISCOUNT',
    ACTIVITY_ADD_EMPLOYEE: 'ACTIVITY_ADD_EMPLOYEE',
    ACTIVITY_REMOVE_EMPLOYEE: 'ACTIVITY_REMOVE_EMPLOYEE',
    ACTIVITY_CHANGE_EMPLOYEE: 'ACTIVITY_CHANGE_EMPLOYEE',
    ACTIVITY_REASON_CANCELLED_SALE: 'ACTIVITY_REASON_CANCELLED_SALE',

    APM_ACTIVITY_ADD_SERVICE: 'APM_ACTIVITY_ADD_SERVICE',
    APM_ACTIVITY_ADD_SERVICE_POSITION: 'APM_ACTIVITY_ADD_SERVICE_POSITION',
    APM_ACTIVITY_ADD_EMPLOYEE: 'APM_ACTIVITY_ADD_EMPLOYEE',
    APM_ACTIVITY_CHANGE_APPOINTMENT_DATE: 'APM_ACTIVITY_CHANGE_APPOINTMENT_DATE',
    APM_ACTIVITY_CHANGE_NUMBER_OF_GUEST: 'APM_ACTIVITY_CHANGE_NUMBER_OF_GUEST',
    APM_ACTIVITY_CHANGE_CUSTOMER: 'APM_ACTIVITY_CHANGE_CUSTOMER',
    APM_ACTIVITY_CHANGE_STATUS: 'APM_ACTIVITY_CHANGE_STATUS',
    APM_ACTIVITY_DELETE_GUEST: 'APM_ACTIVITY_DELETE_GUEST',
    APM_ACTIVITY_CHANGE_SERVICE: 'APM_ACTIVITY_CHANGE_SERVICE',
    APM_ACTIVITY_CHANGE_SERVICE_POSITION: 'APM_ACTIVITY_CHANGE_SERVICE_POSITION',
    APM_ACTIVITY_REMOVE_SERVICE_POSITION: 'APM_ACTIVITY_REMOVE_SERVICE_POSITION',
    APM_ACTIVITY_DELETE_SERVICE: 'APM_ACTIVITY_DELETE_SERVICE',
    APM_ACTIVITY_DELETE_EMPLOYEE: 'APM_ACTIVITY_DELETE_EMPLOYEE',
    APM_ACTIVITY_CHANGE_EMPLOYEE: 'APM_ACTIVITY_CHANGE_EMPLOYEE',

    //#region [Layout]
    PLACE_HOLDER_SEARCH_HEADER: 'PLACE_HOLDER_SEARCH_HEADER',
    CREATE_BILL: 'CREATE_BILL',
    ADD_APPOINTMENT: 'ADD_APPOINTMENT',
    //#endregion [Layout]

    //#region [common]
    DELETE: 'DELETE',
    // CANCEL: 'CANCEL',
    // SAVE: 'SAVE',
    CONFIRM: 'CONFIRM',
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    NAME: 'NAME',
    DESCRIPTION: 'DESCRIPTION',
    CATEGORY: 'CATEGORY',
    CATEGORIES: 'CATEGORIES',
    FACILITY: 'FACILITY',
    EMPLOYEE: 'EMPLOYEE',
    DURATION: 'DURATION',
    PRICE: 'PRICE',
    ACTION: 'ACTION',
    VIEW: 'VIEW',
    UNASSIGNED: 'UNASSIGNED',
    //#endregion [common]

    //#region [rules service]
    SERVICE_REQUIRED: 'SERVICE_REQUIRED',
    NAME_SERVICE_REQUIRED: 'NAME_SERVICE_REQUIRED',
    NAME_SERVICE_MAX_LENGTH: 'NAME_SERVICE_MAX_LENGTH',
    SERVICE_CATEGORY_REQUIRED: 'SERVICE_CATEGORY_REQUIRED',
    DURATION_SERVICE_REQUIRED: 'DURATION_SERVICE_REQUIRED',
    PRICE_SERVICE_REQUIRED: 'PRICE_SERVICE_REQUIRED',
    MINUTE_DURATION: 'MINUTE_DURATION',
    PRICE_SERVICE_INVALID: 'PRICE_SERVICE_INVALID',
    DURATION_SERVICE_INVALID: 'DURATION_SERVICE_INVALID',
    GENERAL_INFO: 'GENERAL_INFO',
    EMPLOYEE_ALLOCATE: 'EMPLOYEE_ALLOCATE',
    GENERAL: 'GENERAL',
    //#endregion [rules service]

    EDIT_TAX: 'EDIT_TAX',

    //#region [page service]
    TITLE_PAGE_SERVICE: 'TITLE_PAGE_SERVICE',
    SERVICE: 'SERVICE',
    TITLE_TABLE_SERVICE: 'TITLE_TABLE_SERVICE',
    PLACE_HOLDER_SEARCH_SERVICE: 'PLACE_HOLDER_SEARCH_SERVICE',
    CREATE_SERVICE: 'CREATE_SERVICE',
    DELETE_SERVICE: 'DELETE_SERVICE',
    DELETE_SERVICE_CONFIRM: 'DELETE_SERVICE_CONFIRM',
    CREATE_SERVICE_CATEGORY: 'CREATE_SERVICE_CATEGORY',
    CREATE_TAX: 'CREATE_TAX',
    ENTER_SERVICE_NAME: 'ENTER_SERVICE_NAME',
    SELECT_CATEGORY: 'SELECT_CATEGORY',
    SERVICE_CATEGORY: 'SERVICE_CATEGORY',
    ENTER_DURATION: 'ENTER_DURATION',
    DURATION_MINUTE: 'DURATION_MINUTE',
    ENTER_PRICE: 'ENTER_PRICE',
    SELECT_TAX: 'SELECT_TAX',
    TAX: 'TAX',
    ENTER_DESCRIPTION: 'ENTER_DESCRIPTION',
    ALLOW_ONLINE_BOOKING: 'ALLOW_ONLINE_BOOKING',
    DOUBLE_APPOINTMENT_MAX_SLOT_MAX_MESSAGE: 'DOUBLE_APPOINTMENT_MAX_SLOT_MAX_MESSAGE',
    BOOKING_DATE_LIMIT_DESCRIPTION: 'BOOKING_DATE_LIMIT_DESCRIPTION',
    ALLOW_ONLINE_BOOKING_DESCRIPTION: 'ALLOW_ONLINE_BOOKING_DESCRIPTION',
    SERVICE_CATEGORY_PLACEHOLDER: 'SERVICE_CATEGORY_PLACEHOLDER',
    HAS_NO_SERVICE: 'HAS_NO_SERVICE',
    SERVICE_LIST: 'SERVICE_LIST',
    //#endregion [page service]

    //#region [page product]
    PRODUCT: 'PRODUCT',
    SELL_BY: 'SELL_BY',
    TITLE_PAGE_PRODUCT: 'TITLE_PAGE_PRODUCT',
    TITLE_TABLE_PRODUCT: 'TITLE_TABLE_PRODUCT',
    PLACE_HOLDER_SEARCH_PRODUCT: 'PLACE_HOLDER_SEARCH_PRODUCT',
    CREATE_PRODUCT: 'CREATE_PRODUCT',
    EDIT_PRODUCT: 'EDIT_PRODUCT',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    DELETE_PRODUCT_CONFIRM: 'DELETE_PRODUCT_CONFIRM',
    ADD_PRODUCT: 'ADD_PRODUCT',
    ADD_PRODUCT_CATEGORY: 'ADD_PRODUCT_CATEGORY',
    EMPTY_PRODUCT_DESCRIPTION: 'EMPTY_PRODUCT_DESCRIPTION',
    EMPTY_PRODUCT_CATEGORY_DESCRIPTION: 'EMPTY_PRODUCT_CATEGORY_DESCRIPTION',

    //#endregion [page product]

    //#region [page package]
    TITLE_PAGE_PACKAGE: 'TITLE_PAGE_PACKAGE',
    TITLE_TABLE_PACKAGE: 'TITLE_TABLE_PACKAGE',
    PLACE_HOLDER_SEARCH_PACKAGE: 'PLACE_HOLDER_SEARCH_PACKAGE',
    CREATE_PACKAGE: 'CREATE_PACKAGE',
    EDIT_PACKAGE: 'EDIT_PACKAGE',
    DELETE_PACKAGE: 'DELETE_PACKAGE',
    DELETE_PACKAGE_CONFIRM: 'DELETE_PACKAGE_CONFIRM',
    //#endregion [page package]

    //#region [page service category]
    EDIT_SERVICE_CATEGORY: 'EDIT_SERVICE_CATEGORY',
    NAME_SERVICE_CATEGORY_REQUIRED: 'NAME_SERVICE_CATEGORY_REQUIRED',
    ENTER_SERVICE_CATEGORY_NAME: 'ENTER_SERVICE_CATEGORY_NAME',
    ENTER_SERVICE_CATEGORY_DESCRIPTION: 'ENTER_SERVICE_CATEGORY_DESCRIPTION',
    CREATE_SERVICE_CATEGORY_BUTTON: 'CREATE_SERVICE_CATEGORY_BUTTON',
    TITLE_FORM_CREATE_SERVICE_CATEGORY: 'TITLE_FORM_CREATE_SERVICE_CATEGORY',
    DELETE_SERVICE_CATEGORY: 'DELETE_SERVICE_CATEGORY',
    DELETE_SERVICE_CATEGORY_CONFIRM: 'DELETE_SERVICE_CATEGORY_CONFIRM',
    SERVICe_CATEGORY_LIST: 'SERVICe_CATEGORY_LIST',
    MAX_LENGTH_NAME: 'MAX_LENGTH_NAME',
    MAX_LENGTH_DESCRIPTION: 'MAX_LENGTH_DESCRIPTION',
    ALLOCATE: 'ALLOCATE',
    ENABLE: 'ENABLE',
    //#endregion [page service category]

    //#region [page product category]
    EDIT_PRODUCT_CATEGORY: 'EDIT_PRODUCT_CATEGORY',
    NAME_PRODUCT_CATEGORY_REQUIRED: 'NAME_PRODUCT_CATEGORY_REQUIRED',
    ENTER_PRODUCT_CATEGORY_NAME: 'ENTER_PRODUCT_CATEGORY_NAME',
    ENTER_PRODUCT_CATEGORY_DESCRIPTION: 'ENTER_PRODUCT_CATEGORY_DESCRIPTION',
    CREATE_PRODUCT_CATEGORY_BUTTON: 'CREATE_PRODUCT_CATEGORY_BUTTON',
    TITLE_FORM_CREATE_PRODUCT_CATEGORY: 'TITLE_FORM_CREATE_PRODUCT_CATEGORY',
    DELETE_PRODUCT_CATEGORY: 'DELETE_PRODUCT_CATEGORY',
    DELETE_PRODUCT_CATEGORY_CONFIRM: 'DELETE_PRODUCT_CATEGORY_CONFIRM',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    PRODUCT_CATEGORY_REQUIRED: 'PRODUCT_CATEGORY_REQUIRED',
    PRODUCT_CATEGORY_PLACEHOLDER: 'PRODUCT_CATEGORY_PLACEHOLDER',
    PRODUCT_CATEGORY_LIST: 'PRODUCT_CATEGORY_LIST',
    PRODUCT_LIST: 'PRODUCT_LIST',
    CREATE_UNIT: 'CREATE_UNIT',
    PRICE_INVALID: 'PRICE_INVALID',
    //#endregion [page product category]

    //#region [page product category]
    EDIT_PACKAGE_CATEGORY: 'EDIT_PACKAGE_CATEGORY',
    NAME_PACKAGE_CATEGORY_REQUIRED: 'NAME_PACKAGE_CATEGORY_REQUIRED',
    ENTER_PACKAGE_CATEGORY_NAME: 'ENTER_PACKAGE_CATEGORY_NAME',
    ENTER_PACKAGE_CATEGORY_DESCRIPTION: 'ENTER_PACKAGE_CATEGORY_DESCRIPTION',
    CREATE_PACKAGE_CATEGORY_BUTTON: 'CREATE_PACKAGE_CATEGORY_BUTTON',
    TITLE_FORM_CREATE_PACKAGE_CATEGORY: 'TITLE_FORM_CREATE_PACKAGE_CATEGORY',
    DELETE_PACKAGE_CATEGORY: 'DELETE_PACKAGE_CATEGORY',
    DELETE_PACKAGE_CATEGORY_CONFIRM: 'DELETE_PACKAGE_CATEGORY_CONFIRM',
    //#endregion [page product category]

    //#region [Employee member route]
    ADD_JOB_TYPE: 'ADD_JOB_TYPE',
    EMPTY_JOB_TYPE_DESCRIPTION: 'EMPTY_JOB_TYPE_DESCRIPTION',
    SEARCH_PLACEHOLDER: 'SEARCH_PLACEHOLDER',
    EMPLOYEE_MEMBERS: 'EMPLOYEE_MEMBERS',
    EMPLOYEE_LIST: 'EMPLOYEE_LIST',
    CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
    ACTIVE: 'ACTIVE',
    SEPARATED: 'SEPARATED',
    EMPLOYEE_ID: 'EMPLOYEE_ID',
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
    CONTACT_INFORMATION: 'CONTACT_INFORMATION',
    JOB_TYPE: 'JOB_TYPE',
    GENERAL_INFORMATION: 'GENERAL_INFORMATION',
    UPLOAD_PHOTO: 'UPLOAD_PHOTO',
    SERVICES: 'SERVICES',
    WORKING_TIME: 'WORKING_TIME',
    FIRST_NAME: 'FIRST_NAME',
    FIRST_NAME_PLACEHOLDER: 'FIRST_NAME_PLACEHOLDER',
    LAST_NAME: 'LAST_NAME',
    LAST_NAME_PLACEHOLDER: 'LAST_NAME_PLACEHOLDER',
    GENDER: 'GENDER',
    GENDER_PLACEHOLDER: 'GENDER_PLACEHOLDER',
    DATE_OF_BIRTH: 'DATE_OF_BIRTH',
    DATE_OF_BIRTH_PLACEHOLDER: 'DATE_OF_BIRTH_PLACEHOLDER',
    PHONE_PLACEHOLDER: 'PHONE_PLACEHOLDER',
    EMAIL_PLACEHOLDER: 'EMAIL_PLACEHOLDER',
    ADDRESS: 'ADDRESS',
    ADDRESS_PLACEHOLDER: 'ADDRESS_PLACEHOLDER',
    SHOW_MORE_DETAIL: 'SHOW_MORE_DETAIL',
    JOB_INFO: 'JOB_INFO',
    SELECT_JOB_TYPE: 'SELECT_JOB_TYPE',
    START_DATE: 'START_DATE',
    START_DATE_PLACEHOLDER: 'START_DATE_PLACEHOLDER',
    STATUS: 'STATUS',
    STATUS_PLACEHOLDER: 'STATUS_PLACEHOLDER',
    ALL_STATUS: 'ALL_STATUS',
    FILTER_STATUS: 'FILTER_STATUS',
    LOCATION: 'LOCATION',
    LOCATION_PLACEHOLDER: 'LOCATION_PLACEHOLDER',
    ALLOW_BOOKING_EMPLOYEE: 'ALLOW_BOOKING_EMPLOYEE',
    PHONE_NUMBER_IS_INVALID: 'PHONE_NUMBER_IS_INVALID',
    LAST_NAME_REQUIRED: 'LAST_NAME_REQUIRED',
    FIRST_NAME_REQUIRED: 'FIRST_NAME_REQUIRED',
    EMAIL_INVALID: 'EMAIL_INVALID',
    EMAIL_REQUIRED: 'EMAIL_REQUIRED',
    PHONE_REQUIRED: 'PHONE_REQUIRED',
    //#endregion [Employee member route]

    //#region [Resource Management]
    SHIFTS: 'SHIFTS',
    THIS_WEEK: 'THIS_WEEK',
    ALL_EMPLOYEES: 'ALL_EMPLOYEES',
    EDIT_SHIFTS: 'EDIT_SHIFTS',
    EDIT_SHIFTS_FOR: 'EDIT_SHIFTS_FOR',
    ADD_BREAK: 'ADD_BREAK',
    BREAK: 'BREAK',
    ADD_TIME_OFF: 'ADD_TIME_OFF',
    TEAM_MEMBER: 'TEAM_MEMBER',
    TIME_OFF_TOTAL: 'TIME_OFF_TOTAL',
    REPEAT_EACH_DAY_TILL: 'REPEAT_EACH_DAY_TILL',
    EDIT_WORKING_HOUR: 'EDIT_WORKING_HOUR',
    VIEW_EMPLOYEE_DETAIL: 'VIEW_EMPLOYEE_DETAIL',
    EDIT_THIS_DAY: 'EDIT_THIS_DAY',
    DELETE_TIME_OFF: 'DELETE_TIME_OFF',
    DELETE_THIS_SHIFT: 'DELETE_THIS_SHIFT',
    DELETE_TIME_OFF_CONFIRM: 'DELETE_TIME_OFF_CONFIRM',
    SET_TIME_OFF_SUCCESS: 'SET_TIME_OFF_SUCCESS',
    //#endregion [Resource Management]

    //#region [Customer route]
    SIMPLE_CRM: 'SIMPLE_CRM',
    CREATE_CUSTOMER: 'CREATE_CUSTOMER',
    EDIT_CUSTOMER: 'EDIT_CUSTOMER',
    CLIENT_LIST: 'CLIENT_LIST',
    ADD_SOURCE: 'ADD_SOURCE',
    SOURCE: 'SOURCE',
    ADD_GROUP: 'ADD_GROUP',
    GROUP: 'GROUP',
    MEMBERSHIP: 'MEMBERSHIP',
    POINT: 'POINT',
    CREATED_ON: 'CREATED_ON',
    CLIENT_GROUP: 'CLIENT_GROUP',
    CLIENT_GROUP_PLACEHOLDER: 'CLIENT_GROUP_PLACEHOLDER',
    CLIENT_SOURCE: 'CLIENT_SOURCE',
    CLIENT_SOURCE_PLACEHOLDER: 'CLIENT_SOURCE_PLACEHOLDER',
    CLIENT: 'CLIENT',
    FIND_EMPLOYEE: 'FIND_EMPLOYEE',
    FIND_CLIENT: 'FIND_CLIENT',
    NOTE: 'NOTE',
    NOTE_PLACEHOLDER: 'NOTE_PLACEHOLDER',
    PERSONAL_INFO: 'PERSONAL_INFO',
    ADDITIONAL_INFO: 'ADDITIONAL_INFO',
    DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    DELETE_NOTE: 'DELETE_NOTE',
    DELETE_CUSTOMER_MSG: 'DELETE_CUSTOMER_MSG',
    DELETE_CUSTOMER_NOTE_MSG: 'DELETE_CUSTOMER_NOTE_MSG',
    REFERRED_BY: 'REFERRED_BY',
    CLIENT_PLACEHOLDER_SEARCH: 'CLIENT_PLACEHOLDER_SEARCH',
    CLIENT_DETAIL_PLACEHOLDER_SEARCH: 'CLIENT_DETAIL_PLACEHOLDER_SEARCH',
    MAX_LENGTH_EMAIL: 'MAX_LENGTH_EMAIL',
    MAX_LENGTH_ADDRESS: 'MAX_LENGTH_ADDRESS',
    MAX_LENGTH_LAST_NAME: 'MAX_LENGTH_LAST_NAME',
    MAX_LENGTH_FIRST_NAME: 'MAX_LENGTH_FIRST_NAME',
    MAX_LENGTH_NOTE: 'MAX_LENGTH_NOTE',
    FIRST_NAME_INVALID: 'FIRST_NAME_INVALID',
    START_ON: 'START_ON',
    BORN_ON: 'BORN_ON',
    LAST_NAME_MIN_LENGTH: 'LAST_NAME_MIN_LENGTH',
    FIRST_NAME_MIN_LENGTH: 'FIRST_NAME_MIN_LENGTH',
    LAST_NAME_INVALID: 'LAST_NAME_INVALID',
    ADD_CUSTOMER: 'ADD_CUSTOMER',
    EMPTY_CUSTOMER_DESCRIPTION: 'EMPTY_CUSTOMER_DESCRIPTION',
    EMPTY_GROUP_DESCRIPTION: 'EMPTY_GROUP_DESCRIPTION',
    EMPTY_SOURCE_DESCRIPTION: 'EMPTY_SOURCE_DESCRIPTION',
    //#endregion [Customer route]

    //#region [Customer Detail route]
    TOTAL_SALE: 'TOTAL_SALE',
    ADD_SALE: 'ADD_SALE',
    WALK_IN: 'WALK_IN',
    CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
    PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
    DESCRIPTION_CONFIRM_PAYMENT: 'DESCRIPTION_CONFIRM_PAYMENT',
    CANCEL_SALE: 'CANCEL_SALE',
    EDIT_SALE: 'EDIT_SALE',
    ENTER_YOUR_NOTE: 'ENTER_YOUR_NOTE',
    CANCEL_SALE_SUCCESS: 'CANCEL_SALE_SUCCESS',
    CREATE_CANCEL_REASON: 'CREATE_CANCEL_REASON',
    CANCEL_REASON_REQUIRED: 'CANCEL_REASON_REQUIRED',
    REVENUE: 'REVENUE',
    LOYALTY_POINT: 'LOYALTY_POINT',
    SALES: 'SALES',
    ADD_NOTE: 'ADD_NOTE',
    ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
    UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
    APPOINTMENTS: 'APPOINTMENTS',
    PRODUCTS: 'PRODUCTS',
    PACKAGES: 'PACKAGES',
    PROMOTIONS: 'PROMOTIONS',
    PRE_PAY_CARD: 'PRE_PAY_CARD',
    COMMUNICATIONS: 'COMMUNICATIONS',
    REVIEW: 'REVIEW',
    TRACKING_HISTORY: 'TRACKING_HISTORY',
    TIME: 'TIME',
    SERVICES_TIME: 'SERVICES_TIME',
    TOTAL_AMOUNT: 'TOTAL_AMOUNT',
    APPOINTMENT_LIST: 'APPOINTMENT_LIST',
    SEARCH_APPOINTMENT: 'SEARCH_APPOINTMENT',
    SELECT_GROUP: 'SELECT_GROUP',
    SELECT_SOURCE: 'SELECT_SOURCE',
    SELECT_DATE: 'SELECT_DATE',
    RESELL_MSG: 'RESELL_MSG',
    RESELL: 'RESELL',
    CREATE_CUSTOMER_SOURCE: 'CREATE_CUSTOMER_SOURCE',
    CREATE_CUSTOMER_GROUP: 'CREATE_CUSTOMER_GROUP',
    ENTER_GROUP_NAME: 'ENTER_GROUP_NAME',
    ENTER_SOURCE_NAME: 'ENTER_SOURCE_NAME',
    ID_SALE: 'ID_SALE',
    DATE_AND_TIME: 'DATE_AND_TIME',
    SALE_LIST: 'SALE_LIST',
    EMPLOYEES: 'EMPLOYEES',
    EMPLOYEE_MORE: 'EMPLOYEE_MORE',
    PRODUCT_NAME: 'PRODUCT_NAME',
    QUANTITY: 'QUANTITY',
    ORDER_DATE: 'ORDER_DATE',
    EMPTY_SALE_DESCRIPTION: 'EMPTY_SALE_DESCRIPTION',
    LAST_ACCESSED_ON: 'LAST_ACCESSED_ON',
    //#endregion [Customer Detail route]

    //#region [Job type route]
    JOB_TYPE_PLACEHOLDER: 'JOB_TYPE_PLACEHOLDER',
    JOB_TYPE_RULE: 'JOB_TYPE_RULE',
    DELETE_JOB_TYPE: 'DELETE_JOB_TYPE',
    SEARCH_JOB_TYPE: 'SEARCH_JOB_TYPE',
    JOB_TYPE_LIST: 'JOB_TYPE_LIST',
    JOB_TYPE_NAME: 'JOB_TYPE_NAME',
    DELETE_JOB_TYPE_MSG: 'DELETE_JOB_TYPE_MSG',
    //#endregion [Job type route]

    //#region [Facility route]
    FACILITY_PLACEHOLDER: 'FACILITY_PLACEHOLDER',
    FACILITY_RULE: 'FACILITY_RULE',
    DELETE_FACILITY: 'DELETE_FACILITY',
    SEARCH_FACILITY: 'SEARCH_FACILITY',
    ADD_FACILITY: 'ADD_FACILITY',
    FACILITY_NAME: 'FACILITY_NAME',
    FACILITY_NAME_PLACEHOLDER: 'FACILITY_NAME_PLACEHOLDER',
    EDIT_FACILITY: 'EDIT_FACILITY',
    FACILITY_DESCRIPTION: 'FACILITY_DESCRIPTION',
    FACILITY_DESCRIPTION_PLACEHOLDER: 'FACILITY_DESCRIPTION_PLACEHOLDER',
    DELETE_FACILITY_MSG: 'DELETE_FACILITY_MSG',
    EMPTY_FACILITY_DESCRIPTION: 'EMPTY_FACILITY_DESCRIPTION',
    //#endregion [Facility route]

    //#region [Employee detail route]
    EMPLOYEE_DETAIL: 'EMPLOYEE_DETAIL',
    DELETE_THIS_EMPLOYEE: 'DELETE_THIS_EMPLOYEE',
    SET_AS_ACTIVE: 'SET_AS_ACTIVE',
    SET_AS_SEPARATED: 'SET_AS_SEPARATED',
    BOOKING_ALLOWED: 'BOOKING_ALLOWED',
    EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',
    //#region [Employee detail route]

    //#region [Scheduler route]
    TOTAL: 'TOTAL',
    CREATE_VS_CHECK_IN: 'CREATE_VS_CHECK_IN',
    ADD_SERVICE: 'ADD_SERVICE',
    EMPTY_SERVICE_DESCRIPTION: 'EMPTY_SERVICE_DESCRIPTION',
    ADD_SERVICE_CATEGORY: 'ADD_SERVICE_CATEGORY',
    EMPTY_SERVICE_CATEGORY_DESCRIPTION: 'EMPTY_SERVICE_CATEGORY_DESCRIPTION',
    GUEST: 'GUEST',
    SELECT_SERVICE: 'SELECT_SERVICE',
    SELECT_EMPLOYEE: 'SELECT_EMPLOYEE',
    THE_EMPLOYEE_IS_REQUIRED: 'THE_EMPLOYEE_IS_REQUIRED',
    ADD_EMPLOYEE: 'ADD_EMPLOYEE',
    EMPTY_EMPLOYEE_DESCRIPTION: 'EMPTY_EMPLOYEE_DESCRIPTION',
    AVAILABILITY: 'AVAILABILITY',
    NUMBER_OF_GUEST: 'NUMBER_OF_GUEST',
    APPOINTMENT_NOTES: 'APPOINTMENT_NOTES',
    NOTES_PLACEHOLDER: 'NOTES_PLACEHOLDER',
    ACTIVITY: 'ACTIVITY',
    SEARCH_CUSTOMER_PLACEHOLDER: 'SEARCH_CUSTOMER_PLACEHOLDER',
    DELETE_APPOINTMENT: 'DELETE_APPOINTMENT',
    DELETE_APPOINTMENT_CONFIRM_MESSAGE: 'DELETE_APPOINTMENT_CONFIRM_MESSAGE',
    IS_NOT_AVAILABLE: 'IS_NOT_AVAILABLE',
    DOES_NOT_PROVIDER_THIS_SERVICE: 'DOES_NOT_PROVIDER_THIS_SERVICE',
    FIRST_AVAILABLE: 'FIRST_AVAILABLE',
    MOVE_SERVICE_TO_STAFF_CONFIRM: 'MOVE_SERVICE_TO_STAFF_CONFIRM',
    SELECT_FACILITY: 'SELECT_FACILITY',
    ANY_RESOURCE: 'ANY_RESOURCE',
    BREAK_TIME: 'BREAK_TIME',
    TIME_BLOCK: 'TIME_BLOCK',
    DELETE_BREAK_TIME: 'DELETE_BREAK_TIME',
    UPDATE_BREAK_TIME: 'UPDATE_BREAK_TIME',
    DELETE_TIME_BLOCK: 'DELETE_TIME_BLOCK',
    UPDATE_TIME_BLOCK: 'UPDATE_TIME_BLOCK',
    ADD_TIME_BLOCK: 'ADD_TIME_BLOCK',
    UPDATE_BREAK_TIME_CONFIRM: 'UPDATE_BREAK_TIME_CONFIRM',
    DELETE_BREAK_TIME_CONFIRM: 'DELETE_BREAK_TIME_CONFIRM',
    EMPTY_APPOINTMENT_DESCRIPTION: 'EMPTY_APPOINTMENT_DESCRIPTION',
    DELETE_TIME_BLOCK_ONLY: 'DELETE_TIME_BLOCK_ONLY',
    DELETE_TIME_BLOCK_FUTURE: 'DELETE_TIME_BLOCK_FUTURE',
    DELETE_TIME_BLOCK_ALL: 'DELETE_TIME_BLOCK_ALL',
    UPDATE_TIME_BLOCK_ONLY: 'UPDATE_TIME_BLOCK_ONLY',
    UPDATE_TIME_BLOCK_FUTURE: 'UPDATE_TIME_BLOCK_FUTURE',
    UPDATE_TIME_BLOCK_ALL: 'UPDATE_TIME_BLOCK_ALL',
    DELETE_TIME_BLOCK_CONFIRM: 'DELETE_TIME_BLOCK_CONFIRM',
    UPDATE_TIME_BLOCK_CONFIRM: 'UPDATE_TIME_BLOCK_CONFIRM',
    REQUIRED_FIELD:'REQUIRED_FIELD',
    APPOINTMENT_WARNING_CONFIRM: 'APPOINTMENT_WARNING_CONFIRM', 
    //#endregion [Scheduler route]

    //#region [Branch setting route]
    BRANCH_SETTING_NAME: 'BRANCH_SETTING_NAME',
    BRANCH_LOGO: 'BRANCH_LOGO',
    BRANCH_NAME: 'BRANCH_NAME',
    BRANCH_NAME_PLACEHOLDER: 'BRANCH_NAME_PLACEHOLDER',
    BRANCH_NAME_IS_REQUIRED: 'BRANCH_NAME_IS_REQUIRED',
    BRANCH_ADDRESS: 'BRANCH_ADDRESS',
    BRANCH_ADDRESS_PLACEHOLDER: 'BRANCH_ADDRESS_PLACEHOLDER',
    BRANCH_ADDRESS_IS_REQUIRED: 'BRANCH_ADDRESS_IS_REQUIRED',
    BRANCH_PHONE: 'BRANCH_PHONE',
    BRANCH_PHONE_PLACEHOLDER: 'BRANCH_PHONE_PLACEHOLDER',
    BRANCH_CITY: 'BRANCH_CITY',
    BRANCH_CITY_PLACEHOLDER: 'BRANCH_CITY_PLACEHOLDER',
    BRANCH_CITY_IS_REQUIRED: 'BRANCH_CITY_IS_REQUIRED',
    BRANCH_STATE: 'BRANCH_STATE',
    BRANCH_STATE_PLACEHOLDER: 'BRANCH_STATE_PLACEHOLDER',
    BRANCH_STATE_IS_REQUIRED: 'BRANCH_STATE_IS_REQUIRED',
    BRANCH_POSTCODE: 'BRANCH_POSTCODE',
    BRANCH_POSTCODE_PLACEHOLDER: 'BRANCH_POSTCODE_PLACEHOLDER',
    BRANCH_POSTCODE_IS_REQUIRED: 'BRANCH_POSTCODE_IS_REQUIRED',
    BRANCH_COUNTRY: 'BRANCH_COUNTRY',
    BRANCH_COUNTRY_PLACEHOLDER: 'BRANCH_COUNTRY_PLACEHOLDER',
    BRANCH_COUNTRY_IS_REQUIRED: 'BRANCH_COUNTRY_IS_REQUIRED',
    BRANCH_CURRENCY: 'BRANCH_CURRENCY',
    BRANCH_CURRENCY_PLACEHOLDER: 'BRANCH_CURRENCY_PLACEHOLDER',
    BRANCH_TAX: 'BRANCH_TAX',
    BRANCH_TAX_PLACEHOLDER: 'BRANCH_TAX_PLACEHOLDER',
    BRANCH_LANGUAGES: 'BRANCH_LANGUAGES',
    BRANCH_LANGUAGES_PLACEHOLDER: 'BRANCH_LANGUAGES_PLACEHOLDER',
    BRANCH_TIMEZONE: 'BRANCH_TIMEZONE',
    BRANCH_TIMEZONE_PLACEHOLDER: 'BRANCH_TIMEZONE_PLACEHOLDER',
    BRANCH_TIMEZONE_IS_REQUIRED: 'BRANCH_TIMEZONE_IS_REQUIRED',
    BRANCH_DATE_TIME_FORMAT: 'BRANCH_DATE_TIME_FORMAT',
    BRANCH_DATE_TIME_FORMAT_PLACEHOLDER: 'BRANCH_DATE_TIME_FORMAT_PLACEHOLDER',
    ADD_FIRST_BRANCH: 'ADD_FIRST_BRANCH',
    BRANCH_SETUP_DESC: 'BRANCH_SETUP_DESC',
    CITY: 'CITY',
    CITY_PLACEHOLDER: 'CITY_PLACEHOLDER',
    STATE: 'STATE',
    STATE_PLACEHOLDER: 'STATE_PLACEHOLDER',
    POSTCODE: 'POSTCODE',
    POSTCODE_PLACEHOLDER: 'POSTCODE_PLACEHOLDER',
    COUNTRY: 'COUNTRY',
    COUNTRY_PLACEHOLDER: 'COUNTRY_PLACEHOLDER',
    STATE_IS_REQUIRED: 'STATE_IS_REQUIRED',
    CITY_IS_REQUIRED: 'CITY_IS_REQUIRED',
    POSTCODE_IS_REQUIRED: 'POSTCODE_IS_REQUIRED',
    COUNTRY_IS_REQUIRED: 'COUNTRY_IS_REQUIRED',
    BRANCH_INFORMATION: 'BRANCH_INFORMATION',
    BRANCH_WORKING_TIME: 'BRANCH_WORKING_TIME',
    BRANCH_UPDATE_TITLE: 'BRANCH_UPDATE_TITLE',
    BRANCH_UPDATE_SUCCESS: 'BRANCH_UPDATE_SUCCESS',
    BRANCH_UPDATE_FAILURE: 'BRANCH_UPDATE_FAILURE',
    BRANCH_UPDATE_DESCRIPTION: 'BRANCH_UPDATE_DESCRIPTION',
    BRANCH_CREATE_SUCCESS: 'BRANCH_CREATE_SUCCESS',
    BRANCH_CREATE_FAILURE: 'BRANCH_CREATE_FAILURE',
    BRANCH_CREATE_DESCRIPTION: 'BRANCH_CREATE_DESCRIPTION',
    BRANCH_CHANGE_SUCCESS: 'BRANCH_CHANGE_SUCCESS',
    BRANCH_CHANGE_FAILURE: 'BRANCH_CHANGE_FAILURE',
    //#endregion [Branch setting route]

    //#region [Setup BusinessHour page]
    BUSINESS_HOUR_NAME: 'BUSINESS_HOUR_NAME',
    //#endregion [Setup BusinessHour page]

    //#region [Salon Management route]
    SALON_INFORMATION: 'SALON_INFORMATION',
    BRANCH_MANAGEMENT: 'BRANCH_MANAGEMENT',
    BRANCH_CREATE: 'BRANCH_CREATE',
    BRANCH_LIST: 'BRANCH_LIST',
    SEARCH_BRANCH: 'SEARCH_BRANCH',
    DELETE_BRANCH: 'DELETE_BRANCH',
    //#endregion [Salon Management route]

    //#region [Profile route]
    PROFILE: 'PROFILE',
    USER_ROLE: 'USER_ROLE',
    EDIT_PROFILE: 'EDIT_PROFILE',
    PROFILE_DETAILS: 'PROFILE_DETAILS',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    PHONE_NUMBER: 'PHONE_NUMBER',
    CREATE_PASSWORD: 'CREATE_PASSWORD',
    CONNECT_A_SOCIAL_ACCOUNT: 'CONNECT_A_SOCIAL_ACCOUNT',
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    CURRENT_PASSWORD: 'CURRENT_PASSWORD',
    CURRENT_PASSWORD_NOT_MATCH: 'CURRENT_PASSWORD_NOT_MATCH',
    NEW_PASSWORD: 'NEW_PASSWORD',
    CONFIRM_NEW_PASSWORD: 'CONFIRM_NEW_PASSWORD',
    PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    CURRENT_PASSWORD_REQUIRED: 'CURRENT_PASSWORD_REQUIRED',
    CONFIRM_PASSWORD_REQUIRED: 'CONFIRM_PASSWORD_REQUIRED',
    PASSWORD_INVALID: 'PASSWORD_INVALID',
    PASSWORD_MIN_LENGTH: 'PASSWORD_MIN_LENGTH',
    PASSWORD_MAX_LENGTH: 'PASSWORD_MAX_LENGTH',
    PASSWORD_NOT_MATCH: 'PASSWORD_NOT_MATCH',
    VALIDATE_PASSWORD: 'VALIDATE_PASSWORD',
    VALIDATE_CONFIRM_PASSWORD: 'VALIDATE_CONFIRM_PASSWORD',
    //#region [Booking setting route]
    BOOKING_ONLINE: 'BOOKING_ONLINE',
    BOOKING_NOW: 'BOOKING_NOW',
    BOOKING_SETTING: 'BOOKING_SETTING',
    PREFERENCES: 'PREFERENCES',
    PAGES: 'PAGES',
    TIME_SLOT_DURATION: 'TIME_SLOT_DURATION',
    TIME_SLOT_DURATION_DESCRIPTION: 'TIME_SLOT_DURATION_DESCRIPTION',
    DOUBLE_APPOINTMENT_ENABLED: 'DOUBLE_APPOINTMENT_ENABLED',
    BOOKING_DATE_LIMIT: 'BOOKING_DATE_LIMIT',
    DOUBLE_APPOINTMENT_ENABLED_DESCRIPTION: 'DOUBLE_APPOINTMENT_ENABLED_DESCRIPTION',
    BOOKING_FEATURE_LIMIT: 'BOOKING_FEATURE_LIMIT',
    BOOKING_FEATURE_LIMIT_DESCRIPTION: 'BOOKING_FEATURE_LIMIT_DESCRIPTION',
    ALLOW_CUSTOMER_SELF_CANCEL: 'ALLOW_CUSTOMER_SELF_CANCEL',
    ALLOW_CUSTOMER_SELF_CANCEL_DESCRIPTION: 'ALLOW_CUSTOMER_SELF_CANCEL_DESCRIPTION',
    ALLOW_BOOKING_SLOT_LIMIT: 'ALLOW_BOOKING_SLOT_LIMIT',
    ALLOW_BOOKING_SLOT_LIMIT_DESCRIPTION: 'ALLOW_BOOKING_SLOT_LIMIT_DESCRIPTION',
    ALLOW_GROUP_APPOINTMENT: 'ALLOW_GROUP_APPOINTMENT',
    ALLOW_GROUP_APPOINTMENT_DESCRIPTION: 'ALLOW_GROUP_APPOINTMENT_DESCRIPTION',
    GROUP_APPOINTMENT_PREFIX: 'GROUP_APPOINTMENT_PREFIX',
    SHOW_SERVICE_PRICE: 'SHOW_SERVICE_PRICE',
    REQUIRED_SELECT_EMPLOYEE: 'REQUIRED_SELECT_EMPLOYEE',
    REQUIRED_SELECT_EMPLOYEE_PREFIX: 'REQUIRED_SELECT_EMPLOYEE_PREFIX',
    SHOW_SERVICE_PRICE_PREFIX: 'SHOW_SERVICE_PRICE_PREFIX',
    CONFIRM_ONLINE_APPOINTMENT: 'CONFIRM_ONLINE_APPOINTMENT',
    CONFIRM_ONLINE_APPOINTMENT_PREFIX: 'CONFIRM_ONLINE_APPOINTMENT_PREFIX',
    ONLINE_BOOKING: 'ONLINE_BOOKING',
    ONLINE_BOOKING_DESCRIPTION: 'ONLINE_BOOKING_DESCRIPTION',
    BOOKING_URL: 'BOOKING_URL',
    BOOKING_URL_DESCRIPTION: 'BOOKING_URL_DESCRIPTION',
    BOOK_TAB_NAME: 'BOOK_TAB_NAME',
    BOOK_TAB_NAME_DESCRIPTION: 'BOOK_TAB_NAME_DESCRIPTION',
    BOOK_TAB_INFORMATION: 'BOOK_TAB_INFORMATION',
    BOOK_TAB_INFORMATION_DESCRIPTION: 'BOOK_TAB_INFORMATION_DESCRIPTION',
    TOP_BANNER: 'TOP_BANNER',
    TOP_BANNER_DESCRIPTION: 'TOP_BANNER_DESCRIPTION',
    GALLERY: 'GALLERY',
    GALLERY_IMAGE: 'GALLERY_IMAGE',
    GALLERY_DESCRIPTION: 'GALLERY_DESCRIPTION',
    GALLERY_IMAGE_DESCRIPTION: 'GALLERY_IMAGE_DESCRIPTION',
    CUSTOMIZE_SERVICE: 'CUSTOMIZE_SERVICE',
    CUSTOMIZE_SERVICE_DESCRIPTION: 'CUSTOMIZE_SERVICE_DESCRIPTION',

    //#region [Sale route]
    CREATE_SALE: 'CREATE_SALE',
    NEW_SALE: 'NEW_SALE',
    DAILY_SALE: 'DAILY_SALE',
    PROCEED: 'PROCEED',
    CHECKOUT: 'CHECKOUT',
    CLOSE: 'CLOSE',
    CART_NO_ITEM: 'CART_NO_ITEM',
    CART_NO_ITEM_DESC: 'CART_NO_ITEM_DESC',
    CART_NO_ITEM_DESC_FAST_SALE: 'CART_NO_ITEM_DESC_FAST_SALE',
    SELECT_CANCEL_REASON: 'SELECT_CANCEL_REASON',
    CANCEL_NOTE_PLACEHOLDER: 'CANCEL_NOTE_PLACEHOLDER',
    ADD_NOTE_PLACEHOLDER: 'ADD_NOTE_PLACEHOLDER',
    TO_LOGIN_PAGE: 'TO_LOGIN_PAGE',

    //#region [other page]
    UNIT: 'UNIT',
    CAPACITY: 'CAPACITY',
    CAPACITY_PLACEHOLDER: 'CAPACITY_PLACEHOLDER',
    ORIGINAL_PRICE: 'ORIGINAL_PRICE',
    ORIGINAL_PRICE_PLACEHOLDER: 'ORIGINAL_PRICE_PLACEHOLDER',
    ORIGINAL_PRICE_REQUIRED: 'ORIGINAL_PRICE_REQUIRED',
    SELLING_PRICE: 'SELLING_PRICE',
    SELLING_PRICE_PLACEHOLDER: 'SELLING_PRICE_PLACEHOLDER',
    SELLING_PRICE_REQUIRED: 'SELLING_PRICE_REQUIRED',
    CAPACITY_PRICE_REQUIRED: 'CAPACITY_PRICE_REQUIRED',
    CAPACITY_INVALID: 'CAPACITY_INVALID',
    ADD_TAX: 'ADD_TAX',
    TAX_REQUIRED: 'TAX_REQUIRED',
    TAX_PLACEHOLDER: 'TAX_PLACEHOLDER',
    EMPTY_TAX_DESCRIPTION: 'EMPTY_TAX_DESCRIPTION',
    OWNER: 'OWNER',
    USER: 'USER',
    CANCELATION_NOTE: 'CANCELATION_NOTE',
    CANCELATION_REASON: 'CANCELATION_REASON',
    SUB_TOTAL: 'SUB_TOTAL',
    TIP: 'TIP',
    FILTER: 'FILTER',
    UNIT_REQUIRED: 'UNIT_REQUIRED',

    //#region [Message]
    UPDATE_BRANCH_SUCCESS: 'UPDATE_BRANCH_SUCCESS',
    UPDATE_BRANCH_FAIL: 'UPDATE_BRANCH_FAIL',
    CREATE_BRANCH_SUCCESS: 'CREATE_BRANCH_SUCCESS',
    CREATE_BRANCH_FAILURE: 'CREATE_BRANCH_FAILURE',
    BRANCH_HAS_CREATED: 'BRANCH_HAS_CREATED',
    PLEASE_CHOOSE_CLIENT: 'PLEASE_CHOOSE_CLIENT',
    CUSTOMER_ALREADY_EXIST: 'CUSTOMER_ALREADY_EXIST',
    EMAIL_ALREADY_EXIST: 'EMAIL_ALREADY_EXIST',
    PHONE_OR_EMAIL_REQUIRED: 'PHONE_OR_EMAIL_REQUIRED',
    PHONE_ALREADY_EXIST: 'PHONE_ALREADY_EXIST',
    ENTER_NAME_METHOD: 'ENTER_NAME_METHOD',
    SELECT_UNIT: 'SELECT_UNIT',
    SELECT_PRODUCT_CATEGORY: 'SELECT_PRODUCT_CATEGORY',
    PRODUCT_NAME_REQUIRED: 'PRODUCT_NAME_REQUIRED',
    DESCRIPTION_PACKAGE_PLACEHOLDER: 'DESCRIPTION_PACKAGE_PLACEHOLDER',
    DESCRIPTION_PRODUCT_PLACEHOLDER: 'DESCRIPTION_PRODUCT_PLACEHOLDER',
    DESCRIPTION_TAX_PLACEHOLDER: 'DESCRIPTION_TAX_PLACEHOLDER',
    SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
    SELECT_PAYMENT_METHOD_CONTINUE: 'SELECT_PAYMENT_METHOD_CONTINUE',
    PRINT_BILL_AFTER_PAYMENT: 'PRINT_BILL_AFTER_PAYMENT',

    //#region [Print sale]
    PRINT_THIS_DOCUMENT: 'PRINT_THIS_DOCUMENT',
    PAYMENT_IS_PROCESSED: 'PAYMENT_IS_PROCESSED',
    RECEIPT: 'RECEIPT',
    CLIENT_NAME: 'CLIENT_NAME',
    PAYMENT_TYPE: 'PAYMENT_TYPE',
    SALE_ID: 'SALE_ID',
    PRINT: 'PRINT',
    PAYMENT_MESSAGE: 'PAYMENT_MESSAGE',
    VALUE: 'VALUE',
    VALUE_TAX_PLACEHOLDER: 'VALUE_TAX_PLACEHOLDER',
    TAX_NAME_REQUIRED: 'TAX_NAME_REQUIRED',
    TAX_RATE: 'TAX_RATE',
    TAX_RATE_REQUIRED: 'TAX_RATE_REQUIRED',
    TAX_RATE_PLACEHOLDER: 'TAX_RATE_PLACEHOLDER',
    NAME_UNIT_PLACEHOLDER: 'NAME_UNIT_PLACEHOLDER',
    NAME_UNIT_REQUIRED: 'NAME_UNIT_REQUIRED',
    NAME_TAX_PLACEHOLDER: 'NAME_TAX_PLACEHOLDER',
    TITLE: 'TITLE',
    TITLE_PLACEHOLDER: 'TITLE_PLACEHOLDER',
    REPEATS: 'REPEATS',
    NOT_REPEAT: 'NOT_REPEAT',
    REPEATS_PLACEHOLDER: 'REPEATS_PLACEHOLDER',
    EVERY_DAY: 'EVERY_DAY',
    EVERY_WEEK: 'EVERY_WEEK',
    EVERY_MONTH: 'EVERY_MONTH',
    ALL_SERVICE: 'ALL_SERVICE',
    CREATE_CUSTOMER_SUCCESS_MESSAGE: 'CREATE_CUSTOMER_SUCCESS_MESSAGE',
    UPDATE_CUSTOMER_SUCCESS_MESSAGE: 'UPDATE_CUSTOMER_SUCCESS_MESSAGE',
    FILTER_SERVICE: 'FILTER_SERVICE',
    FILTER_EMPLOYEE: 'FILTER_EMPLOYEE',
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    CANCELLED: 'CANCELLED',
    CREATE_METHOD: 'CREATE_METHOD',
    EDIT_METHOD: 'EDIT_METHOD',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    PAYMENT_METHOD_REQUIRED: 'PAYMENT_METHOD_REQUIRED',
    DELETE_PAYMENT_METHOD: 'DELETE_PAYMENT_METHOD',
    DELETE_PAYMENT_METHOD_MSG: 'DELETE_PAYMENT_METHOD_MSG',
    YES: 'YES',
    NO: 'NO',
    CHECK_OUT: 'CHECK_OUT',
    EDIT_JOB_TYPE: 'EDIT_JOB_TYPE',
    APPOINTMENT_NO_SHOW_DESC: 'APPOINTMENT_NO_SHOW_DESC',
    APPOINTMENT_CANCEL_DESC: 'APPOINTMENT_CANCEL_DESC',
    APPOINTMENT_BOOKED_DESC: 'APPOINTMENT_BOOKED_DESC',
    APPOINTMENT_CONFIRM_DESC: 'APPOINTMENT_CONFIRM_DESC',

    //#region [Group and Source]
    GROUP_LIST: 'GROUP_LIST',
    SOURCE_LIST: 'SOURCE_LIST',
    CREATE_NEW_GROUP: 'CREATE_NEW_GROUP',
    CREATE_NEW_SOURCE: 'CREATE_NEW_SOURCE',
    SEARCH_GROUP_PLACEHOLDER: 'SEARCH_GROUP_PLACEHOLDER',
    SEARCH_SOURCE_PLACEHOLDER: 'SEARCH_SOURCE_PLACEHOLDER',
    INACTIVE: 'INACTIVE',
    NEW_CLIENT_SOURCE: 'NEW_CLIENT_SOURCE',
    NEW_CLIENT_GROUP: 'NEW_CLIENT_GROUP',
    EDIT_CLIENT_SOURCE: 'EDIT_CLIENT_SOURCE',
    EDIT_CLIENT_GROUP: 'EDIT_CLIENT_GROUP',
    EDIT_CLIENT_DETAIL: 'EDIT_CLIENT_DETAIL',
    DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
    DELETE_CLIENT_SOURCE: 'DELETE_CLIENT_SOURCE',
    DELETE_CLIENT_GROUP: 'DELETE_CLIENT_GROUP',
    DELETE_CLIENT: 'DELETE_CLIENT',
    DELETE_CLIENT_SOURCE_MSG: 'DELETE_CLIENT_SOURCE_MSG',
    DELETE_CLIENT_GROUP_MSG: 'DELETE_CLIENT_GROUP_MSG',
    GROUP_NAME: 'GROUP_NAME',
    GROUP_NAME_REQUIRED: 'GROUP_NAME_REQUIRED',
    SOURCE_NAME: 'SOURCE_NAME',
    SOURCE_NAME_REQUIRED: 'SOURCE_NAME_REQUIRED',

    //#region [Integration setting route]
    CONNECTED: 'CONNECTED',
    CONNECT: 'CONNECT',
    DISCONNECTED: 'DISCONNECTED',
    DISCONNECT: 'DISCONNECT',
    EMAIL_LIMIT_MESSAGE: 'EMAIL_LIMIT_MESSAGE',
    CONNECTION_INFORMATION: 'CONNECTION_INFORMATION',
    INTEGRATION_EMAIL_DESC: 'INTEGRATION_EMAIL_DESC',
    MARKETING: 'MARKETING',
    SMTP_SERVER: 'SMTP_SERVER',
    PLACEHOLDER_PASSWORD: 'PLACEHOLDER_PASSWORD',
    PLACEHOLDER_EMAIL: 'PLACEHOLDER_EMAIL',
    PLACEHOLDER_SMTP_SERVER: 'PLACEHOLDER_SMTP_SERVER',
    PLACEHOLDER_SALON_NAME: 'PLACEHOLDER_SALON_NAME',
    PLACEHOLDER_USER_NAME: 'PLACEHOLDER_USER_NAME',
    PLACEHOLDER_PORT: 'PLACEHOLDER_PORT',
    PORT: 'PORT',
    ENCRYPTION: 'ENCRYPTION',
    USER_NAME: 'USER_NAME',
    PASSWORD: 'PASSWORD',
    SENDING_EMAIL: 'SENDING_EMAIL',
    SENDING_FORM: 'SENDING_FORM',
    GMAIL: 'GMAIL',
    OTHER: 'OTHER',
    SMTP_SERVER_IS_REQUIRED: 'SMTP_SERVER_IS_REQUIRED',
    PORT_IS_REQUIRED: 'PORT_IS_REQUIRED',
    USER_NAME_IS_REQUIRED: 'USER_NAME_IS_REQUIRED',
    PASSWORD_IS_REQUIRED: 'PASSWORD_IS_REQUIRED',
    SENDING_EMAIL_IS_REQUIRED: 'SENDING_EMAIL_IS_REQUIRED',

    //#region [Sale Analyzer]
    CLIENT_REPORT: 'CLIENT_REPORT',
    TOTAL_CLIENT: 'TOTAL_CLIENT',
    NEW_CLIENT: 'NEW_CLIENT',
    RETENTION_CLIENT: 'RETENTION_CLIENT',
    WALK_IN_CLIENT: 'WALK_IN_CLIENT',
    TOTAL_REVENUES: 'TOTAL_REVENUES',
    DISCOUNT: 'DISCOUNT',
    BILL_LIST: 'BILL_LIST',
    BILLS: 'BILLS',
    NUMBER_OF_SERVICE: 'NUMBER_OF_SERVICE',
    NUMBER_OF_PRODUCT: 'NUMBER_OF_PRODUCT',
    TOTAL_DISCOUNT: 'TOTAL_DISCOUNT',
    LINKS: 'LINKS',
    SALES_BY_CLIENT: 'SALES_BY_CLIENT',
    REVENUE_REPORT: 'REVENUE_REPORT',
    HIGHEST_SALE: 'HIGHEST_SALE',
    SUMMARY: 'SUMMARY',
    VIEW_DETAIL: 'VIEW_DETAIL',
    PRODUCT_BY_EMPLOYEE: 'PRODUCT_BY_EMPLOYEE',
    PRODUCTS_BY_CATEGORIES: 'PRODUCTS_BY_CATEGORIES',
    PRODUCTS_SUMMARY: 'PRODUCTS_SUMMARY',
    SERVICE_BY_EMPLOYEE: 'SERVICE_BY_EMPLOYEE',
    SERVICES_BY_CATEGORIES: 'SERVICES_BY_CATEGORIES',
    SERVICES_SUMMARY: 'SERVICES_SUMMARY',
    TOTAL_APPOINTMENT_REVENUE: 'TOTAL_APPOINTMENT_REVENUE',
    TOTAL_PRODUCT_REVENUE: 'TOTAL_PRODUCT_REVENUE',
    TOTAL_SERVICE_REVENUE: 'TOTAL_SERVICE_REVENUE',
    TOTAL_TIP: 'TOTAL_TIP',
    TOTAL_TAX: 'TOTAL_TAX',
    TOTAL_PAYMENT: 'TOTAL_PAYMENT',
    //#endregion [Integration setting route]

    //#region [Automation email setting]
    INTERNAL_EMAIL_DEFAULTS: 'INTERNAL_EMAIL_DEFAULTS',
    EMAILS_ARE_SENT_AUTOMATICALLY: 'EMAILS_ARE_SENT_AUTOMATICALLY',
    SEND_EMAIL_CONFIRM_APPOINTMENT: 'SEND_EMAIL_CONFIRM_APPOINTMENT',
    SEND_EMAIL_CONFIRM_APPOINTMENT_DESCRIPTION: 'SEND_EMAIL_CONFIRM_APPOINTMENT_DESCRIPTION',
    SEND_EMAIL_SALE_CLOSE: 'SEND_EMAIL_SALE_CLOSE',
    SEND_EMAIL_SALE_CLOSE_DESCRIPTION: 'SEND_EMAIL_SALE_CLOSE_DESCRIPTION',
    //#endregion [Automation email setting]

    //#region [Automation Reminder]
    REMIND_EMAIL_APPOINTMENT: 'REMIND_EMAIL_APPOINTMENT',
    REMIND_EMAIL_APPOINTMENT_DESCRIPTION: 'REMIND_EMAIL_APPOINTMENT_DESCRIPTION',
    REMIND_EMAIL_TIME: 'REMIND_EMAIL_TIME',
    REMIND_EMAIL_TIME_DESCRIPTION: 'REMIND_EMAIL_TIME_DESCRIPTION',
    REMIND_EMAIL: 'REMIND_EMAIL',
    REMIND_EMAIL_ACTIVE_DESCRIPTION: 'REMIND_EMAIL_ACTIVE_DESCRIPTION',
    //#endregion [Automation Reminder]

    //#region [Email template]
    EMAIL_TEMPLATE_LIST: 'EMAIL_TEMPLATE_LIST',
    EMAIL_TEMPLATE_SEARCH: 'EMAIL_TEMPLATE_SEARCH',
    EMAIL_TEMPLATE_SUBJECT: 'EMAIL_TEMPLATE_SUBJECT',
    EMAIL_TEMPLATE_SYSTEM: 'EMAIL_TEMPLATE_SYSTEM',
    EMAIL_TEMPLATE_CUSTOM: 'EMAIL_TEMPLATE_CUSTOM',
    EMAIL_TEMPLATE_REVERT: 'EMAIL_TEMPLATE_REVERT',
    EMAIL_TEMPLATE_REVERT_MESSAGE: 'EMAIL_TEMPLATE_REVERT_MESSAGE',
    EMAIL_TEMPLATE_EDITOR: 'EMAIL_TEMPLATE_EDITOR',
    EMAIL_TEMPLATE_PREVIEW: 'EMAIL_TEMPLATE_PREVIEW',
    EMAIL_TEMPLATE_PREVIEW_PLACEHOLDER: 'EMAIL_TEMPLATE_PREVIEW_PLACEHOLDER',
    EMAIL_TEMPLATE_AVAILABLE: 'EMAIL_TEMPLATE_AVAILABLE',
    CUSTOMER_NAME: 'CUSTOMER_NAME',
    RESERVATION_LOCATION: 'RESERVATION_LOCATION',
    RESERVATION_DATE: 'RESERVATION_DATE',
    RESERVATION_BOOKING_TIME: 'RESERVATION_BOOKING_TIME',
    BOOKING_SERVICE_NAME: 'BOOKING_SERVICE_NAME',
    BOOKING_EMPLOYEE_NAME: 'BOOKING_EMPLOYEE_NAME',
    BOOKING_SERVICE_DURATION: 'BOOKING_SERVICE_DURATION',
    STORE_LOCATION: 'STORE_LOCATION',

    //#region [Bank Account]
    ACCOUNT_NAME_REQUIRED: 'ACCOUNT_NAME_REQUIRED',
    ACCOUNT_NUMBER_REQUIRED: 'ACCOUNT_NUMBER_REQUIRED',
    BANK_NAME_REQUIRED: 'BANK_NAME_REQUIRED',
    //#endregion [Bank Account]

    //#region [Message ID]

    //#region [Notification Messages]
    NM_PT_01: 'NM_PT_01',
    NM_PT_02: 'NM_PT_02',
    NM_SM_01: 'NM_SM_01',
    NM_SM_02: 'NM_SM_02',
    NM_SC_01: 'NM_SC_01',
    NM_SC_02: 'NM_SC_02',
    NM_SC_03: 'NM_SC_03',
    NM_SC_04: 'NM_SC_04',
    NM_SC_05: 'NM_SC_05',
    NM_SC_06: 'NM_SC_06',
    NM_SC_07: 'NM_SC_07',
    NM_SC_08: 'NM_SC_08',
    MM_FP_01: 'MM_FP_01',
    MM_FP_02: 'MM_FP_02',
    MM_FP_03: 'MM_FP_03',
    MM_FP_04: 'MM_FP_04',
    NM_CM_01: 'NM_CM_01',
    NM_CM_02: 'NM_CM_02',
    NM_CM_03: 'NM_CM_03',
    NM_CM_04: 'NM_CM_04',
    NM_CM_05: 'NM_CM_05',
    NM_GM_01: 'NM_GM_01',

    //#endregion [Notification Messages]

    //#region [Error Messages]
    ER_SC_01: 'ER_SC_01',
    ER_SC_02: 'ER_SC_02',
    //#endregion [Error Messages]
    
    //#region [Validation Messages]
    VAL_PT_01: 'VAL_PT_01',
    VAL_PT_02: 'VAL_PT_02',
    VAL_PT_03: 'VAL_PT_03',
    VAL_PT_04: 'VAL_PT_04',
    VAL_PT_05: 'VAL_PT_05',
    VAL_PT_06: 'VAL_PT_06',
    VAL_PT_07: 'VAL_PT_07',
    VAL_PT_08: 'VAL_PT_08',
    VAL_SM_01: 'VAL_SM_01',
    VAL_SM_02: 'VAL_SM_02',
    VAL_SM_03: 'VAL_SM_03',
    VAL_SM_04: 'VAL_SM_04',
    VAL_SM_05: 'VAL_SM_05',
    VAL_SC_01: 'VAL_SC_01',
    VAL_SC_02: 'VAL_SC_02',
    VAL_SC_03: 'VAL_SC_03',
    VAL_SC_04: 'VAL_SC_04',
    VAL_SC_05: 'VAL_SC_05',
    VAL_SC_06: 'VAL_SC_06',
    VAL_SC_07: 'VAL_SC_07',
    VAL_SC_08: 'VAL_SC_08',
    VAL_SC_09: 'VAL_SC_09',
    VAL_SC_10: 'VAL_SC_10',
    VAL_FP_01: 'VAL_FP_01',
    VAL_FP_02: 'VAL_FP_02',
    VAL_CM_01: 'VAL_CM_01',
    VAL_CM_02: 'VAL_CM_02',
    VAL_CM_03: 'VAL_CM_03',
    //#endregion [Validation Messages]

    //#region [Tooltip Messages]
    TT_SC_01: 'TT_SC_01',
    TT_SC_02: 'TT_SC_02',
    TT_SC_03: 'TT_SC_03',
    TT_SC_04: 'TT_SC_04',
    //#endregion [Tooltip Messages]

    //#region [Alert Messages]
    AL_SC_01: 'AL_SC_01',
    AL_SC_02: 'AL_SC_02',
    //#endregion [Alert Messages]

    //#endregion [Message ID]
};

export type TranslateResources = Record<keyof typeof translateCodes, string>;