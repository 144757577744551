import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
interface AppointmentDeleteRequest extends HttpRequestData {}

export const deleteAppointment = httpUtil.createHttpRequestMeta<AppointmentDeleteRequest, void>({
    baseUrl: env.apiUrlBase,
    method: 'DELETE',
    path: '/api/v1/appointments/:id',
    authentication: 'bearer',
});
