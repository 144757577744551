import { Navigate, Route, Routes } from 'react-router-dom';
import { ServiceCategory } from './service-category';
import { Services } from './services';
import { ProductCategory } from './product-category';
import { Products } from './products';
import { PRODUCT_TRACKER_SERVICES_URL, productTrackerMenu } from '@/configs';
import { SubMenuLayout } from '@/layouts';

export const ProductTracker = () => {
    return (
        <Routes>
            <Route element={<SubMenuLayout subMenu={productTrackerMenu}/>} >
                <Route path='/services'  Component={Services} />
                <Route path='/service-category' Component={ServiceCategory} />
                <Route path='/product' Component={Services} />
                <Route path='/product-category' Component={ProductCategory} />
                <Route path='/products' Component={Products} />
                {/* <Route path='/package-category' Component={PackageCategory} />
                <Route path='/package' Component={Packages} /> */}
                <Route path='/' element={<Navigate to={PRODUCT_TRACKER_SERVICES_URL} />} />
            </Route>
        </Routes>
    );
};
