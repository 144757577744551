import { TableCustom, globalDrawerState, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { getSummaryRevenueReport } from '@esg/business-report/api/revenue-report';
import { TypeRevenueDetail } from '@esg/business-report/api/revenue-report/getSummaryRevenueDetail';
import { useTranslation } from 'react-i18next';
import { RevenueSummaryDetailTable } from './RevenueSummaryDetailTable';
import { translateCodes } from '@/locales';

interface RevenueSummaryTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

interface RevenueSummaryColumns {
    readonly id?: string;
    readonly title: string;
    readonly value?: number | string;
    readonly typeRevenueDetail: TypeRevenueDetail;
}

export default function RevenueSummaryTable( props: RevenueSummaryTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency } = useLocalesFormat();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const {data: summaryRevenue, isFetching} = useHttpQuery(getSummaryRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        },
    });

    const viewDetail = useCallback((typeRevenueDetail: TypeRevenueDetail) => {
        return setDrawerGlobalState({
            titleTransCode: translateCodes.VIEW_DETAIL,
            isOpen: true,
            hideHeader: true,
            content: (
                <RevenueSummaryDetailTable
                    dateRange={dateRange}
                    typeRevenueDetail={typeRevenueDetail}
                />
            ),
            footer: null,
        });
    },[dateRange, setDrawerGlobalState]);

    const summaryDataTable: RevenueSummaryColumns[] = useMemo(()=>([
        {
            title: t(translateCodes.TOTAL_SALE),
            value: summaryRevenue?.totalSale ?? 0 as number,
            typeRevenueDetail: TypeRevenueDetail.All,
        },
        {
            title: t(translateCodes.TOTAL_REVENUES),
            value: formatCurrency(summaryRevenue?.totalRevenue ?? 0 as number),
            typeRevenueDetail: TypeRevenueDetail.All,
        },
        {
            title: t(translateCodes.TOTAL_APPOINTMENT_REVENUE),
            value: formatCurrency(summaryRevenue?.totalAppointmentRevenue ?? 0 as number),
            typeRevenueDetail: TypeRevenueDetail.Appointment,
        },
        {
            title: t(translateCodes.TOTAL_PRODUCT_REVENUE),
            value: formatCurrency(summaryRevenue?.totalProductRevenue ?? 0 as number),
            typeRevenueDetail: TypeRevenueDetail.Product,
        },
        {
            title: t(translateCodes.TOTAL_SERVICE_REVENUE),
            value: formatCurrency(summaryRevenue?.totalServiceRevenue ?? 0 as number),
            typeRevenueDetail: TypeRevenueDetail.Service,
        },
        {
            title: t(translateCodes.TOTAL_DISCOUNT),
            value: formatCurrency(summaryRevenue?.totalDiscount ?? 0),
            typeRevenueDetail: TypeRevenueDetail.Discount,
        },
        {
            title: t(translateCodes.TOTAL_TIP),
            value: formatCurrency(summaryRevenue?.totalTip ?? 0),
            typeRevenueDetail: TypeRevenueDetail.Tip,
        },
        {
            title: t(translateCodes.TOTAL_TAX),
            value: formatCurrency(summaryRevenue?.totalTax ?? 0),
            typeRevenueDetail: TypeRevenueDetail.Tax,
        },
        {
            title: t(translateCodes.TOTAL_PAYMENT),
            value: formatCurrency(summaryRevenue?.totalPayment ?? 0),
            typeRevenueDetail: TypeRevenueDetail.Payment,
        },
    ]),[summaryRevenue, formatCurrency, t]);

    const columns: TableColumnsType<RevenueSummaryColumns> = useMemo(()=>([
        {
            key: 'title',
            title: t(translateCodes.TITLE),
            dataIndex: 'title',
        },
        {
            key: 'value',
            title: t(translateCodes.VALUE),
            dataIndex: 'value',
        },
        {
            key: 'links',
            title: t(translateCodes.LINKS),
            dataIndex: 'links',
            width: 150,
            render: (_,record) => {
                return (
                    <Tag 
                        className='cursor-pointer' 
                        onClick={()=> viewDetail(record.typeRevenueDetail)}
                    >
                        View Detail
                    </Tag>
                );
            }
        },
    ]),[t, viewDetail]);

    return (
        <TableCustom
            showHeader={false}
            showFooter={false}
            columns={columns}
            dataSource={summaryDataTable}
            loading={isFetching}
        />
    );
}
