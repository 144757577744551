import {
    AvatarUpload,
    FlexBox,
    InputField,
    SelectField,
    Spacer,
    Text,
    useLocationOptions,
} from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { useFormContext } from 'react-hook-form';
import { translateCodes } from '@/locales';
import { DateTimeFormatOptions } from '@/constants';

export const BranchGeneralFormForm = () => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const isUpdate = !!watch('id');

    const currentCountry = watch('country');
    const currentStates = watch('state');
    const {
        citiesOption,
        countriesOption,
        statesOption,
        timezoneOption,
        phoneCodeOptions,
        currencyOptions
    } = useLocationOptions(currentCountry, currentStates);

    return (
        <Spacer>
            <Spacer type='margin' bottom={12}>
                <b>General information</b>
            </Spacer>
            <Card>
                <FlexBox
                    direction='column'
                    alignItems={isUpdate ? 'flex-start' : 'flex-start'}
                >
                    <Spacer bottom={6} left={0}>
                        <Text
                            fontWeight={600}
                            lineHeight='20px'
                            color='#344054'
                        >
                            {t(translateCodes.BRANCH_LOGO)}
                        </Text>
                    </Spacer>
                    <AvatarUpload
                        translateCode={t(translateCodes.UPLOAD_PHOTO)}
                        name='logo'
                        shape='square'
                    />
                </FlexBox>
                <Spacer bottom={16} />
                <FlexBox justifyContent='space-between' gap={16}>
                    <InputField
                        name='name'
                        label={t(translateCodes.BRANCH_NAME)}
                        placeholder={translateCodes.BRANCH_NAME_PLACEHOLDER}
                        rules={{
                            required: t(translateCodes.BRANCH_NAME_IS_REQUIRED),
                            maxLength: 255,
                        }}
                    />
                    <InputField
                        addonBefore={
                            <SelectField
                                showSearch
                                allowClear
                                style={{ width: 150 }}
                                name='countryCode'
                                options={phoneCodeOptions}
                            />
                        }
                        name='phoneNumber'
                        label={t(translateCodes.BRANCH_PHONE)}
                        placeholder={t(translateCodes.BRANCH_PHONE_PLACEHOLDER)}
                        rules={{ maxLength: 11 }}
                    />
                </FlexBox>
                <Spacer bottom={16} />
                <FlexBox justifyContent='space-between' gap={16}>
                    <SelectField
                        onSelect={() => {
                            setValue('city', null);
                            setValue('state', null);
                        }}
                        showSearch
                        options={countriesOption}
                        name='country'
                        label={t(translateCodes.BRANCH_COUNTRY)}
                        placeholder={t(
                            translateCodes.BRANCH_COUNTRY_PLACEHOLDER
                        )}
                        rules={{
                            required: t(
                                translateCodes.BRANCH_COUNTRY_IS_REQUIRED
                            ),
                        }}
                    />
                    <SelectField
                        name='timezone'
                        label={t(translateCodes.BRANCH_TIMEZONE)}
                        placeholder={t(
                            translateCodes.BRANCH_TIMEZONE_PLACEHOLDER
                        )}
                        rules={{
                            required: t(
                                translateCodes.BRANCH_TIMEZONE_IS_REQUIRED
                            ),
                        }}
                        options={timezoneOption}
                        showSearch
                    />
                </FlexBox>
                <Spacer bottom={16} />
                <FlexBox justifyContent='space-between' gap={16}>
                    <SelectField
                        onSelect={() => {
                            setValue('city', null);
                        }}
                        options={statesOption}
                        name='state'
                        showSearch
                        label={t(translateCodes.BRANCH_STATE)}
                        placeholder={t(translateCodes.BRANCH_STATE_PLACEHOLDER)}
                        rules={{
                            required: t(
                                translateCodes.BRANCH_STATE_IS_REQUIRED
                            ),
                        }}
                    />
                    <SelectField
                        options={citiesOption}
                        name='city'
                        showSearch
                        label={t(translateCodes.BRANCH_CITY)}
                        placeholder={t(translateCodes.BRANCH_CITY_PLACEHOLDER)}
                        rules={{
                            required: t(translateCodes.BRANCH_CITY_IS_REQUIRED),
                        }}
                    />
                </FlexBox>
                <Spacer bottom={16} />
                <FlexBox justifyContent='space-between' gap={16}>
                    <InputField
                        name='address'
                        label={t(translateCodes.BRANCH_ADDRESS)}
                        placeholder={t(
                            translateCodes.BRANCH_ADDRESS_PLACEHOLDER
                        )}
                        rules={{
                            required: t(
                                translateCodes.BRANCH_ADDRESS_IS_REQUIRED
                            ),
                            maxLength: 255,
                        }}
                    />
                    <InputField
                        name='postalCode'
                        label={t(translateCodes.BRANCH_POSTCODE)}
                        placeholder={t(
                            translateCodes.BRANCH_POSTCODE_PLACEHOLDER
                        )}
                        rules={{
                            required: t(
                                translateCodes.BRANCH_POSTCODE_IS_REQUIRED
                            ),
                            maxLength: 10,
                        }}
                    />
                </FlexBox>
            </Card>
            <Spacer bottom={21} top={24}>
                <b>Advanced information</b>
            </Spacer>
            <Card>
                <FlexBox justifyContent='space-between' gap={16}>
                    <SelectField
                        allowClear
                        name='dateTimeFormat'
                        label={t(translateCodes.BRANCH_DATE_TIME_FORMAT)}
                        placeholder={t(
                            translateCodes.BRANCH_DATE_TIME_FORMAT_PLACEHOLDER
                        )}
                        options={DateTimeFormatOptions}
                    />
                    <SelectField
                        name='languages'
                        allowClear
                        showSearch
                        label={t(translateCodes.BRANCH_LANGUAGES)}
                        defaultValue='en'
                        options={[
                            {
                                value: 'en',
                                label: 'English',
                            },
                        ]}
                        placeholder={t(
                            translateCodes.BRANCH_LANGUAGES_PLACEHOLDER
                        )}
                    />
                </FlexBox>
                <Spacer bottom={16} />
                <FlexBox
                    justifyContent='space-between'
                    gap={16}
                    width='calc(50% - 8px)'
                >
                    <SelectField
                        name='currency'
                        label={t(translateCodes.BRANCH_CURRENCY)}
                        placeholder={t(
                            translateCodes.BRANCH_CURRENCY_PLACEHOLDER
                        )}
                        options={currencyOptions}
                        showSearch
                        allowClear
                    />
                </FlexBox>
            </Card>
        </Spacer>
    );
};
