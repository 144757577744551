import { useSearchParams } from '@esg/ui/hooks/useSearchParams';

export interface PaginationReportRequest{
    readonly filter?: string;
    readonly keyword?: string;
    readonly pageSizeMain?: string;
    readonly pageSizeDetail?: string;
    readonly pageSizeEmployee?: string;
    readonly pageSizeCategory?: string;
    readonly searchByMain?: string;
    readonly searchByDetail?: string;
    readonly searchByEmployee?: string;
    readonly searchByCategory?: string;
    readonly pageMain?: string;
    readonly pageDetail?: string;
    readonly pageEmployee?: string;
    readonly pageCategory?: string;
    readonly orderByMain?: string;
    readonly orderByDetail?: string;
    readonly orderByEmployee?: string;
    readonly orderByCategory?: string;
    readonly mainDirection?: string;
    readonly detailDirection?: string;
    readonly employeeDirection?: string;
    readonly categoryDirection?: string;
    readonly useCountTotal?: string;
}

export const usePaginationReport = (value?: PaginationReportRequest) => {
    const initialValues = {
        pageMain: '1',
        pageDetail: '1',
        pageEmployee: '1',
        pageCategory: '1',
        useCountTotal: 'true',
        ...value
    };
    return useSearchParams<PaginationReportRequest>(initialValues, [
        'filter',
        'keyword',
        'pageMain',
        'pageDetail',
        'pageEmployee',
        'pageCategory',
        'pageSizeMain',
        'pageSizeDetail',
        'pageSizeEmployee',
        'pageSizeCategory',
        'searchByMain',
        'searchByDetail',
        'searchByEmployee',
        'searchByCategory',
        'mainDirection',
        'orderByMain',
        'orderByDetail',
        'orderByEmployee',
        'orderByCategory',
        'detailDirection',
        'employeeDirection',
        'categoryDirection',
        'useCountTotal'
    ]);
};
