import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface DeleteFacilityRequest extends HttpRequestData {}

export const deleteFacility = httpUtil.createHttpRequestMeta<DeleteFacilityRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/facilities/:id',
    method: 'DELETE',
    authentication: 'bearer',
});