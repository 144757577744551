import React from 'react';
import { IconProps } from '../../Types';

export const IconEditPen = (props: IconProps) => {
    return (
        <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.99998 16.6667H17.5M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5124 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41666C16.9404 4.7263 16.9404 3.60701 16.25 2.91666C15.5597 2.2263 14.4404 2.2263 13.75 2.91666L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.4679C2.5 14.6598 2.5 14.8636 2.5 15.2712V16.6667Z' stroke='#667085' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};

IconEditPen.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
