import { Block, ListNameColumn, TableCustom, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import { TableColumnsType, Tag } from 'antd';
import { flatten } from 'lodash';
import { GetDetailSaleResponse, SaleDetailGetAllResponseItem } from '@esg/business-pos';
import { getRevenueProductDetailReport } from '@esg/business-report/api/revenue-report/getRevenueProductDetail';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { SaleCustomerColumn } from '@/pages/fast-pos/sale/children/SaleCustomerColumn';
import SaleEmployeeColumn from '@/pages/fast-pos/sale/children/SaleEmployeeColumn';
import { saleHelper } from '@/helpers';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface RevenueProductDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly productId?: string;
    readonly employeeId?: string;
    readonly productCategoryId?: string;
}

export function RevenueProductDetailTable(props: RevenueProductDetailTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency, formatDate, formatCurrencySymbol } = useLocalesFormat();
    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeDetail: '10',
        orderByDetail: 'code'
    });

    const { data: revenueProductDetailReport, isFetching } = useHttpQuery(getRevenueProductDetailReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            productId: props.productId,
            employeeId: props.employeeId,
            productCategoryId: props.productCategoryId,
            search: searchParams.searchByDetail,
            orderBy: searchParams.orderByDetail,
            orderDirection: searchParams.detailDirection,
            page: searchParams.pageDetail,
            pageSize: searchParams.pageSizeDetail,
            ...searchParams,
        },
    });

    const columns: TableColumnsType<GetDetailSaleResponse> = useMemo(()=>([
        {
            title: 'ID',
            dataIndex: 'code',
            key: 'id',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.detailDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, detailDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.CLIENT),
            dataIndex: 'customer',
            render: (_, record) => {
                return <SaleCustomerColumn record={record.customer} />;
            },
        },
        {
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'saleDetails',
            render: (saleDetails: SaleDetailGetAllResponseItem[]) => <SaleEmployeeColumn saleDetails={saleDetails} />

        },
        {
            title: t(translateCodes.DATE_AND_TIME),
            dataIndex: 'date',
            render: (_, record) => {
                const checkoutAtTime = formatDate(record?.checkoutAt, 'hh:mm a');
                const createdTime = formatDate(record?.date, 'hh:mm a');
                const createdDate = formatDate(record?.date);                
                return (
                    <Block>
                        <p>{createdTime} - {record?.checkoutAt ? checkoutAtTime : ''}</p>
                        <p>{createdDate}</p>
                    </Block>
                );
            },
        },
        {

            title: t(translateCodes.SERVICES_TIME),
            dataIndex: 'saleDetails',
            render: (saleDetails?: SaleDetailGetAllResponseItem[]) => {
                const listService = flatten(saleDetails?.map(a => a.service));
                const names = listService.filter(o => o?.name !== null && o?.name !== undefined).map(o => o?.name);
                const namesUnique = [...new Set(names)];
                return <ListNameColumn names={namesUnique} limit={2} />;
            },
        },
        {
            title: t(translateCodes.TIP),
            dataIndex: 'tip',
            render: (_, record) => {
                return `${record.tip} ${record.tipUnit == 0 ? '%' : formatCurrencySymbol()}`;
            },
        },
        {
            title: t(translateCodes.DISCOUNT),
            dataIndex: 'Discount',
            render: (_, record) => saleHelper.calcDiscountAmount(record.saleDetails ?? []),
        },
        {
            title: t(translateCodes.TOTAL),
            dataIndex: 'totalAmount',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.totalAmount)}
                    </Text>
                );
            },
        },
    ]),[formatCurrency, formatCurrencySymbol, formatDate, searchParams, setSearchParams, t]);

    return (
        <Block width={1300}>
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, pageDetail: page.toString() })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, searchByDetail: value })
                }
                pageInfo={revenueProductDetailReport?.meta}
                titleTableTransCode={translateCodes.BILL_LIST}
                placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                showHeader={true}
                loading={isFetching}
                columns={columns}
                dataSource={revenueProductDetailReport?.items}
                subTitleTable={<Tag color='processing'>{`${revenueProductDetailReport?.meta.totalItems} ${t(translateCodes.BILLS)}`}</Tag>}
            />
        </Block>
    );
}
