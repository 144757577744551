import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteProductUnitRequest extends HttpRequestData {
}

export const deleteProductUnit = httpUtil.createHttpRequestMeta<
    DeleteProductUnitRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/product-units/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
