import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeletePackageRequest extends HttpRequestData {
}

export const deletePackage = httpUtil.createHttpRequestMeta<
    DeletePackageRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/packages/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
