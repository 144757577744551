import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { FlexBox, Text } from '@esg/ui';
import { Tabs, Tag } from 'antd';
import { TabsProps } from 'antd/lib';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeServices, EmployeeWorkingTime } from '.';
import { translateCodes } from '@/locales';

interface EmployeeBusinessProps {
	employee?: EmployeeGetDetailResponse;
	loading?: boolean 
	refetch: () => void
}

export const EmployeeBusiness = (props: EmployeeBusinessProps) => {
    const { employee, refetch, loading } = props;

    const { t } = useTranslation();

    const serviceIds = useMemo(() => 
        employee?.employeeServices.map((s) => s.serviceId),
    [employee?.employeeServices]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: (
                <FlexBox gap={8}>
                    <Text translateCode={translateCodes.SERVICES} />
                    <Tag color='blue'>{serviceIds?.length}</Tag>
                </FlexBox>
            ),
            children: <EmployeeServices {...{ serviceIds, refetch }} />,
        },
        {
            key: '2',
            label: t(translateCodes.WORKING_TIME),
            children: <EmployeeWorkingTime {...{employee, refetch}} />,
        },
    ];

    if(loading) return null;

    return (
        <Tabs 
            defaultActiveKey='1' 
            items={items} 
            style={{ width: '100%' }}
        />
    );
};
