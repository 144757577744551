import { FileImageOutlined } from '@ant-design/icons';
import { AppButton, Block, FlexBox, FormBase, InputField, PhotoWithRemove, Spacer, SwitchField, Text, useHttpCommand } from '@esg/ui';
import { UploadImage } from '@esg/ui/components/upload';
import { Card, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { FileUpload } from '@esg/business-upload';
import { GetCurrentPageBookingSettingResponse, updatePageBookingSetting } from '@esg/business-setting/api/page-booking';
import FormLayoutBookingSetting from './layout-tab/FormLayoutTabSetting';
import { translateCodes } from '@/locales';
import { env } from '@/configs';

interface PagesTabProps {
    pageBookingSetting?: GetCurrentPageBookingSettingResponse;
    refetch: () => void;
    isFetching: boolean;
}

export default function PagesTab(props: PagesTabProps) {
    const { t } = useTranslation();
    const { pageBookingSetting, refetch, isFetching } = props;

    const {mutateAsync} = useHttpCommand(updatePageBookingSetting, {
        onSuccess: () => {
            refetch();
        },
        onError: () => {
            refetch();
        }
    });

    const onSubmit = useCallback(async (values: GetCurrentPageBookingSettingResponse) => {
        await mutateAsync({
            pathData: {id : values.id},
            body: values,
        });
    }, [mutateAsync]);

    return (
        <Block>
            {isFetching ? <Spin fullscreen={true} /> : (
                <FormBase onSubmit={onSubmit} defaultValues={{
                    ...pageBookingSetting,
                    galleryImages: pageBookingSetting?.galleryImages || []
                }}>
                    {(form)=>(
                        <FormLayoutBookingSetting>
                            <Card>
                                <SwitchField
                                    justifyContent='space-between'
                                    name='allowBookingOnline'
                                    label={t(translateCodes.ONLINE_BOOKING)}
                                    description={t(translateCodes.ONLINE_BOOKING_DESCRIPTION)}
                                    width='100%'
                                />
                                <Divider />
                                <FlexBox gap={16} direction='column'>
                                    <InputField
                                        addonBefore='https://'
                                        addonAfter={env.BOOKING_CLIENT}
                                        name='bookingSlug'
                                        label={t(translateCodes.BOOKING_URL)}
                                        description={t(translateCodes.BOOKING_URL_DESCRIPTION)}
                                    />
                                    <InputField
                                        name='bookTabName'
                                        label={t(translateCodes.BOOK_TAB_NAME)}
                                        rules={{ required: true }}
                                        description={t(translateCodes.BOOK_TAB_NAME_DESCRIPTION)}
                                    />
                                    <InputField
                                        name='bookTabInformation'
                                        label={t(translateCodes.BOOK_TAB_INFORMATION)}
                                        rules={{ required: true }}
                                        description={t(translateCodes.BOOK_TAB_INFORMATION_DESCRIPTION)}
                                    />
                                </FlexBox>
                            </Card>
                            <Spacer top={24} />
                            <Card>
                                <SwitchField
                                    justifyContent='space-between'
                                    name='showTopBanner'
                                    label={t(translateCodes.TOP_BANNER)}
                                    description={t(translateCodes.TOP_BANNER_DESCRIPTION)}
                                    width='100%'
                                />
                                <Divider />
                                <PhotoWithRemove
                                    height={300}
                                    borderRadius={12}
                                    value={form.watch('topBannerUrl') || ''}
                                    defaultValue='/assets/setting/defaultTopBannerUrl.jpg'
                                    onClick={()=> form.setValue('topBannerUrl', undefined, {
                                        shouldDirty: true
                                    })}
                                />
                                <Spacer top={16} />
                                <FlexBox gap={16} alignItems='center'>
                                    <UploadImage
                                        name='topBannerUrl'
                                        onChange={(value) => form.setValue('topBannerUrl', value.uri, {
                                            shouldDirty: true
                                        })}
                                    >
                                        <AppButton
                                            icon={<FileImageOutlined />}
                                        >
                                            {t(translateCodes.UPLOAD_PHOTO)}
                                        </AppButton>
                                    </UploadImage>
                                    <Text fontStyle='italic' >PNG, JPG (max. 800x400 px)</Text>
                                </FlexBox>
                            </Card>
                            <Spacer top={24} />
                            <Card>
                                <SwitchField
                                    justifyContent='space-between'
                                    name='showGallery'
                                    label={t(translateCodes.GALLERY)}
                                    description={t(translateCodes.GALLERY_DESCRIPTION)}
                                    width='100%'
                                />
                                <Divider />
                                <InputField
                                    name='galleryTabName'
                                    label='Gallery tab name'
                                    rules={{ required: true }}
                                    description='Custom name for the gallery page'
                                />
                                <Spacer top={16} />
                                <Text fontWeight={600}>{t(translateCodes.GALLERY_IMAGE)}</Text>
                                <Spacer top_bottom={16}>
                                    <Text>{t(translateCodes.GALLERY_IMAGE_DESCRIPTION)}</Text>
                                </Spacer>
                                <FlexBox gap={16} alignItems='center'>
                                    <UploadImage
                                        multiple
                                        name='galleryImages'
                                        onChange={(value) => form.setValue('galleryImages', [...form.watch('galleryImages'), value], {
                                            shouldDirty: true
                                        })}
                                    >
                                        <AppButton
                                            icon={<FileImageOutlined />}
                                        >
                                            {t(translateCodes.UPLOAD_PHOTO)}
                                        </AppButton>
                                    </UploadImage>
                                    <Text fontStyle='italic' >PNG, JPG (max. 800x400 px)</Text>
                                </FlexBox>
                                <Spacer top={16} />
                                <FlexBox gap={16}>
                                    {form.watch('galleryImages')?.map((image: FileUpload) => (
                                        <PhotoWithRemove
                                            width={178}
                                            height={131}
                                            value={image?.uri || ''}
                                            onClick={async () => {
                                                const newImages = form.watch('galleryImages').filter((img:FileUpload) => img.uri !== image.uri);
                                                form.setValue('galleryImages', newImages, {
                                                    shouldDirty: true
                                                });
                                            }}
                                            key={image.uri}
                                        />
                                    ))}
                                </FlexBox>
                            </Card>
                        </FormLayoutBookingSetting>
                    )}
                </FormBase>
            )}
        </Block>
    );
}
