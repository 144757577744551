import { EmployeeCreateRequest, EmployeeGetDetailResponse, resourceApis } from '@esg/business-resource-management';
import {
    FormBase,
    FormLayout,
    managementProfileState,
    useHttpCommand,
    useHttpQuery,
} from '@esg/ui';
import React, { useCallback, useMemo } from 'react';
import { CreateEmployeeFormStep } from '@esg/shared/enum';
import { useRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { managementApis } from '@esg/business-management';
import { EmployeeInfoForm } from '../@share/components/EmployeeInfoForm';
import { EmployeeWorkingTimeForm } from '../@share/components/EmployeeWorkingTimeForm';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';
import { TreeServiceField } from '@/components';

const stepsFormEmployee = [
    {
        title: 'General Info',
        label: 'General Info',
        value: CreateEmployeeFormStep.Info,
    },
    {
        title: 'Services',
        value: CreateEmployeeFormStep.ServiceEmployee,
    },
    {
        title: 'Working Time',
        label: 'Working Time',
        value: CreateEmployeeFormStep.WorkingTime,
    },
];

interface EmployeeFormProps {
	readonly refetchEmployees?: (response?: EmployeeGetDetailResponse) => void;
}

export const EmployeeForm = (props: EmployeeFormProps) => {
    const [currentProfile] = useRecoilState(managementProfileState);

    const { t } = useTranslation();
    const { data: categoriesData, isFetching } = useHttpQuery(managementApis.getAllServiceCategory);

    const { mutateAsync, isPending } = useHttpCommand(resourceApis.createEmployee);

    const onSubmit = useCallback(
        async (data: EmployeeCreateRequest['body']) => {
            const onSuccess = (response?: EmployeeGetDetailResponse) => {
                props.refetchEmployees?.(response);
            };
            await mutateAsync({
                body: data,
            },{
                onSuccess(_){
                    onSuccess();
                    viewRequests.showNotification.send({
                        type: 'success',
                        message: t(translateCodes.NM_SM_01),
                    });
                }
            });
        },
        [mutateAsync, props, t]
    );

    const defaultValues = useMemo(()=> ({
        shifts: currentProfile?.branch?.businessHour,
        serviceIds: categoriesData?.items?.flatMap((category) => category.services.map((service) => service.id)),
        isAllowBooking: true,
    }),[categoriesData?.items, currentProfile?.branch?.businessHour]);

    return (
        <FormBase onSubmit={onSubmit} defaultValues={defaultValues}>
            <FormLayout
                uxMode='steps'
                isLoadingButton={isPending}
                itemsStep={stepsFormEmployee}
                width={826}
            >
                {(stepCurrent) => (
                    <>
                        {stepCurrent === CreateEmployeeFormStep.Info ? (
                            <EmployeeInfoForm />
                        ) : (
                            <PageLayout
                                pageTitleTransCode={stepsFormEmployee[stepCurrent].label}
                                size='small'
                            >
                                {stepCurrent === CreateEmployeeFormStep.ServiceEmployee && (
                                    <TreeServiceField 
                                        label={t(translateCodes.SERVICES)}
                                        serviceCategories={categoriesData?.items}
                                        isLoading={isFetching}
                                    />
                                )}
                                {stepCurrent === CreateEmployeeFormStep.WorkingTime && (
                                    <EmployeeWorkingTimeForm />
                                )}
                            </PageLayout>
                        )}
                    </>
                )}
            </FormLayout>
        </FormBase>
    );
};
