import { FlexBox, Spacer, TableCustom, globalDrawerState, useHttpQuery, Text, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { GetPaymentMethodRevenueItem, getPaymentMethodRevenueReport } from '@esg/business-report/api/revenue-report';
import { useTranslation } from 'react-i18next';
import {RevenuePaymentMethodDetailTable} from './RevenuePaymentMethodDetailTable';
import { translateCodes } from '@/locales';
import { PaymentMethodImage } from '@/components';

interface RevenuePaymentMethodTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function RevenuePaymentMethodTable( props: RevenuePaymentMethodTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency } = useLocalesFormat();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const {data: paymentMethodRevenue, isFetching} = useHttpQuery(getPaymentMethodRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        },
    });

    const viewDetail = useCallback((paymentMethodItem?: GetPaymentMethodRevenueItem) => {
        return setDrawerGlobalState({
            titleTransCode: t(translateCodes.VIEW_DETAIL),
            isOpen: true,
            hideHeader: true,
            content: (
                <RevenuePaymentMethodDetailTable
                    paymentMethodSettingId={paymentMethodItem!.paymentMethod.id}
                    dateRange={dateRange}
                />
            ),
            footer: null,
        });

    },[dateRange, setDrawerGlobalState, t]);

    const columns: TableColumnsType<GetPaymentMethodRevenueItem> = useMemo(()=>([
        {
            key: 'paymentMethod',
            title: t(translateCodes.PAYMENT_METHOD),
            dataIndex: 'paymentMethod',
            render: (_, record) => {
                return (
                    <>
                        <FlexBox>
                            <PaymentMethodImage paymentMethodName={record.paymentMethod.name} />
                            <Spacer right='xs' />
                            {record.paymentMethod.name}
                        </FlexBox>
                    </>
                );
            },
        },
        {
            key: 'quantity',
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
        },
        {
            key: 'total',
            title: t(translateCodes.TOTAL),
            dataIndex: 'total',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.total)}
                    </Text>
                );
            },
        },
        {
            key: 'links',
            title: t(translateCodes.LINKS),
            dataIndex: 'links',
            width: 150,
            render: (_,record) => {
                return (
                    <Tag 
                        className='cursor-pointer' 
                        onClick={()=> viewDetail(record)}
                    >
                        View Detail
                    </Tag>
                );
            }
        },
    ]),[formatCurrency, t, viewDetail]);

    return (
        <TableCustom
            showHeader={false}
            showFooter={false}
            columns={columns}
            dataSource={paymentMethodRevenue?.items}
            loading={isFetching}
        />
    );
}
