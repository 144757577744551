import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface CreateServiceRequest extends HttpRequestData {
    readonly body: {
        name: string;
        serviceCategoryId: string;
        duration?: number;
        description?: string;
        price: number;
        taxSettingId?: string;
        isAllowOnlineBooking: boolean;
    };
}

export const createService = httpUtil.createHttpRequestMeta<
    CreateServiceRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/services',
    method: 'POST',
    authentication: 'bearer',
});
