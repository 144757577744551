import {
    FormBase,
    FormLayout,
    globalDrawerState,
    useHttpCommand
} from '@esg/ui';
import React, { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';
import { EmployeeUpdateRequest, resourceApis } from '@esg/business-resource-management';
import { EmployeeInfoForm } from '../../@share';
import { translateCodes } from '@/locales';

interface EmployeeEditFormProps {
	readonly refetch: () => void;
	readonly defaultValues: EmployeeUpdateRequest['body'];
    readonly onDeleted?: () => void;
}

export const EmployeeEditForm = (props: EmployeeEditFormProps) => {
    const { refetch, defaultValues, onDeleted } = props;
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { mutateAsync, isPending } = useHttpCommand(resourceApis.updateEmployee, {
        onSuccess() {
            refetch();
            resetDrawerState();
        },
    });
    
    const handleSubmit = useCallback(
        async (data: EmployeeUpdateRequest['body']) => {
            await mutateAsync({
                pathData: {
                    id: defaultValues.id
                },
                body: data,
            });
        },
        [defaultValues.id, mutateAsync]
    );

    return (
        <FormBase
            onSubmit={handleSubmit}
            defaultValues= {defaultValues}
        >
            <FormLayout 
                width={826}
                isLoadingButton={isPending}
                labelSubmitCode={translateCodes.SAVE}
                onDeleted={onDeleted}
            >
                <EmployeeInfoForm />
            </FormLayout>
        </FormBase>
    );
};
