import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface AppointmentServiceDetailUpdateRequest extends HttpRequestData {
    readonly body: {
        id: string;
        serviceId: string;
        facilityId: string;
    };
}

export const updateAppointmentServiceDetail = httpUtil.createHttpRequestMeta<
    AppointmentServiceDetailUpdateRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    method: 'PATCH',
    path: '/api/v1/appointment-service-details/:id',
    authentication: 'bearer',
});