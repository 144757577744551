import React from 'react';
import { Spin } from 'antd';
import { Spacer } from '../layouts';
import { mainLayoutTheme } from '../../../../apps/management/src/layouts/main-layout/@shared';
import { SchedulerProps } from './@shared';
import { HorizontalScheduler } from './children';

export const Scheduler = ({loading,...props}: SchedulerProps) => {
    if (props.layout === 'vertical') {
        throw new Error('Vertical layout is not supported');
    }
    const spaceToHeader = 110;

    return (
        <Spin spinning={loading}>
            <Spacer
                top={16}
                type='margin'
                height={`calc(100vh - ${mainLayoutTheme.headerHeight + spaceToHeader}px)`}
            >
                <HorizontalScheduler {...props} />
            </Spacer>
        </Spin>
    );
};
