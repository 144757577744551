import { NotificationPlacement, IconType } from 'antd/es/notification/interface';
import { Mediator } from '../mediator';

export interface ShowNotificationRequest {
	readonly type: IconType;
	readonly title?: string;
	readonly message: React.ReactNode;
	readonly duration?: number;
	readonly placement?: NotificationPlacement;
}

export const viewRequests = {
    showNotification: new Mediator<ShowNotificationRequest, void>(),
    renderApplication: new Mediator<void, void>(),
};