/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateCodes } from '@/locales';

export const serviceRules = {
    name: (t: any) => ({
        required: translateCodes.VAL_PT_01,
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_NAME, { number: 50 }),
        },
    }),
    serviceCategory: {
        required: translateCodes.VAL_PT_02,
    },
    duration: {
        required: translateCodes.VAL_PT_03,
        pattern : {
            value: /^[0-9]+$/,
            message: translateCodes.DURATION_SERVICE_INVALID,
        }
    },
    price: {
        required: translateCodes.PRICE_SERVICE_REQUIRED,
        pattern : {
            value: /^[0-9]+$/,
            message: translateCodes.PRICE_SERVICE_INVALID,
        }
    },
    descriptions: (t: any) => ({
        maxLength: {
            value: 255,
            message: t(translateCodes.MAX_LENGTH_DESCRIPTION, { number: 255 }),
        },
    }),

};
