/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateCodes } from '@/locales';

export const productRulers = {
    name: (t: any) => ({
        required: translateCodes.PRODUCT_NAME_REQUIRED,
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_NAME, { number: 50 }),
        },
    }),
    productCategory: {
        required: translateCodes.PRODUCT_CATEGORY_REQUIRED,
    },
    unit: {
        required: translateCodes.UNIT_REQUIRED,
    },
    originalPrice: {
        required: translateCodes.ORIGINAL_PRICE_REQUIRED,
        pattern : {
            value: /^[0-9.,]*$/,
            message: translateCodes.VAL_PT_06,
        }
    },
    sellingPrice: {
        required: translateCodes.SELLING_PRICE_REQUIRED,
        pattern : {
            value: /^[0-9.,]*$/,
            message: translateCodes.VAL_PT_06,
        }
    },
    capacity: {
        required: translateCodes.CAPACITY_PRICE_REQUIRED,
        pattern : {
            value: /^[0-9.,]*$/,
            message: translateCodes.CAPACITY_INVALID,
        }
    },
    descriptions: (t: any) => ({
        maxLength: {
            value: 255,
            message: t(translateCodes.MAX_LENGTH_DESCRIPTION, { number: 255 }),
        },
    }),

};
