import { deleteTimeOff } from '@esg/business-resource-management';
import { globalModalState, Paragraph, Spacer, useHttpCommand } from '@esg/ui';
import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useShiftContext } from '@/pages/resource-management/employee-shifts/ShiftContext';
import { translateCodes } from '@/locales';

export const useTimeOffDelete = () => {
    const hideModal = useResetRecoilState(globalModalState);
    const [, showModal] = useRecoilState(globalModalState);
    const { refetchShifts } = useShiftContext();
    const { mutateAsync: deleteTimeOffAsync } = useHttpCommand(deleteTimeOff, {
        onSuccess() {
            hideModal();
            refetchShifts();
        },
    });

    const handleDelete = useCallback(
        (id: string) => {
            showModal({
                content: (
                    <Spacer top='xs'>
                        <Paragraph translateCode={translateCodes.DELETE_TIME_OFF_CONFIRM}/>
                    </Spacer>
                ),
                isOpen: true,
                onOk: async () => {
                    await deleteTimeOffAsync({
                        pathData: {
                            id,
                        },
                    });
                },
                showModalDelete: true,
            });
        },
        [deleteTimeOffAsync, showModal]
    );

    return handleDelete;
};
