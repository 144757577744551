import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { GetRoleDetailResponse } from '@esg/business-account';
import { translateCodes } from '@/locales';

export const RoleDeleteConfirm = ({ role }: { role?: GetRoleDetailResponse }) => {
    return (
        <Spacer top='xs'>
            <Text fontWeight='bold' translateCode={translateCodes.DELETE_ROLE} />
            <Spacer bottom='xs' />
            <Paragraph>
                <Text translateCode={translateCodes.DELETE_ROLE_MSG}/>:  &nbsp;
                <Text fontWeight='bold'>{role?.name}</Text>
            </Paragraph>
        </Spacer>
    );
};
