import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../config';
import { GetUserDetailResponse } from './getUserDetail';

interface DeleteUserRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
}

export const deleteUser = httpUtil.createHttpRequestMeta<
    DeleteUserRequest,
    GetUserDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/users/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
