import { AppButton, IconPrint } from '@esg/ui';
import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { GetDetailSaleResponse } from '@esg/business-pos';

import './PrintSale.scss';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';

interface PrintButtonProps {
    sale: GetDetailSaleResponse;
}

export default function PrintButton(props: PrintButtonProps) {
    const [, setCurrentSale] = useRecoilState(currentSaleState);
    const { t } = useTranslation();
    
    const handlePrint = useReactToPrint({
        documentTitle: t(translateCodes.PRINT_THIS_DOCUMENT),
        removeAfterPrint: true,
        content: () => document.getElementById('print-bill') as HTMLElement,
        onBeforeGetContent: () => {
            setCurrentSale(props.sale);
        }
    });
    
    return (
        <AppButton
            width={44}
            onClick={handlePrint}
            icon={<IconPrint/>}
        />
    );
}
