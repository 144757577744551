import React, { useMemo } from 'react';
import { SchedulerItem } from '@esg/ui';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { ViewType } from './Scheduler';
import { SchedulerTable } from './children/scheduler-table';
import { SchedulerTableList, SchedulerTimelineByFacility } from './children';
import { SchedulerItemInfo } from './Types';
interface SchedulerManagementProps {
	readonly viewType: ViewType;
	readonly refetchAppointment: () => void;
	readonly isAppointmentFetching: boolean;
	readonly appointments: AppointmentGetDetailResponse[];
	readonly schedulerItems: SchedulerItem<SchedulerItemInfo>[];
}

export const SchedulerManagement = ({
    appointments,
    schedulerItems,
    refetchAppointment,
    isAppointmentFetching,
    viewType: { viewBy, viewMode },
}: SchedulerManagementProps) => {

    const { viewTableList, viewTimeLineByFacility, viewTimeLineByEmployee } = useMemo(
        () => ({
            viewTimeLineByEmployee: viewBy === 'EMPLOYEE' && viewMode === 'TIMELINE',
            viewTimeLineByFacility: viewBy === 'FACILITY' && viewMode === 'TIMELINE',
            viewTableList:
				(viewBy === 'EMPLOYEE' && viewMode === 'LIST') ||
				(viewBy === 'FACILITY' && viewMode === 'LIST'),
        }),
        [viewBy, viewMode]
    );

    return (
        <>
            {viewTimeLineByEmployee && <SchedulerTable schedulerItems={schedulerItems} />}
            {viewTimeLineByFacility && (
                <SchedulerTimelineByFacility
                    schedulerItems={schedulerItems}
                    refetch={refetchAppointment}
                />
            )}
            {viewTableList && (
                <SchedulerTableList
                    loading={isAppointmentFetching}
                    refetchAppointment={refetchAppointment}
                    appointments={appointments}
                />
            )}
        </>
    );
};
