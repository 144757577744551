import { AppAvatar, FlexBox, IconDoc, SchedulerItem, Spacer, Text } from '@esg/ui';
import { Badge, Popover } from 'antd';
import React from 'react';
import {
    AppointmentStatus,
    getAppointmentBackgroundColor,
    getAppointmentBorderColor,
    getAppointmentStatusLabel,
} from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { PopoverContent } from './children/PopoverContent';
import { translateCodes } from '@/locales';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import './ScheduleAppointmentItem.scss';
import { ScheduleAppointment } from '@/pages/scheduler/Types';
import { useSchedulerContext } from '@/pages/scheduler/@share/SchedulerContext';

interface ScheduleAppointmentItemProps {
	readonly scheduleAppointment: SchedulerItem<ScheduleAppointment>;
	readonly refetch: () => void;
}

export const ScheduleAppointmentItem = (props: ScheduleAppointmentItemProps) => {
    const [zIndex, setZIndex] = React.useState(100);
    const { t } = useTranslation();
    const showAppointmentForm = useAppointmentForm();
    const { scheduleAppointment, refetch } = props;
    const { viewType, employees } = useSchedulerContext();

    const { service, facility, appointment, appointmentServiceEmployee } =
		scheduleAppointment.data.values;

    const employee = employees.find((s) => s.id === appointmentServiceEmployee?.employeeId);

    const { customer, status } = appointment;

    const borderColor = getAppointmentBorderColor(status ?? 0);

    return (
        <Spacer type='padding' size={2} height='calc(100% - 2px)' width='calc(100% - 2px)'>
            <Popover
                content={<PopoverContent item={scheduleAppointment} refetch={refetch} />}
                title={renderTitle(status, borderColor)}
                zIndex={zIndex}
            >
                <div
                    style={{
                        backgroundColor: getAppointmentBackgroundColor(status ?? 0),
                    }}
                    className='schedule-appointment-item'
                    draggable='true'
                    onDragStart={() => {
                        setZIndex(-1);
                    }}
                    onDragEnd={() => {
                        setZIndex(100);
                    }}
                    onClick={() => {
                        showAppointmentForm({ initialValues: appointment , refetch });
                    }}
                >
                    <div
                        className='schedule-appointment-item-content'
                        style={{ borderColor: borderColor }}
                    >
                        <Text color='#475467' fontSize={12}>
                            {scheduleAppointment.startTime.format('hh:mm A')}&nbsp;
                            <ArrowRightOutlined />
								&nbsp;
                            {scheduleAppointment.endTime.format('hh:mm A')}
                        </Text>
                        <Text color='#101828' fontSize={14} fontWeight={700}>
                            {customer?.fullName}
                        </Text>
                        {service?.name && (
                            <FlexBox alignItems='center'>
                                <Text fontSize={12}>
                                    {service?.name} ({service.duration}){' '}
                                    {t(translateCodes.MINS)}
                                </Text>
									&nbsp;&nbsp;∙&nbsp;&nbsp;
                                {viewType.viewBy === 'EMPLOYEE' ? (
                                    <Text>{facility?.name}</Text>
                                ) : (
                                    employee && (
                                        <FlexBox gap={4}>
                                            <AppAvatar data={employee} size='xs' />{' '}
                                            {employee?.fullName}
                                        </FlexBox>
                                    )
                                )}
                            </FlexBox>
                        )}
                    </div>
                    {appointment.notes && (
                        <Spacer top={11} right={11}>
                            <IconDoc />
                        </Spacer>
                    )}
                </div>
            </Popover>
        </Spacer>
    );
};

const renderTitle = (status: AppointmentStatus, color: string) => {
    const label = getAppointmentStatusLabel(status);
    return (
        <div
            style={{
                borderBottom: '1px solid #EAECF0',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '12px',
                gap: '8px',
            }}
        >
            <Badge color={color} />
            <Text color={color}>{label}</Text>
        </div>
    );
};
