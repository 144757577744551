import React from 'react';
import { IconProps } from '../../Types';

export const IconGroup = (props: IconProps) => {
    return (
        <svg {...props} width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none' 
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M18.3346 17.5V15.8333C18.3346 14.2801 17.2723 12.9751 15.8346 12.605M12.918 2.7423C14.1396 3.23679 15.0013 4.43443 15.0013 5.83333C15.0013 7.23224 14.1396 8.42988 12.918 8.92437M14.168 17.5C14.168 15.9469 14.168 15.1703 13.9142 14.5577C13.5759 13.741 12.927 13.092 12.1102 12.7537C11.4977 12.5 10.7211 12.5 9.16797 12.5H6.66797C5.11483 12.5 4.33826 12.5 3.72569 12.7537C2.90893 13.092 2.26002 13.741 1.9217 14.5577C1.66797 15.1703 1.66797 15.9469 1.66797 17.5M11.2513 5.83333C11.2513 7.67428 9.75892 9.16667 7.91797 9.16667C6.07702 9.16667 4.58464 7.67428 4.58464 5.83333C4.58464 3.99238 6.07702 2.5 7.91797 2.5C9.75892 2.5 11.2513 3.99238 11.2513 5.83333Z' stroke={props.color} strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>

    );
};

IconGroup.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#0C6FF3',
};
