import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { PackageDurationType } from '@esg/shared/enum/PackageEnum';
import { env } from '../../configs';
import { GetAllServiceResponseItem } from '../service';

interface GetAllPackageRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

interface PackageCategory {
    name: string;
    description: string;
}
interface PackageDuration {
    readonly id: string;
    value: number;
    type: PackageDurationType;
}
export interface ServicePackage {
    serviceId?: string;
    service?: GetAllServiceResponseItem;
}
interface PackageBranch {
    readonly packageId: string;
    branchId: string;
    taxSettingId: string;
    price: number;
    taxSetting: taxSetting;
}
interface taxSetting {
    readonly id: string;
    readonly name: string;
    readonly value: number;
    readonly descriptions: string;
}
export interface GetAllPackageResponseItem {
    readonly id: string;
    name: string;
    packageCategoryId: string;
    packageCategory?: PackageCategory;
    packageBranches?: PackageBranch[];
    packageDuration?: PackageDuration;
    servicePackages?: ServicePackage[];
    usageLimit?: number;
    description?: string;
    
}

export const getAllPackages = httpUtil.createHttpRequestMeta<
    GetAllPackageRequest,
    PaginationResponse<GetAllPackageResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/packages',
    method: 'GET',
    authentication: 'bearer',
});
