import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface BreakTimeCreateRequest extends HttpRequestData {
	readonly body: {
		date: string;
		type?: number;
		title?: string;
		employeeId: string;
		endTime: string;
		shiftId: string;
		repeats?: number;
		isFixed: boolean;
		startTime: string;
	};
}

export const createBreakTime = httpUtil.createHttpRequestMeta<BreakTimeCreateRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/break-times',
    method: 'POST',
    authentication: 'bearer',
});
