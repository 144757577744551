import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface DeleteCustomerSourceRequest extends HttpRequestData {
}

export const deleteCustomerSource = httpUtil.createHttpRequestMeta<
    DeleteCustomerSourceRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-sources/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
