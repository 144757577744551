import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteProductRequest extends HttpRequestData {
}

export const deleteProduct = httpUtil.createHttpRequestMeta<
    DeleteProductRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/products/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
