import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateBankAccountRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        accountName: string;
        accountNumber: string;
        bankName: string;
    };
}

export interface UpdateBankAccountResponse {
        id?: string;
        accountName: string;
        accountNumber: number;
        bankName: string;
}

export const updateBankAccount = httpUtil.createHttpRequestMeta<
    UpdateBankAccountRequest,
    UpdateBankAccountResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/bank-account-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
