import { managementApis } from '@esg/business-management';
import {
    AppButton,
    FlexBox,
    FormBase,
    Spacer,
    Text,
    useHttpCommand,
    useHttpQuery,
} from '@esg/ui';
import { IconPen } from '@esg/ui/components/icons';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { resourceApis, EmployeeServiceUpdateRequest } from '@esg/business-resource-management';
import { translateCodes } from '@/locales';
import { TreeServiceField } from '@/components';

interface EmployeeServicesProps {
	serviceIds?: string[];
	refetch: () => void;
}

export const EmployeeServices = ({ serviceIds, refetch: refetchEmployee }: EmployeeServicesProps) => {
    const { id: employeeId } = useParams();
    const [isEdit, setIsEdit] = useState(false);

    const { data: categoriesData, isFetching } = useHttpQuery(managementApis.getAllServiceCategory);

    const { mutateAsync, isPending } = useHttpCommand(resourceApis.updateEmployeeService, {
        onSuccess() {
            setIsEdit(false);
            refetchEmployee();
        },
    });

    const handleSubmit = useCallback(async (data: EmployeeServiceUpdateRequest['body']) =>
        await mutateAsync({
            pathData: { id: employeeId },
            body: {
                id: employeeId,
                serviceIds: data.serviceIds,
            },
        }),
    [mutateAsync, employeeId]
    );

    return (
        <FormBase
            onSubmit={handleSubmit} 
            defaultValues={{ 
                serviceIds: serviceIds
            }}
        >
            <FlexBox  gap={12} justifyContent='space-between'>
                <Text translateCode={translateCodes.SERVICES} fontWeight={700} fontSize={20} />
                {isEdit ? (
                    <FlexBox flex='unset' gap={16}>
                        <AppButton
                            disabled={isPending}
                            translateCode={translateCodes.CANCEL}
                            onClick={() => {
                                setIsEdit(false);
                            }}
                        />
                        <AppButton
                            disabled={isPending}
                            translateCode={translateCodes.SAVE}
                            type='primary'
                            htmlType='submit'
                        />
                    </FlexBox>
                ) : (
                    <AppButton onClick={() => setIsEdit(true)}>
                        <FlexBox gap={6}>
                            <IconPen />
                            <Text translateCode={translateCodes.EDIT}/>
                        </FlexBox>
                    </AppButton>
                )}
            </FlexBox>
            <Spacer size={16} />
            <TreeServiceField 
                mode={isEdit ? 'edit' : 'view'}
                isLoading={isFetching}
                serviceCategories={categoriesData?.items}
            />
        </FormBase>
    );
};
