import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ShiftFormControl } from './ShiftFormControl';
import { ShiftFormValues } from './Types';

interface ShiftFormProps {
	readonly defaultValues: ShiftFormValues;
}

export function EmployeeShiftForm({ defaultValues }: ShiftFormProps) {
    const methods = useForm<ShiftFormValues>({
        defaultValues,
    });

    return (
        <FormProvider {...methods}>
            <ShiftFormControl />
        </FormProvider>
    );
}
