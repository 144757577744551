import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { CreateCustomerGroupResponse } from './createCustomerGroup';

interface UpdateCustomerGroupRequest extends HttpRequestData {
    readonly body: {
        readonly id: string;
        readonly name: string;
        readonly active?: boolean;
    };
}

export const updateCustomerGroup = httpUtil.createHttpRequestMeta<
    UpdateCustomerGroupRequest,
    CreateCustomerGroupResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-groups/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
