import { PropsWithChildren, createContext, useContext } from 'react';
import { notification } from 'antd';
import { useHttpQuery } from '@esg/ui';
import { schedulerApis } from '@esg/business-schedule';
import { RealtimeHandler } from './RealtimeHandler';
import { IRealTimeContext } from './Types';
import { useRealtimeInit } from './@share/hooks/useRealtimeInit';

const RealtimeContext = createContext<IRealTimeContext>(null!);

export const RealtimeRoot = ({
    children,
}: PropsWithChildren) => {
    const [api, contextHolder] = notification.useNotification({
        stack: {
            threshold: 10,
        },
    });

    const { data: aptSetting } = useHttpQuery(schedulerApis.getDetailAppointmentSetting);

    const realtimeConn = useRealtimeInit();

    return (
        <>
            {contextHolder}
            <RealtimeContext.Provider value={{
                aptSetting,
                notify: api,
                realtimeConn,
            }}>
                <RealtimeHandler>{children}</RealtimeHandler>
            </RealtimeContext.Provider>
        </>
    );
};

export const useRealtimeContext = () => {
    const realtimeContext = useContext(RealtimeContext);
    return realtimeContext;
};
