import { Spacer, globalModalState } from '@esg/ui';
import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { TimeBlockUpdateConfirm } from '../../TimeBlockUpdateConfirm';
import { TimeBlockUpdateValues } from '../../Types';
import { translateCodes } from '@/locales';

export const useTimeBlockUpdateConfirm = () => {
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const {
        handleSubmit,
        formState: { defaultValues },
    } = useFormContext<TimeBlockUpdateValues>();

    const showConfirm = useCallback(
        () =>
            handleSubmit((values) => {
                setModalGlobalState({
                    titleTransCode: translateCodes.UPDATE_TIME_BLOCK,
                    content: (
                        <Spacer top='xs'>
                            <TimeBlockUpdateConfirm
                                values={values}
                                defaultValues={defaultValues as TimeBlockUpdateValues}
                            />
                        </Spacer>
                    ),
                    isOpen: true,
                    footer: null,
                });
            }),
        [handleSubmit, setModalGlobalState, defaultValues]
    );

    return { showUpdateConfirm: showConfirm(), isUpdate: !!defaultValues?.id };
};
