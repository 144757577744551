import { schedulerApis } from '@esg/business-schedule';
import { SchedulerItem, SchedulerRowInfo, useHttpCommand } from '@esg/ui';
import { Resetter } from 'recoil';
import { ScheduleAppointment } from '@/pages/scheduler/Types';

export const useSchedulerTableBusiness = ({
    refetchAppointment,
    resetModal,
}: {
	refetchAppointment: () => void;
	resetModal: Resetter;
}) => {
    const { mutateAsync: updateAppointmentServiceEmployee } = useHttpCommand(
        schedulerApis.updateAppointmentServiceEmployee,
        {
            onSuccess: async () => {
                refetchAppointment();
                resetModal();
            },
        }
    );

    const { mutateAsync: createAppointmentServiceEmployee } = useHttpCommand(
        schedulerApis.createAppointmentServiceEmployee,
        {
            onSuccess: async () => {
                refetchAppointment();
                resetModal();
            },
        }
    );

    const { mutateAsync: deleteAppointmentServiceEmployee } = useHttpCommand(
        schedulerApis.deleteAppointmentServiceEmployee,
        {
            onSuccess: async () => {
                refetchAppointment();
                resetModal();
            },
        }
    );

    return (item: SchedulerItem<ScheduleAppointment>, rowInfo: SchedulerRowInfo) => {
        if (rowInfo.key === '0') {
            deleteAppointmentServiceEmployee({
                pathData: { id: item.key },
            });
            return;
        }

        if (item.rowKey === '0') {
            createAppointmentServiceEmployee({
                body: {
                    employeeId: rowInfo.key,
                    appointmentServiceDetailId: item.data.values.id,
                    timeStartWork: item.startTime.toISOString(),
                    timeEndWork: item.endTime.toISOString(),
                },
            });
            return;
        }

        updateAppointmentServiceEmployee({
            pathData: { id: item.key },
            body: {
                id: item.key,
                employeeId: rowInfo.key,
            },
        });
    };
};
