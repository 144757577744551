import { CloseOutlined } from '@ant-design/icons';
import { AppAvatar, AppButton, FlexBox, IconEye, Spacer, Text } from '@esg/ui';
import React from 'react';
import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';
import { Card } from 'antd';
import { CUSTOMER_DETAIL_URL } from '@/configs';
import { CustomerLabelTag } from '@/components/customer/CustomerLabelTag';

interface CustomerSelectValueProps {
    customer?: GetAllCustomerResponseItem;
    onClear: ()=> void;
    disabled?: boolean;
}

export default function CustomerSelectValue(props: CustomerSelectValueProps) {
    const {customer, onClear, disabled} = props;
    
    return (
        <Card size='small'>
            <FlexBox alignItems='center' gap={16}>
                <AppAvatar data={props.customer} size='lg' />
                <FlexBox direction='column'>
                    <FlexBox>
                        <Text ellipsis fontWeight='bold' maxWidth={200} maxLines={1}>{customer?.fullName}</Text>
                        <Spacer right={10}/>
                        <CustomerLabelTag customer={customer as GetCustomerDetailResponse} /> 
                    </FlexBox>
                    <FlexBox flexWrap='wrap' justifyContent='flex-start'>
                        <Text ellipsis maxWidth={200} maxLines={1}>{customer?.email}</Text>
                        <Spacer left={8}/>
                        <Text ellipsis maxWidth={200} maxLines={1}>{customer?.phone}</Text>
                    </FlexBox>
                </FlexBox>
                <FlexBox flex='unset' gap={4}>
                    <AppButton
                        type='text'
                        shape='circle'
                        href={`${CUSTOMER_DETAIL_URL}/${customer?.id}`}
                    >
                        <IconEye />
                    </AppButton>
                    {!disabled && (
                        <AppButton shape='circle' type='text' onClick={onClear}>
                            <CloseOutlined />
                        </AppButton>
                    )}
                </FlexBox>
            </FlexBox>
        </Card>
    );
}
