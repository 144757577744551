import { 
    ActionsColumn, AppButton, ColumnAction, IconPen, 
    IconRevert, TableCustom, globalDrawerState, globalModalState, 
    translateCodes, useHttpCommand, useHttpQuery, usePagination 
} from '@esg/ui';

import React, { useCallback } from 'react';
import {  TableColumnsType, Tag } from 'antd';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllEmailTemplateResponseItem, emailTemplateApi } from '@esg/business-success-platform';
import { StatusEmailTemPlate } from '@esg/business-success-platform/api/email-template/emailTemplateCreate';
import { useTranslation } from 'react-i18next';
import { EmailTemplateDeleteConfirm } from './EmailTemplateDeleteConfirm';
import { EmailTemplateForm } from '@/components/form/email-template/EmailTemplateForm';
import * as locales  from '@/locales';

interface EmailTemplateValues {
    id: string;
    type?: string;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    SubjectCustom?: string;
}

export function EmailTemplate() {
    const { t } = useTranslation();
    const { translateCodes: emailTranslateCodes} = locales;
    const [, setModalState] = useRecoilState(globalModalState);
    const [searchParams, setSearchParams] = usePagination();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const {
        data: emailTemplates,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(emailTemplateApi.emailTemplateGetAll, {
        query: searchParams,
    });
    const { mutateAsync: updateEmailTemplate, } = useHttpCommand(
        emailTemplateApi.emailTemplateUpdate
    );

    const onRevert = React.useCallback(
        async (values: EmailTemplateValues) => {
            await updateEmailTemplate({
                pathData: { id: values.id },
                body: {
                    ...values,
                    IsRevert: true
                },
            },
            {
                onSuccess() {
                    refetch();
                },
            }
            );
            return;

        },
        [refetch, updateEmailTemplate]
    );
    const onEdit = React.useCallback(
        (values: GetAllEmailTemplateResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: values.type,
                content: (
                    <EmailTemplateForm
                        onSuccess={() => {
                            refetch(),
                            resetDrawerState();
                        }
                        }
                        defaultValues={values}
                    />
                ),
            });
        },
        [setDrawerState, refetch, resetDrawerState]
    );

    const showRevertConfirm = React.useCallback((emailTemplate: GetAllEmailTemplateResponseItem) => {
        setModalState({
            content: (
                <EmailTemplateDeleteConfirm />
            ),
            isOpen: true,
            onOk: () => {
                onRevert(emailTemplate);
            },
            showModalConfirm: true,
        });
    }, [setModalState, onRevert]);

    const actionsTable = useCallback((emailTemplate: GetAllEmailTemplateResponseItem) => {
        let actions: ColumnAction[] = [
            {
                icon: <IconPen />,
                tooltipTitle: translateCodes.EDIT,
                onClick: () => onEdit(emailTemplate)
            },
        ];

        if (emailTemplate.status != StatusEmailTemPlate.SystemTemPlate) {
            actions = [
                ...actions,
                {
                    icon: (
                        <AppButton
                            type='primary'
                            danger
                            icon={<IconRevert color='#fff' />}
                            onClick={() => showRevertConfirm(emailTemplate)}
                        />
                    ),
                    tooltipTitle: translateCodes.REVERT
                },
            ];
        }

        return actions;

    }, [onEdit, showRevertConfirm]);
    const columns: TableColumnsType<GetAllEmailTemplateResponseItem> = [
        {
            title: t(emailTranslateCodes.TYPE),
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: t(emailTranslateCodes.EMAIL_TEMPLATE_SUBJECT),
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: t(emailTranslateCodes.STATUS),
            dataIndex: 'status',
            key: 'Status',
            render: (_, record) => {
                return record.status == StatusEmailTemPlate.SystemTemPlate 
                    ? <Tag color='success'> {t(emailTranslateCodes.EMAIL_TEMPLATE_SYSTEM)}</Tag> 
                    : <Tag color='blue'> {t(emailTranslateCodes.EMAIL_TEMPLATE_CUSTOM)}</Tag>;
            }
        },
        {
            width: 116,
            title:  t(emailTranslateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ActionsColumn
                        actions={actionsTable(record)}
                    />
                );
            },
        },
    ];
    return (
        <TableCustom
            onPageChange={(page) =>
                setSearchParams({
                    ...searchParams,
                    page: page.toString(),
                })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            loading={isFetching || isLoading}
            titleTableTransCode={emailTranslateCodes.EMAIL_TEMPLATE_LIST}
            placeholderSearchTransCode={emailTranslateCodes.EMAIL_TEMPLATE_SEARCH}
            columns={columns}
            dataSource={emailTemplates?.items ?? []}
        />
    );
}
