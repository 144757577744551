import React, { useMemo } from 'react';
import { DualAxes } from '@ant-design/plots';
import { Block, Spacer, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { getOverviewChartRevenueReport } from '@esg/business-report';
import { Card } from 'antd';
import { Dayjs, dayjs } from '@esg/shared';
import { ViewType } from '@esg/shared/enum';
import { chartHelper } from '@/helpers';

interface AnalyserRevenueChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface DataColumnChart{
    date: string;
    Revenue: number;
    Sale: number;
}
interface DataLineChart{
    date: string;
    Revenue: number;
    count: number;
}

export default function AnalyserRevenueChart(props: AnalyserRevenueChartProps) {
    const { dateRange } = props;
    const numberOfDays = dateRange[1].diff(dateRange[0], 'd');
    const viewType = useMemo(()=>chartHelper.getViewTypeChart(numberOfDays),[numberOfDays]);
    const { formatCurrency } = useLocalesFormat();

    const {data: overviewChartRevenue, isLoading} = useHttpQuery(getOverviewChartRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            viewType: viewType,
        }
    });

    const dataColumnChart : DataColumnChart[] = useMemo(()=>overviewChartRevenue?.overviewChartInfos.map(o=> ({
        ...o,
        Revenue: o.totalRevenue,
        Sale: o.totalSale,
        date: chartHelper.getDayOrdinalFromDate(o.date)
    })) ?? [], [overviewChartRevenue?.overviewChartInfos]);

    const dataLineChart : DataLineChart[] = useMemo(()=>overviewChartRevenue?.overviewChartInfos.map(o=> ({
        date: dayjs(o.date).format('HH:mm'),
        Revenue: o.totalRevenue,
        count: o.totalSale
    })) ?? [],[overviewChartRevenue?.overviewChartInfos]);

    const configColumnChart = useMemo(()=>({
        data: dataColumnChart,
        xField: 'date',
        legend: {
            color: {
                layout: {
                    justifyContent: 'flex-end'
                },
            },
        },
        scrollbar: { 
            x: { 
                ratio: 0.7 ,
                style: {
                    trackSize: 15
                }
            },
        },
        children: [
            {
                type: 'interval',
                yField: 'Revenue',
                style: { 
                    maxWidth: 40,
                    radiusTopLeft: 10,
                    radiusTopRight: 10, 
                },
                color: '#2970FF',
                name: 'Revenue',
                tooltip: {channel: 'y', valueFormatter: (d:number) => formatCurrency(d)}
            },
            {
                type: 'line',
                yField: 'Sale',
                style: { lineWidth: 2, stroke: '#F04438',},
                axis: { y: { position: 'right' } },
                color: '#F04438',
                name: 'Sale',
            },
        ],
        scale: {
            x: { 
                type: 'band',
            },
            color : {
                range: ['#2970FF', '#F04438'],
            },
        },
    }),[dataColumnChart, formatCurrency]);

    const configLineChart = useMemo(()=> ({
        data: dataLineChart,
        xField: 'date',
        legend: true,
        children: [
            {
                type: 'line',
                yField: 'Revenue',
                style: {
                    stroke: '#2970FF',
                    lineWidth: 2,
                },

                axis: {
                    y: {
                        style: { titleFill: '#2970FF' },
                    },
                },
            },
            {
                type: 'line',
                yField: 'count',
                style: {
                    stroke: '#F4664A',
                    lineWidth: 2,
                },
                axis: {
                    y: {
                        position: 'right',
                        style: { titleFill: '#F4664A' }
                    },
                },
            },
        ],
        scale: { color: { range: ['#2970FF', '#F4664A'] } },
    }),[dataLineChart]);

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={30}>
                <Card>
                    <Text fontSize={18} fontWeight={600}>Total Revenues</Text>
                    {!isLoading && (
                        <>
                            {viewType === ViewType.Hour && (
                                <DualAxes {...configLineChart}/>
                            )}
                            {viewType !== ViewType.Hour && (
                                <DualAxes {...configColumnChart} />
                            )}
                        </>
                    )}
                </Card>
            </Spacer>
        </Block>
    );
}
