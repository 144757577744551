import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { ShiftDisableGetDetailResponse } from '../shift-disable';
import { BreakTimeGetDetailResponse } from '../break-time';
import { TimeOffGetDetailResponse } from '../time-off';
import { EmployeeGetDetailResponse } from '../employee';
import { TimeBlockDetailResponse } from '../time-block';
import { TimeBlockDisableDetailResponse } from '../time-block-disable';

export interface ShiftGetDetailResponse {
	readonly id: string;
	readonly date?: string;
	readonly employeeId: string;
	readonly endTime: string;
	readonly tenantId: string;
	readonly isDayOff: boolean;
	readonly startTime: string;
	readonly dayOfWeek: number;
	readonly dayOfWeekTitle: string;
	readonly organizationId: string;
	readonly breakTimes: BreakTimeGetDetailResponse[];
	readonly employee: EmployeeGetDetailResponse
}

export interface ShiftByEmployeeDetail extends ShiftGetDetailResponse {
	readonly timeOff?: TimeOffGetDetailResponse
	readonly timeBlocks: TimeBlockDetailResponse[]
}

interface ShiftsGetByEmployeeRequest extends HttpRequestData {
	query: {
		startDate: string;
		endDate: string;
	};
}

export interface ShiftsGetByEmployeeResponse {
	readonly employeeId: string;
	readonly shifts: ShiftByEmployeeDetail[];
	readonly shiftDisables: ShiftDisableGetDetailResponse[];
	readonly timeOffs: TimeOffGetDetailResponse[]
	readonly timeBlocks: TimeBlockDetailResponse[]
	readonly timeBlockDisables: TimeBlockDisableDetailResponse[]
}

export const getShiftsByEmployee = httpUtil.createHttpRequestMeta<
	ShiftsGetByEmployeeRequest,
	ShiftsGetByEmployeeResponse[]
>({
    method: 'GET',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/shifts/by-employee',
});
