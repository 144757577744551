import { Block, FlexBox, Header, Spacer, Text } from '@esg/ui';
import React from 'react';
import './Integration.scss';
import { Tag } from 'antd';
import {  IntegrationEmailGetAllResponseItem } from '@esg/business-setting/api/integration/integrationEmailGetAll';
import { getIntegrationService, getIntegrationStatus } from '@esg/shared/utils/integrationUtils';

interface IntegrationProps {
    integration: IntegrationEmailGetAllResponseItem
	desc: string;
    subTitle: string;
	icon: React.ReactNode;
    onClick: () => void;
}

export const Integration = (props: IntegrationProps) => {
    const {  desc, icon, integration, subTitle } = props;

    const serviceName = getIntegrationService(integration.configuration.service)!;
    const status = getIntegrationStatus(integration.status);

    return (
        <Block className='integration' onClick={props.onClick}>
            <Block className='icon'>
                {icon}
            </Block>
            <Block className='content'>
                <FlexBox className='header' justifyContent='space-between' alignItems='start'>
                    <Block>
                        <Header level={4} translateCode={serviceName} />
                        <Text translateCode={subTitle} fontSize={12} />
                    </Block>
                    <Tag color={status === 'Connected' ? 'green' : 'red'}>{status}</Tag>
                </FlexBox>
                <Spacer bottom={12} />
                <Text className='desc' translateCode={desc}/>
            </Block>
        </Block>
    );
};
