import { Spacer } from '@esg/ui';
import React from 'react';

export function GeneralInformation() {
    return (
        <Spacer size='md'>
            <div>GeneralInformation</div>
        </Spacer>
    );
}
