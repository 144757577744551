import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { AppointmentStatus } from '@esg/shared';
import { checkValidAptServiceDetail } from '../appointmentUtils';
import { useAvailableAppointmentDate } from './useAvailableAppointmentDate';

export const useValidSubmit = () => {
    const unAvailableDate = useAvailableAppointmentDate();
    const {
        formState: { isSubmitting, isDirty, defaultValues },
        getValues,
        watch,
    } = useFormContext();

    const isValidService = checkValidAptServiceDetail(getValues('appointmentServices'));

    const status = watch('status');

    const isDisable = useMemo(
        () =>
            isSubmitting ||
			!isValidService ||
			unAvailableDate ||
			(!isDirty && Boolean(defaultValues?.id)) ||
			status === AppointmentStatus.Completed,
        [defaultValues?.id, isDirty, isSubmitting, isValidService, status, unAvailableDate]
    );
    
    return isDisable;
};
