import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetServiceAppointmentRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}
export interface GetServicesAppointmentItem {
    id: string;
    name: string;
    quantity: number;
    revenue: number;
}

export const getServicesAppointment = httpUtil.createHttpRequestMeta<
    GetServiceAppointmentRequest,
	PaginationResponse<GetServicesAppointmentItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments-report/services-summary',
    authentication: 'bearer',
});