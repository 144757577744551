import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { IntegrationEmailConfig } from './Types';

export interface IntegrationEmailUpdateAsConnectRequest extends HttpRequestData {
	body: {
		readonly configuration: IntegrationEmailConfig
	};
}

export const integrationEmailUpdateAsConnect = httpUtil.createHttpRequestMeta<IntegrationEmailUpdateAsConnectRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/integration-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});