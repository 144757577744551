import './SaleAnalyzerRevenue.scss';

import React, { useState } from 'react';
import { Dayjs } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import AnalyserRevenueHeader from './children/AnalyserRevenueHeader';
import AnalyserRevenueOverview from './children/AnalyserRevenueOverview';
import AnalyserRevenueChart from './children/AnalyserRevenueChart';
import AnalyserRevenueTabs from './children/AnalyserRevenueTabs';
import { PageLayout } from '@/layouts';
import { dateHelper } from '@/helpers';
import { translateCodes } from '@/locales';

export function SaleAnalyzerRevenue() {
    const { t } = useTranslation();
    const {startOfMonth, endOfMonth} = dateHelper.getStartAndEndOfMonth();
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([startOfMonth, endOfMonth]);

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.REVENUE_REPORT)}
            className='sale-analyzer-revenue'
            rightHeaderPage={<AnalyserRevenueHeader 
                dateRange={dateRange} 
                setDateRange={(dates)=>{
                    setDateRange([dates[0].startOf('day'), dates[1].endOf('day')]);
                }}/>}
        >
            <AnalyserRevenueOverview dateRange={dateRange}/>
            <AnalyserRevenueChart dateRange={dateRange}/>
            <AnalyserRevenueTabs dateRange={dateRange}/>
        </PageLayout>
    );
}
