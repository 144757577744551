import { ViewType } from '@esg/shared/enum';
import dayjs from 'dayjs';

export const chartHelper = {
    getDayOrdinalFromDate: (dateString: string) => {
        const parsedDate = dayjs(dateString);
        
        const dayOfMonth = parsedDate.date();
    
        let suffix = 'th';
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            suffix = 'st';
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            suffix = 'nd';
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            suffix = 'rd';
        }
    
        const result = dayOfMonth + suffix;
    
        return result;
    },

    getViewTypeChart: (numberOfDays: number) => {
        if (numberOfDays === 0) {
            return ViewType.Hour;
        }
    
        if (numberOfDays === 1) {
            return ViewType.Day;
        }
    
        if (numberOfDays <= 7) {
            return ViewType.Day;
        }
    
        if (numberOfDays <= 30) {
            return ViewType.Day;
        }
    
        return ViewType.Hour;
    },
    getLabelDateByViewChart: (viewType: ViewType, date: string) => {
        switch (viewType) {
        case ViewType.Hour: 
            return dayjs(date).format('HH:mm');
        case ViewType.Day:
            return chartHelper.getDayOrdinalFromDate(date);
        case ViewType.Week: 
            return dayjs(date).format('ddd');
        case ViewType.Month:
            chartHelper.getDayOrdinalFromDate(date);
            return;
        }
    }
};