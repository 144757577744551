import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

interface CloseFastSaleTabProps {
    isPending: boolean;
    onInActive: () => void;
}

export const CloseFastSaleTab : React.FC<CloseFastSaleTabProps> = ({isPending, onInActive}) => {
    const [showPending, setShowPending] = useState(false);

    const onSetInActive=()=>{
        setShowPending(true); 
        onInActive(); 
    };

    return (
        <span
            className='mgl8'
            onClick={()=> {onSetInActive();}}
        >
            {
                showPending && isPending
                && <LoadingOutlined
                    style={{
                        width: 16,
                        color: '#667085',

                    }}
                />
                || <CloseOutlined
                    style={{
                        width: 16,
                        color: '#667085',
                    }}
                />
            }
        </span>
    );
};
