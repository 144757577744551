import { EmployeeGetDetailResponse, EmployeeUpdateRequest } from '@esg/business-resource-management';
import { genderUtil } from '@esg/shared';
import { JOB_STATUS, jobStatusUtil } from '@esg/shared/utils/jobStatusUtil';
import {
    AppAvatar,
    Block,
    FlexBox,
    globalDrawerState,
    Header,
    managementProfileState,
    Spacer,
    Text,
    useHttpQuery,
    useLocalesFormat
} from '@esg/ui';
import {
    IconBag,
    IconCalendar,
    IconCheckCircle,
    IconFace,
    IconLocation,
    IconMail,
    IconPen,
    IconPhone,
    IconSharp
} from '@esg/ui/components/icons';
import {
    Card,
    Divider,
    Tag,
    theme
} from 'antd';
import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { branchApis } from '@esg/business-organization';
import { EmployeeEditForm } from './EmployeeEditForm';
import { translateCodes } from '@/locales';
const {useToken} = theme;

interface EmployeeInformationProps {
	readonly employee?: EmployeeGetDetailResponse;
	readonly loading?: boolean;
	readonly refetch: () => void;
    readonly onDeleted?: () => void;
}

export const EmployeeInformation = (props: EmployeeInformationProps) => {
    const { employee, loading, refetch, onDeleted } = props;
    const {token} = useToken();

    const { formatDate } = useLocalesFormat();
    const [ currentProfile ] = useRecoilState(managementProfileState);
    const [,setDrawerState] = useRecoilState(globalDrawerState);

    const { data: branchesResponse } = useHttpQuery(branchApis.getAllBranches);

    const handleEdit = () => {
        setDrawerState({
            isOpen: true,
            titleTransCode: translateCodes.EDIT_EMPLOYEE,
            content: (
                <EmployeeEditForm
                    onDeleted={onDeleted}
                    defaultValues={employee as EmployeeUpdateRequest['body']}
                    refetch={refetch}
                />
            ),
        });
		
    };
    const statusColor = useMemo(() => 
        employee?.jobStatus == JOB_STATUS.SEPARATED ? 'warning' : 'success'
    , [employee?.jobStatus]);

    const currentBranch = useMemo(() => 
        branchesResponse?.items.find( o => o.id == currentProfile?.branchActive),
    [branchesResponse, currentProfile]);

    return (
        <Card className='employee-info' loading={loading}>
            <Card className='employee-edit-btn' size='small' onClick={handleEdit} hoverable>
                <IconPen />
            </Card>
            <AppAvatar
                data={employee}
                size='2xl'
                className='employee-avatar'
            />
            <Spacer bottom='md' />
            <FlexBox direction='column' gap={16}>
                <Header level={2} translateCode={employee?.fullName} />
                <Block>
                    <Text translateCode={translateCodes.GENERAL_INFO} fontWeight='bold' />
                    <Spacer bottom={12} />
                    <FlexBox direction='column' gap={16}>
                        {employee?.gender && (
                            <Block className='employee-info-item'>
                                <IconFace color={token.colorPrimary}/>
                                <Text>{genderUtil(employee?.gender)}</Text>
                            </Block>
                        )}
                        {employee?.dateOfBirth && (
                            <Block className='employee-info-item'>
                                <IconCalendar color={token.colorPrimary}/>
                                <Text>
									Born on {formatDate(employee.dateOfBirth)}
                                </Text>
                            </Block>
                        )}
                        {employee?.email && (
                            <Block className='employee-info-item'>
                                <IconMail color={token.colorPrimary}/>
                                <Text translateCode={employee.email} />
                            </Block>
                        )}
                        {employee?.phone && (
                            <Block className='employee-info-item'>
                                <IconPhone color={token.colorPrimary}/>
                                <Text translateCode={employee.phone} />
                            </Block>
                        )}
                        {employee?.address && (
                            <Block className='employee-info-item'>
                                <IconLocation color={token.colorPrimary}/>
                                <Text translateCode={employee.address} />
                            </Block>
                        )}
                    </FlexBox>
                </Block>
                <Divider style={{ margin: 0 }} />
                <Block>
                    <Text translateCode={translateCodes.JOB_INFO} fontWeight='bold' />
                    <Spacer bottom={12} />
                    <FlexBox direction='column' gap={16}>
                        <Block className='employee-info-item'>
                            <IconBag color={token.colorPrimary}/>
                            <Tag color={statusColor}>{jobStatusUtil(employee?.jobStatus)}</Tag>
                        </Block>
                        <Block className='employee-info-item'>
                            <IconSharp color={token.colorPrimary}/>
                            <Text translateCode={employee?.employeeCode} />
                        </Block>
                        {employee?.jobTypeId && (
                            <Block className='employee-info-item'>
                                <IconBag color={token.colorPrimary}/>
                                <Text translateCode={employee.jobType?.name} />
                            </Block>
                        )}
                        {employee?.jobStartDate && (
                            <Block className='employee-info-item'>
                                <IconCalendar color={token.colorPrimary}/>
                                <Text>
									Start on {formatDate(employee.jobStartDate)}
                                </Text>
                            </Block>
                        )}
                        {employee?.branchId && (
                            <Block className='employee-info-item'>
                                <IconLocation color={token.colorPrimary}/>
                                <Text>
									Work at <Tag color='cyan'>{currentBranch?.name}</Tag>
                                </Text>
                            </Block>
                        )}
                        {employee?.isAllowBooking && (
                            <Block className='employee-info-item'>
                                <IconCheckCircle color={token.colorPrimary}/>
                                <Text translateCode={translateCodes.BOOKING_ALLOWED} />
                            </Block>
                        )}
                    </FlexBox>
                </Block>
            </FlexBox>
        </Card>
    );
};
