import { useDrop } from 'react-dnd';
import React from 'react';
import { SchedulerItem } from '../SchedulerTypes';

interface SchedulerDroppableFieldProps {
    acceptType: string;
    className?: string;
    style?: React.CSSProperties;
    droppableBgColor?: string;
    onDragOver?: (item: SchedulerItem) => void;
    onDragLeave?: () => void;
    onDrop?: (item: SchedulerItem) => void;
    validateDroppable?: (item: SchedulerItem) => boolean;
}

export const SchedulerDroppableField = (props: React.PropsWithChildren<SchedulerDroppableFieldProps>) => {
    const [{ droppable }, drop] = useDrop(() => ({
        accept: props.acceptType,
        hover: (item: SchedulerItem) => {
            if (props.onDragOver) {
                props.onDragOver(item);
            }
        },
        drop: (item: SchedulerItem) => {
            if (props.onDrop) {
                props.onDrop(item);
            }
        },
        canDrop: props.validateDroppable,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            droppable: monitor.canDrop(),
        }),
    }), [props.acceptType, props.onDragOver, props.onDrop, props.validateDroppable]);

    const style = React.useMemo((): React.CSSProperties => ({
        ...props.style,
        backgroundColor: droppable ? props.droppableBgColor : 'transparent'
    }), [droppable, props.droppableBgColor, props.style]);

    const className = React.useMemo(
        () => `${props.className} ${droppable ? 'droppable' : ''}`,
        [props.className, droppable]
    );

    return (
        <div ref={drop} className={className} style={style} onDragLeave={props.onDragLeave}>
            {props.children}
        </div>
    );
};

SchedulerDroppableField.defaultProps = {
    droppableBgColor: '#ECFDF3',
};