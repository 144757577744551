import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface DeletePaymentMethodRequest extends HttpRequestData {
}

export const deletePaymentMethod = httpUtil.createHttpRequestMeta<
    DeletePaymentMethodRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/payment-method-settings/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
