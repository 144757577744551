import React from 'react';
import { IconProps } from '../../Types';

export const IconAppointmentOnline = (props: IconProps) => {
    return (
        <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                opacity='0.4'
                d='M22.3999 12V14C22.3999 14.2053 22.3999 14.405 22.3997 14.5992C21.4367 13.4677 20.0021 12.75 18.3999 12.75C15.5004 12.75 13.1499 15.1005 13.1499 18C13.1499 19.6022 13.8676 21.0368 14.9992 21.9998C14.8049 22 14.6052 22 14.3999 22H10.3999C6.62867 22 4.74305 22 3.57148 20.8284C2.3999 19.6569 2.3999 17.7712 2.3999 14V12C2.3999 11.161 2.3999 10.4153 2.4128 9.75H22.387C22.3999 10.4153 22.3999 11.161 22.3999 12Z'
                fill={props.color}
            />
            <path
                d='M8.14975 2.5C8.14975 2.08579 7.81396 1.75 7.39975 1.75C6.98553 1.75 6.64975 2.08579 6.64975 2.5V4.07926C5.21042 4.19451 4.26552 4.47737 3.57132 5.17157C2.87712 5.86577 2.59426 6.81067 2.479 8.25H22.3205C22.2052 6.81067 21.9224 5.86577 21.2282 5.17157C20.534 4.47737 19.5891 4.19451 18.1497 4.07926V2.5C18.1497 2.08579 17.814 1.75 17.3997 1.75C16.9855 1.75 16.6497 2.08579 16.6497 2.5V4.0129C15.9845 4 15.2388 4 14.3997 4H10.3997C9.56071 4 8.81501 4 8.14975 4.0129V2.5Z'
                fill={props.color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.3999 15.75C17.1573 15.75 16.1499 16.7574 16.1499 18C16.1499 19.2426 17.1573 20.25 18.3999 20.25C19.6425 20.25 20.6499 19.2426 20.6499 18C20.6499 16.7574 19.6425 15.75 18.3999 15.75ZM14.6499 18C14.6499 15.9289 16.3288 14.25 18.3999 14.25C20.471 14.25 22.1499 15.9289 22.1499 18C22.1499 18.7643 21.9212 19.4752 21.5286 20.068L22.9302 21.4697C23.2231 21.7626 23.2231 22.2374 22.9302 22.5303C22.6373 22.8232 22.1625 22.8232 21.8696 22.5303L20.4679 21.1287C19.8751 21.5213 19.1642 21.75 18.3999 21.75C16.3288 21.75 14.6499 20.0711 14.6499 18Z'
                fill={props.color}
            />
        </svg>
    );
};

IconAppointmentOnline.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#17B26A',
};
