import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { env } from '../../configs';

interface GetProductsByCategoryRevenueRequest extends HttpRequestData {
	readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetProductsByCategoryRevenueItem {
    id: string;
    name: string;
    quantity: number;
    revenue: number;
}

export const getProductsByCategoryRevenueReport = httpUtil.createHttpRequestMeta<
    GetProductsByCategoryRevenueRequest,
	PaginationResponse<GetProductsByCategoryRevenueItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/product-category',
    authentication: 'bearer',
});