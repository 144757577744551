import { FormLayout ,
    FlexBox,
    FormBase,
    InputField,
    TextAreaField,
    useHttpCommand,
} from '@esg/ui';
import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createRole, GetRoleDetailResponse, updateRole } from '@esg/business-account';

import { roleRules } from '../rules';
import { translateCodes } from '@/locales';

interface RoleFormProps {
    readonly onSuccess?: (response?: GetRoleDetailResponse) => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: Partial<GetRoleDetailResponse>;
}

export const RoleForm = (props: RoleFormProps) => {
    const isUpdate = !!props.defaultValues?.id;
    const { t } = useTranslation();

    const { mutateAsync: createRoleMutate, isPending: isLoadingCreate } = useHttpCommand(
        createRole
    );

    const { mutateAsync: updateRoleMutate, isPending: isLoadingUpdate } = useHttpCommand(
        updateRole
    );

    const onSubmit = React.useCallback(
        async (values: GetRoleDetailResponse) => {
            
            if (isUpdate) {
                return await updateRoleMutate({
                    pathData: { id: props.defaultValues?.id },
                    body: values,
                },
                {onSuccess: props.onSuccess});
            }
            
            await createRoleMutate(
                { body: values }, 
                {onSuccess: props.onSuccess}
            );
        },
        [createRoleMutate, isUpdate, props, updateRoleMutate]
    );

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                onDeleted={props.onDelete}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    props.defaultValues?.id
                        ? translateCodes.SAVE
                        : translateCodes.CREATE
                }
            >
                <Card bordered={false}>
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            rules={roleRules.name(t)}
                            name='name'
                            label={translateCodes.NAME}
                            placeholder={translateCodes.ENTER_ROLE_NAME}
                        />
                        <TextAreaField
                            name='description'
                            rules={roleRules.descriptions(t)}
                            label={translateCodes.DESCRIPTION}
                            placeholder={translateCodes.ENTER_ROLE_DESCRIPTION}
                        />
                    </FlexBox>
                </Card>
            </FormLayout>
        </FormBase>
    );
};
