import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface UpdateAutomationEmailSettingRequest extends HttpRequestData {
    readonly body: {
    id?: string;
    sendNewAppointment?: boolean;
    sendConfirmAppointment?: boolean;
    sendCancelAppointment?: boolean;
    sendCheckInAppointment?: boolean;
    sendNoShowAppointment?: boolean;
    sendWelcomeAppointment?: boolean;
    sendCloseSaleAppointment?: boolean;
    };
}

export interface UpdateAutomationEmailSettingResponse {
    id?: string;
    sendNewAppointment?: boolean;
    sendConfirmAppointment?: boolean;
    sendCancelAppointment?: boolean;
    sendCheckInAppointment?: boolean;
    sendNoShowAppointment?: boolean;
    sendWelcomeAppointment?: boolean;
    sendCloseSaleAppointment?: boolean;
}

export const updateAutomationEmailSetting = httpUtil.createHttpRequestMeta<
    UpdateAutomationEmailSettingRequest,
    UpdateAutomationEmailSettingResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/automation-email-settings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
