import React from 'react';
import { SchedulerItem } from '@esg/ui';
import { ScheduleBreakTimeItem } from './children/schedule-break-time-item';
import { ScheduleAppointment, SchedulerItemInfo } from '@/pages/scheduler/Types';
import { ScheduleAppointmentItem } from '@/pages/scheduler/children/components';

interface SmartSchedulerItemProps {
	readonly refetchAppointment: () => void;
	readonly item: SchedulerItem<SchedulerItemInfo>;
}

export const SmartSchedulerItem = ({ item, refetchAppointment }: SmartSchedulerItemProps) => {
    const type = item.data.type;
    if (type === 'TIME_DISABLE') {
        return <ScheduleBreakTimeItem data={item.data.values} />;
    }

    if (type === 'TIME_OFF') {
        return <div style={{ height: '100%' }} className='scheduler-disabled' />;
    }

    return (
        <ScheduleAppointmentItem
            scheduleAppointment={item as SchedulerItem<ScheduleAppointment>}
            refetch={refetchAppointment}
        />
    );
};
