import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface ConfirmAppointmentRequest extends HttpRequestData {
    body: {
        id: string;
    }
}

export const confirmAppointment = httpUtil.createHttpRequestMeta<
	ConfirmAppointmentRequest,
	void
>({
    method: 'PATCH',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/appointments/:id/confirm',
});