import React from 'react';
import { Block, FlexBox} from '@esg/ui';
import { Dayjs } from '@esg/shared';
import AnalyserClientSourceBarChart from './AnalyserClientSourceBarChart';
import AnalyserClientGroupBarChart from './AnalyserClientGroupBarChart';
import AnalyserClientGenderPieChart from './AnalyserClientGenderPieChart';

interface AnalyserClientSubChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AnalyserClientSubChart(props: AnalyserClientSubChartProps) {
    const { dateRange } = props;
    return (
        <Block overflow='auto' maxWidth='100%'>
            <FlexBox justifyContent='space-between' gap={10} >
                <AnalyserClientSourceBarChart dateRange={dateRange} />
                <AnalyserClientGenderPieChart dateRange={dateRange} />
                <AnalyserClientGroupBarChart dateRange={dateRange} />
            </FlexBox>
        </Block>
    );
}
