import { TFunction } from 'i18next';
import { AppointmentActivity } from '@esg/business-management';
import { dateHelper } from './dateHelper';
import { translateCodes } from '@/locales';

export const appointmentActivityHelper = {
    getContentAppointmentCreate : (
        activities: AppointmentActivity[], 
        formatDate: (date: string) => string,
        t: TFunction<'translation', undefined>
    ) => {
        let content = '';

        activities?.forEach((activity) => {

            switch (activity.entityName) {
            case 'Appointment':
            {
                const currentAppointment = JSON.parse(activity.currentDataJson);
                if (activity.action === 'Added') {
                    const customerName =  activity.data.find((o) => o.field === 'CustomerId')?.changedValue;

                    content += `
                    <b>${t(translateCodes.AVAILABILITY)}:</b> ${formatDate(currentAppointment.AppointmentDate)} <br>
                    <b>${t(translateCodes.NUMBER_OF_GUEST)}:</b> ${currentAppointment.NumberOfGuest} <br>
                    <b>${t(translateCodes.CLIENT)}:</b> ${customerName} <br> <br>`;
                }
                break;
            }
            case 'AppointmentServiceDetail':
            {
                const employeesActivities = activities.filter((o) => 
                    o.entityName === 'AppointmentServiceEmployee' &&
                    o.parentRecordId === activity.recordId
                );

                const employeeNames = employeesActivities.map((o) => {
                    return o.data.find((d) => d.field === 'EmployeeId')?.changedValue;
                }).join(', ');

                activity.data.forEach((data) => {
                    switch (data.field) {
                    case 'ServiceId':
                    {
                        content += t(translateCodes.APM_ACTIVITY_ADD_SERVICE, {
                            serviceName: data.changedValue,
                            guestCode: activity.resource?.guestCode
                        }) + '. <br>';
                        break;
                    }
                    case 'FacilityId':
                    {
                        if(data.changedValue) {
                            content += t(translateCodes.APM_ACTIVITY_ADD_SERVICE_POSITION, {
                                facilityName: data.changedValue,
                                serviceName: activity.resource?.serviceName,
                                guestCode: activity.resource?.guestCode
                            }) + '. <br>';
                        }
                        break;
                    }
                    default:
                        break;
                    }
                });

                if(employeeNames) {
                    content += t(translateCodes.APM_ACTIVITY_ADD_EMPLOYEE, {
                        employeeName: employeeNames,
                        serviceName: employeesActivities?.[0]?.resource?.serviceName,
                        guestCode: employeesActivities?.[0]?.resource?.guestCode
                    }) + '. <br>';
                }
                break;
            }
            default:
                break;
            }
        });

        return content;
    },

    getContentAppointmentEdit: (
        activities: AppointmentActivity[], 
        formatDate: (date: string) => string,
        t: TFunction<'translation', undefined>
    ) => {
        let content = '<br/>';

        activities.forEach((activity) => {
            switch (activity.entityName) {
            case 'Appointment':
            {
                activity.data.forEach((data) => {
                    switch (data.field) {
                    case 'AppointmentDate':
                    {
                        const originDate = dateHelper.transformDateLogToISO(data.originValue);
                        const changedDate = dateHelper.transformDateLogToISO(data.changedValue);
                            
                        content += t(translateCodes.APM_ACTIVITY_CHANGE_APPOINTMENT_DATE, {
                            originValue: formatDate(originDate),
                            changedValue: formatDate(changedDate)
                        }) + '.<br>';
                        break;
                    }
                    case 'NumberOfGuest':
                        content += t(translateCodes.APM_ACTIVITY_CHANGE_NUMBER_OF_GUEST, {
                            originValue: data.originValue,
                            changedValue: data.changedValue
                        }) + '.<br>';
                        break;
                    case 'CustomerId':
                    {
                        content += t(translateCodes.APM_ACTIVITY_CHANGE_CUSTOMER, {
                            originValue: data.originValue,
                            changedValue: data?.changedValue
                        }) + '. <br>';
                        break;
                    }
                    case 'Status':
                        content += t(translateCodes.APM_ACTIVITY_CHANGE_STATUS, {
                            originValue: data.originValue,
                            changedValue: data.changedValue
                        }) + '. <br>';
                        break;
                    default:
                        break;
                    }
                });
                break;
            }
            case 'AppointmentService':
            {

                switch (activity.action) {
                case 'Deleted':
                {
                    content += t(translateCodes.APM_ACTIVITY_DELETE_GUEST, {
                        guestCode: activity.resource?.guestCode
                    }) + '. <br>';
                    break;
                }
                default:
                    break;
                }
                break;
            }
            case 'AppointmentServiceDetail':
            {

                switch (activity.action) {
                case 'Added':
                {
                    content += t(translateCodes.APM_ACTIVITY_ADD_SERVICE, {
                        serviceName: activity.resource?.serviceName,
                        guestCode: activity.resource?.guestCode
                    }) + '. <br>';

                    if(activity.resource?.facilityName) {
                        content += t(translateCodes.APM_ACTIVITY_ADD_SERVICE_POSITION, {
                            facilityName: activity.resource?.facilityName,
                            serviceName: activity.resource?.serviceName,
                            guestCode: activity.resource?.guestCode
                        }) + '. <br>';
                    }
                    break;
                }
                    
                case 'Updated':
                {
                    const facilityChanged = activity.data.find((o) => o.field === 'FacilityId');
                    const serviceChanged = activity.data.find((o) => o.field === 'ServiceId');

                    if(serviceChanged) {
                        content += t(translateCodes.APM_ACTIVITY_CHANGE_SERVICE, {
                            originValue: serviceChanged.originValue,
                            changedValue: serviceChanged?.changedValue,
                            guestCode: activity.resource?.guestCode
                        }) + '. <br>';
                    }

                    if(facilityChanged) {
                        if(facilityChanged.originValue && facilityChanged.changedValue) {
                            content += t(translateCodes.APM_ACTIVITY_CHANGE_SERVICE_POSITION, {
                                originValue: facilityChanged.originValue,
                                changedValue: facilityChanged.changedValue,
                                serviceName: activity.resource?.serviceName,
                                guestCode: activity.resource?.guestCode
                            }) + '. <br>';
                        }
                        else if(!facilityChanged.changedValue)
                        {
                            content += t(translateCodes.APM_ACTIVITY_REMOVE_SERVICE_POSITION, {
                                facilityName: facilityChanged.originValue,
                                serviceName: activity.resource?.serviceName,
                                guestCode: activity.resource?.guestCode
                            }) + '. <br>';
                        }
                        else {
                            content += t(translateCodes.APM_ACTIVITY_ADD_SERVICE_POSITION, {
                                facilityName: facilityChanged.changedValue,
                                serviceName: activity.resource?.serviceName,
                                guestCode: activity.resource?.guestCode
                            }) + '. <br>';
                        }
                    }
                    break;
                }
                case 'Deleted':
                {
                    content += t(translateCodes.APM_ACTIVITY_DELETE_SERVICE, {
                        serviceName: activity.resource?.serviceName,
                        guestCode: activity.resource?.guestCode
                    }) + '. <br>';
                    break;
                }
                default:
                    break;
                }
                break;
            }
            case 'AppointmentServiceEmployee':
            {
                switch (activity.action) {
                case 'Added':
                {
                    content += t(translateCodes.APM_ACTIVITY_ADD_EMPLOYEE, {
                        employeeName: activity?.resource?.employeeName,
                        serviceName: activity?.resource?.serviceName,
                        guestCode: activity?.resource?.guestCode
                    }) + '. <br>';
                    break;
                }
                case 'Deleted':
                {
                    content += t(translateCodes.APM_ACTIVITY_DELETE_EMPLOYEE, {
                        employeeName: activity?.resource?.employeeName,
                        serviceName: activity?.resource?.serviceName,
                        guestCode: activity?.resource?.guestCode
                    }) + '. <br>';
                    break;
                }
                case 'Updated':
                {
                    const employeeChanged = activity.data.find((o) => o.field === 'EmployeeId');
                    if(employeeChanged) {
                        content += t(translateCodes.APM_ACTIVITY_CHANGE_EMPLOYEE, {
                            originValue: employeeChanged?.changedValue,
                            changedValue: employeeChanged?.changedValue,
                            serviceName: activity?.resource?.serviceName,
                            guestCode: activity?.resource?.guestCode
                        }) + '. <br>';
                    }
                    break;
                }
                default:
                    break;
                }
                break;
            }
            default:
                break;
            }
        });

        return content;
    }
    
};
