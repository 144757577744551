import { managementApis } from '@esg/business-management';
import { useHttpQuery } from '@esg/ui';

export default function useFetchDataSaleForm() {
    const {data: serviceCategories, isFetching: servicesLoading} = useHttpQuery(managementApis.getAllServiceCategory, undefined, {
        staleTime : 0,
    });

    const {data: productCategories, isFetching: productsLoading} = useHttpQuery(managementApis.getAllProductCategory, undefined, {
        staleTime : 0,
    });

    const isLoading = servicesLoading || productsLoading;

    return {
        serviceCategories: serviceCategories?.items,
        productCategories: productCategories?.items,
        isLoadingInitData: isLoading
    };
}
