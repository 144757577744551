import { PaginationResponse, HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetAllAutomationReminderRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllAutomationReminderResponseItem {
    id?: string;
    reminderTimeInSeconds?: number;
    emailReminder?: boolean;
    SMSReminder?: boolean;
}

export const getAllAutomationReminder = httpUtil.createHttpRequestMeta<
    GetAllAutomationReminderRequest,
    PaginationResponse<GetAllAutomationReminderResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/automation-reminders',
    method: 'GET',
    authentication: 'bearer',
});
