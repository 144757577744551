import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';

interface GetAllCustomerSourceRequest
    extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllCustomerSourceResponseItem {
    readonly id: string;
    readonly name: string;
    readonly active?: boolean;
}

export const getAllCustomersSource = httpUtil.createHttpRequestMeta<GetAllCustomerSourceRequest, PaginationResponse<GetAllCustomerSourceResponseItem>>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customer-sources',
    method: 'GET',
    authentication: 'bearer'
});