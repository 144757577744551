import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { env } from '../../configs';

interface GetProductSummaryRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}
export interface GetProductSummaryItem {
    id: string;
    name: string;
    quantity: number;
    discount: number;
    revenue: number;
}

export const getProductsSummaryRevenueReport = httpUtil.createHttpRequestMeta<
    GetProductSummaryRequest,
	PaginationResponse<GetProductSummaryItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/product',
    authentication: 'bearer',
});