import { AppButton, DatePickerRange, FlexBox, Spacer } from '@esg/ui';
import React from 'react';
import {Dayjs} from 'dayjs';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

interface AnalyserClientHeaderProps {
    dateRange: [Dayjs, Dayjs];
    setDateRange: (dates: [Dayjs, Dayjs]) => void;
}

export default function AnalyserClientHeader(props: AnalyserClientHeaderProps) {
    const {dateRange, setDateRange} = props;
    const { t } = useTranslation();
    return (
        <Spacer right={16}>
            <FlexBox justifyContent='flex-end' gap={16}>
                <DatePickerRange
                    onChange={(dates) => setDateRange(dates)}
                    defaultValue={dateRange}
                />
                <AppButton> {t(translateCodes.PRINT)} </AppButton>
            </FlexBox>
        </Spacer>
    );
}
