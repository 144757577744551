import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetOverviewChartClientRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetOverviewChartClientResponse {
    overviewChartInfos : ClientReportOverview[];
}

interface ClientReportOverview {
    date: string;
    newClient: number;
    retentionClient: number;
    walkInClient: number;
}

export const getOverviewChartClientReport = httpUtil.createHttpRequestMeta<
    GetOverviewChartClientRequest,
	GetOverviewChartClientResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-report/overview-chart',
    authentication: 'bearer',
});