import { HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';
import { TimeOffType } from './@type';

export interface TimeOffGetDetailResponse {
	readonly id: string;
	readonly date: string;
	readonly endTime: string;
	readonly startTime: string;
	readonly type: TimeOffType;
	readonly employeeId: string;
	readonly repeatToDate: string;
}

interface GetAllTimeOffsRequest extends HttpRequestData {
	readonly query: PaginationRequest & {
        readonly startDate: string;
        readonly endDate: string;
    };
}

export const getAllTimeOffs = httpUtil.createHttpRequestMeta<
	GetAllTimeOffsRequest,
	TimeOffGetDetailResponse[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/time-offs',
    method: 'GET',
    authentication: 'bearer',
});
