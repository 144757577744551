import { httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { BreakTimeUpdateRequest } from '../break-time';

export const updateShiftByBreakTime = httpUtil.createHttpRequestMeta<BreakTimeUpdateRequest, void>({
    method: 'PATCH',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/shifts/:id/update-break',
});
