import { GetDetailSaleResponse } from '@esg/business-pos';
import {
    getAllPaymentMethod,
} from '@esg/business-setting/api/payment-method/getAllPaymentMethod';
import {
    FlexBox,
    FormBase,
    FormLayoutModal,
    Text,
    globalModalState,
    useHttpQuery,
} from '@esg/ui';
import { Card, Radio, Spin } from 'antd';
import React, { useMemo, useCallback } from 'react';
import { useResetRecoilState, useRecoilState } from 'recoil';
import { DollarOutlined } from '@ant-design/icons';
import { PaymentProcessing } from './PaymentProcessing';
import { PaymentMethodImage } from '@/components/payment-method';
import { translateCodes } from '@/locales';

interface SelectPaymentFormValues {
    readonly paymentMethodSettingId: string;
}

interface SelectPaymentFormProps {
    readonly onCancel?: () => void;
    readonly sale: GetDetailSaleResponse;
    readonly isPrintBillAfterPayment: boolean;
}

export default function SelectPaymentForm(props: SelectPaymentFormProps) {
    const { sale } = props;
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const { data: paymentMethods, isLoading } = useHttpQuery(getAllPaymentMethod);

    const paymentMethodsActive = useMemo(
        () => paymentMethods?.items?.filter((item) => item.isActive === true),
        [paymentMethods]
    );

    const submit = useCallback(async (values: SelectPaymentFormValues) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            content: (
                <PaymentProcessing
                    isPrintBillAfterPayment={props.isPrintBillAfterPayment}
                    paymentMethodId={values.paymentMethodSettingId}
                    saleId={sale.id}
                />
            ),
            closeIcon: null,
            titleTransCode: <DollarOutlined size={30} />,
        });
    }, [globalModal, props.isPrintBillAfterPayment, sale.id, setGlobalModal]);

    if(isLoading) return (
        <FlexBox alignItems='center' justifyContent='center' >
            <Spin />
        </FlexBox>
    );

    return (
        <FormBase
            onSubmit={submit}
            defaultValues={{
                paymentMethodSettingId: paymentMethods?.items?.[0]?.id,
            }}
        >
            {(form) => (
                <FormLayoutModal
                    useSubmitButton={true}
                    onCancel={resetGlobalModal}
                    okTextCode={translateCodes.CHECKOUT}
                    cancelTextCode={translateCodes.CLOSE}
                    alwaysEnableSubmit
                >
                    <FlexBox direction='column' gap={16}>
                        {paymentMethodsActive?.map((paymentMethod) => (
                            <Card
                                key={paymentMethod.id}
                                onClick={() => form.setValue('paymentMethodSettingId', paymentMethod.id)}
                            >
                                <FlexBox
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    <FlexBox gap={16} alignItems='center'>
                                        <PaymentMethodImage paymentMethodName={paymentMethod.name} />
                                        <Text>{paymentMethod.name}</Text>
                                    </FlexBox>
                                    <Radio
                                        value={paymentMethod.id}
                                        checked={form.watch('paymentMethodSettingId') === paymentMethod.id}
                                    />
                                </FlexBox>
                            </Card>
                        ))}
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
