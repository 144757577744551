import {
    deleteTimeBlock,
    TimeBlockDetailResponse,
    createTimeBlockDisable,
    updateTimeBlock,
} from '@esg/business-resource-management';
import { globalModalState, useHttpCommand } from '@esg/ui';
import { useResetRecoilState } from 'recoil';
import { useCallback } from 'react';

import { dayjs } from '@esg/shared';
import { timeBlockEvents } from '@/event';
import { TimeBlockCircle } from '@/components';

interface Params {
	readonly currentDate: string;
	readonly delType?: TimeBlockCircle;
	readonly values: TimeBlockDetailResponse;
}

export const useTimeBlockDelete = () => {
    const hideModal = useResetRecoilState(globalModalState);

    const handleSuccess = useCallback(() => {
        hideModal();
        timeBlockEvents.refetch.emit();
    }, [hideModal]);

    const { mutateAsync: deleteTimeBlockAsync } = useHttpCommand(deleteTimeBlock, {
        onSuccess() {
            handleSuccess();
        },
    });

    const { mutateAsync: createTBDisable } = useHttpCommand(createTimeBlockDisable, {
        onSuccess() {
            handleSuccess();
        },
    });

    const { mutateAsync: updateTimeBlockAsync } = useHttpCommand(updateTimeBlock, {
        onSuccess() {
            handleSuccess();
        },
    });

    const handleDelete = useCallback(
        async ({ values, delType, currentDate }: Params) => {
            if (delType === 'now') {
                return void (await createTBDisable({
                    body: {
                        date: currentDate,
                        timeBlockId: values.id,
                        employeeId: values.employeeId,
                    },
                }));
            }

            if (delType === 'future') {
                return void (await updateTimeBlockAsync({
                    pathData: {
                        id: values.id,
                    },
                    body: {
                        ...values,
                        repeatTo: dayjs(currentDate).add(-1, 'd').toISOString(),
                    },
                }));
            }

            deleteTimeBlockAsync({ pathData: { id: values.id } });
        },
        [createTBDisable, deleteTimeBlockAsync, updateTimeBlockAsync]
    );

    return handleDelete;
};
