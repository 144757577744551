import React from 'react';
import './EmployeeShifts.scss';
import { Spacer } from '@esg/ui';
import { ShiftProvider } from './ShiftContext';
import { ShiftsManagement } from './children/shift-management';
import { ShiftsHeader } from './children/shift-header';

export const EmployeeShifts = () => {

    return (
        <Spacer type='padding' left_right={20} top={16} className='employee-shifts'>
            <ShiftProvider>
                <ShiftsHeader/>
                <ShiftsManagement/>
            </ShiftProvider>
        </Spacer>
    );
};
