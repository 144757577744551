import { GetDetailFacilitiesResponse, resourceApis } from '@esg/business-resource-management';
import { TableCustom, useHttpQuery } from '@esg/ui';
import { Card, Switch, TableColumnsType } from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ServiceFacilityValue } from '../ServiceForm';
import { translateCodes } from '@/locales';

export const FacilityService = () => {
    const { data: facilitiesResponse, isLoading } = useHttpQuery(resourceApis.getAllFacilities);
    const form = useFormContext();
    const serviceFacilities: ServiceFacilityValue[] = form.watch('serviceFacilities');
    const {t} = useTranslation();
    const columns: TableColumnsType<GetDetailFacilitiesResponse> = [
        {
            width: '80%',
            title: t(translateCodes.FACILITY),
            dataIndex: 'name',
        },
        {
            title: t(translateCodes.ENABLE),
            dataIndex: 'isAllocate',
            render: (_, record) => {
                return (
                    <Switch
                        checked={serviceFacilities?.some((o) => o.facilityId === record.id)}
                        onChange={(value) => {
                            let newFacilityServices = [];
                            if (value) {
                                newFacilityServices = [...serviceFacilities, { facilityId: record.id }];
                            } else {
                                newFacilityServices = serviceFacilities.filter((o) => o.facilityId !== record.id);
                            }

                            form.setValue('serviceFacilities', newFacilityServices, {
                                shouldDirty: true,
                            });
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Card bordered={false}>
            <TableCustom
                loading={isLoading}
                columns={columns}
                dataSource={facilitiesResponse?.items ?? []}
                showHeader={false}
                showFooter={false}
            />
        </Card>
    );
};
