export class HttpRequestError extends Error {
    public readonly name: string = 'HttpRequestError';
    public readonly statusCode: number;
    public readonly response: Response;

    constructor(message: string, response: Response) {
        super(message);
        this.statusCode = response.status;
        this.response = response;
    }
}