import { AppButton, FlexBox, IconMore, Text } from '@esg/ui';
import { Badge, Card, Dropdown } from 'antd';
import { AppointmentStatus as Status } from '@esg/shared';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useListenPaidSale } from '../../@share/hooks/useListenPaidSale';
import { checkValidAptServiceDetail } from '../../@share/appointmentUtils';
import {
    aptStatusInfos,
    useAptStatusMenu,
    useAptStatusFormUpdate,
    useAptStatusNextStep } from './appointment-status/@share';

export const AppointmentStatus = () => {
    const {
        watch,
        getValues
    } = useFormContext();

    useListenPaidSale();
    
    const status = watch('status') as Status;
    
    const isValidService = checkValidAptServiceDetail(getValues('appointmentServices'));

    const { handleUpdate, loading, saleId } = useAptStatusFormUpdate();

    const handleNextStep = useAptStatusNextStep(handleUpdate);
    
    const getStatusMenu = useAptStatusMenu(handleUpdate, isValidService);

    const disabled = useMemo(
        () => (!isValidService && status === Status.Booked) || loading,
        [isValidService, loading, status]
    );

    const item = useMemo(() => aptStatusInfos[status], [status]);

    const { nextStep, more, color } = item;

    return (
        <Card size='small' className='appointment-status'>
            <FlexBox alignItems='center' gap={12}>
                <Badge
                    color={color}
                    text={
                        <Text fontWeight='bold' fontSize={16} color={item.color}>
                            {item.currentStep}
                        </Text>
                    }
                />
                {nextStep && (
                    <AppButton
                        ghost
                        type='primary'
                        disabled={disabled}
                        className='status-btn'
                        translateCode={nextStep.label}
                        onClick={() => {
                            handleNextStep(nextStep, saleId);
                        }}
                    />
                )}
                {more && (
                    <Dropdown
                        trigger={['click']}
                        placement='bottomRight'
                        overlayStyle={{ width: 279 }}
                        menu={{ items: getStatusMenu(more) }}
                    >
                        <AppButton shape='circle' type='text' className='icon-btn'>
                            <IconMore />
                        </AppButton>
                    </Dropdown>
                )}
            </FlexBox>
        </Card>
    );
};
