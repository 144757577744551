import React, { useMemo } from 'react';
import { Dayjs } from '@esg/shared';
import { Pie } from '@ant-design/plots';
import { Badge, Card } from 'antd';
import { Block, FlexBox, useHttpQuery, Text, IconGrowUp, IconGrowDown, Spacer } from '@esg/ui';
import { getOverviewClientGenderReport } from '@esg/business-report';
import { useTranslation } from 'react-i18next';
import { chartHelper, dateHelper } from '@/helpers';
import { translateCodes } from '@/locales';

interface AnalyserClientGenderPieChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}

interface PieDataChart {
    type: string;
    color: string;
    value: number;
    preValue: number;
}
const renderGrow = (current: number, previous: number) => {
    const grow = current - previous;
    const color = grow >= 0 ? 'green' : 'red';

    if (previous === 0) {
        return ;
    }

    const percent = (grow / previous) * 100;

    return (
        <FlexBox alignItems='center' >
            <Text fontWeight={600} color={color}>({percent.toFixed(2)}%)</Text>
            {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
        </FlexBox>
    );

};

export default function AnalyserClientGenderPieChart(props: AnalyserClientGenderPieChartProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const numberOfDays = useMemo(()=>dateRange[1].diff(dateRange[0], 'd'),[dateRange]);
    const viewType = useMemo(()=>(chartHelper.getViewTypeChart(numberOfDays)),[numberOfDays]);

    const { data: overviewChartClientGender, isLoading } = useHttpQuery(getOverviewClientGenderReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            viewType: viewType,
        }
    });
    const dateRangePrevious = useMemo(()=>dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]),[dateRange]);

    const { data: overviewChartClientGenderPrevious } = useHttpQuery(getOverviewClientGenderReport, {
        query: {
            startDate: dateRangePrevious[0].toISOString(),
            endDate: dateRangePrevious[1].toISOString(),
        }
    });

    const dataChart : PieDataChart[]= useMemo(()=> ( [
        {
            type: 'Male',
            value: overviewChartClientGender?.totalMale ?? 0,
            preValue: overviewChartClientGenderPrevious?.totalMale ?? 0,
            color: '#2970FF'
        },
        {
            type: 'Female',
            value: overviewChartClientGender?.totalFemale ?? 0,
            preValue: overviewChartClientGenderPrevious?.totalFemale ?? 0,
            color: '#84ADFF'
        },
        {
            type: 'Unknown',
            value: overviewChartClientGender?.totalUnknown ?? 0,
            preValue: overviewChartClientGenderPrevious?.totalUnknown ?? 0,
            color: '#B2CCFF'
        },
        {
            type: 'Other',
            value: overviewChartClientGender?.totalOther ?? 0,
            preValue: overviewChartClientGenderPrevious?.totalOther ?? 0,
            color: '#D1E0FF'
        },
    ]),[overviewChartClientGender, overviewChartClientGenderPrevious]);
    
    const config = useMemo(()=>( {
        data: dataChart,
        angleField: 'value',
        colorField: 'type',
        height: 280,
        className: 'pie-chart', 
        legend: false,
        scale: { color: { range: ['#2970FF', '#84ADFF', '#B2CCFF', '#D1E0FF'] } },
        tooltip: {
            title: (d: PieDataChart) => {
                return d.type;
            },
        }
    }),[dataChart]);

    return (
        <Block width='100%' minWidth='300px'>
            <Card title= {t(translateCodes.GENDER)}>
                {!isLoading && <Pie {...config} loading={isLoading} />}
                <Spacer left_right={20}>
                    {dataChart.map(o => {
                        const resultPercent = ((o.value! / (overviewChartClientGender?.totalClient ?? 0)) * 100).toFixed(2);
                        return (
                            <FlexBox key={o.type} justifyContent='space-between'>
                                <Badge color={o.color} text={o.type} />
                                <Block>
                                    <FlexBox gap={8}>
                                        <Text translateCode={`${resultPercent} %  `} />
                                        {renderGrow(o.value, o.preValue)}
                                    </FlexBox>
                                </Block>
                            </FlexBox>
                        );
                    })}
                </Spacer>
            </Card>
        </Block>

    );
}
