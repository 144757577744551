import { AppAvatar, FlexBox, TableCustom, Text, globalDrawerState, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { getServicesByEmployeeRevenueItem, getServicesByEmployeeRevenueReport } from '@esg/business-report/api/revenue-report';
import { useTranslation } from 'react-i18next';
import { RevenueServiceDetailTable } from './RevenueServiceDetailTable';
import { translateCodes } from '@/locales';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface RevenueServiceByEmployeeTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function RevenueServiceByEmployeeTable( props: RevenueServiceByEmployeeTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency } = useLocalesFormat();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeEmployee: '10',
        orderByEmployee: 'id',
    },);
    
    const {data: servicesByEmployee, isFetching} = useHttpQuery(getServicesByEmployeeRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            search: searchParams.searchByEmployee,
            orderBy: searchParams.orderByEmployee,
            orderDirection: searchParams.employeeDirection,
            page: searchParams.pageEmployee,
            pageSize: searchParams.pageSizeEmployee,
            ...searchParams
        },
    });

    const viewDetail = useCallback((employeeId: string) => {
        setDrawerGlobalState({
            titleTransCode: t(translateCodes.VIEW_DETAIL),
            isOpen: true,
            hideHeader: true,
            content: (
                <RevenueServiceDetailTable
                    dateRange={dateRange}
                    employeeId={employeeId}
                />
            ),
            footer: null,
        });
    },[dateRange, setDrawerGlobalState, t]);

    const columns: TableColumnsType<getServicesByEmployeeRevenueItem> = useMemo(()=>([
        {
            key: 'employee',
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'employee',
            render: (_, value) => {
                return (
                    <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                        <AppAvatar
                            size='md'
                            data={value.employee}
                        />
                        <Text fontWeight='bold'>{value.employee?.fullName}</Text>
                    </FlexBox>
                );
            },
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.employeeDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, employeeDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t(translateCodes.REVENUE),
            dataIndex: 'revenue',
            key: 'revenue',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.revenue)}
                    </Text>
                );
            },
        },
        {
            title: t(translateCodes.LINKS),
            key: 'links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={()=>viewDetail(record.id)}>View Detail</Tag>
                );
            }
        },
    ]),[formatCurrency, searchParams, setSearchParams, t, viewDetail]);

    return (
        <TableCustom
            titleTableTransCode={translateCodes.SERVICE_BY_EMPLOYEE}
            loading={isFetching}
            columns={columns}
            dataSource={servicesByEmployee?.items}
            pageInfo={servicesByEmployee?.meta}
            placeholderSearchTransCode={`${t(translateCodes.SEARCH)} ${t(translateCodes.EMPLOYEE)}`}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByEmployee: value })
            }
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, pageEmployee: page.toString() })
            }
        />
    );
}
