export enum CreateServiceFormStep {
    Info,
    EmployeeAllocate,
    Facility,
}

export enum DurationUnit {
    Min,
    Hour
}
