import { managementApis } from '@esg/business-management';
import { FlexBox, Spacer, useHttpQuery, useLocalesFormat } from '@esg/ui';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GetDetailSaleResponse } from '@esg/business-pos';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { saleActivityHelper } from '@/helpers';
import { translateCodes } from '@/locales';
import { ActivityInfo, ActivityLogItem } from '@/components/activities';

const saleEditFields = ['Note', 'Tip', 'TipUnit', 'CustomerId', 'CheckoutAt'];
interface OrderActivitiesProps {
    readonly sale?:  GetDetailSaleResponse;
}

export const OrderActivities = (props: OrderActivitiesProps) => {
    const { sale } = props;
    const {t} = useTranslation(); 
    const {formatCurrency,  formatCurrencySymbol, formatDate} = useLocalesFormat();
    const currencySymbol = formatCurrencySymbol();

    const [currentLocale] = useRecoilState(currentLocaleState);

    const formatDateTime = useCallback((date: string) => {
        return formatDate(date, `${currentLocale?.format}, HH:mm`);
    }, [currentLocale, formatDate]);
    
    const { data: saleActivities, isFetching } = useHttpQuery(
        managementApis.getAllSaleActivities,
        {
            pathData: {
                id: sale?.id,
            },
        }
    );

    const activitiesLogs = useMemo(() => {
        const activities = saleActivities?.activities?.map((activity) => {
            const saleActivities = activity.filter(o => o.entityName === 'Sale');
            const isCreateSale = saleActivities.some(o => o.action === 'Added');
            const isCancelSale = saleActivities.some(o => o.action === 'Updated' && o.data.some(o => o.field === 'Status' && o.changedValue === 'Cancelled'));
            const isEditSale = saleActivities.some(o => o.action === 'Updated' && o.data.some(o => saleEditFields.includes(o.field)));

            const actionTime = saleActivities.find(o => o.createdTime)?.createdTime;
            const actionTimeFormat = formatDateTime(actionTime!);
            const actionByName = saleActivities.find(o=> o.createByName!)?.createByName;
              
            if (isCreateSale) {

                return {
                    actionName: 'Created',
                    rootEntityName: 'Sale',
                    actionBy: actionByName,
                    actionTime: actionTimeFormat,
                    activityData: saleActivityHelper.getContentSaleCreate(activity, t)
                };
            }
    
            if (isCancelSale) {
                return {
                    actionName: 'Cancelled',
                    rootEntityName: 'Sale',
                    actionBy: actionByName,
                    actionTime: actionTimeFormat,
                    activityData: t(translateCodes.ACTIVITY_REASON_CANCELLED_SALE, {reason: sale?.cancelReason?.name}),
                };
            }
    
            if (isEditSale) {
                return {
                    actionName: 'Edited',
                    rootEntityName: 'Sale',
                    actionBy: actionByName,
                    actionTime: actionTimeFormat,
                    activityData: saleActivityHelper.getContentSaleEdit(saleActivities[0], t, currencySymbol),
                };
            }
    
            //handle sale detail 
            const saleDetailMain = activity.find((o) => o.entityName === 'SaleDetail');
            const saleDetailEmployee = activity.filter((o) => o.entityName === 'SaleDetailEmployee');
    
            return {
                actionName: 'Edited',
                rootEntityName: 'Sale',
                actionBy: activity[0].createByName,
                actionTime: actionTimeFormat,
                activityData: saleActivityHelper.getContentSaleDetail(
                        saleDetailMain!,
                        saleDetailEmployee,
                        t,
                        formatCurrency,
                        currencySymbol
                ),
            };
        }) ?? [];

        return activities.filter(o=> o.activityData !== '') as ActivityInfo[];

    }, [saleActivities?.activities, formatDateTime, t, formatCurrency, currencySymbol, sale?.cancelReason?.name]);

    return (
        <Spacer left_right={24} bottom={16}>
            <FlexBox direction='column' gap={16}>
                {activitiesLogs?.map((activity, index) => {
                    return (
                        <ActivityLogItem
                            loading={isFetching}
                            key={index}
                            activityInfo={activity}
                        />
                    );
                })}
            </FlexBox>
        </Spacer>
    );
};
