import { getOverviewClientReport } from '@esg/business-report';
import { Block, FlexBox, IconGrowDown, IconGrowUp, IconRevenue, IconTotalClient, IconTotalNewClient, IconTotalRetentionClient, IconTotalWalkInClient, Text, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Avatar, Card } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { dateHelper } from '@/helpers';

interface AnalyserClientOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
    }

const renderGrow = (current: number, previous: number) => {
    const grow = current - previous;
    const color = grow >= 0 ? 'green' : 'red';

    if(previous === 0) {
        return ;
    }

    const percent = (grow / previous) * 100;

    return (
        <FlexBox alignItems='center' >
            <Text className='grow-level' color={color}>({percent.toFixed(2)}%)</Text>
            {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
        </FlexBox>
    );

};

export default function AnalyserClientOverview(props: AnalyserClientOverviewProps) {
    const { formatCurrency } = useLocalesFormat();
    const { t } = useTranslation();
    const { dateRange } = props;
    const {data: overviewClient, isLoading} = useHttpQuery(getOverviewClientReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
        }
    });

    const dateRangePrevious = useMemo(()=>dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]),[dateRange]);

    const {data: overviewClientPrevious} = useHttpQuery(getOverviewClientReport, {
        query: {
            startDate: dateRangePrevious[0].toISOString(),
            endDate: dateRangePrevious[1].toISOString(),
        }
    });
    
    const appointmentOverviews = useMemo(() => [
        {
            label: t(translateCodes.TOTAL_CLIENT),
            value: overviewClient?.totalClient ?? 0,
            preValue: overviewClientPrevious?.totalClient ?? 0,
            color: '#EFF4FF',
            icon: <IconTotalClient/>
        },
        {
            label: t(translateCodes.NEW_CLIENT),
            value: overviewClient?.newClient ?? 0,
            preValue: overviewClientPrevious?.newClient ?? 0,
            color: '#FEF3F2',
            icon: <IconTotalNewClient/>
        },
        {
            label: t(translateCodes.RETENTION_CLIENT),
            value: overviewClient?.retentionClient ?? 0,
            preValue: overviewClientPrevious?.retentionClient ?? 2,
            color: '#ECFDF3',
            icon: <IconTotalRetentionClient/>
        },
        {
            label: t(translateCodes.WALK_IN_CLIENT),
            value: overviewClient?.walkInClient ?? 0,
            preValue: overviewClientPrevious?.walkInClient ?? -1,
            color: '#FFFAEB',
            icon: <IconTotalWalkInClient/>
        },
        {
            label: t(translateCodes.TOTAL_REVENUES),
            value: overviewClient?.totalRevenue ?? 0,
            preValue: overviewClientPrevious?.totalRevenue ?? 0,
            color: '#EFF4FF',
            formatCurrency: true,
            icon: <IconRevenue />
        }
    ],[overviewClient, overviewClientPrevious, t]);

    return (
        <FlexBox className='overview-client' direction='row' gap={16} flexWrap='wrap'>
            {appointmentOverviews.map((o, i)=> (
                <Card key={i} loading={isLoading} className='card-overview'>
                    <FlexBox gap={8} alignItems='center' flex='none' width={167}>
                        <Block>
                            <Avatar
                                style={{ backgroundColor: o.color, flex: 1 }}
                                size={42}
                                icon={o.icon}
                            />
                        </Block>
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={121} fontWeight={600} translateCode={o.label} />
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#101828' fontWeight='700' fontSize={18}>
                                        {o.formatCurrency === true ? formatCurrency(o.value) : o.value}
                                    </Text>
                                </Block>
                                {renderGrow(o.value, o.preValue)}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
