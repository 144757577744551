import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
interface AppointmentServiceEmployeeDeleteRequest extends HttpRequestData {}

export const deleteAppointmentServiceEmployee = httpUtil.createHttpRequestMeta<AppointmentServiceEmployeeDeleteRequest, void>({
    baseUrl: env.apiUrlBase,
    method: 'DELETE',
    path: '/api/v1/appointment-service-employees/:id',
    authentication: 'bearer',
});
