import React from 'react';
import { IconProps } from '../../Types';

export const IconRevenue = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path opacity='0.3' d='M21.92 16.7496C21.59 19.4096 19.41 21.5896 16.75 21.9196C15.14 22.1196 13.64 21.6796 12.47 20.8196C11.8 20.3296 11.96 19.2896 12.76 19.0496C15.77 18.1396 18.14 15.7596 19.06 12.7496C19.3 11.9596 20.34 11.7996 20.83 12.4596C21.68 13.6396 22.12 15.1396 21.92 16.7496Z'
                fill={props.color} />
            <path d='M9.99 2C5.58 2 2 5.58 2 9.99C2 14.4 5.58 17.98 9.99 17.98C14.4 17.98 17.98 14.4 17.98 9.99C17.97 5.58 14.4 2 9.99 2ZM9.05 8.87L11.46 9.71C12.33 10.02 12.75 10.63 12.75 11.57C12.75 12.65 11.89 13.54 10.84 13.54H10.75V13.59C10.75 14 10.41 14.34 10 14.34C9.59 14.34 9.25 14 9.25 13.59V13.53C8.14 13.48 7.25 12.55 7.25 11.39C7.25 10.98 7.59 10.64 8 10.64C8.41 10.64 8.75 10.98 8.75 11.39C8.75 11.75 9.01 12.04 9.33 12.04H10.83C11.06 12.04 11.24 11.83 11.24 11.57C11.24 11.22 11.18 11.2 10.95 11.12L8.54 10.28C7.68 9.98 7.25 9.37 7.25 8.42C7.25 7.34 8.11 6.45 9.16 6.45H9.25V6.41C9.25 6 9.59 5.66 10 5.66C10.41 5.66 10.75 6 10.75 6.41V6.47C11.86 6.52 12.75 7.45 12.75 8.61C12.75 9.02 12.41 9.36 12 9.36C11.59 9.36 11.25 9.02 11.25 8.61C11.25 8.25 10.99 7.96 10.67 7.96H9.17C8.94 7.96 8.76 8.17 8.76 8.43C8.75 8.77 8.81 8.79 9.05 8.87Z'
                fill={props.color} />
        </svg>

    );
};

IconRevenue.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#3D8CF5',
};
