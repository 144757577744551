import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteServiceCategoryRequest extends HttpRequestData {
}

export const deleteServiceCategory = httpUtil.createHttpRequestMeta<
    DeleteServiceCategoryRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/service-categories/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
