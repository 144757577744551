import { httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface TimeBlockDisableCreateRequest {
	readonly body: {
		readonly date: string;
		readonly employeeId: string;
		readonly timeBlockId: string;
	};
}

export interface TimeBlockDisableDetailResponse{
	readonly date: string;
	readonly timeBlockId: string;
	readonly employeeId: string;
}

export const createTimeBlockDisable = httpUtil.createHttpRequestMeta<
	TimeBlockDisableCreateRequest,
	void
>({
    method: 'POST',
    baseUrl: env.apiUrlBase,
    authentication: 'bearer',
    path: '/api/v1/time-block-disables',
});
