import {
    resourceApis,
} from '@esg/business-resource-management';
import { FormBase, FormLayout, InputField, Spacer, TextAreaField } from '@esg/ui/components';
import { useHttpCommand, useHttpQuery } from '@esg/ui/hooks';
import { globalDrawerState } from '@esg/ui/state';
import React, { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { managementApis } from '@esg/business-management';
import { TreeServiceField } from '../service';
import { translateCodes } from '@/locales';

export interface FacilityFormValues {
    id?: string;
    name: string;
    description?: string;
    serviceIds?: string[];
}

interface FacilityProps {
    refetchFacility: () => void;
    labelSubmitCode?: string;
    defaultValues?: FacilityFormValues;
    readonly onDeleted?: () => void;
}

export const FacilityForm = (props: FacilityProps) => {
    const { t } = useTranslation();
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const isUpdate = !!props.defaultValues?.id;

    const { data: categoriesData, isFetching } = useHttpQuery(managementApis.getAllServiceCategory);

    const { mutateAsync: createFacility, isPending: isLoadingCreate } = useHttpCommand(
        resourceApis.createFacility,
        {
            onSuccess() {
                resetDrawerState();
                props.refetchFacility();
            },
        }
    );

    const { mutateAsync: editFacility, isPending: isLoadingUpdate } = useHttpCommand(
        resourceApis.updateFacility,
        {
            onSuccess() {
                resetDrawerState();
                props.refetchFacility();
            },
        }
    );

    const handleSubmit = useCallback(
        async (data: FacilityFormValues) => {
            if (props.defaultValues) {
                await editFacility({
                    pathData: {
                        id: props.defaultValues.id,
                    },
                    body: data,
                });
                return;
            }
            await createFacility({
                body: data,
            });
        },
        [createFacility, editFacility, props.defaultValues]
    );

    return (
        <FormBase onSubmit={handleSubmit} defaultValues={props.defaultValues}>
            <FormLayout width={700}
                onDeleted={props.onDeleted}
                isLoadingButton={isLoadingCreate || isLoadingUpdate}
                labelSubmitCode={
                    isUpdate ? translateCodes.SAVE : translateCodes.CREATE
                }
            >
                <Spacer size='sm'>
                    <InputField
                        name='name'
                        label={translateCodes.FACILITY_NAME}
                        placeholder={translateCodes.FACILITY_NAME_PLACEHOLDER}
                        rules={{
                            required: translateCodes.FACILITY_RULE ,
                            maxLength: { value: 50, message: t(translateCodes.MAX_LENGTH_NAME, { number: 50 })}
                        }}
                    />
                    <Spacer height={12} />
                    <TextAreaField
                        name='description'
                        label={translateCodes.DESCRIPTION}
                        placeholder={translateCodes.FACILITY_DESCRIPTION_PLACEHOLDER}
                        rules={{
                            maxLength: {
                                value: 255,
                                message: t(translateCodes.MAX_LENGTH_DESCRIPTION, { number: 255 }),
                            },
                        }}
                    />
                    <Spacer height={12} />
                    <TreeServiceField 
                        useSearch
                        isLoading={isFetching}
                        serviceCategories={categoriesData?.items}
                    />
                </Spacer>
            </FormLayout>
        </FormBase>
    );
};