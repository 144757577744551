import { PlusCircleFilled } from '@ant-design/icons';
import { AppButton, globalDrawerState, useLocalesFormat } from '@esg/ui';
import React from 'react';

import { dayjs } from '@esg/shared';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { CustomerForm } from '@/components/form/customer/CustomerForm';
import { translateCodes } from '@/locales';

interface CreateCustomerBtnBtnProps {
    readonly refetchCustomers?: () => void;
}

export const CreateCustomerBtn = (props: CreateCustomerBtnBtnProps) => {
    const {t} = useTranslation();
    const { formatDate } = useLocalesFormat();

    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    return (
        <AppButton
            translateCode={translateCodes.CREATE_CUSTOMER}
            icon={<PlusCircleFilled />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    subtitle: t(translateCodes.CREATED_ON) + ' ' + formatDate(dayjs(Date())),
                    isOpen: true,
                    titleTransCode: translateCodes.CREATE_CUSTOMER,
                    content: (
                        <CustomerForm
                            onSuccess={()=> {
                                props.refetchCustomers?.();
                                resetDrawerState();
                            }}
                        />
                    )
                });
            }}
        />
    );
};
