import {
    FieldArray,
    FlexBox,
    FormLayout,
    Spacer,
    Text,
} from '@esg/ui';
import React from 'react';
import { AppointmentInfo, AppointmentService } from './children';
import { useAptFormFooter } from './@share/hooks/useAptFormFooter';
import { translateCodes } from '@/locales';

export const AppointmentForm = () => {
    
    const aptFormFooter = useAptFormFooter();
    
    return (
        <FormLayout width='auto' footer={aptFormFooter}>
            <FlexBox className='appointment-container'>
                <AppointmentInfo />
                <FlexBox className='appointment-service-container' direction='column' width='66vw'>
                    <Spacer bottom={12}>
                        <Text
                            translateCode={translateCodes.SERVICES}
                            fontWeight='bold'
                            fontSize={16}
                        />
                        <span style={{ color: 'red' }}>*</span>
                    </Spacer>
                    <FieldArray name='appointmentServices' isUseWrapper={false}>
                        {(fieldArrayProps) => {
                            return <AppointmentService {...fieldArrayProps} />;
                        }}
                    </FieldArray>
                </FlexBox>
            </FlexBox>
        </FormLayout>
    );
};
