import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteSaleDetailRequest extends HttpRequestData {
}

interface DeleteSaleDetailResponse {
    readonly id: string;
}

export const deleteSaleDetail = httpUtil.createHttpRequestMeta<
    DeleteSaleDetailRequest,
    DeleteSaleDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sale-details/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
