import { getAllEmployees } from '@esg/business-resource-management';
import { getDetailAppointmentSetting } from '@esg/business-schedule';
import { useHttpQuery } from '@esg/ui';
import { useMemo } from 'react';

export const useTimeBlockResources = () => {
    const { data: employees } = useHttpQuery(getAllEmployees, {
        query: {
            pageSize: 100,
        },
    });

    const { data: aptSetting } = useHttpQuery(getDetailAppointmentSetting);

    const employeeOptions = useMemo(
        () =>
            employees?.items.map((employee) => ({
                value: employee.id,
                avatar: employee.avatar,
                label: employee.fullName,
                fullName: employee.fullName,
            })) ?? [],
        [employees?.items]
    );

    return {
        employeeOptions,
        timeStep: aptSetting?.timeSlotDuration ?? 30,
    };
};