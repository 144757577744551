import { taxApi } from '@esg/business-setting';
import { CreateTaxResponseItem } from '@esg/business-setting/api/tax/taxCreate';
import {
    FormBase,
    FormLayout,
    InputField,
    Spacer,
    useHttpCommand,
} from '@esg/ui';
import React from 'react';
import { taxRules } from '../rules';
import { translateCodes } from '@/locales';
interface TaxValues {
    id?: string;
    name: string;
    branchId: string;
    value: number;
    descriptions: string;
}

interface TaxProps {
    readonly onSuccess?: (response?:  TaxValues) => void;
    readonly defaultValues?: TaxValues;
    readonly onDeleted?: () => void;
}
export const TaxForm = (props: TaxProps) => {

    const { mutateAsync: createTax, isPending: isCreating } = useHttpCommand(
        taxApi.taxCreate
    );

    const { mutateAsync: updateTax, isPending: isUpdating } = useHttpCommand(
        taxApi.taxUpdate
    );

    const isUpdate = !!props.defaultValues?.id;

    const onSubmit = React.useCallback(
        async (values: TaxValues) => {
            const onSuccess = (response?: CreateTaxResponseItem) => {
                props.onSuccess?.(response);
            };

            if (isUpdate) {
                await updateTax(
                    {
                        pathData: {
                            id: props.defaultValues?.id,
                        },
                        body: values,
                    },
                    { onSuccess }
                );
                return;
            }

            await createTax(
                {
                    body: {
                        ...values,
                    },
                },
                { onSuccess }
            );
        },
        [createTax, isUpdate, props, updateTax]
    );
    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                isLoadingButton={isCreating || isUpdating}
                labelSubmitCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                onDeleted={props.onDeleted}
            >
                <InputField
                    rules={taxRules.name}
                    name='name'
                    label={translateCodes.NAME}
                    placeholder= {translateCodes.NAME_TAX_PLACEHOLDER}
                />
                <Spacer bottom='sm' />
                <InputField
                    rules={taxRules.taxRate}
                    name='value'
                    label={translateCodes.TAX_RATE}
                    placeholder= {translateCodes.TAX_RATE_PLACEHOLDER}
                    addonBefore='%'
                />
                {/* <Spacer bottom='sm' />
                    <InputField
                        type='textarea'
                        name='descriptions'
                        label={translateCodes.DESCRIPTION}
                        placeholder= {translateCodes.DESCRIPTION_TAX_PLACEHOLDER}
                    /> */}
            </FormLayout>
        </FormBase>
    );
};
