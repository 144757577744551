import { useDrag } from 'react-dnd';
import React from 'react';
import { SchedulerItem } from '..';

interface SchedulerDraggableObjectProps {
    type: string;
    className?: string;
    style?: React.CSSProperties;
    item: SchedulerItem;
    onDragStart?: (item: SchedulerItem) => void;
    onDragEnd?: (item: SchedulerItem) => void;
}

export const SchedulerDraggableObject = (props: React.PropsWithChildren<SchedulerDraggableObjectProps>) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: props.type,
        item: props.item,
        canDrag: props.item.canDrag,
        end: () => {
            props.onDragEnd?.(props.item);
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        }),
    }));

    const style = React.useMemo(
        () => ({
            ...props.style,
            opacity: isDragging ? 0.5 : 1,
            zIndex: isDragging ? 100 : 0
        }),
        [props.style, isDragging]
    );

    return (
        <div
            ref={drag}
            className={props.className}
            style={style}
            onDragStart={() => props.onDragStart?.(props.item)}
        >
            {props.children}
        </div>
    );
};