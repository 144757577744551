import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';
import { TimeOffType } from './@type';

export interface UpdateTimeOffResponse {
    readonly id: string;
	readonly employeeId: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly date: string;
    readonly isRepeat: boolean;
    readonly repeatToDate: string;
    readonly type: TimeOffType;
}

export interface UpdateTimeOffRequest extends HttpRequestData {
	readonly body: {
        readonly id: string;
        readonly employeeId: string;
        readonly startTime: string;
        readonly endTime: string;
        readonly date: string;
        readonly isRepeat: boolean;
        readonly repeatToDate: string;
        readonly type: TimeOffType;
    }
}

export const updateTimeOff = httpUtil.createHttpRequestMeta<
    UpdateTimeOffRequest,
    UpdateTimeOffResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/time-offs/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
