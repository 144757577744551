import { Block, FlexBox, FormBase, Spacer, SwitchField, Text, useHttpCommand, useHttpQuery } from '@esg/ui';
import React, { useCallback } from 'react';
import { Card } from 'antd';
import { automationEmailSettingApi } from '@esg/business-success-platform';
import { useTranslation } from 'react-i18next';
import FormLayoutAutomation from '../components/FormLayoutAutomation';
import { translateCodes } from '@/locales';

interface AutomationEmailSettingValues {
    id?: string;
    sendNewAppointment?: boolean;
    sendConfirmAppointment?: boolean;
    sendCancelAppointment?: boolean;
    sendCheckInAppointment?: boolean;
    sendNoShowAppointment?: boolean;
    sendWelcomeAppointment?: boolean;
    sendCloseSaleAppointment?: boolean;
}

export function AutomationEmailSetting() {
    const { t } = useTranslation();
    const { data: automationEmailSettings, refetch, isFetching } = useHttpQuery(
        automationEmailSettingApi.getAllAutomationEmailSetting
    );
    const { mutateAsync, } = useHttpCommand(
        automationEmailSettingApi.updateAutomationEmailSetting,
        {
            onSuccess: () => {
                refetch();
            },
        }
    );

    const automationEmailSetting = automationEmailSettings?.items[0];

    const handleUpdateAutomationEmailSetting = useCallback(async (values: AutomationEmailSettingValues) => {        
        await mutateAsync({
            pathData: { id: values.id },
            body: values,
        });
    }, [mutateAsync]);
    return (
        !isFetching && (
            <FormBase onSubmit={handleUpdateAutomationEmailSetting} defaultValues={automationEmailSetting}>
                <FormLayoutAutomation>
                    <Card style={{paddingTop: 15}} title={
                        <FlexBox preset='column-start' >
                            <Text translateCode={translateCodes.INTERNAL_EMAIL_DEFAULTS} fontSize={16} fontWeight={700} />
                            <Text fontWeight={500} translateCode={translateCodes.EMAILS_ARE_SENT_AUTOMATICALLY} />
                            <Spacer bottom={10} />
                        </FlexBox>
                    }>
                        <FlexBox preset='column-start' gap={30}>
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendConfirmAppointment'
                                gap={8}
                                label= {t(translateCodes.SEND_EMAIL_CONFIRM_APPOINTMENT)} 
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text translateCode={translateCodes.SEND_EMAIL_CONFIRM_APPOINTMENT_DESCRIPTION} />
                                        </Spacer>
                                    </Block>
                                }
                            />
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendCloseSaleAppointment'
                                gap={8}
                                label={t(translateCodes.SEND_EMAIL_SALE_CLOSE)}
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text translateCode={translateCodes.SEND_EMAIL_SALE_CLOSE_DESCRIPTION} />
                                        </Spacer>
                                    </Block>
                                }
                            />

                            {/* <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendNewAppointment'
                                gap={8}
                                label='Send Customer New Appointment Email'
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text >The default setting to send the customer an email when a new appointment is booked internally. This can be overridden when creating a Appointment</Text>
                                        </Spacer>
                                    </Block>
                                }
                            />
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendCancelAppointment'
                                gap={8}
                                label='Send Customer Cancel Appointment Email'
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text >The default setting to send the customer an email when a appointment is cancelled internally. This can be overridden when cancelling a Appointment</Text>
                                        </Spacer>
                                    </Block>
                                }
                            />
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendCheckInAppointment'
                                gap={8}
                                label='Send Customer Check-in Appointment Email'
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text >The default setting to send the customer an email when they are checked-in for their appointment</Text>
                                        </Spacer>
                                    </Block>
                                }
                            />
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendNoShowAppointment'
                                gap={8}
                                label='Send Customer No-show Appointment Email'
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text >The default setting to send the customer an email when their appointment is marked as a no-show</Text>
                                        </Spacer>
                                    </Block>
                                }
                            />
                            <SwitchField
                                alignItems='flex-start'
                                directionLabel='column'
                                justifyContent='flex-start'
                                name='sendWelcomeAppointment'
                                gap={8}
                                label='Send Customer Welcome Email'
                                prefix={
                                    <Block display='inline-block'>
                                        <Spacer left={16}>
                                            <Text >The default setting to send the customer a welcome email when they are added to your Izisalon account</Text>
                                        </Spacer>
                                    </Block>
                                }
                            /> */}
                        </FlexBox>

                    </Card>
                </FormLayoutAutomation>

            </FormBase>
        )
    );
}
