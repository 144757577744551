import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import PlusCircleFilled from '@ant-design/icons/lib/icons/PlusCircleFilled';
import { translateCodes } from '@/locales';
import { ProductForm } from '@/components/form/product';

interface CreateProductBtnProps {
    readonly refetchProduct?: () => void;
}

export const CreateProductBtn = (props: CreateProductBtnProps) => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerGlobal = useResetRecoilState(globalDrawerState);

    return (
        <AppButton
            translateCode={translateCodes.CREATE_PRODUCT}
            icon={<PlusCircleFilled />}
            type='primary'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: translateCodes.CREATE_PRODUCT,
                    content: (
                        <ProductForm
                            onSuccess={()=>{
                                props.refetchProduct?.();
                                resetDrawerGlobal();
                            }}
                        />
                    ),
                });
            }}
        />
    );
};