import { createTimeBlock } from '@esg/business-resource-management';
import { viewRequests } from '@esg/framework';
import { globalDrawerState, useHttpCommand } from '@esg/ui';
import { useResetRecoilState } from 'recoil';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TimeBlockCreateValues } from '../../Types';
import { getRepeatToDate } from '../blockTimeUtils';
import { translateCodes } from '@/locales';
import { timeBlockEvents } from '@/event';

export const useTimeBlockCreate = () => {
    const { handleSubmit } = useFormContext<TimeBlockCreateValues>();
    const { t } = useTranslation();
    const hideDrawer = useResetRecoilState(globalDrawerState);

    const { mutateAsync: createTimeBlockAsync, isPending } = useHttpCommand(createTimeBlock, {
        onSuccess() {
            hideDrawer();
            timeBlockEvents.refetch.emit();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SC_04),
            });
        },
    });

    const handleCreate = useMemo(
        () =>
            handleSubmit(async (values) => {
                createTimeBlockAsync({
                    body: {
                        ...values,
                        repeatTo: getRepeatToDate(values),
                        title: values.title ? values.title : 'Time block',
                    },
                });
            }),
        [createTimeBlockAsync, handleSubmit]
    );

    return { handleCreate, loading: isPending };
};
