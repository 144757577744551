import { httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface TaxCreateRequest {
    readonly body: {
        readonly name: string;
        readonly branchId: string;
        readonly value: number;
        readonly descriptions: string;
    };
}
export interface CreateTaxResponseItem {
    readonly id: string;
    readonly name: string;
    readonly branchId: string;
    readonly value: number;
    readonly descriptions: string;
}

export const taxCreate = httpUtil.createHttpRequestMeta<
    TaxCreateRequest,
    CreateTaxResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/TaxSettings',
    method: 'POST',
    authentication: 'bearer',
});
