import './Facilities.scss';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    GetDetailFacilitiesResponse,
    resourceApis,
} from '@esg/business-resource-management';
import { Block, useHttpQuery } from '@esg/ui';
import { managementApis } from '@esg/business-management';
import EmptyTable from '@esg/ui/components/table/EmptyTable';
import { useTranslation } from 'react-i18next';
import FacilityDetail from './children/FacilityDetail';
import { FacilitiesMenu } from './children/FacilitiesMenu';
import { AddFacilityBtn } from './children/AddFacilityBtn';
import { mainLayoutTheme } from '@/layouts/main-layout/@shared';
import { translateCodes } from '@/locales';

export function Facilities() {
    const params = new URLSearchParams(location.search);
    const { data: servicesData } = useHttpQuery(managementApis.getAllServices);
    const { data: categoriesData } = useHttpQuery(managementApis.getAllServiceCategory);
    const { t } = useTranslation();
    const [facilityActive, setFacilityActive] = useState<GetDetailFacilitiesResponse>();
    const { data: facilityData, refetch } = useHttpQuery(
        resourceApis.getAllFacilities
    );

    const handleActiveFacility = (facility:GetDetailFacilitiesResponse) => {
        params.set('id', facility?.id);
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
        setFacilityActive(facility);
    };

    useEffect(()=>{
        setFacilityActive(facilityData?.items.find((e)=>e.id == params.get('id')) ?? facilityData?.items[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[facilityData]);

    return (
        <Layout id='facility-layout' style={{height: '100%'}}>
            <Layout.Sider 
                theme='light' 
                style={{
                    width: mainLayoutTheme.subSiderWidth,
                    height: `calc(100vh - ${mainLayoutTheme.headerHeight})`,
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    overflowY: 'auto',
                    marginTop: mainLayoutTheme.headerHeight,
                    borderRight: '1px solid #0505050f'
                }}
            >
                <FacilitiesMenu
                    handleActiveFacility={handleActiveFacility}
                    refetch={refetch}
                    facilityData={facilityData?.items}
                />
            </Layout.Sider>
            <Layout.Content 
                style={{ 
                    marginLeft: mainLayoutTheme.subSiderWidth, 
                    overflowY: 'auto',
                    padding: 0
                }}
            >
                {facilityActive ? (
                    <FacilityDetail
                        facilityActive={facilityActive}
                        refetch={refetch}
                        services={servicesData?.items ?? []}
                        categories={categoriesData?.items ?? []}
                    />
                ):(
                    <Block className='empty-table' height='90vh'>
                        <EmptyTable
                            title={t(translateCodes.ADD_FACILITY)}
                            description={t(translateCodes.EMPTY_FACILITY_DESCRIPTION)}
                            actionButton={
                                <AddFacilityBtn refetch={refetch}/>
                            }
                        />
                    </Block>
                )}
            </Layout.Content>
        </Layout>
    );
}