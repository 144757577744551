import { Block } from '@esg/ui';
import React, { useState } from 'react';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { AppointmentGeneralInfo } from './appointment-info';
import AppointmentActivity from './appointment-activity/AppointmentActivity';
import { translateCodes } from '@/locales';

enum Tabs {
	GeneralInfo,
	Activity,
}

export const AppointmentInfo = () => {
    const [active, setActive] = useState<Tabs>(Tabs.GeneralInfo);
    const {
        formState: { defaultValues },
    } = useFormContext();
    const { t } = useTranslation();

    return (
        <Block className='appointment-info' width='40%'>
            <Segmented
                value={active}
                options={[
                    { value: Tabs.GeneralInfo, label: t(translateCodes.GENERAL_INFO) },
                    {
                        value: Tabs.Activity,
                        label: t(translateCodes.ACTIVITY),
                        disabled: !defaultValues?.id,
                    },
                ]}
                onChange={(value) => {
                    setActive(value as Tabs);
                }}
                block
            />
            {active === Tabs.GeneralInfo && <AppointmentGeneralInfo />}
            {active === Tabs.Activity && <AppointmentActivity appointmentId={defaultValues?.id} />}
        </Block>
    );
};
