import {
    FormLayout,
    FlexBox,
    FormBase,
    InputField,
    useHttpCommand,
    Text,
    Block,
    Spacer,
    EditorField,
    useHttpQuery,
} from '@esg/ui';
import { Card, Radio, Tag } from 'antd';

import { MailOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import React, { useMemo } from 'react';
import { emailTemplateApi  } from '@esg/business-success-platform';
import { StatusEmailTemPlate } from '@esg/business-success-platform/api/email-template/emailTemplateCreate';
import { EmailTemplateUpdateResponseItem } from '@esg/business-success-platform/api/email-template/emailTemplateUpdate';
import './EmailTemplateForm.scss';
import { IntegrationEmailGetAllResponseItem, integrationEmailGetAll } from '@esg/business-setting/api/integration/integrationEmailGetAll';
import { EmailEncryptionType, IntegrationEmailService, IntegrationStatus, IntegrationType } from '@esg/business-setting/api/integration/Types';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';

interface EmailTemplateValues {
    id: string;
    type?: string;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    subjectCustom?: string;
}

interface EmailTemplateFormProps {
    readonly onSuccess?: (response?: EmailTemplateValues) => void;
    readonly onDelete?: () => void;
    readonly defaultValues?: EmailTemplateValues;
}

export const EmailTemplateForm = (props: EmailTemplateFormProps) => {
    const { t } = useTranslation();
    const [bodyValue, setBodyValue] = React.useState<string>(props.defaultValues?.bodyCustom ?? '');
    const [subjectValue, setSubjectValue] = React.useState<string>(props.defaultValues?.subjectCustom ?? '');
    const [tabValue, setTabValue] = React.useState<string>('EDITOR');

    const variables = {
        'customer.Name': 'John Doe',
        'location': 'Example Location',
        'reservation.date': '2024-05-05',
        'reservation.startTime': '10:00 AM',
        'service.title': 'Example Service',
        'employee.Name': 'Jane Smith',
        'service.duration': '1 hour',
        'location.title': 'Example Location'
    };

    const replaceVariables = (template: string, variables: { [key: string]: string }) => {
        let replacedTemplate = template;

        for (const variable in variables) {
            if (Object.prototype.hasOwnProperty.call(variables, variable)) {
                const regex = new RegExp(`{{${variable}}}`, 'g');
                replacedTemplate = replacedTemplate.replace(regex, variables[variable]);
            }
        }

        return replacedTemplate;
    };

    const { mutateAsync: updateEmailTemplate, isPending: isLoadingUpdate } = useHttpCommand(
        emailTemplateApi.emailTemplateUpdate
    );
    const { data: integrations } = useHttpQuery(integrationEmailGetAll);

    const { emailIntegration } = useMemo(() => {
        const emailIntegration = 
			integrations?.find((it) => it.type === IntegrationType.Email) ?? (
                {
                    type: IntegrationType.Email,
                    configuration: {
                        service: IntegrationEmailService.Gmail,
                        smtpServer: 'smtp.gmail.com',
                        port: 465,
                        encryption: EmailEncryptionType.SSL,
                    },
                    status: IntegrationStatus.Disabled,
                } as Partial<IntegrationEmailGetAllResponseItem>
			);

        return {
            emailIntegration,
        };
    }, [integrations]);

    const onSubmit = React.useCallback(
        async (values: EmailTemplateValues) => {
            const onSuccess = (response?: EmailTemplateUpdateResponseItem) => {
                props.onSuccess?.(response);
            };

            await updateEmailTemplate({
                pathData: { id: props.defaultValues?.id },
                body: values,
            },
            { onSuccess }
            );
            return;

        },
        [props, updateEmailTemplate]
    );
    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                onDeleted={props.onDelete}
                isLoadingButton={isLoadingUpdate}
                labelSubmitCode={translateCodes.SAVE}
                width={1062}
            >
                <FlexBox direction='row' gap={16}>
                    <FlexBox direction='column' gap={16}>
                        <Radio.Group defaultValue={tabValue} buttonStyle='solid'>
                            <Radio.Button onChange={(value) => { setTabValue(value.target.value); }} value='EDITOR'>{t(translateCodes.EMAIL_TEMPLATE_EDITOR)}</Radio.Button>
                            <Radio.Button onChange={(value) => { setTabValue(value.target.value); }} value='PREVIEW'>{t(translateCodes.EMAIL_TEMPLATE_PREVIEW)}</Radio.Button>
                        </Radio.Group>
                        <Card>
                            <InputField
                                name='subject'
                                label={t(translateCodes.EMAIL_TEMPLATE_SUBJECT)}
                                placeholder={t(translateCodes.EMAIL_TEMPLATE_PREVIEW_PLACEHOLDER)}
                                onChange={(value) => {
                                    setSubjectValue(value.target.value);
                                }}
                            />
                        </Card>
                        <Card style={{ height: 400 }} title={t(translateCodes.EMAIL_TEMPLATE_AVAILABLE)}>
                            <FlexBox direction='column'>
                                <FlexBox direction='row'>
                                    <FlexBox direction='column' gap={12}>
                                        <Block>
                                            <Tag color='warning'> customer.Name</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> location</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> reservation.date</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> reservation.startTime</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> service.title</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> employee.Name</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> service.duration</Tag>
                                        </Block>
                                        <Block>
                                            <Tag color='warning'> location.title</Tag>
                                        </Block>
                                    </FlexBox>
                                    <FlexBox direction='column' gap={12}>
                                        <Text translateCode={translateCodes.CUSTOMER_NAME} />
                                        <Text translateCode={translateCodes.RESERVATION_LOCATION} />
                                        <Text translateCode={translateCodes.RESERVATION_DATE} />
                                        <Text translateCode={translateCodes.RESERVATION_BOOKING_TIME} />
                                        <Text translateCode={translateCodes.BOOKING_SERVICE_NAME} />
                                        <Text translateCode={translateCodes.BOOKING_EMPLOYEE_NAME} />
                                        <Text translateCode={translateCodes.BOOKING_SERVICE_DURATION} />
                                        <Text translateCode={translateCodes.STORE_LOCATION} />
                                    </FlexBox>
                                    <Spacer width='10%' />
                                </FlexBox>
                            </FlexBox>
                        </Card>
                    </FlexBox>
                    <FlexBox direction='column' gap={10}>
                        {tabValue === 'EDITOR' && <FlexBox className='email-template-form'>
                            <EditorField name='bodyCustom' onchange={(value) => {
                                setBodyValue(value);
                            }} />
                        </FlexBox>}
                        {tabValue === 'PREVIEW' && <Card style={{ width: 600, borderRadius: 0 }} title={
                            <div>
                                <FlexBox gap={25}>
                                    <MailOutlined style={{ marginRight: -20 }} />
                                    <Text translateCode={emailIntegration.configuration?.sendingFrom} />
                                    <Text fontWeight={500} translateCode={subjectValue} />
                                </FlexBox>
                            </div>
                        }>
                            <Card style={{ backgroundColor: '#F0F2F5' }} bordered={false} >
                                <div>
                                    <div>
                                        <Text fontWeight={700}>{t(translateCodes.EMAIL_TEMPLATE_SUBJECT)}: </Text>
                                        <Text>{subjectValue}</Text>
                                    </div>
                                    <br />
                                    {ReactHtmlParser(replaceVariables(bodyValue, variables))}
                                </div>
                            </Card>
                        </Card>}
                    </FlexBox>

                </FlexBox>
            </FormLayout>
        </FormBase>
    );
};
