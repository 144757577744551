import { GetAllCustomerGroupResponseItem } from '@esg/business-crm';
import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { translateCodes } from '@/locales';

export const CustomerGroupDeleteConfirm = ({ group }: { group?: GetAllCustomerGroupResponseItem }) => {
    return (
        <Spacer top='xs'>
            <Text fontWeight='bold' translateCode={translateCodes.DELETE_CLIENT_GROUP} />
            <Spacer bottom='xs' />
            <Paragraph>
                <Text translateCode={translateCodes.DELETE_CLIENT_GROUP_MSG} /> 
                <Text translateCode=' ' />
                <Text fontWeight='bold'>{group?.name}</Text>
            </Paragraph>
        </Spacer>
    );
};
