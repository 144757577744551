import { GetAllCustomerResponseItem } from '@esg/business-crm';
import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { translateCodes } from '@/locales';

export const CustomerDeleteConfirm = ({ customer }: { customer?: GetAllCustomerResponseItem }) => {
    return (
        <Spacer top='xs'>
            <Text fontWeight='bold' translateCode={translateCodes.DELETE_CUSTOMER} />
            <Spacer bottom='xs' />
            <Paragraph>
                <Text translateCode={translateCodes.DELETE_CUSTOMER_MSG} /> 
                <Text fontWeight='bold'>{customer?.customerCode}</Text>: 
                <Text fontWeight='bold'>  {customer?.fullName}</Text>
            </Paragraph>
        </Spacer>
    );
};
