import { getAllRole, GetCurrentProfileResponse } from '@esg/business-account';
import { AvatarUpload, FlexBox, FormBase, FormLayoutModal, IconMail, InputField, SelectField, globalModalState, useHttpQuery, useLocationOptions } from '@esg/ui';
import React, { useMemo } from 'react';
import { useResetRecoilState } from 'recoil';
import { translateCodes } from '@/locales';

interface ProfileFormProps {
    onSubmit: (values: GetCurrentProfileResponse) => void;
    defaultValues?: GetCurrentProfileResponse;
    isLoading?: boolean;
}

export const ProfileForm = (props: ProfileFormProps) => {
    const resetModalState = useResetRecoilState(globalModalState);
    const {data: rolesResponse, isLoading} = useHttpQuery(getAllRole);

    const { phoneCodeOptions } = useLocationOptions();
    
    const roleOptions = useMemo(()=> {
        return rolesResponse?.items?.map((role) => ({
            label: role.name,
            value: role.id,
        }));
    }, [rolesResponse]);

    return (
        <FormBase onSubmit={props.onSubmit} defaultValues={props.defaultValues}>
            <FormLayoutModal
                useSubmitButton={true}
                isLoadingButton={props.isLoading}
                onCancel={resetModalState}
                okTextCode={translateCodes.SAVE}
            >
                <FlexBox direction='column' gap={16}>
                    <AvatarUpload name='avatar' />
                    <FlexBox gap={16}>
                        <InputField
                            name='firstName'
                            label={translateCodes.FIRST_NAME}
                        />
                        <InputField
                            name='lastName'
                            label={translateCodes.LAST_NAME}
                        />
                    </FlexBox>
                    <SelectField
                        loading={isLoading}
                        label={translateCodes.USER_ROLE}
                        name='roleId'
                        disabled={true}
                        options={roleOptions}
                        defaultValue='owner'
                    />
                    <FlexBox gap={16}>
                        <InputField
                            name='email'
                            label={translateCodes.EMAIL}
                            addonBefore={<IconMail />}
                        />
                        <InputField
                            addonBefore={
                                <SelectField
                                    showSearch
                                    allowClear
                                    style={{ width: 80 }}
                                    name='countryCode'
                                    options={phoneCodeOptions}
                                />
                            }
                            name='phoneNumber'
                            label={translateCodes.PHONE_NUMBER}
                        />
                    </FlexBox>
                </FlexBox>
            </FormLayoutModal>
        </FormBase>
    );
};
