import { ShiftByEmployeeDetail } from '@esg/business-resource-management';
import { dayjs } from '@esg/shared';
import { Text } from '@esg/ui';
import React, { useMemo } from 'react';

interface ShiftAvailableProps {
	readonly shift: ShiftByEmployeeDetail;
}

export const ShiftAvailable = ({ shift }: ShiftAvailableProps) => {
    const { startTime, endTime, breakTimes, timeBlocks } = shift;

    const totalBreakMinutes = useMemo(() => {
        const breakMinutes = breakTimes.reduce(
            (total, bt) => (total += dayjs(bt.endTime).diff(bt.startTime, 'm')),
            0
        );

        const blockMinutes = timeBlocks.reduce(
            (total, tb) => (total += dayjs(tb.endTime).diff(tb.startTime, 'm')),
            0
        );

        return breakMinutes + blockMinutes; 
    }, [breakTimes, timeBlocks]);

    const practicalHour = useMemo(() => dayjs(endTime).diff(startTime, 'h'), [endTime, startTime]);

    return (
        <>
            <Text fontSize={11} fontWeight='bold'>
                {dayjs(startTime).format('h:mm A')} - {dayjs(endTime).format('h:mm A')}
            </Text>
            <Text fontSize={11} ellipsis>
                {`${
                    practicalHour - Math.floor(totalBreakMinutes / 60)
                }h/${practicalHour}h - ${totalBreakMinutes} min break`}
            </Text>
        </>
    );
};
