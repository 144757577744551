import { IntegrationEmailGetAllResponseItem } from '@esg/business-setting/api/integration/integrationEmailGetAll';
import { getIntegrationService, getIntegrationStatus } from '@esg/shared/utils/integrationUtils';
import { FlexBox, Header } from '@esg/ui';
import { Tag } from 'antd';

export const IntegrationDrawerTitle = ({
    integration,
    icon,
}: {
	integration: IntegrationEmailGetAllResponseItem;
	icon: React.ReactNode;
}) => {
    const serviceName = getIntegrationService(integration.configuration.service)!;
    const status = getIntegrationStatus(integration.status);

    return (
        <FlexBox alignItems='center' gap={12}>
            {icon}
            <Header level={4} translateCode={serviceName} />
            <Tag color={status === 'Connected' ? 'green' : 'red'}>{status}</Tag>
        </FlexBox>
    );
};
