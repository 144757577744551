import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetCitiesByCountryAndStateRequest extends HttpRequestData {
    readonly pathData: {
        country: string;
        state: string;
    }
}

export interface GetCitiesByCountryAndStateResponse {
    readonly id: string;
    name: string;
}
export const getCitiesByCountryAndState = httpUtil.createHttpRequestMeta<GetCitiesByCountryAndStateRequest, GetCitiesByCountryAndStateResponse[]>({
    baseUrl: env.apiUrlCountry,
    path: '/v1/countries/:country/states/:state/cities',
    method: 'GET',
});
