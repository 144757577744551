import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';

import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';
import { StatusEmailTemPlate } from './emailTemplateCreate';

interface GetAllEmailTemplateRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}
export interface GetAllEmailTemplateResponseItem {
    id: string;
    type?: string;
    subject?: string;
    status?: StatusEmailTemPlate;
    body?: string;
    bodyCustom?: string;
    SubjectCustom?: string;
}

export const emailTemplateGetAll = httpUtil.createHttpRequestMeta<
    GetAllEmailTemplateRequest,
    PaginationResponse<GetAllEmailTemplateResponseItem>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/email-templates',
    method: 'GET',
    authentication: 'bearer',
});
