import React from 'react';
import { useRecoilState } from 'recoil';
import { GetDetailSaleResponse } from '@esg/business-pos';
import { Block } from '@esg/ui';
import { Spin } from 'antd';
import { FastSaleDetail } from './FastSaleDetail';
import {FastSaleMainTab} from './FastSaleMainTab';
import { currentSaleState } from '@/states';
import './FastSaleMainContent.scss';

interface FastSaleMainContentProps {
    sales: GetDetailSaleResponse[];
    loadingUpdate?: boolean;
    loadingCreate?: boolean;
    isFetching: boolean;
}

export const FastSaleMainContent: React.FC<FastSaleMainContentProps> = ({ 
    sales, 
    loadingCreate,
    loadingUpdate,
    isFetching
}) => {
    const [currentSale] = useRecoilState(currentSaleState);

    return (
        <Block className='fast-sale-tab-wrapper'>
            <FastSaleMainTab sales={sales} />
            <Block className={`fast-sale-tab-content-wrapper ${isFetching ? 'spin': ''}`}>
                {
                    isFetching && <Spin/>
                }
                {
                    currentSale && !isFetching 
                    && <FastSaleDetail 
                        loadingCreate={loadingCreate}
                        loadingUpdate={loadingUpdate}
                    />
                }
            </Block>
        </Block>
    );
};
