import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, TableColumnsType } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import {
    ColumnActions,
    TableCustom,
    globalDrawerState,
    useHttpCommand,
    useHttpQuery,
} from '@esg/ui';
import { bankAccountApi, GetAllBankAccountResponseItem } from '@esg/business-setting';
import { CreateBankAccountBtn } from './button-handler/CreateBankAccountBtn';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { BankAccountForm } from '@/components';

export const BankAccount = () => {
    const { confirm } = Modal;
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const { data: bankAccount, isFetching, refetch } = useHttpQuery(bankAccountApi.getAllBankAccount);
    const { mutateAsync } = useHttpCommand(bankAccountApi.deleteBankAccount);
    const onEdit = React.useCallback(
        (values: GetAllBankAccountResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: 'EDIT BANK ACCOUNT',
                content: (
                    <BankAccountForm
                        onSuccess={refetch}
                        defaultValues={values}
                    />
                ),
            });
        },
        [setDrawerState, refetch]
    );
    const onDelete = React.useCallback(
        async (id: string) => {
            await mutateAsync(
                {
                    pathData: {
                        id: id,
                    },
                },
                {
                    onSuccess(_) {
                        refetch();
                    },
                }
            );
        },
        [mutateAsync, refetch]
    );
    const columns: TableColumnsType<GetAllBankAccountResponseItem> = [
        {
            width: 200,
            title: 'Account Name',
            dataIndex: 'Account Name',
            render: (_, record) => {
                return record.accountName;
            },
        },
        {
            width: 200,
            title: 'Account Number',
            dataIndex: 'Account Number',
            render: (_, record) => {
                return record.accountNumber;
            },
        },
        {
            title: 'Bank Name',
            dataIndex: 'Bank Name',
            render: (_, record) => {
                return record.bankName;
            },
        },
        {
            width: 116,
            title: 'Action',
            align: 'center',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showDeleteConfirm(record.id);
                        }}
                        onClickEdit={() => onEdit(record)}
                    />
                );
            },
        },
    ];
    const showDeleteConfirm = (id: string) => {
        confirm({
            title: 'Delete Bank Account',
            icon: <ExclamationCircleFilled />,
            content: 'Are you sure delete this Bank Account?',
            okText: 'Yes',
            okType: 'danger',
            focusTriggerAfterClose: false,
            cancelText: 'No',
            onOk() {
                onDelete(id);
            }
        });
    };
    return (
        <PageLayout
            pageTitleTransCode='Bank Account'
            rightHeaderPage={<CreateBankAccountBtn refetchCreateBankAccount={refetch} />}
        >
            <TableCustom
                showHeader={false}
                loading={isFetching}
                columns={columns}
                dataSource={bankAccount?.items ?? []}
            />
        </PageLayout>
    );
};
