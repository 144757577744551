import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '@esg/business-management/configs';
import { GetAllServiceResponseItem } from '@esg/business-management';

interface GetDetailFacilitiesRequest extends HttpRequestData {}
export interface ServicesFacility 
{
  readonly id: string;
  serviceId: string;
  facilityId:string;
  service: GetAllServiceResponseItem;
}

export interface GetDetailFacilitiesResponse {
  readonly id: string;
  name: string;
  description?: string;
  serviceFacilities?: ServicesFacility[];
}

export const getDetailFacilities = httpUtil.createHttpRequestMeta<
  GetDetailFacilitiesRequest,
  GetDetailFacilitiesResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/facilities/:id',
    method: 'GET',
    authentication: 'bearer',
});