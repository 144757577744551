import {  HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface UpdateServiceCategoryRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        description?: string;
    };
}

export interface UpdateServiceCategoryResponse {
    readonly id: string;
    name: string;
    description: string;
}

export const updateServiceCategory = httpUtil.createHttpRequestMeta<
    UpdateServiceCategoryRequest,
    UpdateServiceCategoryResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/service-categories/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
