import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { EmployeeGetDetailResponse, ShiftByEmployeeDetail } from '@esg/business-resource-management';
import { SchedulerItem } from '@esg/ui';
import { ShiftQueryParams } from './Types';
import { useShiftResources } from './@share/hooks/useShiftResources';

export interface IShiftContext {
	readonly refetchShifts: () => void;
	readonly employees: EmployeeGetDetailResponse[];
	readonly shiftItems: SchedulerItem<ShiftByEmployeeDetail>[];
	setQuery: (value: Partial<ShiftQueryParams>) => void;
	query: ShiftQueryParams;
	loading: boolean;
}

const ShiftContext = createContext<IShiftContext>(null!);

export const ShiftProvider = ({ children }: PropsWithChildren) => {
    const { employees, shiftItems, refetchShifts, setQuery, query, loading } = useShiftResources();

    const context = useMemo<IShiftContext>(
        () => ({
            query,
            loading,
            setQuery,
            employees,
            shiftItems,
            refetchShifts,
        }),
        [loading, query, refetchShifts, setQuery, shiftItems, employees]
    );
    return <ShiftContext.Provider value={context}>{children}</ShiftContext.Provider>;
};

export const useShiftContext = () => useContext(ShiftContext);
