import { deleteShift, shiftDisableCreate, ShiftGetDetailResponse } from '@esg/business-resource-management';
import { globalModalState, Paragraph, Spacer, useHttpCommand } from '@esg/ui';
import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useShiftContext } from '../../../../../../ShiftContext';

export const useShiftDelete = () => {
    const { refetchShifts } = useShiftContext();
    const [, showModal] = useRecoilState(globalModalState);
    const hideModal = useResetRecoilState(globalModalState);

    const { mutateAsync: createShiftDisable } = useHttpCommand(shiftDisableCreate, {
        onSuccess() {
            refetchShifts();
            hideModal();
        },
    });

    const {mutateAsync: deleteShiftAsync} = useHttpCommand(deleteShift, {
        onSuccess() {
            refetchShifts();
            hideModal();
        },
    });

    const handleDelete = useCallback((shift: ShiftGetDetailResponse, date: string) => {
        showModal({
            content: (
                <Spacer top='xs'>
                    <Paragraph>Are you sure you want to delete this time shift ?</Paragraph>
                </Spacer>
            ),
            isOpen: true,
            onOk: async () => {
                if (shift.date) {
                    return void await deleteShiftAsync({
                        pathData: {
                            id: shift.id
                        }
                    });
                }

                await createShiftDisable({
                    body: {
                        date,
                        shiftId: shift.id,
                        employeeId: shift.employeeId,
                    },
                });
            },
            showModalDelete: true,
        });
    }, [createShiftDisable, deleteShiftAsync, showModal]);

    return handleDelete;
};
