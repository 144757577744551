import { EmployeeGetDetailResponse, ShiftGetDetailResponse, TimeOffGetDetailResponse } from '@esg/business-resource-management';
import { convertSameDate, dayjs, Dayjs } from '@esg/shared';

export interface EmployeeTimeFrame {
	id?: string;
	employeeId: string;
	endTime: string;
	startTime: string;
}

interface GetEmployeeTimeOffsWorkParams {
	readonly currentDate: string;
	readonly shifts: ShiftGetDetailResponse[];
	readonly branchWorkingTime: [Dayjs, Dayjs];
}

export const getEmployeeTimeOffsWork = (params: GetEmployeeTimeOffsWorkParams) => {
    const { shifts, branchWorkingTime, currentDate } = params;

    const result: TimeOffGetDetailResponse[] = [];

    shifts.forEach((shift) => {
        if (dayjs(currentDate).day() === shift.dayOfWeek) {
            const diffStartTime = convertSameDate(currentDate, shift.startTime).diff(
                branchWorkingTime[0],
                'm'
            );

            if (diffStartTime > 0) {
                result.push({
                    employeeId: shift.employeeId,
                    startTime: branchWorkingTime[0].toISOString(),
                    endTime: convertSameDate(currentDate, shift.startTime).toISOString(),
                } as TimeOffGetDetailResponse);
            }

            const diffEndTime = branchWorkingTime[1].diff(
                convertSameDate(currentDate, shift.endTime),
                'm'
            );

            if (diffEndTime > 0) {
                result.push({
                    employeeId: shift.employeeId,
                    endTime: branchWorkingTime[1].toISOString(),
                    startTime: convertSameDate(currentDate, shift.endTime).toISOString(),
                } as TimeOffGetDetailResponse);
            }
        }
    });

    return result;
};

export const getEmployeeTimeOffs = ({
    shifts,
    timeOffs,
    employees,
    branchWorkingTime
}: {
    timeOffs: TimeOffGetDetailResponse[];
	shifts: ShiftGetDetailResponse[];
	branchWorkingTime: [Dayjs, Dayjs];
	employees: EmployeeGetDetailResponse[];
}) => {
    const employeeIdsByShift = shifts?.map((s) => s.employeeId) || [];
    const result = timeOffs.filter((t) => employeeIdsByShift.includes(t.employeeId)) || [];

    const employeeDayOffs: TimeOffGetDetailResponse[] = [];

    employees.forEach((o) => {
        if (!employeeIdsByShift.includes(o.id)) {
            employeeDayOffs.push({
                employeeId: o.id,
                startTime: branchWorkingTime[0].toISOString(),
                endTime: branchWorkingTime[1].toISOString(),
            } as TimeOffGetDetailResponse);
        }
    });

    return [...result, ...employeeDayOffs];
};
