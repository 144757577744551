import { Text, FlexBox, IconBorn, IconCalendar, IconFace, IconGroup, IconLocation, IconMail, IconPhone, IconRank, IconSource, useLocalesFormat, Spacer, IconArrowUpClient, IconArrowDownClient } from '@esg/ui';
import { Card, Divider, theme, } from 'antd';
import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { GetOverviewClientDetailResponse } from '@esg/business-report/api/client-detail-report';
import { useTranslation } from 'react-i18next';
import { genderUtil } from '@esg/shared';
import { translateCodes } from '@/locales/translateCodes';
import './CustomerDetailTable.scss';

interface CustomerPackagesDetailProps {
    customer?: GetCustomerDetailResponse;
    loading?: boolean;
    reportOverview?: GetOverviewClientDetailResponse
}
const { useToken } = theme;

export function CustomerPackagesDetail({ customer, loading }: CustomerPackagesDetailProps) {
    const { token } = useToken();
    const { formatDate } = useLocalesFormat();
    const { t } = useTranslation();
    const [isShowDetail, setIsShowDetail] = React.useState(false);
    const [isShowOverview, setIsShowOverview] = React.useState(false);
    const infos = React.useMemo(() => {
        return [
            {
                icon: <IconPhone color='#98A2B3' />,
                label: customer?.phone
            },
            {
                icon: <IconMail color='#98A2B3' />,
                label: customer?.email
            },
            {
                icon: <IconLocation color='#98A2B3' />,
                label: customer?.address
            },
            {
                icon: <IconBorn color='#98A2B3' />,
                label:
                    customer?.dateOfBirth ? t(translateCodes.BORN_ON) + ' ' + formatDate(customer?.dateOfBirth) : '-'
            },
            {
                icon: <IconFace color='#98A2B3' />,
                label: genderUtil(customer?.gender)
            },
            {
                icon: <IconCalendar color='#98A2B3' />,
                label: t(translateCodes.START_ON) + ' ' + formatDate(customer?.profileCreationTime ?? '')
            }
        ];
    }, [customer?.phone, customer?.dateOfBirth, customer?.email, customer?.gender, customer?.address, customer?.profileCreationTime, t, formatDate]);

    const infoShowMores = React.useMemo(() => {
        return [
            {
                icon: <IconSource color='#98A2B3' />,
                label: customer?.customerSource?.name
            },
            {
                icon: <IconGroup color='#98A2B3' />,
                label: customer?.customerGroup?.name
            },

            {
                icon: <IconRank color='#98A2B3' />,
                label: customer?.note
            },
        ];
    }, [customer?.customerSource?.name, customer?.customerGroup?.name, customer?.note]);
    return (
        <Card className='customer-detail-info' loading={loading} style={{ borderRadius: 16 }} >
            <FlexBox gap={12} flexWrap='wrap'>
                <a onClick={() => {
                    setIsShowOverview(!isShowOverview);
                }}>
                    <FlexBox alignItems='center' gap={8} flex='none' width={244}>
                        {isShowOverview ? <IconArrowUpClient color='#98A2B3' /> : <IconArrowDownClient color='#98A2B3' />}
                        <Text fontSize={14} fontWeight='bold'>Packages (3)</Text>
                    </FlexBox>
                </a>
            </FlexBox>
            {
                isShowOverview && (
                    <>
                        <Spacer height={12} />
                        <FlexBox gap={12} flexWrap='wrap'>
                            {infos.map((o, i) => {
                                return (
                                    <FlexBox key={i} className='customer-info-item' gap={8} alignItems='center' width={244} flex='none'>
                                        {o.icon}
                                        {o.label ? <Text maxWidth={200} translateCode={o.label} /> : '-'}
                                    </FlexBox>
                                );
                            })}
                        </FlexBox>
                        {isShowDetail &&
                            <>
                                <Divider style={{ margin: '12px 0' }} />
                                <FlexBox gap={12} flexWrap='wrap'>
                                    {infoShowMores.map((o, i) => {
                                        return (
                                            <FlexBox key={i} className='customer-info-item' gap={8} alignItems='center' width={244} flex='none'>
                                                {o.icon}
                                                {o.label ? <Text maxWidth={200} translateCode={o.label} /> : '-'}
                                            </FlexBox>
                                        );
                                    })}
                                </FlexBox>
                            </>
                        }
                        <Spacer height={12} />
                        <FlexBox className='customer-info-item' alignItems='center' gap={8} flex='none'>
                            <a onClick={() => {
                                setIsShowDetail(!isShowDetail);
                            }} color={token.colorPrimary}>{isShowDetail ? 'Show less' : 'Show more'}</a>
                        </FlexBox>
                    </>
                )
            }
        </Card>
    );
}
