import React, { useState } from 'react';

import { fullCalendarTheme } from '../@shared';

interface PositionScroll {
    top: number;
    left: number;
}
  
interface FullCalendarContextProps {
    startDate?: Date;
    endDate?: Date;
    viewType?: 'day' | 'week' | 'month';
    positionScroll?: PositionScroll,
    setScrollPosition?: (p : PositionScroll) => void;
    children?: React.ReactNode; 
    columnWidth?: number;
    columnPadding?: number;
    rowHeight?: number;
    headerHeight?: number;
    siderWidth?: number;
    formatDate?: string;
    bodyHeight?: number;
}

export const FullCalendarContext = React.createContext<FullCalendarContextProps>({
    setScrollPosition: () => {},
});

export const FullCalendarContextProvider = (props: FullCalendarContextProps) => {

    const setScrollPosition = (position: PositionScroll) => {
        setState({...state, positionScroll: position});
    };

    const initState = {
        setScrollPosition: setScrollPosition,
        columnWidth: fullCalendarTheme.defaultColumnWidth,
        columnPadding: fullCalendarTheme.defaultColumnPadding,
        rowHeight: fullCalendarTheme.defaultRowHeight,
        headerHeight: fullCalendarTheme.defaultHeaderHeight,
        siderWidth: fullCalendarTheme.defaultSiderWidth,
        formatDate: fullCalendarTheme.defaultFormatDate,
        bodyHeight: fullCalendarTheme.defaultBodyHeight,
        ...props
    }; 

    const [state, setState] = useState<FullCalendarContextProps>(initState);

    return (
        <FullCalendarContext.Provider value={state}>
            {props.children}
        </FullCalendarContext.Provider>
    );
};