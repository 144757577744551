import { AppButton, FlexBox, IconPen, IconTrash } from '@esg/ui';
import React, { useMemo } from 'react';
import { dayjs } from '@esg/shared';
import { useBreakTimeDelete } from '../@share/hooks';
import { ScheduleTimeDisableValue } from '@/pages/scheduler/Types';
import { useBreakTimeForm } from '@/hooks/useBreakTimeForm';
import { useTimeBlockDeleteConfirm } from '@/hooks/useTimeBlockDeleteConfirm';
import { useTimeBlockForm } from '@/hooks/useTimeBlockForm';

export const BreakTimePopoverAction = ({ data }: { data: ScheduleTimeDisableValue }) => {
    const { type, startTime } = data;

    const handleDeleteTimeBlock = useTimeBlockDeleteConfirm();
    const handleDeleteBreakTime = useBreakTimeDelete();
    const showBreakTimeForm = useBreakTimeForm();
    const showTimeBlockForm = useTimeBlockForm();

    const date = useMemo(() => dayjs(startTime).startOf('d').toISOString(), [startTime]);

    return (
        <FlexBox justifyContent='flex-start' gap={16}>
            <AppButton
                shape='circle'
                onClick={() => {
                    if (type === 'BREAK_TIME') {
                        return void handleDeleteBreakTime({ ...data, date });
                    }

                    handleDeleteTimeBlock(data, date);
                }}
            >
                <FlexBox preset='row-center'>
                    <IconTrash />
                </FlexBox>
            </AppButton>
            <AppButton
                shape='circle'
                onClick={() => {
                    if (type === 'BREAK_TIME') {
                        return void showBreakTimeForm({ ...data, date });
                    }

                    showTimeBlockForm({
                        mode: 'update',
                        defaultValues: { ...data, originDate: data.date, date },
                    });
                }}
            >
                <FlexBox preset='row-center'>
                    <IconPen />
                </FlexBox>
            </AppButton>
        </FlexBox>
    );
};
