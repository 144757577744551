import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { PlusCircleFilled } from '@ant-design/icons';
import { translateCodes } from '@/locales';
import { FacilityForm } from '@/components/form/facility-form';

interface AddFacilityBtnProps{
    refetch: () => void;
}
export const AddFacilityBtn = (props: AddFacilityBtnProps) => {
    const { t } = useTranslation();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    return (
        <AppButton
            type='primary'
            block={true}
            translateCode={t(translateCodes.ADD_FACILITY)}
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: t(translateCodes.ADD_FACILITY),
                    content: <FacilityForm 
                        {...{ refetchFacility: props.refetch }}
                        labelSubmitCode='submit' />
                });
            }}
            icon={<PlusCircleFilled />}
        />
    );
};
