import { ClockCircleOutlined } from '@ant-design/icons';
import { AppButton, Block, FlexBox, IconTrash, Spacer, Text, TimePickerField } from '@esg/ui';
import React, { useMemo } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { dayjs } from '@esg/shared';
import { translateCodes } from '@/locales';

interface ShiftFormItemProps {
    readonly title?: string
	readonly fieldName: string;
	readonly fieldIndex: number;
	readonly remove?: UseFieldArrayRemove;
}

export const ShiftFormItem = ({ fieldName, fieldIndex, remove, title }: ShiftFormItemProps) => {
    const { watch } = useFormContext();

    const startTime = watch(`${fieldName}.startTime`);
    const endTime = watch(`${fieldName}.endTime`);

    const { hour, minute } = useMemo(() => {
        const totalMinute = dayjs(endTime).diff(startTime, 'm');

        return {
            hour: Math.floor(totalMinute / 60),
            minute: totalMinute % 60,
        };
    }, [endTime, startTime]);

    return (
        <FlexBox gap={24} alignItems='center'>
            <Block width={136}>
                <Spacer left={58}>
                    <Text translateCode={title ? title : translateCodes.BREAK} ellipsis/>
                </Spacer>
            </Block>
            <Block>
                <TimePickerField name={`${fieldName}.startTime`} format='h:mm A' />
            </Block>
            <Block>
                <TimePickerField name={`${fieldName}.endTime`} format='h:mm A' />
            </Block>
            <FlexBox flex='none' gap={3} width={77}>
                <ClockCircleOutlined />
                <Text>
                    {hour}h {minute ? minute + 'min' : null}
                </Text>
            </FlexBox>
            {remove && (
                <Spacer left={4}>
                    <AppButton
                        type='text'
                        shape='circle'
                        onClick={() => {
                            remove(fieldIndex);
                        }}
                    >
                        <IconTrash />
                    </AppButton>
                </Spacer>
            )}
        </FlexBox>
    );
};
