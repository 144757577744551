import { httpUtil } from '@esg/framework';

import { env } from '../../config';

export interface DisconnectProviderRequest {
    readonly body: {
        readonly userId: string;
        readonly provider?: string;
    };
}

export interface DisconnectProviderResponse {
    readonly id: string;
    readonly loginMethods: string[];
}

export const disconnectProvider = httpUtil.createHttpRequestMeta<
    DisconnectProviderRequest,
    DisconnectProviderResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/account/disconnect-provider',
    method: 'POST',
});
