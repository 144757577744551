import './SaleAnalyzerClient.scss';

import React, { useState } from 'react';
import { Dayjs } from '@esg/shared';
import { Spacer } from '@esg/ui';
import { useTranslation } from 'react-i18next';
import AnalyserClientHeader from './children/AnalyserClientHeader';
import AnalyserClientOverview from './children/AnalyserClientOverview';
import SalesByClientTableTable from './children/table/SalesByClientTable';
import AnalyserClientMainChart from './children/AnalyserClientMainChart';
import AnalyserClientSubChart from './children/AnalyserClientSubChart';
import { PageLayout } from '@/layouts';
import { dateHelper } from '@/helpers';
import { translateCodes } from '@/locales';

export function SaleAnalyzerClient() {
    const { t } = useTranslation();
    const {startOfMonth, endOfMonth} = dateHelper.getStartAndEndOfMonth();
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([startOfMonth, endOfMonth]);

    return (
        <PageLayout
            pageTitleTransCode={t(translateCodes.CLIENT_REPORT)}
            className='sale-analyzer-client'
            rightHeaderPage={<AnalyserClientHeader 
                dateRange={dateRange} 
                setDateRange={(dates)=>{
                    setDateRange([dates[0].startOf('day'), dates[1].endOf('day')]);
                }}/>}
        >
            <AnalyserClientOverview dateRange={dateRange}/>
            <AnalyserClientMainChart dateRange={dateRange}/>
            <Spacer top={30}/>
            <AnalyserClientSubChart dateRange={dateRange} />
            <Spacer top={30}/>
            <SalesByClientTableTable dateRange={dateRange} />
        </PageLayout>
    );
}
