import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface GetSaleSummaryRequest extends HttpRequestData {
}

export interface GetSaleSummaryResponse {
    readonly numberSalePaid: number;
    readonly numberSaleUnpaid: number;
    readonly numberSaleCancelled: number;
}

export const getSaleSummary = httpUtil.createHttpRequestMeta<GetSaleSummaryRequest, GetSaleSummaryResponse>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sales/summary',
    method: 'GET',
    authentication: 'bearer'
});