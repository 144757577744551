import { Image } from 'antd';
import React from 'react';
import { AbbreviationsNamePaymentMethod } from '@/pages/setting/salon-information/payment-method/children';
import { resourceSettingTheme } from '@/pages/setting/@shared';

interface PaymentMethodImageProps {
    readonly paymentMethodName: string;
}

export const PaymentMethodImage = (props: PaymentMethodImageProps) => {
    const { paymentMethodName } = props;

    switch (paymentMethodName.toLocaleLowerCase()) {
    case 'credit card':
        return (
            <Image
                src={resourceSettingTheme.creditCardIcon}
                preview={false}
                width={34}
                height={24}
            />
        );
    case 'cash':
        return (
            <Image
                src={resourceSettingTheme.cashIcon}
                preview={false}
                width={34}
                height={24}
            />
        );
    case 'bank transfer':
        return (
            <Image
                src={resourceSettingTheme.bankTransferIcon}
                preview={false}
                width={34}
                height={24}
            />
        );
    case 'split payment':
        return (
            <Image
                src={resourceSettingTheme.splitPaymentIcon}
                preview={false}
                width={34}
                height={24}
            />
        );
    case 'prepaid card':
        return (
            <Image
                src={resourceSettingTheme.prepaidCashIcon}
                preview={false}
                width={34}
                height={24}
            />
        );
    case 'membership credit':
        return (
            <Image
                src={resourceSettingTheme.membershipCreditIcon}
                preview={false}
                width={34}
                height={24}
            />
        );
    default:
        return (
            <AbbreviationsNamePaymentMethod
                namePayment={paymentMethodName}
            />
        );
    }
};
