import { SaleDetailGetAllResponseItem } from '@esg/business-pos';
import { ColumnAvatarGroup } from '@esg/ui';
import { flatten } from 'lodash';
import React, { useMemo } from 'react';

interface SaleEmployeeColumnProps {
    readonly saleDetails: SaleDetailGetAllResponseItem[];
}

export default function SaleEmployeeColumn(props: SaleEmployeeColumnProps) {
    const { saleDetails } = props;
    
    const employeesInfo = useMemo(()=>{
        const listEmployees = flatten(saleDetails.map(a => flatten(a.saleDetailEmployees?.map(f => f.employee))));

        const employees = listEmployees.filter(x => !!x).map(o => ({
            id: o?.id,
            avatar: o?.avatar,
            fullName: o?.fullName,
        }));
        return employees;
    },[saleDetails]);

    if(employeesInfo?.length == 0){
        return;
    }

    const prefixEnd = employeesInfo?.length >= 1 ? `${employeesInfo?.length} employees` : employeesInfo[0].fullName;
    
    return ( 
        <ColumnAvatarGroup
            maxWidthText={200}
            items={employeesInfo}
            maxCount={2}
            prefixEnd={prefixEnd}
        />
    );
}
