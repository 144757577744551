import { deleteRole, getAllRole, GetRoleDetailResponse } from '@esg/business-account';
import { ActionsColumn, ColumnAvatarGroup, globalDrawerState, globalModalState, IconPen, IconTrash, TableCustom, Translate, useHttpCommand, useHttpQuery, usePagination } from '@esg/ui';
import { TableColumnsType } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { RoleDeleteConfirm } from './RoleDeleteConfirm';
import { translateCodes } from '@/locales';
import { RoleForm } from '@/components';

export const RoleTable = () => {
    const [queryParams, setQueryParams] = usePagination({
        orderBy: 'IsSystem',
        orderDirection: 'desc',
    });
    const {t} = useTranslation();

    const [, setGlobalModal] = useRecoilState(globalModalState);
    const [, setGlobalDrawer] = useRecoilState(globalDrawerState);

    const resetGlobalModal = useResetRecoilState(globalModalState);
    const resetGlobalDrawer = useResetRecoilState(globalDrawerState);

    const {data: rolesResponse, isFetching, refetch} = useHttpQuery(getAllRole, {
        query: queryParams,
    });

    const {mutateAsync: deleteRoleMutate} = useHttpCommand(deleteRole, {
        onSuccess() {
            refetch();
            resetGlobalModal();
            resetGlobalDrawer();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_ACC_06),
            });
        },
        onError() {
            resetGlobalModal();
            resetGlobalDrawer();
            viewRequests.showNotification.send({
                type: 'warning',
                placement: 'bottomRight',
                message: (
                    <Translate
                        type='html'
                        translateCode={translateCodes.CANNOT_DELETE_ROLE_WITH_USERS}
                    />
                ),
            });
        },
        showNotificationError: false,
    });

    const handleDeleteRole = React.useCallback((role: GetRoleDetailResponse) => {
        setGlobalModal({
            isOpen: true,
            showModalDelete: true,
            content: <RoleDeleteConfirm role={role} />,
            onOk: async () => await deleteRoleMutate({pathData: {id: role.id}}),
        });
    }, [deleteRoleMutate, setGlobalModal]);
    
    const handleUpdateRole = React.useCallback((role: GetRoleDetailResponse) => {
        setGlobalDrawer({
            isOpen: true,
            titleTransCode: translateCodes.EDIT_ROLE,
            content: <RoleForm 
                defaultValues={role}
                onDelete={() => handleDeleteRole(role)}
                onSuccess={() => {
                    resetGlobalDrawer();
                    refetch();
                    viewRequests.showNotification.send({
                        type: 'success',
                        message: t(translateCodes.NM_ACC_05),
                    });
                }}
            />,
        });
    }, [handleDeleteRole, refetch, resetGlobalDrawer, setGlobalDrawer, t]);

    const columns = useMemo<TableColumnsType<GetRoleDetailResponse>>(() => [
        {
            key: 'name',
            title: t(translateCodes.NAME),
            dataIndex: 'name',
        },
        {
            key: 'description',
            title: t(translateCodes.DESCRIPTION),
            dataIndex: 'description',
        },
        {
            key: 'users',
            title: t(translateCodes.ASSIGNED),
            dataIndex: 'users',
            render: (users) => {
                const prefixEnd = users?.length >= 1 ? `${users?.length} users` : users[0]?.fullName;

                return  <ColumnAvatarGroup
                    maxWidthText={200}
                    items={users}
                    maxCount={2}
                    prefixEnd={prefixEnd}
                />;
            },
        },
        {
            key: 'action',
            title: t(translateCodes.ACTION),
            render: (_, record) => {

                if(record.isSystem) {
                    return;
                }

                return <ActionsColumn
                    actions={[
                        {
                            icon: <IconPen />,
                            onClick: () => handleUpdateRole(record),
                            tooltipTitle: t(translateCodes.EDIT),
                        },
                        {
                            icon: <IconTrash />,
                            onClick: () => handleDeleteRole(record),
                            tooltipTitle: t(translateCodes.DELETE),
                        }
                    ]}
                />;
            }
        }
    ],[handleDeleteRole, handleUpdateRole, t]);

    return (
        <TableCustom
            loading={isFetching}
            columns={columns}
            dataSource={rolesResponse?.items}
            titleTableTransCode={translateCodes.ROLE_LIST}
            placeholderSearchTransCode={translateCodes.SEARCH_ROLE_PLACEHOLDER}
            pageInfo={rolesResponse?.meta}
            onPageChange={(page, pageSize) =>
                setQueryParams({ 
                    ...queryParams, 
                    page: page.toString(),
                    pageSize: pageSize?.toString()
                })
            }
            onSearch={(value) =>
                setQueryParams({ ...queryParams, search: value })
            }
        />
    );
};
