import { EmployeeGetDetailResponse } from '../employee';

export interface TimeOff {
	readonly organizationId: string;
	readonly tenantId: string;

	readonly id: string;
	readonly date: string;
	readonly endTime: string;
	readonly startTime: string;
	readonly isRepeat: boolean;
	readonly type: TimeOffType;
	readonly employeeId: string;
	readonly repeatToDate: string;
	readonly employees: EmployeeGetDetailResponse;

}

export enum TimeOffType {
    AnnualLeave = 0,
    SickLeave = 1,
    Training = 2,
    NoShow = 3,
    LateForWork = 4,
    EarlyLeave = 5,
    Other = 6,
} 
