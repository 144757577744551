import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteProductCategoryRequest extends HttpRequestData {
}

export const deleteProductCategory = httpUtil.createHttpRequestMeta<
    DeleteProductCategoryRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/product-categories/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
