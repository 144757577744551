import React from 'react';
import { SchedulerItem, Scheduler } from '@esg/ui/components/schedulers';

import { SchedulerItemInfo } from '../../Types';
import { useSchedulerContext } from '../../@share/SchedulerContext';
import { useSchedulerTableDrop } from '../../@share/hooks/useSchedulerTableDrop';
import { useBreakTimeEvent } from '../../@share/hooks/useBreakTimeEvent';
import { handleCreateAppointment } from './@share/handleCreateAppointment';
import { getSchedulerDisableTimes, getSchedulerEmployeeRowInfos } from './@share/utils';
import { useSchedulerRenderRowInfo , useTimeBlockRefetchListener } from './@share/hooks';
import { SmartSchedulerItem } from './children/smart-scheduler-item';

interface SchedulerTableProps {
	readonly schedulerItems: SchedulerItem<SchedulerItemInfo>[];
}

export const SchedulerTable = (props: SchedulerTableProps) => {
    const {
        loading,
        employees,
        timeDisables,
        employeeTimeOffs,
        branchWorkingTime,
        branchBusinessHour,
        appointmentSetting,
        refetchAppointment,
    } = useSchedulerContext();
    const { schedulerItems } = props;

    const onDrop = useSchedulerTableDrop();

    useBreakTimeEvent();

    useTimeBlockRefetchListener();

    const renderRowInfo = useSchedulerRenderRowInfo();

    const renderItem = React.useCallback(
        (item: SchedulerItem<SchedulerItemInfo>) => (
            <SmartSchedulerItem item={item} refetchAppointment={refetchAppointment} />
        ),
        [refetchAppointment]
    );

    const breakTimeItems = React.useMemo(
        () =>
            getSchedulerDisableTimes({
                date: branchWorkingTime[0],
                list: timeDisables,
                type: 'TIME_DISABLE',
            }),
        [branchWorkingTime, timeDisables]
    );

    const timeOffItems = React.useMemo(
        () =>
            getSchedulerDisableTimes({
                date: branchWorkingTime[0],
                list: employeeTimeOffs,
                type: 'TIME_OFF',
            }),
        [branchWorkingTime, employeeTimeOffs]
    );

    return (
        <Scheduler
            columns={[]}
            onDrop={onDrop}
            loading={loading}
            layout='horizontal'
            baseRowHeight={100}
            baseColumnWidth={100}
            renderTimeMaker={true}
            renderItem={renderItem}
            timesDisabled={timeOffItems}
            renderRowInfo={renderRowInfo}
            endTime={branchWorkingTime[1]}
            startTime={branchWorkingTime[0]}
            onCreate={handleCreateAppointment}
            items={[...schedulerItems, ...breakTimeItems]}
            rowInfos={getSchedulerEmployeeRowInfos(employees)}
            isDisabled={branchBusinessHour?.isDayOff ?? false}
            minutesPerCell={appointmentSetting?.timeSlotDuration ?? 15}
        />
    );
};
