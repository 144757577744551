import {
    BreakTimeGetDetailResponse,
    deleteBreakTime,
    deleteShiftByBreakTime,
} from '@esg/business-resource-management';
import { Spacer, Text, Translate, globalModalState, useHttpCommand } from '@esg/ui';
import { useRecoilState, useResetRecoilState } from 'recoil';
import React, { useCallback } from 'react';
import { useSchedulerContext } from '@/pages/scheduler/@share/SchedulerContext';
import { translateCodes } from '@/locales';

export const useBreakTimeDelete = () => {
    const { refetchShifts } = useSchedulerContext();
    const [, showModal] = useRecoilState(globalModalState);
    const hideModal = useResetRecoilState(globalModalState);

    const { mutateAsync: deleteBreakTimeAsync } = useHttpCommand(deleteBreakTime, {
        onSuccess() {
            hideModal();
            refetchShifts();
        },
    });

    const { mutateAsync: deleteShift } = useHttpCommand(deleteShiftByBreakTime, {
        onSuccess() {
            hideModal();
            refetchShifts();
        },
    });

    const del = useCallback(
        async (values: BreakTimeGetDetailResponse) => {
            if (values.isFixed) {
                return void deleteShift({
                    pathData: {
                        id: values.shiftId
                    },
                    body: values
                });
            }

            await deleteBreakTimeAsync({
                pathData: {
                    id: values.id,
                },
            });
        },
        [deleteBreakTimeAsync, deleteShift]
    );

    return (breakTime: BreakTimeGetDetailResponse) =>
        showModal({
            content: (
                <Spacer top='xs'>
                    <Text>
                        <Translate translateCode={translateCodes.DELETE_BREAK_TIME_CONFIRM} />
                    </Text>
                </Spacer>
            ),
            isOpen: true,
            showModalDelete: true,
            onOk: () => {
                del(breakTime);
            },
        });
};
