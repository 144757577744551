import { paymentMethodApi } from '@esg/business-setting';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import {
    FormBase,
    FormLayout,
    globalDrawerState,
    InputField,
    useHttpCommand,
} from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
interface PaymentMethodValues {
    id?: string;
    name: string;
    isActive: boolean;
}

interface PaymentMethodProps {
    readonly onSuccess?: () => void;
    readonly onDeleted?: () => void;
    readonly defaultValues?: PaymentMethodValues;
}
export const PaymentMethodForm = (props: PaymentMethodProps) => {
    const {t} = useTranslation();
    const { mutateAsync: createPaymentMethod, isPending: isCreating } = useHttpCommand(
        paymentMethodApi.createPaymentMethod
    );

    const { mutateAsync: updatePaymentMethod, isPending: isUpdating } = useHttpCommand(
        paymentMethodApi.updatePaymentMethod
    );

    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const isUpdate = !!props.defaultValues?.id;
    const onSubmit = React.useCallback(
        async (values: PaymentMethodValues) => {
            const onSuccess = () => {
                props.onSuccess?.();
                resetDrawerState();
            };

            if (isUpdate) {
                return void await updatePaymentMethod(
                    {
                        pathData: { id: props.defaultValues?.id },
                        body: values,
                    },
                    { onSuccess }
                );
            }

            await createPaymentMethod(
                {
                    body: {
                        ...values,
                        isActive: true,
                    },
                },
                { onSuccess }
            );
        },
        [createPaymentMethod, isUpdate, props, resetDrawerState, updatePaymentMethod]
    );

    return (
        <FormBase onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FormLayout
                isLoadingButton={isCreating || isUpdating}
                onDeleted={props.onDeleted}
                labelSubmitCode={
                    isUpdate ? translateCodes.SAVE : translateCodes.CREATE
                }
            >
                <InputField
                    rules={{ required: translateCodes.PAYMENT_METHOD_REQUIRED }}
                    name='name'
                    label= {t(translateCodes.NAME)}
                    placeholder={t(translateCodes.ENTER_NAME_METHOD)}
                />
            </FormLayout>
        </FormBase>
    );
};
