import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface EmployeeDeleteRequest extends HttpRequestData {
	readonly pathData: {
		id: string;
	};
}

export const deleteEmployee = httpUtil.createHttpRequestMeta<EmployeeDeleteRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/employees/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
