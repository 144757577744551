import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface getOverviewClientGroupRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface getOverviewClientGroupResponse {
    newClient?: number;
    name?: number;
}

export const getOverviewClientGroupReport = httpUtil.createHttpRequestMeta<
    getOverviewClientGroupRequest,
	getOverviewClientGroupResponse[]
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/clients-report/overview-group',
    authentication: 'bearer',
});