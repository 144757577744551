import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../config';
import { GetUserDetailResponse } from './getUserDetail';

interface GetAllUserRequest extends HttpRequestData {
    readonly query: PaginationRequest
}

export const getAllUser = httpUtil.createHttpRequestMeta<
    GetAllUserRequest,
    PaginationResponse<GetUserDetailResponse>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/users',
    method: 'GET',
    authentication: 'bearer',
});
