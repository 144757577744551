import { HttpRequestData, httpUtil } from '@esg/framework';

import { ProductUnitType } from '@esg/shared/enum';
import { env } from '../../configs';
import { GetAllProductUnitResponseItem } from './getAllProductUnit';

interface UpdateProductUnitRequest extends HttpRequestData {
    readonly body: {
        id?: string;
        name: string;
        type: ProductUnitType;
    }
}

export const updateProductUnit = httpUtil.createHttpRequestMeta<
    UpdateProductUnitRequest,
    GetAllProductUnitResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/product-units/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
