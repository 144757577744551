import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';

import {  GetDetailFacilitiesResponse } from '.';

interface GetAllFacilitiesRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}

export const getAllFacilities = httpUtil.createHttpRequestMeta<
	GetAllFacilitiesRequest,
	PaginationResponse<GetDetailFacilitiesResponse>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/facilities',
    method: 'GET',
    authentication: 'bearer',
});