import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../config';
import { GetUserDetailResponse, UserBranch } from './getUserDetail';

interface UpdateUserRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: string;
    };
    readonly body: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly phoneNumber: string;
        readonly roleId: string;
        readonly userBranches: UserBranch[];
    };
}

export const updateUser = httpUtil.createHttpRequestMeta<
    UpdateUserRequest,
    GetUserDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/users/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
