import { TFunction } from 'i18next';
import { translateCodes } from '@/locales';

export const userRules = {
    lastName: (t: TFunction) => ({
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_LAST_NAME, { number: 50 }),
        },
        minLength: {
            value: 2,
            message: t(translateCodes.LAST_NAME_MIN_LENGTH),
        },
        pattern: {
            value: /^[^!@#$%^&*(),.?":{}|<>]/,
            message: translateCodes.VAL_SM_04,
        },
    }),
    firstName:(t: TFunction) => ({
        required: translateCodes.VAL_SM_01,
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_FIRST_NAME, { number: 50 }),
        },
        minLength: {
            value: 2,
            message: t(translateCodes.FIRST_NAME_MIN_LENGTH),
        },
        pattern: {
            value: /^[^!@#$%^&*(),.?":{}|<>]/,
            message: translateCodes.VAL_SM_02,
        },
    }),
    phone: {
        pattern: {
            value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
            message: translateCodes.PHONE_NUMBER_IS_INVALID,
        },
    },
    email: {
        required: translateCodes.EMAIL_REQUIRED,
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
            message: translateCodes.EMAIL_INVALID,
        },
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_EMAIL,
        },
    },
    role: {
        required: translateCodes.ROLE_REQUIRED,
    },
    branches: {
        required: translateCodes.LOCATION_REQUIRED,
    },
};