import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';
import { GetDetailSaleResponse } from './getDetailSale';

interface SaleGetAllRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}

export const getAllSale = httpUtil.createHttpRequestMeta<
    SaleGetAllRequest,
	PaginationResponse<GetDetailSaleResponse>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/sales',
    authentication: 'bearer',
});