import { Translate, useHttpCommand } from '@esg/ui';
import { useCallback } from 'react';
import { confirmAppointment } from '@esg/business-schedule';
import { changeAppointmentStatus } from '@esg/business-schedule/api/appointment/noShowAppointment';
import { AppointmentStatus as Status } from '@esg/shared';
import { viewRequests } from '@esg/framework';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { appointmentEvents } from '@/event';
import { translateCodes } from '@/locales';

interface Params {
	readonly id: string;
	readonly status: Status;
	readonly employee?: EmployeeGetDetailResponse;
}

const handleErrorConfirm = (employeeName: string) => {
    viewRequests.showNotification.send({
        type: 'warning',
        placement: 'bottomRight',
        message: (
            <Translate
                type='html'
                option={{ employeeName }}
                translateCode={translateCodes.APPOINTMENT_WARNING_CONFIRM}
            />
        ),
    });
};

export const useApptStatusFastUpdate = () => {
    const { mutateAsync: confirmAppt, isPending: isConfirming } = useHttpCommand(
        confirmAppointment,
        {
            showNotificationError: false,
        }
    );

    const { mutateAsync: updateApptStatus, isPending: isUpdating } = useHttpCommand(
        changeAppointmentStatus,
        {
            onSuccess() {
                appointmentEvents.refetchAppointments.emit();
            },
        }
    );

    const handleUpdate = useCallback(
        async ({ id, status, employee }: Params) => {
            if (status === Status.Booked && employee) {
                return void (await confirmAppt(
                    {
                        pathData: {
                            id,
                        },
                        body: {
                            id,
                        },
                    },
                    {
                        onSuccess() {
                            appointmentEvents.refetchAppointments.emit();
                        },
                        onError() {
                            handleErrorConfirm(employee.fullName);
                        },
                    }
                ));
            }

            updateApptStatus({
                pathData: {
                    id,
                },
                body: {
                    id,
                    status: status === Status.Booked 
                        ? Status.Confirmed 
                        : Status.CheckedIn,
                },
            });
        },
        [confirmAppt, updateApptStatus]
    );

    return { handleUpdate, loading: isConfirming || isUpdating };
};
