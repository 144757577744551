import {  GetAllCustomerResponseItem, customerApi } from '@esg/business-crm';
import { resourceApis } from '@esg/business-resource-management';
import { FlexBox, SelectField, InputField,Text, useHttpQuery, Block, globalModalState } from '@esg/ui';
import { Card, Radio } from 'antd';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { EmployeeSelectField } from '../../employee/children/employee-select/EmployeeSelectField';
import { CustomerInfo } from '../../Appointment/children/appointment-info/appointment-customer-info/CustomerInfo';
import CreateSourceForm from './customer-source/CreateSourceForm';
import CreateGroupForm from './customer-group/CreateGroupForm';
import { translateCodes } from '@/locales';
import { ReferredTypeEnum, referredTypeOptions } from '@/constants';
import { customerRules } from '@/pages/crm/customer/@share/customerRules';

interface AdditionalInfoProps {
    customer?: Partial<GetAllCustomerResponseItem>;
}

export const AdditionalInfo = (props: AdditionalInfoProps) => {
    const { watch, register, setValue, formState } = useFormContext();
    const { t } = useTranslation();
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const { data: customers } = useHttpQuery(customerApi.getAllCustomers);
    const { data: customerGroups, isFetching: groupLoading, refetch: refetchGroup } = useHttpQuery(customerApi.getAllCustomersGroup, { query: { active: true } });
    const { data: customerSources, isFetching: sourceLoading, refetch: refetchSource } = useHttpQuery(customerApi.getAllCustomersSource, { query: { active: true } });
    const { data: employees } = useHttpQuery(resourceApis.getAllEmployees);

    const customerGroupOptions = useMemo(() =>customerGroups?.items?.map((item) => ({
        label: item.name,
        value: item.id,
    })) ?? [], [customerGroups]);

    const customerSourceOptions = useMemo(() => customerSources?.items?.map((item) => ({
        label: item.name,
        value: item.id,
    })) ?? [], [customerSources]);

    const createCustomerGroup = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.NEW_CLIENT_GROUP,
            content: <CreateGroupForm
                activeDefault={true}
                onCancel={() => resetGlobalModal()}
                onSuccess={(res) => {
                    refetchGroup();
                    setValue('customerGroupId', res?.id);              
                    resetGlobalModal();
                }}
            />
        });
    };

    const createCustomerSource = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.NEW_CLIENT_SOURCE,
            content: <CreateSourceForm
                activeDefault={true}
                onCancel={() => resetGlobalModal()}
                onSuccess={(res) => {
                    refetchSource();
                    setValue('customerSourceId', res?.id);     
                    resetGlobalModal();
                }}
            />
        });
    };

    const referredByType = watch('referredByType') ?? ReferredTypeEnum.Employee;
    register('referredByType');

    return (
        <Block>
            <Card bordered={false}>
                <FlexBox direction='column' gap={16}>
                    <FlexBox gap={16} >
                        <SelectField
                            loading={groupLoading}
                            filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                            allowClear
                            name='customerGroupId'
                            label={t(translateCodes.CLIENT_GROUP)}
                            placeholder={t(translateCodes.CLIENT_GROUP_PLACEHOLDER)}
                            options={customerGroupOptions}
                            onCreate={createCustomerGroup}
                            style={{ width: 260 }}
                        />
                        <SelectField
                            style={{ width: 260 }}
                            loading={sourceLoading}
                            filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
                            allowClear
                            options={customerSourceOptions}
                            name='customerSourceId'
                            label={t(translateCodes.CLIENT_SOURCE)}
                            placeholder={t(translateCodes.CLIENT_SOURCE_PLACEHOLDER)}
                            onCreate={createCustomerSource}
                        />
                    </FlexBox>
                    <FlexBox direction='column'>
                        <Text fontWeight={600}translateCode={translateCodes.REFERRED_BY} color='#344054'lineHeight='30px'/>  
                        <FlexBox gap={16} alignItems='center'>
                            <Radio.Group
                                name='referredByType'
                                onChange={(e) => {
                                    setValue('referredByType', e.target.value);
                                    setValue('employeeReferralId', null);
                                    setValue('customerReferralId', null);
                                }}
                                defaultValue={formState.defaultValues?.referredByType ?? ReferredTypeEnum.Employee}
                                options={referredTypeOptions}
                                buttonStyle='solid'
                                optionType='button'
                                style={{ display: 'flex' }}
                            />
                            {referredByType == ReferredTypeEnum.Employee ?
                                <EmployeeSelectField
                                    allowClear
                                    handleChangedEmployee={(value) => setValue('employeeReferralId', value)} 
                                    value={watch('employeeReferralId')}
                                    name='employeeReferralId'
                                    employeeOptions={employees?.items.map(o=> ({
                                        ...o,
                                        label: o.fullName,
                                        value: o.id,
                                    }))}
                                    label={undefined}
                                    placeholder={translateCodes.FIND_EMPLOYEE}
                                />
                                :
                                <SelectField
                                    filterOption={(input, option) => (option?.label?.toString()?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
                                    options={customers?.items?.filter((item) => item.id !== props.customer?.id).map((o) => ({
                                        ...o,
                                        label: o.fullName,
                                        value: o.id,
                                    })) || []}
                                    optionFilterProp='label'
                                    showSearch
                                    allowClear
                                    placeholder={translateCodes.FIND_CLIENT}
                                    listHeight={513}
                                    optionRender={({ data }) => {
                                        const customer = data as GetAllCustomerResponseItem;
                                        return <CustomerInfo {...{ customer, type: 'select' }} />;
                                    }}
                                    name='customerReferralId'
                                    style={{ width: 390 }}
                                    onChange={(value) => setValue('customerReferralId', value)} 

                                />                               
                            }
                        </FlexBox>
                    </FlexBox>
                    <InputField
                        type='textarea'
                        name='note'
                        rules={customerRules.note(t)}
                        label={t(translateCodes.NOTE)}
                        placeholder={t(translateCodes.NOTE_PLACEHOLDER)}
                    />
                </FlexBox>
            </Card>
        </Block>
    );
};