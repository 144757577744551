import { TimeBlockDetailResponse, TimeBlockRepeatBy } from '@esg/business-resource-management';
import { AppButton, FlexBox, globalModalState, Spacer, Text, Translate } from '@esg/ui';
import { Radio, Space } from 'antd';
import React, { useState, useMemo } from 'react';
import { useResetRecoilState } from 'recoil';
import { TimeBlockCircle } from './Types';
import { useTimeBlockDelete } from './@share/hooks';
import { translateCodes } from '@/locales';

interface TimeBlockConfirmProps {
	readonly values: TimeBlockDetailResponse;
	readonly currentDate: string;
}

export const TimeBlockDeleteConfirm = ({ values, currentDate }: TimeBlockConfirmProps) => {
    const hideModal = useResetRecoilState(globalModalState);

    const isNone = useMemo(() => values.repeatBy === TimeBlockRepeatBy.NONE, [values.repeatBy]);

    const [type, setType] = useState<TimeBlockCircle | undefined>(isNone ?  undefined : 'now');

    const handleDelete = useTimeBlockDelete();

    return (
        <>
            {isNone ? (
                <Text>
                    <Translate translateCode={translateCodes.DELETE_BREAK_TIME_CONFIRM} />
                </Text>
            ) : (
                <Radio.Group
                    value={type}
                    onChange={(value) => {
                        setType(value.target.value);
                    }}
                >
                    <Space direction='vertical'>
                        <Radio value='now'>
                            <Translate translateCode={translateCodes.DELETE_TIME_BLOCK_ONLY} />
                        </Radio>
                        <Radio value='future'>
                            <Translate translateCode={translateCodes.DELETE_TIME_BLOCK_FUTURE} />
                        </Radio>
                        <Radio value='all'>
                            <Translate translateCode={translateCodes.DELETE_TIME_BLOCK_ALL} />
                        </Radio>
                    </Space>
                </Radio.Group>
            )}
            <Spacer top_bottom={20} />
            <FlexBox justifyContent='space-between' gap={20}>
                <AppButton translateCode={translateCodes.CANCEL} width='100%' onClick={hideModal} />
                <AppButton
                    danger
                    width='100%'
                    type='primary'
                    translateCode={translateCodes.DELETE}
                    onClick={() => {
                        handleDelete({ values, delType: type, currentDate });
                    }}
                />
            </FlexBox>
        </>
    );
};
