import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BankAccount, Tax } from './branch-settings';

import { PaymentMethod, GeneralInformation } from './salon-information';
import BookingOnlineSetting from './booking-online/BookingOnlineSetting';
import { Integrations } from './integrations/Integrations';
import { BranchSetting } from './branch-settings/branch-information/BranchSetting';
import UserRole from './account-settings/user-role/UserRole';
import { SubMenuLayout } from '@/layouts';
import { SETTING_GENERAL_BRANCH, settingMenu } from '@/configs';

export function Setting() {
    return (
        <Routes>
            <Route
                element={<SubMenuLayout subMenu={settingMenu} />}
            >
                <Route path='/' element={<Navigate to={SETTING_GENERAL_BRANCH} />} />
                <Route path='/general-information' Component={GeneralInformation} />
                <Route path='/bank-account' Component={BankAccount} />
                <Route path='/tax' Component={Tax} />
                <Route path='/payment-method' Component={PaymentMethod} />
                <Route path='/branch' Component={BranchSetting} />
                <Route path='/booking-online' Component={BookingOnlineSetting} />
                <Route path='/integrations' Component={Integrations} />
                <Route path='/user-role' Component={UserRole} />
            </Route>
        </Routes>
    );
}
