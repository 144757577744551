import { SchedulerItem } from '@esg/ui';
import { convertSameDate, Dayjs } from '@esg/shared';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { SchedulerItemInfo } from '@/pages/scheduler/Types';

interface EmployeeTimeFrame {
	id?: string;
	endTime: string;
	startTime: string;
	employeeId: string;
}

export const getSchedulerDisableTimes = <T>({
    list,
    date,
    type,
}: {
	list: (T & EmployeeTimeFrame)[];
	date: Dayjs;
	type: SchedulerItemInfo['type'];
}) => {
    const result: SchedulerItem[] = [];
    list.forEach((o) => {
        const startTime = convertSameDate(date, o.startTime);
        let endTime = convertSameDate(date, o.endTime);

        if (endTime < startTime) {
            endTime = endTime.add(1, 'd');
        }

        result.push({
            key: o.id ?? Math.random().toString(),
            rowKey: o.employeeId,
            canDrag: false,
            startTime,
            endTime,
            data: {
                type,
                values: {
                    ...o,
                    startTime,
                    endTime
                },
            },
        });
    });
    return result;
};

export const getSchedulerEmployeeRowInfos = (employees: EmployeeGetDetailResponse[]) => {
    const init = [
        {
            key: '0',
            allowOverlap: true,
            data: {
                name: 'Unassigned',
                isUnassigned: true,
            },
        },
    ];
    const employeeRows = employees.map((employee) => {
        return {
            key: employee.id,
            data: {
                name: employee.fullName,
                employee: employee,
            },
        };
    });

    return [...init, ...employeeRows];
};
