import { FormBase, FormLayoutModal, TextAreaField, useHttpCommand, } from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { viewRequests } from '@esg/framework';
import { createCustomerNote, GetCustomerNoteDetailResponse, updateCustomerNote } from '@esg/business-crm/api/customer-note';
import { translateCodes } from '@/locales';

interface CustomerAddNoteFormProps {
    readonly onCancel?: () => void;
    readonly onSuccess?: () => void;
    readonly note?: GetCustomerNoteDetailResponse;
    readonly customerId: string;
}

export default function CustomerAddNoteForm(props: CustomerAddNoteFormProps) {
    const { t } = useTranslation();

    const { mutateAsync: onCreateNote } = useHttpCommand(createCustomerNote, {
        onSuccess: () => {
            props.onSuccess?.();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.ADD_NOTE_SUCCESS)
            });
        }
    });

    const { mutateAsync: onUpdateNote } = useHttpCommand(updateCustomerNote, {
        onSuccess: () => {
            props.onSuccess?.();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.UPDATE_NOTE_SUCCESS)
            });
        }
    });

    const submit = async (values: GetCustomerNoteDetailResponse) => {
        if (values.id) {
            await onUpdateNote({
                pathData: { id: props.note?.id },
                body: {
                    id: values.id,
                    value: values.value,
                    customerId: props.customerId
                }
            });
        } else {
            await onCreateNote({
                body: {
                    value: values.value,
                    customerId: props.customerId
                }
            });
    
        }
        
    };

    return (
        <FormBase onSubmit={submit} defaultValues={props.note}>
            {() => (
                <FormLayoutModal
                    useSubmitButton={true}
                    onCancel={props.onCancel}
                    cancelTextCode={translateCodes.CANCEL}
                    okTextCode={translateCodes.SAVE}
                >
                    <TextAreaField
                        name='value'
                        placeholder={t(translateCodes.ADD_NOTE_PLACEHOLDER)}
                        label=''
                    />
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
