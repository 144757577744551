import React from 'react';

export const IconTotalWalkInClient = () => {
    return (
        <svg width='49' height='48' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0.600098 24C0.600098 10.7452 11.3453 0 24.6001 0C37.8549 0 48.6001 10.7452 48.6001 24C48.6001 37.2548 37.8549 48 24.6001 48C11.3453 48 0.600098 37.2548 0.600098 24Z' fill='#EFF4FF'/>
            <path opacity='0.4' d='M17.1798 18.9443C16.827 18.6307 16.4963 18.9002 16.0553 19.3853C15.8949 19.5617 15.4291 20.0688 15.041 20.5098C14.5559 21.061 15.0183 21.7707 15.3711 22.1456C15.7238 22.5204 23.0963 29.8549 24.9626 31.715C26.4185 33.1662 27.543 33.2764 28.0943 33.2764H32.5703C34.4893 33.2764 34.5541 31.6048 34.5982 30.7889C34.6423 29.9731 33.8926 29.7526 33.4075 29.7526H29.2181C28.1163 29.7526 27.6533 29.528 27.1241 29.0429C26.7139 28.6669 17.5767 19.2971 17.1798 18.9443Z' fill='#2970FF'/>
            <path d='M20.0675 15.443C19.8212 15.6918 18.1639 17.3613 17.3774 18.1771L22.2945 23.05C22.39 21.5139 22.6039 18.3274 22.6921 17.3572C22.7983 16.1886 22.6039 15.7255 21.8976 15.2225C21.25 14.7613 20.3989 15.1081 20.0675 15.443Z' fill='#2970FF'/>
            <path d='M24.6979 18.8606C24.4862 19.2487 23.6689 19.2575 23.2867 19.2134L22.978 23.7336C23.9849 24.7405 26.2322 26.9545 27.0571 27.8568C28.1824 29.0875 28.6888 28.9813 29.218 28.9813H32.8782C31.7544 27.8568 30.2102 25.0565 29.6149 23.7336C29.3135 24.0717 28.6227 24.814 28.2699 25.0786C27.895 25.255 27.6084 24.7478 27.8068 24.5494C27.9656 24.3906 28.8873 23.4102 29.3282 22.9398L28.5345 20.7789C27.9097 21.4184 26.5985 22.7458 26.3516 22.9398C26.0429 23.1823 25.6901 22.6091 25.9988 22.3004C26.3075 21.9917 27.2335 20.9994 28.2699 19.9852C27.7407 18.0669 27.2776 17.7141 26.3516 17.67C25.4255 17.6259 24.9625 18.3756 24.6979 18.8606Z' fill='#2970FF'/>
            <rect x='14.6001' y='29.749' width='6.52664' height='0.970176' rx='0.485088' fill='#2970FF'/>
            <rect x='14.6001' y='32.3066' width='10.0986' height='0.970176' rx='0.485088' fill='#2970FF'/>
        </svg>
    );
};

