import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { Block, FlexBox, Spacer, Text } from '@esg/ui';
import React from 'react';
import { dayjs } from '@esg/shared';

import { translateCodes } from '@/locales';

interface EmployeeWorkingTimeTableProps {
    employee?: EmployeeGetDetailResponse;
}

export function EmployeeWorkingTimeTable(props: EmployeeWorkingTimeTableProps) {
    const { employee } = props;

    return (
        <Block border='1px solid #EAECF0' borderRadius={16} overflow='hidden'>
            <FlexBox width='100%' height={44} backgroundColor='#E7F1FE' alignItems='center' justifyContent='space-between'>
                <Spacer width='28%' type='padding' left={16}>
                    <Text fontSize={14} fontWeight={600} translateCode={translateCodes.DATE} />
                </Spacer>
                <Block width='28%'>
                    <Text fontSize={14} fontWeight={600} translateCode={translateCodes.WORKING_TIME} />
                </Block>
                <Block width='28%'>
                    <Text fontSize={14} fontWeight={600} translateCode={translateCodes.DURATION} />
                </Block>
            </FlexBox>
            {employee?.shifts.map(shift => {
                const startHour = dayjs(shift.startTime).hour() ;
                const endHour = dayjs(shift.endTime).hour();
                const duration = Math.round(endHour - startHour);
                
                return (
                    <div
                        key={shift.id}
                        style={{
                            backgroundColor: 'white',
                            borderBottom: '1px solid #EAECF0',
                            padding: '16px 0'
                        }}
                    >
                        <FlexBox direction='column' gap={12}>
                            <FlexBox width='100%' alignItems='center' justifyContent='space-between'>
                                <Spacer width='28%' type='padding' left={16}>
                                    <Text fontSize={14} fontWeight={600} translateCode={shift.dayOfWeekTitle} />
                                </Spacer>
                                <Block width='28%'>
                                    <Text fontSize={14} fontWeight={600}>
                                        {dayjs(shift?.startTime).format('h:mm A')} - {dayjs(shift?.endTime).format('h:mm A')}
                                    </Text>
                                </Block>
                                <Block width='28%'>
                                    <Text fontSize={14} fontWeight={600} >
                                        {duration}h
                                    </Text>
                                </Block>
                            </FlexBox>
                            {shift.breakTimes.map(breakTime => {
                                const breakTimeTotalHours = dayjs(breakTime.endTime).diff(dayjs(breakTime.startTime), 'hours');
                                return (
                                    <FlexBox key={breakTime.id} width='100%' alignItems='center' justifyContent='space-between'>
                                        <Spacer width='28%' type='padding' left={16}>
                                            <Text fontSize={14} translateCode={translateCodes.BREAK} />
                                        </Spacer>
                                        <Block width='28%'>
                                            <Text fontSize={14}>
                                                {dayjs(breakTime?.startTime).format('h:mm A')} - {dayjs(breakTime?.endTime).format('h:mm A')}
                                            </Text>
                                        </Block>
                                        <Block width='28%'>
                                            <Text fontSize={14} >
                                                {breakTimeTotalHours}h
                                            </Text>
                                        </Block>
                                    </FlexBox>
                                );
                            })}
                        </FlexBox>
                    </div>
                );
            })}
        </Block>
    );
}
