import { CheckboxField, DatePickerField, FlexBox, Translate } from '@esg/ui';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dayjs, dayjs } from '@esg/shared';
import { TimeOffFormValues } from '../Types';
import { translateCodes } from '@/locales';

export const TimeOffRepeat = () => {
    const { watch, setValue, getValues } = useFormContext<TimeOffFormValues & { repeat: string }>();
    const repeat = watch('repeat');

    const disabledDate = useCallback(
        (cur: Dayjs) => {
            const date = getValues('date');
            return cur && cur <= dayjs(date);
        },
        [getValues],
    );

    return (
        <FlexBox gap={20}>
            <CheckboxField
                name='repeat'
                className='repeat'
                onChange={() => {
                    setValue('repeatToDate', undefined);
                }}
            >
                <Translate translateCode={translateCodes.REPEAT} />
            </CheckboxField>
            {repeat ? (
                <DatePickerField
                    name='repeatToDate'
                    disabledDate={disabledDate}      
                    label={translateCodes.REPEAT_EACH_DAY_TILL}
                    rules={{
                        required: 'The field is not null',
                    }}
                />
            ) : null}
        </FlexBox>
    );
};
