import { translateCodes } from '@/locales';

export const integrationRules = {
    smtpServer: {
        required: translateCodes.SMTP_SERVER_IS_REQUIRED,
        // pattern: {
        //     value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        //     message: translateCodes.EMAIL_INVALID,
        // },
    },
    port: {
        required: translateCodes.PORT_IS_REQUIRED,
    },
    userName: {
        required: translateCodes.USER_NAME_IS_REQUIRED,
    },
    password: {
        required: translateCodes.PASSWORD_IS_REQUIRED,
    },
    sendingEmail: {
        required: translateCodes.SENDING_EMAIL_IS_REQUIRED,
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: translateCodes.EMAIL_INVALID,
        },
    },
};