import { TableCustom, globalDrawerState, useHttpQuery, Text, useLocalesFormat } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs } from '@esg/shared';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { GetServicesByCategoryRevenueItem, getServicesByCategoryRevenueReport } from '@esg/business-report/api/revenue-report';
import { useTranslation } from 'react-i18next';
import { RevenueServiceDetailTable } from './RevenueServiceDetailTable';
import { translateCodes } from '@/locales';
import { usePaginationReport } from '@/pages/sale-analyzer/report-hooks';

interface RevenueServicesByCategoryTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function RevenueServicesByCategoryTable(props: RevenueServicesByCategoryTableProps) {
    const { t } = useTranslation();
    const { dateRange } = props;
    const { formatCurrency } = useLocalesFormat();
    const [, setDrawerGlobalState] = useRecoilState(globalDrawerState);

    const [searchParams, setSearchParams] = usePaginationReport({
        pageSizeCategory: '10',
        orderByCategory: 'name',
    },);
    
    const { data: servicesByCategory, isFetching } = useHttpQuery(getServicesByCategoryRevenueReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            search: searchParams.searchByCategory,
            orderBy: searchParams.orderByCategory,
            orderDirection: searchParams.categoryDirection,
            page: searchParams.pageCategory,
            pageSize: searchParams.pageSizeCategory,
            ...searchParams
        },
    });

    const viewDetail = useCallback((serviceCategoryId: string) => {
        setDrawerGlobalState({
            titleTransCode: t(translateCodes.VIEW_DETAIL),
            isOpen: true,
            hideHeader: true,
            content: (
                <RevenueServiceDetailTable
                    dateRange={dateRange}
                    serviceCategoryId={serviceCategoryId}
                />
            ),
            footer: null,
        });
    },[dateRange, setDrawerGlobalState, t]);

    const columns: TableColumnsType<GetServicesByCategoryRevenueItem> = useMemo(()=>([
        {
            key: 'name',
            title: t(translateCodes.CATEGORIES),
            dataIndex: 'name',
            sorter: true,
            onHeaderCell: () => ({
                onClick: () => {
                    const order = searchParams.categoryDirection === 'desc' ? 'asc' : 'desc';
                    setSearchParams({ ...searchParams, categoryDirection: order });
                }
            }),
        },
        {
            title: t(translateCodes.QUANTITY),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t(translateCodes.REVENUE),
            dataIndex: 'revenue',
            key: 'revenue',
            render: (_, record) => {
                return (
                    <Text>
                        {formatCurrency(record.revenue)}
                    </Text>
                );
            },
        },
        {
            title: t(translateCodes.LINKS),
            key: 'links',
            dataIndex: 'links',
            width: 150,
            render: (_, record) => {
                return (
                    <Tag className='cursor-pointer' onClick={() => viewDetail(record.id)}>View Detail</Tag>
                );
            }
        },
    ]),[formatCurrency, searchParams, setSearchParams, t, viewDetail]);

    return (
        <TableCustom
            titleTableTransCode={translateCodes.SERVICES_BY_CATEGORIES}
            loading={isFetching}
            columns={columns}
            dataSource={servicesByCategory?.items}
            pageInfo={servicesByCategory?.meta}
            placeholderSearchTransCode={`${t(translateCodes.SEARCH)} ${t(translateCodes.SERVICE_CATEGORY)}`}
            onSearch={(value) =>
                setSearchParams({ ...searchParams, searchByCategory: value })
            }
            onPageChange={(page) =>
                setSearchParams({ ...searchParams, pageCategory: page.toString() })
            }
        />
    );
}
