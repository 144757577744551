import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BreakTimeGetDetailResponse } from '@esg/business-resource-management';
import { BreakTimeFormControl } from './BreakTimeFormControl';
import { BreakTimeProvider } from './BreakTimeContext';

interface BreakTimeFormProps {
	readonly defaultValues: BreakTimeGetDetailResponse;
}

export const BreakTimeForm = ({ defaultValues }: BreakTimeFormProps) => {
    const methods = useForm<BreakTimeGetDetailResponse>({
        defaultValues: defaultValues,
    });

    return (
        <FormProvider {...methods}>
            <BreakTimeProvider >
                <BreakTimeFormControl />
            </BreakTimeProvider>
        </FormProvider>
    );
};
