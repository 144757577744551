import { DownOutlined } from '@ant-design/icons';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { GetAllCustomerActivityResponseItem } from '@esg/business-crm/api/customer-activity';
import { managementApis } from '@esg/business-management';
import { AppointmentGetDetailResponse, schedulerApis } from '@esg/business-schedule';
import { AppointmentStatus, getAppointmentStatusAction, getAppointmentStatusLabel, getAppointmentTagColor } from '@esg/shared';
import { ActivityType } from '@esg/shared/enum/CustomerActivityEnum';
import { FlexBox, globalModalState, IconEditPen, IconTrash, Text, useHttpCommand, useHttpQuery, useLocalesFormat } from '@esg/ui';
import { Card, Divider, Tag, theme, Tree } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { getDetailSale } from '@esg/business-pos';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { AppointmentUpcomingEmpty } from './AppointmentUpcomingEmpty';
import { getAppointmentSummary } from '@/components/form/Appointment/@share/appointmentUtils';
import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';
import { translateCodes } from '@/locales/translateCodes';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
import { AppointmentDeleteConfirm } from '@/components/appointment/AppointmentDeleteConfirm';

interface CustomerActivityProps {
    customerActivities: GetAllCustomerActivityResponseItem[];
    customer?: GetCustomerDetailResponse;
    appointmentUpcoming: AppointmentGetDetailResponse[];
    refetchAppointmentUpcoming: () => void;
    isLoadingAppointmentUpcoming: boolean;
}

const { useToken } = theme;

export function CustomerActivity({
    customerActivities,
    customer,
    appointmentUpcoming,
    refetchAppointmentUpcoming,
    isLoadingAppointmentUpcoming
}: CustomerActivityProps) {
    const { formatDate } = useLocalesFormat();
    const { token } = useToken();
    const { t } = useTranslation();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const resetModal = useResetRecoilState(globalModalState);
    const { onShowFormSale } = useOpenSaleFrom();
    const showAppointmentForm = useAppointmentForm();
    const { mutateAsync: getCurrentSale } = useHttpCommand(getDetailSale);
    const { mutateAsync: DeleteAppointment } = useHttpCommand(schedulerApis.deleteAppointment,{
        onSuccess() {
            refetchAppointmentUpcoming();
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.NM_SC_03),
            });
        },
    });

    const {
        data: serviceResponse,
        isFetching: isServiceFetching,
    } = useHttpQuery(managementApis.getAllServices);

    const { mutateAsync: updateAppointmentStatus } = useHttpCommand(schedulerApis.changeAppointmentStatus, {
        onSuccess() {
            refetchAppointmentUpcoming();
        },
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await DeleteAppointment(
                {
                    pathData: {
                        id: id,
                    },
                },
            );
        },
        [DeleteAppointment]
    );

    const showDeleteConfirm = (appointment: AppointmentGetDetailResponse) => {
        setModalGlobalState({
            content: <AppointmentDeleteConfirm item={appointment} />,
            isOpen: true,
            onOk: () => {
                if (appointment.id) {
                    onDelete(appointment.id);
                }
                resetModal();
            },
            showModalDelete: true,
        });
    };

    const changeAppointmentStatus = useCallback(
        async (appointment: AppointmentGetDetailResponse) => {
            if (appointment.status === AppointmentStatus.Completed
                || appointment.status === AppointmentStatus.CheckedIn) {
                const sale = await getCurrentSale({
                    pathData: {
                        id: appointment.saleId!,
                    }
                });

                return void onShowFormSale(sale, { paidFromScheduler: true });
            }

            await updateAppointmentStatus({
                pathData: {
                    id: appointment.id
                },
                body: {
                    id: appointment.id,
                    status: getAppointmentStatusAction(appointment.status).nextValue
                },
            });
        },
        [getCurrentSale, onShowFormSale, updateAppointmentStatus]
    );

    const isLoading = isLoadingAppointmentUpcoming || isServiceFetching;

    const getActivityContent = (type: ActivityType) => {
        switch (type) {
        case ActivityType.Cancelled:
            return 'canceled appointment';
        case ActivityType.Booked:
            return 'booked an appointment';
        case ActivityType.CheckOut:
            return 'CheckOut an appointment';
        case ActivityType.Confirmed:
            return 'Confirmed an appointment';
        case ActivityType.CheckedIn:
            return 'CheckedIn an appointment';
        case ActivityType.Completed:
            return 'Completed an appointment';
        case ActivityType.NoShow:
            return 'NoShow an appointment';
        case ActivityType.Paid:
            return 'paid invoice';
        case ActivityType.UnPaid:
            return 'unPaid invoice';
        case ActivityType.Create:
            return 'was created by';
        default:
            return 'was created by';
        }
    };

    const groupedActivities = customerActivities?.reduce<Record<string, typeof customerActivities>>((acc, activity) => {
        if (activity.branchId) {
            const date = formatDate(activity.creationTime);
            if (!acc[date]) acc[date] = [];
            acc[date].push(activity);
        }
        return acc;
    }, {});

    const treeData = Object.entries(groupedActivities).map(([date, activities], dateIndex) => ({
        title: (
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                {formatDate(date, `ddd, ${currentLocale?.format}`)}
            </span>
        ),
        key: `day-${dateIndex}`,
        children: activities.map((activity, activityIndex) => ({
            title: (
                <div>
                    <Text fontSize={16} fontWeight={600}>{activity.activityDetails.customerName} </Text>
                    <Text color='#344054' fontSize={16} fontWeight={400}>{getActivityContent(activity.activityType)}</Text>
                    <Text color={token.colorPrimary} fontSize={16} fontWeight={600}>{` ${activity.activityDetails.code}`}</Text>
                    <Text color='#344054' fontSize={16} fontWeight={400}>{` at ${dayjs(activity.creationTime).format('HH:mm A')}`}</Text>
                </div>
            ),
            key: `day-${dateIndex}-activity-${activityIndex}`,
        })),
    }));

    const appointmentUpcomingItem =
        appointmentUpcoming?.map(o => {
            const { price } = getAppointmentSummary(o.appointmentServices, serviceResponse?.items ?? []);
            const { minutes } = getAppointmentSummary(o.appointmentServices, serviceResponse?.items ?? []);
            const servicesName = o.appointmentServices.map((s) =>
                s.appointmentServiceDetails.map((o) => o.service?.name)
            )[0] ?? [];
            return (
                <>
                    <Card>
                        <FlexBox direction='column' gap={4} >
                            <FlexBox direction='row' justifyContent='space-between'>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                    <Text fontSize={14} fontWeight='bold' color={token.colorPrimary}>{o.appointmentCode}</Text>
                                    <Text color='#344054' fontSize={14} fontWeight={400}>{formatDate(o.appointmentDate, 'h:mm a ddd, ' + currentLocale?.format)} </Text>
                                    <Tag color={getAppointmentTagColor(o.status)}>
                                        {getAppointmentStatusLabel(o.status)}
                                    </Tag>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                                    <a onClick={() => changeAppointmentStatus(o)} style={{ fontSize: 14, fontWeight: 'bold' }} color={token.colorPrimary}>{getAppointmentStatusAction(o.status)?.label}</a>
                                    <Divider type='vertical' style={{ height: '100%' }} />
                                    <a><IconEditPen onClick={() => showAppointmentForm({ initialValues: o, refetch: refetchAppointmentUpcoming })} /></a>
                                    <a><IconTrash onClick={() => showDeleteConfirm(o)} /></a>
                                </div>
                            </FlexBox>
                            <FlexBox direction='column'>
                                <Text fontSize={18} fontWeight={600}>Cupping therapy</Text>
                                <FlexBox direction='row'>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                        <Text color='#344054' fontSize={14} fontWeight={400}>{servicesName}</Text>
                                        <Text color='#344054' fontSize={14} fontWeight={400}>{minutes} mins </Text>
                                        <Text color='#344054' fontSize={14} fontWeight={400}>${price}</Text>
                                    </div>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </Card>
                </>
            );
        });

    return (
        <FlexBox direction='column' gap={20}>
            <Card loading={isLoading}>
                <FlexBox direction='column' gap={20}>
                    <Text fontSize={18} fontWeight='bold'>Upcoming appointments ({appointmentUpcoming.length})</Text>
                    {
                        appointmentUpcoming.length > 0 ? appointmentUpcomingItem : <AppointmentUpcomingEmpty refetchAppointmentUpcoming={refetchAppointmentUpcoming} customer={customer} />
                    }
                </FlexBox>
            </Card>
            <Card>
                <FlexBox direction='column' gap={20}>
                    <Text fontSize={18} fontWeight='bold'>Activity history</Text>
                    <Tree
                        className='custom-tree'
                        showLine
                        defaultExpandAll={true}
                        switcherIcon={<DownOutlined />}
                        onSelect={() => { }}
                        treeData={treeData}
                    />
                </FlexBox>
            </Card>
        </FlexBox>
    );
}

