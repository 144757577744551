import { AppointmentGetDetailResponse, AppointmentService } from '@esg/business-schedule';
import { getAppointmentBackgroundColor, getAppointmentBorderColor, dayjs } from '@esg/shared';
import { Text } from '@esg/ui';
import './AppointmentCardInfo.scss';

import React from 'react';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';

export const AppointmentCardInfo = ({
    appointment,
}: {
	appointment: AppointmentGetDetailResponse;
}) => {
    const showAppointmentForm = useAppointmentForm();
    const status = appointment?.status;
    const borderColor = getAppointmentBorderColor(status ?? 0);
    const customer = appointment.customer;
    const service = appointment.appointmentServices[0].appointmentServiceDetails[0].service;

    const appointmentDuration = getAppointmentDuration(appointment.appointmentServices);

    return (
        <div
            className='appointment-card'
            style={{
                backgroundColor: getAppointmentBackgroundColor(status ?? 0),
            }}
            onClick={() => {
                showAppointmentForm({
                    initialValues: appointment,
                });
            }}
        >
            <div
                className='appointment-card-info'
                style={{
                    borderLeft: '4px solid ' + ' ' + borderColor,
                }}
            >
                <Text color='#475467' fontSize={12}>
                    {dayjs(appointment.appointmentDate).format('hh:mm A') +
						' -> ' +
						dayjs(appointment.appointmentDate).add(appointmentDuration, 'minutes').format('hh:mm A')}
                </Text>
                <Text color='#101828' fontSize={14} fontWeight={700}>
                    {customer?.fullName}
                </Text>
                <Text color='#475467' fontSize={12}>
                    {service.name} ({service.duration} mins)
                </Text>
            </div>
        </div>
    );
};

const getAppointmentDuration = (appointmentServices: AppointmentService[]) => {
    const durations: number[] = [];

    appointmentServices.forEach((as) => {
        let sum = 0;
        as.appointmentServiceDetails.forEach((asd) => {
            sum += asd.service.duration;
        });
        durations.push(sum);
    });
    return Math.max(...durations);
};
