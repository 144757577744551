import { Block, FlexBox, Spacer } from '@esg/ui';
import { Layout } from 'antd';
import React from 'react';

import {
    AddAptButton,
    CreateBillButton,
    // NotificationButton,
    SelectSalonBranch,
    UserDropdown
} from './main-layout-header';

const headerStyle: React.CSSProperties = {
    paddingLeft: 0,
    position: 'sticky',
    top: 0,
    zIndex: 10,
    width: '100%',
    borderBottom: '1px solid #EAECF0'
};

export function MainLayoutHeader() {
    return (
        <Layout.Header style={headerStyle} >
            <FlexBox alignItems='center' justifyContent='space-between'>
                <Block flexGrow={1}>
                    <SelectSalonBranch />
                </Block>
                <Block>
                    <FlexBox  >
                        {/* <SearchHeader /> */}
                        <Spacer left={16}>
                            <CreateBillButton />
                        </Spacer>
                        <Spacer left={16}>
                            <AddAptButton />
                        </Spacer>
                        {/* <Spacer left={16}>
                            <NotificationButton />
                        </Spacer> */}
                    </FlexBox>
                </Block>
                <Block>
                    <UserDropdown />
                </Block>
            </FlexBox>
        </Layout.Header>
    );
}
