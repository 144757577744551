import { GetAllServiceResponseItem } from '@esg/business-management/api/service';
import { env } from '@esg/business-management/configs';
import { HttpRequestData, httpUtil } from '@esg/framework';
import { ShiftGetDetailResponse } from '../shift';

export interface EmployeeGetDetailResponse {
	readonly id: string;
	readonly dateOfBirth?: string;
	readonly phone: string;
	readonly avatar?: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly fullName: string;
	readonly email: string;
	readonly address?: string;
	readonly gender?: number;
	readonly employeeCode: string;
	readonly jobStatus?: number;
	readonly isAllowBooking: boolean;
	readonly jobStartDate?: string;
	readonly userId?: string;
	readonly branchId: string;
	readonly jobTypeId?: string;
	readonly jobType?: {
		readonly id: string;
		readonly name: string;
	};
	readonly employeeServices: {
		readonly id: string;
		readonly employeeId: string;
		readonly serviceId: string;
		readonly service: GetAllServiceResponseItem;
	}[];
	readonly shifts: ShiftGetDetailResponse[];
}

interface GetEmployeeDetailTypeRequest extends HttpRequestData {
}

export const getEmployeeDetail = httpUtil.createHttpRequestMeta<
	GetEmployeeDetailTypeRequest,
	EmployeeGetDetailResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/employees/:id',
    method: 'GET',
    authentication: 'bearer',
});
