import { GetAllCustomerResponseItem } from '@esg/business-crm';
import { GetClientType, getAppointmentsDetailClientsReport } from '@esg/business-report';
import { TableCustom, useHttpQuery, useLocalesFormat, usePagination } from '@esg/ui';
import { TableColumnsType, Tag } from 'antd';
import { Dayjs , dayjs } from '@esg/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerFullNameColumn } from '@/pages/crm/customer/list/children/CustomerFullNameColumn';
import { translateCodes } from '@/locales';

interface AppointmentDetailClientsTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly getClientType?: GetClientType;
}

export default function AppointmentDetailClientsTable(props: AppointmentDetailClientsTableProps) {
    const { formatDate } = useLocalesFormat();
    const { dateRange, getClientType } = props;
    const [searchParams, setSearchParams] = usePagination({
        pageSize: '10',
    });

    const {t} = useTranslation();

    const {data: appointmentDetailClients, isFetching} = useHttpQuery(getAppointmentsDetailClientsReport, {
        query: {
            startDate: dateRange[0].toISOString(),
            endDate: dateRange[1].toISOString(),
            getClientType: getClientType,
        }
    });

    const columns: TableColumnsType<GetAllCustomerResponseItem> = [
        {
            title: 'ID',
            dataIndex: 'customerCode',
            key: 'customerCode',
            sorter: (a, b) => a.customerCode.length - b.customerCode.length,
        },
        {
            title: t(translateCodes.NAME),
            dataIndex: 'fullName',
            key: 'fullName',
            render: (_, record) => <CustomerFullNameColumn record={record} />,
        },
        {
            title: t(translateCodes.PHONE),
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: t(translateCodes.GROUP),
            dataIndex: 'Group',
            render: (_, record) => {
                return record.customerGroup?.name ?? '';
            },
        },
        {
            title: t(translateCodes.SOURCE),
            dataIndex: 'Source',
            render: (_, record) => {
                return record.customerSource?.name ?? '';
            },
        },
        {
            title: t(translateCodes.CREATED_ON),
            dataIndex: 'Created',
            render: (_, record) => {
                return formatDate(dayjs(record?.profileCreationTime));
            },
        },
    ];

    return (
        <TableCustom
            onPageChange={(page) =>
                setSearchParams({
                    ...searchParams,
                    page: page.toString(),
                })
            }
            onSearch={(value) =>
                setSearchParams({ ...searchParams, search: value })
            }
            loading={isFetching}
            titleTableTransCode={translateCodes.CLIENT_LIST}
            placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
            columns={columns}
            dataSource={appointmentDetailClients?.items ?? []}
            pageInfo={appointmentDetailClients?.meta}
            subTitleTable={<Tag color='processing'>{`${appointmentDetailClients?.meta.totalItems} ${t(translateCodes.BILLS)}`}</Tag>}
        />
    );
}
