import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface DeleteCustomerRequest extends HttpRequestData {
}

export const deleteCustomer = httpUtil.createHttpRequestMeta<
    DeleteCustomerRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customers/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
