import { AppButton, FlexBox, Text } from '@esg/ui';
import React from 'react';
import { translateCodes } from '@/locales';

interface ConnectSocialItemProps {
    icon: React.ReactNode;
    labelCode: string;
    connected?: boolean;
    onClick?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
}

export const ConnectSocialItem = (props: ConnectSocialItemProps) => {

    return (
        <FlexBox justifyContent='center' alignItems='center' flexWrap='wrap'>
            <FlexBox alignItems='center' gap={20}>
                {props.icon}
                <FlexBox direction='column' gap={4}>
                    <Text translateCode={props.labelCode} fontWeight={700} />
                    <Text translateCode={translateCodes.CONNECT} fontSize={12} />
                </FlexBox>
            </FlexBox>
            <AppButton
                disabled={props.disabled}
                onClick={props.onClick}
                type='primary'
                loading={props.isLoading}
                ghost
                translateCode={props.connected ? translateCodes.DISCONNECT : translateCodes.CONNECT} />
        </FlexBox>
    );
};
