import { EmptyLayout, useLogout } from '@esg/ui';
import { Result } from 'antd';
import React from 'react';

import { ToLoginPageLink } from '@/components';

export const Unauthorized = () => {
    useLogout();

    return (
        <EmptyLayout>
            <Result
                status='403'
                title='Unauthorized'
                subTitle='Sorry, you are not authorized to access this page.'
                extra={<ToLoginPageLink />}
            />
        </EmptyLayout>
    );
};