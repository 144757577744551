import { managementApis } from '@esg/business-management';
import { FormBase, Spacer, Text, useHttpCommand, useHttpQuery } from '@esg/ui';
import { Card, Spin } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetCurrentBookingOnlineSettingResponse, updateServiceAllowBookingSetting } from '@esg/business-setting/api/booking-online';
import FormLayoutBookingSetting from './layout-tab/FormLayoutTabSetting';
import { translateCodes } from '@/locales';
import { TreeServiceField } from '@/components';

interface ServicesSettingFormValues {
    readonly serviceIds: string[];
}
interface ServicesSettingTabProps {
    readonly bookingOnlineSetting?: GetCurrentBookingOnlineSettingResponse;
}

export default function ServicesSettingTab(props: ServicesSettingTabProps) {
    const { bookingOnlineSetting } = props;
    const { t } = useTranslation();

    const { data: serviceCategories, refetch, isFetching } = useHttpQuery(managementApis.getAllServiceCategory);
    const { mutateAsync , isPending } = useHttpCommand(updateServiceAllowBookingSetting, {
        onSuccess: () => {
            refetch();
        }
    });

    const onSubmit = useCallback(async (value: ServicesSettingFormValues)=>{
        await mutateAsync({
            pathData: { id: bookingOnlineSetting?.id },
            body: {
                id: bookingOnlineSetting?.id,
                serviceIds: value.serviceIds
            }
        });

    },[bookingOnlineSetting?.id, mutateAsync]);

    const serviceAllowBookingOnlineIds = useMemo(()=> {
        const services = serviceCategories?.items
            .map(category => category.services).flat();

        return services?.filter(service => service.isAllowOnlineBooking)
            .map(service => service.id);
    },[serviceCategories?.items]);
    
    if(isPending) return <Spin fullscreen/>;

    return (
        <FormBase
            onSubmit={onSubmit}
            defaultValues={{
                serviceIds: serviceAllowBookingOnlineIds
            }}
        >
            <FormLayoutBookingSetting>
                <Card>
                    <Spacer>
                        <Text fontWeight={600}>
                            {t(translateCodes.CUSTOMIZE_SERVICE)}
                        </Text>
                    </Spacer>
                    <Text>{t(translateCodes.CUSTOMIZE_SERVICE_DESCRIPTION)}</Text>
                    <Spacer size='md' />
                    <TreeServiceField 
                        useSearch
                        serviceCategories={serviceCategories?.items}
                        label={t(translateCodes.SERVICES)}
                        isLoading={isFetching}
                    />
                </Card>
            </FormLayoutBookingSetting>
        </FormBase>
    );
}
