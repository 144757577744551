import {useEffect} from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useAppointmentContext } from '../AppointmentFormContext';
import { getEmployeeTimeWork } from '../appointmentUtils';
import { AppointmentFormValues } from '../../AppointmentFormControl';
interface Params {
	guestIndex: number;
	serviceIndex: number;
	parentFieldName: string;
	currentServiceId: string;
	fields: FieldArrayWithId[];
}

export const useServicesWorkTime = ({serviceIndex, fields, guestIndex, parentFieldName, currentServiceId} : Params) => {
    const {services} = useAppointmentContext();
    const { getValues, setValue } = useFormContext();

    useEffect(() => {
        const values = getValues();

        for (let i = serviceIndex; i < fields.length; i++) {
            const { startTime, endTime } = getEmployeeTimeWork({
                services,
                guestIndex,
                guestServiceIndex: i,
                appointment: values as AppointmentFormValues,
            });
            setValue(`${parentFieldName}.${i}.startTime`, startTime);
            setValue(`${parentFieldName}.${i}.endTime`, endTime);
        }
    }, [fields.length, guestIndex, parentFieldName, serviceIndex, currentServiceId, getValues, services, setValue]);
};