import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { env } from '../../configs';

interface getProductsByEmployeeRevenueRequest extends HttpRequestData {
	readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

interface EmployeeInfo {
    id: string;
    fullName: string;
    avatar: string;

}
export interface getProductsByEmployeeRevenueItem {
    id: string;
    employee?: EmployeeInfo;
    quantity: number;
    revenue: number;
}

export const getProductsByEmployeeRevenueReport = httpUtil.createHttpRequestMeta<
    getProductsByEmployeeRevenueRequest,
	PaginationResponse<getProductsByEmployeeRevenueItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/revenue-report/product-by-employee',
    authentication: 'bearer',
});