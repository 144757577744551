import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../config';
import { GetRoleDetailResponse } from './getRoleDetail';

interface GetAllRoleRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export const getAllRole = httpUtil.createHttpRequestMeta<
    GetAllRoleRequest,
    PaginationResponse<GetRoleDetailResponse>
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/roles',
    method: 'GET',
    authentication: 'bearer',
});
