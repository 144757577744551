import { IconType } from 'antd/es/notification/interface';
import warningIcon from '../images/warning_icon.png';
import successIcon from '../images/success_icon.png';
import infoIcon from '../images/info_icon.png';
import errorIcon from '../images/error_icon.png';

export const notifyIcon: Record<IconType, string> = {
    info: infoIcon,
    error: errorIcon,
    warning: warningIcon,
    success: successIcon  
};