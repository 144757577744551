import { managementApis } from '@esg/business-management';
import { FlexBox, globalDrawerState, globalModalState, useHttpCommand, useHttpQuery, usePagination, Text, ColumnActions, TableCustom, useLocalesFormat } from '@esg/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { TableColumnsType, Tag } from 'antd';
import { GetAllProductResponseItem } from '@esg/business-management/api/product';
import { ProductCapacityType } from '@esg/shared/enum';
import { CreateProductBtn } from './children/CreateProductBtn';
import { translateCodes } from '@/locales';
import { PageLayout } from '@/layouts';
import { ProductForm } from '@/components/form/product';

export function Products() {
    const { formatCurrency } = useLocalesFormat();

    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();

    const {
        data: products,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllProduct, { query: searchParams});

    const { mutateAsync } = useHttpCommand(managementApis.deleteProduct, {
        onSuccess() {
            resetModalState();
            resetDrawerState();
            refetch();
        }
    });

    const onDelete = React.useCallback(
        async (id: string) => {
            await mutateAsync({ pathData: {id: id} });
        },
        [mutateAsync]
    );

    const showModalDelete = React.useCallback((id: string) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_PRODUCT)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_PRODUCT_CONFIRM)}
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onEdit = React.useCallback(
        (values: GetAllProductResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: translateCodes.EDIT_PRODUCT,
                content: (
                    <ProductForm
                        onDelete={() => showModalDelete(values.id)}
                        onSuccess={() => {
                            refetch(),
                            resetDrawerState();
                        }}
                        defaultValues={{
                            ...values,
                            productCapacityValue: values.productCapacity?.value,
                            productCapacityType: values.productCapacity?.type,
                            originalPrice: values.productBranches[0].originalPrice,
                            sellingPrice: values.productBranches[0].sellingPrice,
                            taxSettingId: values.productBranches[0].taxSettingId,
                        }}
                    />
                ),
            });
        },
        [setDrawerState, showModalDelete, refetch, resetDrawerState]
    );

    const columns: TableColumnsType<GetAllProductResponseItem> = [
        {
            width: 200,
            title: t(translateCodes.NAME),
            dataIndex: 'name',
            key: 'name',
        },
        {
            width: 116,
            title: t(translateCodes.CATEGORY),
            dataIndex: 'productCategory',
            key: 'category',
            render: (_, record) => {
                return (
                    <Tag>
                        {record.productCategory?.name}
                    </Tag>
                );
            },
        },
        {
            width: 116,
            title: t(translateCodes.UNIT),
            dataIndex: 'productUnit',
            key: 'productUnit',
            render: (_, record) => {
                return (
                    <Tag>
                        {record.productUnit?.name}
                    </Tag>
                );
            },
        },
        {
            width: 116,
            title: t(translateCodes.CAPACITY),
            dataIndex: 'productCapacity',
            key: 'productCapacity',
            render: (_, record) => {
                if(record.productCapacity?.value == null){
                    return '';
                }
                return ((record.productCapacity?.value ?? '') +' '+ ProductCapacityType[record.productCapacity.type]);
            },
        },
        {
            width: 116,
            title: t(translateCodes.ORIGINAL_PRICE),
            dataIndex: 'originalPrice',
            key: 'originalPrice',
            render: (_, record) => {
                if (record?.productBranches?.length === 0) return '';
                const price = record?.productBranches?.[0]?.originalPrice;
                return <Text>{formatCurrency(price ?? 0)}</Text>;
            },
        },
        {
            width: 116,
            title: t(translateCodes.SELLING_PRICE),
            dataIndex: 'sellingPrice',
            key: 'sellingPrice',
            render: (_, record) => {
                if (record?.productBranches?.length === 0) return '';
                const price = record?.productBranches?.[0]?.sellingPrice;
                return <Text fontWeight={600}>{formatCurrency(price ?? 0)}</Text>;
            },
        },
        {
            width: 116,
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showModalDelete(record.id);
                        }}
                        onClickEdit={() => onEdit(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.PRODUCTS}
            rightHeaderPage={
                <CreateProductBtn
                    refetchProduct={() => {
                        refetch(),
                        resetDrawerState();
                    }} />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({...searchParams, page: page.toString()})
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading}
                titleTableTransCode={translateCodes.PRODUCT_LIST}
                placeholderSearchTransCode= {translateCodes.PLACE_HOLDER_SEARCH_PRODUCT}
                columns={columns}
                dataSource={products?.items ?? []}
                emptyOption={{
                    title: t(translateCodes.ADD_PRODUCT),
                    description: t(translateCodes.EMPTY_PRODUCT_DESCRIPTION),
                    actionButton: (
                        <CreateProductBtn
                            refetchProduct={() => {
                                refetch(),
                                resetDrawerState();
                            }} />
                    )
                }}
            />
        </PageLayout>
    );
}
