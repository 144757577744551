import { Card } from 'antd';
import React from 'react';
import { DatePickerField, FlexBox, FormLayout, InputField } from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { BreakTimeGetDetailResponse } from '@esg/business-resource-management';
import { EmployeeSelectField } from '../employee';
import { useBreakTimeSubmit } from './@share/hooks';
import { useBreakTimeContext } from './BreakTimeContext';
import { BreakTimeTimeRange } from './children/BreakTimeTimeRange';
import { translateCodes } from '@/locales';

export const BreakTimeFormControl = () => {
    const { setValue, handleSubmit } = useFormContext<BreakTimeGetDetailResponse>();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { employees } = useBreakTimeContext();
    
    const { handleEdit, loading } = useBreakTimeSubmit();

    return (
        <form onSubmit={handleSubmit(handleEdit)}>
            <FormLayout width={613} isLoadingButton={loading} labelSubmitCode='Save'>
                <Card className='time-block-form'>
                    <InputField
                        disabled
                        name='title'
                        label={translateCodes.TITLE}
                        placeholder={translateCodes.BREAK_TIME}
                    />
                    <FlexBox gap={16}>
                        <EmployeeSelectField
                            disabled
                            name='employeeId'
                            employeeOptions={employees.map(o=> ({
                                ...o,
                                label: o.fullName,
                                value: o.id,
                            }))}
                            label={translateCodes.EMPLOYEE}
                            placeholder={translateCodes.SELECT_EMPLOYEE}
                            rules={{ required: translateCodes.THE_EMPLOYEE_IS_REQUIRED }}
                        />
                        <DatePickerField
                            disabled
                            name='date'
                            format={currentLocale?.format}
                            label={translateCodes.DATE}
                            onChange={(value) =>
                                setValue('date', value.startOf('day').toISOString())
                            }
                        />
                    </FlexBox>
                    <BreakTimeTimeRange />
                </Card>
            </FormLayout>
        </form>
    );
};
