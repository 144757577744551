import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import { AppButton, Block, Spacer } from '@esg/ui';
import React from 'react';
import { AppointmentStatus } from '@esg/shared';
import { NotificationInstance } from 'antd/es/notification/interface';
import { useUpdateAptStatus } from '../@share/hooks/useUpdateAptStatus';
import { translateCodes } from '@/locales';
import { AptNotifyStatus } from '@/contexts/realtime-context';
import { AppointmentCardInfo } from '@/components/appointment/appointment-card-info';

interface AppointmentNotifyProps {
	readonly desc: string;
	readonly status: AptNotifyStatus;
	readonly notify: NotificationInstance;
	readonly appointment: AppointmentGetDetailResponse;
}
export const AppointmentNotify = ({
    desc,
    status,
    notify,
    appointment,
}: AppointmentNotifyProps) => {
    const updateAptStatus = useUpdateAptStatus();
    const isCancel = status === 'cancel';
    const isAction = status === 'book' || isCancel;

    return (
        <Block>
            <p dangerouslySetInnerHTML={{
                __html: desc
            }} />
            <Spacer bottom={12} />
            <AppointmentCardInfo appointment={appointment} />
            <Spacer bottom={12} />
            {isAction && (
                <AppButton
                    width='100%'
                    type='primary'
                    danger={isCancel}
                    onClick={() => {
                        notify.destroy();
                        updateAptStatus(
                            appointment.id,
                            isCancel ? AppointmentStatus.Cancelled : AppointmentStatus.Confirmed
                        );
                    }}
                    translateCode={
                        isCancel ? translateCodes.CANCEL : translateCodes.CONFIRM
                    }
                />
            )}
        </Block>
    );
};
