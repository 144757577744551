import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetSummaryAppointmentRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetSummaryAppointmentResponse {
    readonly totalAppointment: number;
    readonly totalServices: number;
    readonly totalClients: number;
    readonly totalClientsNoShow: number;
    readonly totalAppointmentValue: number;
}

export const getSummaryAppointment = httpUtil.createHttpRequestMeta<
    GetSummaryAppointmentRequest,
	GetSummaryAppointmentResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments-report/summary',
    authentication: 'bearer',
});