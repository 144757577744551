import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { ShiftGetDetailResponse } from '../shift';

export interface EmployeeUpdateWorkingTimeRequest extends HttpRequestData {
	readonly body: {
		readonly id: string;
		readonly shifts: ShiftGetDetailResponse[];
	};
}

export const updateEmployeeWorkingTime = httpUtil.createHttpRequestMeta<EmployeeUpdateWorkingTimeRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/employees/:id/working-times',
    method: 'PATCH',
    authentication: 'bearer',
});
