import { Avatar } from 'antd';
import { Text } from '@esg/ui';
import { GroupProps } from 'antd/es/avatar';
import React from 'react';

import { FlexBox } from '../../layouts';

export interface AvatarItem {
    id?: string;
    src?: string;
    fullName?: string;
    avatar?: string;
}

interface ColumnAvatarGroupProps extends GroupProps {
    items: AvatarItem[];
    prefixEnd?: string;
    maxWidthText?: number;
}

export const ColumnAvatarGroup = (props: ColumnAvatarGroupProps) => {
    return (
        <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
            <Avatar.Group
                maxCount={props.maxCount}
                maxPopoverTrigger='hover'
                size={props.size}
            >
                {props.items?.map((item) => (
                    <Avatar
                        key={item?.id}
                        src={item?.avatar}
                        style={{ backgroundColor: '#F2F4F7' }}
                    >
                        {!item?.avatar ? item?.fullName?.[0] : undefined}
                    </Avatar>
                ))}
            </Avatar.Group>
            
            <Text maxLines={1} maxWidth={props.maxWidthText} ellipsis color='#475467'>
                {props.prefixEnd && props.items?.length > 1 ? props.prefixEnd : props.items[0]?.fullName ?? ''}
            </Text>
        </FlexBox>
    );
};
