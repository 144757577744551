import { Card } from 'antd';
import React from 'react';
import { DatePickerField, FlexBox, FormLayout, InputField, SelectField, Translate } from '@esg/ui';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { useRecoilState } from 'recoil';
import { TimeBlockRepeatBy as RepeatBy } from '@esg/business-resource-management';
import { EmployeeSelectField } from '../employee';
import { TimeBlockRepeat } from './children/TimeBlockRepeat';
import { TimeBlockTimeRange } from './children/TimeBlockTimeRange';
import {
    useTimeBlockCreate,
    useTimeBlockResources,
    useTimeBlockUpdateConfirm,
} from './@share/hooks';

import { translateCodes } from '@/locales';

export const TimeBlockFormControl = () => {
    const { employeeOptions, timeStep } = useTimeBlockResources();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const { loading, handleCreate } = useTimeBlockCreate();
    const { showUpdateConfirm, isUpdate } = useTimeBlockUpdateConfirm();

    return (
        <form onSubmit={isUpdate ? showUpdateConfirm : handleCreate}>
            <FormLayout
                width={613}
                isLoadingButton={loading}
                labelSubmitCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
            >
                <Card className='time-block-form'>
                    <InputField
                        name='title'
                        label={translateCodes.TITLE}
                        placeholder={translateCodes.TITLE_PLACEHOLDER}
                    />
                    <FlexBox gap={16}>
                        <EmployeeSelectField
                            type='Appointment'
                            label={translateCodes.EMPLOYEES}
                            employeeOptions={employeeOptions}
                            mode={isUpdate ? undefined : 'multiple'}
                            placeholder={translateCodes.SELECT_EMPLOYEE}
                            name={isUpdate ? 'employeeId' : 'employeeIds'}
                            rules={{ required: translateCodes.THE_EMPLOYEE_IS_REQUIRED }}
                        />
                        <DatePickerField
                            name='date'
                            label={translateCodes.DATE}
                            format={currentLocale?.format}
                        />
                    </FlexBox>
                    <TimeBlockTimeRange timeStep={timeStep} />
                    <SelectField
                        name='repeatBy'
                        options={repeatOptions}
                        label={translateCodes.REPEATS}
                        placeholder={translateCodes.REPEATS_PLACEHOLDER}
                    />
                    <TimeBlockRepeat />
                </Card>
            </FormLayout>
        </form>
    );
};

const repeatOptions = [
    {
        label: <Translate translateCode={translateCodes.NOT_REPEAT} />,
        value: RepeatBy.NONE,
    },
    {
        label: <Translate translateCode={translateCodes.EVERY_DAY} />,
        value: RepeatBy.DAILY,
    },
    {
        label: <Translate translateCode={translateCodes.EVERY_WEEK} />,
        value: RepeatBy.WEEKLY,
    },
    {
        label: <Translate translateCode={translateCodes.EVERY_MONTH} />,
        value: RepeatBy.MONTHLY,
    },
];
