import { useCallback } from 'react';
import { globalDrawerState, globalModalState, useHttpCommand } from '@esg/ui';
import {
    createTimeBlock,
    createTimeBlockDisable,
    TimeBlockRepeatBy as RepeatBy,
    updateTimeBlock,
} from '@esg/business-resource-management';
import { useResetRecoilState } from 'recoil';
import { dayjs } from '@esg/shared';
import { TimeBlockCircle, TimeBlockUpdateValues } from '../../Types';
import { getRepeatToDate } from '../blockTimeUtils';
import { timeBlockEvents } from '@/event';

interface Params {
	readonly editType?: TimeBlockCircle;
	readonly values: TimeBlockUpdateValues;
	readonly defaultValues: TimeBlockUpdateValues;
}

export const useTimeBlockUpdate = () => {
    const hideModal = useResetRecoilState(globalModalState);
    const hideDrawer = useResetRecoilState(globalDrawerState);

    const { mutateAsync: createTimeBlockAsync } = useHttpCommand(createTimeBlock);

    const { mutateAsync: createTBDisable } = useHttpCommand(createTimeBlockDisable, {
        onSuccess() {
            hideModal();
            hideDrawer();
            timeBlockEvents.refetch.emit();
        },
    });

    const { mutateAsync: updateTimeBlockAsync } = useHttpCommand(updateTimeBlock, {
        onSuccess() {
            hideModal();
            hideDrawer();
            timeBlockEvents.refetch.emit();
        },
    });

    const handleUpdate = useCallback(
        async ({ values, editType, defaultValues }: Params) => {
            const { date, employeeId, id, originDate } = values;

            if (editType === 'now') {
                await createTimeBlockAsync({
                    body: {
                        ...values,
                        repeatTo: date,
                        repeatBy: RepeatBy.NONE,
                        employeeIds: [employeeId],
                    },
                });

                return void (await createTBDisable({
                    body: { date: defaultValues.date, employeeId, timeBlockId: id },
                }));
            }

            if (editType === 'future') {
                await createTimeBlockAsync({
                    body: {
                        ...values,
                        employeeIds: [employeeId],
                        repeatTo: getRepeatToDate(values),
                    },
                });

                return void (await updateTimeBlockAsync({
                    pathData: { id },
                    body: {
                        ...defaultValues,
                        date: originDate,
                        repeatTo: dayjs(defaultValues.date).add(-1, 'day').toISOString(),
                    },
                }));
            }

            await updateTimeBlockAsync({
                pathData: { id },
                body: {
                    ...values,
                    repeatTo: getRepeatToDate(values),
                    date: editType === 'all' ? originDate : date,
                },
            });
        },
        [createTBDisable, createTimeBlockAsync, updateTimeBlockAsync]
    );

    return handleUpdate;
};
