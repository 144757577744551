import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TimeOffFormValues } from './Types';
import { TimeOffFormControl } from './TimeOffFormControl';

interface TimeOffFormProps {
	readonly defaultValues: TimeOffFormValues;
}

export function TimeOffForm({ defaultValues }: TimeOffFormProps) {
    const methods = useForm<TimeOffFormValues>({
        defaultValues,
    });

    return (
        <FormProvider {...methods}>
            <TimeOffFormControl/>
        </FormProvider>
    );
}
