import { managementApis } from '@esg/business-management';
import { GetAllServiceCategoryResponseItem } from '@esg/business-management/api/service-category';
import {
    ColumnActions,
    FlexBox,
    TableCustom,
    Text,
    globalDrawerState,
    globalModalState,
    useHttpCommand,
    useHttpQuery,
    usePagination,
} from '@esg/ui';
import { TableColumnsType } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { CreateServiceCategoryBtn } from './button-handler/CreateServiceCategoryBtn';

import { ServiceCategoryForm } from '@/components';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { translateCodes } from '@/locales';

export const ServiceCategory = () => {
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();

    const {
        data: serviceCategories,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllServiceCategory, {
        query: searchParams,
    });

    const { mutateAsync } = useHttpCommand(
        managementApis.deleteServiceCategory
    );

    const onDelete = React.useCallback(
        async (id: string) => {
            await mutateAsync(
                {
                    pathData: {
                        id: id,
                    },
                },
                {
                    onSuccess(_) {
                        resetModalState();
                        resetDrawerState();
                        refetch();
                    },
                }
            );
        },
        [mutateAsync, refetch, resetDrawerState, resetModalState]
    );

    const showModalDelete = React.useCallback((id: string) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_SERVICE_CATEGORY)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_SERVICE_CATEGORY_CONFIRM)}
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onEdit = React.useCallback(
        (values: GetAllServiceCategoryResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: translateCodes.EDIT_SERVICE_CATEGORY,
                content: (
                    <ServiceCategoryForm
                        onDelete={() => showModalDelete(values.id)}
                        refetch={refetch}
                        resetDrawerState={resetDrawerState}
                        defaultValues={values}
                    />
                ),
            });
        }, [setDrawerState, showModalDelete, refetch, resetDrawerState]
    );

    const columns: TableColumnsType<GetAllServiceCategoryResponseItem> = [
        {
            title: t(translateCodes.NAME),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Text maxWidth={300} ellipsis>{record.name}</Text>;
            }
        },
        {
            width: 600,
            title: t(translateCodes.DESCRIPTION),
            dataIndex: 'description',
            render: (_, record) => {
                return <Text maxWidth={600} ellipsis>{record.description || 'N/A'}</Text>;
            }
        },
        {
            width: 116,
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showModalDelete(record.id);
                        }}
                        onClickEdit={() => onEdit(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.SERVICE_CATEGORY}
            rightHeaderPage={
                <CreateServiceCategoryBtn 
                    refetch={refetch}
                    resetDrawerState={resetDrawerState}
                />
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({
                        ...searchParams,
                        page: page.toString(),
                    })
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading}
                titleTableTransCode={translateCodes.SERVICe_CATEGORY_LIST}
                placeholderSearchTransCode={translateCodes.SERVICE_CATEGORY_PLACEHOLDER}
                columns={columns}
                dataSource={serviceCategories?.items ?? []}
                emptyOption={{
                    title: t(translateCodes.ADD_SERVICE_CATEGORY),
                    description: t(translateCodes.EMPTY_SERVICE_CATEGORY_DESCRIPTION),
                    actionButton: (
                        <CreateServiceCategoryBtn 
                            refetch={refetch}
                            resetDrawerState={resetDrawerState}
                        />
                    )
                }}
            />
        </PageLayout>
    );
};
