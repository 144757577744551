import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../configs';

interface TaxUpdateRequest extends HttpRequestData {
    readonly body: {
        readonly id?: string;
        readonly name: string;
        readonly branchId: string;
        readonly value: number;
        readonly descriptions: string;
    };
}
export interface TaxUpdateResponseItem {
    readonly id: string;
    readonly name: string;
    readonly branchId: string;
    readonly value: number;
    readonly descriptions: string;
}

export const taxUpdate = httpUtil.createHttpRequestMeta<
    TaxUpdateRequest,
    TaxUpdateResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/TaxSettings/:id',
    method: 'PATCH',
    authentication: 'bearer',
});
