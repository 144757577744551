import { managementApis } from '@esg/business-management';
import { GetAllServiceResponseItem } from '@esg/business-management/api/service';
import {
    ColumnActions,
    ColumnAvatarGroup,
    FlexBox,
    ListNameColumn,
    TableCustom,
    Text,
    useHttpCommand,
    useHttpQuery,
    useLocalesFormat,
    usePagination,
} from '@esg/ui';
import { globalDrawerState, globalModalState } from '@esg/ui/state';
import { TableColumnsType, Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { CreateServiceBtn } from './button-handler';

import { ServiceForm } from '@/components';
import { PageLayout } from '@/layouts';
import { translateCodes } from '@/locales';

export const Services = () => {
    const { formatCurrency } = useLocalesFormat();

    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = usePagination();

    const {
        data: servicesResponse,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(managementApis.getAllServices, {
        query: searchParams,
    });

    const { mutateAsync } = useHttpCommand(managementApis.deleteService);

    const onDelete = React.useCallback(
        async (id: string) => {
            await mutateAsync(
                { pathData: { id: id } },
                {
                    onSuccess(_) {
                        resetModalState();
                        resetDrawerState();
                        refetch();
                    },
                }
            );
        },
        [mutateAsync, refetch, resetModalState, resetDrawerState]
    );

    const showModalDelete = React.useCallback((id: string) => {
        setModalState({
            content: (
                <FlexBox direction='column'>
                    <Text fontSize={18} fontWeight={700}>
                        {t(translateCodes.DELETE_SERVICE)}
                    </Text>
                    <Text fontSize={14}>
                        {t(translateCodes.DELETE_SERVICE_CONFIRM)}
                    </Text>
                </FlexBox>
            ),
            isOpen: true,
            onOk: () => {
                onDelete(id);
            },
            showModalDelete: true,
        });
    }, [onDelete, setModalState, t]);

    const onEdit = React.useCallback(
        (values: GetAllServiceResponseItem) => {
            setDrawerState({
                isOpen: true,
                titleTransCode: translateCodes.TITLE_PAGE_SERVICE,
                content: (
                    <ServiceForm
                        onDelete={() => showModalDelete(values.id)}
                        refetch={refetch}
                        resetDrawerState={resetDrawerState}
                        defaultValues={{
                            ...values,
                            price: values.serviceBranches?.[0]?.price,
                            taxSettingId: values.serviceBranches?.[0]?.taxSettingId,
                        }}
                    />
                ),
            });
        },
        [refetch, resetDrawerState, setDrawerState, showModalDelete]
    );

    const columns: TableColumnsType<GetAllServiceResponseItem> = [
        {
            title: t(translateCodes.NAME),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Text maxWidth={200} ellipsis>{record.name}</Text>;
            }
        },
        {
            title: t(translateCodes.CATEGORY),
            dataIndex: 'serviceCategory',
            key: 'category',
            render: (_, record) => {
                return <Tag><Text maxWidth={85} ellipsis>{record.serviceCategory?.name}</Text></Tag>;
            },
        },
        {
            title: t(translateCodes.FACILITY),
            key: 'facility',
            dataIndex: 'facility',
            render: (_, record) => {
                const facilityNames = record.serviceFacilities?.filter((e)=> e.facility).map((item) => item?.facility?.name);
                return <ListNameColumn names={facilityNames} limit={2} tooltip/>;
            },
        },
        {
            title: t(translateCodes.EMPLOYEES),
            dataIndex: 'employee',
            key: 'employee',
            render: (_, record) => {
                const employees = record?.employeeServices?.map((item) => item.employee).filter(e=> e) ?? [];
                return (
                    <ColumnAvatarGroup
                        items={employees}
                        maxCount={2}
                        prefixEnd={`${employees?.length} ${t(translateCodes.EMPLOYEE_MORE)}`}
                    />
                );
            },
        },
        {
            title: t(translateCodes.DURATION),
            dataIndex: 'duration',
            key: 'duration',
            render: (_, record) => {
                return `${record.duration} ${t(
                    translateCodes.MINUTE_DURATION
                )}`;
            },
        },
        {
            title: t(translateCodes.PRICE),
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                if (record?.serviceBranches?.length === 0) return '';
                const price = record?.serviceBranches?.[0]?.price;
                return <Text fontWeight={600}>{formatCurrency(price ?? 0)}</Text>;
            },
        },
        {
            title: t(translateCodes.ACTION),
            key: 'action',
            render: (_, record) => {
                return (
                    <ColumnActions
                        onClickDelete={() => {
                            showModalDelete(record.id);
                        }}
                        onClickEdit={() => onEdit(record)}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.TITLE_PAGE_SERVICE}
            rightHeaderPage={<CreateServiceBtn refetchServices={refetch} />}
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({ ...searchParams, page: page.toString()})
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                loading={isFetching || isLoading}
                titleTableTransCode={translateCodes.SERVICE_LIST}
                placeholderSearchTransCode={translateCodes.PLACE_HOLDER_SEARCH_SERVICE}
                columns={columns}
                dataSource={servicesResponse?.items}
                pageInfo={servicesResponse?.meta}
                emptyOption={{
                    title: t(translateCodes.ADD_SERVICE),
                    description: t(translateCodes.EMPTY_SERVICE_DESCRIPTION),
                    actionButton: (
                        <CreateServiceBtn refetchServices={refetch} />
                    )
                }}
            />
        </PageLayout>
    );
};
