import { InputSearch, SelectField, Text, useLocalesFormat } from '@esg/ui';
import React, { useCallback, useMemo, useState } from 'react';
import {
    GetAllServiceCategoryResponseItem,
    GetAllServiceResponseItem,
} from '@esg/business-management';
import { Card, Divider, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { BaseOptionType } from 'antd/es/select';
import { UseControllerProps } from 'react-hook-form';
import { translateCodes } from '@/locales';

interface ServiceSelectFieldProps {
	fieldName: string;
	dataSource: (GetAllServiceResponseItem & { employeeIds: string[] })[];
	categories: GetAllServiceCategoryResponseItem[];
	employees: EmployeeGetDetailResponse[];
	currentEmployeeIds: string[];
	currentFacilityId: string;
    rules?: UseControllerProps['rules'];
}

export const ServiceSelectField = (props: ServiceSelectFieldProps) => {
    const { fieldName, dataSource: services, categories, currentEmployeeIds = [], employees, currentFacilityId, rules } = props;
    const { formatCurrency } = useLocalesFormat();
    const [searchValue, setSearchValue] = useState('');

    const servicesSearch = useMemo(() => 
        services.filter((o) => o.name.toLowerCase().includes(searchValue.toLowerCase())),
    [services, searchValue]);

    const serviceOptions = useMemo(() => {
        return categories.map((category) => {

            const servicesByCategory = servicesSearch.filter((o) => o.serviceCategoryId === category.id);

            return {
                label: category.name,
                options: servicesByCategory.map((service) => {

                    const employeeNotProvideId = currentEmployeeIds?.find(employee => !service.employeeIds.includes(employee));
                    const facilityIds = service.serviceFacilities?.map(o => o?.facility?.id);

                    return {
                        label: service.name,
                        value: service.id,
                        price: service.serviceBranches[0].price,
                        duration: service.duration,
                        employeeNotProvideId: employeeNotProvideId,
                        disabled: currentFacilityId && !facilityIds?.includes(currentFacilityId),
                    };
                }),
            };
        });
    },
    [categories, currentFacilityId, currentEmployeeIds, servicesSearch]);

    const serviceOptionsRender = useCallback(
        (option: BaseOptionType) => {
            const { label, duration, price, employeeNotProvideId, disabled } = option;
            let employee;
            if (employeeNotProvideId) {
                employee = employees.find((st) => st.id === employeeNotProvideId);
            }
            return (
                <Tooltip title={employeeNotProvideId ? `${employee?.fullName} doesn't provide this service.` : ''}>
                    <Card size='small' className='service-option' style={{opacity: disabled ? '0.5' : '1'}}>
                        {employeeNotProvideId && <ExclamationCircleOutlined className='warning-icon' />}
                        <Text maxLines={1} maxWidth='40%'>{label}</Text>
                        <Text fontSize={12} color='#475467'>
                            {duration} mins
                        </Text>
                        <Text className='price'>{formatCurrency(price)}</Text>
                    </Card>
                </Tooltip>
            );
        },
        [formatCurrency, employees]
    );

    const dropdownRenderService = useCallback(
        (options: React.ReactElement) => (
            <>
                {serviceOptions.length > 0 && (
                    <>
                        <InputSearch variant='borderless' placeholder='Search' onChange={(value) => setSearchValue(value.target.value)}/>
                        <Divider />
                    </>
                )}
                {options}
            </>
        ),
        [serviceOptions.length]
    );

    return (
        <SelectField
            name={`${fieldName}.serviceId`}
            placeholder={translateCodes.SELECT_SERVICE}
            options={serviceOptions}
            listHeight={473}
            popupMatchSelectWidth={398}
            popupClassName='appointment-service-select'
            optionRender={({ data }) => serviceOptionsRender(data)}
            dropdownRender={dropdownRenderService}
            rules={rules}
        />
    );
};
