import {
    AppButton,
    FlexBox,
    Spacer,
    Text,
    IconBorn,
    useHttpQuery,
} from '@esg/ui';
import {
    Flex,
} from 'antd';
import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';

import { GetOverviewClientDetailResponse } from '@esg/business-report/api/client-detail-report';
import { getAllCustomerNotes } from '@esg/business-crm/api/customer-note';
import { getAllCustomerActivity } from '@esg/business-crm/api/customer-activity';
import { getUpcomingAppointments } from '@esg/business-schedule';
import { CustomerOverview } from './CustomerOverview';
import { CustomerInfoDetail } from './CustomerInfoDetail';
import { CustomerDetailTable } from './CustomerDetailTable';
import { CustomerPackagesDetail } from './CustomerPackagesDetail';
import { CustomerMembershipDetail } from './CustomerMembershipDetail';
import { CustomerNoteDetail } from './CustomerNoteDetail';
import { ActionCustomerDetail } from './ActionCustomerDetail';
import { translateCodes } from '@/locales';
import { useAppointmentForm } from '@/hooks/useAppointmentForm';
interface CustomerInfoProps {
    customer?: GetCustomerDetailResponse;
    loading?: boolean;
    refetchCustomerDetail: () => void;
    reportOverview?: GetOverviewClientDetailResponse;
    refetchOverview: () => void;
}

export const CustomerInfo = ({ customer, loading, refetchCustomerDetail, reportOverview, refetchOverview }: CustomerInfoProps) => {

    const showAppointmentForm = useAppointmentForm();

    const { data: customerNotes, refetch } = useHttpQuery(getAllCustomerNotes, {
        query: {
            customerId: customer?.id,
        }
    });

    const { data: CustomerActivities, refetch: refetchCustomerActivity  } = useHttpQuery(getAllCustomerActivity, {
        query: {
            customerId: customer?.id,
            pageSize: 1000,
        }
    });
    
    const {
        data: appointmentResponse,
        isFetching: isAppointmentFetching,
        refetch: refetchUpcomingAppointments
    } = useHttpQuery(getUpcomingAppointments,
        {
            query: {
                customerId: customer?.id,
                pageSize: 5,
            }
        }
    );
    
    return (
        <FlexBox direction='column'>
            <FlexBox justifyContent='space-between' alignItems='end'>
                <Text translateCode={translateCodes.PERSONAL_INFO} fontWeight='bold' fontSize={18} />
                <Flex align='center' gap={10}>
                    <ActionCustomerDetail
                        refetchCustomerNotes={refetch}
                        customer={customer}
                        refetch={refetchCustomerDetail}
                        refetchCustomerActivity={refetchCustomerActivity}
                    />
                    <AppButton
                        type='primary'
                        onClick={()=>showAppointmentForm({
                            customerId: customer?.id,
                            refetch: refetchUpcomingAppointments,
                        })}>
                        <FlexBox gap={6}>
                            <IconBorn width={20} height={20} color='white' />
                            <Text color='white' translateCode={translateCodes.BOOKING_NOW} />
                        </FlexBox>
                    </AppButton>
                </Flex>
            </FlexBox >
            <Spacer height={10} />
            <FlexBox gap={20}>
                <FlexBox flex={10} direction='column'>
                    <CustomerOverview
                        customer={customer}
                        loading={loading}
                        reportOverview={reportOverview}
                    />
                    <Spacer height={20} />
                    <CustomerDetailTable
                        customerActivities={CustomerActivities?.items ?? []}
                        customer={customer} 
                        refetchOverview={refetchOverview} 
                        appointmentUpcoming={appointmentResponse?.items ?? []}
                        refetchAppointmentUpcoming={refetchUpcomingAppointments}
                        isLoadingAppointmentUpcoming={isAppointmentFetching}
                    />
                </FlexBox>
                <FlexBox gap={20} flex={2} direction='column'>
                    <CustomerInfoDetail
                        customer={customer}
                        loading={loading}
                        reportOverview={reportOverview}
                    />
                    <CustomerPackagesDetail
                        customer={customer}
                        loading={loading}
                        reportOverview={reportOverview}
                    />
                    <CustomerMembershipDetail
                        customer={customer}
                        loading={loading}
                        reportOverview={reportOverview}
                    />
                    <CustomerNoteDetail
                        refetchCustomerNotes={refetch}
                        customerNotes={customerNotes?.items ?? []}
                        customer={customer}
                        loading={loading}
                        reportOverview={reportOverview}
                    />
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};
