import {
    GetDetailFacilitiesResponse,
    resourceApis,
} from '@esg/business-resource-management';
import {
    Header,
    IconPen,
    IconTrash,
    Paragraph,
    Spacer,
    Text,
} from '@esg/ui';
import { useHttpCommand } from '@esg/ui/hooks';
import { useCallback, useMemo, useState } from 'react';
import { globalDrawerState, globalModalState } from '@esg/ui/state';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { GetAllServiceCategoryResponseItem, GetAllServiceResponseItem } from '@esg/business-management';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import ListServiceCategory from './ListServiceCategory';
import { translateCodes } from '@/locales';
import { RESOURCE_MANAGEMENT_FACILITY_LIST_URL } from '@/configs';
import { PageLayout } from '@/layouts';
import { FacilityForm } from '@/components/form/facility-form';

interface FacilityDetailProps {
    facilityActive?: GetDetailFacilitiesResponse;
    services: GetAllServiceResponseItem[],
    categories: GetAllServiceCategoryResponseItem[],
    refetch: () => void;
}

export default function FacilityDetail(props: FacilityDetailProps) {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setModalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const navigate = useNavigate();

    const servicesCategory: GetAllServiceCategoryResponseItem[] = useMemo(() => {
        const listServiceOfFacility = props.facilityActive?.serviceFacilities?.map((o) => o.service);
        const serviceCategoriesIds = uniq(listServiceOfFacility?.map((o) => o.serviceCategoryId));

        return props.categories?.filter((service) => serviceCategoriesIds.includes(service.id)).map(category => ({
            ...category,
            services: listServiceOfFacility?.filter(o => o.serviceCategoryId === category.id) ?? []
        }));

    }, [props.categories, props.facilityActive?.serviceFacilities]);

    const { mutateAsync } = useHttpCommand(resourceApis.deleteFacility, {
        onSuccess() {
            props.refetch();
            resetModalState();
            resetDrawerState();
            navigate(RESOURCE_MANAGEMENT_FACILITY_LIST_URL);
        },
    });

    const searchHandler = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    const handleDelete = useCallback(
        async (Facility: GetDetailFacilitiesResponse) => {
            setModalState({
                isOpen: true,
                showModalDelete: true,
                content: (
                    <>
                        <Header level={3} translateCode={t(translateCodes.DELETE_FACILITY)} />
                        <Spacer bottom='xs' />
                        <Paragraph>
                            {t(translateCodes.DELETE_FACILITY_MSG)}:
                            <Text fontWeight='bold'>{' '+Facility.name}</Text>
                        </Paragraph>
                    </>
                ),
                onOk: () => {
                    mutateAsync({
                        pathData: {
                            id: Facility.id,
                        },
                    });
                },
            });
        },
        [mutateAsync, setModalState, t]
    );

    const handleEdit = useCallback(
        async () => {
            setDrawerState({
                isOpen: true,
                titleTransCode: t(translateCodes.EDIT_FACILITY),
                content: (
                    <FacilityForm
                        onDeleted={() => handleDelete(props.facilityActive!)}
                        refetchFacility={props.refetch}
                        defaultValues={{
                            ...props.facilityActive!,
                            serviceIds: props?.facilityActive?.serviceFacilities?.map((e) => e.serviceId),
                        }}
                    />
                ),
            });
        },
        [handleDelete, props.facilityActive, props.refetch, setDrawerState, t]
    );

    return (
        <PageLayout
            pageTitleTransCode={props.facilityActive?.name}
            rightHeaderPage={(
                <Space>
                    <Button icon={<IconPen onClick={handleEdit} />} />
                    <Button icon={<IconTrash onClick={() => handleDelete(props.facilityActive!)} />} />
                </Space>
            )}
        >
            <Space
                direction='vertical'
                size='middle'
                style={{
                    border: '1px solid #D0D5DD',
                    borderRadius: '14px',
                    padding: '20px',
                    background: '#fff',
                    width: '100%'
                }}
            >
                {props.facilityActive?.serviceFacilities?.length ? (
                    <>
                        <Input
                            size='large'
                            placeholder={t(translateCodes.PLACE_HOLDER_SEARCH_SERVICE)}
                            prefix={<SearchOutlined />}
                            onChange={(value) => searchHandler(value.target.value)}
                        />
                        <ListServiceCategory defaultData={servicesCategory} searchParams={searchValue} />
                    </>
                ) : (
                    <Text translateCode={t(translateCodes.HAS_NO_SERVICE)} />
                )}
            </Space>
        </PageLayout>
    );
}