import { customerApi } from '@esg/business-crm';
import { useHttpQuery } from '@esg/ui';
import { useState } from 'react';

export default function useSearchCustomer () {
    const [searchValue, setSearchValue] = useState('');
    const { data, isLoading, isFetching, refetch } = useHttpQuery(
        customerApi.getAllCustomers,
        {
            query: { search: searchValue },
        }
    );

    const loading = isLoading || isFetching;

    const customers = data?.items;

    return { customers, setSearchValue, loading , searchValue, refetch};
}
