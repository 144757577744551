import { HttpRequestData, httpUtil, PaginationResponse } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../../configs';

interface GetEmployeeAppointmentReportRequest extends HttpRequestData {
	readonly query: PaginationRequest;
}

interface EmployeeInfo {
    id: string;
    fullName: string;
    avatar: string;

}
export interface GetEmployeeAppointmentReportItem {
    id: string;
    employee: EmployeeInfo;
    quantity: number;
    revenue: number;
}

export const getEmployeesAppointmentReport = httpUtil.createHttpRequestMeta<
    GetEmployeeAppointmentReportRequest,
	PaginationResponse<GetEmployeeAppointmentReportItem>
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments-report/employee-summary',
    authentication: 'bearer',
});