import {
    Paragraph,
    SchedulerItem,
    SchedulerRowInfo,
    Spacer,
    Translate,
    globalModalState,
} from '@esg/ui';
import React from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { ScheduleAppointment } from '../../Types';
import { useSchedulerContext } from '../SchedulerContext';
import { SchedulerEmployeeRowInfo } from '../../children/scheduler-table/children/SchedulerEmployeeRowInfo';
import { useSchedulerTableBusiness } from './useSchedulerTableBusiness';
import { translateCodes } from '@/locales';

export const useSchedulerTableDrop = () => {
    const { employees, refetchAppointment } = useSchedulerContext();
    const [, setModalGlobalState] = useRecoilState(globalModalState);
    const resetModal = useResetRecoilState(globalModalState);

    const handleAppointmentServiceEmployee = useSchedulerTableBusiness({
        refetchAppointment,
        resetModal,
    });

    const dropConfirm = React.useCallback(
        (
            currentItem: SchedulerItem<ScheduleAppointment>,
            employeeTarget: SchedulerRowInfo<SchedulerEmployeeRowInfo>,
            onOk: () => void
        ) => {
            setModalGlobalState({
                content: (
                    <Spacer type='padding' size='lg'>
                        <Paragraph>
                            <Translate
                                translateCode={translateCodes.MOVE_SERVICE_TO_STAFF_CONFIRM}
                                option={{
                                    serviceName: currentItem.data.values.service.name,
                                    employeeName: employeeTarget.data.name,
                                }}
                            />
                        </Paragraph>
                    </Spacer>
                ),
                titleTransCode: 'Update appointment',
                isOpen: true,
                onOk: onOk,
            });
        },
        [setModalGlobalState]
    );

    return (item: SchedulerItem<ScheduleAppointment>, rowInfo: SchedulerRowInfo) => {
        const serviceIds = employees
            .find((o) => o.id === rowInfo.key)
            ?.employeeServices?.map((o) => o.serviceId) || [];

        const serviceId = item.data.values.service.id;

        if (!serviceIds.includes(serviceId) && rowInfo.key !== '0') {
            return void viewRequests.showNotification.send({
                type: 'warning',
                placement: 'bottomRight',
                message: (
                    <Translate
                        type='html'
                        translateCode={translateCodes.DOES_NOT_PROVIDER_THIS_SERVICE}
                        option={{
                            employeeName: rowInfo.data.name,
                        }}
                    />
                ),
            });
        }

        dropConfirm(item, rowInfo, () => handleAppointmentServiceEmployee(item, rowInfo));
    };
};