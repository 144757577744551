import { Block, Text, useLocalesFormat } from '@esg/ui';
import { Col, Row, Space, Tag } from 'antd';
import { GetAllServiceCategoryResponseItem } from '@esg/business-management';

interface ListServiceCategoryProps {
    defaultData?: GetAllServiceCategoryResponseItem[]
    searchParams: string;
}
export default function ListServiceCategory(props: ListServiceCategoryProps) {
    const {formatCurrency} = useLocalesFormat();
    return (
        <>
            {props.defaultData?.map((e) => {
                return (
                    <Block key={e.id} >
                        <Space direction='vertical' size='small' style={{ width: '100%', marginBottom: '20px' }} >
                            <Space size='small'>
                                <h3 >{e.name.toUpperCase()}</h3>
                                <Tag color='processing'>{e.services?.length}</Tag>
                            </Space>
                            <Space direction='vertical' size='small' style={{ width: '100%' }}>
                                {e.services?.filter(o => o.name.toLowerCase().includes(props.searchParams.toLowerCase())).map((service) => (
                                    <Row
                                        key={service.id+e.id}
                                        style={{
                                            border: '1px solid #D0D5DD',
                                            padding: '10px 20px',
                                            borderRadius: '14px',
                                        }}
                                    >
                                        <Col span={10}>
                                            <Text maxWidth={200} ellipsis>{service.name}</Text>
                                        </Col>
                                        <Col span={8}>
                                            {service.duration} min
                                        </Col>
                                        <Col span={5} style={{ textAlign: 'right' }}>
                                            {formatCurrency(service.serviceBranches[0].price)}
                                        </Col>
                                    </Row>
                                ))}
                            </Space>
                        </Space>
                    </Block>
                );
            })}
        </>
    );
}