import { SettingOutlined } from '@ant-design/icons';
import { AppButton, globalDrawerState } from '@esg/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useSchedulerContext } from '../@share/SchedulerContext';
import { AppointmentSettingForm } from '@/components/form/appointment-setting';

export function AppointmentSetting() {
    const { appointmentSetting, refetchAppointmentSetting } = useSchedulerContext();
    const [, setDrawerState] = useRecoilState(globalDrawerState);

    return (
        <AppButton
            shape='circle'
            onClick={() => {
                setDrawerState({
                    isOpen: true,
                    titleTransCode: 'Appointment Settings',
                    content: (
                        <AppointmentSettingForm
                            defaultValues={appointmentSetting}
                            refetchAppointmentSetting={refetchAppointmentSetting}
                        />
                    ),
                });
            }}
        >
            <SettingOutlined />
        </AppButton>
    );
}
