import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

export interface TimeOffDeleteRequest extends HttpRequestData {
	readonly pathData: {
		id: string;
	};
}

export const deleteTimeOff = httpUtil.createHttpRequestMeta<TimeOffDeleteRequest, void>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/time-offs/:id',
    method: 'DELETE',
    authentication: 'bearer',
});
