import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';

interface GetTotalNewClientsAppointmentRequest extends HttpRequestData {
    readonly query : {
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetTotalNewClientsAppointmentResponse {
    readonly totalClientsNew: number;
}

export const getTotalNewClientsAppointment = httpUtil.createHttpRequestMeta<
    GetTotalNewClientsAppointmentRequest,
	GetTotalNewClientsAppointmentResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'GET',
    path: '/api/v1/appointments-report/total-new-clients',
    authentication: 'bearer',
});