import React from 'react';
import { IconProps } from '../../Types';

export const IconRowLeft = (props: IconProps) => {
    return (
        <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.5 15L7.5 10L12.5 5' stroke={props.color} strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};

IconRowLeft.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#0C6FF3',
};
