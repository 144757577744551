import { AppButton, FlexBox, IconRefresh, IconTrash } from '@esg/ui';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentGetDetailResponse } from '@esg/business-schedule';
import dayjs from 'dayjs';
import { AppointmentFormBtn } from '@/components';
import { translateCodes } from '@/locales';

interface AppointmentColumnActionsProps {
    onClickDelete?: () => void;
    isShowRebook?: boolean;
    appointment?: AppointmentGetDetailResponse;
    refetch?: () => void;
}

export const AppointmentColumnActions = (props: AppointmentColumnActionsProps) => {
    const { onClickDelete , isShowRebook, appointment, refetch } = props;
    const { t } = useTranslation();

    return (
        <FlexBox alignItems='center' gap={18}>
            {isShowRebook && (
                <Tooltip placement='top' title={t(translateCodes.REBOOK)}>
                    <AppointmentFormBtn
                        type='text'
                        size='small'
                        initialValues={{
                            ...appointment,
                            id: undefined,
                            appointmentDate: dayjs().toISOString(),
                            status: undefined,
                        }}
                        refetch={refetch}>
                        <IconRefresh
                            className='icon-refresh'
                        />
                    </AppointmentFormBtn>
              
                </Tooltip>
            )}
            {/* <Tooltip placement='top' title={t(translateCodes.VIEW)}>
                <AppointmentFormBtn
                    type='text'
                    size='small'
                    initialValues={appointment}
                    refetch={refetch}>
                    <IconEye
                        className='icon-view' />
                </AppointmentFormBtn>
            </Tooltip> */}
            <Tooltip
                placement='top'
                title={t(translateCodes.DELETE)}
            >
                <AppButton type='text' size='small' onClick={onClickDelete}>
                    <IconTrash
                        className='icon-delete'
                    />
                </AppButton> 
            </Tooltip>
        </FlexBox>
       
    );
};
