import './SaleManagement.scss';
import React from 'react';
import { Spin } from 'antd';
import { useHttpQuery } from '@esg/ui';
import { managementApis } from '@esg/business-management';
import { FastSaleLayout } from './children/fast-sales/FastSaleLayout';

export interface FilterSaleParams {
    readonly employeeIds?: string[];
    readonly date?: string;
    readonly serviceIds?: string[];
}
export default function FastPostManagement() {

    const { data: serviceCategories, isFetching: servicesLoading } = useHttpQuery(managementApis.getAllServiceCategory, undefined, {
        staleTime: 0,
    });

    const { data: productCategories, isFetching: productsLoading } = useHttpQuery(managementApis.getAllProductCategory, undefined, {
        staleTime: 0,
    });

    const renderFastSaleContentView = () => {
        const isLoading = servicesLoading || productsLoading;
        if (isLoading) { return null; }

        return (
            <FastSaleLayout
                serviceCategories={serviceCategories?.items}
                productCategories={productCategories?.items}
            />
        );
    };

    return (
        <Spin spinning={servicesLoading || productsLoading} style={{ padding: servicesLoading || productsLoading ? 200 : 0 }}>
            {renderFastSaleContentView()}
        </Spin>
    );
}